import React from "react";
import edit from "../../assets/images/edit.png";
import deleteBox from "../../assets/images/deletebox.png";
import Loader from "../../modals/Loader";

const KebabMenu = ({ onEdit, onDelete, loading, ref }) => {
  return (
    <div
      ref={ref}
      className="justify-center flex items-center z-10 bg-white border border-gray-100 mt-[30px] w-auto rounded-md px-3 py-3 absolute shadow-md top-0 right-0"
    >
      <div>
        {onEdit && (
          <div className="flex items-center">
            <img
              src={edit}
              style={{
                width: "20px",
                height: "15px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            <h2 onClick={onEdit} className="ml-2 text-sm cursor-pointer">
              Edit comment
            </h2>
          </div>
        )}

        {onDelete && (
          <div className="flex w-max items-center" onClick={onDelete}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <img
                  src={deleteBox}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <h2 className="ml-2 text-sm cursor-pointer">Delete chat</h2>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default KebabMenu;
