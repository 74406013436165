import { useEffect, useState } from "react";
// import hospitaldashboard from "../../assets/images/hospitaldashboard.png";
import hospitaldashboard from "../../assets/images/hospitaldashboard.svg";
// import newOne from "../../assets/images/newOne.png";
import newOne from "../../assets/images/newOne.svg";
import { axiosInstance } from "../../../config/axiosConfig";
import user from "../../assets/images/user.png";
import "./dashboard.css";
import group from "../../assets/images/group.svg";
import group2 from "../../assets/images/group2.svg";
import vector from "../../assets/images/Vector.svg";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState();
  const navType = localStorage.getItem("type");
  const [dashboardData1, setDashboardData1] = useState("");
  const [today, setToday] = useState("");
  const [filterDate, setFilterDate] = useState(false);
  const [data, setData] = useState("");
  const currentDate = new Date();
  const d = currentDate.getDate();
  const m = currentDate.getMonth() + 1;
  const y = currentDate.getFullYear();
  const profile = useSelector((state) => state.data.profile);
  const usertype = localStorage.getItem("type");
  const todayFormattedDate = `${m < 10 ? "0" + m : m}/${
    d < 10 ? "0" + d : d
  }/${y}`;
  useEffect(() => {
    setToday(todayFormattedDate);
    setFilterDate(currentDate);
    if (navType == "staff") {
      const fetchData1 = async () => {
        const res = await axiosInstance.get(`v1/LHS/staff/dashboard`);
        const data = res.data.data;
        console.log(data.totalContracts);
        setDashboardData(data);
      };
      fetchData1();
    }
    if (navType == "superAdmin" || navType == "admin") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const fetchData2 = async () => {
        try {
          const res = await axiosInstance.get(`v1/LHS/admin/dashboard`);
          if (res.data) {
            setData(res.data);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData2();
    }
    if (navType == "hospital") {
      const fetchData3 = async () => {
        const res = await axiosInstance.get(`v1/LHS/hospital/dashboard`);
        const data = res.data.data;
        setDashboardData1(data);
      };
      fetchData3();
    }
  }, [navType]);

  return (
    <>
      <div className="theme2">
        <div className="flex">
          <div
            className="theme2_container"
            style={{ background: "#fff", borderRadius: "16px" }}
          >
            <div className="">
              <div>
                {navType == "staff" && (
                  <div className="bg-white">
                    <div className="col col-2xl-6 staff_contract px-4">
                      <h1 className="text-[25px] text-[#333333] mt-5 mb-4">
                        {" "}
                        Job Contract Overview{" "}
                      </h1>
                      <div className="row first_row bottom_card">
                        <div className="col-sm-6 col-md-6">
                          <div className="card w-[325px] h-auto">
                            <div className="card_body_1">
                              {/* <div className="container"> */}
                              <div className="flex items-center justify-between p-4 w-[325px] h-auto">
                                {/* <hr className="admin_dash_item_hr" /> */}
                                <div
                                  className=""
                                  style={{
                                    float: "right",
                                  }}
                                >
                                  <p className="text-[16px] text-[#333333]">
                                    Accepted jobs
                                  </p>
                                  <p className="text-[20px] text-[#333333]">
                                    {dashboardData?.activeContracts}
                                  </p>
                                </div>
                                <button className="">
                                  <img
                                    src={group}
                                    alt="hospital.svg"
                                    className="admin_dash_item_img"
                                  />
                                </button>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-5" style={{ width: "100%" }} />
                    <div className="col col-2xl-6 staff_hiring px-4">
                      <h1 className="text-[25px] text-[#333333]">
                        {" "}
                        Job Application Overview{" "}
                      </h1>
                      <div className="Job_Application_Container">
                        <div className=" flex w-[325px]  border-1 justify-between p-3 rounded-md">
                          <div className="card_body_4">
                            <div className="container justify-between flex">
                              <div className="col flex">
                                <div
                                  className="col"
                                  style={{
                                    float: "right",
                                  }}
                                >
                                  <p className="text-[20px] text-[#333333]">
                                    {" "}
                                    Applied{" "}
                                  </p>
                                  <p className="text-[20px] text-[#333333]">
                                    {dashboardData?.jobsApplied}
                                  </p>
                                </div>
                                <div className="flex w-fit">
                                  <button className="">
                                    <img
                                      src={group2}
                                      alt="hospital.svg"
                                      className="admin_dash_item_img"
                                    />
                                  </button>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                        <div className="flex w-[325px] h-[95px] border-1 justify-between p-3 rounded-md">
                          <div className="card_body_4">
                            <div
                              className=""
                              style={{
                                float: "left",
                              }}
                            >
                              <p
                                className="text-[16px]"
                                style={{ lineHeight: "26px" }}
                              >
                                {" "}
                                Jobs Offer{" "}
                              </p>
                              <p className="text-[20px]">
                                {dashboardData?.jobsOffers}
                              </p>
                            </div>
                            <div className="flex w-fit">
                              <button className="">
                                <img
                                  src={vector}
                                  alt="hospital.svg"
                                  className="admin_dash_item_img"
                                />
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {navType == "superAdmin" && (
                  <div className="t2_sp_work" style={{ marginTop: 20 }}>
                    <div className="row admin_dash_part1"></div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <h5 className="p-1">Hospital</h5>
                        <div className="admin_dash_item_container">
                          <button className="admin_dash_item">
                            <img
                              src={hospital}
                              alt="hospital.svg"
                              className="admin_dash_item_img"
                            />
                          </button>
                          <button className="admin_dash_item admin_dash_item_right">
                            <h5>{data?.data?.latestHospital}</h5>
                            <p className="item_description">
                              New Registrations
                            </p>
                          </button>
                        </div>
                        <div className="admin_dash_item_container">
                          <button className="admin_dash_item">
                            <img
                              src={register}
                              alt="hospital.svg"
                              className="admin_dash_item_img"
                            />
                          </button>
                          <button className="admin_dash_item admin_dash_item_right">
                            <h5>{data?.data?.totalHospital}</h5>
                            <p className="item_description">
                              Total Registrations
                            </p>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <h5 className="p-1">Staff</h5>
                        <div className="admin_dash_item_container">
                          <button className="admin_dash_item">
                            <img
                              src={staff}
                              alt="hospital.svg"
                              className="admin_dash_item_img"
                            />
                          </button>
                          <hr className="admin_dash_item_hr" />
                          <button className="admin_dash_item admin_dash_item_right">
                            <h5>{data?.data?.latestStaff}</h5>
                            <p className="item_description">
                              New Registrations
                            </p>
                          </button>
                        </div>
                        <div className="admin_dash_item_container">
                          <button className="admin_dash_item">
                            <img
                              src={register}
                              alt="hospital.svg"
                              className="admin_dash_item_img"
                            />
                          </button>
                          <hr className="admin_dash_item_hr" />
                          <button className="admin_dash_item admin_dash_item_right">
                            <h5>{data?.data?.totalStaff}</h5>
                            <p className="item_description">
                              Total Registrations
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                {(navType == "superAdmin" || navType == "admin") && (
                  <div
                    className="t2_sp_work"
                    style={{ marginTop: 15, padding: "0px" }}
                  >
                    <div className="row admin_dash_part1">
                      <div className="flex justify-center text-center flex-row">
                        <div>
                          <div className="w-36 h-36 rounded-full overflow-hidden">
                            <img
                              className="object-cover w-full h-full"
                              src={profile?.image ? profile?.image : user}
                              alt="not found"
                            />
                          </div>
                          <div className="mt-3 text-2xl">
                            {usertype == "superAdmin"
                              ? `Super admin`
                              : usertype == "admin"
                              ? "Admin"
                              : ` ${profile?.name?.split(" ")[0]}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 border border-red-400 mt-2"></div>
                    <h5 className="ml-10 text-[25px] p-2">Hospital</h5>
                    <div className="flex flex-row p-4 gap-5 ml-4">
                      <div className="admin_dash_item_container w-[336px] p-3 justify-between ">
                        <button>
                          <p className="text-[16px]">New Registrations</p>
                          <h5 className="text-left">
                            {data?.data?.latestHospital}
                          </h5>
                        </button>
                        <button className="">
                          <img
                            src={hospitaldashboard}
                            alt="hospital.svg"
                            className="w-[41px] h-[41px]"
                          />
                        </button>
                      </div>
                      <div className="admin_dash_item_container w-[336px] justify-between p-3">
                        <button className="">
                          <p className="text-[16px]">Total Registrations</p>
                          <h5 className="text-left">
                            {data?.data?.totalHospital}
                          </h5>
                        </button>
                        <button className="admin_dash_item">
                          <img
                            src={hospitaldashboard}
                            alt="hospital.svg"
                            className="w-[41px] h-[41px]"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 border border-red-400 mt-2"></div>
                    <h5 className="ml-10 text-[25px] p-2">Staff</h5>
                    <div className="flex flex-row p-4 gap-5 ml-4">
                      <div className="admin_dash_item_container w-[336px] justify-between p-3">
                        <hr className="admin_dash_item_hr" />
                        <button className="">
                          <p className="text-[16px]">New Registrations</p>
                          <h5 className="text-left">
                            {data?.data?.latestStaff}
                          </h5>
                        </button>
                        <button>
                          <img
                            src={newOne}
                            alt="hospital.svg"
                            className="w-[41px] h-[41px]"
                          />
                        </button>
                      </div>
                      <div className="admin_dash_item_container w-[336px]  justify-between p-3">
                        <hr className="admin_dash_item_hr" />
                        <button className="">
                          <p className="text-[16px]">Total Registrations</p>
                          <h5 className="text-left">
                            {data?.data?.totalStaff}
                          </h5>
                        </button>
                        <button className="">
                          <img
                            src={newOne}
                            alt="hospital.svg"
                            className="w-[41px] h-[41px]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {navType == "hospital" && (
                  <div className="">
                    <div className="flex flex-col">
                      <div className="p-4">
                        <h3 className="text-2xl"> Staff Contract Overview</h3>
                        <div
                          className="row first_row"
                          style={{ margin: "30px 0px 0 0px" }}
                        >
                          <div className="col-sm-6 col-md-6">
                            <div
                              className="card"
                              style={{
                                width: "336px",
                                minHeight: "88px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="card_body_1 p-2">
                                <div className="container">
                                  <div className="flex justify-between">
                                    <div
                                      className=""
                                      style={{ fontSize: "20px" }}
                                    >
                                      <p>Offer Accepted</p>
                                      <p style={{ lineHeight: "25px" }}>
                                        {dashboardData1.activeContracts}
                                      </p>
                                    </div>
                                    <div
                                      className=""
                                      style={{
                                        float: "right",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                      }}
                                    >
                                      <img
                                        src={group}
                                        alt="not found"
                                        width="50px"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ margin: "15px 0px" }} />
                      <div className="staff_hiring text-2xl p-4">
                        <h3> Staff Hiring Overview </h3>
                        <div className="flex">
                          <div className="card bottom_card w-[336px] min-h-[88px] p-3 ">
                            {/* <div className="flex  "> */}
                            <div className="container">
                              <div className="flex justify-between">
                                <div
                                  className="col"
                                  style={{
                                    float: "right",
                                    textAlign: "left",
                                    fontSize: "20px",
                                    color: "rgba(51, 51, 51, 0.7)",
                                  }}
                                >
                                  <p> Open Positions </p>
                                  <p style={{ lineHeight: "20px" }}>
                                    {dashboardData1.openPositions}
                                  </p>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    float: "right",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    src={group2}
                                    alt="not found"
                                    width="50px"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="card bottom_card">
                            <div className="flex w-[330px] justify-between p-2 mt-2">
                              <div className="container">
                                <div className="flex justify-between">
                                  <div
                                    className="col"
                                    style={{
                                      float: "right",
                                      textAlign: "left",
                                      fontSize: "20px",
                                      color: "rgba(51, 51, 51, 0.7)",
                                    }}
                                  >
                                    <p> Job Offer </p>
                                    <p style={{ lineHeight: "20px" }}>
                                      {dashboardData1.jobOffered}
                                    </p>
                                  </div>
                                  <div
                                    className=""
                                    style={{
                                      float: "right",
                                      textAlign: "center",
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <img
                                      src={vector}
                                      alt="not found"
                                      width="45px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
