import React, { useEffect, useState } from "react";
import Confirm from "../../modals/confirm";
import Pagination from "./pagination";
import Loader from "../../modals/Loader";
import moment from "moment";
import { setSession } from "../../../redux/action/action";
import { axiosInstance } from "../../../config/axiosConfig";
import "../theme2.css";

const ManagePayment = () => {
  const [hospitals, setHospitals] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sortByName, setSortByName] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [showConfirm, setShowConfirm] = useState("none");
  const [confirmTitle, setConfirmTitle] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [setType, setSetType] = useState("");
  const [adminDetails, setAdminDetails] = useState({});
  const [search, setSearch] = useState("");
  const [skip, setSkip] = useState(0);
  const [showLoader, setShowLoader] = useState("");
  const [showMainLoader, setShowMainLoader] = useState(false);
  const [showPayButton, setShowPayButton] = useState(false);
  const [showPayLoader, setShowPayLoader] = useState("");

  useEffect(() => {
    getStaffByQuery();
    initState();
    let adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
    setAdminDetails(adminDetails);
    setShowMainLoader(true);
    const type = localStorage.getItem("type");
    setSetType(type);
    localStorage.setItem("hospitalJobId", "");
    return () => {};
  }, []);

  const setCurPage1 = async (num) => {
    setHospitals([]);
    setShowMainLoader(true);
    const value = num * 10 - 10;
    setCurPage(num);
    setSkip(value);
    getStaffByQuery(skip);
  };

  const setSortByName1 = () => {
    setSortByName(-1 * sortByName);
    getStaffByQuery();
  };

  const getStaffByQuery = async (skip = 0) => {
    var data = {
      name,
      email,
      sortByName,
      curPage,
    };

    var res = await axiosInstance.get(
      `v1/LHS/payment/getList?skip=${skip}&type=Pending`
    );
    if (res.data) {
      if (setType === "superAdmin") {
        setSession(res.token, res.data._id, "superAdmin");
        setShowMainLoader(false);
        setShowPayButton(true);
      } else {
        setSession(res.token, res.data._id, "admin");
        setShowMainLoader(false);
      }
      var totalCount = res.data.count;
      var page = Math.ceil(totalCount / 10);
      setHospitals([...res.data.result]);
      setTotalPage(page);
      setShowLoader("");
    } else {
      setHospitals([]);
      setShowMainLoader(false);
    }
  };

  const handlePayment = async (id) => {
    setShowPayLoader(id);
    var token = localStorage.getItem("token");
    token = "Bearer " + token;
    var res = await callApi(
      "POST",
      `v1/LHS/payment/gateway/payout/${id}`,
      token
    );
    console.log(res, "payment data");
    if (res.status === 404) {
      alert("Something went wrong");
      setShowPayLoader("");
    } else if (res.status === 200) {
      alert("Payment Successful");
      setShowPayLoader("");
    } else {
      setShowPayLoader("");
      alert("Something went wrong");
    }
  };

  return (
    <div className="theme2_body" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <div className="t2_sp_work" style={{ marginTop: 0 }}>
        <div className="row"></div>
        {showMainLoader ? (
          <Loader />
        ) : (
          <div
            className="row hide_scrollbar"
            style={{
              overflowX: "auto",
              marginTop: "32px",
              display: hospitals.length ? "block" : "none",
            }}
          >
            <table
              className="admin_table"
              style={{
                width: "99%",
                minWidth: "800px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              <thead>
                <tr className="nurseHead">
                  <th
                    className="t2_sp_licence_th"
                    style={{ paddingLeft: "30px" }}
                  >
                    Staff Name{" "}
                  </th>
                  <th className="t2_sp_licence_th">Amount </th>
                  <th className="t2_sp_licence_th"> Verified Date </th>
                  <th className="t2_sp_licence_th" style={{ width: "80px" }}>
                    {" "}
                    Pay{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 20 }} />
                {hospitals.map((item) => (
                  <React.Fragment key={item._id}>
                    <tr className="admin_mngStaff_row">
                      <td
                        className="admin_mngStaff_td"
                        style={{ paddingLeft: "30px" }}
                      >
                        {item.staff && item.staff.name}
                      </td>
                      <td className="admin_mngStaff_td">
                        {" "}
                        {`$ ${item.shiftCost}`}{" "}
                      </td>
                      <td className="admin_mngStaff_td">
                        {item.verifiedOn
                          ? `${moment(item.verifiedOn).format(
                              "MM-DD-YYYY"
                            )}, ${moment(item.verifiedOn).format("HH:mm A")}`
                          : ""}
                      </td>
                      <td className="admin_mngStaff_td">
                        <button
                          className="w-75 m-0 me-5 admin_mngAdmin_roleFilter admin_mngAdmin_addAdmin"
                          onClick={() => handlePayment(item._id)}
                        >
                          {showPayLoader === item._id ? <Loader /> : "Pay"}
                        </button>
                      </td>
                    </tr>
                    <tr style={{ height: 24 }}></tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <Confirm
              display={showConfirm}
              title={'Delete "' + confirmTitle + '"'}
              content={'Are you sure you want to delete "' + confirmTitle + '"'}
              agreeFn={deleteOneRow}
              disagreeFn={() => setShowConfirm("none")}
            />
          </div>
        )}

        {!hospitals.length && !showMainLoader ? (
          <p className="text-center my-5">No record found</p>
        ) : null}

        {hospitals.length ? (
          <Pagination
            curPage={curPage}
            totalPage={totalPage}
            setCurPage={setCurPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ManagePayment;
