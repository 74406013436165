import React, { useState, useEffect } from "react";
import "./subHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { decrementStep } from "../../../../redux/action/action";

const SubHeader = (props) => {
  const dispatch = useDispatch();
  const [nextColor, setNextColor] = useState("#E8E7E6");

  useEffect(() => {
    if (props.redo === true) {
      setNextColor("#73C7A9");
    }
  }, [props.redo]);

  const navToNext = () => {
    if (props.redo === true) {
      props.history.push(props.next);
    }
    // dispatch(decrementStep());
  };

  const navToPrev = () => {
    if (props.onBack) {
      dispatch(decrementStep());
      props.onBack();
    }
  };

  return (
    <div className="ssu_subHeader_container m-auto">
      <div
        className="ssu_subHeader_back"
        onClick={navToPrev}
        style={{ display: props.prev ? "block" : "none" }}
      >
        <svg
          width="24"
          height="20"
          viewBox="0 0 30 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30 10.927H6.23656L14.8599 2.30368L12.5562 0L0 12.5562L12.5562 25.1124L14.8599 22.8087L6.23656 14.1854H30V10.927Z"
            fill="#73C7A9"
          />
        </svg>
      </div>
      {/* <div
        className="ssu_subHeader_next"
        onClick={navToNext}
        style={{
          display:
            props.next && props.num !== "1" ? "block" : "none",
        }}
      >
        <svg
          width="24"
          height="20"
          viewBox="0 0 30 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5527e-07 15.073L23.7634 15.073L15.1401 23.6963L17.4438 26L30 13.4438L17.4438 0.887585L15.1401 3.19127L23.7634 11.8146L1.24013e-06 11.8146L9.5527e-07 15.073Z"
            fill={nextColor}
          />
        </svg>
      </div> */}
      <div className="ssu_subHeader_center">
        <h4>
          <span
            className="w3-badge w3-small w3-blue"
            style={{
              marginRight: "20px",
              padding: "2px 8px",
              textAlign: "center",
              background: "#73C7A9",
              color: "#ffff",
              borderRadius: "100%",
            }}
          >
            {props.num}
          </span>
          <b className="ssu_subHeader_title">{props.title}</b>
        </h4>
        <img
          alt="img"
          src={props.img}
          className="ssu_subHeader_img !text-[#73C7A9]"
        />
      </div>
    </div>
  );
};

export default SubHeader;
