import React, { useEffect, useRef, useState } from "react";
import Nav from "../Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import { useHistory } from "react-router-dom";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";
import Modal from "../../common/modal";
import dots from "../../assets/images/dots.png";
import RightSideBar from "../../containers/commonComponent/rightSideBar";
import Loader from "../../modals/Loader";
// import io from "socket.io-client";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import calculateTime from "../../modals/calculateTime";
import KebabMenu from "../../containers/commonComponent/Kebabmenu";
import useClickOutside from "../../modals/hooks/useClickOutside";
import {
  chatMessageService,
  hospitalMessageService,
} from "../../../sockets/socketService";
import { useSelector } from "react-redux";
import { formatMessageDate } from "../../modals/todayYesDateTime";

// const socket = io("https://devapi.linkhealthstaff.com");
const Meassge = ({}) => {
  const history = useHistory();
  const userIdValuefinl = localStorage.getItem("_id");
  const [getAllChats, setGetAllChats] = useState([]);
  const [getAllContacts, setGetAllContacts] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const closeOnOutsideClick = () => {
    setOpenMenu(false);
  };
  const leaveRoom = useSelector((state) => state.data.leaveRoom);
  const queryParams = new URLSearchParams(location.search);
  const param1Value = queryParams.get("param1") ?? "tab1";
  const menuRefs = useClickOutside(closeOnOutsideClick);
  const [menuIndex, setMenuIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const localdata = localStorage.getItem("tab");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChatbox = (item) => {
    localStorage.setItem("CurrentChatData", JSON.stringify(item));
    const combinedIds = `${item?._id}-${item?.userId?._id}`;
    const timestamp = moment().unix();
    const chatid =
      userIdValuefinl == item?.userId?._id
        ? item?.staffId?._id
          ? item?.staffId?._id
          : item?.hospitalId?._id
        : item?.userId?._id;
    history.push({
      pathname: `/HospitalChatBox/${combinedIds}?chatid=${chatid}&timestamp=${timestamp}`,
      state: { from: item },
      search: `param1=${localdata}`,
    });
  };
  const handleContactChatbox = (item) => {
    const chatid =
      userIdValuefinl == item?.userId?._id
        ? item?.staffId?._id
          ? item?.staffId?._id
          : item?.hospitalId?._id
        : item?.userId?._id;
    const combinedIds = `${item?._id}-${
      userIdValuefinl == item?.userId?._id
        ? item?.staffId?._id
          ? item?.staffId?._id
          : item?.hospitalId?._id
        : item?.userId?._id
    }`;
    history.push({
      pathname: `/HospitalChatBox/${combinedIds}?chatid=${chatid}`,
      search: `param1=${localdata}`,
    });
  };
  const getChatUsers = async (nextPage) => {
    try {
      setLoader(true);
      let url = `/v2/LHS/User/getAllChats?page=${nextPage || page}&limit=7`;
      const response = await axiosInstance.get(url);
      if (response.status == 200) {
        setGetAllChats((prevData) => {
          if (page > 1 && !nextPage) {
            return [...prevData, ...response?.data?.data];
          } else {
            return [...response?.data?.data];
          }
        });
        setTotalPages(response.data.totalPages);
        setHasMore(response?.data?.data?.length > 0);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const Iran = useRef(0);

  useEffect(() => {
    if (Iran.current) return;
    if (param1Value === "tab1") {
      getChatUsers();
      Iran.current = 1;
    } else if (param1Value === "tab2") {
      getContacts();
      Iran.current = 1;
    }
  }, []);

  const getContacts = async (nextPage) => {
    setLoader(true);
    try {
      const response = await axiosInstance.get(
        `/v2/LHS/User/getContacts?page=${nextPage || page}&limit=7`
      );
      if (response.status === 200) {
        setGetAllContacts((prevData) => {
          if (page > 1) {
            return [...prevData, ...response?.data?.data];
          } else {
            return [...response?.data?.data];
          }
        });
        setTotalPages(response.data.totalPages);
        setPage((prevPage) => prevPage + 1);
        setHasMore(response?.data?.data?.length > 0);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const setChatData = (item) => {
    localStorage.setItem("currentChat", JSON.stringify(item));
  };

  // const [chatCountData, setChatCountData] = useState(null);
  // useEffect(() => {
  //   const { ChatId, loggedin_id, userrtype } = leaveRoom;
  //   chatMessageService.leaveRoom(ChatId, loggedin_id, userrtype);
  //   hospitalMessageService.onChatCount((data) => {
  //     setChatCountData(data);
  //   });
  //   return () => {
  //     hospitalMessageService.offChatCount();
  //   };
  // }, []);
  const [chatCountData, setChatCountData] = useState(null);
  useEffect(() => {
    const { ChatId, loggedin_id, userrtype } = leaveRoom;

    chatMessageService.leaveRoom(ChatId, loggedin_id, userrtype);
    const handleChatCount = (count) => {
      setGetAllChats((prevChats) => {
        return prevChats.map((chat) => {
          if (chat?.chatMessages?.chatId === count?.data?.chatId) {
            return {
              ...chat,
              totalMessage: count?.data?.chatCount,
            };
          }
          return chat;
        });
      });
    };
    hospitalMessageService.onChatCount(handleChatCount);
    return () => {
      hospitalMessageService.offChatCount();
    };
  }, [getAllChats]);
  const handleTabClick = (tab) => {
    if (loader) return;
    localStorage.setItem("tab", tab);
    setActiveTab(tab);
    history.push(`/meassage?param1=${tab}`);
    if (tab == "tab2") {
      setPage(1);
      getContacts(1);
    } else if (tab == "tab1") {
      setPage(1);
      getChatUsers(1);
    }
  };
  const SendRequest = async () => {
    const storedData = localStorage.getItem("currentChat");
    const parsedData = JSON.parse(storedData);
    const data = {
      id: parsedData?._id,
      type: 1,
      chatType: 1,
    };
    try {
      let url = `/v2/LHS/User/sendChatRequest`;
      const response = await axiosInstance.post(url, data);
      if (response?.status === 200) {
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteChatUsers = async (ChatId) => {
    try {
      setLoading(true);
      let url = `/v2/LHS/User/deleteChat/${ChatId?._id}`;
      const response = await axiosInstance.delete(url);
      if (response.status === 200) {
        showSuccessToast(response?.data?.message);
        setPage(1);
        getChatUsers(1);
      } else {
        showErrorToast(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setOpenMenu(false);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Nav />
      <div
        className="flex justify-center items-center"
        style={{ backgroundColor: "#F3F2EF" }}
      >
        <div
          className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <div className="sticky h-auto min-w-[25%] hidden lg:block">
            <RightSideBar />
          </div>
          <div className="flex flex-col !gap-3 md:px-5 lg:px-0 w-full xl:w-[52%] lg:w-[48%] mx-auto">
            <div>
              <div className="flex h-16 bg-white rounded-t-lg text-xl justify-between">
                <div
                  className={`cursor-pointer px-4 py-2 flex items-center w-2/4 justify-center text-slate-400 ${
                    activeTab === "tab1" || param1Value == "tab1"
                      ? "!text-[#73C7A9] border-b-2 !border-[#73C7A9]"
                      : ""
                  }`}
                  onClick={() => {
                    setGetAllChats([]);
                    handleTabClick("tab1");
                  }}
                >
                  Chats
                </div>
                <div
                  className={`cursor-pointer px-4 py-2 flex items-center w-2/4 justify-center text-slate-400 ${
                    activeTab === "tab2" || param1Value == "tab2"
                      ? "!text-[#73C7A9] border-b-2 !border-[#73C7A9]"
                      : ""
                  }`}
                  onClick={() => {
                    setGetAllContacts([]);
                    handleTabClick("tab2");
                  }}
                >
                  Contacts
                </div>
              </div>
              <div className="mt-3">
                {(activeTab === "tab1" || param1Value == "tab1") && (
                  <>
                    {getAllChats?.length > 0 ? (
                      <>
                        <InfiniteScroll
                          dataLength={getAllChats?.length}
                          next={getChatUsers}
                          hasMore={hasMore}
                          loader={<Loader />}
                          endMessage={<p>No more items to load</p>}
                        >
                          {getAllChats.map((data, index) => {
                            const formatTime = (date) => {
                              const options = {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone: "Asia/Kolkata",
                              };
                              const formattedDate = date.toLocaleString(
                                "en-IN",
                                options
                              );
                              const [dd, mm, yyyy] = formattedDate.split("/");
                              return `${mm}/${dd}/${yyyy}`;
                            };

                            // Inside your component
                            const chatDate = new Date(
                              data.chatMessages.createdAt
                            );
                            const userIdValue = localStorage.getItem("_id");
                            return (
                              <div
                                className="flex justify-between p-3 bg-white rounded-[10px] h-[90px] sm:w-full mb-3"
                                key={index}
                              >
                                <div
                                  className="flex items-center gap-3 w-4/5 cursor-pointer"
                                  onClick={() => handleChatbox(data)}
                                >
                                  <div className="w-12 min-w-[40px] h-12 rounded-full overflow-hidden">
                                    <img
                                      src={
                                        userIdValue == data?.userId?._id
                                          ? data?.staffId?.image
                                            ? data?.staffId?.image
                                            : data?.hospitalId?.image
                                          : data?.userId?.image
                                      }
                                      alt=""
                                      className="object-cover w-full h-full"
                                    />
                                  </div>
                                  <div onClick={() => handleChatbox(data)}>
                                    <h3 className="p-0 text-[#333333] text-[18px] font-semibold cursor-pointer">
                                      {userIdValue == data?.userId?._id
                                        ? data?.staffId?.name
                                          ? data?.staffId?.name
                                          : data?.hospitalId?.name
                                        : data?.userId?.name}
                                    </h3>
                                    <p className="text-gray-500 text-[15px] font-normal">
                                      {userIdValue == data?.userId?._id
                                        ? data?.staffId?.jobTitle
                                          ? data?.staffId?.jobTitle
                                          : data?.hospitalId?.hiringRole
                                        : data?.userId?.role == "staff"
                                        ? data?.userId?.jobTitle
                                        : data?.userId?.hiringRole}
                                    </p>
                                  </div>
                                </div>
                                <div className="justify-between flex flex-col">
                                  <div className="gap-[7px] justify-between flex">
                                    <p className="text-[12px] text-gray-500 mt-1 font-normal">
                                      {formatMessageDate(chatDate)}
                                    </p>
                                    <div className="h-full items-center flex relative">
                                      {openMenu && menuIndex == index && (
                                        <KebabMenu
                                          ref={menuRefs}
                                          loading={loading}
                                          key={index}
                                          onDelete={(e) => {
                                            e.stopPropagation();
                                            DeleteChatUsers(data);
                                          }}
                                        />
                                      )}
                                      <div className="">
                                        <img
                                          key={index}
                                          src={dots}
                                          className="w-4 h-1 align-top"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setOpenMenu((s) => !s);
                                            setMenuIndex(index);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex justify-end">
                                    {(chatCountData?.data?.chatId ==
                                    data?.chatMessages?.chatId
                                      ? chatCountData?.data?.chatCount != 0
                                      : data?.totalMessage !== 0) && (
                                      <div className="w-[23px] h-[23px] bg-[#73C7A9] rounded-full text-white flex items-center justify-center">
                                        {chatCountData?.data?.chatId ==
                                        data?.chatMessages?.chatId
                                          ? chatCountData?.data?.chatCount
                                          : data?.totalMessage}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </>
                    ) : (
                      <>
                        {/* {loader ? (
                          <Loader />
                        ) : ( */}
                        {!loader && (
                          <p className="text-center mt-5">No chat available</p>
                        )}
                        {/* )} */}
                      </>
                    )}
                  </>
                )}
                {(activeTab === "tab2" || param1Value == "tab2") && (
                  <>
                    {getAllContacts?.length > 0 ? (
                      <>
                        <InfiniteScroll
                          dataLength={getAllContacts?.length}
                          next={getContacts}
                          hasMore={hasMore}
                          loader={<Loader />}
                          endMessage={<p>No more items to load</p>}
                        >
                          {getAllContacts?.map((item, index) => {
                            const userIdValue = localStorage.getItem("_id");
                            return (
                              <div
                                className="flex justify-between p-3 bg-white rounded-[10px] h-[90px] sm:w-full  mb-3"
                                key={index}
                              >
                                <div
                                  className="flex items-center gap-3 cursor-pointer w-full"
                                  onClick={() => {
                                    handleContactChatbox(item);
                                    setChatData(item);
                                  }}
                                >
                                  <div className="w-12 min-w-[40px] h-12 rounded-full overflow-hidden">
                                    <img
                                      src={
                                        userIdValue == item?.userId?._id
                                          ? item?.staffId?.image
                                            ? item?.staffId?.image
                                            : item?.hospitalId?.image
                                          : item?.userId?.image
                                      }
                                      alt=""
                                      className="object-cover w-full h-full"
                                    />
                                  </div>
                                  <div>
                                    <h3 className="p-0 text-[#333333] text-[18px] font-semibold cursor-pointer">
                                      {userIdValue == item?.userId?._id
                                        ? item?.staffId?.name
                                          ? item?.staffId?.name
                                          : item?.hospitalId?.name
                                        : item?.userId?.name}
                                    </h3>
                                    <p className="text-gray-500 text-[15px] font-normal">
                                      {userIdValue == item?.userId?._id
                                        ? item?.staffId?.jobTitle
                                          ? item?.staffId?.jobTitle
                                          : item?.hospitalId?.hiringRole
                                        : item?.userId?.hiringRole
                                        ? item?.userId?.hiringRole
                                        : item?.userId?.jobTitle}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </>
                    ) : (
                      <>
                        {!loader && (
                          <p className="text-center mt-5">
                            No contacts available
                          </p>
                        )}
                        {/* )} */}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {loader && page <= 1 && <Loader />}
          </div>
          <RecommendedForYou />
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        className={"md:w-1/2 md:mt-0"}
      >
        <div className="bg-white z-10 w-full h-full max-w-[600px] max-h-[284px] rounded-lg">
          <div className="p-12">
            <p className="text-xl leading-8 font-medium w-[26rem] m-auto mb-5 text-center">
              Are you sure you want to send request to Chat?
            </p>

            <div className="text-center">
              <button
                onClick={closeModal}
                className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5"
              >
                Cancel Request
              </button>
              <button
                className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                onClick={() => {
                  setModalOpen(false);
                  SendRequest();
                }}
              >
                Send Request
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Meassge;
