import React, { useState, useEffect, useRef } from "react";
import "./postnewjob.css";
import place from "../../assets/images/place.svg";
import drop from "../../assets/images/drop.svg";
import "./../staffSignUp/staffSignUp.css";
import calendar from "../../assets/images/calendar.png";
import { stateOfUs } from "../../../constants/otherConstans";
import { decrementStep } from "../../../redux/action/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../config/axiosConfig";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import Loader from "../../modals/Loader";
import classNames from "classnames";
import moment from "moment";
import Button from "../../ui/Button";

const AddEditJob = ({ type, applicantData, closeModal }) => {
  const [formData, setFormData] = useState({
    costPerHour: null,
    shiftStartTime: "",
    shiftEndTime: "",
    healthCareLocation: "",
    jobTitle: "",
    jobDescription: "",
    medicalSettings: "Acute Care Hospitals",
    contractType: "Temporary Position",
    timeCommitment: "",
    weekendAvailiblity: false,
    contractLength: {
      duration: "",
      length: "Days",
    },
    expectedStartDate: "",
    openPositions: null,
  });

  const [exp, setExp] = useState("Acute Care Hospitals");
  const [_exps, setExps] = useState([
    "Acute Care Hospitals",
    "Urgent Care Centers",
    "Rehabilitation Centers",
    "Nursing Homes",
    "Other Long-term Care Facilities",
    "Specialized Outpatient Services",
  ]);

  const [showLoader, setShowLoader] = useState(false);
  const [showOtherExp, setShowOtherExp] = useState(false);

  const toggleExp = (selectedExp) => {
    setExp(selectedExp);
    setFormData((prev) => ({ ...prev, medicalSettings: selectedExp }));
    setShowOtherExp(selectedExp === "Other" ? !showOtherExp : showOtherExp);
    setErrors((prevErrors) => ({
      ...prevErrors,
      medicalSettings:
        selectedExp.trim() === "" ? "Error: Medical settings is required" : "",
    }));
  };

  const toggleExps = () => {
    setExp("");
    setFormData((prev) => ({ ...prev, medicalSettings: "" }));
    setShowOtherExp((prev) => !prev);
  };

  const setOtherExp = (e) => {
    const errorCopy = { ...error };
    errorCopy.medical = "none";
    if (e.target.value.length < 51) {
      setExp(e.target.value);
      setFormData((prev) => ({ ...prev, medicalSettings: e.target.value }));
      if (e.target.value.length > 0) setError(errorCopy);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      medicalSettings:
        e.target.value.trim() === ""
          ? "Error: Medical settings is required"
          : "",
    }));
  };

  useEffect(() => {
    if (type == "edit" && applicantData) {
      setFormData({
        costPerHour: applicantData.costPerHour,
        healthCareLocation: applicantData.healthCareLocation,
        jobTitle: applicantData.jobTitle,
        jobDescription: applicantData.jobDescription,
        contractType: applicantData?.contractType,
        shiftStartTime:
          applicantData?.contractType == "Temporary Position"
            ? moment(applicantData?.shiftStartTime, "h:mm A").toDate()
            : "",
        shiftEndTime:
          applicantData?.contractType == "Temporary Position"
            ? moment(applicantData?.shiftEndTime, "h:mm A").toDate()
            : "",
        weekendAvailiblity: applicantData?.weekendAvailiblity,
        contractLength: {
          duration: applicantData?.contractLength?.duration,
          length: applicantData?.contractLength?.length,
        },
        expectedStartDate: applicantData?.expectedStartDate
          ? new Date(applicantData?.expectedStartDate)
          : "",
        openPositions: applicantData?.openPositions,
        medicalSettings: applicantData?.medicalSettings,
      });
      if (_exps.includes(applicantData?.medicalSettings)) {
        setExp(applicantData?.medicalSettings);
      } else {
        setExp(applicantData?.medicalSettings);
        setShowOtherExp(true);
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        medicalSettings: "",
      }));
    }
  }, []);
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const handleStateSelect = (selectedStateName) => {
    const stateValue = selectedStateName || "";

    setFormData({
      ...formData,
      healthCareLocation: stateValue,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      healthCareLocation:
        stateValue.trim() === ""
          ? "Error: Health Care Location is required"
          : "",
    }));

    setDropdownOpen(false);
  };

  const handleContractTypeChange = (type) => {
    setFormData({
      ...formData,
      contractType: type,
    });
  };
  const handleTimeChange = (type, time) => {
    setFormData({
      ...formData,
      [type]: time,
    });
  };
  const handleWeekendAvailability = (value) => {
    setFormData({
      ...formData,
      weekendAvailiblity: value,
    });
  };
  // const handleContractLength = (value) => {
  //   setFormData({
  //     ...formData,
  //     contractLength: {
  //       ...formData.contractLength,
  //       value: value,
  //     },
  //   });

  // };
  const handleContractLength = (value) => {
    const numVal = value.replace(/\D/g, "");
    setFormData({
      ...formData,
      contractLength: {
        ...formData.contractLength,
        duration: numVal,
      },
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      contractLengths:
        numVal.trim() === "" ? "Error: Contract Length is required" : "",
    }));
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownnew = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownnew.current && !dropdownnew.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add the event listener after the initial render
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleContractLengthUnit = (unit) => {
    setFormData({
      ...formData,
      contractLength: {
        ...formData.contractLength,
        length: unit,
      },
    });
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleCostPerHour = (value) => {
    const numVal = value.replace(/\D/g, "");
    setFormData({
      ...formData,
      costPerHour: numVal,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      costPerHour:
        numVal.trim() === "" ? "Error: cost Per Hour is required" : "",
    }));
  };
  const handlejobTitle = (value) => {
    setFormData({
      ...formData,
      jobTitle: value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      jobTitle: value.trim() === "" ? "Error: Job Title is required" : "",
    }));
  };
  const handleDiscription = (value) => {
    setFormData({
      ...formData,
      jobDescription: value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      jobDescription:
        value.trim() === "" ? "Error: jobDescription Title is required" : "",
    }));
  };

  const handleHiringCount = (value) => {
    const numVal = value.replace(/\D/g, "");
    setFormData({
      ...formData,
      openPositions: numVal,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      openPositions:
        numVal.trim() === "" ? "Error: Hiring Count is required" : "",
    }));
  };

  const [startDate, setStartDate] = useState(null);
  const [nurseLicenceState, setNurseLicenceState] = useState([]);
  const [error, setError] = useState({
    medical: "none",
    nursingLicence: "none",
    licenseData: "none",
  });

  const [errors, setErrors] = useState({
    healthCareLocation: "",
    jobTitle: "",
    jobDescription: "",
    contractType: "",
    timeCommitment: "",
    weekendAvailiblity: "",
    contractLengths: "",
    shiftTime: "",
    ContractLengthUnit: "",
    costPerHour: "",
    expectedStartDate: "",
    hiringCount: "",
  });

  const continueToNext = () => {
    const newErrors = {};
    let noErr = true;

    if (!formData.healthCareLocation) {
      newErrors.healthCareLocation = "Error: Please select a state";
      noErr = false;
    }
    if (!formData.jobTitle) {
      newErrors.jobTitle = "Error: Job Title is required";
      noErr = false;
    }
    if (!formData.medicalSettings) {
      newErrors.medicalSettings = "Error: Medical settings is required";
      noErr = false;
    }
    if (!formData.jobDescription) {
      newErrors.jobDescription = "Error: Job Description is required";
      noErr = false;
    }
    if (
      !formData.contractLength.duration &&
      formData?.contractType == "Temporary Position"
    ) {
      newErrors.contractLengths = "Error: Contract Length is required";
      noErr = false;
    }
    if (
      (!formData.shiftStartTime || !formData.shiftStartTime) &&
      formData?.contractType == "Temporary Position"
    ) {
      newErrors.shiftTime = "Error: Shift time is required";
      noErr = false;
    }
    if (
      !formData.costPerHour &&
      formData?.contractType == "Temporary Position"
    ) {
      newErrors.costPerHour = "Error: Cost Per Hour is required";
      noErr = false;
    }
    if (!formData?.expectedStartDate) {
      newErrors.expectedStartDate = "Error: Expected start date is required";
      noErr = false;
    }
    if (!formData.openPositions) {
      newErrors.openPositions = "Error: Hiring Count is required";
      noErr = false;
    }
    setErrors(newErrors);
    if (noErr) console.log(formData);
    if (noErr) handlesubmitjob();
  };

  const handlesubmitjob = async () => {
    try {
      setShowLoader(true);
      const startDateMoment = moment(formData?.shiftStartTime);
      const endDateMoment = moment(formData?.shiftEndTime);
      const totalHours = endDateMoment.diff(startDateMoment, "hours");
      const employmentType = totalHours >= 8 ? "Full Time" : "Part Time";

      let payload = {};

      if (formData?.contractType == "Permanent Position") {
        payload = {
          healthCareLocation: formData.healthCareLocation,
          jobTitle: formData.jobTitle,
          jobDescription: formData.jobDescription,
          medicalSettings: formData.medicalSettings,
          contractType: formData.contractType,
          timeCommitment: "Full Time",
          expectedStartDate: formData?.expectedStartDate,
          openPositions: formData.openPositions,
          contractLength: {
            duration: 0,
            length: "days",
          },
          weekendAvailiblity: false,
          costPerHour: null,
          shiftStartTime: "",
          shiftEndTime: "",
        };
      } else {
        payload = {
          costPerHour: formData.costPerHour,
          shiftStartTime: moment(formData.shiftStartTime).format("h:mm A"),
          shiftEndTime: moment(formData.shiftEndTime).format("h:mm A"),
          healthCareLocation: formData.healthCareLocation,
          jobTitle: formData.jobTitle,
          jobDescription: formData.jobDescription,
          medicalSettings: formData.medicalSettings,
          contractType: formData.contractType,
          timeCommitment: employmentType,
          weekendAvailiblity: formData.weekendAvailiblity,
          contractLength: {
            duration: formData.contractLength.duration,
            length: formData.contractLength.length,
          },
          expectedStartDate: moment(formData?.expectedStartDate).format(
            "YYYY-MM-DDTHH:mm:ss.SSS"
          ),
          openPositions: formData.openPositions,
        };
      }

      let res;
      if (type == "edit") {
        res = await axiosInstance.put(
          `v1/LHS/job/update/${applicantData?._id}`,
          payload
        );
      } else {
        res = await axiosInstance.post(`v1/LHS/job/add`, payload);
      }
      if (res.status === 200) {
        if (type == "edit") {
          showSuccessToast("Job Updated Successfully");
        } else {
          showSuccessToast("Job Posted Successfully");
        }
      } else {
        console.log("Job update unsuccessful");
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
      closeModal();
    }
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <section className="bg-white">
        <section className="flex flex-col gap-2">
          <div className="font-semibold text-xl">
            Healthcare Institution Location*
          </div>
          {/* <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <div className="input_left_icon">
              <img alt="img" src={place} width="16px" height="20px" />
            </div>
            <select
              className="ssu2_modal3_select"
              name="healthCareLocation"
              onChange={(e) => handleStateSelect(e.target.value)}
              value={formData.healthCareLocation}
            >
              <option value="" disabled customhidden>
                Select
              </option>
              {stateOfUs.map((state) => (
                <option key={state.num} value={state._id}>
                  {state}
                </option>
              ))}
            </select>
          </div> */}
          <div
            className="ssu2_modal1_input ssu2_modal3_selectBox"
            ref={dropdownRef}
          >
            <div className="input_left_icon">
              <img alt="img" src={place} width="16px" height="20px" />
            </div>
            <div
              className=" ssu2_modal3_select "
              onClick={() => setDropdownOpen(!DropdownOpen)}
            >
              {formData.healthCareLocation || "Select"}
            </div>
            {DropdownOpen && (
              <div className="custom-dropdown-contentnew text-black overflow-auto h-96">
                {stateOfUs.map((state) => (
                  <div
                    key={state}
                    className="custom-dropdown-item"
                    onClick={() => handleStateSelect(state)}
                    onMouseEnter={(e) => e.target.classList.add("hover")}
                    onMouseLeave={(e) => e.target.classList.remove("hover")}
                  >
                    {state}
                  </div>
                ))}
              </div>
            )}
          </div>
          {errors.healthCareLocation && (
            <div className="flex items-start text-red-500">
              {errors.healthCareLocation}
            </div>
          )}
        </section>
        <hr className="my-4 -mx-14" />
        <section className="flex flex-col gap-2">
          <div className="font-semibold text-xl">Job Title</div>
          <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <input
              className="ssu2_modal3_select pl-5"
              name="jobTitle"
              placeholder="Job Title"
              type="text"
              value={formData.jobTitle}
              onChange={(e) => handlejobTitle(e.target.value)}
            />
          </div>
          {errors.jobTitle && (
            <div className="flex items-start text-red-500">
              {errors.jobTitle}
            </div>
          )}
        </section>
        <hr className="my-4 -mx-14" />

        <section className="flex flex-col gap-2">
          <div className="font-semibold text-xl">
            Job Description and key Result Areas?*
          </div>
          <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <textarea
              className="ssu2_modal3_select pl-5 rounded-xl focus:rounded-xl"
              placeholder="Describe the responsibilities of this"
              name="jobDescription"
              value={formData.jobDescription}
              onChange={(e) => handleDiscription(e.target.value)}
            />
          </div>
          {errors.jobDescription && (
            <div className="flex items-start text-red-500">
              {errors.jobDescription}
            </div>
          )}
        </section>
        <hr className="my-4 -mx-14" />
        <section className="flex flex-col gap-2">
          <div className="font-semibold text-xl">
            What best describes the Medical Setting for this job?
          </div>
          <div className="flex gap-x-4 w-full flex-wrap">
            {_exps.map((item) => (
              <button
                className={classNames(
                  { selectedExp: item != exp },
                  "ssu1_nearLocItem1"
                )}
                onClick={() => {
                  toggleExp(item);
                  setShowOtherExp(false);
                }}
              >
                {item}
              </button>
            ))}
            <div className="ssu2_otherItem">
              <button
                className={classNames(
                  { selectedExp: !showOtherExp },
                  "ssu1_nearLocItem1"
                )}
                onClick={toggleExps}
              >
                Other
              </button>
            </div>
            {showOtherExp === true ? (
              <input
                type="text"
                value={exp}
                className="ssu2_otherExp ssu2_modal1_input mx-0 mt-2 ssu2_modal3_select d-flex w-full pl-5 max-w-full"
                placeholder="Please specify"
                style={{ display: showOtherExp ? "block" : "none" }}
                onChange={setOtherExp}
                onKeyUp={(e) => {
                  if (e.key === "Enter") continueToNext();
                }}
              />
            ) : (
              ""
            )}
          </div>
          {errors.medicalSettings && (
            <div className="flex items-start text-red-500">
              {errors.medicalSettings}
            </div>
          )}
        </section>
        <hr className="my-4 -mx-14" />
        <section className="flex flex-col gap-2">
          <div className="font-semibold text-xl">
            Please specify the contract type for this job?
          </div>
          <div className="w-full flex gap-4">
            <button
              className={`ssu_sel1 w-1/4 ${
                formData.contractType === "Permanent Position"
                  ? "ssu_sel_selected"
                  : ""
              }`}
              onClick={() => handleContractTypeChange("Permanent Position")}
            >
              Permanent Position
            </button>
            <button
              className={`ssu_sel1 w-1/4 ${
                formData.contractType === "Temporary Position"
                  ? "ssu_sel_selected"
                  : ""
              }`}
              onClick={() => handleContractTypeChange("Temporary Position")}
            >
              Temporary Position
            </button>
          </div>
        </section>
        <hr className="my-4 -mx-14" />

        {formData.contractType === "Permanent Position" ? null : (
          <>
            <section className="flex flex-col gap-2">
              <div className="font-semibold text-xl">
                How much commitment is required from the staff per day?
              </div>
              {errors.contractType && (
                <div className="flex items-start text-red-500">
                  {errors.contractType}
                </div>
              )}
              <div className="flex gap-4 mt-3">
                <div>
                  <DatePicker
                    selected={formData.shiftStartTime}
                    onChange={(date) => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        shiftTime: "",
                      }));
                      handleTimeChange("shiftStartTime", date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    placeholderText="Shift start time"
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    className="px-4 py-2 border !border-[#73C7A9] !text-[#73C7A9] rounded-full"
                  />
                </div>
                <p className="text-xl font-semibold self-end">to</p>
                <div>
                  <DatePicker
                    selected={formData.shiftEndTime}
                    onChange={(date) => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        shiftTime: "",
                      }));
                      handleTimeChange("shiftEndTime", date);
                    }}
                    showTimeSelect
                    placeholderText="Shift end time"
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    className="px-4 py-2 border !border-[#73C7A9] !text-[#73C7A9] rounded-full"
                  />
                </div>
              </div>
              {errors.shiftTime && (
                <div className="flex items-start text-red-500">
                  {errors.shiftTime}
                </div>
              )}
            </section>
            <hr className="my-4 -mx-14" />

            <section className="flex flex-col gap-2">
              <div className="font-semibold text-xl">
                Is weekend availability required from the staff?
              </div>
              <div className="w-full flex gap-4">
                <button
                  className={
                    "ssu_sel1 w-24 " +
                    (formData.weekendAvailiblity ? "ssu_sel_selected" : "")
                  }
                  onClick={() => handleWeekendAvailability(true)}
                >
                  Yes
                </button>
                <button
                  className={
                    "ssu_sel1 w-24 " +
                    (!formData.weekendAvailiblity ? "ssu_sel_selected" : "")
                  }
                  onClick={() => handleWeekendAvailability(false)}
                >
                  No
                </button>
              </div>
            </section>
            <hr className="my-4 -mx-14" />

            <section className="flex flex-col gap-2">
              <div className="font-semibold text-xl">Contract Length*</div>
              <div className="flex gap-4">
                <div className="ssu_selBox1 gap-3">
                  <input
                    className="ssu2_modal3_select pl-5 my-2 md:my-0"
                    placeholder="Contract Length"
                    type="text"
                    value={formData.contractLength?.duration}
                    onChange={(e) => handleContractLength(e.target.value)}
                  />
                </div>
                <div className="ssu_selBox1 gap-3 m-0" ref={dropdownnew}>
                  <div className="custom-dropdown ssu2_modal3_select">
                    <div
                      className=""
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {formData.contractLength.length}{" "}
                      <div className=" flex justify-end mt-[-20px] p-1">
                        <img width="18px" height="18px" alt="img" src={drop} />
                      </div>
                    </div>
                    {isDropdownOpen && (
                      <div className="custom-dropdown-content absolute bg-white shadow-md mt-4 z-40 mr-10 justify-center text-center rounded-lg border-1 border-[#73c7a9]">
                        {/* Dropdown Arrow Icon */}
                        <div
                          onClick={() => handleContractLengthUnit("Days")}
                          style={{
                            backgroundColor:
                              formData.contractLength.length === "Days",
                          }}
                        >
                          Days
                        </div>
                        <div
                          onClick={() => handleContractLengthUnit("Months")}
                          style={{
                            backgroundColor:
                              formData.contractLength.length === "Months",
                          }}
                        >
                          Months
                        </div>
                        <div
                          onClick={() => handleContractLengthUnit("Years")}
                          style={{
                            backgroundColor:
                              formData.contractLength.length === "Years",
                          }}
                        >
                          Years
                        </div>
                      </div>
                    )}
                    <div className="input_left_post_icon">
                      <img
                        width="18px"
                        height="15px"
                        alt="img"
                        src={calendar}
                        style={{ zIndex: "99" }}
                      />
                    </div>
                  </div>
                  <div className="input_left_post_icon">
                    <img
                      width="18px"
                      height="15px"
                      alt="img"
                      src={calendar}
                      style={{ zIndex: "99" }}
                    />
                  </div>
                </div>
              </div>
              {errors.contractLengths && (
                <div className="flex items-start text-red-500">
                  {errors.contractLengths}
                </div>
              )}
            </section>
            <hr className="my-4 -mx-14" />

            <section className="flex flex-col gap-2">
              <div className="font-semibold text-xl">Cost Per Hour*</div>
              <div className="ssu2_modal1_input ssu2_modal3_selectBox mt-3">
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="Cost Per Hour"
                  type="text"
                  value={formData.costPerHour}
                  onChange={(e) => handleCostPerHour(e.target.value)}
                />
              </div>
              {errors.costPerHour && (
                <div className="flex items-start text-red-500">
                  {errors.costPerHour}
                </div>
              )}
            </section>
            <hr className="my-4 -mx-14" />
          </>
        )}

        <section className="flex flex-col gap-2">
          <div className="font-semibold text-xl">Expected start date*</div>
          <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <DatePicker
              selected={formData?.expectedStartDate}
              onChange={(date) => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  expectedStartDate: "",
                }));
                setFormData({
                  ...formData,
                  expectedStartDate: date,
                });
              }}
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              minDate={
                new Date(formData?.expectedStartDate) > new Date()
                  ? new Date()
                  : formData?.expectedStartDate || new Date()
              }
              dateFormat="MM/dd/yyyy"
              placeholderText="Expected start date"
              className="ssu2_modal3_select pl-5"
              style={{ Width: "100%" }}
            />
          </div>
          {errors.expectedStartDate && (
            <div className="flex items-start text-red-500">
              {errors.expectedStartDate}
            </div>
          )}
        </section>
        <hr className="my-4 -mx-14" />
        <section className="flex flex-col gap-2">
          <div className="font-semibold text-xl">
            How many hires do you want for this position*
          </div>
          <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <input
              className="ssu2_modal3_select pl-5"
              placeholder="Hiring count"
              type="text"
              value={formData.openPositions}
              onChange={(e) => handleHiringCount(e.target.value)}
            />
          </div>
          {errors.openPositions && (
            <div className="flex items-start text-red-500">
              {errors.openPositions}
            </div>
          )}
        </section>
        <hr className="my-4 -mx-14" />
        <div className="flex gap-4 justify-end">
          <Button
            onClick={closeModal}
            className="px-7 border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
          >
            Cancel
          </Button>
          <div className="">
            <Button
              className="px-7 rounded-full"
              loading={showLoader}
              onClick={continueToNext}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddEditJob;
