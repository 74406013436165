import React, { useEffect, useRef } from "react";
import RecommendedForYou from "../../../containers/commonComponent/RecommendedForYou";
import StaffMyJob from "./StaffMyJob";

const RecommendedJobs = () => {
  return (
    <>
      <StaffMyJob apiUrl="getRecomendedJobs" />
    </>
  );
};

export default RecommendedJobs;
