import React, { useState } from "react";
import user from "../../../../assets/images/user.png";
// import videoupload from "../../../../assets/images/videoUpload.svg";
import Modal from "../../../../common/modal";
import { useSelector } from "react-redux";

const NewAdvertisementImage = ({ setCreateNew }) => {
  const advertisementData = useSelector(
    (state) => state.checkoutAdvertisement.advertisementData
  );
  const profile = useSelector((state) => state.data.profile);

  return (
    <div className="pb-7">
      <div className="pt-[40px] pb-[60px] px-[30px] flex flex-col gap-[35px]">
        <div className="flex justify-between items-center mx-auto w-full">
          <div className=" flex justify-between items-start">
            <div
              className=" flex items-center gap-[14px]"
              style={{ cursor: "pointer" }}
            >
              <img
                src={profile?.image}
                style={{
                  height: "64px",
                  width: "64px",
                }}
                className="rounded-full"
              />
              <div className="flex flex-col gap-y-[6px]">
                <h1 className="font-medium text-[18px] leading-5 tracking-[0.2px]">
                  {profile?.healthCareInstitution?.name}
                </h1>
                <h1 className="text-[#73C7A9] text-[15px] tracking-[0.2px]">
                  Promoted
                </h1>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={() => setCreateNew("tab_1")}
              className="text-base border-1 border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white w-32 h-11 ml-5"
            >
              Cancel
            </button>
            <button
              onClick={() => setCreateNew("tab_3")}
              className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
            >
              Next
            </button>
          </div>
        </div>
        <div>
          <h1 className="text-[18px] leading-[20px] tracking-[0.2px] text-[#333333]">
            Image
          </h1>

          <div className="mt-3 rounded-lg border border-gray-600 py-8 px-3">
            <div className="rounded-lg w-full flex flex-col items-center justify-center overflow-hidden max-h-[300px]">
              <div className="overflow-hidden flex rounded-xl">
                <img
                  src={advertisementData?.fileUrl}
                  className="w-full h-auto object-cover"
                  alt="Preview"
                />
              </div>
            </div>
          </div>

          <h1 className="text-[18px] leading-[20px] tracking-[0.2px] mt-4 mb-3 text-[#333333] ">
            Description
          </h1>
          <div className="w-full h-[216px] p-4 border text-[16px] text-[#333333] border-gray-400 rounded-lg overflow-y-scroll">
            {advertisementData?.caption}
          </div>
          <h1 className="text-[18px] leading-[20px] tracking-[0.2px] mt-4 mb-3 rounded-lg text-[#333333]">
            Add URL{" "}
          </h1>
          <div className="w-full h-auto p-3 border text-[16px] text-[#333333] border-gray-400 rounded-lg">
            {advertisementData?.url}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewAdvertisementImage;
