import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Modal from "../../common/modal";
import StaffMyJob from "../../theme_2/staffProfile/components/StaffMyJob";

const Jobs = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectDropdown, setSelectDropdown] = useState();
  const [error, setError] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("0");

  return (
    <>
      <StaffMyJob
        apiUrl="getAllJobList"
        loginModal={(e) => setModalOpen(e)}
        setSelectedJobId={setSelectedJobId}
      />
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        className={"md:w-1/3 md:mt-0"}
        style={{ backgroundColor: "red" }}
      >
        <div className="px-2">
          <p className="text-[21px] text-center text-[#333333] mb-5">
            To apply for the job, please log in or sign up. Thank you!
          </p>

          <div className="text-center">
            <button
              onClick={() => {
                history.push({
                  pathname: "/login",
                  search: selectedJobId,
                });
                setModalOpen(false);
              }}
              className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5"
            >
              Sign up
            </button>
            <button
              className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5 hover:!text-[#73C7A9]"
              onClick={() => {
                setModalOpen(false);
                history.push({
                  pathname: "/login",
                  search: selectedJobId,
                });
              }}
            >
              Log in
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Jobs;
