import React, { useState, useEffect } from "react";
import "./components.css";
import edit_note from "../../../assets/images/edit_note.svg";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import { callApi } from "../../../../redux/action/action";
import { axiosInstance } from "../../../../config/axiosConfig";

const ToggleExpItem = (props) => {
  const toggleExp = () => {
    props.toggleExp(props.id);
  };

  var className =
    props.selectedExp !== props.exp || props.showOtherExp === "none"
      ? "ssu1_locItem ssu1_nearLocItem"
      : "ssu1_locItem";
  if (props.exp === "Other" && props.display === "none")
    className = "ssu1_locItem ssu2_otherExp_item";
  else if (props.exp === "Other")
    className = "ssu1_locItem ssu1_nearLocItem ssu2_otherExp_item";
  return (
    <button className={className} onClick={toggleExp}>
      {props.exp}
    </button>
  );
};

const MedicalSettings = (props) => {
  const [experiencedIn, setExperiencedIn] = useState("");
  const [newExperiencedIn, setNewExperiencedIn] = useState("");
  const [showModal, setShowModal] = useState("none");
  const [error, setError] = useState("none");
  const [showOtherExp, setShowOtherExp] = useState("block");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setExperiencedIn(props.experiencedIn);
    componentDidMount();
  }, [props.experiencedIn]);

  useEffect(() => {
    setExperiencedIn(props.experiencedIn);
    // setCurPos('medicalSettings');
  }, []);

  useEffect(async () => {
    let typeData = localStorage.getItem("type");
    if (typeData === "admin") {
      setIsAdmin(true);
    }
    if (props.admin) {
      var id = window.localStorage.getItem("Staffid");
    } else {
      var id = window.localStorage.getItem("_id");
    }
    var Authorization = "Bearer ".concat(window.localStorage.getItem("token"));
    var res = await axiosInstance.get(`v1/LHS/staff/getById/${id}`);
    if (res.data) {
      setExperiencedIn(res.data.medicalSettings);
    }
  }, []);

  const showModal1 = () => {
    setShowModal("block");
    setNewExperiencedIn(experiencedIn);
  };

  const toggleExp = (newExperiencedIn) => {
    if (newExperiencedIn === newExperiencedIn) {
      setNewExperiencedIn("");
    } else {
      setNewExperiencedIn(newExperiencedIn);
      setError("none");
    }
    setShowOtherExp("block");
  };

  const showOtherExp1 = () => {
    var temp = showOtherExp === "none" ? "block" : "none";
    setNewExperiencedIn("");
    setShowOtherExp(temp);
  };

  const setOtherExp = (e) => {
    if (e.target.value.length < 51) {
      setNewExperiencedIn(e.target.value);
      if (e.target.value.length > 0) setError("none");
    }
  };

  const handleSave = async () => {
    if (newExperiencedIn === "") {
      setError("block");
    } else {
      var data = {
        medicalSettings: newExperiencedIn,
      };
      var token = "Bearer " + localStorage.getItem("token");
      var id = window.localStorage.getItem("_id");
      var res = await callApi("PUT", `v1/LHS/staff/update/${id}`, token, data);
      if (res.Message === "Staff Updated Successfully") {
        componentDidMount();
        closeModal();
      }
    }
  };

  const closeModal = () => {
    setShowModal("none");
    setNewExperiencedIn("");
    setError("none");
  };

  return (
    <div className="t2_sp_work">
      <div className="t2_sp_work_title">
        Medical settings
        {isAdmin ? (
          ""
        ) : (
          <img
            alt="icon.svg"
            src={edit_note}
            width="20px"
            style={{ marginTop: 4, float: "right", cursor: "pointer" }}
            onClick={showModal}
          />
        )}
      </div>
      <div className="row t2_sp_work_container">
        <button className="t2_sp_work_item">{experiencedIn}</button>
      </div>
      <div className="w3-modal " style={{ display: showModal }}>
        <div className="w3-modal-content ssu2_modal1 ">
          <div className="w3-container ">
            <div className="ssu2_modal1_text1 ">Edit Medical Settings</div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="row ssu1_locs">
              {_exps.map((item) => {
                return (
                  <ToggleExpItem
                    key={item}
                    exp={item}
                    id={item}
                    toggleExp={toggleExp}
                    display="block"
                    selectedExp={newExperiencedIn}
                  />
                );
              })}
              <div className="ssu2_otherItem">
                <ToggleExpItem
                  exp="Specialized Outpatient Surgery Center"
                  id="Specialized Outpatient Surgery Center"
                  toggleExp={toggleExp}
                  display="block"
                  selectedExp={newExperiencedIn}
                />
                <ToggleExpItem
                  exp="Other"
                  toggleExp={showOtherExp}
                  selectedExp={newExperiencedIn}
                  display={showOtherExp}
                />
              </div>
            </div>
            <input
              type="text"
              value={newExperiencedIn}
              className="ssu2_otherExp"
              placeholder="Please specify"
              style={{ display: showOtherExp === "none" ? "block" : "none" }}
              onChange={setOtherExp}
              onKeyUp={(e) => {
                if (e.key === "Enter") handleSave();
              }}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ErrorState
                show={error}
                name="Please Enter Your Medical Settings."
              />
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="row ssu_bottom">
              <button className="ssu2_modal1_button1" onClick={handleSave}>
                {" "}
                SAVE{" "}
              </button>
              <button
                className="border !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                onClick={closeModal}
              >
                {" "}
                CANCEL{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalSettings;
