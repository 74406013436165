import React, { useState, useEffect } from "react";
import home from "../../assets/images/home.svg";
import place from "../../assets/images/place.svg";
import ErrorState from "../staffSignUp/components/errorState";
import "./hospSignUp.css";
import { callApi } from "../../../redux/action/action";

const Location = (props) => {
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [error, setError] = useState({
    street: "block",
    zipCode: "block",
  });
  const [showZipErr, setShowZipErr] = useState(false);
  const [showStreetErr, setShowStreetErr] = useState(false);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    const { zipCode, street, state, city } = props;
    initState(street, zipCode, state, city);
    setStreet(street);
    setState(state);
    setCity(city);
    setZipCode(zipCode);

    if (zipCode) {
      setShowZipErr(false);
    } else {
      setShowZipErr(true);
    }
    if (street) setShowStreetErr(false);
  }, [props]);

  useEffect(() => {
    if (zipCode && zipCode?.length == 5) {
      window.localStorage.setItem("zip_code", zipCode);
      window.localStorage.setItem("hoscity", city);
      window.localStorage.setItem("hosstate", state);
      window.localStorage.setItem("hosstreet_address", street);
    }
  }, [zipCode, state, city]);

  useEffect(() => {
    if (props.showErr === true) {
      setShowZipErr(true);
      setShowStreetErr(true);
    }
  }, [props.showErr]);

  const initState = async () => {
    var state = "";
    var city = "";
    var zipError = "block";

    var zip = zipCode + "";
    if (zip.length === 5) {
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      var res = await callApi("POST", "v1/LHS/other/getCityState", null, {
        zip: zip,
      });
      if (res.result === "OK") {
        city = res.data.city;
        state = res.data.state;
        zipError = "none";
      }
    }
    var error = { ...error };
    error.zipCode = zipError;

    if (street === "") {
      error.street = "block";
    } else {
      error.street = "none";
    }

    setStreet(street);
    setState(state);
    setCity(city);
    setZipCode(zipCode);
    setError(error);
  };

  const setStreetValue = (e) => {
    if (e.target.value.length < 201) {
      var newError = { ...error };
      if (e.target.value === "") {
        newError.street = "block";
      } else {
        newError.street = "none";
      }
      setStreet(e.target.value);
      setShowStreetErr(true);
      setError(newError);
    }
  };

  const setStreetErr = () => {
    if (!street) {
      props.setLocError(true);
    } else {
      props.setLocError(false);
    }
    setShowStreetErr(true);
    window.localStorage.setItem("zip_code", zipCode);
    window.localStorage.setItem("hoscity", city);
    window.localStorage.setItem("hosstate", state);
    window.localStorage.setItem("hosstreet_address", street);
  };
  const setZipCodeValue = async (e) => {
    const newZipCode = e.target.value;
    setZipCode(newZipCode);
    setShowZipErr(true);

    if (newZipCode.length === 5) {
      const res = await callApi("POST", "v1/LHS/other/getCityState", null, {
        zip: newZipCode,
      });

      if (res.result === "OK") {
        const { city, state } = res.data;
        setCity(city);
        setState(state);
        setError((prevError) => ({ ...prevError, zipCode: "none" }));
      } else {
        setError((prevError) => ({ ...prevError, zipCode: "block" }));
      }
    } else {
      setError((prevError) => ({ ...prevError, zipCode: "block" }));
      window.localStorage.removeItem("hoscity");
      window.localStorage.removeItem("hosstate");
      window.localStorage.removeItem("hosstreet_address");
      setCity("");
      setState("");
    }
  };

  const needToSetZip = () => {
    setShowZipErr(true);
  };

  const handleSave = async () => {
    setZipCode(zipCode);
    setStreet(street);
    setState(state);
    setCity(city);
  };

  return (
    <div>
      <div className="ssu2_modal1_input">
        <div
          className="ssu2_modal1_input  ssu2_modal3_selectBox"
          style={{ marginBottom: "10px" }}
        >
          <div className="input_left_icon">
            <img width="18px" height="15px" alt="img" src={place} />
          </div>
          <input
            className="ssu2_modal3_select"
            placeholder="Zip code"
            type="text"
            value={zipCode}
            name="zipCode"
            onChange={setZipCodeValue}
            onBlur={setZipCodeValue}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleSave();
            }}
          />
        </div>
        <ErrorState
          show={showZipErr ? error.zipCode : "none"}
          name="Valid zip code is required."
        />
      </div>
      <div className="ssu2_modal1_input">
        <div className="ssu2_modal3_selectBox">
          <div className="input_left_icon">
            <img width="18px" height="15px" alt="img" src={home} />
          </div>
          <input
            className="ssu2_modal3_select"
            placeholder="Street Address"
            type="text"
            value={street}
            name="street"
            onBlur={setStreetErr}
            onChange={setStreetValue}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleSave();
            }}
          />
        </div>
        <ErrorState
          show={showStreetErr ? error.street : "none"}
          name="Street address is required."
        />
      </div>
      <div className="ssu2_modal1_input">
        <div className="ssu2_modal3_selectBox" onClick={needToSetZip}>
          <div className="input_left_icon">
            <img
              alt="img"
              src={place}
              width="18px"
              height="15px"
              style={{ marginTop: "-3px" }}
            />
          </div>
          <input
            className="ssu2_modal3_select"
            placeholder="State"
            disabled={true}
            type="text"
            value={state}
          />
        </div>
      </div>
      <div className="ssu2_modal1_input">
        <div className="ssu2_modal3_selectBox" onClick={needToSetZip}>
          <div className="input_left_icon">
            <img width="18px" height="15px" alt="img" src={place} />
          </div>
          <input
            className="ssu2_modal3_select"
            placeholder="City"
            disabled={true}
            type="text"
            value={city}
          />
        </div>
      </div>
    </div>
  );
};

export default Location;
