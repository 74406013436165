import React, { useState, useEffect } from "react";
import { Tab, Tabs, Modal as Modal1, Spinner } from "react-bootstrap";
import avatar from "../../../components/assets/images/avatar.png";
import starIcon from "../../../components/assets/images/star_icon.svg";
import starIconGray from "../../../components/assets/images/rating_gray.svg";
import fllipsImag from "../../../components/assets/images/UserEllipse.png";
import StarIcon from "@material-ui/icons/Star";
import Loader from "../../../components/modals/Loader";
import { useSelector } from "react-redux";
import Alert from "../../../components/modals/alert";
import { axiosInstance } from "../../../config/axiosConfig";
import Nav from "../../../components/theme_1/Nav";

import RecommendedForYou from "../../../components/containers/commonComponent/RecommendedForYou";
import RightSideBar from "../../../components/containers/commonComponent/rightSideBar";
import SearchFilter from "../../../components/containers/commonComponent/SearchFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import JobCard from "../../../components/containers/commonComponent/JobCard";
import MyJobDetails from "../../../components/theme_2/staffProfile/components/MyJobDetails";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../components/common/toastMeassage/ToastMeassage";
import Modal from "../../../components/common/modal";
import Button from "../../../components/ui/Button";

export const AddReasonModal = (props) => {
  const [showErr, setShowErr] = useState(false);
  const profile = useSelector((state) => state.data.profile);
  return (
    <Modal1
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName=""
    >
      <Modal1.Body>
        <div className="row m-0">
          <div className="col-12 p-lg-4 p-1">
            <div className="row m-0">
              <div className="flex gap-3">
                {props.keyValue ? (
                  <div className="w-16 h-16 rounded-full overflow-hidden">
                    <img
                      alt="avatar.png"
                      src={props?.userData?.hospital?.image || avatar}
                      className="object-cover w-full h-full"
                    />
                  </div>
                ) : (
                  <img
                    alt="avatar.png"
                    src={props?.userData?.image || avatar}
                    width="70px"
                    height="70px"
                    className="job_image"
                    style={{ borderRadius: "50%" }}
                  />
                )}

                <div className="ms-4">
                  {props.keyValue ? (
                    <p className="mb-0 username ">
                      {typeof props.userData.hospital === "object"
                        ? props.userData.hospital.healthCareInstitution.name
                        : props.userData.hospitalName}
                    </p>
                  ) : (
                    <p className="mb-0 username ">
                      {typeof props.userData.hospital === "object"
                        ? props.userData.hospital.name
                        : props.userData.name}
                    </p>
                  )}

                  {/* <p className="mb-0 role">{props.userData ? props.userData.jobTitle : "Adventist Health"}</p> */}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center my-4">
              <p className="mb-0 ">
                {props.applicationType === "withdrawn"
                  ? "Reason for withdraw application"
                  : "Reason for rejecting"}
              </p>
            </div>

            <div className="row m-0 rate_reviw_card_comment mt-3">
              <textarea
                id="w3review"
                name="w3review"
                rows="4"
                cols="50"
                placeholder="Write your comment here"
                style={{
                  borderColor:
                    showErr && props.rejectReason === "" ? "red" : "",
                }}
                onChange={props.setRejectReason}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="row m-0 justify-content-center align-items-center rate_reviw_card_footer mt-3">
          <div className="flex gap-4 place-content-end">
            <Button
              onClick={props?.onHide}
              className="border !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
            >
              CANCEL
            </Button>
            <Button
              loading={props?.loader}
              onClick={() => {
                setShowErr(true);
                if (props.rejectReason) {
                  props.rejectJobOffer(
                    props?.userData?._id,
                    props?.userData?.buttonIndex
                  );
                  setShowErr(false);
                }
              }}
              className="rounded-full bg-[#73C7A9] text-white"
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </Modal1.Body>
    </Modal1>
  );
};

export const AddReviewModal = (props) => {
  const [ratings, setRatings] = useState(1);
  const [review, setReview] = useState("");

  useEffect(() => {
    setReview("");
    setRatings(1);
  }, [props]);

  return (
    <Modal {...props}>
      <div className="">
        <div className="p-0">
          <div className="col-6 d-flex align-items-center p-0">
            {props.type ? (
              <div className="w-16 h-16 rounded-full overflow-hidden">
                <img
                  alt="avatar.png"
                  src={props?.data?.image || avatar}
                  className="object-cover w-full h-full"
                />
              </div>
            ) : (
              <div className="w-16 h-16 rounded-full overflow-hidden">
                <img
                  alt="avatar.png"
                  src={props?.data?.hospital?.image || avatar}
                  className="object-cover w-full h-full"
                />
              </div>
            )}

            <div className="ms-4">
              {props?.type ? (
                <p className="mb-0 username">{props?.data?.name}</p>
              ) : (
                <p className="mb-0 username">
                  {props?.data?.hospital?.healthCareInstitution &&
                  props?.data?.hospital?.healthCareInstitution?.name
                    ? props?.data?.hospital?.healthCareInstitution?.name
                    : ""}
                </p>
              )}

              {/* <p className="mb-0 role">Adventist Health</p> */}
            </div>
          </div>
          <div className="mt-3">
            {[1, 2, 3, 4, 5].map((ind) => (
              <StarIcon
                key={ind}
                className={
                  ind <= ratings ? "text-yellow-500" : "text-[#e3e3e3]"
                }
                onClick={() => setRatings(ind + 0)}
              />
            ))}
          </div>

          <div className="row m-0 rate_reviw_card_comment mt-3">
            <textarea
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              placeholder="Write your comment here"
              value={review}
              style={{
                borderColor: review === "" ? "red" : "",
              }}
              onChange={(e) => setReview(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row m-0 justify-content-center align-items-center rate_reviw_card_footer mt-3">
        <div className="flex gap-4 place-content-end">
          <Button
            onClick={props?.onClose}
            className="border !border-[#73C7A9] !text-[#73C7A9]  rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
          >
            CANCEL
          </Button>
          <Button
            loading={props.loader}
            onClick={() => {
              if (review) {
                props.postReview(review, ratings);
              }
            }}
            className="rounded-full bg-[#73C7A9] text-white"
          >
            POST
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const MyJob = () => {
  const [showAddReviewModal, setShowAddReviewModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buffer, setBuffer] = useState(false);
  const [currentSelectedItem, setCurrentSelectedItem] = useState({});
  const [reasonData, setReasonData] = useState({});
  const [searchFilter, setSearchFilter] = useState({
    sort: "desc",
    search: "",
  });
  const [clearFilter, setClearFilter] = useState(false);
  const [onGoingJobs, setOnGoingJobs] = useState([]);
  const [allOngoingJobs, setAllOnGoingJobs] = useState([]);
  const [allAppliedJobs, setAllAppliedJobs] = useState([]);
  const [allRejectedJobs, setAllRejectedJobs] = useState([]);
  const [allWithdrawJobs, setAllWithdrawnJobs] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [applicationType, setApplicationType] = useState("");
  const [rejectReason, setrejectReason] = useState("");
  const [page, setPage] = useState(0);
  const [jobDetailsPage, setJobDetailsPage] = useState(false);
  const [windowScrollY, setWindowScrollY] = useState(0);
  const [jobDetails, setJobDetails] = useState({
    id: "",
    index: 0,
    pageType: "",
  });
  const [hasMore, setHasMore] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [activeKey, setActiveKey] = useState("accepted_jobs");
  const [showAlert, setShowAlert] = useState("none");
  const profile = useSelector((state) => state.data.profile);

  const acceptJobOffer = async (acceptId, index) => {
    try {
      const jobCardIndex = jobDetails?.index ? jobDetails?.index : index;
      setLoader(jobCardIndex);
      let url = `v1/LHS/staff/accept/${acceptId}`;
      const responseData = await axiosInstance.post(url);
      if (responseData?.data?.status === 200) {
        const updatedOngoingJobs = [...allOngoingJobs];
        updatedOngoingJobs.splice(jobCardIndex, 1);
        setAllOnGoingJobs(updatedOngoingJobs);
        showSuccessToast(responseData?.data?.Message);
        setJobDetailsPage(false);
        setApplicationType("");
        // GetAllJobsOffers(searchFilter);
      }
    } catch (err) {
      showErrorToast(err.Message);
    } finally {
      setLoader(false);
    }
  };

  const handleSelectSubJobApp = (key) => {
    setClearFilter(true);
    setSearchFilter({});
    setPage(0);
    setHasMore(false);
    setActiveKey(key);
    if (key == "accepted_jobs") {
      GetAllOngoingJobs({ nextPage: 0, filter: { search: "" } });
    } else if (key === "applied_jobs") {
      GetAllAppliedJobs({ nextPage: 0, filter: { search: "" } });
    } else if (key === "reject_offer") {
      GetAllRejectedJobs({ nextPage: 0, filter: { search: "" } });
    } else if (key === "withdrawn_offer") {
      GetAllWithdrawnJobs({ nextPage: 0, filter: { search: "" } });
    } else if (key === "job_offer") {
      GetAllJobsOffers({ nextPage: 0, filter: { search: "" } });
    }
  };

  const rejectJobOffer = async (rejectedId, index) => {
    const jobCardIndex = jobDetails?.index ? jobDetails?.index : index;
    try {
      setLoader(true);
      let obj = {
        rejectionReason: rejectReason,
      };
      if (applicationType !== "withdrawn") {
        obj.isJobRejected = true;
        (obj.status = "Rejected"), (obj.rejectedBy = "staff");
      }
      let url =
        applicationType === "withdrawn"
          ? `v1/LHS/staff/withdraw/${rejectedId}`
          : `v1/LHS/staff/reject/${rejectedId}`;
      const responseData = await axiosInstance.post(url, obj);
      setShowReasonModal(false);
      if (responseData?.data?.status === 200) {
        if (applicationType == "withdrawn") {
          const updatedAppliedJobs = [...allAppliedJobs];
          updatedAppliedJobs.splice(jobCardIndex, 1);
          setAllAppliedJobs(updatedAppliedJobs);
          showSuccessToast(responseData?.data?.Message);
          setJobDetailsPage(false);
        } else {
          const updatedOngoingJobs = [...allOngoingJobs];
          updatedOngoingJobs.splice(jobCardIndex, 1);
          setAllOnGoingJobs(updatedOngoingJobs);
          showSuccessToast(responseData?.data?.Message);
          setJobDetailsPage(false);
        }
        setApplicationType("");
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const GetAllOngoingJobs = async ({ nextPage, filter } = {}) => {
    try {
      const newPage = nextPage == 0 ? nextPage * 10 : page * 10;
      if (newPage == 0) setBuffer(true);
      let url = `v1/LHS/staff/inProgress?skip=${newPage}&${new URLSearchParams(
        filter || searchFilter
      )}`;
      const data = await axiosInstance.get(url);
      if (data.data?.data?.count > 0) {
        setOnGoingJobs((prevData) => {
          if (page > 0 && newPage > 0) {
            return [...prevData, ...data?.data?.data?.data];
          } else {
            return [...data?.data?.data?.data];
          }
        });
        const totalPage = (newPage + 1) * 10;
        setHasMore(data?.data?.data?.count > totalPage);
        if (nextPage == 0) {
          setPage(1);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
      } else {
        setOnGoingJobs([]);
        setPage(1);
        setHasMore(false);
      }
    } catch (err) {
    } finally {
      setBuffer(false);
    }
  };

  let Iran = false;
  useEffect(() => {
    if (!Iran) {
      GetAllOngoingJobs();
      Iran = true;
    }
  }, []);

  const GetAllCompletedJobs = async () => {
    try {
      let url = `v1/LHS/staff/MyCompletedJobsByHospital`;
      const data = await axiosInstance.get(url);
      if (data?.data?.status == 200) {
        let completedtotalCount = data?.data?.count;
        let completedpage = Math.ceil(completedtotalCount / 10);
        setCompletedTotalPage(completedpage);
        // setCompletedJobs(data?.data?.data);
      } else {
        // setCompletedJobs([]);
      }
    } catch (err) {
      if (err.response?.Message == " No Completed Job Found...") {
        setErrMsg(err.response.data?.Message);
      }
    }
  };

  const GetAllJobsOffers = async ({ nextPage, filter } = {}) => {
    try {
      const newPage = nextPage == 0 ? nextPage * 10 : page * 10;
      if (newPage == 0) setBuffer(true);
      let url = `v1/LHS/staff/getMyJobsOffersByJobTitle?skip=${newPage}&${new URLSearchParams(
        filter || searchFilter
      )}`;
      const data = await axiosInstance.get(url);
      if (data.data?.status == 200) {
        setAllOnGoingJobs((prevData) => {
          if (page > 0 && newPage > 0) {
            return [...prevData, ...data?.data?.data?.data];
          } else {
            return [...data?.data?.data?.data];
          }
        });
        const totalPage = (newPage + 1) * 10;
        setHasMore(data?.data?.data?.count > totalPage);
        if (nextPage == 0) {
          setPage(1);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (err) {
      setAllOnGoingJobs([]);
      setPage(1);
      setHasMore(false);
    } finally {
      setBuffer(false);
    }
  };

  const GetAllAppliedJobs = async ({ nextPage, filter } = {}) => {
    const newPage = nextPage == 0 ? nextPage * 10 : page * 10;
    if (newPage == 0) setBuffer(true);
    let url = `v1/LHS/staff/myApplicationsByTitle?skip=${newPage}&${new URLSearchParams(
      filter || searchFilter
    )}`;
    try {
      const data = await axiosInstance.get(url);
      if (data.data) {
        setAllAppliedJobs((prevData) => {
          if (page > 0 && newPage > 0) {
            return [...prevData, ...data?.data?.data?.data];
          } else {
            return [...data?.data?.data?.data];
          }
        });
        const totalPage = (newPage + 1) * 10;
        setHasMore(data?.data?.data?.count > totalPage);
        if (nextPage == 0) {
          setPage(1);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (error) {
      setAllAppliedJobs([]);
      setPage(1);
      setHasMore(false);
    } finally {
      setBuffer(false);
    }
  };

  const postReview = async (review, reviewCount) => {
    if (!loader) {
      let obj = {
        ratingTo: currentSelectedItem?.hospital?._id,
        rating: reviewCount,
        review: review,
      };
      let url = `v1/LHS/rating/save`;
      var res = await axiosInstance.post(url, obj);
      if (res?.data?.status === 200) {
        GetAllOngoingJobs({ nextPage: 0 });
        setPage(0);
        showSuccessToast("Review saved successfully");
        // GetAllCompletedJobs(searchFilter);
        setShowAddReviewModal(false);
      }
    }
  };

  const GetAllRejectedJobs = async ({ nextPage, filter } = {}) => {
    try {
      const newPage = nextPage == 0 ? nextPage * 10 : page * 10;
      if (newPage == 0) setBuffer(true);
      let url = `v1/LHS/staff/myRejectedApplications?skip=${newPage}&${new URLSearchParams(
        filter || searchFilter
      )}`;
      const data = await axiosInstance.get(url);
      if (data.data) {
        setAllRejectedJobs((prevData) => {
          if (page > 0 && newPage > 0) {
            return [...prevData, ...data?.data?.data?.data];
          } else {
            return [...data?.data?.data?.data];
          }
        });
        const totalPage = (newPage + 1) * 10;
        setHasMore(data?.data?.data?.count > totalPage);
        if (nextPage == 0) {
          setPage(1);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (err) {
      setAllRejectedJobs([]);
      setPage(1);
      setHasMore(false);
    } finally {
      setBuffer(false);
    }
  };

  const GetAllWithdrawnJobs = async ({ nextPage, filter } = {}) => {
    try {
      const newPage = nextPage == 0 ? nextPage * 10 : page * 10;
      if (newPage == 0) setBuffer(true);
      let url = `v1/LHS/staff/myWithdrawnApplications?skip=${newPage}&${new URLSearchParams(
        filter || searchFilter
      )}`;
      const data = await axiosInstance.get(url);
      if (data.data) {
        setAllWithdrawnJobs((prevData) => {
          if (page > 0 && newPage > 0) {
            return [...prevData, ...data?.data?.data?.data];
          } else {
            return [...data?.data?.data?.data];
          }
        });
        const totalPage = (newPage + 1) * 10;
        setHasMore(data?.data?.data?.count > totalPage);
        if (nextPage == 0) {
          setPage(1);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (err) {
      setAllWithdrawnJobs([]);
      setPage(1);
      setHasMore(false);
    } finally {
      setBuffer(false);
    }
  };

  // const GetAllInterestJobs = async ({ nextPage } = {}) => {
  //   let url = `v1/LHS/staff/myIntrest?skip=${
  //     nextPage*10 || page * 10
  //   }&skip=0&${new URLSearchParams(searchFilter)}`;
  //   const data = await axiosInstance.get(url);
  //   if (data.data) {
  //     // setAllInterestJobs(data.data[0].jobIntrest);
  //   } else {
  //     // setAllInterestJobs([]);
  //   }
  // };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilterChange = (filterData) => {
    let filter = {
      sort: filterData?.sort == "latestFirst" ? "desc" : "asc",
      search: filterData?.search == null ? "" : filterData?.search,
    };
    setSearchFilter(filter);
    setPage(0);
    setHasMore(false);
    if (activeKey == "accepted_jobs")
      GetAllOngoingJobs({ nextPage: 0, filter });
    if (activeKey == "applied_jobs") GetAllAppliedJobs({ nextPage: 0, filter });
    if (activeKey == "reject_offer")
      GetAllRejectedJobs({ nextPage: 0, filter });
    if (activeKey == "withdrawn_offer")
      GetAllWithdrawnJobs({ nextPage: 0, filter });
    if (activeKey == "job_offer") GetAllJobsOffers({ nextPage: 0, filter });
  };

  const handleButtonAction = ({ index, jobCardData, type }) => {
    if (type == "Accept") {
      setApplicationType("accept");
      acceptJobOffer(jobCardData?.data?._id, index);
    } else if (type == "Reject") {
      setApplicationType("reject");
      setReasonData({ ...jobCardData?.data, buttonIndex: index });
      setShowReasonModal(true);
    } else if (type == "acceptWithdrawn") {
      setReasonData({ ...jobCardData?.data, buttonIndex: index });
      setApplicationType("withdrawn");
      setShowReasonModal(true);
    }
  };

  useEffect(() => {
    if (!jobDetailsPage) {
      window.scrollTo(0, windowScrollY);
      setWindowScrollY(0);
      setJobDetails({});
    } else {
      window.scrollTo(0, 0);
    }
  }, [jobDetailsPage]);

  return (
    <>
      <Nav />
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]">
          <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />

          <div className="flex flex-col !gap-3 md:px-5 lg:px-0 w-full xl:w-[52%] xl:max-w-[47.6%] lg:w-[48%] lg:max-w-[47.6%]">
            {jobDetailsPage ? (
              <MyJobDetails
                buttonAction={handleButtonAction}
                detailsData={jobDetails}
                loading={loader}
                closeJobDetails={() => setJobDetailsPage(false)}
              />
            ) : (
              <div className="flex gap-3 flex-col relative">
                <SearchFilter
                  hideRecentHistory={true}
                  placeholder={"Search job title"}
                  hideFilters={[1, 2, 3, 4, 7, 8]}
                  onFilterChange={handleFilterChange}
                  clearFilter={clearFilter}
                  setClearFilterFalse={() => setClearFilter(false)}
                />
                <div className="flex h-16 bg-white rounded-t-lg font-medium text-xl tracking-[0.2px] justify-between  ">
                  <div
                    className={`cursor-pointer flex items-center w-2/4 justify-center text-[#333333b3] font-[Avenir] ${
                      activeTab === "tab1"
                        ? "!text-[#73C7A9] border-b-2 !border-[#73C7A9]"
                        : "mb-1"
                    }`}
                    onClick={() => {
                      handleTabClick("tab1");
                      setPage(0);
                      setClearFilter(true);
                      handleSelectSubJobApp("accepted_jobs");
                    }}
                  >
                    Accepted jobs
                  </div>
                  <div
                    className={`cursor-pointer flex items-center w-2/4 justify-center text-[#333333b3] font-[Avenir] ${
                      activeTab === "tab2"
                        ? "!text-[#73C7A9] border-b-2 !border-[#73C7A9]"
                        : "mb-1"
                    }`}
                    onClick={() => {
                      handleTabClick("tab2");
                      setPage(0);
                      setClearFilter(true);
                      handleSelectSubJobApp("applied_jobs");
                    }}
                  >
                    Job Applications
                  </div>
                </div>
                <div className="">
                  <div className="my_job_background ">
                    <div className="m-auto justify-center px-0 container application-sub-tabs gap-[18px]">
                      {activeTab === "tab1" && (
                        <>
                          {buffer ? (
                            <Loader />
                          ) : (
                            <>
                              {onGoingJobs?.length > 0 ? (
                                <>
                                  <InfiniteScroll
                                    dataLength={onGoingJobs?.length}
                                    next={GetAllOngoingJobs}
                                    hasMore={hasMore || false}
                                    loader={<Loader />}
                                    className="flex flex-col gap-[18px]"
                                    endMessage={<p>No more items to load</p>}
                                  >
                                    {onGoingJobs
                                      ?.filter((v) => v.job)
                                      .map((v, i) => {
                                        return (
                                          <JobCard
                                            index={i}
                                            jobCardData={{ data: v?.job }}
                                            pathName={() => {
                                              setJobDetails({
                                                id: v?.job?._id,
                                                data: v?.job,
                                                index: i,
                                                pageType: "",
                                                expectedDatePlaceholder:
                                                  "Start Date",
                                                diffData: v,
                                              });
                                              setJobDetailsPage(true);
                                              setWindowScrollY(window.scrollY);
                                            }}
                                            userImage={fllipsImag}
                                            name={
                                              v?.job?.hospital
                                                ?.healthCareInstitution?.name
                                            }
                                            location={
                                              v?.job?.healthCareLocation
                                            }
                                            ratings={v?.job?.hospital?.ratings?.ratingSum.toFixed(
                                              1
                                            )}
                                            totalRatings={
                                              v?.job?.hospital?.ratings
                                                ?.totalRatings
                                            }
                                            seeAll={v?.job?.hospital?._id}
                                            jobTitle={v?.job.jobTitle}
                                            medicalSettings={
                                              v?.job?.medicalSettings
                                            }
                                            startDate={v?.startDate}
                                            expectedDatePlaceholder="Start Date"
                                            endDate={v?.endDate}
                                            costPerHour={v?.job?.costPerHour}
                                            contractType={v?.job?.contractType}
                                            contractLength={
                                              v?.job?.contractLength
                                            }
                                            timeCommitment={
                                              v?.job?.timeCommitment
                                            }
                                            jobDescription={
                                              v?.job?.jobDescription
                                            }
                                            weekendAvailiblity={
                                              v?.job?.weekendAvailiblity
                                            }
                                            addReview={() => {
                                              setCurrentSelectedItem(v?.job);
                                              setShowAddReviewModal(true);
                                            }}
                                            buttonType={""}
                                          />
                                        );
                                      })}
                                  </InfiniteScroll>
                                </>
                              ) : (
                                <div className="text-center mt-3">
                                  <p>No record found</p>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {activeTab === "tab2" && (
                        <Tabs
                          id="uncontrolled-tab-example"
                          className="  flex flex-nowrap whitespace-nowrap overflow-y-hidden w-full mb-[7px]"
                          onSelect={handleSelectSubJobApp}
                          activeKey={activeKey}
                        >
                          <Tab
                            eventKey="applied_jobs"
                            title="Applications in Progress"
                            className="first-Tab"
                          >
                            {/* <JobHeader /> */}
                            {buffer ? (
                              <Loader style={{ marginTop: "45px" }} />
                            ) : (
                              <>
                                {allAppliedJobs?.length > 0 ? (
                                  <>
                                    {activeKey == "applied_jobs" && (
                                      <InfiniteScroll
                                        dataLength={allAppliedJobs?.length}
                                        next={GetAllAppliedJobs}
                                        hasMore={hasMore || false}
                                        loader={<Loader />}
                                        className="flex flex-col gap-[18px]"
                                        endMessage={
                                          <p>No more items to load</p>
                                        }
                                      >
                                        {allAppliedJobs?.map((v, i) => {
                                          return (
                                            <>
                                              <JobCard
                                                index={i}
                                                jobCardData={{ data: v }}
                                                pathName={() => {
                                                  setJobDetails({
                                                    id: v?._id,
                                                    data: v,
                                                    index: i,
                                                    pageType: "acceptWithdrawn",
                                                  });
                                                  setJobDetailsPage(true);
                                                  setWindowScrollY(
                                                    window.scrollY
                                                  );
                                                }}
                                                userImage={fllipsImag}
                                                name={
                                                  v?.hospital
                                                    ?.healthCareInstitution
                                                    ?.name
                                                }
                                                location={v?.healthCareLocation}
                                                ratings={v?.hospital?.ratings?.ratingSum.toFixed(
                                                  1
                                                )}
                                                totalRatings={
                                                  v?.hospital?.ratings
                                                    ?.totalRatings
                                                }
                                                seeAll={v?.hospital?._id}
                                                jobTitle={v.jobTitle}
                                                medicalSettings={
                                                  v?.medicalSettings
                                                }
                                                startDate={v?.expectedStartDate}
                                                costPerHour={v?.costPerHour}
                                                contractType={v?.contractType}
                                                contractLength={
                                                  v?.contractLength
                                                }
                                                timeCommitment={
                                                  v?.timeCommitment
                                                }
                                                jobDescription={
                                                  v?.jobDescription
                                                }
                                                weekendAvailiblity={
                                                  v?.weekendAvailiblity
                                                }
                                                buttonType={"applied"}
                                              />
                                            </>
                                          );
                                        })}
                                      </InfiniteScroll>
                                    )}
                                  </>
                                ) : (
                                  <div className="text-center mt-3">
                                    <p>No record found</p>
                                  </div>
                                )}
                              </>
                            )}
                          </Tab>

                          <Tab eventKey="job_offer" title="Job Offers">
                            {buffer ? (
                              <Loader style={{ marginTop: "45px" }} />
                            ) : (
                              <>
                                {allOngoingJobs?.length > 0 ? (
                                  <>
                                    {activeKey == "job_offer" && (
                                      <InfiniteScroll
                                        dataLength={allOngoingJobs?.length}
                                        next={GetAllJobsOffers}
                                        hasMore={hasMore || false}
                                        loader={<Loader />}
                                        className="flex flex-col gap-[18px]"
                                        endMessage={
                                          <p>No more items to load</p>
                                        }
                                      >
                                        {allOngoingJobs?.map((v, i) => {
                                          return (
                                            <JobCard
                                              index={i}
                                              jobCardData={{ data: v }}
                                              pathName={() => {
                                                setJobDetails({
                                                  id: v?._id,
                                                  data: v,
                                                  index: i,
                                                  pageType: "acceptReject",
                                                  expectedDatePlaceholder:
                                                    "Start Date",
                                                  diffData: v?.application,
                                                });
                                                setJobDetailsPage(true);
                                                setWindowScrollY(
                                                  window.scrollY
                                                );
                                              }}
                                              buttonAction={handleButtonAction}
                                              loading={loader}
                                              userImage={fllipsImag}
                                              name={
                                                v?.hospital
                                                  ?.healthCareInstitution?.name
                                              }
                                              location={v?.healthCareLocation}
                                              ratings={v?.hospital?.ratings?.ratingSum.toFixed(
                                                1
                                              )}
                                              totalRatings={
                                                v?.hospital?.ratings
                                                  ?.totalRatings
                                              }
                                              seeAll={v?.hospital?._id}
                                              jobTitle={v.jobTitle}
                                              medicalSettings={
                                                v?.medicalSettings
                                              }
                                              startDate={
                                                v?.application.startDate
                                              }
                                              weekendAvailiblity={
                                                v?.weekendAvailiblity
                                              }
                                              expectedDatePlaceholder="Start Date"
                                              endDate={v?.application?.endDate}
                                              costPerHour={v?.costPerHour}
                                              contractType={v?.contractType}
                                              contractLength={v?.contractLength}
                                              timeCommitment={v?.timeCommitment}
                                              jobDescription={v?.jobDescription}
                                              buttonType={"acceptReject"}
                                            />
                                          );
                                        })}
                                      </InfiniteScroll>
                                    )}
                                  </>
                                ) : (
                                  <div className="text-center mt-3">
                                    <p>No record found</p>
                                  </div>
                                )}
                              </>
                            )}
                            <Alert
                              display={showAlert}
                              title={"ALERT"}
                              content={
                                "Verify your bank details by going to profile section (Profile -> Account Information) "
                              }
                              agreeFn={() => setShowAlert("none")}
                            />
                          </Tab>

                          <Tab
                            eventKey="reject_offer"
                            title="Rejected Applications"
                          >
                            {buffer ? (
                              <Loader style={{ marginTop: "45px" }} />
                            ) : (
                              <>
                                {allRejectedJobs?.length > 0 ? (
                                  <>
                                    <div className="flex flex-col gap-[18px]">
                                      {activeKey == "reject_offer" && (
                                        <InfiniteScroll
                                          dataLength={allRejectedJobs?.length}
                                          next={GetAllRejectedJobs}
                                          hasMore={hasMore || false}
                                          loader={<Loader />}
                                          className="flex flex-col gap-[18px]"
                                          endMessage={
                                            <p>No more items to load</p>
                                          }
                                        >
                                          {allRejectedJobs?.map((v, i) => {
                                            return (
                                              <JobCard
                                                index={i}
                                                jobCardData={{ data: v }}
                                                pathName={() => {
                                                  setJobDetails({
                                                    id: v?._id,
                                                    data: v,
                                                    index: i,
                                                    pageType: "rejected",
                                                  });
                                                  setJobDetailsPage(true);
                                                  setWindowScrollY(
                                                    window.scrollY
                                                  );
                                                }}
                                                userImage={fllipsImag}
                                                name={
                                                  v?.hospital
                                                    ?.healthCareInstitution
                                                    ?.name
                                                }
                                                location={v?.healthCareLocation}
                                                ratings={v?.hospital?.ratings?.ratingSum.toFixed(
                                                  1
                                                )}
                                                totalRatings={
                                                  v?.hospital?.ratings
                                                    ?.totalRatings
                                                }
                                                seeAll={v?.hospital?._id}
                                                jobTitle={v.jobTitle}
                                                medicalSettings={
                                                  v?.medicalSettings
                                                }
                                                startDate={v?.expectedStartDate}
                                                costPerHour={v?.costPerHour}
                                                contractType={v?.contractType}
                                                contractLength={
                                                  v?.contractLength
                                                }
                                                timeCommitment={
                                                  v?.timeCommitment
                                                }
                                                jobDescription={
                                                  v?.jobDescription
                                                }
                                                weekendAvailiblity={
                                                  v?.weekendAvailiblity
                                                }
                                                buttonType={"rejected"}
                                                loading={loader}
                                              />
                                            );
                                          })}
                                        </InfiniteScroll>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div className="text-center mt-3">
                                    <p>No record found</p>
                                  </div>
                                )}
                              </>
                            )}
                          </Tab>

                          <Tab
                            eventKey="withdrawn_offer"
                            title="Withdrawn Applications"
                          >
                            {buffer ? (
                              <Loader style={{ marginTop: "45px" }} />
                            ) : (
                              <>
                                {allWithdrawJobs?.length > 0 ? (
                                  <>
                                    {activeKey == "withdrawn_offer" && (
                                      <InfiniteScroll
                                        dataLength={allWithdrawJobs?.length}
                                        next={GetAllWithdrawnJobs}
                                        hasMore={hasMore || false}
                                        loader={<Loader />}
                                        className="flex flex-col gap-[18px]"
                                        endMessage={
                                          <p>No more items to load</p>
                                        }
                                      >
                                        {allWithdrawJobs?.map((v, i) => {
                                          return (
                                            <>
                                              <JobCard
                                                index={i}
                                                jobCardData={{ data: v }}
                                                pathName={() => {
                                                  setJobDetails({
                                                    id: v?._id,
                                                    data: v,
                                                    index: i,
                                                    pageType: "withdrawn",
                                                  });
                                                  setJobDetailsPage(true);
                                                  setWindowScrollY(
                                                    window.scrollY
                                                  );
                                                }}
                                                userImage={fllipsImag}
                                                name={
                                                  v?.hospital
                                                    ?.healthCareInstitution
                                                    ?.name
                                                }
                                                location={v?.healthCareLocation}
                                                ratings={v?.hospital?.ratings?.ratingSum.toFixed(
                                                  1
                                                )}
                                                totalRatings={
                                                  v?.hospital?.ratings
                                                    ?.totalRatings
                                                }
                                                seeAll={v?.hospital?._id}
                                                jobTitle={v.jobTitle}
                                                medicalSettings={
                                                  v?.medicalSettings
                                                }
                                                startDate={v?.expectedStartDate}
                                                costPerHour={v?.costPerHour}
                                                contractType={v?.contractType}
                                                contractLength={
                                                  v?.contractLength
                                                }
                                                timeCommitment={
                                                  v?.timeCommitment
                                                }
                                                jobDescription={
                                                  v?.jobDescription
                                                }
                                                weekendAvailiblity={
                                                  v?.weekendAvailiblity
                                                }
                                                buttonType={"withdrawn"}
                                                loading={loader}
                                              />
                                            </>
                                          );
                                        })}
                                      </InfiniteScroll>
                                    )}
                                  </>
                                ) : (
                                  <div className="text-center mt-3">
                                    <p>No record found</p>
                                  </div>
                                )}
                              </>
                            )}
                          </Tab>
                        </Tabs>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <RecommendedForYou />
        </div>
      </div>

      <AddReviewModal
        isOpen={showAddReviewModal}
        onClose={() => setShowAddReviewModal(false)}
        className={"md:w-1/2 md:mt-0"}
        postReview={(review, reviewCount) => postReview(review, reviewCount)}
        data={currentSelectedItem}
        loader={loader}
      />

      {showReasonModal && (
        <AddReasonModal
          show={showReasonModal}
          applicationType={applicationType}
          onHide={() => setShowReasonModal(false)}
          setRejectReason={(e) => setrejectReason(e.target.value)}
          rejectJobOffer={rejectJobOffer}
          rejectReason={rejectReason}
          userData={reasonData}
          keyValue={true}
          loader={loader}
        />
      )}
    </>
  );
};

export default MyJob;
