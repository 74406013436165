import React from "react";
import "./t2_sp.css";

const SubHeader = ({ setNavSelected, navSelected }) => {
  const navType = localStorage.getItem("type");

  return (
    <div>
      <div className="t2_subHeader mt-0">
        {(navType == "superAdmin" || navType == "admin") && (
          <>
            <button
              onClick={() => {
                setNavSelected("dashboard");
              }}
              className={
                navSelected == "dashboard"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Dashboard
            </button>
            <button
              onClick={() => {
                setNavSelected("password");
              }}
              className={
                navSelected == "password"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Change Password
            </button>
            {navType == "superAdmin" && (
              <button
                onClick={() => {
                  setNavSelected("advertisement");
                }}
                className={
                  navSelected == "advertisement"
                    ? "t2_subHeader_item t2_subHeader_item_clicked"
                    : "t2_subHeader_item"
                }
              >
                Advertisement
              </button>
            )}
          </>
        )}
        {navType == "staff" && (
          <>
            <button
              onClick={() => {
                setNavSelected("myprofile");
              }}
              className={
                navSelected == "myprofile"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              My Profile
            </button>
            <button
              onClick={() => {
                setNavSelected("dashboard");
              }}
              className={
                navSelected == "dashboard"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Dashboard
            </button>
            <button
              onClick={() => {
                setNavSelected("contact");
              }}
              className={
                navSelected == "contact"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Contact Information
            </button>
            <button
              onClick={() => {
                setNavSelected("resume");
              }}
              className={
                navSelected == "resume"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Resume
            </button>
            <button
              onClick={() => {
                setNavSelected("password");
              }}
              className={
                navSelected == "password"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Change Password
            </button>
          </>
        )}
        {navType == "hospital" && (
          <>
            <button
              onClick={() => {
                setNavSelected("myprofile");
              }}
              className={
                navSelected == "myprofile"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              My Profile
            </button>
            <button
              onClick={() => {
                setNavSelected("password");
              }}
              className={
                navSelected == "password"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Change Password
            </button>
            <button
              onClick={() => {
                setNavSelected("dashboard");
              }}
              className={
                navSelected == "dashboard"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Dashboard
            </button>
            <button
              onClick={() => {
                setNavSelected("advertisement");
              }}
              className={
                navSelected == "advertisement"
                  ? "t2_subHeader_item t2_subHeader_item_clicked"
                  : "t2_subHeader_item"
              }
            >
              Advertisement
            </button>
          </>
        )}
        <hr style={{ color: "grey" }}></hr>
      </div>
    </div>
  );
};

export default SubHeader;
