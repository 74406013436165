import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  incrementStep,
  decrementStep,
  collectData,
} from "../../../redux/action/action";
import "./staffSignUp.css";
import SignUpFormOne from "./formOne";
import logo from "../../assets/images/Logo.png";

import StaffSignUp_1 from "./staffSignUp_1";
import StaffSignUp_2 from "./staffSignUp_2";
import StaffSignUp_3 from "./staffSignUp_3";
import StaffSignUp_4 from "./staffSignUp_4";

const StaffSignUp = () => {
  const step = useSelector((state) => state.step);
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();

  // Set up your initial state
  const [formData, setFormData] = useState({});

  // Function to handle data collection and step navigation
  const handleNext = (data) => {
    if (data?.clearAllData) {
      setFormData({});
      return;
    }
    if (data?.currentLocation) {
      setFormData((prev) => ({
        ...prev,
        currentLocation: data?.currentLocation,
      }));
    } else if (data?.nursing) {
      setFormData((prev) => ({
        ...prev,
        nursing: data?.nursing,
      }));
    } else if (data?.contactDetails) {
      setFormData((prev) => ({
        ...prev,
        contactDetails: data?.contactDetails,
      }));
    }

    // dispatch(collectData(dataToCollect)); // Dispatch the action to collect data

    // if (step >1) {
    dispatch(incrementStep()); // Dispatch the action to increment the step
    // }
  };

  // Function to handle navigating to the previous step

  const handleOptionClick = (option) => {
    // setSelectedOption(option);
  };

  const handleDateClick = (value) => {
    // setStartDate(value === "specific");
    // setDateSelected(value === "specific");
  };

  // Render content based on the current step
  const renderStepContent = (steps) => {
    switch (steps) {
      // case 0:
      //   console.log(step, "stepstep");
      //   return (
      //     <SignUpFormOne
      //       onFormSubmit={handleNext}
      //       handleOptionClick={handleOptionClick}
      //       selectedOption={selectedOption}
      //       setFullTime={setFullTime}
      //       fullTime={fullTime}
      //       setPartTime={setPartTime}
      //       partTime={partTime}
      //       setAvailableWeekEnd={setAvailableWeekEnd}
      //       availableWeekEnd={availableWeekEnd}
      //       setStartDate={setStartDate}
      //       setDateSelected={setDateSelected}
      //       startDate={startDate}
      //       dateSelected={dateSelected}
      //       handleDateClick={handleDateClick}
      //     />
      //   );
      case 0:
        return <StaffSignUp_1 onFormSubmit={handleNext} formData={formData} />;
      case 1:
        return <StaffSignUp_2 onFormSubmit={handleNext} formData={formData} />;
      case 2:
        return <StaffSignUp_3 onFormSubmit={handleNext} formData={formData} />;
      case 3:
        return <StaffSignUp_4 onFormSubmit={handleNext} formData={formData} />;

      default:
        return null;
    }
  };

  return (
    <div>
      {renderStepContent(step)}
      {/* <button onClick={handlePrev}>Previous</button> */}
      {/* <button onClick={handleNext}>Next</button> */}
    </div>
  );
};

export default StaffSignUp;
