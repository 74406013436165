import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../main/Staff.css";
import { useParams } from "react-router-dom";
import Staff from "./staff";

const SinglePost = () => {
  const { id: PostId } = useParams();

  return (
    <>
      <Staff singlePostId={PostId} />
    </>
  );
};

export default SinglePost;
