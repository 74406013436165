import user from "../../assets/images/user.png";
import { useSelector } from "react-redux";
import useSticky from "../../modals/hooks/useSticky";

const RightSideBar = ({ className }) => {
  const profile = useSelector((state) => state.data.profile);
  const { isSticky, parentWidth, stickyElementRef } = useSticky();
  const usertype = localStorage.getItem("type");
  return (
    <div className={`relative ${className}`}>
      <div
        style={{
          position: "sticky",
          top: isSticky ? "20px" : "auto",
          // minWidth: parentWidth ? `${parentWidth}px` : "25%",
          // maxWidth: parentWidth ? `${parentWidth}px` : "100%",
          borderRadius: "10px",
        }}
        ref={stickyElementRef}
        className="border border-gray-100 align-top bg-white h-auto mb-4"
      >
        <div className="flex justify-center pt-4">
          <div className="w-20 h-20 rounded-full overflow-hidden">
            <img
              src={profile?.image ? profile?.image : user}
              className="object-cover w-full h-full"
            />
          </div>
        </div>
        <h1 className="font-semibold text-[18px] leading-[20px] tracking-[0.2px] text-center mt-4">
          Welcome,
          {usertype == "superAdmin"
            ? `${profile?.firstName}`
            : ` ${profile?.name?.split(" ")[0]}`}
        </h1>
        <h1 className="font-normal text-[15px] text-center mt-3 mb-4 leading-[20px] text-gray-500 tracking-[0.2px]">
          {profile?.jobTitle == "Hospital"
            ? profile?.hiringRole
            : profile?.jobTitle}
        </h1>
        <div className="align-middle justify-center">
          {profile?.role == "staff" && (
            <>
              {profile?.currentLocation?.name !== "" &&
                profile?.currentLocation?.zipCode !== "" &&
                profile?.currentLocation?.state !== "" && (
                  <div className="pt-3 pb-2 border-t">
                    <h1
                      className="font-normal text-center text-[#333333] text-[15px] tracking-[0.2px]"
                      style={{ lineHeight: "28px" }}
                    >
                      {profile?.currentLocation?.name +
                        ", " +
                        profile?.currentLocation?.state}

                      <br />
                      {profile?.currentLocation?.zipCode}
                    </h1>
                  </div>
                )}
            </>
          )}
          {}
          {profile?.role == "hospital" && (
            <>
              <div className="pt-3 pb-2 border-t">
                <h1
                  className="font-normal text-center text-[#333333] text-[15px] leading-[22.5px] tracking-[0.2px] pb-[34px]"
                  style={{ lineHeight: "28px" }}
                >
                  {profile?.corporateAddress[0]?.street
                    ?.charAt(0)
                    .toUpperCase() +
                    profile.corporateAddress[0]?.street?.slice(1) ||
                    "" + ", " + profile?.corporateAddress[0]?.city ||
                    "" + ", " + profile?.corporateAddress[0]?.state ||
                    ""}
                  <br />
                  {profile?.corporateAddress[0]?.zipCode}
                </h1>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
