import JobType from "../../staffProfile/components/jobType";
import right_arrow from "../../../assets/images/right_arrow.svg";

const ManageStaffEdit = ({ id, setAddEditModal }) => {
  return (
    <div>
      <p className="flex items-center py-3 bg-white rounded-tl-[10px] px-3 rounded-tr-[10px]">
        <span className="flex gap-10 items-center" onClick={setAddEditModal}>
          <img src={right_arrow} className="w-6 h-6 cursor-pointer" />
          <div className="text-lg"> Edit Staff</div>
        </span>
      </p>
      <JobType adminStaffId={id} />
    </div>
  );
};

export default ManageStaffEdit;
