import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_LHS_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      const role = localStorage.getItem("type");
      if (role == "staff" || role == "hospital") {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    const response = error?.response?.data || error;
    return Promise.reject(response);
  }
);

export const axiosInstanceWithToken = axios.create({
  baseURL: process.env.REACT_APP_LHS_URL,
});

axiosInstanceWithToken.interceptors.request.use(
  async (config) => {
    const customToken = config.customToken;
    if (customToken) {
      config.headers.authorization = `Bearer ${customToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstanceWithToken.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      const role = localStorage.getItem("type");
      if (role == "staff" || role == "hospital") {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    const response = error?.response?.data || error;
    return Promise.reject(response);
  }
);
