import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ErrorState from '../../../theme_1/staffSignUp/components/errorState';
import place from '../../../assets/images/place.svg';
import { SET_SP_PROFILE, SET_AUTH } from '../../../../constants/actionTypes';
import { callApi } from '../../../../redux/action/action';

const JobType = () => {
    const [locations, setLocations] = useState([]);
    const [getLocation, setGetLocation] = useState([]);
    const [newLoc, setNewLoc] = useState({
        name: '',
        state: '',
        zipCode: ''
    });
    const [newZip, setNewZip] = useState('');
    const [error, setError] = useState(false);
    const [errorTitle, setErrorTitle] = useState('Required 5 digits as a zip code.');
    const [showInput, setShowInput] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const dispatch = useDispatch();
    const currentLocation = useSelector(state => state?.staffProfile?.currentLocation);
    const otherCities = useSelector(state => state?.staffProfile?.otherCities);

    useEffect(() => {
        const typeData = localStorage.getItem('type');
        if (typeData === 'admin') {
            setIsAdmin(true);
        }

        let id;
        if (isAdmin) {
            id = window.localStorage.getItem('Staffid');
        } else {
            id = window.localStorage.getItem('_id');
        }

        async function fetchData() {
            const Authorization = 'Bearer '.concat(window.localStorage.getItem('token'));
            const res = await callApi('GET', `v1/LHS/staff/getById/${id}`);
            if (res.data) {
                setGetLocation(res.data.otherCities);
            }
        }

        fetchData();
    }, [isAdmin]);

    const addNewLoc = async () => {
        setShowInput(false);
        setError(false);

        const data = {
            name: newLoc.name,
            state: newLoc.state,
            zipCode: newLoc.zipCode,
        };

        const Authorization = 'Bearer '.concat(window.localStorage.getItem('token'));
        const id = window.localStorage.getItem('_id');
        const res = await callApi('POST', `v1/LHS/staff/otherCities/add?userId=${id}`, Authorization, data);

        if (res.data) {
            setGetLocation(res.data.otherCities);
            fetchData();
            clearNewLoc();
        }
    };

    const removeOneLoc = async (index) => {
        const id = getLocation[index]._id;
        const Authorization = 'Bearer '.concat(window.localStorage.getItem('token'));
        const _id = window.localStorage.getItem('_id');
        const res = await callApi('DELETE', `v1/LHS/staff/otherCities/${id}?userId=${_id}`, Authorization);
        setGetLocation(res.data.otherCities);
        fetchData();
    };

    const clearNewLoc = () => {
        setNewLoc({
            name: '',
            state: '',
            zipCode: '',
        });
        setError(false);
        setNewZip('');
    };

    const setOtherLocation = async (e) => {
        const zip = e.target.value;
        if (zip.length === 5) {
            setNewZip(zip);
            if (zip[0] === '0') {
                zip = zip.substring(1);
            }
            if (zip[0] === '0') {
                zip = zip.substring(1);
            }

            const res = await callApi('POST', 'v1/LHS/other/getCityState', null, { zip: zip });
            if (res.result === 'OK') {
                const city = res.data.city;
                const state = res.data.state;
                setError(false);
                const temp = {
                    name: city,
                    state: state,
                    zipCode: zip,
                };
                setNewLoc(temp);
            } else {
                setError(true);
                setErrorTitle("This isn't a zip code of the city in the USA.");
            }
        } else if (e.target.value.length > 5) {
            // Handle the case if needed
        } else {
            setNewZip(zip);
            setError(true);
            setErrorTitle('Required 5 digits as a zip code.');
        }
    };

    // Your updateDB function can be implemented similarly

    return (
        <div className="t2_sp_work">
            <div className="t2_sp_work_title">Available For Other Location</div>
            <div className="row t2_sp_work_container">
                {getLocation.map((item, index) => (
                    <button className="t2_sp_work_item t2_sp_loc_selected" style={{ cursor: 'default' }} key={item.num}>
                        <span className="t2_sp_loc_img" key={item._id}>
                            <svg width="16" height="24" viewBox="0 3 28 35" xmlns="http://www.w3.org/2000/svg">
                                {/* Your SVG path here */}
                            </svg>
                        </span>
                        {item.name + ', ' + item.state}
                        {isAdmin ? '' : <span className="t2_sp_loc_btn" onClick={() => removeOneLoc(index)}> X </span>}
                    </button>
                ))}

                <button
                    className="t2_sp_work_item"
                    style={{ display: newLoc.zipCode ? 'block' : 'none', cursor: 'default' }}
                    onClick={addNewLoc}
                >
                    <span className="t2_sp_loc_img">
                        <svg width="16" height="24" viewBox="0 3 28 35" xmlns="http://www.w3.org/2000/svg">
                            {/* Your SVG path here */}
                        </svg>
                    </span>
                    {newLoc.name + ', ' + newLoc.state}
                    <span className="t2_sp_loc_btn" onClick={clearNewLoc}> X </span>
                </button>
            </div>

            {!isAdmin && (
                <div className="ssu2_addItem" style={{ display: showInput ? 'none' : 'block' }} onClick={() => setShowInput(true)}>
                    + Add {locations.length ? 'Another' : ''} Location
                </div>
            )}
            <div className="ssu1_input" style={{ marginTop: 24, display: showInput ? 'block' : 'none' }}>
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                        <img alt="img" src={place} width="16px" height="20px" style={{ marginTop: '-3px' }} />
                    </div>
                    <input style={{ width: '100%' }} className="ssu2_modal3_select" placeholder="Zip Code" type="text" value={newZip} name="curLoc" onChange={setOtherLocation} />
                </div>
                <ErrorState show={error ? 'block' : 'none'} name={errorTitle} />
            </div>
        </div>
    );
};

export default JobType;
