import classNames from "classnames";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import amex from "../../../../../assets/cards/amex.svg";
import code from "../../../../../assets/cards/code.svg";
import discover from "../../../../../assets/cards/discover.svg";
import jcb from "../../../../../assets/cards/jcb.svg";
import mastercard from "../../../../../assets/cards/mastercard.svg";
import visa from "../../../../../assets/cards/visa.svg";
// import ImageFallback from "@/components/ui/ImageFallback";

export default function CreditCardBox({ item, selectedCard, onSelect }) {
  const { exp_month, exp_year, last4, brand } = item || {};
  const cardBrand = brand?.toLowerCase();

  const isSelected = selectedCard?.id === item?.id;

  const cardBrandSvg =
    {
      amex,
      code,
      discover,
      jcb,
      mastercard,
      visa,
    }[cardBrand] || visa;

  return (
    <div
      className={classNames(
        "flex cursor-pointer items-center justify-between space-x-4 rounded-xl border-2 bg-white px-3 py-3",
        {
          "border-[#73C7A9] shadow-lg": isSelected,
          "border-[#E5E5E5]": !isSelected,
        }
      )}
      onClick={onSelect}
    >
      <img src={cardBrandSvg} alt={cardBrand} width={30} height={30} />
      <div>
        <p className="text-[16px]">XXXX XXXX XXXX {last4}</p>
        <p className="text-[15px] font-light">
          {exp_month}/{exp_year}
        </p>
      </div>

      <div className="text-[#73C7A9] w-[30px]">
        {isSelected && <FaCheckCircle size={20} />}
      </div>
    </div>
  );
}
