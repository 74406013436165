import { format } from "date-fns";

export default function calculateTime(time) {
  const x = new Date();
  const y = new Date(time);

  function compare(x, y) {
    return Math.abs(x - y);
  }

  const diff = compare(x, y);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));

  const yesterday = new Date(x.getFullYear(), x.getMonth(), x.getDate() - 1);
  const toDate = new Date(y.getFullYear(), y.getMonth(), y.getDate());

  let hoursMins = "";
  let result = "";

  if (toDate.getTime() === yesterday.getTime()) {
    result = "1d";
    return result;
  } else {
    hoursMins = format(y, "h:mm a");

    if (days >= 365) {
      result = `${Math.floor(days / 365)}Y`;
    } else if (days >= 30) {
      result = `${Math.floor(days / 30)}M`;
    } else if (days >= 7) {
      result = `${Math.floor(days / 7)}w`;
    } else if (days >= 1) {
      result = `${days}d`;
    } else if (hours >= 1) {
      result = `${hours}h`;
    } else if (minutes > 1) {
      result = hoursMins;
    } else {
      result = hoursMins;
    }

    return result;
  }
}
