import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../config/axiosConfig";
import calculateTime from "../modals/calculateTime";
import like_true from "../assets/images/like-true.svg";
import like_true2 from "../assets/images/like_true2.svg";
import reply from "../assets/images/reply.png";
import dots from "../assets/images/dots.png";
import like from "../assets/images/like.png";
import edit from "../assets/images/edit.png";
import user from "../assets/images/user.png";
import deletebox from "../assets/images/deletebox.png";
import likedTrue from "../assets/images/likedTrue.png";
import { useSelector } from "react-redux";
import Modal from "../common/modal";
import ReplayList from "../theme_1/main/replayList";
import {
  showErrorToast,
  showSuccessToast,
} from "../common/toastMeassage/ToastMeassage";
import Loader from "../modals/Loader";
import { useHistory } from "react-router-dom";

export default function CommentsList({
  props,
  getAllComments,
  setCommentCount,
  commentCount,
  showCommentModal,
  setCommentids,
  setCommentText,
  toProfileList,
  updatedComments,
  ClicktoUserData,
  setIsButtonHidden,
  editCommentPopup,
  updateReplyPopup,
  setShowCommentModal,
}) {
  const history = useHistory();
  const [commentsToRender, setCommentsToRender] = useState([]);
  const [updateReplyText, setUpdateReplyText] = useState(null);
  const profile = useSelector((state) => state.data.profile);
  const token = localStorage.getItem("type");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [updateReplyId, setUpdateReplyId] = useState(null);
  const [updateCommentOption, setUpdateCommentOption] = useState(false);
  const [replyScreenModal, setReplyScreenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [replyText, setReplyText] = useState("");
  const [allReplyByCommntId, setAllReplyByCommntId] = useState([]);
  const [updateReply, setUpdateReply] = useState(false);
  const [updateComments, setUpdateComments] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);

  const getAllCommentByPostId = async ({ page = 1 }) => {
    setLoadMore(true);
    props.setLoading(true);
    try {
      let url = `v2/LHS/User/getAllCommentByPostId?postId=${props?._id}&page=${page}&limit=10`;
      const response = await axiosInstance.get(url);
      if (response?.data?.data) {
        setCommentsToRender((prevData) => {
          if (page > 1 && page <= totalPages) {
            return [...prevData, ...response?.data?.data?.commentData];
          } else {
            return [...response?.data?.data?.commentData];
          }
        });
        setTotalPages(response?.data?.data?.totalPages);
        setCommentCount(response?.data?.data?.commentCount);
        setShowCommentModal(true);
      }
    } catch (error) {
      props.setLoading(false);
      console.error("Error fetching interest jobs:", error);
    } finally {
      props.setLoading(false);
      setLoadMore(false);
    }
  };

  // useEffect(() => {
  //   if (props?._id == updatedComments?.id) {
  //     const updateComments = commentsToRender.map((comment, i) =>
  //       comment?._id == updatedComments?.comment?.[i]?._id
  //         ? {
  //             ...comment,
  //             likeCount: updatedComments?.comment?.[i]?.likeCount || 0,
  //             repliesCount: updatedComments?.comment?.[i]?.replies || 0,
  //           }
  //         : comment
  //     );
  //     setCommentsToRender(updateComments);
  //   }
  // }, [updatedComments, props?._id]);

  useEffect(() => {
    if (props?._id === updatedComments?.id) {
      const updatedCommentsArray = commentsToRender.map((comment) => {
        const updatedComment = updatedComments?.comment?.find(
          (c) => c._id === comment._id
        );
        if (updatedComment) {
          return {
            ...comment,
            likeCount: updatedComment.likeCount || 0,
            repliesCount: updatedComment.replies || 0,
          };
        }
        return comment;
      });
      setCommentsToRender(updatedCommentsArray);
    }
  }, [updatedComments, props?._id]);

  const getMoreComments = () => {
    if (page > totalPages) return;
    getAllCommentByPostId({ page });
    setPage((prev) => prev + 1);
  };

  const handleButtonClick = (data) => {
    history.push(`/LikedCommentList?comment_id=${data?._id}`);
  };

  useEffect(() => {
    if (showCommentModal && props?._id && getAllComments !== true) {
      getAllCommentByPostId({ page: 1 });
      setCommentsToRender(props?.comments?.slice(0, 1));
    } else if (!showCommentModal) {
      // setAllComments([]);
      // getAllCommentByPostId({ page: 1 });
      setCommentsToRender((prev) => prev?.slice(0, 1));
    }
    // if (showCommentModal && getAllComments !== true) {
    //   // setCommentsToRender([]);
    //   // getAllCommentByPostId({ page: 1 });
    //   setCommentsToRender(props?.comments?.slice(0, 1));
    // }
    setTotalPages(2);
    setPage(1);
  }, [showCommentModal, props?._id, getAllComments]);

  const handleDeleteReply = (_id) => {
    props.setLoading(true);
    let url = `v2/LHS/User/deleteReply/${_id}`;
    axiosInstance
      .delete(url)
      .then((data) => {
        handleAllReplyByCommntId();
        showSuccessToast("Reply deleted successfully!");
        props.setLoading(false);
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err);
      });
  };

  const handleLikeComment = async (commentId, index) => {
    try {
      if (buttonClicked) {
        return;
      }
      setButtonClicked(true);
      let url = `/v2/LHS/User/likeDislikeComment`;
      const response = await axiosInstance.post(url, {
        commentId: commentId?._id,
      });
      if (response?.data?.success) {
        const updateComments = commentsToRender.map((comment, i) =>
          i == index
            ? {
                ...comment,
                likeCount: response?.data?.data?.comment?.[0]?.likeCount || 0,
                isLiked: response?.data?.data?.comment?.[0]?.isLiked || false,
              }
            : comment
        );
        setCommentsToRender(updateComments);
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setButtonClicked(false);
    }
  };

  const handleAllReplyByCommntId = async (id) => {
    try {
      let url = `/v2/LHS/User/getAllReplyByCommentId?page=1&limit=10&commentId=${
        id || currentCommentId
      }`;
      const response = await axiosInstance.get(url);
      if (response.data) {
        if (response?.data?.data) {
          setAllReplyByCommntId(response?.data?.data);
          const updateComments = commentsToRender.map((comment, i) =>
            comment?._id == currentCommentId
              ? {
                  ...comment,
                  repliesCount: response?.data?.data?.replyCount || 0,
                }
              : comment
          );
          setCommentsToRender(updateComments);
        } else {
          setAllReplyByCommntId([]);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (currentCommentId) {
      handleAllReplyByCommntId();
    }
  }, [currentCommentId]);

  const handleTogglePopup = (commentId) => {
    if (props?.updateComment === commentId) {
      props?.setUpdateComment(null);
    } else {
      props?.setUpdateComment(commentId);
    }
  };
  const handleEditComment = (commenttext) => {
    setCommentids(commenttext?._id);
    setCommentText(commenttext?.comment);
    setIsButtonHidden(true);
  };

  const handleToggleReplyPopup = (replyId) => {
    if (updateReplyId === replyId) {
      setUpdateReplyId(null);
    } else {
      setUpdateReplyId(replyId);
    }
  };
  const handleEditReply = (replytext) => {
    setUpdateReply(true);
    setUpdateReplyText(replytext?.comment);
  };

  const handleDeleteComment = (_id) => {
    props.setLoading(true);
    let url = `v2/LHS/User/deleteComment/${_id}`;
    axiosInstance
      .delete(url)

      .then((data) => {
        if (data?.data?.success == true) {
          getAllCommentByPostId({ page: page });
          showSuccessToast("Comment deleted successfully!");
          props.setLoading(false);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err);
      });
  };

  const handleReplyComment = async (commentId) => {
    setLoading(true);
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    try {
      const formData = {
        commentId: commentId,
        comment: replyText,
      };
      await axiosInstance.post("v2/LHS/User/addReply", formData);
      showSuccessToast("Reply added successfully!");
      setLoading(false);
    } catch (error) {
      showErrorToast("Something went wrong");
      setLoading(false);
    } finally {
      setReplyText("");
      handleAllReplyByCommntId();
      setButtonClicked(false);
    }
  };

  const handleUpdateReply = async (commentId) => {
    setLoading(true);
    try {
      const formData = {
        comment: updateReplyText,
      };
      const response = await axiosInstance.put(
        `v2/LHS/User/updateReply/${commentId}`,
        formData
      );
      setUpdateReplyText("");
      setUpdateReplyId(null);
      showSuccessToast("Reply updated successfully!");
      setLoading(false);
    } catch (error) {
      showErrorToast("Something went wrong");
      setLoading(false);
    } finally {
      handleAllReplyByCommntId();
    }
  };

  const renderImage = (isLiked) => {
    if (isLiked) {
      return (
        <img
          src={like_true}
          alt="Liked"
          style={{ height: "19px", width: "20px", cursor: "pointer" }}
        />
      );
    } else {
      return (
        <img
          src={like_true2}
          alt="Like"
          style={{ height: "19px", width: "20px", cursor: "pointer" }}
        />
      );
    }
  };

  useEffect(() => {
    if (getAllComments !== true || showCommentModal) return;
    setCommentsToRender(props?.comments?.slice(0, 1));
  }, [props?._id, props?.comments, getAllComments]);

  return (
    <>
      <div>
        <div>
          {commentsToRender.length > 0 &&
            commentsToRender.map((data, index) => {
              const userRole = data?.userId?.role?.split(/(?=[A-Z])/);
              const JoinRole = userRole?.join(" ");
              const isMyComment = props?.myProfile?._id === data.userId?._id;
              return (
                <div>
                  <div key={index}>
                    <div className="flex items-start gap-3">
                      <div className="w-12 h-12 min-w-[48px] rounded-full overflow-hidden">
                        <img
                          className="object-cover w-full h-full"
                          src={data?.userId?.image ? data?.userId?.image : user}
                          onClick={() => {
                            if (profile) {
                              toProfileList(data);
                              ClicktoUserData(data);
                            }
                          }}
                        />
                      </div>
                      <div
                        onClick={() => profile && handleTogglePopup(data?._id)}
                        className="border border-[#F2F2F2] justify-between bg-[#F3F2EF]  flex items-start py-2 px-3   rounded-[6px]"
                        style={{
                          backgroundColor: "#F3F2EF",
                          width: "100%",
                        }}
                      >
                        <div className="w-full flex flex-col gap-[6px]">
                          <div className="flex items-center justify-between w-full">
                            <h1
                              className="font-[#333333] mt-2 text-md font-semibold leading-5 tracking-wide cursor-pointer"
                              onClick={() => {
                                if (profile) {
                                  toProfileList(data);
                                  ClicktoUserData(data);
                                }
                              }}
                            >
                              {data?.userId?.name
                                ? data?.userId?.name
                                : data?.userId?.firstName +
                                  " " +
                                  data?.userId?.lastName}
                            </h1>
                            <div className="flex items-center mt-2 justify-end gap-[10px]">
                              <h1 className="font-extralight text-sm leading-[15px]">
                                {calculateTime(
                                  data?.updatedAt || data?.createdAt
                                )}
                              </h1>

                              {updateComments == true &&
                                props?.updateComment === data?._id &&
                                (isMyComment || token == "superAdmin") && (
                                  <div
                                    ref={editCommentPopup}
                                    className="justify-center flex items-center z-10  bg-white border border-gray-100 mt-[30px] w-auto rounded-md px-3 py-3 absolute"
                                  >
                                    <div>
                                      <div>
                                        <div className="flex items-center">
                                          <img
                                            src={edit}
                                            style={{
                                              width: "20px",
                                              height: "15px",
                                              marginRight: "10px",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <h2
                                            onClick={() =>
                                              handleEditComment(data)
                                            }
                                            className="ml-2 text-sm cursor-pointer"
                                          >
                                            Edit comment
                                          </h2>
                                        </div>
                                      </div>

                                      <div
                                        className="flex items-center mt-2"
                                        onClick={() =>
                                          handleDeleteComment(data?._id)
                                        }
                                      >
                                        <img
                                          src={deletebox}
                                          style={{
                                            width: "20px",
                                            height: "15px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <h2 className="ml-2 text-sm cursor-pointer">
                                          Delete comment
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {(isMyComment || token == "superAdmin") && (
                                <div className="">
                                  <img
                                    src={dots}
                                    className="w-4 h-1 align-top"
                                    onClick={() => {
                                      setUpdateCommentOption((s) => !s);
                                      setUpdateComments(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <h1 className="text-gray-500 text-sm">
                            {data?.userId?.role == "hospital"
                              ? data?.userId?.hiringRole
                              : data?.userId?.role == "staff"
                              ? data?.userId?.jobTitle
                              : JoinRole}
                          </h1>
                          <h1 className="text-base font-normal leading-6 tracking-[0.2px] mt-[14px]">
                            {data?.comment}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  {profile && (
                    <div className="w-10/12 flex items-center ml-16 py-2 gap-2.5">
                      <div
                        className="flex items-center"
                        onClick={() => {
                          handleLikeComment(data, index);
                        }}
                      >
                        {renderImage(data?.isLiked)}
                      </div>
                      {data?.likeCount >= 1 ? (
                        <button
                          className="mt-[5px]"
                          id="likeButton"
                          onClick={() => {
                            handleButtonClick(data);
                          }}
                        >
                          {data?.likeCount}
                          {data?.likeCount > 1 ? "Likes" : "Like"}
                        </button>
                      ) : (
                        <p className="mt-[5px]">
                          {data?.likeCount}
                          Like
                        </p>
                      )}

                      <div
                        className="flex items-center py-2 px-4 gap-2.5"
                        onClick={() => setCurrentCommentId(data._id)}
                      >
                        <img
                          src={reply}
                          style={{
                            height: "10px",
                            width: "15px",
                            cursor: "pointer",
                          }}
                        />

                        {data?.replies?.length >= 1 ? (
                          <>
                            {localStorage.setItem(
                              "dataId",
                              JSON.stringify(data)
                            )}

                            <h1
                              className="cursor-pointer"
                              onClick={() => {
                                if (data?.replies?.length > 2) {
                                  history.push(
                                    `/replayList?postId=${data._id}`
                                  );
                                  // setReplyScreenModal(true);
                                }
                              }}
                            >
                              {data?.repliesCount >= 0
                                ? data?.repliesCount
                                : currentCommentId == data._id
                                ? allReplyByCommntId?.replyCount
                                : data?.replies?.length}
                              replies
                            </h1>
                          </>
                        ) : (
                          <h1
                            className="cursor-pointer"
                            onClick={() => {
                              if (data?.repliesCount >= 1) {
                                history.push(`/replayList?postId=${data._id}`);
                              }
                            }}
                          >
                            {data?.repliesCount >= 0
                              ? data?.repliesCount
                              : currentCommentId == data._id
                              ? allReplyByCommntId?.replyCount
                              : data?.replies?.length}{" "}
                            replies
                          </h1>
                        )}
                      </div>
                    </div>
                  )}
                  {currentCommentId == data._id &&
                    allReplyByCommntId?.replyData?.map((item) => {
                      const isMyReply =
                        props?.myProfile?._id === item.userId?._id;
                      const userRole = item?.userId?.role?.split(/(?=[A-Z])/);
                      const JoinRole = userRole?.join(" ");
                      return (
                        <>
                          <div className="flex justify-end mt-2">
                            {updateReplyId &&
                              updateReplyId == item?._id &&
                              !updateReply && (
                                <div
                                  ref={updateReplyPopup}
                                  className="justify-center flex items-center z-10  bg-white border border-gray-100 mt-[40px] rounded-md px-3 mr-5 py-3 absolute "
                                >
                                  <div>
                                    <div>
                                      <div className="flex items-center">
                                        <img
                                          src={edit}
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <h1
                                          onClick={() => handleEditReply(item)}
                                          className="cursor-pointer text-sm"
                                        >
                                          Edit reply
                                        </h1>
                                      </div>
                                    </div>

                                    <div
                                      className="flex items-center mt-2"
                                      onClick={() => {}}
                                    >
                                      <img
                                        src={deletebox}
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <h1
                                        onClick={() =>
                                          handleDeleteReply(item?._id)
                                        }
                                        className="cursor-pointer text-sm"
                                      >
                                        Delete reply
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              )}
                            <div className="flex items-start w-11/12 gap-[7px]">
                              <img
                                src={
                                  item?.userId?.image ? item.userId.image : user
                                }
                                style={{
                                  height: "54px",
                                  width: "54px",
                                  borderRadius: "40px",
                                }}
                              />

                              <div
                                className="w-full border border-gray-100 justify-between bg-slate-300 flex items-start py-2 px-3 rounded-[12px]"
                                style={{
                                  backgroundColor: "#F3F2EF",
                                }}
                              >
                                <div>
                                  <h1 className="font-[#333333] mt-2 text-md font-semibold leading-5 tracking-wide">
                                    {item?.userId?.name
                                      ? item?.userId?.name
                                      : item?.userId?.firstName +
                                        " " +
                                        item?.userId?.lastName}
                                  </h1>

                                  <h1 className="text-gray-500 text-sm mt-2">
                                    {item?.userId?.role == "hospital"
                                      ? item?.userId?.hiringRole
                                      : item?.userId?.role == "staff"
                                      ? item?.userId?.jobTitle
                                      : JoinRole}
                                  </h1>

                                  <h1 className="font-[#333333]  text-base mt-2 w-40 leading-5 tracking-wide font-normal">
                                    {item?.comment}
                                  </h1>
                                </div>

                                {/* {isMyReply && ( */}
                                <div className="flex item-center mt-2 w-[90px] justify-end">
                                  <h1 className="font-extralight mr-2 text-sm ">
                                    {calculateTime(
                                      item?.updatedAt || item?.createdAt
                                    )}
                                  </h1>
                                  {(isMyReply || token == "superAdmin") && (
                                    <div className="justify-end">
                                      <img
                                        src={dots}
                                        className="w-4 h-1 align-top mt-1"
                                        onClick={() => {
                                          setUpdateCommentOption((s) => !s);
                                          setUpdateReply(false);
                                          handleToggleReplyPopup(item._id);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {updateReplyId == item._id && updateReply && (
                            <div className="flex items-center justify-end mt-3">
                              <img
                                src={
                                  item?.userId?.image
                                    ? item?.userId?.image
                                    : user
                                }
                                style={{
                                  height: "38px",
                                  width: "38px",
                                  borderRadius: "40px",
                                  marginRight: "15px",
                                }}
                              />
                              <div className="border-gray-100 border rounded-lg justify-center w-10/12 items-center">
                                <input
                                  className="border-gray-100 appearance-none border rounded w-full h-11 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id="inline-full-name"
                                  type="text"
                                  placeholder="Update  reply"
                                  value={updateReplyText}
                                  onChange={(e) =>
                                    setUpdateReplyText(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="flex items-center mt-3 ml-28">
                            {updateReplyId == item._id && updateReply && (
                              <div className="mb-8 flex items-center mt-3 gap-[20px]">
                                <button
                                  onClick={() => {
                                    setUpdateReply(false);
                                    setUpdateReplyId(null);
                                    setUpdateReplyText("");
                                  }}
                                  className="h-9 w-[100px] font-medium  border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                                >
                                  Cancel
                                </button>
                                {updateReplyText && (
                                  <button
                                    onClick={() => handleUpdateReply(item._id)}
                                    className="h-9 w-[140px] rounded-full bg-[#73C7A9] text-[#FFFFFF] font-medium"
                                  >
                                    {loading ? <Loader /> : "Update Reply"}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  {currentCommentId == data._id && (
                    <div className="flex items-center justify-end mt-3 gap-[7px]">
                      <img
                        src={profile?.image ? profile?.image : user}
                        style={{
                          height: "38px",
                          width: "38px",
                          borderRadius: "40px",
                        }}
                      />
                      <div className="border-gray-100 border rounded-lg justify-center w-10/12 items-center">
                        <input
                          className="border-gray-100 appearance-none border rounded w-full h-11 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="inline-full-name"
                          type="text"
                          placeholder="Add a reply"
                          value={replyText}
                          onChange={(e) => setReplyText(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="ml-28">
                    {currentCommentId == data._id && (
                      <div className="mb-10 flex items-center mt-3 gap-[20px]">
                        <button
                          onClick={() => {
                            setCurrentCommentId("");
                            setReplyText("");
                          }}
                          className="h-9 w-[100px] font-medium  border-1  !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                        >
                          Cancel
                        </button>
                        {replyText && (
                          <button
                            onClick={() => handleReplyComment(data._id)}
                            className="h-9 w-[100px] rounded-full bg-[#73C7A9] text-[#FFFFFF] font-medium"
                            disabled={buttonClicked}
                          >
                            {loading ? <Loader /> : "Reply"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {loadMore ? (
          <Loader />
        ) : (
          page <= totalPages &&
          (commentCount || props?.commentsCount) > commentsToRender?.length && (
            <p
              className="font-semibold cursor-pointer"
              onClick={getMoreComments}
            >
              Load more comments...
            </p>
          )
        )}
      </div>
      <Modal
        className="md:w-full mt-0"
        isOpen={replyScreenModal}
        onClose={() => {
          setReplyScreenModal(false);
        }}
      >
        <ReplayList props={props} />
      </Modal>
    </>
  );
}
