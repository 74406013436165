import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "./axiosConfig";
import useLoader from "../components/modals/hooks/useLoader";

export const userLoggedInUserInfo = () => {
  const { showLoader, hideLoader } = useLoader();
  const userProfile = useSelector((state) => state.data.profile);
  const dispatch = useDispatch();
  const [profile, setProfile] = useState([]);
  const token = localStorage.getItem("accessToken");
  const getProfile = async () => {
    try {
      // showLoader();
      let url = "v1/LHS/staff/getProfile";
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        localStorage.setItem("CurrentUser", JSON.stringify(response.data.data));
        setProfile(response.data.data);
        dispatch({ type: "PROFILE_DATA", payload: response.data.data });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      // hideLoader();
    }
  };
  useEffect(() => {
    if (token && !userProfile) {
      getProfile();
    }
  }, [token]);

  return { profile };
};
