import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import edit_note from "../../../assets/images/edit_note.svg";
import user from "../../../assets/images/user.svg";
import email from "../../../assets/images/email.svg";
import phone from "../../../assets/images/phone.svg";
import { callApi } from "../../../../redux/action/action";
import Modal from "../../../common/modal";
import right_arrow from "../../../assets/images/right_arrow.svg";
import jobTitle from "../../../assets/images/jobTitle.svg";
import { axiosInstance } from "../../../../config/axiosConfig";
import Loader from "../../../modals/Loader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../common/toastMeassage/ToastMeassage";
import CorporateAddress from "./address";
import edit_badge from "../../../assets/images/edit_badge.svg";

function Contact({ hospitalData, setHospitalDetails }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.data.profile);
  const userType = localStorage.getItem("type");
  const [showLoader, setShowLoader] = useState(false);
  const [state, setState] = useState({
    name: hospitalData?.name || "",
    hiringRole: hospitalData?.hiringRole || "",
    email: hospitalData?.email || "",
    phone: hospitalData?.phone || "",
  });

  const handleVal = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (str) => {
    setModalOpen(true);
    setState((prevState) => ({
      ...prevState,
      mname: prevState.name,
      msize: prevState.hiringRole,
      memail: prevState.email,
      mPhone: prevState.phone,
      error: {
        name: "none",
        size: "none",
        emai: "none",
        phone: "none",
      },
    }));
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const fetch = () => {};

  useEffect(() => {
    if (hospitalData) {
      setState({
        ...state,
        name: hospitalData.name || "",
        hiringRole: hospitalData.hiringRole || "",
        email: hospitalData.email || "",
        phone: hospitalData.phone || "",
      });
    }
  }, []);

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const emailRepeatCheck = async () => {
    if (state.email !== state.memail) {
      var data = {
        email: state.memail,
      };
      var res1 = await callApi(
        "POST",
        "v1/LHS/hospital/emailCheck",
        null,
        data
      );
      var res2 = await callApi("POST", "v1/LHS/staff/emailCheck", null, data);
      if (res1.result === "OK" && res2.result === "OK") {
        const dataupdated = {
          name: state.name,
          hiringRole: state.hiringRole,
          email: state.email,
          phone: state.phone,
        };
        var apidata = await axiosInstance.put(
          "v1/LHS/staff/update",
          dataupdated
        );
        console.log(apidata, "apidataapidataapidata");
        closeModal();
      } else {
        setState((prevState) => ({
          ...prevState,
          emailErr: "This Email address is already in use.",
          error: {
            ...prevState.error,
            email: "block",
          },
        }));
      }
    }
  };

  const continueToNext = async () => {
    try {
      let { name, hiringRole, email, phone } = state;
      if (!name || !hiringRole || !email || !phone) {
        showErrorToast("All fields are required!");
        return;
      }
      await emailRepeatCheck();
      if (!state.emailErr) {
        setShowLoader(true);
        try {
          const response = await axiosInstance.post(
            `v1/LHS/hospital/update/${hospitalData?._id}`,
            {
              name,
              hiringRole,
              email,
              phone,
            }
          );
          if (response.status === 200) {
            showSuccessToast(
              response.data?.Message || "User Updated Successfully"
            );
            closeModal();
            setShowLoader(false);
            setHospitalDetails(response?.data?.data);
            closeModal();
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setShowLoader(false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUploadProfile = async (files) => {
    var formData = new FormData();
    formData.append("file", files);
    if (!files?.name) return;
    try {
      let url = "/v2/LHS/Upload/uploadFile";
      const response = await axiosInstance.post(url, formData);
      const respData = response.data.data;
      try {
        const response = await axiosInstance.post(
          `v1/LHS/hospital/update/${hospitalData?._id}`,
          {
            image: respData,
          }
        );
        if (response.status === 200) {
          closeModal();
          setShowLoader(false);
          setHospitalDetails(response?.data?.data);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setShowLoader(false);
        closeModal();
      }
    } catch {
      console.error("Failed to upload image:");
    }
  };

  return (
    <>
      <div className="flex text-center justify-center flex-col mt-4 gap-1">
        <div className="flex justify-center relative">
          <div className="w-40 h-40 rounded-full overflow-hidden">
            <img
              src={hospitalData?.image}
              className="object-cover w-full h-full"
            />
          </div>
          {profile?.role == "hospital" && (
            <div className="absolute bottom-0 right-[45%]">
              <img
                src={edit_badge}
                alt="profile"
                className="w-[32px] h-[32px]"
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    handleUploadProfile(file);
                  }
                }}
                className="w-[100px] absolute left-0 top-0 opacity-0"
              />
            </div>
          )}
        </div>

        <div className="text-[#000] text-2xl">{}</div>
        <div className="text-[#5c5959] text-xl">
          {/* {userData?.currentLocation?.name} ,{userData?.currentLocation?.state}{" "} */}
          <h3 style={{ fontSize: "22px" }}>{hospitalData?.name}</h3>
          <h3 className="bg-#333333">
            {`${hospitalData?.corporateAddress[0]?.city},${hospitalData?.corporateAddress[0]?.state} | ${hospitalData?.corporateAddress[0]?.zipCode}`}
          </h3>
        </div>
      </div>
      <div className="border border-red-500 w-full mt-2 p-0"></div>
      <div className="grid gap-4 w-full grid-cols-2 px-5 py-4">
        <div>
          <div className="" style={{ fontSize: "25px" }}>
            Contact information{" "}
          </div>
          <div className="relative t2_sp_work_container p-8 gap-0 mt-2">
            <div className="grid gap-3 grid-cols-2">
              <div className="flex flex-col gap-4">
                <div className="">
                  <h6 style={{ fontSize: 16 }}>Name </h6>
                  <h5
                    className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                    title={"state.name"}
                  >
                    {hospitalData?.name}
                  </h5>
                </div>
                <div className="">
                  <h6 style={{ fontSize: 16 }}> Job title </h6>
                  <h5
                    className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                    title={"state.hiringRole"}
                  >
                    {" "}
                    {hospitalData?.hiringRole}
                  </h5>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="">
                  <h6 style={{ fontSize: 16 }}> Email address </h6>
                  <h5
                    className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                    title={"state.email"}
                  >
                    {hospitalData?.email}
                  </h5>
                </div>
                <div className="">
                  <h6 style={{ fontSize: 16 }}> Phone number </h6>
                  <h5
                    className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                    title={"state.phone"}
                  >
                    {hospitalData?.phone}
                  </h5>
                </div>
              </div>
            </div>
            <div>
              {(profile?.role == "hospital" ||
                profile?.role == "superAdmin") && (
                <img
                  alt="icon.svg"
                  src={edit_note}
                  width="20px"
                  className="cursor-pointer top-2 right-2 absolute"
                  onClick={openModal}
                />
              )}
            </div>
          </div>
        </div>
        <CorporateAddress
          hospitalData={hospitalData}
          setHospitalDetails={setHospitalDetails}
        />
      </div>

      <Modal isOpen={modalOpen} onClose={closeModal} className="md:w-1/2">
        <div>
          <div className="flex gap-3 p-4">
            <div className="ssu2_modal1_text1 ml-5 text-xl">
              Edit Contact information
            </div>
          </div>
          <div className="">
            Name
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="Full Name"
                  type="text"
                  defaultValue={hospitalData?.name || state.name}
                  name="name"
                  onChange={handleVal}
                />
              </div>
            </div>
            Job title
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="Job Title"
                  type="text"
                  defaultValue={hospitalData?.hiringRole || state.hiringRole}
                  name="hiringRole"
                  onChange={handleVal}
                />
              </div>
            </div>
            Company email address
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <input
                  className="ssu2_modal3_select pl-5 cursor-not-allowed"
                  placeholder="Company Email Address"
                  type="text"
                  defaultValue={hospitalData?.email || state.email}
                  name="email"
                  readOnly
                  // onChange={handleVal}
                />
              </div>
            </div>
            Phone number
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="Phone Number"
                  type="number"
                  min="1"
                  max="9999999999"
                  onKeyDown={formatInput}
                  defaultValue={hospitalData?.phone || state.phone}
                  name="phone"
                  onChange={handleVal}
                />
              </div>
            </div>
            {/* Buttons */}
            <div className="flex justify-end gap-3 mt-4">
              <button
                className="w-32 bg-white  border-1 text-[#73C7A9] rounded-[28px] border !border-[#73C7A9] p-2 hover:!bg-[#73C7A9] hover:text-white"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className={
                  state.name.length === 0 ||
                  state.hiringRole.length === 0 ||
                  state.email.length === 0 ||
                  state.phone.length === 0
                    ? "w-32 text-white bg-[#73C7A9] opacity-50 rounded-[28px] p-2"
                    : " w-32 text-white bg-[#73C7A9] rounded-[28px] p-2"
                }
                onClick={() => continueToNext()}
              >
                {showLoader ? <Loader /> : "Update"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Contact;
