import React from "react";
// import Part1 from "./part1";
// import Part2 from "./part2";
// import Part3 from "./part3";
// import Part4 from "./part4";
// import Part5 from "./part5";
// import Part6 from "./part6";
// import Part7 from "./part7";
// import Part8 from "./part8";
// import Part9 from "./part9";
import "./landing.css";
// import Nav from "../Nav";
// import SignUpHeader from "../LandingHeader";
// import LandingHeader from "../LandingHeader";
// import { useHistory } from "react-router-dom";
import LogIn from "../logIn/logIn";
const Landing = () => {
  // const token = localStorage.getItem("accessToken");

  return (
    <div>
      {/* {token ? <Nav /> : <LandingHeader />}
      <Part1 />
      <Part2 />
      <Part3 />
      <Part4 />
      <Part5 />
      <Part6 />
      <Part7 />
      <Part8 />
      <Part9 /> */}
      <LogIn />
    </div>
  );
};

export default Landing;
