import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import edit from "../../../assets/images/edit_note.svg";
import del from "../../../assets/images/delete.svg";
import calendar from "../../../assets/images/calendar.png";
import profile from "../../../assets/images/profile.svg";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import DatePicker from "react-datepicker";
import Confirm from "../../../modals/confirm";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { callApi } from "../../../../redux/action/action";
import $ from "jquery";
import moment from "moment";
import { axiosInstance } from "../../../../config/axiosConfig";

const DrugTest = () => {
  const [drugTest, setDrugTest] = useState("");
  const [report, setReport] = useState("");
  const [testDate, setTestDate] = useState("");
  const [status, setStatus] = useState("");
  const [furl, setFurl] = useState("");
  const [showEditModal, setShowEditModal] = useState("none");
  const [file, setFile] = useState(false);
  const [error, setError] = useState({
    file: "none",
    testDate: "none",
    status: "none",
  });
  const [showConfirm, setShowConfirm] = useState("none");
  const [confirmTitle, setConfirmTitle] = useState("");
  const [agreeFn, setAgreeFn] = useState(null);
  const [title, setTitle] = useState("Add");
  const [isAdmin, setIsAdmin] = useState(false);

  const dispatch = useDispatch();
  const typeData = localStorage.getItem("type");
  const admin = useSelector((state) => state.admin);

  useEffect(() => {
    if (typeData === "admin") {
      setIsAdmin(true);
    }
    if (admin) {
      var id = window.localStorage.getItem("Staffid");
    } else {
      var id = window.localStorage.getItem("_id");
    }
    var Authorization = "Bearer ".concat(window.localStorage.getItem("token"));

    async function fetchData() {
      const res = await axiosInstance.get(`v1/LHS/staff/getById/${id}`);
      if (res.data) {
        setDrugTest(res.data.drugTest);
        setReport(res.data.drugTest ? res.data.drugTest.report : "");
        setTestDate(new Date(res.data.drugTest.testDate));
        setStatus(res.data.drugTest.status);
      }
    }

    fetchData();
  }, [admin]);

  const addDrugTest = () => {
    $("#finput").val(null);
    setShowEditModal("block");
    setFurl("");
    setFile(false);
    setTestDate("");
    setStatus("");
    setError({
      file: "none",
      testDate: "none",
      status: "none",
    });
    setTitle("Add");
  };

  const handleSave = async () => {
    var state = {
      ...this.state,
    };

    var file =
      (state.error.file === "block" || state.file === false) &&
      state.furl === ""
        ? "block"
        : "none";
    var testDate =
      state.error.testDate === "block" || state.testDate === ""
        ? "block"
        : "none";
    var status =
      state.error.status === "block" || state.status === "" ? "block" : "none";
    var temp = {
      file: file,
      testDate: testDate,
      status: status,
    };
    setError({ ...temp });

    if (file === "none" && testDate === "none" && status === "none") {
      if (furl !== report) {
        var res = await callApi(
          "POST",
          "v1/LHS/file/upload",
          null,
          { file: file },
          true
        );
        console.log(res);
        setReport(res.upload.upload.link);
      }
      console.log(report);
      updateDB(report);
    }
  };

  const updateDB = async (drugTest) => {
    console.log(drugTest);

    if (title === "Add") {
      var passDate = moment(testDate).add(1, "d");

      var data = {
        drugTest: {
          report: drugTest,
          status: status,
          testDate: testDate,
        },
      };
      var id = localStorage.getItem("_id");
      var Authorization = "Bearer ".concat(
        window.localStorage.getItem("token")
      );
      var res = await callApi(
        "PUT",
        `v1/LHS/staff/update/${id}`,
        Authorization,
        data
      );
      console.log(res);
      if (res.data) {
        fetchData();
        closeModal();
      }
    }

    if (title === "Edit") {
      var state = {
        ...this.state,
      };

      var file =
        (state.error.file === "block" || state.file === false) &&
        state.furl === ""
          ? "block"
          : "none";
      var testDate =
        state.error.testDate === "block" || state.testDate === null
          ? "block"
          : "none";
      var status =
        state.error.status === "block" || state.status === ""
          ? "block"
          : "none";
      var temp = {
        file: file,
        testDate: testDate,
        status: status,
      };
      setError({ ...temp });

      var passDate = moment(testDate).add(1, "d");
      if (file === "none" && testDate === "none" && status === "none") {
        var data = {
          drugTest: {
            report: drugTest,
            status: status,
            testDate: testDate,
          },
        };
        var id = localStorage.getItem("_id");
        var Authorization = "Bearer ".concat(
          window.localStorage.getItem("token")
        );
        var res = await callApi(
          "PUT",
          `v1/LHS/staff/update/${id}`,
          Authorization,
          data
        );
        if (res.data) {
          fetchData();
          closeModal();
        }
      }
    }
  };

  const closeModal = () => {
    setShowEditModal("none");
  };

  const confirmDelete = () => {
    setShowConfirm("block");
    setConfirmTitle("Drug Test");
    setAgreeFn(deleteDrugTest);
  };

  const deleteDrugTest = async () => {
    var data = {
      drugTest: {
        report: "",
        testDate: "",
        status: "",
      },
    };
    var token = "Bearer " + localStorage.getItem("token");
    var id = localStorage.getItem("_id");
    var res = await callApi("PUT", "v1/LHS/staff/update/" + id, token, data);
    if (res.data) {
      setShowConfirm("none");
      fetchData();
    }
  };

  const edit = () => {
    $("#finput").val(null);
    setShowEditModal("block");
    setFurl(report);
    setFile(false);
    setTestDate(new Date(testDate));
    setStatus(status);
    setError({
      file: "none",
      testDate: "none",
      status: "none",
    });
    setTitle("Edit");
  };

  const clickInput = () => {
    $("#finput").click();
  };

  const setTestDate1 = (date) => {
    setTestDate(date);
    var error = error;
    error.testDate = "none";
    setError(error);
  };

  const setStatus1 = (e) => {
    if (e.target.value.length < 51) {
      setStatus(e.target.value);
      if (e.target.value !== "") {
        var error = error;
        error.status = "none";
        setError(error);
      }
    }
  };

  const fileHandler = (e) => {
    var file = e.target.files[0];
    var error = error;
    if (file) {
      let fileType = file.type;
      let validExtensions = ["image/jpeg", "image/jpg", "image/png"];
      if (validExtensions.includes(fileType)) {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          let fileURL = fileReader.result;
          setFurl(fileURL);
        };
        fileReader.readAsDataURL(file);
        error.file = "none";
        setError(error);
      } else {
        error.file = "block";
        setError({
          ...error,
          file: "block",
        });
        $("#finput").val(null);
      }
    } else {
      setFile(false);
      setFurl("");
      $("#finput").val(null);
    }
  };

  var date = new Date(drugTest && testDate);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yy = date.getFullYear();
  dd = dd < 10 ? "0" + dd : dd;
  mm = mm < 10 ? "0" + mm : mm;
  var date_received = mm + "/" + dd + "/" + yy;

  return (
    <div className="nur_text1" style={{ marginTop: "48px" }}>
      <h4 style={{ textAlign: "left" }}>Drug Test</h4>
      <hr className="t2_sp_hr" />
      <div
        className="row"
        style={{
          overflowX: "auto",
          marginTop: "32px",
          display: drugTest && drugTest.status ? "block" : "none",
        }}
      >
        <table
          style={{ minWidth: "800px", marginBottom: "20px", textAlign: "left" }}
        >
          <thead>
            <tr className="nurseHead">
              <th className="t2_sp_licence_th" style={{ paddingLeft: "50px" }}>
                Report
              </th>
              <th className="t2_sp_licence_th">Test Date</th>
              <th className="t2_sp_licence_th">Status</th>
              {isAdmin ? (
                ""
              ) : (
                <th className="t2_sp_licence_th" style={{ width: "130px" }}>
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: 20 }} />
            <tr className="t2_sp_licence_row">
              <td style={{ paddingLeft: "50px" }}>
                <a href={drugTest && report} download target="new">
                  <img
                    alt="img"
                    width="80"
                    height="48"
                    src={drugTest && report}
                  />
                </a>
              </td>
              <td className="t2_sp_licence_td">
                {" "}
                {drugTest &&
                  moment(drugTest.testDate).format("MM/DD/YYYY")}{" "}
              </td>
              <td className="t2_sp_licence_td">
                {" "}
                {drugTest && drugTest.status}{" "}
              </td>
              {isAdmin ? (
                ""
              ) : (
                <td>
                  <span style={{ marginRight: "20px" }} onClick={() => edit()}>
                    <img
                      width="20px"
                      height="20px"
                      alt="img"
                      src={edit}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                  <span onClick={() => confirmDelete()}>
                    <img
                      width="20px"
                      height="18px"
                      alt="img"
                      src={del}
                      style={{ cursor: "pointer", display: "inline" }}
                    />
                  </span>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      {isAdmin ? (
        ""
      ) : (
        <div
          className="ssu2_addItem"
          onClick={addDrugTest}
          style={{ display: drugTest && drugTest.testDate ? "none" : "block" }}
        >
          + Add Drug Test
        </div>
      )}
      <div className="w3-modal " style={{ display: showEditModal }}>
        <div className="w3-modal-content ssu2_modal1 ">
          <div className="w3-container ">
            <div className="ssu2_modal1_text1 ">{title} Drug Test</div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="ssu2_modal1_input" style={{ marginTop: "30px" }}>
              <div className="row t2_sp_editdrug_part1">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={furl}
                    style={{ display: furl === "" ? "none" : "block" }}
                    alt="drag report img"
                    className="t2_sp_editdrug_img"
                  />
                </div>
                <button
                  className="col-md-6 col-sm-12"
                  style={{
                    cursor: "default",
                    backgroundColor: "white",
                    borderWidth: "0px",
                  }}
                >
                  <div className="row">
                    <button
                      id={"fbutton"}
                      className="t2_sp_editdrug_btn"
                      onClick={clickInput}
                    >
                      <span>
                        <CloudUploadIcon style={{ marginRight: "12px" }} />
                      </span>
                      UPLOAD NEW{" "}
                    </button>
                  </div>
                  <input
                    id={"finput"}
                    type="file"
                    customhidden
                    onChange={fileHandler}
                    name="file"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleSave();
                    }}
                    placeholder="upload jpeg, jpg or png file"
                  />
                </button>
                <ErrorState
                  name="The PNG, JPEG, JPG file is required."
                  show={error.file}
                />
                <div className="ssu2_modal1_text2">
                  Please upload only png, jpg, or jpeg images
                </div>
              </div>
              <div className="ssu2_modal1_input" style={{ marginTop: "18px" }}>
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div
                    className="input_left_icon"
                    onClick={() => $(".ssu2_modal3_date").focus()}
                  >
                    <img width="22px" height="17px" alt="img" src={calendar} />
                  </div>
                  <DatePicker
                    placeholderText={"Test Date"}
                    className="ssu2_modal3_date"
                    selected={testDate}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    onChange={(date) => setTestDate(date)}
                    maxDate={new Date()}
                  />
                </div>
                <ErrorState
                  name="Test Date is required."
                  show={error.testDate}
                />
              </div>
            </div>
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal1_input">
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div className="input_left_icon">
                    <img
                      width="22px"
                      height="23px"
                      alt="img"
                      src={profile}
                      style={{ marginTop: "-3px" }}
                    />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="Status"
                    type="text"
                    value={status}
                    onChange={setStatus1}
                  />{" "}
                </div>
                <ErrorState show={error.status} name="Status is required." />
              </div>
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="row ssu_bottom">
              <button className="ssu2_modal1_button1" onClick={handleSave}>
                {" "}
                SAVE{" "}
              </button>
              <button
                className="border !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                onClick={closeModal}
              >
                {" "}
                CANCEL{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        title={'Delete "' + confirmTitle + '"'}
        display={showConfirm}
        agreeFn={agreeFn}
        content={'Are you sure you want to delete "' + confirmTitle + '"'}
        disagreeFn={() => setShowConfirm("none")}
      />
    </div>
  );
};

export default DrugTest;
