import React, { useState, useEffect } from "react";
import edit_note from "../../assets/images/edit_note.svg";
import user from "../../assets/images/user.svg";
import email from "../../assets/images/email.svg";
import phone from "../../assets/images/phone.svg";
import jobTitle from "../../assets/images/jobTitle.svg";
import ErrorState from "../../theme_1/staffSignUp/components/errorState";
import { callApi } from "../../../redux/action/action";
import right_arrow from "../../assets/images/right_arrow.svg";
import { axiosInstance } from "../../../config/axiosConfig";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import Modal from "../../common/modal";
import { useDispatch, useSelector } from "react-redux";

const ContactDetails = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setId] = useState(false);
  const openIsModal = () => {
    setModalOpen(true);
  };
  const profile = useSelector((state) => state.data.profile);
  const dispatch = useDispatch();
  const closeModal = () => {
    setModalOpen(false);
  };
  const [state, setState] = useState({
    _id: "",
    email: "",
    name: "",
    phone: "",
    jobTitle: "",
    modalShow: "none",
    memail: "",
    fname: "",
    mphone: "",
    mjobTitle: "",
    error: {
      fname: "none",
      email: "none",
      phone: "none",
      jobTitle: "none",
    },
    emailErr: "This email is already used.",
  });

  const fetchData = async (id) => {
    const res = await axiosInstance.get(`v1/LHS/staff/getById/${id || userId}`);
    if (res.data?.data) {
      setState((prevState) => ({
        ...prevState,
        name: res.data?.data?.name,
        email: res.data?.data?.email,
        phone: res.data?.data?.phone,
        jobTitle: res.data?.data?.jobTitle,
      }));
    }
  };
  useEffect(() => {
    setId(props.adminId || profile?._id);
    fetchData(props.adminId || profile?._id);
  }, [props.adminId, profile?._id]);

  const edit = () => {
    openIsModal();
    setState((prevState) => ({
      ...prevState,
      fname: prevState.name,
      memail: prevState.email,
      mphone: prevState.phone,
      mjobTitle: prevState.jobTitle,
      modalShow: "block",
      error: {
        fname: "none",
        email: "none",
        phone: "none",
        jobTitle: "none",
      },
    }));
  };

  const setName = (e) => {
    if (e.target.value.length < 51) {
      setState((prevState) => ({
        ...prevState,
        fname: e.target.value,
      }));

      if (e.target.value !== "") {
        setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            fname: "none",
          },
        }));
      }
    }
  };

  const preventNumberInput = (e) => {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      ((keyCode > 47 && keyCode) < 58 || (keyCode > 95 && keyCode < 107)) &&
      keyCode !== 8 &&
      keyCode !== 9 &&
      keyCode !== 32
    ) {
      e.preventDefault();
    }
  };

  const setjobTitle = (e) => {
    if (e.target.value.length < 51) {
      setState((prevState) => ({
        ...prevState,
        mjobTitle: e.target.value,
      }));

      if (e.target.value !== "") {
        setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            jobTitle: "none",
          },
        }));
      }
    }
  };

  const setEmail = (e) => {
    setState((prevState) => ({
      ...prevState,
      memail: e.target.value,
      emailErr: "Valid email address is required.",
    }));

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zAZ]{2,}))$/;
    var valid = re.test(String(e.target.value).toLowerCase());
    var error = state.error;

    if (e.target.value !== "" && valid) {
      error.email = "none";
      setState((prevState) => ({
        ...prevState,
        error: error,
      }));
    } else {
      error.email = "block";
      setState((prevState) => ({
        ...prevState,
        error: error,
      }));
    }
  };

  const setPhone = (e) => {
    if (e.target.value.length < 11) {
      setState((prevState) => ({
        ...prevState,
        mphone: e.target.value,
      }));

      var validate;
      if (e.target.value.match(/\d/g))
        if (e.target.value.match(/\d/g).length === 10) validate = true;
        else validate = false;
      else validate = false;

      var error = state.error;

      if (e.target.value !== "" && validate) {
        error.phone = "none";
        setState((prevState) => ({
          ...prevState,
          error: error,
        }));
      } else {
        error.phone = "block";
        setState((prevState) => ({
          ...prevState,
          error: error,
        }));
      }
    }
  };

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const updateDB = async () => {
    var data = {
      name: state.fname,
      email: state.memail,
      phone: state.mphone,
      jobTitle: state.mjobTitle,
    };

    var token = "Bearer " + localStorage.getItem("accessToken");
    var res = await callApi(
      "PUT",
      `v1/LHS/staff/update/${userId}`,
      token,
      data
    );
    if (res.data) {
      showSuccessToast(res?.Message);
      modalClose();
      closeModal();
      fetchData();
    }
    data = {
      name: res.data.name,
      email: res.data.email,
      phone: res.data.phone,
      jobTitle: res.data.jobTitle,
    };
  };

  const emailRepeatCheck = async () => {
    if (state.email !== state.memail) {
      var data = {
        email: state.memail,
      };
      var res1 = await callApi(
        "POST",
        "v1/LHS/hospital/emailCheck",
        null,
        data
      );
      var res2 = await callApi("POST", "v1/LHS/staff/emailCheck", null, data);
      if (res1.result === "OK" && res2.result === "OK") {
        updateDB();
      } else {
        setState((prevState) => ({
          ...prevState,
          emailErr: "This Email address is already in use.",
        }));
        var err = state.error;
        err.email = "block";
        setState((prevState) => ({
          ...prevState,
          error: err,
        }));
      }
    } else {
      updateDB();
    }
  };

  const continueToNext = () => {
    var fname =
      state.error.fname === "block" || state.fname === "" ? "block" : "none";
    var email =
      state.error.email === "block" || state.memail === "" ? "block" : "none";
    var phone =
      state.error.phone === "block" || state.mphone === "" ? "block" : "none";
    var jobTitle =
      state.error.jobTitle === "block" || state.mjobTitle === ""
        ? "block"
        : "none";

    var error = {
      fname: fname,
      email: email,
      phone: phone,
      jobTitle: jobTitle,
    };
    setState((prevState) => ({
      ...prevState,
      error: error,
    }));

    if (
      fname === "none" &&
      email === "none" &&
      phone === "none" &&
      jobTitle === "none"
    ) {
      emailRepeatCheck();
    }
  };

  const modalClose = () => {
    setState((prevState) => ({
      ...prevState,
      modalShow: "none",
      error: {
        fname: "none",
        email: "none",
        phone: "none",
        jobTitle: "none",
      },
    }));
  };

  return (
    <div className="t2_sp_work">
      <div className="t2_sp_work_title">Contact Details</div>
      <div className="t2_sp_work_container w-1/2 relative">
        <div className="grid grid-cols-2 w-full items-center gap-10">
          <div>
            <div className="mb-4">
              <h6 style={{ fontSize: 16 }}> Full Name </h6>
              <h5 className="mt-[10px] text-[20px]" title={state.fname}>
                {state.name}{" "}
              </h5>
              {/* <hr style={{ marginBottom: 18 }} /> */}
            </div>
            <div className="">
              <h6 style={{ fontSize: 16 }}> Job Title </h6>
              <h5 className="mt-[10px] text-[20px]" title={state.mjobTitle}>
                {state.jobTitle}
              </h5>
              {/* <hr style={{ marginBottom: 18 }} /> */}
            </div>
          </div>
          <div>
            <div className="mb-4">
              <h6 style={{ fontSize: 16 }}> Email Address </h6>
              <h5 className="mt-[10px] text-[20px] text-ellipsis overflow-hidden">
                {state.email}
              </h5>
              {/* <hr style={{ marginBottom: 18 }} /> */}
            </div>
            <div className="">
              <h6 style={{ fontSize: 16 }}> Phone Number </h6>
              <h5 className="mt-[10px] text-[20px]">{state.phone}</h5>
              {/* <hr style={{ marginBottom: 18 }} /> */}
            </div>
          </div>
          <div className="absolute right-2 top-2">
            {(profile?.role == "staff" || profile?.role == "superAdmin") && (
              <img
                alt="icon.svg"
                src={edit_note}
                width="20px"
                style={{ cursor: "pointer" }}
                onClick={edit}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        style={{ backgroundColor: "red" }}
        className="!w-[800px]"
      >
        <div className="">
          <div className="w-full  ">
            <div className="w3-modal">
              <div className="w3-modal-content" style={{ overflowY: "auto" }}>
                <div className="">
                  <div className="flex items-center gap-3 font-semibold">
                    <div className="ssu2_modal1_text1 text-xl">
                      Edit Contact Details
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="text-base mb-2">Name</div>
                    <div className="ssu2_modal1_input p-0 mb-4" style={{}}>
                      <div className="ssu2_modal1_input p-0 ssu2_modal3_selectBox">
                        <div className="input_left_icon">
                          {/* <img
                            width="18px"
                            height="15px"
                            alt="img"
                            src={user}
                          /> */}
                        </div>
                        <input
                          className="ssu2_modal3_select pl-5 !border-slate-300 !shadow-none"
                          placeholder="Full Name"
                          type="text"
                          value={state.fname}
                          onChange={setName}
                          onKeyDown={preventNumberInput}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") continueToNext();
                          }}
                        />
                      </div>
                      <ErrorState
                        show={state.error.fname}
                        name={"Full Name is required."}
                      />
                    </div>
                    <div className="text-base mb-2">Job tittle</div>
                    <div className="ssu2_modal1_input p-0 mb-4">
                      <div className="ssu2_modal1_input p-0 ssu2_modal3_selectBox">
                        <div className="input_left_icon">
                          {/* <img
                            alt="img"
                            width="18px"
                            height="15px"
                            src={jobTitle}
                          /> */}
                        </div>
                        <input
                          className="ssu2_modal3_select pl-5 !border-slate-300 !shadow-none"
                          placeholder="Job Title"
                          type="text"
                          value={state.mjobTitle}
                          onChange={setjobTitle}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") continueToNext();
                          }}
                        />
                      </div>
                      <ErrorState
                        show={state.error.jobTitle}
                        name="Job Title is required."
                      />
                    </div>
                    <div className="text-base mb-2">Email address</div>
                    <div className="ssu2_modal1_input p-0 mb-4">
                      <div className="ssu2_modal1_input p-0 ssu2_modal3_selectBox">
                        <div className="input_left_icon">
                          {/* <img
                            width="18px"
                            height="15px"
                            alt="img"
                            src={email}
                          /> */}
                        </div>
                        <input
                          className="ssu2_modal3_select pl-5 !border-slate-300 !shadow-none"
                          placeholder="Company Email Address"
                          type="text"
                          value={state.memail}
                          readOnly
                          onKeyUp={(e) => {
                            if (e.key === "Enter") continueToNext();
                          }}
                        />
                      </div>
                      <ErrorState
                        show={state.error.email}
                        name={state.emailErr}
                      />
                    </div>
                    <div className="text-base mb-2">Phone number</div>
                    <div className="ssu2_modal1_input p-0 mb-4">
                      <div className="ssu2_modal1_input p-0 ssu2_modal3_selectBox">
                        <div className="input_left_icon">
                          {/* <img
                            width="18px"
                            height="15px"
                            alt="img"
                            src={phone}
                          /> */}
                        </div>
                        <input
                          className="ssu2_modal3_select pl-5 !border-slate-300 !shadow-none"
                          placeholder="Phone Number"
                          type="number"
                          min="1"
                          max="9999999999"
                          onKeyDown={formatInput}
                          value={state.mphone}
                          onChange={setPhone}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") continueToNext();
                          }}
                        />
                      </div>
                      <ErrorState
                        show={state.error.phone}
                        name="10 digits phone number is required."
                      />
                    </div>
                    <div className="flex justify-end gap-3 mt-4">
                      <button
                        className="w-[120px] bg-white  border-1 text-[#73C7A9] rounded-[28px] border !border-[#73C7A9] p-2 hover:!bg-[#73C7A9] hover:text-white"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="w-[120px] text-white bg-[#73C7A9] rounded-[28px] p-2"
                        onClick={continueToNext}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContactDetails;
