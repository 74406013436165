import React, { useEffect, useState } from "react";
import topImg from "../../assets/images/topImg.png";
import leftArrow from "../../assets/images/leftArrow.png";
import user from "../../assets/images/user.png";
import Nav from "../Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RightSideBar from "../../containers/commonComponent/rightSideBar";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";

const LikedPostList = (props) => {
  const history = useHistory();

  const { location } = props || {};
  const postId = location?.state?.postId || "";
  const commentId = location?.state?.commentId || "";
  const userRole = props?.userId?.role.split(/(?=[A-Z])/);
  const JoinRole = userRole?.join(" ");
  const profile = useSelector((state) => state.data.profile);
  const jobData = [
    {
      id: "0",
      Value: "Primary Care Doctor",
    },
    {
      id: "1",
      value: "Urgent Care",
    },
    {
      id: "2",
      value: "Dermatologist",
    },
    {
      id: "3",
      value: " OB-GYN",
    },
    {
      id: "4",
      value: "Dentist",
    },
    {
      id: "5",
      value: "Psychiatrist",
    },
    {
      id: "6",
      value: "Ear, Nose & Throat Doctor",
    },
    {
      id: "7",
      value: "Gastroenterologist",
    },
    {
      id: "8",
      value: "Therapist Counselor",
    },
    {
      id: "9",
      value: " Physical Therapist",
    },
  ];
  const [viewAll, setViewAll] = useState(false);
  const currentJobData = viewAll ? jobData : jobData?.slice(0, 5);
  const [likedList, setLikedList] = useState([]);
  const [likedCount, setLikedCount] = useState([]);

  function calculateTime(time) {
    const x = new Date();
    const y = new Date(time);

    function compare(x, y) {
      return Math.abs(x - y);
    }

    const diff = compare(x, y);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));

    const yesterday = new Date(x.getFullYear(), x.getMonth(), x.getDate() - 1);
    const toDate = new Date(y.getFullYear(), y.getMonth(), y.getDate());

    let hoursMins = "";
    let result = "";

    if (toDate.getTime() === yesterday.getTime()) {
      result = "1d";
      return result;
    } else {
      hoursMins = format(y, "h:mm a");

      if (days > 365) {
        result = `${Math.floor(days / 365)}Y`;
      } else if (days > 30) {
        result = `${Math.floor(days / 30)}m`;
      } else if (days > 7) {
        result = `${Math.floor(days / 7)}w`;
      } else if (days >= 1) {
        result = `${days}d`;
      } else if (hours > 1) {
        result = hoursMins;
      } else if (minutes > 1) {
        result = hoursMins;
      } else {
        result = hoursMins;
      }

      return result;
    }
  }

  const GetLikedPostList = async () => {
    try {
      let url = `/v2/LHS/User/getAllLikesByPostId/${postId}?page=${1}&limit=${10}`;
      const response = await axiosInstance.get(url);
      setLikedList(response?.data?.data?.likeData);
      setLikedCount(response?.data?.data?.likeCount);
    } catch (error) {
      console.error("Error fetching interest jobs:", error);
    }
  };

  const GetLikedCommentList = async () => {
    try {
      let url = `//v2/LHS/User/getAllLikesByCommentId/${commentId}?page=${1}&limit=${10}`;
      const response = await axiosInstance.get(url);
      setLikedList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching interest jobs:", error);
    }
  };

  useEffect(() => {
    GetLikedPostList();
  }, []);

  useEffect(() => {
    GetLikedCommentList();
  }, []);

  return (
    <>
      {profile ? <Nav /> : ""}
      <div
        className="flex justify-center items-center"
        style={{ backgroundColor: "#F3F2EF" }}
      >
        <div className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]">
          <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />
          <div className="flex flex-col gap-2 bg-white">
            <div className={"mt-4 flex items-center"}>
              <div
                className="flex items-center  px-6"
                onClick={() =>
                  history.push({
                    pathname: "/staff",
                  })
                }
              >
                <img
                  src={leftArrow}
                  style={{
                    height: "18px",
                    width: "22px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <h1> {likedCount} Likes</h1>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="border border-[#000000]-600 mt-3 opacity-30  w-[95%]" />
            </div>

            {likedList?.length === 0 ? (
              <div className="flex justify-center items-center p-3 rounded-[10px] h-full 2xl:w-[760px] lg:w-[666px] sm:w-full font-semibold text-[24px]">
                <h2>No data found</h2>
              </div>
            ) : (
              likedList?.map((item, index) => {
                const userRole = item?.userId?.role.split(/(?=[A-Z])/);
                const JoinRole = userRole.join(" ");
                return (
                  <div
                    className="flex justify-between p-3 bg-white rounded-[10px] h-[70px] 2xl:w-[760px] lg:w-[666px] sm:w-full"
                    key={index}
                  >
                    <div className="flex items-center gap-3">
                      <div className="w-12 min-w-[40px] h-12 rounded-full overflow-hidden">
                        <img
                          // src={topImg}
                          src={item?.userId?.image}
                          alt=""
                          className="object-cover w-full h-full"
                        />
                      </div>
                      <div>
                        <h3 className="p-0 text-[#333333] text-[18px] font-medium">
                          {item?.userId?.name
                            ? item?.userId?.name
                            : item?.userId?.firstName +
                              " " +
                              item?.userId?.lastName}
                        </h3>
                        <p className="text-gray-500 text-[15px] font-normal">
                          {item?.userId?.role == "hospital"
                            ? item?.userId?.hiringRole
                            : item?.userId?.role == "staff"
                            ? item?.userId?.jobTitle
                            : JoinRole}
                        </p>
                      </div>
                    </div>
                    <p className="text-[12px] text-gray-500 mt-1 font-normal">
                      {/* {item?.pushType} */}
                      {calculateTime(item?.createdAt)}
                    </p>
                  </div>
                );
              })
            )}
          </div>
          <RecommendedForYou />
        </div>
      </div>
    </>
  );
};

export default LikedPostList;
