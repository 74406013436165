import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/images/Logo.png";
import settings from "../../assets/images/settings.png";
import msg from "../../assets/images/msg.png";
import user from "../../assets/images/user.png";
import drop_down from "../../assets/images/drop_down.png";
import Modal from "../../common/modal";
import { Link, useLocation } from "react-router-dom";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import HomeIcon from "@material-ui/icons/Home";
import blueMyJobIcon from "../../assets/images/blueMyJobIcon.svg";
import myJobIcon from "../../assets/images/myJobIcon.svg";

const JobsHeader = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNav, setSelectedNav] = useState("");
  const location = useLocation();
  const openRightMenu = () => {
    setIsOpen(!isOpen);
  };
  const openModal = (str) => {
    setModalOpen(true);

    setSelectedNav(str);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const navTo = () => {};

  const token = localStorage.getItem("accessToken");
  const navType = localStorage.getItem("type");
  const [showNotificationCount, setShowNotificationCount] = useState(true);
  const [notificationClicked, setNotificationClicked] = useState(false);

  const handleClick = () => {
    if (location.pathname === "/notification") {
      return;
    }
    setShowNotificationCount((prevIcon) => !prevIcon);
    setNotificationClicked(true); // Set the notification as clicked
    history.push("/notification");
  };
  const modalPopup = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalPopup?.current && !modalPopup.current.contains(event.target)) {
        setModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalPopup]);
  return (
    <div className="">
      <div
        className="flex items-center py-3 w-11/12 m-auto max-w-[1500px]"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="w-56 sm:w-60 lg:w-52 " onClick={() => navTo("")}>
          <img alt="img" src={logo} />
        </div>
        <div className="items-center justify-center w-auto  mdx:flex hidden">
          <div
            className=" flex items-center px-3"
            style={{ cursor: "pointer" }}
            onClick={() => openModal("Home")}
          >
            <HomeIcon
              className={`!h-[24px] !w-[24px] mr-2.5 ${
                location.pathname === "/Home"
                  ? "text-[#73C7A9]"
                  : "text-gray-500 hover:text-gray-500"
              }`}
            />
            Home
          </div>

          <Link
            to="/jobs"
            className={`flex items-center px-3 ${
              location.pathname === "/jobs" ||
              location.pathname.split("/")[1] == "myJobDetails"
                ? "text-[#73C7A9] hover:text-[#73C7A9]"
                : "hover:text-black"
            }`}
            style={{ cursor: "pointer" }}
          >
            <PageviewOutlinedIcon
              className={`h-5 w-4 mr-2 ${
                location.pathname === "/jobs" ||
                location.pathname.split("/")[1] == "myJobDetails"
                  ? "text-[#73C7A9]"
                  : "text-gray-500 hover:text-gray-500"
              }`}
            />
            Jobs
          </Link>
          <div
            className=" flex items-center px-3"
            style={{ cursor: "pointer" }}
            onClick={() => openModal("My jobs")}
          >
            {location.pathname === "/myjobs" ? (
              <img
                src={blueMyJobIcon}
                alt=""
                className="mr-2 !h-[16px] !w-[18px]"
              />
            ) : (
              <img
                src={myJobIcon}
                alt=""
                className="mr-2 !h-[18px] !w-[19px]"
                style={{ color: "gray" }}
              />
            )}
            My jobs
          </div>
          <div
            className=" flex items-center px-3"
            style={{ cursor: "pointer" }}
            onClick={() => openModal("Messaging")}
          >
            <img src={msg} className=" h-4 w-4 mr-2 active:bg-[#73C7A9]" />
            Messaging
          </div>
          <div
            className=" flex items-center px-3"
            style={{ cursor: "pointer" }}
            onClick={() => openModal("Settings")}
          >
            <img src={settings} className=" h-4 w-4 mr-2 active:bg-[#73C7A9]" />
            Settings
          </div>
        </div>

        <div className="flex items-center justify-between gap-4 relative">
          <div className="flex items-center justify-between gap-4 cursor-pointer">
            <div
              className="flex items-center gap-1 relative"
              onClick={openRightMenu}
            >
              <img src={user} className=" h-10 w-10 rounded-full mr-2" />
              {/* <img src={drop_down} className="h-[10px] w-[10px] mr-2" /> */}
            </div>
          </div>
          {isOpen && (
            <div className="dropdown-card rounded-[10px]">
              <a
                onClick={() => {
                  openModal("Profile");
                }}
                className="cursor-pointer"
              >
                Login
              </a>
            </div>
          )}
        </div>

        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          className={"md:w-1/3 md:mt-0"}
        >
          <div
            ref={modalPopup}
            className="bg-white z-10 w-full h-full max-w-[500px] max-h-[224px] rounded-lg"
          >
            <div className="">
              <p className="text-[20px] text-center m-auto mb-5">
                {`To ${selectedNav}, please log in or sign up.`}
              </p>

              <div className="text-center">
                <button
                  onClick={() => {
                    history.push("/login");
                    setModalOpen(false);
                  }}
                  className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5"
                >
                  Sign up
                </button>
                <button
                  className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                  onClick={() => {
                    history.push("/login");
                    setModalOpen(false);
                  }}
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default JobsHeader;
