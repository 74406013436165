import { format, isToday, isYesterday } from "date-fns";

export const formatMessageDate = (createdAt) => {
  const date = new Date(createdAt);
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    return format(date, "MM-dd-yyyy");
  }
};
