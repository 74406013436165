import React, { Component } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import user from "../../assets/images/user.svg";
import phoneIcon from "../../assets/images/phone.svg";
import emailIcon from "../../assets/images/email.svg";
import right_arrow from "../../assets/images/right_arrow.svg";
import key from "../../assets/images/key.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../../redux/action/action";
import ErrorState from "../../theme_1/staffSignUp/components/errorState";
import { axiosInstance } from "../../../config/axiosConfig";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import Loader from "../../modals/Loader";

class ManageAdiministart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      psw: "",
      repsw: "",
      phone: "",
      role: "",
      ein: "",
      showLoader: false,
      selectedPermissions: [],
      adminsList: [],
      error: {
        fname: "none",
        lname: "none",
        email: "none",
        psw: "none",
        repsw: "none",
        phone: "none",
        role: "none",
        ein: "none",
      },
    };
  }
  togglePassword = (id) => {
    let input = $("#" + id);
    if (input.attr("type") === "password") {
      input.attr("type", "text");
      $("#c_" + id).hide();
      $("#o_" + id).show();
    } else {
      input.attr("type", "password");
      $("#o_" + id).hide();
      $("#c_" + id).show();
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
    }));
  };
  setVal = (e) => {
    if (e.target.value.length < 250) {
      this.setState({ [e.target.name]: e.target.value });
      if (e.target.value !== "") {
        var error = this.state.error;
        error[e.target.name] = "none";
        this.setState({ error: error });
      }
    }
  };
  setRole = (e) => {
    if (e.target.value.length < 100) {
      this.setState({ role: e.target.value });
      if (e.target.value !== "") {
        var error = this.state.error;
        error.role = "none";
        this.setState({ error: error });
      }
    }
  };
  setEmail = (e) => {
    this.setState({
      email: e.target.value,
      emailErr: "Valid email address is required.",
    });
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var valid = re.test(String(e.target.value).toLowerCase());
    var error = this.state.error;
    if (e.target.value !== "" && valid) {
      error.email = "none";
      this.setState({ error: error });
    } else {
      error.email = "block";
      this.setState({ error: error });
    }
  };
  setPhone = (e) => {
    if (e.target.value.length < 11) {
      this.setState({ phone: e.target.value });
      var validate;
      if (e.target.value.match(/\d/g))
        if (e.target.value.match(/\d/g).length === 10) validate = true;
        else validate = false;
      else validate = false;

      var error = this.state.error;
      if (e.target.value !== "" && validate) {
        error.phone = "none";
        this.setState({ error: error });
      } else {
        error.phone = "block";
        this.setState({ error: error });
      }
    }
  };

  setPsw = (e) => {
    this.setState({ psw: e.target.value });
    var password = e.target.value;
    var error = this.state.error;
    if (password.length === 0) {
      this.setState({ pswErr: "Password is required." });
      error.psw = "block";
      this.setState({ error: error });
      return;
    } else {
      if (password.length < 6) {
        this.setState({
          pswErr:
            "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
        });
        error.psw = "block";
        this.setState({ error: error });
      } else {
        var matchedCase = [];
        matchedCase.push("[$@$!%*#?&]"); // Special Charector
        matchedCase.push("[A-Z]"); // Uppercase Alpabates
        matchedCase.push("[0-9]"); // Numbers
        matchedCase.push("[a-z]"); // Lowercase Alphabates
        var ctr = 0;
        for (var i = 0; i < matchedCase.length; i++) {
          if (new RegExp(matchedCase[i]).test(password)) {
            ctr++;
          }
        }
        switch (ctr) {
          case 0:
          case 1:
          case 2:
          case 3:
            this.setState({
              pswErr:
                "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
            });
            error.psw = "block";
            this.setState({ error: error });
            break;
          case 4:
            error.psw = "none";
            this.setState({ error: error });
            break;
          default:
            return;
        }
        if (this.state.repsw !== "") {
          var validate = this.state.repsw === e.target.value;
          error = this.state.error;
          if (validate) {
            error.repsw = "none";
            this.setState({ error: error });
          } else {
            error.repsw = "block";
            this.setState({ error: error });
          }
        }
      }
    }
  };

  setRepsw = (e) => {
    var validate = this.state.psw === e.target.value;
    this.setState({ repsw: e.target.value });
    var error = this.state.error;
    if (e.target.value !== "" && validate) {
      error.repsw = "none";
      this.setState({ error: error });
    } else if (e.target.value === "" && this.state.psw === "") {
      error.repsw = "none";
      this.setState({ error: error });
    } else {
      error.repsw = "block";
      this.setState({ error: error });
    }
  };

  setEin = async (e) => {
    const einValue = e.target.value.replace(/\D/g, "");

    this.setState({ ein: einValue });
    if (einValue !== "") {
      var error = this.state.error;
      error.ein = "none";
      this.setState({ error: error });
    }
    var res = await callApi("GET", `v1/LHS/hospital/ein/${einValue}`);
    if (res.status === 500) {
      var error = this.state.error;
      error.ein = "block";
      this.setState({
        error: error,
        einMsgError: "EIN Number already present",
      });
    } else {
      var error = this.state.error;
      error.ein = "none";
      this.setState({
        error: error,
        einMsgError: "The EIN number is required",
      });
    }
  };
  goToNext = () => {
    this.props.history.push("/hospSignUp_2");
  };
  emailRepeatCheck = async () => {
    if (this.state.email !== "") {
      var data = {
        email: this.state.email,
      };
      const [res1, res2, res3] = await Promise.all([
        callApi("POST", "v1/LHS/hospital/emailCheck", null, data),
        callApi("POST", "v1/LHS/staff/emailCheck", null, data),
        callApi("POST", "v1/LHS/admin/emailCheck", null, data),
      ]);
      if (
        res1.result === "OK" &&
        res2.result === "OK" &&
        res3.result === "OK"
      ) {
        this.goToNext();
      } else {
        this.setState({ emailErr: "This Email address is already in use." });
        var err = this.state.error;
        err.email = "block";
        this.setState({ error: err });
      }
    } else {
      var error = this.state.error;
      error.email = "block";
      this.setState({
        error: error,
        emailErr: "Valid Email address is required.",
      });
    }
  };

  continueToNext = () => {
    const { history } = this.props;
    var state = this.state;
    var fname =
      state.error.fname === "block" || state.fname === "" ? "block" : "none";
    var lname =
      state.error.lname === "block" || state.lname === "" ? "block" : "none";
    var email =
      state.error.email === "block" || state.email === "" ? "block" : "none";
    var phone =
      state.error.phone === "block" || state.phone === "" ? "block" : "none";
    var psw =
      state.error.psw === "block" || state.psw === "" ? "block" : "none";
    var repsw =
      state.error.repsw === "block" || state.repsw === "" ? "block" : "none";
    var role =
      state.error.role === "block" || state.role === "" ? "block" : "none";
    var ein =
      state.error.ein === "block" || state.ein === "" ? "block" : "none";
    if (psw === "") {
      this.setState({ pswErr: "Password is required" });
    }
    var error = {
      fname: fname,
      lname: lname,
      email: email,
      phone: phone,
      psw: psw,
      repsw: repsw,
      role: role,
      ein: ein,
    };
    this.setState({ error: { ...error } });

    if (
      fname === "none" &&
      lname === "none" &&
      email === "none" &&
      phone === "none" &&
      psw === "none" &&
      repsw === "none" &&
      role === "none" &&
      ein === "none"
    ) {
      this.emailRepeatCheck();
      window.localStorage.setItem("first_name", this.state.fname);
      window.localStorage.setItem("role", this.state.role);
      window.localStorage.setItem("last_name", this.state.lname);
      window.localStorage.setItem("company_email_address", this.state.email);
      window.localStorage.setItem("phone_number", this.state.phone);
      window.localStorage.setItem("password", this.state.psw);
      window.localStorage.setItem("repassword", this.state.repsw);
      window.localStorage.setItem("role_id", this.state.role);
      window.localStorage.setItem("ein", this.state.ein);
      history.push("/hospSignUp_2");
    }
  };

  backButton = () => {
    const { history } = this.props;
    history.push("/main/admin/manageAdmin");
  };

  handlePermissionChange = (permission, isChecked, _id) => {
    this.setState((prevState) => {
      if (isChecked) {
        return {
          selectedPermissions: [...prevState.selectedPermissions, permission],
        };
      } else {
        return {
          selectedPermissions: prevState.selectedPermissions.filter(
            (p) => p !== permission
          ),
        };
      }
    });
  };
  handleSubmit = async () => {
    const { fname, lname, email, phone, psw, repsw, role, ein } = this.state;
    let formIsValid = true;
    const error = { ...this.state.error };
    if (fname.trim() === "") {
      formIsValid = false;
      error.fname = "block";
    } else {
      error.fname = "none";
    }
    if (lname.trim() === "") {
      formIsValid = false;
      error.lname = "block";
    } else {
      error.lname = "none";
    }
    if (email.trim() === "") {
      formIsValid = false;
      error.email = "block";
    } else {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!emailRegex.test(email.trim())) {
        formIsValid = false;
        error.email = "block";
      } else {
        error.email = "none";
      }
    }
    if (phone.trim() === "" || phone.trim().length !== 10) {
      formIsValid = false;
      error.phone = "block";
    } else {
      error.phone = "none";
    }
    if (psw.trim() === "") {
      formIsValid = false;
      error.psw = "block";
    } else {
      error.psw = "none";
    }
    if (repsw.trim() === "" || repsw.trim() !== psw) {
      formIsValid = false;
      error.repsw = "block";
    } else {
      error.repsw = "none";
    }
    if (role.trim() === "") {
      formIsValid = false;
      error.role = "block";
    } else {
      error.role = "none";
    }
    if (ein.trim() === "") {
      formIsValid = false;
      error.ein = "block";
    } else {
      error.ein = "none";
    }
    this.setState({ error });
    if (formIsValid) {
      this.addAdministrator();
    }
  };

  getAllAdmins = async () => {
    try {
      const res = await axiosInstance.get(
        `v1/LHS/admin/adminList?search=&skip=`
      );
      console.log(res, "resresres");
      console.log(this.props.location.state?.permission, "pr");
      this.setState({
        adminsList: res.data.data,
      });
    } catch (error) {
      showErrorToast("Something went wrong");
    } finally {
      console.log("error");
    }
  };
  addAdministrator = async () => {
    const { fname, lname, email, phone, psw, repsw, role, ein } = this.state;

    const permissions = this.state.selectedPermissions;
    const data = {
      firstName: fname,
      lastName: lname,
      email,
      phone,
      password: psw,
      permissions: permissions,
    };

    try {
      await axiosInstance.post("v1/LHS/admin/add", data);
      showSuccessToast("Add New Administrator successfully!");
      await this.getAllAdmins();
    } catch (error) {
      showErrorToast("Something went wrong");
    } finally {
      console.log("error");
    }
  };

  changeAdminStatus = async (status, id) => {
    console.log(this.props.location.state, "this.props.location.state?");

    var token = localStorage.getItem("accessToken");
    token = "Bearer " + token;
    const { fname, lname, phone, email, psw, repsw, selectedPermissions } =
      this.state;
    const existingUser = this.props.location.state;
    if (!existingUser) {
      showErrorToast("User not found");
      return;
    }

    const updatedUser = {
      ...existingUser,
      firstName: fname ? fname : this.props.location.state?.firstName,
      lastName: lname ? lname : this.props.location.state?.lastName,
      phone: phone ? phone : this.props.location.state?.phone,
      email: email ? email : this.props.location.state?.email,
      password: psw ? psw : this.props.location.state?.psw,
      permissions: selectedPermissions,
    };
    this.setState({ showLoader: true });
    setTimeout(async () => {
      try {
        const response = await axiosInstance.put(
          `v1/LHS/admin/update/${existingUser._id}`,
          updatedUser
        );
        if (response.data) {
          toast.success(response.data.Message);
          window.location.href = "/main/admin/manageAdmin";
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.setState({ showLoader: false });
      }
    }, 1000);
  };

  render() {
    const { isConfirmPasswordVisible, isPasswordVisible } = this.state;
    return (
      <>
        <div
          className="outer_container"
          style={{ backgroundColor: "rgb(243,242,239)" }}
        >
          {this.props.location.state && this.props.location.state?.length ? (
            <div
              className="main_container"
              style={{ padding: "30px 0px 120px" }}
            >
              <div className="ssu_container">
                <div
                  className="ssu3_body"
                  style={{ maxWidth: 800, marginTop: 80 }}
                >
                  <div className="flex items-center">
                    <img
                      width="12px"
                      height="12px"
                      alt="img"
                      src={right_arrow}
                      onClick={this.backButton}
                    />
                    <div className="hsu1_txt1">Add New Administrator</div>
                  </div>

                  <div className="ssu2_modal1_input mt-5">
                    <h1 style={{ fontSize: "25px" }}>First Name*</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_email_icon">
                        <img width="18px" height="15px" alt="img" src={user} />
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter First Name"
                        type="text"
                        defaultValue={this.state.fname}
                        name="fname"
                        onChange={this.setVal}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    <ErrorState
                      show={this.state.error.fname}
                      name="First Name is required."
                    />
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "25px" }}>Last Name*</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_email_icon">
                        <img width="18px" height="15px" alt="img" src={user} />
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter Last Name"
                        type="text"
                        value={this.state.lname}
                        name="lname"
                        onChange={this.setVal}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    <ErrorState
                      show={this.state.error.lname}
                      name="Last Name is required."
                    />
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "25px" }}>Contact Number*</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_icon">
                        <img
                          width="18px"
                          height="15px"
                          alt="img"
                          src={phoneIcon}
                        />
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter Contact Number"
                        type="number"
                        value={this.state.phone}
                        name="phone"
                        onChange={this.setPhone}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    <ErrorState
                      show={this.state.error.phone}
                      name="10 digits phone number is required."
                    />
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "25px" }}>Email Address*</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_email_icon">
                        <img
                          width="18px"
                          height="15px"
                          alt="img"
                          src={emailIcon}
                        />
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter Email Address"
                        type="text"
                        value={this.state.email}
                        name="email"
                        onChange={this.setEmail}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    <ErrorState
                      show={this.state.error.email}
                      name="Valid Email address is required."
                    />
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "25px" }}>Password*</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_icon">
                        <img width="18px" height="15px" alt="img" src={key} />
                      </div>
                      <input
                        id="psw"
                        className="ssu3_password"
                        placeholder="Enter Password"
                        type={isPasswordVisible ? "text" : "password"}
                        value={this.state.psw}
                        name="psw"
                        onChange={this.setPsw}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                      <div
                        className="ssu3_eye"
                        onClick={this.togglePasswordVisibility}
                      >
                        {isPasswordVisible ? (
                          <VisibilityIcon
                            id="o_psw"
                            style={{ display: "block" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            id="c_psw"
                            style={{ display: "block" }}
                          />
                        )}
                      </div>
                    </div>
                    <ErrorState
                      show={this.state.error.psw}
                      name="Password is required."
                    />
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "25px" }}>Confirm Password*</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_icon">
                        <img width="18px" height="15px" alt="img" src={key} />
                      </div>
                      <input
                        id="repsw"
                        className="ssu3_password"
                        placeholder="Enter Confirm Password"
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        value={this.state.repsw}
                        name="repsw"
                        onChange={this.setRepsw}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                      <div
                        className="ssu3_eye"
                        onClick={this.toggleConfirmPasswordVisibility}
                      >
                        {isConfirmPasswordVisible ? (
                          <VisibilityIcon
                            id="o_psw"
                            style={{ display: "block" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            id="c_psw"
                            style={{ display: "block" }}
                          />
                        )}
                      </div>
                    </div>
                    <ErrorState
                      show={this.state.error.repsw}
                      name="Doesn't match with the password."
                    />
                  </div>

                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "25px", marginBottom: "12px" }}>
                      Premission*
                    </h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox grid gap-4 ">
                      <label
                        className=""
                        style={{
                          border: "1px solid #cccccc",
                          boxShadow: "0px 0px 2px #cccccc",
                          fontSize: "20px",
                          padding: "10px 15px",
                          borderRadius: "25px",
                        }}
                      >
                        <input
                          className="mr-4 customone"
                          type="checkbox"
                          onChange={(e) =>
                            this.handlePermissionChange(
                              "manageStaff",
                              e.target.checked
                            )
                          }
                        />
                        Manage staff
                      </label>
                      <label
                        className=""
                        style={{
                          border: "1px solid #cccccc",
                          boxShadow: "0px 0px 2px #cccccc",
                          fontSize: "20px",
                          padding: "10px 15px",
                          borderRadius: "25px",
                        }}
                      >
                        <input
                          className="mr-4"
                          type="checkbox"
                          onChange={(e) =>
                            this.handlePermissionChange(
                              "manageHospital",
                              e.target.checked
                            )
                          }
                        />
                        Manage Hospital
                      </label>
                    </div>
                  </div>
                </div>

                <hr style={{ margin: "72px 0px 0px" }} />
                <div className="ssu_bottom">
                  <div
                    className="ssu_button"
                    //  onClick={this.continueToNext}
                    onClick={() => this.addAdministrator()}
                  >
                    Add
                  </div>
                  <div
                    className="  border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                    onClick={this.backButton}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="main_container flex justify-center"
              style={{ padding: "30px 0px 120px" }}
            >
              <div className="ssu_container w-[800px]">
                <div className="ssu3_body" style={{ marginTop: 30 }}>
                  <div className="flex items-center">
                    {/* <img
                      width="10px"
                      height="12px"
                      alt="img"
                      src={right_arrow}
                      onClick={this.backButton}
                    /> */}
                    <div className=" text-xl">Add new administrator</div>
                  </div>

                  <div className="ssu2_modal1_input mt-4">
                    <h1 style={{ fontSize: "20px" }}>First Name</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter First Name"
                        type="text"
                        defaultValue={this.props.location.state?.firstName}
                        name="fname"
                        onChange={this.setVal}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    {
                      <ErrorState
                        show={this.state.error.fname}
                        name="First Name is required."
                      />
                    }
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "20px" }}>Last Name</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_email_icon">
                        {/* <img width="18px" height="15px" alt="img" src={user} /> */}
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter Last Name"
                        type="text"
                        defaultValue={this.props.location.state?.lastName}
                        name="lname"
                        onChange={this.setVal}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    {
                      <ErrorState
                        show={this.state.error.lname}
                        name="Last Name is required."
                      />
                    }
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "20px" }}>Contact Number</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_icon">
                        {/* <img
                          width="18px"
                          height="15px"
                          alt="img"
                          src={phoneIcon}
                        /> */}
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter Contact Number"
                        type="number"
                        defaultValue={this.props.location.state?.phone}
                        name="phone"
                        onChange={this.setPhone}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    {
                      <ErrorState
                        show={this.state.error.phone}
                        name="10 digits phone number is required."
                      />
                    }
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "20px" }}>Email Address</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_email_icon">
                        {/* <img
                          width="18px"
                          height="15px"
                          alt="img"
                          src={emailIcon}
                        /> */}
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Enter Email Address"
                        type="text"
                        defaultValue={this.props.location.state?.email}
                        name="email"
                        onChange={this.setEmail}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    {
                      <ErrorState
                        show={this.state.error.email}
                        name="Valid Email address is required."
                      />
                    }
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "20px" }}>Password</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_icon">
                        {/* <img width="18px" height="15px" alt="img" src={key} /> */}
                      </div>
                      <input
                        id="psw"
                        className="ssu3_password"
                        placeholder="Enter Password"
                        type={isPasswordVisible ? "text" : "password"}
                        defaultValue={this.state.psw}
                        name="psw"
                        onChange={this.setPsw}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                      <div
                        className="ssu3_eye"
                        onClick={this.togglePasswordVisibility}
                      >
                        {isPasswordVisible ? (
                          <VisibilityIcon
                            id="o_psw"
                            style={{ display: "block" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            id="c_psw"
                            style={{ display: "block" }}
                          />
                        )}
                      </div>
                    </div>
                    {
                      <ErrorState
                        show={this.state.error.psw}
                        name="Password is required."
                      />
                    }
                  </div>
                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "20px" }}>Confirm Password</h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_icon">
                        {/* <img width="18px" height="15px" alt="img" src={key} /> */}
                      </div>
                      <input
                        id="repsw"
                        className="ssu3_password"
                        placeholder="Enter Confirm Password"
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        defaultValue={this.state.repsw}
                        name="repsw"
                        onChange={this.setRepsw}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                      <div
                        className="ssu3_eye"
                        onClick={this.toggleConfirmPasswordVisibility}
                      >
                        {isConfirmPasswordVisible ? (
                          <VisibilityIcon
                            id="o_psw"
                            style={{ display: "block" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            id="c_psw"
                            style={{ display: "block" }}
                          />
                        )}
                      </div>
                    </div>
                    {
                      <ErrorState
                        show={this.state.error.repsw}
                        name="Doesn't match with the password."
                      />
                    }
                  </div>

                  <div className="ssu2_modal1_input">
                    <h1 style={{ fontSize: "20px", marginBottom: "12px" }}>
                      Premission
                    </h1>
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox grid gap-4">
                      <label
                        className=""
                        style={{
                          border: "1px solid #cccccc",
                          boxShadow: "0px 0px 2px #cccccc",
                          fontSize: "16px",
                          padding: "10px 15px",
                          borderRadius: "25px",
                        }}
                      >
                        <input
                          className="mr-4 customone"
                          type="checkbox"
                          defaultChecked={
                            this.props.location.state?.permissions ==
                            "manageStaff"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.handlePermissionChange(
                              "manageStaff",
                              e.target.checked,
                              this.props.location.state?._id
                            )
                          }
                        />
                        Manage staff
                      </label>
                      <label
                        className=""
                        style={{
                          border: "1px solid #cccccc",
                          boxShadow: "0px 0px 2px #cccccc",
                          fontSize: "16px",
                          padding: "10px 15px",
                          borderRadius: "25px",
                        }}
                      >
                        <input
                          className="mr-4"
                          type="checkbox"
                          defaultChecked={
                            this.props.location.state?.permissions ==
                            "manageHospital"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.handlePermissionChange(
                              "manageHospital",
                              e.target.checked,
                              this.props.location.state?._id
                            )
                          }
                        />
                        Manage Hospital
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-end gap-3 text-center mb-4">
                    <div
                      className="w-32 bg-white  border-1 text-[#73C7A9] rounded-[28px] border !border-[#73C7A9] p-2 hover:!bg-[#73C7A9] hover:text-white "
                      onClick={this.backButton}
                    >
                      Cancel
                    </div>
                    <div
                      className="w-32 text-white bg-[#73C7A9] rounded-[28px] p-2 "
                      //  onClick={this.continueToNext}
                      onClick={() => this.changeAdminStatus()}
                      // onClick={() => this.props.changeAdminStatus(this.props.location.state?._id, this.props.location.state?.isDeleted)}
                    >
                      {this.state.showLoader ? <Loader /> : "Save"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default ManageAdiministart;
