import React, { useEffect, useState } from "react";
import JobType from "./components/jobType";
import { stateOfUs } from "../../../constants/otherConstans";
import Insurance from "./components/insurance";
import place from "../../assets/images/place.svg";
import { axiosInstance } from "../../../config/axiosConfig";

function Profile() {
  const [staffData, setStaffData] = useState("");
  const [nursingLicence, setNursingLicence] = useState([]);
  const [stateOfUsOptions, setStateOfUsOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [stateVal, setStateVal] = useState("");
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    const temp = stateOfUs.map((state, num) => ({
      name: state,
      num,
    }));
    setStateOfUsOptions(temp);
  }, []);
  const _id = localStorage.getItem("_id");
  const type = localStorage.getItem("type");
  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(`v1/LHS/${type}/getById/${_id}`);
      if (res.status === 200) {
        const data = res.data.data;
        setStaffData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (_id && type) {
      fetchData();
    }
  }, [_id && type]);
  const addState = (e) => {
    const state = e.target.value;
    const num = stateOfUsOptions.find((option) => option.name === state)?.num;
    setStateVal("");
    setShowInput(false);
    const newSelectedStates = selectedStates.slice();
    newSelectedStates.push(num);
    setSelectedStates(newSelectedStates);
    const nursingLicense = nursingLicence.slice();
    nursingLicense.push({
      image: "",
      expirationDate: new Date(),
      state: state,
      name: "",
      number: "",
      num: nursingLicense.length,
    });

    updateDB(nursingLicense);
  };

  const updateDB = async () => {};

  return (
    <>
      <div className="container">
        <JobType />
        <div
          className="ssu2_modal1_input"
          style={{
            maxWidth: "none",
            width: "100%",
            display: showInput ? "block" : "none",
          }}
        >
          <div
            className="ssu2_modal1_input ssu2_modal3_selectBox"
            style={{ maxWidth: "none!important" }}
          >
            <div className="input_left_icon">
              <img
                alt="img"
                src={place}
                width="16px"
                height="20px"
                style={{ marginTop: "-3px" }}
              />
            </div>
            <select
              className="ssu2_modal3_select"
              name="option"
              onChange={addState}
              value={stateVal}
            >
              <option value="" disabled selected customhidden>
                Select a state
              </option>
              {stateOfUsOptions.map((state) => (
                <option
                  key={state.num}
                  disabled={selectedStates.includes(state.num)}
                  value={state.name}
                >
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* {staffData.liabilityInsurance ? (
          <Insurance staff={staffData.liabilityInsurance} />
        ) : (
          ""
        )} */}
      </div>
    </>
  );
}

export default Profile;
