import React, { useState, useEffect } from "react";
import hospital from "../../assets/images/hospital_blue.svg";
import staff from "../../assets/images/staff_blue.svg";
import register from "../../assets/images/register.svg";
import "../theme2.css";
import { axiosInstance } from "../../../config/axiosConfig";
import Nav from "../../theme_1/Nav";

const AdminDashboard = () => {
  const [today, setToday] = useState("");
  const [date, setDate] = useState("");
  const [filterDate, setFilterDate] = useState(false);
  const [data, setData] = useState("");

  const initState = (props) => {
    const currentDate = new Date();
    const d = currentDate.getDate();
    const m = currentDate.getMonth() + 1;
    const y = currentDate.getFullYear();
    const formattedDate = `${m < 10 ? "0" + m : m}/${
      d < 10 ? "0" + d : d
    }/${y}`;
    setDate(formattedDate);
  };

  const selectDate = (date) => {
    const todayDate = new Date();
    const d = todayDate.getDate();
    setDate(formattedDate);
    setFilterDate(date);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get(`v1/LHS/admin/dashboard`);
        if (res.data) {
          setData(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const currentDate = new Date();
    const d = currentDate.getDate();
    const m = currentDate.getMonth() + 1;
    const y = currentDate.getFullYear();
    const todayFormattedDate = `${m < 10 ? "0" + m : m}/${
      d < 10 ? "0" + d : d
    }/${y}`;
    setToday(todayFormattedDate);
    setFilterDate(currentDate);
    initState();
    fetchData();
  }, []);
  const m = todayDate.getMonth() + 1;
  const y = todayDate.getFullYear();
  const todayFormattedDate = `${m < 10 ? "0" + m : m}/${
    d < 10 ? "0" + d : d
  }/${y}`;
  initState();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${month < 10 ? "0" + month : month}/${
    day < 10 ? "0" + day : day
  }/${year}`;
  setToday(todayFormattedDate);

  return (
    <div className="theme2_body">
      <Nav />
      <div className="t2_sp_work" style={{ marginTop: 0 }}>
        <div className="row admin_dash_part1"></div>
        <div className="row">
          <div className="col-md-6 col-12">
            <h5>Hospital</h5>
            <div className="row admin_dash_item_container">
              <button className="col-sm-6 col-12 admin_dash_item">
                <img
                  src={hospital}
                  alt="hospital.svg"
                  className="admin_dash_item_img"
                />
              </button>
              <hr className="admin_dash_item_hr" />
              <button className="col-sm-6 col-12 admin_dash_item admin_dash_item_right">
                <h5>{data?.latestHospital}</h5>
                <p style={{ fontSize: 12, color: "#333333", opacity: 0.7 }}>
                  New Registrations
                </p>
              </button>
            </div>
            <div className="row admin_dash_item_container">
              <button className="col-sm-6 col-12 admin_dash_item">
                <img
                  src={register}
                  alt="hospital.svg"
                  className="admin_dash_item_img"
                />
              </button>
              <hr className="admin_dash_item_hr" />
              <button className="col-sm-6 col-12 admin_dash_item admin_dash_item_right">
                <h5>{data.totalHospital}</h5>
                <p style={{ fontSize: 12, color: "#333333", opacity: 0.7 }}>
                  Total Registrations
                </p>
              </button>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <h5>Staff</h5>
            <div className="row admin_dash_item_container">
              <button className="col-sm-6 col-12 admin_dash_item">
                <img
                  src={staff}
                  alt="hospital.svg"
                  className="admin_dash_item_img"
                />
              </button>
              <hr className="admin_dash_item_hr" />
              <button className="col-sm-6 col-12 admin_dash_item admin_dash_item_right">
                <h5>{data.latestStaff}</h5>
                <p style={{ fontSize: 12, color: "#333333", opacity: 0.7 }}>
                  New Registrations
                </p>
              </button>
            </div>
            <div className="row admin_dash_item_container">
              <button className="col-sm-6 col-12 admin_dash_item">
                <img
                  src={register}
                  alt="hospital.svg"
                  className="admin_dash_item_img"
                />
              </button>
              <hr className="admin_dash_item_hr" />
              <button className="col-sm-6 col-12 admin_dash_item admin_dash_item_right">
                <h5>{data.totalStaff}</h5>
                <p style={{ fontSize: 12, color: "#333333", opacity: 0.7 }}>
                  Total Registrations
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
