import React, { useState, useEffect } from "react";
import leftarrow from "../../../assets/images/leftarrow.svg";
import downarrow from "../../../assets/images/downarrow.svg";
import edit from "../../../assets/images/edit_note.svg";
import del from "../../../assets/images/delete.svg";
import calendar from "../../../assets/images/calendar.png";
import DatePicker from "react-datepicker";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import FileBrowser from "../../../theme_1/staffSignUp/components/fileBrowser";
import profile from "../../../assets/images/profile.svg";
import place from "../../../assets/images/place.svg";
import Confirm from "../../../modals/confirm";
import $ from "jquery";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { callApi } from "../../../../redux/action/action";
import { axiosInstance } from "../../../../config/axiosConfig";

const NurseLicense = (props) => {
  const [showInput, setShowInput] = useState(false);
  const [licenseData, setLicenseData] = useState("");
  const [licenseId, setLicenseId] = useState("");
  const [stateVal, setStateVal] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [stateOfUs, setStateOfUs] = useState([]);
  const [downarrow, setDownarrow] = useState("inline");
  const [leftarrow, setLeftarrow] = useState("none");
  const [currentIndex, setCurrentIndex] = useState("");
  const [licenses, setLicenses] = useState({});
  const [stateName, setStateName] = useState("");
  const [selectedNum, setSelectedNum] = useState("");
  const [showConfirm, setShowConfirm] = useState("none");
  const [confirmTitle, setConfirmTitle] = useState("");
  const [agreeFn, setAgreeFn] = useState(false);
  const [showEditModal, setShowEditModal] = useState("none");
  const [file, setFile] = useState(false);
  const [fileAdded, setFileAdded] = useState(false);
  const [expDate, setExpDate] = useState(false);
  const [lname, setLname] = useState("");
  const [lnum, setLnum] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [fname, setFname] = useState("");
  const [error, setError] = useState({
    lname: "none",
    lnum: "none",
    expDate: "none",
    lfile: "none",
  });
  const [delFile, setDelFile] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [title, setTitle] = useState("Add");
  const [showLoader, setShowLoader] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let typeData = localStorage.getItem("type");
      if (typeData === "admin") {
        setIsAdmin(true);
      }
      let id = props.admin
        ? window.localStorage.getItem("Staffid")
        : window.localStorage.getItem("_id");
      const res = await axiosInstance.get(`v1/LHS/staff/getById/${id}`);
      if (res.data) {
        setLicenseData(res.data.nursingLicense);
      }
    };
    fetchData();
  }, [props.admin]);

  const addState = (e) => {
    const state = e.target.value;
    setStateVal(state);
    addLicense();
  };

  const toggleAccordion = (num) => {
    if (currentIndex === num) {
      setCurrentIndex("");
    } else {
      setCurrentIndex(num);
    }
  };

  const confirmDeleteState = () => {
    setShowConfirm("block");
    setConfirmTitle(stateName);
    setAgreeFn(deleteState);
  };

  const deleteState = () => {
    setShowConfirm("none");
    const nursingLicence = props.nursingLicence;
    nursingLicence.splice(selectedNum, 1);
    updateDB(nursingLicence);
  };

  const confirmDeleteLicence = (num) => {
    setShowConfirm("block");
    setConfirmTitle(licenseData[num].name);
    setLicenseId(licenseData[num]._id);
    setAgreeFn(deleteOneRow);
  };

  const deleteOneRow = async () => {
    const Authorization = `Bearer ${window.localStorage.getItem("token")}`;
    const _id = window.localStorage.getItem("_id");
    const res = await callApi(
      "DELETE",
      `v1/LHS/staff/delete/nursingLicense/${licenseId}?userId=${_id}`,
      Authorization
    );
    console.log(res);
    setShowConfirm("none");
    componentDidMount();
    modalClose();
  };

  const editOneRow = (index) => {
    console.log(index);
    const passDate = moment(licenseData[index].expirationDate);
    setShowEditModal("block");
    setEditRow(true);
    setTitle("Edit");
    setSelectedNum(index);
    setLname(licenseData[index].name);
    setLnum(licenseData[index].number);
    setExpDate(new Date(passDate));
    setFile(licenseData[index].image);
    setImgUrl(licenseData[index].image);
    setLicenseId(licenseData[index]._id);
    setStateVal(licenseData[index].state);
    setFname(licenseData[index].fname);
    setError({
      lname: "none",
      lnum: "none",
      expDate: "none",
      lfile: "none",
    });
  };

  const addLicense = () => {
    $("#" + props.num + "input").val(null);
    setShowEditModal("block");
    setEditRow(false);
    setExpDate(false);
    setLname("");
    setLnum("");
    setFile(false);
    setFname("");
    setImgUrl("");
    setError({
      lname: "none",
      lnum: "none",
      expDate: "none",
      lfile: "none",
    });
    setDelFile(false);
    setTitle("Add");
  };

  const updateDB = async (nursingLicence) => {
    const data = { nursingLicence: nursingLicence };
    let token;
    if (props.admin) {
      token = `Bearer ${localStorage.getItem("token")}`;
    } else {
      token = `Bearer ${localStorage.getItem("token")}`;
    }
    const type = localStorage.getItem("type");
    const _id = props._id;
    const res = await callApi(
      "POST",
      `v1/LHS/staff/update/${_id}`,
      token,
      data
    );
    setSession(res.token, res.data._id, type);
    data = {
      name: res.data.name,
      type: "staff",
      avatar: res.data.avatar,
      badge: res.data.badge,
    };
    props.setAuth(data);
  };

  const handleSave = async (file) => {
    const state = {
      lname,
      lnum,
      expDate,
      file,
      fname,
      imgUrl,
    };

    const lname = state.lname === "" ? "block" : "none";
    const lnum = state.lnum === "" ? "block" : "none";
    const expDate = state.expDate === false ? "block" : "none";
    const lfile = state.file === false && !state.fname ? "block" : "none";
    const temp = {
      lname,
      lnum,
      expDate,
      lfile,
    };
    setError({ ...temp });
    setDelFile(false);

    if (title === "Edit") {
      const state = {
        lname,
        lnum,
        expDate,
        file,
        fname,
        imgUrl,
      };

      const lname = state.lname === "" ? "block" : "none";
      const lnum = state.lnum === "" ? "block" : "none";
      const expDate = state.expDate === null ? "block" : "none";
      const lfile = state.file === false && !state.fname ? "block" : "none";
      const temp = {
        lname,
        lnum,
        expDate,
        lfile,
      };
      setError({ ...temp });
      setDelFile(false);
      if (
        lname === "none" &&
        lnum === "none" &&
        expDate === "none" &&
        lfile === "none"
      ) {
        const passDate = moment(expDate)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const data = {
          image: file,
          name: lname,
          state: stateVal,
          number: lnum,
          expirationDate: passDate,
        };
        console.log(data, "in edit license");
        const Authorization = `Bearer ${window.localStorage.getItem("token")}`;
        const _id = window.localStorage.getItem("_id");
        let res;
        if (props.admin) {
          res = await callApi(
            "PUT",
            `v1/LHS/staff/update/nursingLicense/${licenseId}?userId=${_id}`,
            Authorization,
            data
          );
        } else {
          res = await callApi(
            "PUT",
            `v1/LHS/staff/update/nursingLicense/${licenseId}`,
            Authorization,
            data
          );
        }
        console.log(file, "file after save");
        if (res.data) {
          modalClose();
          componentDidMount();
        }
      }
    }

    if (title === "Add") {
      console.log(stateVal, "in handle save for add license");
      console.log(file, "in handle save for add license");
      if (
        lname === "none" &&
        lnum === "none" &&
        expDate === "none" &&
        lfile === "none"
      ) {
        const passDate = moment(expDate)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .add(1, "d");
        const data = {
          image: file,
          name: lname,
          state: stateVal,
          number: lnum,
          expirationDate: passDate,
        };
        console.log(passDate, "expdate in handle save for add");
        const Authorization = `Bearer ${window.localStorage.getItem("token")}`;
        const _id = window.localStorage.getItem("_id");
        const res = await callApi(
          "POST",
          `v1/LHS/staff/add/nursingLicense?userId=${_id}`,
          Authorization,
          data
        );
        if (res.Message === "License inserted successfully..") {
          componentDidMount();
          modalClose();
        }
      }
    }
  };

  const setFile1 = (file) => {
    uploadFileFunc(file);
    setFileAdded(file);
  };

  const uploadFileFunc = async (file) => {
    setShowLoader(true);
    const data = {
      file: file,
      name: "license",
    };
    const res1 = await callApi("POST", "v1/LHS/file/upload", null, data, true);
    alert(res1.Message);
    setFile(res1.upload.upload.link);
    setShowLoader(false);
  };

  const showFileErr = () => {
    const err = { ...error };
    err.lfile = "block";
    setError(err);
  };

  const hideFileErr = () => {
    const err = { ...error };
    err.lfile = "none";
    setError(err);
  };

  const setImgUrl1 = async (url) => {
    setImgUrl(url);
  };

  const modalClose = () => {
    const state = {
      showEditModal: "none",
      stateVal: "",
      file: false,
      fname: "",
      fileAdded: "",
    };
    setState(state);
  };

  const setDelFile1 = (del) => {
    setDelFile(del);
    if (del && error.lfile === "block") hideFileErr();
  };

  const setLicenceName = (e) => {
    if (e.target.value.length < 51) {
      setLname(e.target.value);
      if (e.target.value !== "") {
        const err = { ...error };
        err.lname = "none";
        setError(err);
      }
    }
  };

  const setLicenceNum = (e) => {
    if (e.target.value.length < 51) {
      setLnum(e.target.value);
      if (e.target.value !== "") {
        const err = { ...error };
        err.lnum = "none";
        setError(err);
      }
    }
  };

  const setExpDate1 = (date) => {
    setExpDate(date);
    if (date !== "") {
      const err = { ...error };
      err.expDate = "none";
      setError(err);
    }
  };

  return (
    <>
      <h4 className="t2_sp_title1">Nursing License</h4>
      <div
        className="ssu2_modal1_input"
        style={{
          maxWidth: "none",
          width: "100%",
        }}
      >
        {isAdmin ? (
          ""
        ) : (
          <div
            className="ssu2_modal1_input ssu2_modal3_selectBox"
            style={{ maxWidth: "100%" }}
          >
            <div className="input_left_icon">
              <img
                alt="img"
                src={place}
                width="16px"
                height="20px"
                style={{ marginTop: "-3px" }}
              />
            </div>
            <select
              className="ssu2_modal3_select"
              name="option"
              onChange={addState}
              value={stateVal}
            >
              <option value="" disabled selected customhidden>
                Select a state to add new licence
              </option>

              {stateOfUs.map((state) => {
                return (
                  <option
                    key={state._id}
                    disabled={
                      selectedStates.indexOf(state.num) !== -1 ? true : false
                    }
                    value={state._id}
                  >
                    {state.name}
                  </option>
                );
              })}
              {console.log(stateVal, "in line 394 NLC")}
            </select>
          </div>
        )}
      </div>
      <div className="t2_sp_nl_item">
        {licenseData &&
          licenseData.map((data, index) => {
            return (
              <div key={index}>
                <div
                  className="col-12 t2_sp_licence_accBtn d-flex align-items-center"
                  style={{ fontSize: 18 }}
                  onClick={() => toggleAccordion(index)}
                >
                  <span>
                    <img
                      alt="img"
                      src={leftarrow}
                      width="10px"
                      style={{
                        display: index === currentIndex ? "inline" : "none",
                        marginRight: "34px",
                      }}
                    />
                    <img
                      alt="img"
                      src={downarrow}
                      width="18px"
                      style={{
                        display: index !== currentIndex ? "inline" : "none",
                        marginRight: "22px",
                      }}
                    />
                  </span>
                  {data.state}
                </div>
                <div
                  className="col-12 nur_accBody"
                  style={{
                    display: index === currentIndex ? "block" : "none",
                  }}
                  key={index}
                >
                  <hr className="t2_sp_hr" />
                  <div className="nur_text1">
                    <div
                      className="row"
                      style={{
                        overflowX: "auto",
                        marginTop: "24px",
                        display: licenseData ? "block" : "none",
                      }}
                    >
                      <table
                        style={{
                          minWidth: "800px",
                          marginBottom: "15px",
                          textAlign: "left",
                        }}
                      >
                        <thead>
                          <tr className="nurseHead">
                            <th
                              className="t2_sp_licence_th"
                              style={{ paddingLeft: "50px" }}
                            >
                              Image
                            </th>
                            <th className="t2_sp_licence_th">Name</th>
                            <th className="t2_sp_licence_th">Number</th>
                            <th className="t2_sp_licence_th">
                              Expiration Date
                            </th>
                            {isAdmin ? (
                              ""
                            ) : (
                              <th
                                className="t2_sp_licence_th"
                                style={{
                                  width: props.admin ? "80px" : "130px",
                                }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ height: 20 }} />
                          <tr className="t2_sp_licence_row">
                            <td style={{ paddingLeft: "50px" }}>
                              <a
                                href={data.image && data.image}
                                download
                                target="new"
                              >
                                <img
                                  alt="img"
                                  width="60"
                                  height="36"
                                  src={data.image && data.image}
                                />
                              </a>
                            </td>
                            <td className="t2_sp_licence_td">
                              {data.name && data.name}
                            </td>
                            <td className="t2_sp_licence_td">
                              {data.number && data.number}
                            </td>
                            <td className="t2_sp_licence_td">
                              {data.expirationDate &&
                                `${data.expirationDate.slice(
                                  5,
                                  7
                                )}/${data.expirationDate.slice(
                                  8,
                                  10
                                )}/${data.expirationDate.slice(0, 4)} `}
                            </td>
                            {isAdmin ? (
                              ""
                            ) : (
                              <td>
                                <span
                                  style={{ marginRight: "20px" }}
                                  onClick={() => editOneRow(index)}
                                >
                                  <img
                                    width="20px"
                                    height="20px"
                                    alt="img"
                                    src={edit}
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                                <span
                                  onClick={() => confirmDeleteLicence(index)}
                                >
                                  <img
                                    width="20px"
                                    height="18px"
                                    alt="img"
                                    src={del}
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="w3-modal" style={{ display: showEditModal }}>
          <div
            className="w3-modal-content ssu2_modal1"
            style={{ overflowY: "auto", maxHeight: 600 }}
          >
            <div className="w3-container">
              <div className="ssu2_modal1_text1">{title} License</div>
              <hr style={{ margin: "30px 0px 0px" }} />
              <div className="ssu2_modal1_input">
                <div
                  className="ssu2_modal1_input ssu2_modal3_selectBox"
                  style={{ marginTop: "30px" }}
                >
                  <div className="input_left_icon">
                    <img height="17px" width="22px" alt="img" src={profile} />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    type="text"
                    placeholder="License Name"
                    value={lname}
                    onChange={setLicenceName}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleSave();
                    }}
                  />
                </div>
                <ErrorState
                  show={error.lname}
                  name="License Name is required."
                />
              </div>
              <div className="ssu2_modal1_input">
                <div
                  className="ssu2_modal1_input ssu2_modal3_selectBox"
                  style={{ marginTop: "18px" }}
                >
                  <div className="input_left_icon">
                    <img width="22px" height="17px" alt="img" src={profile} />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="License Number"
                    type="text"
                    value={lnum}
                    onChange={setLicenceNum}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleSave();
                    }}
                  />
                </div>
                <ErrorState
                  name="License Number is requried."
                  show={error.lnum}
                />
              </div>
              <div className="ssu2_modal1_input" style={{ marginTop: "18px" }}>
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div
                    className="input_left_icon"
                    onClick={() => $(".ssu2_modal3_date").focus()}
                  >
                    <img width="22px" height="17px" alt="img" src={calendar} />
                  </div>
                  <DatePicker
                    placeholderText="Expiration Date"
                    className="ssu2_modal3_date"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    selected={expDate}
                    onChange={(date) => setExpDate(date)}
                  />
                </div>
                <ErrorState
                  name="Expiration Date is required."
                  show={error.expDate}
                />
              </div>
              <div className="ssu2_modal1_text">
                Please upload only png, jpg, or jpeg images
              </div>
              <hr style={{ margin: "60px 0px 0px" }} />
              <div className="row ssu_bottom">
                <button onClick={handleSave} className="ssu2_modal1_button1">
                  {showLoader ? <Spinner animation="border" /> : "SAVE"}
                </button>
                <button
                  onClick={modalClose}
                  className="border !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                >
                  {" "}
                  CANCEL{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Confirm
          display={showConfirm}
          title={'Delete "' + confirmTitle + '"'}
          content={'Are you sure you want to delete "' + confirmTitle + '"'}
          agreeFn={agreeFn}
          disagreeFn={() => this.setState({ showConfirm: "none" })}
        />
      </div>
    </>
  );
};

export default NurseLicense;
