import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const LightboxComponent = ({ images }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const renderImages = () => {
    const imageCount = images?.length;

    if (imageCount === 1) {
      return (
        <div className="w-full h-auto max-h-[16rem] overflow-hidden">
          <img
            key={0}
            src={images[0]?.src}
            alt={images[0]?.alt}
            style={{
              height: "100%",
              width: "100%",
              margin: "0px auto",
              borderRadius: "15px",
              cursor: "pointer",
              objectFit: "cover",
            }}
            onClick={() => openLightbox(0)}
          />
        </div>
      );
    } else if (imageCount === 2) {
      return (
        <div
          className="w-full h-auto max-h-[12.5rem] overflow-hidden"
          style={{
            gap: "10px",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          {images.map((image, index) => (
            <div className="w-full" style={{ maxHeight: "12.5rem" }}>
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                style={{
                  height: "100%",
                  width: "100%",
                  margin: "0px auto",
                  borderRadius: "15px",
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </div>
      );
    } else if (imageCount == 3) {
      return (
        <div className="w-full h-auto max-h-[27rem] overflow-hidden">
          <div
            style={{
              gap: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            {images.map((image, index) => (
              <div
                style={{
                  width: "100%",
                  gridColumn: `${index === 2 ? "span 2" : ""}`,
                  maxHeight: "12.5rem",
                }}
              >
                <img
                  key={index}
                  src={image.src}
                  alt={image.alt}
                  style={{
                    height: "100%",
                    width: "100%",
                    margin: "0px auto",
                    borderRadius: "15px",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                  onClick={() => openLightbox(index)}
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else if (imageCount == 4) {
      return (
        <div className="w-full h-auto max-h-[27rem] overflow-hidden">
          <div
            style={{
              gap: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            }}
          >
            {images.map((image, index) => (
              <div className="w-[100%]" style={{ maxHeight: "12.5rem" }}>
                <img
                  key={index}
                  src={image.src}
                  alt={image.alt}
                  style={{
                    height: "100%",
                    width: "100%",
                    margin: "0px auto",
                    borderRadius: "15px",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                  onClick={() => openLightbox(index)}
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else if (imageCount >= 5) {
      const firstRowImages = images.slice(0, 2);
      const secondRowImages = images.slice(2);

      return (
        <div className="w-full h-auto max-h-[27rem] overflow-hidden">
          <div
            style={{ display: "flex", gap: "10px", flexDirection: "column" }}
          >
            <div
              style={{
                gap: "10px",
                display: "grid",
                maxHeight: "12.5rem",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              {firstRowImages.map((image, index) => (
                <div className="w-full" style={{ maxHeight: "12.5rem" }}>
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    style={{
                      height: "100%",
                      width: "100%",
                      margin: "0px auto",
                      borderRadius: "15px",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    onClick={() => openLightbox(index)}
                  />
                </div>
              ))}
            </div>
            <div
              style={{
                gap: "10px",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                maxHeight: "12.5rem",
              }}
            >
              {secondRowImages.map((image, index) => (
                <div
                  className="w-full self-center h-full"
                  style={{ maxHeight: "inherit" }}
                >
                  <img
                    key={index + 2}
                    src={image.src}
                    alt={image.alt}
                    style={{
                      height: "100%",
                      width: "100%",
                      margin: "0px auto",
                      borderRadius: "15px",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    onClick={() => openLightbox(index + 2)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {renderImages()}
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={
            images?.length > 1 && images[(photoIndex + 1) % images.length].src
          }
          prevSrc={
            images?.length > 1 &&
            images[(photoIndex + images.length - 1) % images.length].src
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default LightboxComponent;
