import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "../components/adminHeader";
import Navigation from "../components/adminNav";
import Dashboard from "./dashboard";
import ManageAdmin from "./manageAdmin";
import ManageStaff from "./manageStaff";
import ManageStaffEdit from "./components/manageStaffEdit";
import ManageHospital from "./manageHospital";
import ManageHospitalEdit from "./components/manageHospitalEdit";
import "./admin.css";
import ManageAdminAddEdit from "./manageAdminAddEdit";
import ChangePassword from "./changePassword";
import ManageAttendance from "./manageAttendance";
import HospitalJobs from "./hospitalJobs";
import StaffList from "./staffList";
import StaffAttendance from "./staffAttendance";
import ManagePayment from "./managePayment";
import NewAdministrators from "./NewAdministrators";
import ManageAdiministart from "./ManageAdiministart";

const StaffProfile = () => {
  const [curPos, setCurPos] = useState("");

  return (
    <div className="theme2">
      <Navigation curPos={curPos} />
      <div className="theme2_container">
        <Header />
        <div className="theme2_main_container">
          <Switch>
            <Route
              path="/main/admin/manageHospitalEdit"
              render={(props) => <ManageHospitalEdit {...props} />}
            />
            <Route
              path="/main/admin/manageAttendance"
              render={(props) => <ManageAttendance {...props} />}
            />
            <Route
              path="/main/admin/managePayment"
              render={(props) => <ManagePayment {...props} />}
            />
            <Route
              path="/main/admin/hospitalJob"
              render={(props) => <HospitalJobs {...props} />}
            />
            <Route
              path="/main/admin/hospital-staffList"
              render={(props) => <StaffList {...props} />}
            />
            <Route
              path="/main/admin/hospital-staffAttendance"
              render={(props) => <StaffAttendance {...props} />}
            />
            <Route
              path="/main/admin/manageHospital"
              render={(props) => <ManageHospital {...props} />}
            />
            <Route
              path="/main/admin/manageStaffEdit"
              render={(props) => <ManageStaffEdit />}
            />
            <Route
              path="/main/admin/manageStaff"
              render={(props) => <ManageStaff {...props} />}
            />
            <Route
              path="/main/admin/manageAdmin"
              render={(props) => <ManageAdmin {...props} />}
            />
            <Route
              path="/main/admin/admin/add"
              render={(props) => <ManageAdminAddEdit {...props} />}
            />
            <Route
              path="/main/admin/ManageAdiministart"
              render={(props) => <ManageAdiministart {...props} />}
            />
            <Route
              path="/main/admin/change-password"
              render={(props) => <ChangePassword {...props} />}
            />
            <Route
              path="/main/admin"
              render={(props) => <Dashboard {...props} />}
            />
            <Route
              path="/main/admin/NewAdministrators"
              render={(props) => <NewAdministrators {...props} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default StaffProfile;
