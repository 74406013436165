import React, { useState, useEffect } from "react";
import avatar from "../../../assets/images/avatar.png";
import place from "../../../assets/images/place.svg";
import FileBrowser from "../../hospitalProfile/components/filebrowser";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import edit_badge from "../../../assets/images/edit_badge.svg";
import { callApi, removeSession } from "../../../../redux/action/action";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../staffProfile/components/components.css";
import $ from "jquery";
import moment from "moment";
import "../admin.css";
import "../../theme2.css";
import { axiosInstance } from "../../../../config/axiosConfig";

function Avatar(props) {
  const [state, setState] = useState({
    _id: "",
    name: "",
    avatar: "",
    location: "",
    badge: false,
    modal1show: "none",
    file: false,
    fileAdded: false,
    imgUrl: "",
    fileErr: "none",
    delFile: false,
    errorTitle: "The PNG, JPEG, JPG file is required.",
    modal2show: "none",
    crop: { aspect: 1 },
    image: false,
    croppedImg: false,
    croppedImgUrl: false,
    createdAt: "",
  });

  useEffect(() => {
    // Initialize data when props change or on component mount
    if (props.admin) {
      var id = window.localStorage.getItem("Staffid");
    } else {
      var id = window.localStorage.getItem("_id");
    }
    async function fetchData() {
      var res = await axiosInstance.get(`v1/LHS/staff/getById/${id}`);
      if (res.data) {
        setState((prevState) => ({
          ...prevState,
          name: res.data.name,
          avatar: res.data.avatar,
          location: res.data.currentLocation,
          createdAt: res.data.createdAt,
        }));
      }
    }
    fetchData();
  }, [props.admin]);

  const logOut = () => {
    removeSession();
    window.location.href = "/home";
  };

  const openModal1 = () => {
    setState((prevState) => ({
      ...prevState,
      modal1show: "block",
      file: false,
    }));
    $("#_input").val(null);
  };

  const setFile = (file) => {
    setState((prevState) => ({
      ...prevState,
      file: file,
    }));
  };

  const uploadFileFunc = async (file) => {
    const data = {
      file: file,
      name: "avatar",
    };

    var res1 = await callApi("POST", "v1/LHS/file/upload", null, data, true);
    alert(res1.Message);
    setState((prevState) => ({
      ...prevState,
      file: res1.upload.upload.link,
    }));
  };

  const showFileErr = (title) => {
    setState((prevState) => ({
      ...prevState,
      fileErr: "block",
      errorTitle: title ? title : "The PNG, JPEG, JPG file is required.",
    }));
  };

  const hideFileErr = () => {
    setState((prevState) => ({
      ...prevState,
      fileErr: "none",
    }));
  };

  const setImgUrl = (url) => {
    setState((prevState) => ({
      ...prevState,
      imgUrl: url,
      croppedImgUrl: url,
    }));
  };

  const setDelFile = (del) => {
    setState((prevState) => ({
      ...prevState,
      delFile: del,
    }));
    if (del && state.fileErr === "block") hideFileErr();
  };

  const handleSave1 = () => {
    if (state.file && state.fileErr === "none") {
      setState((prevState) => ({
        ...prevState,
        modal2show: "block",
      }));
    }
  };

  const modal1Close = () => {
    $("#_input").val(null);
    setState((prevState) => ({
      ...prevState,
      modal1show: "none",
    }));
  };

  const getCroppedImg = async (image, pixelCrop, fileName) => {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  };

  const onImageLoaded = (image, pixelCrop) => {
    var imgWidth = image.naturalWidth;
    var imgHeight = image.naturalHeight;
    if (imgWidth > imgHeight) {
      setState((prevState) => ({
        ...prevState,
        crop: {
          x: 0,
          y: 0,
          aspect: 1,
          height: 100,
        },
        disabled: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        crop: {
          x: 0,
          y: 1,
          aspect: 1,
          width: 100,
        },
        disabled: false,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      image: image,
    }));
  };

  const onCropComplete = async (crop, pixelCrop) => {
    var croppedImg = await getCroppedImg(state.image, pixelCrop, "croppedImg");
    setState((prevState) => ({
      ...prevState,
      croppedImg: croppedImg,
    }));
    let fileReader = new FileReader();
    fileReader.onload = () => {
      let croppedImgUrl = fileReader.result;
      setState((prevState) => ({
        ...prevState,
        croppedImgUrl: croppedImgUrl,
      }));
    };
    fileReader.readAsDataURL(croppedImg);
  };

  const onChange = (crop, pixelCrop) => {
    setState((prevState) => ({
      ...prevState,
      crop: crop,
    }));
  };

  const handleSave2 = () => {
    imgUpload();
  };

  const modal2Close = () => {
    setState((prevState) => ({
      ...prevState,
      modal2show: "none",
    }));
  };

  const imgUpload = async () => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      setState((prevState) => ({
        ...prevState,
        avatar: fileReader.result,
      }));
    };
    fileReader.readAsDataURL(state.croppedImg);

    var res1 = await callApi(
      "POST",
      "v1/LHS/file/upload",
      null,
      { file: state.croppedImg },
      true
    );
    setState((prevState) => ({
      ...prevState,
      avatar: res1.upload.upload.link,
    }));
    updateDB();
  };

  const updateDB = async () => {
    var data = {
      avatar: state.avatar,
    };
    var token = "Bearer " + localStorage.getItem("token");
    var id = window.localStorage.getItem("_id");
    var res = await callApi("PUT", `v1/LHS/staff/update/${id}`, token, data);
    if (res.data) {
      componentDidMount();
      modal2Close();
    }
  };

  return (
    <div className="row t2_sp_avatar_container">
      <div className="col-md-10">
        <div className="t2_sp_avatar_img">
          <img
            alt="avatar.png"
            src={state.avatar && state.avatar !== "" ? state.avatar : avatar}
            width="80px"
            height="auto"
            style={{ borderRadius: "50%" }}
          />
          <img
            src={edit_badge}
            alt="badge.png"
            className="t2_sp_avatar_badge"
            style={{ display: state.badge ? "block" : "false" }}
          />
        </div>
        <div className="row t2_sp_avtar_desc">
          <p className="col-12 t2_sp_avatar_txt1">
            {state.name ? state.name : "loading..."}
          </p>
          <p className="col-12 t2_sp_avatar_txt2">
            <span style={{ marginRight: 12 }}>
              <img alt="place" width="13px" height="16px" src={place} />
            </span>
            {state.location
              ? state.location.name + " ," + state.location.state
              : "loading..."}
          </p>
        </div>
      </div>
      <div className="col-md-2">
        <p className="col-12 t2_sp_avatar_txt1"> {"Joined"} </p>
        <p className="col-12 t2_sp_avatar_txt2">
          {state.createdAt
            ? moment(state.createdAt).format("MM/DD/YYYY")
            : "loading..."}
        </p>
      </div>
      <div className="w3-modal" style={{ display: state.modal1show }}>
        <div
          className="w3-modal-content ssu2_modal1"
          style={{ maxHeight: 600, overflowY: "auto" }}
        >
          <div className="w3-container">
            <div className="ssu2_modal1_text1">Update Profile Picture</div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <FileBrowser
              setFile={setFile}
              showFileErr={showFileErr}
              title="PICTURE"
              hideFileErr={hideFileErr}
              setImgUrl={setImgUrl}
              file={state.fileAdded}
              setDelFile={setDelFile}
              accept="image/png, image/jpg, image/jpeg"
            />
            <ErrorState show={state.fileErr} name={state.errorTitle} />
            <div className="ssu2_modal1_text2">
              Please upload only png, jpg, or jpeg images
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="row ssu_bottom">
              <button
                className={
                  state.file && state.fileErr === "none"
                    ? "ssu2_modal1_button1"
                    : "ssu2_modal1_button1 button_disabled"
                }
                onClick={handleSave1}
              >
                SAVE
              </button>
              <button
                className="border !border-[#73C7A9] !text-[#73C7A9]  rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                onClick={modal1Close}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w3-modal" style={{ display: state.modal2show }}>
        <div
          className="w3-modal-content ssu2_modal1"
          style={{ maxHeight: 600, overflowY: "auto" }}
        >
          <div className="w3-container">
            <div className="ssu2_modal1_text1">Update Profile Picture</div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img
                  alt="img"
                  width="50%"
                  height="auto"
                  src={state.croppedImgUrl}
                  style={{ marginLeft: "25%", marginTop: 48 }}
                />
                <p
                  style={{
                    width: "72%",
                    marginLeft: "14%",
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  Select the visible area of your picture using the selector
                  tool. When finished selecting the area, click the save button
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <ReactCrop
                  style={{ marginTop: 48 }}
                  src={state.imgUrl}
                  crop={state.crop}
                  onChange={(newCrop) => onChange(newCrop)}
                  onComplete={onCropComplete}
                  onImageLoaded={onImageLoaded}
                />
              </div>
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="row ssu_bottom">
              <button className="ssu2_modal1_button1" onClick={handleSave2}>
                SAVE
              </button>
              <button
                className="w-32  p-2 border !border-[#73C7A9] !text-[#73C7A9]  rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                onClick={modal2Close}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Avatar;
