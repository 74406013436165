import React, { useState, useEffect, useRef } from "react";
import Loader from "../../../modals/Loader";
import edit_note from "../../../assets/images/edit_note.svg";
import delete_note from "../../../assets/images/deleteDark.png";
import filter_img from "../../../assets/images/filter_img.svg";
import searchImg from "../../../assets/images/search.svg";
import { axiosInstance } from "../../../../config/axiosConfig";
import "./jobPosting.css";
import Modal from "../../../common/modal";
import DataTable from "../../../ui/DataTable";
import { useHistory } from "react-router-dom";
import Button from "../../../ui/Button";
import AddEditJob from "../../../theme_1/main/AddEditJob";
import { showSuccessToast } from "../../../common/toastMeassage/ToastMeassage";

function JobPosting(props) {
  const [applicant, setApplicant] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [addEditModal, setAddEditModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    searchValue: "",
    sortingKey: "", // Updated to store the current sorting key
    limit: 10,
  });
  const [sortOptionText, setSortOptionText] = useState("Sort by");
  const history = useHistory();

  useEffect(() => {
    props?.setHoldBack(0);
    getAllJobPosting();
  }, [filter]);

  const getAllJobPosting = async () => {
    try {
      setLoader(true);
      const res = await axiosInstance.get(
        `v1/LHS/job/getByName?skip=${filter?.page * 10}&search=${
          filter?.searchValue
        }&sort=${filter?.sortingKey}&limit=${filter?.limit}`
      );
      if (res.data) {
        setJobs(res.data.data.job);
        setTotalRecords(res.data?.data?.count);
      } else {
        if (filter?.page > 0) {
          setFilter((prev) => ({ ...prev, page: filter?.page - 1 }));
        }
      }
    } catch (err) {
      if (filter?.page > 0) {
        setFilter((prev) => ({ ...prev, page: filter?.page - 1 }));
      } else {
        setJobs([]);
      }
      setLoader(false);
      setTotalRecords(0);
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: "Job Title",
      key: "jobTitle",
    },
    {
      title: "Job Type",
      key: "contractType",
    },
    {
      title: "Positions",
      key: "openPositions",
    },
    {
      title: "Healthcare Institution Location",
      key: "healthCareLocation",
    },
    {
      title: "Actions",
      key: "action",
      render: (item) => {
        return (
          <div className="flex gap-4 relative">
            <div className="relative w-5">
              <img
                onClick={() => handleEdit(item)}
                className="cursor-pointer"
                src={edit_note}
                alt="Edit"
              />
            </div>
            <div className="relative w-5">
              <img
                onClick={() => openReview(item)}
                className="cursor-pointer"
                src={delete_note}
                alt="Delete"
              />
            </div>
          </div>
        );
      },
    },
  ];
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const handleSorting = (sortValue) => {
    let optionText = "Latest First";
    if (sortValue === "asc") {
      optionText = " Oldest First";
    }
    setFilter((prev) => ({ ...prev, sortingKey: sortValue }));
    setSortOptionText(optionText);
    setSortDropdownOpen(false);
    getAllJobPosting();
  };

  const handleApplicant = (jobTitle, jobDetails) => {
    props?.applicant(jobTitle, jobDetails);
  };

  const debounceTimer = useRef(null);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = null;
    }
    debounceTimer.current = setTimeout(() => {
      setFilter((prev) => ({
        ...prev,
        page: 0,
        searchValue: value,
        limit: value?.length > 0 ? 99999999 : 10,
      }));
    }, 300);
  };

  const handleEdit = (data) => {
    setApplicant(data);
    setAddEditModal(true);
  };

  const openReview = (job) => {
    setModalDelete(true);
    setJobId(job._id);
    setJobTitle(job.jobTitle);
  };

  const closeReview = () => {
    setModalDelete(false);
  };

  const handleDelete = async () => {
    try {
      setShowLoader(true);
      const res = await axiosInstance.delete(`v1/LHS/job/delete/${jobId}`);
      if (res.data) {
        showSuccessToast(res.data?.Message);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      getAllJobPosting();
      setShowLoader(false);
      setModalDelete(false);
    }
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSortDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="bg-white overflow-hidden rounded-tr-xl rounded-tl-xl">
      <div className="flex items-center justify-between gap-4 p-4">
        <div className="w-3/5 top_NSearch overflow-hidden">
          <div className="flex gap-3 w-full">
            <img className="w-5" alt="search.svg" src={searchImg} />
            <input
              className="w-full"
              placeholder="Search By Job Title"
              type="text"
              value={searchText}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div
            className="relative flex items-center cursor-pointer border-1 border-[#73C7A9] rounded-full px-7 py-2 appearance-none"
            ref={dropdownRef}
          >
            <div className="dropdown">
              <span
                className="dropdown-btn "
                onClick={() => setSortDropdownOpen(!sortDropdownOpen)}
              >
                <div className="mr-2">{sortOptionText}</div>
              </span>
              {sortDropdownOpen && (
                <div className="dropdown-content absolute bg-white shadow-md mt-3 w-full z-40 rounded-lg">
                  <div onClick={() => handleSorting("desc")}>Latest First</div>
                  <div onClick={() => handleSorting("asc")}>Oldest First</div>
                </div>
              )}
            </div>
            <img src={filter_img} className="w-5 absolute right-2" />
          </div>
          <Button
            className=" cursor-pointer p-3 rounded-full"
            onClick={() => {
              setApplicant("");
              setAddEditModal(true);
            }}
          >
            Post New job
          </Button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={jobs}
        totalRecords={totalRecords}
        onPageChange={(e) => setFilter((prev) => ({ ...prev, page: e - 1 }))}
        onSearch={null}
        loader={loader}
        holdPageState={filter?.page}
        // title={(item) => handleApplicant(item.jobTitle, item)}
        // moveBack={moveBack}
      />

      <Modal
        isOpen={modalDelete}
        onClose={closeReview}
        className={"md:w-2/5 md:mt-0"}
      >
        <div className="flex flex-col gap-3">
          <div className="staffOne modalStaffOne deleteJob p-0">Delete</div>
          <div className="sendMain text-center">
            Are you sure you want to delete "{jobTitle}"
          </div>
          <div className="flex self-center gap-4 mt-4">
            <Button
              className="border !border-[#73C7A9] !text-[#73C7A9]  rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
              onClick={closeReview}
            >
              CANCEL
            </Button>
            <Button className="rounded-full" onClick={handleDelete}>
              {showLoader ? <Loader /> : "DELETE"}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={addEditModal}
        onClose={() => setAddEditModal(false)}
        className={"md:w-1/2 md:mt-0"}
      >
        <AddEditJob
          type={applicant && "edit"}
          applicantData={applicant}
          closeModal={() => {
            getAllJobPosting();
            setAddEditModal(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default JobPosting;
