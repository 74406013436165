import React, { useState, useEffect, useRef } from "react";
import searchImg from "../../assets/images/search.svg";
import edit_note from "../../assets/images/edit_note.svg";
import delete_note from "../../assets/images/deleteDark.png";
import Nav from "../../theme_1/Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import "../theme2.css";
import "./admin.css";
import DataTable from "../../ui/DataTable";
import Button from "../../ui/Button";
import ManageStaffEdit from "./components/manageStaffEdit";
import _debounce from "lodash/debounce";

function ManageStaff() {
  const [filter, setFilter] = useState({
    page: 0,
    searchValue: "",
    limit: 10,
  });
  const [staffs, setStaffs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [addEditModal, setAddEditModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const getStaffByQuery = async ({ load } = {}) => {
    !load && setLoader(true);
    try {
      let url = `v1/LHS/admin/staffList?skip=${filter?.page * 10}&search=${
        filter?.searchValue
      }&limit=${filter?.limit}`;
      var res = await axiosInstance.get(url);
      if (res?.data?.status === 200) {
        setStaffs(res.data?.data?.data);
        setTotalPage(res.data?.data?.count);
      }
    } catch (err) {
      setStaffs([]);
      setTotalPage(0);
    } finally {
      setLoader(false);
      setButtonLoader(false);
    }
  };

  const changeAdminStatus = async (status, id) => {
    setButtonLoader(id);
    try {
      const userObj = {
        isDeleted: status ? false : true,
      };
      let url = `v1/LHS/staff/update/${id}`;
      var res = await axiosInstance.put(url, userObj);
    } catch (err) {
    } finally {
      getStaffByQuery({ load: true });
    }
  };

  useEffect(() => {
    getStaffByQuery();
  }, [filter]);

  const debounceTimer = useRef(null);

  const handleSearch = (e) => {
    const { value } = e.target;
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
      setSearchText(value);
      debounceTimer.current = null;
    }
    debounceTimer.current = setTimeout(() => {
      setFilter((prev) => ({
        ...prev,
        page: 0,
        searchValue: value,
        limit: value.length > 0 ? 99999999 : 10,
      }));
    }, 300);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
    },
    {
      title: "Email Address",
      key: "email",
    },
    {
      title: "Change Status",
      key: "changeStatus",
      render: (item) => {
        return (
          <>
            <Button
              loading={item?._id == buttonLoader}
              className="rounded-full"
              style={{
                background: !item.isDeleted ? "#ff7675" : "#73C7A9",
              }}
              onClick={() => changeAdminStatus(item.isDeleted, item._id)}
            >
              {item.isDeleted ? "Enable" : "Disable"}
            </Button>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "actions",
      render: (item) => {
        return (
          <div className="flex justify-between h-5 w-15 relative">
            <div className="relative w-5">
              <img
                onClick={() => setAddEditModal(item?._id)}
                className="cursor-pointer"
                src={edit_note}
                alt="Logo"
              />
            </div>
          </div>
        );
      },
    },
  ];

  const onPageChange = (e) => {
    setFilter((prev) => ({ ...prev, page: e - 1 }));
  };

  return (
    <>
      <Nav />
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div
          className="lg:flex gap-3 block align-top py-4 w-11/12 md:w-3/4 mx-auto flex-col max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          {addEditModal ? (
            <ManageStaffEdit
              id={addEditModal}
              setAddEditModal={() => setAddEditModal(false)}
            />
          ) : (
            <div className="bg-white overflow-hidden rounded-tr-xl rounded-tl-xl">
              <div className="flex items-center justify-between p-4">
                <div className="w-3/5 top_NSearch overflow-hidden">
                  <div className="flex gap-3 w-full">
                    <img className="w-5" alt="search.svg" src={searchImg} />
                    <input
                      className="w-full"
                      placeholder="Search staff name"
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={staffs}
                totalRecords={totalPage}
                onPageChange={onPageChange}
                onSearch={null}
                loader={loader}
                holdPageState={filter?.page}
                // moveBack={moveBack}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ManageStaff;
