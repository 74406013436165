import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import key from "../../assets/images/key.svg";
import emailImg from "../../assets/images/email.svg";
import ErrorState from "../staffSignUp/components/errorState";
import logo from "../../assets/images/loginLogo.png";
import "./logIn.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  axiosInstance,
  axiosInstanceWithToken,
} from "../../../config/axiosConfig";
import {
  showSuccessToast,
  showErrorToast,
} from "../../common/toastMeassage/ToastMeassage";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../modals/Loader";

const LogIn = () => {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailInputClick = () => {
    setdisablebtn(false);
    setISdisablebtn(false);
  };

  // const getProfile = (token) => {
  //   return new Promise((resolve, reject) => {
  //     let url = "v1/LHS/staff/getProfile";
  //     axiosInstanceWithToken
  //       .get(url, {
  //         customToken: token,
  //       })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           dispatch({ type: "PROFILE_DATA", payload: response?.data?.data });
  //           setTimeout(() => {
  //             resolve(true);
  //           }, 2000);
  //         } else {
  //           reject(new Error("Failed to get profile"));
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //         reject(error);
  //       });
  //   });
  // };

  const loginFunc = async (role) => {
    try {
      const response = await axiosInstance.post(`v2/LHS/User/Superlogin`, {
        email: email,
        password: password,
      });

      if (response.data.Message === "Logged In Successfully") {
        setIsLoading(true);
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("type", response.data?.data?.role);
        localStorage.setItem("_id", response.data?.data?._id);
        showSuccessToast("Login Successfully");
        const jobselectId = localStorage.getItem("jobId");

        setTimeout(() => {
          if (response.data?.data?.role === "superAdmin") {
            window.location.href = "/main/admin";
          } else if (response.data?.data?.role === "admin") {
            if (response.data?.data.permissions.includes("Manage Staff")) {
              window.location.href = "/main/admin/manageStaff";
            } else {
              window.location.href = "/main/admin/manageHospital";
            }
          } else if (response.data?.data?.role === "staff") {
            if (search) {
              window.location.href = `/myJobDetails/${search?.substring(1)}`;
            } else {
              window.location.href = `/staff`;
            }
          } else {
            window.location.href = "/hospital";
          }
        }, 2000);
        // const token = response.data.token;
        // const result = await getProfile(token);

        // if (result) {
        //   window.location.href =
        //     response.data?.data?.role === "superAdmin"
        //       ? "/main/admin"
        //       : `/${response.data?.data?.role}`;
        // }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showErrorToast(error?.Message);

      if (error.status === 406) {
        // if (email === "") {
        //   showErrorToast(error?.response);
        // }
        // if (password === "") showErrorToast(" Valid password required ");
      }
    }
  };

  const [Isdisablebtn, setISdisablebtn] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleLogin = async () => {
    setIsLoading(true);

    if (!email || !password) {
      setIsLoading(false); // Stop the loader

      if (!email) {
        setISdisablebtn(true);
      }
      if (!password) {
        setPasswordError(true);
      }

      return; // Do not proceed with the login if either email or password is empty
    }
    loginFunc();
    // emailRepeatCheck(email);
    setISdisablebtn(false);
    setPasswordError(false);
  };

  const [disablebtn, setdisablebtn] = useState(false);

  // const emailRepeatCheck = async (email) => {
  //   try {
  //     setdisablebtn(false); // Reset disablebtn state to false when a new email is entered

  //     const data = { email };
  //     const [res1, res2, res3] = await Promise.all([
  //       axiosInstance.post("v1/LHS/hospital/emailCheck", data),
  //       axiosInstance.post("v1/LHS/staff/emailCheck", data),
  //       axiosInstance.post("v1/LHS/admin/emailCheck", data),
  //     ]);

  //     if (
  //       (res1?.data?.result === "OK" &&
  //         res2.data?.result === "REPEAT" &&
  //         res3.data?.result === "OK") ||
  //       (res1?.data?.result === "REPEAT" &&
  //         res2.data?.result === "REPEAT" &&
  //         res3.data?.result === "OK")
  //     ) {
  //       loginFunc("staff");
  //     } else if (
  //       res1?.data?.result === "OK" &&
  //       res2.data?.result === "OK" &&
  //       res3.data?.result === "REPEAT"
  //     ) {
  //       loginFunc("admin");
  //     } else if (
  //       res1?.data?.result === "REPEAT" &&
  //       res2.data?.result === "OK" &&
  //       res3.data?.result === "OK"
  //     ) {
  //       loginFunc("hospital");
  //     } else if (
  //       res1?.data?.result === "REPEAT" &&
  //       res2.data?.result === "REPEAT" &&
  //       res3.data?.result === "REPEAT"
  //     ) {
  //       setdisablebtn(true);
  //     } else {
  //       setdisablebtn(true); // Ensure the button is enabled in other cases
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Error during emailRepeatCheck:", error);
  //     setdisablebtn(false); // Handle errors by ensuring the button is enabled
  //     setIsLoading(false);
  //   }
  // };

  const handleChange = (e) => {
    setUsername(e.target.value);
    setISdisablebtn(false);
    setdisablebtn(false);
  };

  const handleSignUpClick = () => {
    history.push("/signUp");
  };

  const handleExploreJobClick = () => {
    history.push("/jobs");
  };
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const role = localStorage.getItem("type");

    if (token && role) {
      if (role === "superAdmin") {
        history.push("/main/admin");
      } else if (role === "admin") {
        history.push("/main/admin/manageStaff");
      } else if (role === "staff") {
        history.push("/staff");
      } else {
        history.push("/hospital");
      }
    }
  }, [history]);
  return (
    <>
      <div
        className="d-flex h-screen overflow-hidden"
        style={{ backgroundColor: "#73C7A9" }}
      >
        <div
          className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
          style={{ padding: "0", maxWidth: 720 }}
        >
          <div className="ssu_container p-5 max-h-[90vh] h-auto">
            <p className="flex items-center justify-center">
              <img alt="img" src={logo} className="logInLogo" />
            </p>

            <div className="logIn_txt1 font-semibold text-2xl mt-4">
              {" "}
              Log in{" "}
            </div>
            {/* <div className="logIn_txt2 mt-1 text-base">
              {" "}
              Welcome to LinkHealthStaff{" "}
            </div> */}
            <div className="logIn_body mt-3 w-auto">
              {/* Email Input */}
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <div className="input_left_email_icon">
                  <img width="18px" height="15px" alt="img" src={emailImg} />
                </div>

                <input
                  className="ssu2_modal3_select"
                  type="text"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleChange}
                  onClick={handleEmailInputClick}
                />
              </div>
              {Isdisablebtn && <p className="error-code">Email is Required</p>}
              {disablebtn && <p className="error-code">Email does not exist</p>}
              {/* Password Input */}
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <div className="input_left_icon">
                  <img width="18px" height="15px" alt="img" src={key} />
                </div>

                <input
                  id="psw"
                  className="ssu3_password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  }}
                />

                <div className="ssu3_eye">
                  <VisibilityOffIcon
                    id="c_psw"
                    style={{ display: showPassword ? "none" : "block" }}
                    onClick={() => setShowPassword(true)}
                  />
                  <VisibilityIcon
                    id="o_psw"
                    style={{ display: showPassword ? "block" : "none" }}
                    onClick={() => setShowPassword(false)}
                  />
                </div>
                {passwordError && (
                  <p className="error-code">Password is Required</p>
                )}
              </div>
              <ErrorState show={""} />

              <p
                className="login_letterBtn mt-2 text-base"
                onClick={() => history.push("/forgotPassword")}
              >
                Forgot your password?
              </p>

              <button
                className="logIn_button"
                // disabled={disablebtn}
                // style={{
                //   backgroundColor: disablebtn ? "gray" : " #73C7A9",
                // }}
                onClick={() => handleLogin()}
              >
                {isLoading ? <Loader /> : "LOGIN"}
              </button>

              <p className="login_txt3 text-base" onClick={handleSignUpClick}>
                Don't have an account?{" "}
                <span
                  style={{
                    color: "#73C7A9",
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                >
                  Sign up
                </span>
              </p>

              <div
                className="logIn_button signUp_button mt-3"
                onClick={handleExploreJobClick}
              >
                EXPLORE JOBS
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogIn;
