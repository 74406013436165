import React, { useEffect, useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import user from "../../assets/images/user.svg";
import phoneIcon from "../../assets/images/phone.svg";
import emailIcon from "../../assets/images/email.svg";
import key from "../../assets/images/key.svg";
import SubHeader from "../staffSignUp/components/subHeader";
// import img from "../../assets/images/1-3.png";
import img from "../../assets/images/1-4.svg";
import role1 from "../../assets/images/role.svg";
import ErrorState from "../staffSignUp/components/errorState";
import { callApi } from "../../../redux/action/action";
import Loader from "../../modals/Loader";
import "react-toastify/dist/ReactToastify.css";
import "./hospSignUp.css";
import { HeaderComp } from "../staffSignUp/headerComp";

const HospSignUp = ({ history }) => {
  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
    psw: "",
    repsw: "",
    phone: "",
    role: "",
    ein: "",
    showLoader: false,
    error: {
      fname: "none",
      lname: "none",
      email: "none",
      psw: "none",
      repsw: "none",
      phone: "none",
      role: "none",
      // ein: "none",
      showLoader: false,
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    var hosFirstname = window.localStorage.getItem("first_name") || "";
    var hosLastName = window.localStorage.getItem("last_name") || "";
    var hosEmail = window.localStorage.getItem("email") || "";
    var hosPhone = window.localStorage.getItem("phone") || "";
    var hosPassword = window.localStorage.getItem("password") || "";
    var hosRepassword = window.localStorage.getItem("password") || "";
    var hosRole = window.localStorage.getItem("role") || "";
    var hosEin = window.localStorage.getItem("ein") || "";

    setState((prev) => ({
      ...prev,
      fname: hosFirstname,
      lname: hosLastName,
      email: hosEmail,
      psw: hosPassword,
      repsw: hosRepassword,
      phone: hosPhone,
      role: hosRole,
      ein: hosEin,
    }));
  }, []);

  const togglePasswordVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  const toggleConfirmPasswordVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
    }));
  };

  const setVal = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === "") {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: inputValue,
        error: { ...prevState.error, [e.target.name]: "none" },
      }));
      if (e.target.name === "fname") {
        localStorage.setItem("firstName", inputValue);
      }
    }
  };

  const setRole = (e) => {
    if (e.target.value.length < 100) {
      setState((prevState) => ({
        ...prevState,
        role: e.target.value,
        error: { ...prevState.error, role: "none" },
      }));
    }
  };

  const setEmail = async (e) => {
    const inputValue = e.target.value.trim();

    setState((prevState) => ({
      ...prevState,
      email: inputValue,
      emailErr: inputValue ? "Valid email address is required." : "",
    }));

    if (inputValue) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValidEmail = re.test(String(inputValue).toLowerCase());
      if (isValidEmail) {
        const isEmailValid = await emailRepeatCheck();

        if (isEmailValid) {
          setState((prevState) => ({
            ...prevState,
            error: {
              ...prevState.error,
              email: "none",
            },
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            error: {
              ...prevState.error,
              email: "block",
            },
            emailErr: "This Email address is already in use.",
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            email: "block",
          },
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          email: "block",
        },
        emailErr: "Valid email address is required.",
      }));
    }
  };

  const setPhone = (e) => {
    if (e.target.value.length < 11) {
      setState((prevState) => ({
        ...prevState,
        phone: e.target.value,
      }));

      var validate;
      if (e.target.value.match(/\d/g))
        if (e.target.value.match(/\d/g).length === 10) validate = true;
        else validate = false;
      else validate = false;

      var error = { ...state.error };
      if (e.target.value !== "" && validate) {
        error.phone = "none";
      } else {
        error.phone = "block";
      }

      setState((prevState) => ({
        ...prevState,
        error: { ...error },
      }));
    }
  };

  const setPsw = (e) => {
    setState((prevState) => ({
      ...prevState,
      psw: e.target.value,
    }));

    var password = e.target.value;
    var error = { ...state.error };

    if (password.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pswErr: "Password is required.",
        error: { ...error, psw: "block" },
      }));
      return;
    } else {
      if (password.length < 6) {
        setState((prevState) => ({
          ...prevState,
          pswErr:
            "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
          error: { ...error, psw: "block" },
        }));
      } else {
        var matchedCase = [];
        matchedCase.push("[$@$!%*#?&]");
        matchedCase.push("[A-Z]");
        matchedCase.push("[0-9]");
        matchedCase.push("[a-z]");
        var ctr = 0;
        for (var i = 0; i < matchedCase.length; i++) {
          if (new RegExp(matchedCase[i]).test(password)) {
            ctr++;
          }
        }
        // Display it
        switch (ctr) {
          case 0:
          case 1:
          case 2:
          case 3:
            setState((prevState) => ({
              ...prevState,
              pswErr:
                "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
              error: { ...error, psw: "block" },
            }));
            break;
          case 4:
            error.psw = "none";
            setState((prevState) => ({
              ...prevState,
              error: { ...error },
            }));
            break;
          default:
            return;
        }
        if (state.repsw !== "") {
          var validate = state.repsw === e.target.value;
          error = { ...state.error };
          if (validate) {
            error.repsw = "none";
          } else {
            error.repsw = "block";
          }
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      error: { ...error },
    }));
  };

  const setRepsw = (e) => {
    var validate = state.psw === e.target.value;
    setState((prevState) => ({
      ...prevState,
      repsw: e.target.value,
    }));

    var error = { ...state.error };
    if (e.target.value !== "" && validate) {
      error.repsw = "none";
    } else if (e.target.value === "" && state.psw === "") {
      error.repsw = "none";
    } else {
      error.repsw = "block";
    }

    setState((prevState) => ({
      ...prevState,
      error: { ...error },
    }));
  };

  const setEin = async (e) => {
    const einValue = e.target.value.replace(/\D/g, "");

    setState((prevState) => ({
      ...prevState,
      ein: einValue,
    }));

    if (einValue !== "") {
      var error = { ...state.error };
      error.ein = "none";
      setState((prevState) => ({
        ...prevState,
        error: { ...error },
        einMsgError: "The EIN number is required",
      }));
    }

    var res = await callApi("GET", `v1/LHS/hospital/ein/${einValue}`);
    if (res.status === 500) {
      var error = { ...state.error };
      error.ein = "block";
      setState((prevState) => ({
        ...prevState,
        error: { ...error },
        einMsgError: "EIN Number already present",
      }));
    } else {
      var error = { ...state.error };
      error.ein = "none";
      setState((prevState) => ({
        ...prevState,
        error: { ...error },
        einMsgError: "The EIN number is required",
      }));
    }
  };

  const emailRepeatCheck = async () => {
    if (state.email !== "") {
      var data = {
        email: state.email,
      };

      var res1 = await callApi(
        "POST",
        "v1/LHS/hospital/emailCheck",
        null,
        data
      );
      var res2 = await callApi("POST", "v1/LHS/staff/emailCheck", null, data);
      var res3 = await callApi("POST", "v1/LHS/admin/emailCheck", null, data);

      return (
        res1.result === "OK" && res2.result === "OK" && res3.result === "OK"
      );
    }
    return false;
  };

  const continueToNext = async () => {
    const { fname, lname, email, phone, role, psw, repsw, ein, showLoader } =
      state;

    // Validation for empty fields
    if (!fname || !lname || !email || !phone || !role || !psw || !repsw) {
      setState((prevState) => ({
        ...prevState,
        error: {
          fname: !fname ? "block" : "none",
          lname: !lname ? "block" : "none",
          email: !email ? "block" : "none",
          phone: !phone ? "block" : "none",
          role: !role ? "block" : "none",
          psw: !psw ? "block" : "none",
          repsw: !repsw ? "block" : "none",
          // ein: !ein ? "block" : "none",
          showLoader: false,
        },
      }));
      return;
    }

    // Continue to the next step
    setState((prevState) => ({
      ...prevState,
      showLoader: true,
    }));

    const isEmailValid = await emailRepeatCheck();

    if (isEmailValid) {
      localStorage.setItem("first_name", state.fname);
      localStorage.setItem("last_name", state.lname);
      localStorage.setItem("email", state.email);
      localStorage.setItem("phone", state.phone);
      localStorage.setItem("role", state.role);
      localStorage.setItem("password", state.psw);
      // localStorage.setItem("ein", state.ein);
      history.push("/hospSignUp_2");
    } else {
      setState((prevState) => ({
        ...prevState,
        emailErr: "This Email address is already in use.",
        error: { ...prevState.error, email: "block" },
        showLoader: false,
      }));
    }
  };

  const { isConfirmPasswordVisible, isPasswordVisible, showLoader } = state;
  const handleBack = () => {
    history.push("/signUp");
  };
  return (
    <>
      <div
        className="d-flex h-screen overflow-hidden"
        style={{ backgroundColor: "#73C7A9" }}
      >
        <div
          className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
          style={{ padding: "0", maxWidth: 720 }}
        >
          <div className="ssu_container py-4 px-5 min-h-[90vh] max-h-[90vh] h-auto flex flex-col">
            <HeaderComp />
            <div className="flex flex-col justify-between h-screen">
              <SubHeader
                num="1"
                onBack={handleBack}
                title="Contact Information"
                redo={false}
                next="/hospSignUp_2"
                prev="/signUp"
                img={img}
              />
              <div className="ssu3_body m-0 w-auto" style={{ marginTop: 0 }}>
                <div className="hsu1_txt1 text-xl mt-4 mb-0">
                  Create Your Account
                </div>
                <div className="hsu1_txt2 text-base mt-4 mb-0">
                  Get qualified staff on-demand for your Healthcare Institution
                </div>
                <div className="ssu2_modal1_input py-1 mt-2">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_email_icon top-[20px]">
                      <img width="18px" height="15px" alt="img" src={user} />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="First Name"
                      type="text"
                      value={state.fname}
                      name="fname"
                      onChange={setVal}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={state.error.fname}
                    name="First Name is required."
                  />
                </div>
                <div className="ssu2_modal1_input py-1">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_email_icon top-[20px]">
                      <img width="18px" height="15px" alt="img" src={user} />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Last Name"
                      type="text"
                      value={state.lname}
                      name="lname"
                      onChange={setVal}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={state.error.lname}
                    name="Last Name is required."
                  />
                </div>
                <div className="ssu2_modal1_input py-1">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_email_icon top-[20px]">
                      <img
                        width="18px"
                        height="15px"
                        alt="img"
                        src={emailIcon}
                      />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Company Email Address"
                      type="text"
                      value={state.email}
                      name="email"
                      onChange={setEmail}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={state.error.email}
                    name="This Email address is already in use."
                  />
                </div>
                <div className="ssu2_modal1_input py-1">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_icon top-[15px]">
                      <img
                        width="18px"
                        height="15px"
                        alt="img"
                        src={phoneIcon}
                      />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Phone Number"
                      type="number"
                      value={state.phone}
                      name="phone"
                      onChange={setPhone}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={state.error.phone}
                    name="10 digits phone number is required."
                  />
                </div>
                <div className="ssu2_modal1_input py-1">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_icon top-[15px]">
                      <img alt="img" width="18px" height="15px" src={role1} />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Your role in hiring process."
                      type="text"
                      value={state.role}
                      name="role"
                      onChange={setRole}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={state.error.role}
                    name="The role in hiring process is required. "
                  />
                </div>
                <div className="ssu2_modal1_input py-1">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_icon top-[15px]">
                      <img width="18px" height="15px" alt="img" src={key} />
                    </div>
                    <input
                      id="psw"
                      className="ssu3_password"
                      placeholder="Password"
                      type={isPasswordVisible ? "text" : "password"}
                      value={state.psw}
                      name="psw"
                      onChange={setPsw}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                    <div
                      className="ssu3_eye"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <VisibilityIcon
                          id="o_psw"
                          style={{ display: "block" }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          id="c_psw"
                          style={{ display: "block" }}
                        />
                      )}
                    </div>
                  </div>
                  <ErrorState
                    show={state.error.psw}
                    name="Password is required."
                  />
                </div>
                <div className="ssu2_modal1_input py-1">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_icon top-[15px]">
                      <img width="18px" height="15px" alt="img" src={key} />
                    </div>
                    <input
                      id="repsw"
                      className="ssu3_password"
                      placeholder="Confirm Password"
                      type={isConfirmPasswordVisible ? "text" : "password"}
                      value={state.repsw}
                      name="repsw"
                      onChange={setRepsw}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                    <div
                      className="ssu3_eye"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {isConfirmPasswordVisible ? (
                        <VisibilityIcon
                          id="o_psw"
                          style={{ display: "block" }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          id="c_psw"
                          style={{ display: "block" }}
                        />
                      )}
                    </div>
                  </div>
                  <ErrorState
                    show={state.error.repsw}
                    name="Doesn't match with the password."
                  />
                </div>
                {/* <div className="ssu2_modal1_input py-1">
                  <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                    <div className="input_left_icon top-[15px]">
                      <img alt="img" width="18px" height="15px" src={key} />
                    </div>
                    <input
                      id="ein"
                      className="ssu2_modal3_select"
                      placeholder="EIN Number"
                      type={showPassword ? "text" : "password"}
                      value={state.ein}
                      name="ein"
                      onChange={setEin}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") continueToNext();
                      }}
                    />
                    <div
                      className="ssu3_eye top-3"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </div>
                  </div>
                  <ErrorState
                    show={state.error.ein}
                    name="EIN Number already in use."
                  />
                </div> */}
              </div>

              <hr style={{ margin: "10px 0px 0px" }} />
              <div className="ssu_bottom">
                <div className="ssu_button" onClick={continueToNext}>
                  {showLoader ? <Loader /> : "CONTINUE"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospSignUp;
