import React, { useState, useEffect } from 'react';
import search from '../../assets/images/search.svg';
import avatar from '../../assets/images/admin.svg';
import { Link } from 'react-router-dom';
import { removeSession } from '../../../redux/action/action';

const Header = (props) => {
  const [type, setType] = useState('');
  const [name, setName] = useState('Olivia');
  const [title, setTitle] = useState('Profile');
  const [searchText, setSearchText] = useState('');
  const [openAccount, setOpenAccount] = useState(false);
  const [page, setPage] = useState('');

  useEffect(() => {
    initState(props);
  }, [props]);

  const initState = (props) => {
    setType(props.auth.type);
    setName(props.auth.name);
    setTitle(props.auth.title);
  };

  useEffect(() => {
    let url = window.location.href;
    let splitUrl = url.split('/main/');
    console.log(splitUrl, url);

    switch (splitUrl[1]) {
      case 'admin':
        setPage('Dashboard');
        break;
      case 'admin/manageAdmin':
        setPage('Manage Administrator');
        break;
      case 'admin/manageStaff':
        setPage('Manage Staff');
        break;
      case 'admin/manageHospital':
        setPage('Manage Hospital');
        break;
      case 'admin/manageAttendance':
        setPage('Manage Attendance');
        break;
      case 'admin/managePayment':
        setPage('Manage Payment');
        break;
      default:
        setPage('');
    }
  }, []);


  const logOut = () => {
    removeSession();
    window.location.href = '/login';
  };

  const toggleAccount = () => {
    setOpenAccount(!openAccount);
  };

  return (
    <div className="theme2_header">
      {page}
      <div className="theme2_header_item">
        <div className="theme2_header_avatar_container mb-0" onClick={toggleAccount}>
          <div className="theme2_header_avatar_img">
            {type === 'staff' ? (
              <img
                width="100%"
                src={props.auth.staffAvatar || avatar}
                alt="avatar.png"
                style={{ borderRadius: '50%' }}
              />
            ) : (
              <img
                width="100%"
                src={props.auth.avatar || avatar}
                alt="avatar.png"
                style={{ borderRadius: '50%' }}
              />
            )}

            <div className={openAccount ? 'rectangle' : 'rectangle openAccount'}></div>
            <div className={openAccount ? 'account admin_account' : 'account openAccount'}>
              <Link
                style={{ textDecoration: 'none', marginBottom: '15px', display: 'flex' }}
                to="/main/admin/change-password"
                className="profile"
              >
                Change Password
              </Link>
              <p className="logout" onClick={logOut}>
                Logout
              </p>
            </div>
          </div>
          <b className="theme2_header_avatar_name">
            {type === 'staff' ? props.auth.staffName : name}
          </b>
        </div>
        <div className="searchImag">
          <img className="theme2_header_search_img" alt="search.svg" src={search} />
        </div>
      </div>
    </div>
  );
};

export default Header;
