import React, { useState, useEffect } from "react";
import edit_note from "../../../assets/images/edit_note.svg";
import institution from "../../../assets/images/institute.svg";
import institutionSize from "../../../assets/images/instituteSize.svg";
import institutionWebsite from "../../../assets/images/inst_website.svg";
import { callApi, setSession } from "../../../../redux/action/action";
import { SET_HP_PROFILE } from "../../../../constants/actionTypes";
import { axiosInstance } from "../../../../config/axiosConfig";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Modal from "../../../common/modal";
import Loader from "../../../modals/Loader";
import { toast } from "react-toastify";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../common/toastMeassage/ToastMeassage";
import Locations from "./locations";
import { useDispatch, useSelector } from "react-redux";

const Institution = ({ hospitalData, setHospitalDetails }) => {
  const [showLoader, setShowLoader] = useState(false);
  const profile = useSelector((state) => state.data.profile);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: "",
    size: "",
    website: "",
    ein: "",
    modalShow: "none",
    mname: "",
    msize: "",
    mwebsite: "",
    mein: "",
    error: {
      name: "none",
      size: "none",
      website: "none",
      // ein: "none",
    },
    nameErr: "Healthcare institution name is required.",
    // einMsgError: "EIN is required",
  });

  const setName = (e) => {
    e.preventDefault();
    if (e.target.value.length < 250) {
      setState((prevState) => ({
        ...prevState,
        mname: e.target.value,
        error: {
          ...prevState.error,
          name: e.target.value !== "" ? "none" : "block",
        },
      }));
    }
  };

  const setsize = (e) => {
    if (e.target.value.length < 7) {
      setState((prevState) => ({
        ...prevState,
        msize: e.target.value,
        error: {
          ...prevState.error,
          size: "none",
        },
      }));
    }
  };

  const setein = async (e) => {
    const einValue = e.target.value.replace(/\D/g, "");
    setState((prevState) => ({ ...prevState, mein: einValue }));

    if (einValue !== "") {
      setState((prevState) => ({
        ...prevState,
        error: { ...prevState.error, ein: "none" },
        einMsgError: "",
      }));
    }
    try {
      var res = await axiosInstance.get(`v1/LHS/hospital/ein/${einValue}`);

      if (res.status === 500) {
        setState((prevState) => ({
          ...prevState,
          error: { ...prevState.error, ein: "block" },
          einMsgError: "EIN Number already present",
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          error: { ...prevState.error, ein: "none" },
          einMsgError: "EIN is required",
        }));
      }
    } catch (err) {}
  };

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") âœ¨
    let checkIfNum;

    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }

    return checkIfNum && e.preventDefault();
  };

  const setInsWebsite = (e) => {
    setState((prevState) => ({ ...prevState, mwebsite: e.target.value }));
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    var valid = !!pattern.test(e.target.value);
    setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [e.target.name]: e.target.value === "" || !valid ? "block" : "none",
      },
    }));
  };

  const continueToNext = async () => {
    let { mname, msize, mwebsite, mein } = state;
    if (!mname || !msize || !mwebsite || !mein) {
      showErrorToast("All fields are required!");
      return;
    }
    try {
      const response = await axiosInstance.post(
        "v1/LHS/hospital/update/" + hospitalData?._id,
        {
          healthCareInstitution: {
            name: mname,
            size: msize,
            website: mwebsite,
          },
          ein: mein,
        }
      );
      if (response.data) {
        toast.success(response.data.Message);
        setHospitalDetails(response?.data?.data);
        closeModal();
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const modalClose = () => {};

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = (str) => {
    setState((prevState) => ({
      ...prevState,
      mname: hospitalData?.healthCareInstitution?.name,
      msize: hospitalData?.healthCareInstitution?.size,
      mwebsite: hospitalData?.healthCareInstitution?.website,
      mein: hospitalData?.ein,
      modalShow: "block",
      error: {
        name: "none",
        size: "none",
        website: "none",
        ein: "none",
      },
    }));
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <div className="px-5 py-4 grid gap-4 w-full grid-cols-1 ">
      <div className="w-1/2">
        <div className="t2_sp_work_title"> Healthcare Institution details</div>
        <div className="t2_sp_work_container gap-0 relative">
          <div className="grid gap-3 grid-flow-col grid-col-2">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <h6 style={{ fontSize: 16 }}> Healthcare institution name</h6>
                <h5
                  className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                  title={state?.name}
                >
                  {hospitalData?.healthCareInstitution?.name}{" "}
                </h5>
              </div>
              <div className="flex flex-col" style={{}}>
                <h6 style={{ fontSize: 16 }}>
                  {" "}
                  Healthcare institution website{" "}
                </h6>
                <h5
                  className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                  title={state?.website}
                >
                  {" "}
                  {hospitalData?.healthCareInstitution?.website}{" "}
                </h5>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="">
                <h6 style={{ fontSize: 16 }}> Healthcare institution size </h6>
                <h5
                  className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                  title={state?.size}
                >
                  {hospitalData?.healthCareInstitution?.size}
                </h5>
              </div>
              {profile?.role == "hospital" && (
                <div className=" text-xl">
                  {/* <h6 style={{ fontSize: 16 }}> EIN Number </h6> */}
                  <input
                    type="password"
                    // value={hospitalData?.ein}
                    disabled={true}
                    className="mt-[10px] flex-wrap text-xl overflow-x-auto break-all"
                  ></input>
                </div>
              )}
            </div>
          </div>
          <div>
            {(profile?.role == "hospital" || profile?.role == "superAdmin") && (
              <img
                alt="icon.svg"
                src={edit_note}
                width="20px"
                className="absolute top-2 right-2 cursor-pointer"
                onClick={openModal}
              />
            )}
          </div>
        </div>
      </div>
      <Locations
        hospitalData={hospitalData}
        setHospitalDetails={setHospitalDetails}
      />

      <Modal isOpen={modalOpen} onClose={closeModal} className="md:w-1/2">
        <div className="w-full">
          <div className="ssu2_modal1_text1 text-xl">
            Edit healthcare institution details
          </div>
          <div className="text-lg mt-2 p-2">Healthcare institute name</div>
          <div className="ssu2_modal1_input">
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                {/* <img
                      width="18px"
                      height="15px"
                      alt="img"
                      src={institution}
                    /> */}
              </div>
              <input
                className="ssu2_modal3_select pl-5"
                placeholder="Healthcare institution name"
                type="text"
                defaultValue={
                  hospitalData?.healthCareInstitution?.name || state.mname
                }
                name="mname"
                onChange={setName}
                onKeyUp={(e) => {
                  if (e.key === "Enter") continueToNext();
                }}
              />
            </div>
          </div>
          <div className="text-lg ">Healthcare institute size</div>
          <div className="ssu2_modal1_input">
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                {/* <img
                      alt="img"
                      width="18px"
                      height="15px"
                      src={institutionSize}
                    /> */}
              </div>
              <input
                className="ssu2_modal3_select pl-5"
                placeholder="Healthcare institution size"
                type="number"
                min="1"
                max="999999"
                defaultValue={
                  hospitalData?.healthCareInstitution?.size || state.msize
                }
                name="size"
                onKeyDown={formatInput}
                onChange={setsize}
                onKeyUp={(e) => {
                  if (e.key === "Enter") continueToNext();
                }}
              />
            </div>
          </div>
          <div className="text-lg">Healthcare institute website</div>
          <div className="ssu2_modal1_input">
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                {/* <img
                      width="18px"
                      height="15px"
                      alt="img"
                      src={institutionWebsite}
                    /> */}
              </div>
              <input
                className="ssu2_modal3_select pl-5"
                placeholder="Healthcare institution website"
                type="text"
                defaultValue={
                  hospitalData?.healthCareInstitution?.website || state.mwebsite
                }
                name="mwebsite"
                onChange={setInsWebsite}
                onKeyUp={(e) => {
                  if (e.key === "Enter") continueToNext();
                }}
              />
            </div>
          </div>
          {profile?.role == "hospital" && (
            <>
              {/* <div className="text-lg">EIN number</div>
              <div className="ssu2_modal1_input">
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div className="input_left_icon"></div>
                  <input
                    className="ssu2_modal3_select pl-5"
                    placeholder="EIN"
                    type={showPassword ? "text" : "password"}
                    defaultValue={hospitalData?.ein || state.mein}
                    name="size"
                    onKeyDown={formatInput}
                    onChange={setein}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") continueToNext();
                    }}
                  />

                  <div
                    className="ssu3_eye"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </div>
                </div>
              </div> */}
            </>
          )}
          <div className=" flex justify-end gap-3 t-4">
            <button
              className="w-32  border-1 text-[#73C7A9] border !border-[#73C7A9] rounded-[28px] p-2 hover:bg-[#73C7A9] hover:text-white"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="w-32 text-white bg-[#73C7A9] rounded-[28px] p-2"
              onClick={continueToNext}
            >
              {showLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Institution;
