import React, { useState, useEffect } from "react";
import ContactDetails from "../../staffProfile/contactDetails";
import JobType from "../../staffProfile/components/jobType";
import Location from "../../staffProfile/components/location";
import NurseLicense from "../../staffProfile/components/nurseLicense";
import Insurance from "../../staffProfile/components/insurance";
import DrugTest from "../../staffProfile/components/drugTest";
import Education from "../../staffProfile/components/education";
import Certifications from "../../staffProfile/components/certifications";
import MedicalSettings from "../../staffProfile/components/medicalSettings";
import place from "../../../assets/images/place.svg";
import { callApi, setSession } from "../../../../redux/action/action";
import Avatar from "../components/staffAvatar";
import "../admin.css";
import "../../theme2.css";

function ManageStaffProfile() {
  const [stateOfUs, setStateOfUs] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [stateVal, setStateVal] = useState("");
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    // if (props._id === "") window.location.href = "/main/admin/manageStaff";
    const temp = stateOfUs.map((state, index) => {
      return {
        name: state,
        num: index,
      };
    });
    setStateOfUs(temp);
  }, []);

  // const initState = (props) => {
  //   const nursingLicence = props.nursingLicence.map((license, index) => {
  //     return {
  //       ...license,
  //       num: index,
  //     };
  //   });
  //   setNursingLicence(nursingLicence);
  //   const temp = stateOfUs.reduce((acc, state, stateIndex) => {
  //     props.nursingLicence.forEach((license) => {
  //       if (state.name === license.state) {
  //         acc.push(stateIndex);
  //       }
  //     });
  //     return acc;
  //   }, []);
  //   setSelectedStates(temp);
  // };

  const addState = (e) => {
    const state = e.target.value;
    let num;
    const states = stateOfUs;
    for (let i = 0; i < states.length; i++) {
      if (states[i].name === state) {
        num = i;
      }
    }
    setStateVal("");
    setShowInput(false);

    const arr = selectedStates.concat(num);
    setSelectedStates(arr);

    const nursingLicence = [
      ...nursingLicence,
      {
        image: "",
        expirationDate: new Date(),
        state: state,
        name: "",
        number: "",
        num: nursingLicence.length,
      },
    ];

    updateDB(nursingLicence);
  };

  const updateDB = async (nursingLicence) => {
    const data = { nursingLicence: nursingLicence };
    const token = "Bearer " + localStorage.getItem("accessToken");
    const _id = props._id;
    const res = await callApi("POST", "v1/LHS/staff/update/" + _id, token, data);
    setSession(res.token, res.data._id, "admin");
  };

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   initState(props);
  // }, [props]);

  return (
    <div>
      <Avatar setCurPos={() => console.log("")} admin={true} id={props.id} />
      <ContactDetails
        setCurPos={() => console.log("")}
        admin={true}
        id={props.id}
      />
      <JobType setCurPos={() => console.log("")} admin={true} id={props.id} />
      <Location setCurPos={() => console.log("")} admin={true} id={props.id} />
      <NurseLicense
        setCurPos={() => console.log("")}
        admin={true}
        id={props.id}
      />
      <div
        className="ssu2_modal1_input"
        style={{
          maxWidth: "none",
          width: "100%",
          display: showInput ? "block" : "none",
        }}
      >
        <div
          className="ssu2_modal1_input ssu2_modal3_selectBox"
          style={{ maxWidth: "none!important" }}
        >
          <div className="input_left_icon">
            <img
              alt="img"
              src={place}
              width="16px"
              height="20px"
              style={{ marginTop: "-3px" }}
            />
          </div>
          <select
            className="ssu2_modal3_select"
            name="option"
            onChange={addState}
            value={stateVal}
          >
            <option value="" disabled selected customhidden>
              Select a state
            </option>
            {stateOfUs.map((state) => (
              <option
                key={state.name}
                disabled={selectedStates.includes(state.num)}
                value={state.name}
              >
                {state.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Insurance setCurPos={() => console.log("")} admin={true} id={props.id} />
      <DrugTest setCurPos={() => console.log("")} admin={true} id={props.id} />
      <Education setCurPos={() => console.log("")} admin={true} id={props.id} />
      <Certifications
        setCurPos={() => console.log("")}
        admin={true}
        id={props.id}
      />
      <MedicalSettings
        setCurPos={() => console.log("")}
        admin={true}
        id={props.id}
      />
    </div>
  );
}

export default ManageStaffProfile;
