import React from "react";
import DatePicker from "react-datepicker";
import { Document, Page, pdfjs } from "react-pdf";
import $ from "jquery";
import "./findStaff.css";
import "./applicantMember.css";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import avatar from "../../../assets/images/avatar.png";
import badge from "../../../assets/images/badge.png";
import calendar from "../../../assets/images/calendar.png";
import left_arrow from "../../../../components/assets/images/left_arrow.png";
import { callApi } from "../../../../redux/action/action";
import ViewDoc from "../../../../components/modals/viewDoc";
import axios from "axios";
import { AddReasonModal } from "./../../../../containers/staff/myJobs/myJob";
import moment from "moment";
import { CometChat } from "@cometchat-pro/chat";
import Loader from "../../../modals/Loader";

class ApplicantMember extends React.Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      showReview: false,
      showPdf: "none",
      staffRating: "",
      src: "",
      format: "",
      numPages: null,
      pageNumber: 1,
      viewResume: false,
      avatar: "",
      processReview: "",
      processInterview: "",
      processOffer: "",
      processHire: "",
      processTimeReview: "",
      processTimeInterview: "",
      processTimeOffer: "",
      processTimeHire: "",
      showView: "none",
      showReviewView: "none",
      expStartDate: null,
      expEndDate: null,
      error: {
        dateStart: "none",
        dateEnd: "none",
      },
      showReasonModal: "",
      rejectReason: "",
      showInterestModal: false,
      allJobIds: [],
      selectedJobId: "",
      jobDetails: {},
      interviewLoader: false,
      jobOfferLoader: false,
      confirmHiringLoader: false,
    };
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  handleprops = () => {
    props = this.props.applicantData;
  };
  componentWillMount = async () => {
    console.log(localStorage.getItem("token"));
    this.initState(this.props);
    this.getAllJobList();
  };
  componentWillReceiveProps = (newProps) => {
    this.initState(newProps);
  };
  initState = (props) => {
    this.setState({
      avatar: props?.auth?.avatar !== "" ? props?.auth?.avatar : avatar,
    });
  };
  handleProcess = (param) => {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var hour = date.getHours();
    var min = date.getMinutes();
    if (hour >= 0 && hour < 12) {
      var AP = "AM";
    } else {
      var AP = "PM";
    }
    var nowTime =
      day + "/" + month + "/" + year + " " + hour + ":" + min + " " + AP;
    if (param === "review")
      if (this.state.processReview !== "")
        this.setState({ processReview: "", processTimeReview: "" });
      else this.setState({ processReview: param, processTimeReview: nowTime });
    else if (param === "interview")
      if (this.state.processInterview !== "")
        this.setState({ processInterview: "", processTimeInterview: "" });
      else
        this.setState({
          processInterview: param,
          processTimeInterview: nowTime,
        });
    else if (param === "offer")
      if (this.state.processOffer !== "")
        this.setState({ processOffer: "", processTimeOffer: "" });
      else this.setState({ processOffer: param, processTimeOffer: nowTime });
    else if (param === "hire")
      if (this.state.processHire !== "")
        this.setState({ processHire: "", processTimeHire: "" });
      else
        this.setState({
          processHire: param,
          processTimeHire: nowTime,
          showView: "block",
        });
  };

  changeJobStatus = async () => {
    this.setState({ interviewLoader: true });
    let obj = {
      status: "Interview",
    };
    var Authorization = "Bearer ".concat(window.localStorage.getItem("token"));
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}v1/LHS/job/changeApplicationStatus/${this.props.applicantData._id}`,
      obj,
      { headers: { Authorization: Authorization } }
    );
    if (res) {
      let authKey = "3b150f16cb126d01ff19132bd8b2b56021189cc6";
      var uid = this.props.applicantData.staff;
      var user = new CometChat.User(uid);
      user.setName(this.props.applicantData.name);
      CometChat.login(uid, authKey).then(
        (user) => {
          console.log("Login Successful:", { user });
          let data = {
            accepted: [`${this.props.applicantData.staff}`],
          };
          let headers = {
            apiKey: "52f43f2e72dff3ba8c24f8dc537c1d6993c77394",
            "Content-Type": "application/json",
            Accept: "application/json",
          };
          let resData = axios.post(
            `https://206961e2dbe5aa43.api-us.cometchat.io/v3/users/${this.props.applicantData.hospital}/friends`,
            data,
            { headers: headers }
          );
          if (resData) {
            CometChat.login(this.props.applicantData.hospital, authKey).then(
              (user) => {
                console.log("Login Successful:", { user });
              },
              (error) => {
                console.log("Login failed with exception:", { error });
              }
            );
          }
          this.setState({ interviewLoader: false });
        },
        (error) => {
          console.log("Login failed with exception:", { error });
        }
      );
      /*----------- CometChat SignUp end ----------*/
      this.getAllJobList();
      this.props.handleMember(
        this.props.applicantData.job,
        this.props.applicantData.staff
      );
      this.setState({ interviewLoader: false });
    }
  };

  rejectJobOffer = async () => {
    let obj = {
      rejectionReason: this.state.rejectReason,
    };
    var Authorization = "Bearer ".concat(window.localStorage.getItem("token"));
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}v1/LHS/job/jobApplication/reject/${this.props.applicantData._id}`,
      obj,
      { headers: { Authorization: Authorization } }
    );
    if (res) {
      this.setState({
        showReasonModal: false,
      });
      this.props.handleMember(
        this.props.applicantData.job,
        this.props.applicantData.staff
      );
    }
  };

  jobOfferToUser = async () => {
    this.setState({ confirmHiringLoader: true });
    if (this.state.expStartDate === null) {
      var error = this.state.error;
      error.dateStart = "block";
      this.setState({ error: error, confirmHiringLoader: false });
    } else if (
      this.props.applicantData &&
      this.props.applicantData.natureOfJob &&
      this.state.jobDetails.contractType === "Temporary Position" &&
      this.state.expEndDate === null
    ) {
      var error = this.state.error;
      error.dateEnd = "block";
      this.setState({ error: error, confirmHiringLoader: false });
    } else {
      var date1 = new Date(this.state.expStartDate);
      var date2 = new Date(this.state.expEndDate);
      date1 = `${
        date1.getMonth() + 1
      }/${date1.getDate()}/${date1.getFullYear()}`;
      date2 = `${
        date2.getMonth() + 1
      }/${date2.getDate()}/${date2.getFullYear()}`;
      var dateFind1 = new Date(date1);
      var dateFind2 = new Date(date2);
      var difference_In_Time = dateFind2.getTime() - dateFind1.getTime();
      var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      let obj = {};
      if (this.state.jobDetails.contractType === "Temporary Position") {
        (obj.startDate = date1),
          (obj.endDate = date2),
          (obj.totalDays = difference_In_Days);
      } else {
        (obj.startDate = date1), (obj.endDate = date2), (obj.totalDays = 0);
      }
      var Authorization = "Bearer ".concat(
        window.localStorage.getItem("token")
      );
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/LHS/job/jobApplication/offer/${this.props.applicantData._id}`,
        obj,
        { headers: { Authorization: Authorization } }
      );
      if (res) {
        this.setState({ showView: "none" });
        this.setState({ showReview: false, confirmHiringLoader: false });
        this.props.handleMember(
          this.props.applicantData.job,
          this.props.applicantData.staff
        );
      } else {
        this.setState({ confirmHiringLoader: false });
      }
    }
  };

  showInterest = async () => {
    var Authorization = "Bearer ".concat(window.localStorage.getItem("token"));
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}v1/LHS/hospital/showIntrest?job=${this.state.selectedJobId}&user=${this.props.applicantData._id}`,
      {},
      { headers: { Authorization: Authorization } }
    );
    if (res) {
      this.props.handleMember(
        this.props.applicantData._id,
        this.props.applicantData.staff
      );
      this.setState({
        showInterestModal: false,
      });
    } else {
    }
  };

  getAllJobList = async () => {
    try {
      var Authorization = "Bearer ".concat(
        window.localStorage.getItem("token")
      );
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/LHS/job/getByName`,
        { headers: { Authorization: Authorization } }
      );
      if (res.status === 200) {
        const resData = await axios.get(
          `${process.env.REACT_APP_API_URL}v1/LHS/job/get/${this.props.applicantData.job}`,
          { headers: { Authorization: Authorization } }
        );
        let data = res.data.data.job.map((v) => {
          return {
            name: v.jobTitle,
            id: v._id,
          };
        });
        this.setState({
          allJobIds: data,
          jobDetails: resData.data.data,
        });
      }
    } catch (err) {}
  };

  openReview = () => {
    this.setState({ showView: "block" });
  };
  closeReview = () => {
    this.setState({ showView: "none" });
    this.setState({ showReview: false });
  };
  setExpStartDate = (date) => {
    this.setState({ expStartDate: date });
    if (date === null) {
      var error = this.state.error;
      error.dateStart = "block";
      this.setState({ error: error });
    } else {
      var error = this.state.error;
      error.dateStart = "none";
      this.setState({ error: error });
    }
  };
  setExpEndDate = (date) => {
    console.log(date);
    this.setState({ expEndDate: date });
    if (date === null) {
      var error = this.state.error;
      error.dateEnd = "block";
      this.setState({ error: error });
    } else {
      var error = this.state.error;
      error.dateEnd = "none";
      this.setState({ error: error });
    }
  };
  handleConfirm = () => {};
  handleReview = async () => {
    console.log("in handlereview");
    var userID = this.props.applicantData.staff;
    var Authorization = "Bearer ".concat(window.localStorage.getItem("token"));
    var res = await callApi(
      "GET",
      `v1/LHS/rating/getStaffRating/${userID}`,
      Authorization
    );
    this.setState({ staffRating: res.data });
    this.setState({ showReviewView: "block" });
    this.setState({ showReview: true });
  };

  getFileType = (file) => {
    let type = file.split(".");
    let c = type[type.length - 1];
    return c;
  };

  render() {
    console.log(this.state.jobDetails, "jobDetailsjobDetailsjobDetails");
    const { pageNumber, numPages } = this.state;

    return (
      <div className="applicantMember">
        <div className="staffOne row staffOneBorderNone">
          <div className="col-md-7 col-sm-7 col-7 staffOneData">
            <div className="row">
              <div className="avatar_img col-md-3 col-sm-3 col-4">
                <img
                  width="100%"
                  src={
                    this.props.applicantData.avatar &&
                    this.props.applicantData.avatar !== ""
                      ? this.props.applicantData.avatar
                      : avatar
                  }
                  alt="avatar.png"
                  style={{ borderRadius: "50%" }}
                />
                <img src={badge} alt="badge.png" className="avatar_badge" />
              </div>
              <div className="col-md-4 col-sm-4 col-4 marginLeft">
                <p className="username">{this.props.applicantData.name}</p>
                <p className="nurse">{this.props.applicantData.jobTitle}</p>
                <p
                  className="available"
                  style={{ display: "inline-block", whiteSpace: "nowrap" }}
                >
                  Availability:{" "}
                  {this.props.applicantData.immediatelyStart
                    ? "Immediately"
                    : moment(this.props.applicantData.startWorkDate).format(
                        "MM-DD-YYYY"
                      )}
                </p>
                <div className="position">
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.892 15.598 11.13 14.5 12.5L8 20L1.5 12.5C0.402 11.13 0 9.892 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315ZM11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                      fill="#333333"
                      fill-opacity="0.7"
                    />
                  </svg>
                  <p className="position_text">
                    {this.props.applicantData.currentLocation &&
                      this.props.applicantData.currentLocation.name +
                        ", " +
                        this.props.applicantData.currentLocation.state}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-4 available_BTNs marginLeft">
                <div className="available_BTN">
                  <p className="BTN_text">Weekend availability</p>
                  {this.props.applicantData.weekendAvailiblity ? (
                    <svg
                      className="available_svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7H0ZM6.60053 9.996L10.6307 4.95787L9.90267 4.37547L6.46613 8.66973L4.032 6.6416L3.43467 7.3584L6.60053 9.99693V9.996Z"
                        fill="#6FCF97"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="available_svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.58438 9.65938L8.55313 9.65469L7 7.80313L5.44844 9.65312L4.41563 9.65781C4.34688 9.65781 4.29063 9.60312 4.29063 9.53281C4.29063 9.50312 4.30156 9.475 4.32031 9.45156L6.35313 7.02969L4.32031 4.60938C4.30143 4.58647 4.29096 4.5578 4.29063 4.52812C4.29063 4.45937 4.34688 4.40312 4.41563 4.40312L5.44844 4.40781L7 6.25938L8.55156 4.40938L9.58281 4.40469C9.65156 4.40469 9.70781 4.45937 9.70781 4.52969C9.70781 4.55937 9.69688 4.5875 9.67813 4.61094L7.64844 7.03125L9.67969 9.45312C9.69844 9.47656 9.70938 9.50469 9.70938 9.53438C9.70938 9.60313 9.65313 9.65938 9.58438 9.65938Z"
                        fill="#FF3A44"
                      />
                    </svg>
                  )}
                </div>
                <div className="available_BTN BTN">
                  <p className="BTN_text">Nursing License</p>
                  {this.props.applicantData.nursingLicense &&
                  this.props.applicantData.nursingLicense.length === 0 ? (
                    <svg
                      className="available_svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.58438 9.65938L8.55313 9.65469L7 7.80313L5.44844 9.65312L4.41563 9.65781C4.34688 9.65781 4.29063 9.60312 4.29063 9.53281C4.29063 9.50312 4.30156 9.475 4.32031 9.45156L6.35313 7.02969L4.32031 4.60938C4.30143 4.58647 4.29096 4.5578 4.29063 4.52812C4.29063 4.45937 4.34688 4.40312 4.41563 4.40312L5.44844 4.40781L7 6.25938L8.55156 4.40938L9.58281 4.40469C9.65156 4.40469 9.70781 4.45937 9.70781 4.52969C9.70781 4.55937 9.69688 4.5875 9.67813 4.61094L7.64844 7.03125L9.67969 9.45312C9.69844 9.47656 9.70938 9.50469 9.70938 9.53438C9.70938 9.60313 9.65313 9.65938 9.58438 9.65938Z"
                        fill="#FF3A44"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="available_svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7H0ZM6.60053 9.996L10.6307 4.95787L9.90267 4.37547L6.46613 8.66973L4.032 6.6416L3.43467 7.3584L6.60053 9.99693V9.996Z"
                        fill="#6FCF97"
                      />
                    </svg>
                  )}
                </div>
                <div className="available_BTN BTN">
                  <p className="BTN_text">Liability Insurance</p>
                  {this.props.applicantData.liabilityInsurance &&
                  this.props.applicantData.liabilityInsurance
                    .insuranceProvider ? (
                    <svg
                      className="available_svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7H0ZM6.60053 9.996L10.6307 4.95787L9.90267 4.37547L6.46613 8.66973L4.032 6.6416L3.43467 7.3584L6.60053 9.99693V9.996Z"
                        fill="#6FCF97"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="available_svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.58438 9.65938L8.55313 9.65469L7 7.80313L5.44844 9.65312L4.41563 9.65781C4.34688 9.65781 4.29063 9.60312 4.29063 9.53281C4.29063 9.50312 4.30156 9.475 4.32031 9.45156L6.35313 7.02969L4.32031 4.60938C4.30143 4.58647 4.29096 4.5578 4.29063 4.52812C4.29063 4.45937 4.34688 4.40312 4.41563 4.40312L5.44844 4.40781L7 6.25938L8.55156 4.40938L9.58281 4.40469C9.65156 4.40469 9.70781 4.45937 9.70781 4.52969C9.70781 4.55937 9.69688 4.5875 9.67813 4.61094L7.64844 7.03125L9.67969 9.45312C9.69844 9.47656 9.70938 9.50469 9.70938 9.53438C9.70938 9.60313 9.65313 9.65938 9.58438 9.65938Z"
                        fill="#FF3A44"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3 staffOneData">
            <div className="review_info">
              <div className="middle_line"></div>
              <svg
                width="33"
                height="31.6"
                viewBox="0 0 42 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.0185 13.9063L27.621 12.1045L22.079 0.869159C21.9277 0.561542 21.6786 0.312518 21.371 0.161151C20.5995 -0.219709 19.662 0.0976744 19.2763 0.869159L13.7343 12.1045L1.33684 13.9063C0.995041 13.9551 0.682541 14.1162 0.443283 14.3604C0.154034 14.6577 -0.005356 15.0576 0.000137431 15.4724C0.00563086 15.8871 0.175558 16.2828 0.47258 16.5723L9.44231 25.3174L7.32317 37.666C7.27347 37.9533 7.30526 38.2487 7.41492 38.5188C7.52459 38.7889 7.70774 39.0229 7.94362 39.1942C8.17949 39.3656 8.45865 39.4674 8.74943 39.4881C9.04022 39.5088 9.331 39.4477 9.58879 39.3115L20.6777 33.4815L31.7665 39.3115C32.0693 39.4727 32.4208 39.5264 32.7577 39.4678C33.6073 39.3213 34.1786 38.5156 34.0322 37.666L31.913 25.3174L40.8827 16.5723C41.1269 16.333 41.288 16.0205 41.3368 15.6787C41.4687 14.8242 40.873 14.0332 40.0185 13.9063Z"
                  fill="#73C7A9"
                />
              </svg>
              <p className="marks">
                {this.props.applicantData.ratings &&
                  this.props.applicantData.ratings.ratingSum.toFixed(1)}
              </p>
              <div>
                {" "}
                <p style={{}} className="review">
                  {this.props.applicantData.ratings &&
                    this.props.applicantData.ratings.totalRatings}{" "}
                  Review
                </p>
                {this.props.applicantData.ratings &&
                this.props.applicantData.ratings.totalRatings !== 0 ? (
                  <span
                    className="rating_view_link"
                    // onClick={() => this.openReview(staff)}
                    onClick={this.handleReview}
                  >
                    See all{" "}
                    <img className="ms-2" src={left_arrow} width="7" alt="" />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {console.log(this.props.applicantData, "{this.props.applicantData")}
          </div>
          <div className="col-md-2 col-sm-2 staffOneData">
            <div className="review_info">
              <div>
                {this.props.applicantData.status &&
                  this.props.applicantData.status === "Review" && (
                    <div className="reviewBTN" onClick={this.changeJobStatus}>
                      {" "}
                      {this.state.interviewLoader ? (
                        <Loader />
                      ) : (
                        "Interview"
                      )}{" "}
                    </div>
                  )}
                {this.props.applicantData.status &&
                  this.props.applicantData.status === "Interview" && (
                    <div className="reviewBTN" onClick={this.openReview}>
                      Job Offer
                    </div>
                  )}
                {this.props.applicantData.status &&
                  this.props.applicantData.status === "Completed" && (
                    <div className="reviewBTN">Completed</div>
                  )}
                {this.props.applicantData.status &&
                  this.props.applicantData.status === "Offer Declined" && (
                    <div className="reviewBTN">Offer Declined</div>
                  )}
                {this.props.applicantData.status &&
                  this.props.applicantData.status === "In Progress" && (
                    <div className="reviewBTN">In-Progress</div>
                  )}
                {this.props.applicantData.status &&
                  this.props.applicantData.status === "Job Offered" && (
                    <div className="reviewBTN">Job Offered</div>
                  )}
                {this.props.applicantData.status &&
                  this.props.applicantData.status === "Rejected" && (
                    <div className="reviewBTN">Rejected</div>
                  )}
                <br />
                {this.props.applicantData.status &&
                  this.props.applicantData.status !== "Completed" &&
                  this.props.applicantData.status !== "Job Offered" &&
                  this.props.applicantData.status !== "Offer Declined" &&
                  this.props.applicantData.status !== "In Progress" &&
                  this.props.applicantData.status !== "Rejected" && (
                    <div
                      className="reviewBTN reviewBTN_underline"
                      onClick={() =>
                        this.setState({
                          showReasonModal: true,
                        })
                      }
                    >
                      Rejected
                    </div>
                  )}

                {this.props.applicantData &&
                  (this.state.allJobIds || [])
                    .map((v) => v.id)
                    .sort()
                    .join(",") !==
                    (this.props.applicantData.jobIntrest || [])
                      .sort()
                      .join(",") &&
                  !this.props.applicantData.status && (
                    <div
                      className="reviewBTN"
                      onClick={() => this.setState({ showInterestModal: true })}
                    >
                      Show Interest
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showReasonModal && (
          <AddReasonModal
            userData={this.props.applicantData}
            show={this.state.showReasonModal}
            applicationType=""
            onHide={() => this.setState({ showReasonModal: false })}
            setRejectReason={(e) =>
              this.setState({ rejectReason: e.target.value })
            }
            rejectJobOffer={this.rejectJobOffer}
            rejectReason={this.state.rejectReason}
          />
        )}

        {this.state.showInterestModal ? (
          <div
            className="w3-modal viewModal"
            id="modal3"
            style={{ display: this.state.showInterestModal ? "block" : "none" }}
          >
            <div className="w3-modal-content ssu2_modal2">
              <div className="w3-container">
                <div className="ssu2_modal1_text1">
                  <p className="reviewStaff">{this.props.applicantData.name}</p>
                  <p className="reviewEach">
                    {this.props.applicantData.ratings &&
                      this.props.applicantData.ratings.totalRatings}{" "}
                    Review
                  </p>
                  <p className="reviewMark reviewMarkhidden">
                    {this.props.applicantData.ratings &&
                      this.props.applicantData.ratings.ratingSum.toFixed(1)}
                  </p>
                  <svg
                    className="reviewSvg reviewSvghidden"
                    width="33"
                    height="31.6"
                    viewBox="0 0 42 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M40.0185 13.9063L27.621 12.1045L22.079 0.869159C21.9277 0.561542 21.6786 0.312518 21.371 0.161151C20.5995 -0.219709 19.662 0.0976744 19.2763 0.869159L13.7343 12.1045L1.33684 13.9063C0.995041 13.9551 0.682541 14.1162 0.443283 14.3604C0.154034 14.6577 -0.005356 15.0576 0.000137431 15.4724C0.00563086 15.8871 0.175558 16.2828 0.47258 16.5723L9.44231 25.3174L7.32317 37.666C7.27347 37.9533 7.30526 38.2487 7.41492 38.5188C7.52459 38.7889 7.70774 39.0229 7.94362 39.1942C8.17949 39.3656 8.45865 39.4674 8.74943 39.4881C9.04022 39.5088 9.331 39.4477 9.58879 39.3115L20.6777 33.4815L31.7665 39.3115C32.0693 39.4727 32.4208 39.5264 32.7577 39.4678C33.6073 39.3213 34.1786 38.5156 34.0322 37.666L31.913 25.3174L40.8827 16.5723C41.1269 16.333 41.288 16.0205 41.3368 15.6787C41.4687 14.8242 40.873 14.0332 40.0185 13.9063Z"
                      fill="#73C7A9"
                    />
                  </svg>
                </div>

                <div className="my-5">
                  <p className="modalName modalNameUnder mb-0 mt-5">
                    {" "}
                    Select Job{" "}
                  </p>
                  <select
                    className="job_drodown job_drodown_hospital position-relative"
                    name="option"
                    value={this.state.selectedJobId}
                    onChange={(e) =>
                      this.setState({ selectedJobId: e.target.value })
                    }
                  >
                    <option>Select</option>
                    {this.state.allJobIds
                      .filter(
                        (value) =>
                          !this.props.applicantData.jobIntrest.includes(
                            value.id
                          )
                      )
                      .map((v, i) => {
                        return (
                          <option key={i} value={v.id}>
                            {v.name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="row modalLast">
                  <div className="col-md-3"></div>
                  <div
                    className="col-md-3 modalBTNCHL"
                    onClick={this.showInterest}
                  >
                    Confirm
                  </div>
                  <div
                    className="col-md-3  border-1  !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                    onClick={() => this.setState({ showInterestModal: false })}
                  >
                    Cancel
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.showReview ? (
          <div
            className="w3-modal viewModal"
            id="modal3"
            style={{ display: this.state.showReviewView }}
          >
            <div className="w3-modal-content ssu2_modal2">
              <div className="w3-container">
                <div className="ssu2_modal1_text1">
                  <p className="reviewStaff">{this.props.applicantData.name}</p>
                  <p className="reviewEach">
                    {this.props.applicantData.ratings &&
                      this.props.applicantData.ratings.totalRatings}{" "}
                    Review
                  </p>
                  <p className="reviewMark reviewMarkhidden">
                    {this.props.applicantData.ratings &&
                      this.props.applicantData.ratings.ratingSum.toFixed(1)}
                  </p>
                  <svg
                    className="reviewSvg reviewSvghidden"
                    width="33"
                    height="31.6"
                    viewBox="0 0 42 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M40.0185 13.9063L27.621 12.1045L22.079 0.869159C21.9277 0.561542 21.6786 0.312518 21.371 0.161151C20.5995 -0.219709 19.662 0.0976744 19.2763 0.869159L13.7343 12.1045L1.33684 13.9063C0.995041 13.9551 0.682541 14.1162 0.443283 14.3604C0.154034 14.6577 -0.005356 15.0576 0.000137431 15.4724C0.00563086 15.8871 0.175558 16.2828 0.47258 16.5723L9.44231 25.3174L7.32317 37.666C7.27347 37.9533 7.30526 38.2487 7.41492 38.5188C7.52459 38.7889 7.70774 39.0229 7.94362 39.1942C8.17949 39.3656 8.45865 39.4674 8.74943 39.4881C9.04022 39.5088 9.331 39.4477 9.58879 39.3115L20.6777 33.4815L31.7665 39.3115C32.0693 39.4727 32.4208 39.5264 32.7577 39.4678C33.6073 39.3213 34.1786 38.5156 34.0322 37.666L31.913 25.3174L40.8827 16.5723C41.1269 16.333 41.288 16.0205 41.3368 15.6787C41.4687 14.8242 40.873 14.0332 40.0185 13.9063Z"
                      fill="#73C7A9"
                    />
                  </svg>
                </div>
                <div className="mainView">
                  {this.state.staffRating &&
                    this.state.staffRating.map((staff) => {
                      return (
                        <div className="" key={staff._id}>
                          <div className="ViewOne">
                            <div className="HospitalData">
                              <img
                                width="72px"
                                src={
                                  staff.hospital && staff.hospital.avatar !== ""
                                    ? staff.hospital.avatar
                                    : avatar
                                }
                                alt="avatar"
                                style={{ borderRadius: "50%" }}
                              />
                              <div className="HospitalInfo">
                                <p className="hospitalName">
                                  {staff.hospital &&
                                    staff.hospital.healthCareInstitution.name}
                                </p>
                                {/* <p className="hospitalCity">San Francisco, California</p> */}
                              </div>
                              <div className="HospitalMark">
                                <svg
                                  className="reviewSvg"
                                  width="33"
                                  height="31.6"
                                  viewBox="0 0 42 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M40.0185 13.9063L27.621 12.1045L22.079 0.869159C21.9277 0.561542 21.6786 0.312518 21.371 0.161151C20.5995 -0.219709 19.662 0.0976744 19.2763 0.869159L13.7343 12.1045L1.33684 13.9063C0.995041 13.9551 0.682541 14.1162 0.443283 14.3604C0.154034 14.6577 -0.005356 15.0576 0.000137431 15.4724C0.00563086 15.8871 0.175558 16.2828 0.47258 16.5723L9.44231 25.3174L7.32317 37.666C7.27347 37.9533 7.30526 38.2487 7.41492 38.5188C7.52459 38.7889 7.70774 39.0229 7.94362 39.1942C8.17949 39.3656 8.45865 39.4674 8.74943 39.4881C9.04022 39.5088 9.331 39.4477 9.58879 39.3115L20.6777 33.4815L31.7665 39.3115C32.0693 39.4727 32.4208 39.5264 32.7577 39.4678C33.6073 39.3213 34.1786 38.5156 34.0322 37.666L31.913 25.3174L40.8827 16.5723C41.1269 16.333 41.288 16.0205 41.3368 15.6787C41.4687 14.8242 40.873 14.0332 40.0185 13.9063Z"
                                    fill="#73C7A9"
                                  />
                                </svg>
                                <p className="reviewMark">
                                  {staff.rating && staff.rating}
                                </p>
                              </div>
                            </div>
                            <div className="reviewContent">
                              <p>{staff.review && staff.review}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="row ssu_bottom">
                  <button
                    className="border !border-[#73C7A9] !text-[#73C7A9]  rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                    onClick={this.closeReview}
                  >
                    {" "}
                    CANCEL{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            this.state.processInterview ? "callRoom" : "callRoom display"
          }
        >
          <div className="row callChat">
            <div className="col-md-3 videoCall">VIDEO CALL</div>
            <div className="col-md-3 chat">CHAT</div>
            <div className="col-md-3 voiceCall">VOICE CALL</div>
          </div>
        </div>

        {this.props.applicantData.status === "Interview" ? (
          <div>
            <div className="row callChat justify-content-center mt-3">
              <div
                className="col-md-3 chat"
                onClick={() =>
                  this.props.redirectTo() && this.props.redirectTo()
                }
              >
                CHAT
              </div>
            </div>
          </div>
        ) : null}

        <p className="MSettingTitle">Medical Settings</p>
        <div className="MSettingDiv">
          <div className="care">
            {this.props.applicantData.medicalSettings &&
              this.props.applicantData.medicalSettings}
          </div>
        </div>
        <p className="MSettingTitle">Nursing License</p>
        {this.props.applicantData.nursingLicense &&
          this.props.applicantData.nursingLicense.map((nursing) => {
            return (
              <div className="row nursingDIV">
                <div>
                  <div className="row nursingRightDIV">
                    <div className="col-md-6 col-sm-6 col-6">
                      <img
                        src={nursing.image}
                        className="nurseLicence"
                        alt="NurseLicence"
                      />
                      <p className="nurseNum">Number</p>
                      <p className="nurseNumber">{nursing.number}</p>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p className="nurseNum nurseName">Name</p>
                      <p className="nurseNumber">{nursing.name}</p>
                      <p className="nurseNum nurseDate">Expiration Date</p>
                      <p className="nurseNumber">
                        {nursing.expirationDate &&
                          moment(nursing.expirationDate)
                            .subtract(1, "d")
                            .format("MM-DD-YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <p className="MSettingTitle">Liability insurance</p>
        <div className="row insurance">
          <div className="col-md-6 col-sm-6 col-6">
            <p className="nurseNum nurseName">Insurance provider</p>
            <p className="nurseNumber">
              {this.props.applicantData.liabilityInsurance &&
                this.props.applicantData.liabilityInsurance.insuranceProvider}
            </p>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="nurseNum nurseName">Policy number </p>
            <p className="nurseNumber">
              {this.props.applicantData.liabilityInsurance &&
                this.props.applicantData.liabilityInsurance.policyNumber}
            </p>
          </div>
        </div>
        <p className="MSettingTitle">Certifications</p>
        {this.props.applicantData.certifications &&
          this.props.applicantData.certifications.map((certification) => {
            return (
              <div className="row nursingDIV">
                <div
                // className="col-md-6 col-sm-12 col-12 padNoneLeft"
                >
                  <div className="row nursingRightDIV">
                    <div className="col-md-8 col-sm-8 col-8">
                      <p className="nurseNum nurseName">Certification</p>
                      <p className="nurseNumber">{certification.name}</p>
                      <p className="nurseNum nurseDate">Certifying Authority</p>
                      <p className="nurseNumber">
                        {certification.certifyingAuthority}
                      </p>
                    </div>
                    <div className="col-md-4 col-sm-4 col-4">
                      <p className="nurseNum nurseName">Received Date</p>
                      <p className="nurseNumber">
                        {certification.receivedOn &&
                          moment(certification.receivedOn)
                            .subtract(1, "d")
                            .format("MM-DD-YYYY")}
                      </p>
                      <p className="nurseNum nurseDate">Expiration Date</p>
                      <p className="nurseNumber">
                        {certification.expirationDate &&
                          moment(certification.expirationDate)
                            .subtract(1, "d")
                            .format("MM-DD-YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <p className="MSettingTitle">Education Level</p>
        <div className="row insurance">
          <div className="col-md-5 col-sm-5 col-5">
            <p className="nurseNum nurseName">Degree</p>
            <p className="nurseNumber">
              {this.props.applicantData.education &&
                this.props.applicantData.education.degree}
            </p>
          </div>
          <div className="col-md-5 col-sm-5 col-5">
            <p className="nurseNum nurseName">College/University</p>
            <p className="nurseNumber">
              {this.props.applicantData.education &&
                this.props.applicantData.education.college}
            </p>
          </div>
          <div className="col-md-2 col-sm-2 col-2">
            <p className="nurseNum nurseName">Received Date</p>
            <p className="nurseNumber">
              {this.props.applicantData.education &&
                this.props.applicantData.education.receivedOn &&
                moment(this.props.applicantData.education.receivedOn)
                  .subtract(1, "d")
                  .format("MM-DD-YYYY")}
            </p>
          </div>
        </div>
        <p className="MSettingTitle">Available for</p>
        {/* {this.props.applicantData.natureOfJob && this.props.applicantData.natureOfJob.map( (job) => { return ( */}
        <div className="MSettingDiv">
          <div className="care">
            {this.props.applicantData.natureOfJob &&
              this.props.applicantData.natureOfJob[0]}
          </div>
          {this.props.applicantData.natureOfJob &&
          this.props.applicantData.natureOfJob.length === 2 ? (
            <div className="care">
              {this.props.applicantData.natureOfJob &&
                this.props.applicantData.natureOfJob[1]}
            </div>
          ) : null}
        </div>
        {/* // ) } )} */}
        <p className="MSettingTitle">
          Available for Part Time or Full time Work Schedule
        </p>
        <div className="MSettingDiv">
          <div className="care">
            {this.props.applicantData.workSchedule &&
              this.props.applicantData.workSchedule[0]}
          </div>
          {this.props.applicantData.workSchedule &&
          this.props.applicantData.workSchedule.length === 2 ? (
            <div className="care">
              {this.props.applicantData.workSchedule &&
                this.props.applicantData.workSchedule[1]}
            </div>
          ) : null}
        </div>
        <p className="MSettingTitle">Available for Other Location</p>
        {this.props.applicantData.otherCities &&
          this.props.applicantData.otherCities.map((index) => {
            return (
              <div className="MSettingDiv">
                <div className="care">
                  <svg
                    width="13"
                    height="18"
                    style={{ marginTop: -3 + "px" }}
                    viewBox="0 0 28 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.80761 4.80761L4.10051 4.10051L4.80761 4.80761ZM23.1924 4.80761L23.8995 4.10051L23.1924 4.80761ZM24.5625 21.3125L25.3182 21.9674C25.3266 21.9577 25.3348 21.9479 25.3428 21.9379L24.5625 21.3125ZM14 33.5L13.2443 34.1549C13.4343 34.3741 13.71 34.5 14 34.5C14.29 34.5 14.5657 34.3741 14.7557 34.1549L14 33.5ZM3.4375 21.3125L2.65719 21.9379C2.66521 21.9479 2.67341 21.9577 2.68181 21.9674L3.4375 21.3125ZM14 0C10.287 0 6.72601 1.475 4.10051 4.10051L5.51472 5.51472C7.76516 3.26428 10.8174 2 14 2V0ZM23.8995 4.10051C21.274 1.475 17.713 0 14 0V2C17.1826 2 20.2348 3.26428 22.4853 5.51472L23.8995 4.10051ZM28 14C28 10.287 26.525 6.72601 23.8995 4.10051L22.4853 5.51472C24.7357 7.76516 26 10.8174 26 14H28ZM25.3428 21.9379C26.2927 20.7527 26.9683 19.59 27.3996 18.289C27.8293 16.9924 28 15.6079 28 14H26C26 15.4666 25.8441 16.6252 25.5011 17.6597C25.1597 18.6898 24.6166 19.646 23.7822 20.6871L25.3428 21.9379ZM14.7557 34.1549L25.3182 21.9674L23.8068 20.6576L13.2443 32.8451L14.7557 34.1549ZM2.68181 21.9674L13.2443 34.1549L14.7557 32.8451L4.19319 20.6576L2.68181 21.9674ZM0 14C0 15.6079 0.170675 16.9924 0.600445 18.289C1.03169 19.59 1.70731 20.7527 2.65719 21.9379L4.21781 20.6871C3.38344 19.646 2.84031 18.6898 2.49887 17.6597C2.15595 16.6252 2 15.4666 2 14H0ZM4.10051 4.10051C1.475 6.72601 0 10.287 0 14H2C2 10.8174 3.26428 7.76516 5.51472 5.51472L4.10051 4.10051ZM14 19.875C17.2447 19.875 19.875 17.2447 19.875 14H17.875C17.875 16.1401 16.1401 17.875 14 17.875V19.875ZM8.125 14C8.125 17.2447 10.7553 19.875 14 19.875V17.875C11.8599 17.875 10.125 16.1401 10.125 14H8.125ZM14 8.125C10.7553 8.125 8.125 10.7553 8.125 14H10.125C10.125 11.8599 11.8599 10.125 14 10.125V8.125ZM19.875 14C19.875 10.7553 17.2447 8.125 14 8.125V10.125C16.1401 10.125 17.875 11.8599 17.875 14H19.875Z"
                      fill="#333333"
                    />
                  </svg>{" "}
                  &nbsp;
                  {index.name}
                </div>
              </div>
            );
          })}

        <p className="MSettingTitle">Resume</p>

        {this.props.applicantData.resume &&
        this.props.applicantData.resume.link ? (
          <div className="row MSettingDiv">
            <div
              className="col-md-8 col-sm-9 col-9"
              style={{ color: "#73C7A9" }}
            >
              {this.props.applicantData.name + "   Resume"}
            </div>
            <div className="col-md-4 d-flex col-sm-3 col-3">
              <button
                className="viewResume"
                onClick={() => {
                  this.setState({ showPdf: "block" });
                }}
              >
                View Resume{" "}
              </button>

              <button
                className="viewResume ml-3"
                onClick={() => {
                  window.open(this.props.applicantData.resume.link);
                }}
              >
                Download Resume{" "}
              </button>
            </div>
          </div>
        ) : (
          <p>No resume available</p>
        )}

        <div
          className="w3-modal viewModal sendReview"
          id="modal3"
          style={{ display: this.state.showPdf }}
        >
          <div
            className="w3-modal-content ssu2_modal2"
            style={{ width: "690px !important" }}
          >
            <div className="w3-container">
              {console.log(this.props.applicantData.resume)}
              {this.props.applicantData && this.props.applicantData.resume ? (
                this.getFileType(this.props.applicantData.resume.link) ===
                "pdf" ? (
                  <div>
                    <Document
                      file={this.props.applicantData.resume.link}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                      {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page) => (
                          <Page pageNumber={page} />
                        ))}
                    </Document>
                    {/* <p>
                                            Page {pageNumber} of {numPages}
                                        </p> */}
                  </div>
                ) : (
                  <ViewDoc
                    link={
                      this.props.applicantData.resume &&
                      this.props.applicantData.resume.link
                    }
                  />
                )
              ) : null}

              <div className="row modalLast">
                <div className="col-md-4"></div>
                <div
                  className="col-md-4 modalBTNCHR"
                  onClick={() => {
                    this.setState({
                      showPdf: "none",
                    });
                  }}
                >
                  Close
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w3-modal viewModal sendReview"
          id="modal3"
          style={{ display: this.state.showView }}
        >
          <div className="w3-modal-content ssu2_modal2">
            <div className="w3-container">
              <div className="staffOne row modalStaffOne">
                <div className="col-md-9 col-sm-9 col-9 staffOneData">
                  <div className="row">
                    <div className="avatar_img col-md-3 col-sm-3 col-4 modalAvatar">
                      <img
                        width="100%"
                        src={
                          this.props.applicantData.avatar &&
                          this.props.applicantData.avatar !== ""
                            ? this.props.applicantData.avatar
                            : avatar
                        }
                        alt="avatar.png"
                        style={{ borderRadius: "50%" }}
                      />
                      <img
                        src={badge}
                        alt="badge.png"
                        className="avatar_badge"
                      />
                    </div>
                    <div className="col-md-4 col-sm-4 col-4 marginLeft">
                      {console.log(
                        this.state.staffRating,
                        "staffRatingstaffRating"
                      )}
                      <p className="username modalName">
                        {this.props.applicantData.name}
                      </p>
                      <p className="nurse modalNurse">
                        {this.props.applicantData.jobTitle}
                      </p>
                      <p className="available modalAvailable">
                        Availability:{" "}
                        {this.props.applicantData.immediatelyStart
                          ? "Immediately"
                          : moment(
                              this.props.applicantData.startWorkDate
                            ).format("MM-DD-YYYY")}
                      </p>
                      <div className="position">
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 16 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.892 15.598 11.13 14.5 12.5L8 20L1.5 12.5C0.402 11.13 0 9.892 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315ZM11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                            fill="#333333"
                            fill-opacity="0.7"
                          />
                        </svg>
                        <p className="position_text modalText">
                          {this.props.applicantData.currentLocation &&
                            this.props.applicantData.currentLocation.name +
                              ", " +
                              this.props.applicantData.currentLocation.state}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-4 available_BTNs">
                      <div className="available_BTN">
                        <p className="BTN_text">Weekend availability</p>
                        {this.props.applicantData.weekendAvailiblity ? (
                          <svg
                            className="available_svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7H0ZM6.60053 9.996L10.6307 4.95787L9.90267 4.37547L6.46613 8.66973L4.032 6.6416L3.43467 7.3584L6.60053 9.99693V9.996Z"
                              fill="#6FCF97"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="available_svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.58438 9.65938L8.55313 9.65469L7 7.80313L5.44844 9.65312L4.41563 9.65781C4.34688 9.65781 4.29063 9.60312 4.29063 9.53281C4.29063 9.50312 4.30156 9.475 4.32031 9.45156L6.35313 7.02969L4.32031 4.60938C4.30143 4.58647 4.29096 4.5578 4.29063 4.52812C4.29063 4.45937 4.34688 4.40312 4.41563 4.40312L5.44844 4.40781L7 6.25938L8.55156 4.40938L9.58281 4.40469C9.65156 4.40469 9.70781 4.45937 9.70781 4.52969C9.70781 4.55937 9.69688 4.5875 9.67813 4.61094L7.64844 7.03125L9.67969 9.45312C9.69844 9.47656 9.70938 9.50469 9.70938 9.53438C9.70938 9.60313 9.65313 9.65938 9.58438 9.65938Z"
                              fill="#FF3A44"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="available_BTN BTN">
                        <p className="BTN_text">Nursing License</p>
                        {this.props.applicantData.nursingLicense &&
                        this.props.applicantData.nursingLicense.length === 0 ? (
                          <svg
                            className="available_svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.58438 9.65938L8.55313 9.65469L7 7.80313L5.44844 9.65312L4.41563 9.65781C4.34688 9.65781 4.29063 9.60312 4.29063 9.53281C4.29063 9.50312 4.30156 9.475 4.32031 9.45156L6.35313 7.02969L4.32031 4.60938C4.30143 4.58647 4.29096 4.5578 4.29063 4.52812C4.29063 4.45937 4.34688 4.40312 4.41563 4.40312L5.44844 4.40781L7 6.25938L8.55156 4.40938L9.58281 4.40469C9.65156 4.40469 9.70781 4.45937 9.70781 4.52969C9.70781 4.55937 9.69688 4.5875 9.67813 4.61094L7.64844 7.03125L9.67969 9.45312C9.69844 9.47656 9.70938 9.50469 9.70938 9.53438C9.70938 9.60313 9.65313 9.65938 9.58438 9.65938Z"
                              fill="#FF3A44"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="available_svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7H0ZM6.60053 9.996L10.6307 4.95787L9.90267 4.37547L6.46613 8.66973L4.032 6.6416L3.43467 7.3584L6.60053 9.99693V9.996Z"
                              fill="#6FCF97"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="available_BTN BTN">
                        <p className="BTN_text">Liability Insurance</p>
                        {this.props.applicantData.liabilityInsurance &&
                        this.props.applicantData.liabilityInsurance
                          .insuranceProvider ? (
                          <svg
                            className="available_svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7H0ZM6.60053 9.996L10.6307 4.95787L9.90267 4.37547L6.46613 8.66973L4.032 6.6416L3.43467 7.3584L6.60053 9.99693V9.996Z"
                              fill="#6FCF97"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="available_svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.58438 9.65938L8.55313 9.65469L7 7.80313L5.44844 9.65312L4.41563 9.65781C4.34688 9.65781 4.29063 9.60312 4.29063 9.53281C4.29063 9.50312 4.30156 9.475 4.32031 9.45156L6.35313 7.02969L4.32031 4.60938C4.30143 4.58647 4.29096 4.5578 4.29063 4.52812C4.29063 4.45937 4.34688 4.40312 4.41563 4.40312L5.44844 4.40781L7 6.25938L8.55156 4.40938L9.58281 4.40469C9.65156 4.40469 9.70781 4.45937 9.70781 4.52969C9.70781 4.55937 9.69688 4.5875 9.67813 4.61094L7.64844 7.03125L9.67969 9.45312C9.69844 9.47656 9.70938 9.50469 9.70938 9.53438C9.70938 9.60313 9.65313 9.65938 9.58438 9.65938Z"
                              fill="#FF3A44"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 staffOneData">
                  <div className="review_info">
                    <div className="middle_line modal_middle_line"></div>
                    <div className="modalRating">
                      <div className="modalRatingMark">
                        <svg
                          className="modalMarkSVG"
                          width="33"
                          height="31.6"
                          viewBox="0 0 42 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M40.0185 13.9063L27.621 12.1045L22.079 0.869159C21.9277 0.561542 21.6786 0.312518 21.371 0.161151C20.5995 -0.219709 19.662 0.0976744 19.2763 0.869159L13.7343 12.1045L1.33684 13.9063C0.995041 13.9551 0.682541 14.1162 0.443283 14.3604C0.154034 14.6577 -0.005356 15.0576 0.000137431 15.4724C0.00563086 15.8871 0.175558 16.2828 0.47258 16.5723L9.44231 25.3174L7.32317 37.666C7.27347 37.9533 7.30526 38.2487 7.41492 38.5188C7.52459 38.7889 7.70774 39.0229 7.94362 39.1942C8.17949 39.3656 8.45865 39.4674 8.74943 39.4881C9.04022 39.5088 9.331 39.4477 9.58879 39.3115L20.6777 33.4815L31.7665 39.3115C32.0693 39.4727 32.4208 39.5264 32.7577 39.4678C33.6073 39.3213 34.1786 38.5156 34.0322 37.666L31.913 25.3174L40.8827 16.5723C41.1269 16.333 41.288 16.0205 41.3368 15.6787C41.4687 14.8242 40.873 14.0332 40.0185 13.9063Z"
                            fill="#73C7A9"
                          />
                        </svg>
                        <p className="marks modalMarks">
                          {this.props.applicantData.ratings &&
                            this.props.applicantData.ratings.ratingSum.toFixed(
                              1
                            )}
                        </p>
                      </div>
                      <p className="review modalTotalMarks">
                        {this.props.applicantData.ratings &&
                          this.props.applicantData.ratings.totalRatings}{" "}
                        Review
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sendMain">
                <div className="ViewOne reviewDiv modalView">
                  <p className="modalName modalNameUnder"> Hiring Timeline </p>

                  <div className="row">
                    <div
                      className="col-md-6 modalCalLeft MLDIV"
                      style={{ paddingLeft: "0px" }}
                    >
                      <div className="ssu2_modal1_input ssu2_modal3_selectBox modalCalLeft">
                        <div
                          className="input_left_icon"
                          onClick={() => $(".startDate").focus()}
                        >
                          <img
                            width="22px"
                            height="17px"
                            alt="img"
                            src={calendar}
                          />
                        </div>
                        <DatePicker
                          className="ssu2_modal3_date startDate"
                          selected={this.state.expStartDate}
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          onChange={(date) => this.setExpStartDate(date)}
                          placeholderText={"Start Date"}
                          minDate={new Date()}
                        />
                      </div>
                      <ErrorState
                        show={this.state.error.dateStart}
                        name="Start Date is required."
                      />
                    </div>
                    {this.props.applicantData &&
                      this.props.applicantData.natureOfJob &&
                      this.state.jobDetails.contractType ===
                        "Temporary Position" && (
                        <div
                          className="col-md-6 modalCalLeft MRDIV"
                          style={{ paddingRight: "0px" }}
                        >
                          <div className="ssu2_modal1_input ssu2_modal3_selectBox modalCalLeft">
                            <div
                              className="input_left_icon"
                              onClick={() => $(".endDate").focus()}
                            >
                              <img
                                width="22px"
                                height="17px"
                                alt="img"
                                src={calendar}
                              />
                            </div>
                            <DatePicker
                              className="ssu2_modal3_date endDate"
                              selected={this.state.expEndDate}
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              onChange={(date) => this.setExpEndDate(date)}
                              placeholderText={"End Date"}
                              minDate={new Date(this.state.expStartDate)}
                            />
                          </div>
                          <ErrorState
                            show={this.state.error.dateEnd}
                            name="End Date is required."
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row modalLast">
                <div className="col-md-3"></div>
                <div
                  className="col-md-3 modalBTNCHL"
                  onClick={this.jobOfferToUser}
                >
                  {this.state.confirmHiringLoader ? (
                    <Loader />
                  ) : (
                    "Confirm Hiring"
                  )}
                </div>
                <div
                  className="col-md-3  border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                  onClick={this.closeReview}
                >
                  Cancel
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicantMember;
