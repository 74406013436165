import React, { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import key from "../../assets/images/key.svg";
import $ from "jquery";
import { removeSession, callApi } from "../../../redux/action/action";
import ErrorState from "../staffSignUp/components/errorState";
import Loader from "../../modals/Loader";
import classNames from "classnames";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";

function ChangePassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({
    password: "none",
    confirmPassword: "none",
    otp: "none",
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState(
    "Password is required"
  );
  const [passwordError, setPasswordError] = useState("Password is required");
  const [otpError, setOtpError] = useState("OTP is required");
  const [showConfirmPwdBtn, setShowConfirmPwdBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const togglePasswordVisibility = (id) => {
    let val = $("#" + id);
    if (val.attr("type") === "password") {
      val.attr("type", "text");
      $("#c_" + id).hide();
      $("#o_" + id).show();
    } else {
      val.attr("type", "password");
      $("#o_" + id).hide();
      $("#c_" + id).show();
    }
  };

  const validatePassword = (value) => {
    var password = value;
    var error = errors;
    // Do not show anything when the length of password is zero.
    if (password.length === 0) {
      setPasswordError("Password is required.");
      error.password = "block";
      setErrors({ ...errors });
      return;
    } else {
      if (password.length < 6) {
        setPasswordError(
          "Password should have a minimum of 6 characters, including 1 uppercase, 1 lowercase, 1 special character, and 1 number."
        );
        error.password = "block";
        setErrors({ ...errors });
      } else {
        // Create an array and push all possible values that you want in the password
        var matched = [];
        matched.push("[$@$!%*#?&]"); // Special Character
        matched.push("[A-Z]"); // Uppercase Alphabets
        matched.push("[0-9]"); // Numbers
        matched.push("[a-z]"); // Lowercase Alphabets

        // Check the conditions
        var ctr = 0;
        for (var i = 0; i < matched.length; i++) {
          if (new RegExp(matched[i]).test(password)) {
            ctr++;
          }
        }
        // Display it
        switch (ctr) {
          case 0:
          case 1:
          case 2:
          case 3:
            setPasswordError(
              "Password should have a minimum of 6 characters, including 1 uppercase, 1 lowercase, 1 special character, and 1 number."
            );
            error.password = "block";
            setErrors({ ...errors });
            break;
          case 4:
            error.password = "none";
            setErrors({ ...errors });
            break;
          default:
            return;
        }
      }
      if (confirmPassword !== "") {
        var isMatching = confirmPassword === value;
        error = errors;
        if (isMatching) {
          error.confirmPassword = "none";
          setErrors({ ...errors });
        } else {
          error.confirmPassword = "block";
          setErrors({ ...errors });
        }
      }
    }
  };

  const validateConfirmPassword = (value) => {
    var isValid = password === value;
    setConfirmPassword(value);
    var error = errors;
    if (value !== "" && isValid) {
      error.confirmPassword = "none";
      setErrors({ ...errors });
    } else if (value === "" && password === "") {
      error.confirmPassword = "none";
      setErrors({ ...errors });
    } else {
      error.confirmPassword = "block";
      setErrors({ ...errors });
    }
  };

  const validateOtp = (value) => {
    setOtp(value);
    var error = errors;
    if (value !== "") {
      error.otp = "none";
      setErrors({ ...errors });
    } else {
      error.otp = "block";
      setErrors({ ...errors });
    }
  };

  const savePassword = async () => {
    var passwordErrorState = errors.password;
    var confirmPasswordErrorState = errors.confirmPassword;
    var otpErrorState = errors.otp;
    if (passwordErrorState === "") {
      setPasswordError("Password is required");
      setShowLoader(false);
    }
    var isValid = password === confirmPassword;
    if (!isValid && passwordErrorState !== "")
      confirmPasswordErrorState = "block";
    var state = errors;
    var passwordErrorState =
      passwordErrorState === "block" || password === "" ? "block" : "none";
    var confirmPasswordErrorState =
      confirmPasswordErrorState === "block" || confirmPassword === ""
        ? "block"
        : "none";
    var otpErrorState =
      otpErrorState === "block" || otp === "" ? "block" : "none";
    var updatedErrors = {
      password: passwordErrorState,
      confirmPassword: confirmPasswordErrorState,
      otp: otpErrorState,
    };
    setErrors({ ...updatedErrors });
    if (
      password !== "" &&
      confirmPassword !== "" &&
      otp !== "" &&
      passwordErrorState === "none" &&
      otpErrorState === "none" &&
      confirmPasswordErrorState === "none" &&
      isValid
    ) {
      setShowLoader(true);
      var data = {
        password: password,
        otp: otp,
        userId: props.userId,
        role: props.role,
      };
      var res = await callApi("POST", "v1/LHS/general/verify", null, data);
      if (
        res.Message === "Password Changed Successfully..." ||
        res.status === 200
      ) {
        setConfirmPassword("");
        setPassword("");
        setOtp("");
        setShowLoader(false);
        removeSession();
        showSuccessToast("Password updated");
        sendOtp();
      } else {
        showErrorToast("Failed to Change Password.");
        setShowLoader(false);
      }
    }
  };

  const sendOtp = async () => {
    props.history.push("/login");
  };

  return (
    <div className={classNames({ t2_sp_work: !props?.userId }, "mt-3")}>
      {/* <div className="t2_sp_work_title mt-4">Change Password</div> */}
      <div
        className={classNames({
          "row t2_sp_work_container": !props?.userId,
        })}
      >
        <div
          className="ssu2_modal1_input"
          style={{ maxWidth: 600, marginTop: 30 }}
        >
          <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <div className="input_left_icon">
              <img width="18px" height="15px" alt="img" src={key} />
            </div>
            <input
              className="ssu3_password"
              id="psw"
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") savePassword();
              }}
            />
            <div
              className="ssu3_eye"
              onMouseDown={() => togglePasswordVisibility("psw")}
              onMouseUp={() => togglePasswordVisibility("psw")}
              onTouchStart={() => togglePasswordVisibility("psw")}
              onTouchEnd={() => togglePasswordVisibility("psw")}
            >
              <VisibilityOffIcon style={{ display: "block" }} id="c_psw" />
              <VisibilityIcon style={{ display: "none" }} id="o_psw" />
            </div>
          </div>
          <ErrorState name={passwordError} show={errors.password} />
        </div>
        <div
          className="ssu2_modal1_input"
          style={{ maxWidth: 600, marginTop: 0 }}
        >
          <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <div className="input_left_icon">
              <img height="15px" width="18px" alt="img" src={key} />
            </div>
            <input
              className="ssu3_password"
              id="rpsw"
              placeholder="Confirm New password"
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                validateConfirmPassword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") savePassword();
              }}
            />
            <div
              className="ssu3_eye"
              onMouseDown={() => togglePasswordVisibility("rpsw")}
              onTouchStart={() => togglePasswordVisibility("rpsw")}
              onMouseUp={() => togglePasswordVisibility("rpsw")}
              onTouchEnd={() => togglePasswordVisibility("rpsw")}
            >
              <VisibilityOffIcon id="c_rpsw" style={{ display: "block" }} />
              <VisibilityIcon id="o_rpsw" style={{ display: "none" }} />
            </div>
          </div>
          <ErrorState
            name="Doesn't match with the password."
            show={errors.confirmPassword}
          />
        </div>

        <div
          className="ssu2_modal1_input"
          style={{ maxWidth: 600, marginTop: 0 }}
        >
          <div className="ssu2_modal1_input ssu2_modal3_selectBox">
            <div className="input_left_icon">
              <img width="18px" height="15px" alt="img" src={key} />
            </div>
            <input
              className="ssu3_password"
              id="cpsw"
              placeholder="Enter OTP"
              type="text"
              value={otp}
              onChange={(e) => {
                validateOtp(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") savePassword();
              }}
            />
          </div>
          <ErrorState name={otpError} show={errors.otp} />
        </div>

        <div
          style={{ width: "100%" }}
          className="ssu2_modal1_input d-flex justify-content-between align-items-center"
        >
          <p className="text-center" style={{ fontSize: "15px" }}>
            OTP is valid for only 10 minutes.
          </p>
          <div className="ssu_button w-full" onClick={savePassword}>
            {showLoader ? <Loader /> : "CHANGE PASSWORD"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
