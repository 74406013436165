import React, { useState, useEffect } from "react";
import Pagination from "./pagination";
import Loader from "../../modals/Loader";
import "../theme2.css";
import { axiosInstance } from "../../../config/axiosConfig";
import { setSession } from "../../../redux/action/action";

const PaidPayment = () => {
  const [getStaffList, setgetStaffList] = useState([]);
  const [type, setType] = useState("");
  const [showMainLoader, setshowMainLoader] = useState(false);
  const [showPayButton, setshowPayButton] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setshowMainLoader(true);

    const getStaffByQuery = async (skip = 0) => {
      var res = await axiosInstance.get(
        `v1/LHS/payment/getList?skip=${skip}&type=paid`
      );
      if (res.data) {
        if (type === "superAdmin") {
          setSession(res.token, res.data._id, "superAdmin");
          setshowMainLoader(false);
          setshowPayButton(true);
        } else {
          setSession(res.token, res.data._id, "admin");
          setshowMainLoader(false);
        }
        var totalCount = res.data.count;
        var page = Math.ceil(totalCount / 10);
        setgetStaffList(res.data.result);
        setTotalPage(page);
      } else {
        setgetStaffList([]);
        setshowMainLoader(false);
      }
    };

    getStaffByQuery();
  }, [skip]);

  const setcurPage = async (num) => {
    const value = num * 10 - 10;
    await setCurPage(num);
    await setSkip(value);
  };
  return (
    <div className="theme2_body" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <div className="t2_sp_work" style={{ marginTop: 0 }}>
        <div className="row"></div>
        {showMainLoader ? (
          <Loader />
        ) : (
          <div
            className="row hide_scrollbar"
            style={{
              overflowX: "auto",
              marginTop: "32px",
              display: getStaffList.length ? "block" : "none",
            }}
          >
            <table
              className="admin_table"
              style={{
                width: "99%",
                minWidth: "800px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              <thead>
                <tr className="nurseHead">
                  <th
                    className="t2_sp_licence_th"
                    style={{ paddingLeft: "30px" }}
                  >
                    Staff Name{" "}
                  </th>
                  <th className="t2_sp_licence_th">Amount </th>
                  {
                    <th className="t2_sp_licence_th" style={{ width: "80px" }}>
                      {" "}
                      Pay{" "}
                    </th>
                  }
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 20 }} />
                {getStaffList.map((item) => {
                  return (
                    <React.Fragment key={item._id}>
                      <tr className="admin_mngStaff_row">
                        <td
                          className="admin_mngStaff_td"
                          style={{ paddingLeft: "30px" }}
                        >
                          {" "}
                          {item.staff && item.staff.name}{" "}
                        </td>
                        <td className="admin_mngStaff_td">
                          {" "}
                          {`$ ${item.shiftCost}`}{" "}
                        </td>
                        {
                          <td
                            className="admin_mngStaff_td"
                            style={{ color: "green" }}
                          >
                            {"Completed"}
                          </td>
                        }
                      </tr>
                      <tr style={{ height: 24 }}></tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {!getStaffList.length && !showMainLoader ? (
          <p className="text-center my-5">No record found</p>
        ) : null}

        {getStaffList.length ? (
          <Pagination
            curPage={curPage}
            totalPage={totalPage}
            setCurPage={setcurPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PaidPayment;
