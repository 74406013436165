import React, { useState, useEffect } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import "./fileBrowser.css";
import $ from "jquery";

function FileBrowser(props) {
  const [file, setFile] = useState(false);

  useEffect(() => {
    setFile(props.file);
  }, [props.file]);

  useEffect(() => {
    if (!props.delFile) {
      setFile(props.file);
    }
  }, [props.delFile]);

  const dragOverHandler = (e) => {
    e.preventDefault();
    $(e.target).addClass("active");
    $("#" + props.prefix + "dragText").text("Release to Upload File");
  };

  const dragLeaveHandler = (e) => {
    $(e.target).removeClass("active");
    $("#" + props.prefix + "dragText").text("Drag & Drop to Upload File");
  };

  const dropHandler = (e) => {
    fileHandler(e.dataTransfer.files[0]);
  };

  const fChangeHandler = (e) => {
    fileHandler(e.target.files[0]);
  };

  const fileHandler = (file) => {
    if (props.validateImage) {
      if (file) validateImage(file);
      else {
        setFile(false);
        props.setFile(false);
        $("#" + props.prefix + "dropArea").removeClass("active");
        props.setDelFile(false);
      }
    } else {
      if (file) {
        validateDoc(file);
      } else {
        setFile(false);
        props.setFile(false);
        $("#" + props.prefix + "dropArea").removeClass("active");
        props.setDelFile(false);
      }
    }
  };

  const clickInput = () => {
    $("#" + props.prefix + "input").click();
  };

  const deleteFile = () => {
    $("#" + props.prefix + "dropArea").removeClass("active");
    $("#" + props.prefix + "input").val(null);
    setFile(false);
    props.setFile(false);
    props.setDelFile(true);
  };

  const uploadFile = () => {
    $("#" + props.prefix + "dropArea").removeClass("active");
    $("#" + props.prefix + "input").val(null);
    setFile(false);
    props.setFile();
  };

  const validateImage = (file) => {
    let fileType = file.type;
    let validExtensions = ["image/jpeg", "image/jpg", "image/png"];
    if (validExtensions.includes(fileType)) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        let fileURL = fileReader.result;
        props.setImgUrl(fileURL);
      };
      fileReader.readAsDataURL(file);
      props.hideFileErr();
      setFile(file);
      props.setFile(file);
      $("#" + props.prefix + "dropArea").addClass("active");
      props.setDelFile(true);
    } else {
      props.showFileErr();
      setFile(false);
      props.setFile(false);
      $("#" + props.prefix + "dropArea").remove("active");
      props.setDelFile(false);
    }
  };

  const validateDoc = (file) => {
    let fileType = file.type;
    let validExtensions = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    if (validExtensions.includes(fileType)) {
      props.hideFileErr();
      setFile(file);
      props.setFile(file);
      $("#" + props.prefix + "dropArea").addClass("active");
      props.setDelFile(true);
    } else {
      props.showFileErr();
      setFile(false);
      props.setFile(false);
      $("#" + props.prefix + "dropArea").remove("active");
      props.setDelFile(false);
    }
  };

  return (
    <div>
      <div id={props.prefix + "dropArea"} className="drag-area relative">
        <p
          style={{
            fontSize: "16px",
            color: "#73C7A9",
            display: (file || props.fname) && !uploadFile() ? "block" : "none",
          }}
        >
          Selected file: {file ? file.name : props.fname ? props.fname : null}
          <span
            style={{ marginLeft: "18px", cursor: "pointer" }}
            onClick={deleteFile}
          >
            <DeleteIcon style={{ color: "#73C7A9" }} />
          </span>
        </p>
        <div className="row">
          <button id={props.prefix + "button"} onClick={clickInput}>
            <span>
              <CloudUploadIcon style={{ marginRight: "12px" }} />
            </span>
            UPLOAD YOUR {props.title}
          </button>
        </div>
        <input
          className="absolute top-2 left-44 z-[-1]"
          id={props?.prefix + "input"}
          type="file"
          value={!file ? "" : file}
          customhidden
          onChange={fChangeHandler}
          name="file"
          accept={props?.accept && props?.accept}
        />
      </div>
    </div>
  );
}

export default FileBrowser;
