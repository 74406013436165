import React, { useEffect, useState, useRef } from "react";
import chatSend from "../../assets/images/ChatSendIcon.svg";
import videoupload from "../../assets/images/videoUpload.svg";
import fileChat from "../../assets/images/attachment-chat.svg";
import Nav from "../Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import { useHistory } from "react-router-dom";
import VectorPdf from "../../assets/images/Vector-pdf.svg";

import Modal from "../../common/modal";
import RightSideBar from "../../containers/commonComponent/rightSideBar";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";
import backBtn from "../../assets/images/leftArrow.png";
import { Link } from "react-router-dom";
import Loader from "../../modals/Loader";
import {
  chatMessageService,
  initializeChatUser,
} from "../../../sockets/socketService";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { formatMessageDate } from "../../modals/todayYesDateTime";

const HospitalChatBox = () => {
  const [imageOpen, setImageOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const chathistory = useHistory();
  const [files, setImage] = useState(null);
  const [initialScrollPosition, setInitialScrollPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const currentPath = window.location.pathname;
  const [id1, id2] = currentPath.split("-").slice(-2);
  const urlParts = id1.split("/");
  const ChatId = urlParts[urlParts.length - 1];
  const [loader, setLoader] = useState(false);
  const loggedin_id = localStorage.getItem("_id");
  const [page, setPage] = useState(1);
  const [scrollDown, setScrollDown] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const chatContainerRef = useRef(null);
  const openImagePopup = () => {
    setImageOpen(true);
  };
  const dispatch = useDispatch();
  const Type = localStorage.getItem("type");
  const searchParams = new URLSearchParams(window.location.search);
  const chatId = searchParams.get("chatid");
  const timestampvalue = searchParams.get("timestamp");
  const closeImagepopup = () => {
    setImageOpen(false);
    setImage(null);
  };

  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userStore, setUserStore] = useState(null);
  const userrtype = localStorage.getItem("type");
  function checkFileType(inputString) {
    const fileExtension = inputString.split(".").pop().toLowerCase();
    if (
      fileExtension === "jpg" ||
      fileExtension == "jpeg" ||
      fileExtension == "png" ||
      fileExtension == "webp"
    ) {
      return 3;
    } else if (
      fileExtension === "doc" ||
      fileExtension === "docx" ||
      fileExtension === "txt" ||
      fileExtension === "pdf"
    ) {
      return 2;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    initializeChatUser(ChatId, loggedin_id, userrtype);

    dispatch({
      type: "LEAVE_ROOM",
      payload: { ChatId, loggedin_id, userrtype },
    });

    chatMessageService.onIncomingMessage(handleIncomingMessage);

    return () => {
      chatMessageService.offIncomingMessage();
    };
  }, [ChatId, loggedin_id, userrtype, messages]);

  const handleIncomingMessage = (data) => {
    const newMessage = data?.data[0];
    const msgList = [...messages, newMessage]?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t?._id === item?._id)
    );
    setMessages(msgList);
    setScrollDown(true);
  };

  const sendMessageToUser = () => {
    const messageData = {
      message: message,
      chatId: ChatId,
      userId: loggedin_id,
      receiverId: chatId?.split("?")[0]
        ? chatId?.split("?")[0]
        : chathistory?.location?.state?.from?.userId?._id,
      messageType: checkFileType(message),
    };

    chatMessageService.sendMessage(messageData);

    setMessage("");
    setUploadedFiles([]);
  };

  const leaveRoom = () => {
    chatMessageService.leaveRoom(ChatId, loggedin_id, userrtype);
  };
  const handleTyping = () => {};

  const openRightMenu = (itemId) => {
    setIsOpen(!isOpen);
    setSelectedItemId(itemId);
  };
  const openIsModal = () => {
    setIsOpen(true);
  };
  const [loading, setLoading] = useState(false);

  const handleUpload = async (files) => {
    setLoading(true);
    try {
      if (files?.length) {
        const formData = new FormData();
        formData.append("files", files[0]);
        let url = "v2/LHS/Upload/uploadFiles";
        const response = await axiosInstance.post(url, formData);
        const uploadedImageUrls = response.data.data;
        setUploadedFiles([...uploadedFiles, ...uploadedImageUrls]);
        setMessage(uploadedImageUrls[0]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
    } finally {
      setShowLoader(false);
      setLoading(false);
    }
  };

  const formatDateString = (dateString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      timeZone: "IST",
    };

    const formattedTime = new Date(dateString).toLocaleString("en-US", options);
    return formattedTime;
  };

  const handleDelete = async (id) => {
    openIsModal();
    const data = {
      id: id,
    };
    try {
      let url = `/v2/LHS/User/deleteMessage`;
      const response = await axiosInstance.post(url, data);

      if (response.status == 200) {
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
    getbyIDChatUsers();
  };

  const getbyIDChatUsers = async (pageNum) => {
    try {
      setLoader(true);
      let url = `/v2/LHS/User/getChatById`;
      const params = {
        chatId: ChatId,
        page: pageNum || 1,
        limit: 10,
        userId: chatId?.split("?")[0],
      };
      const queryString = new URLSearchParams(params).toString();
      url = `${url}?${queryString}`;
      const response = await axiosInstance.get(url);
      if (response.status == 200) {
        setTotalPages(Math.ceil(response.data.data.count / 10));
        const datas = [...messages, ...response.data.data.data];
        const lists = datas.reduce((acc, cur) => {
          if (!acc[cur._id]) {
            acc[cur._id] = cur;
          }
          return acc;
        }, {});
        setMessages((prevMessages) => [...Object.values(lists)]);
        setScrollDown(false);
        setUserStore(response.data.data?.user);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight } = chatContainerRef.current;

      if (scrollTop <= 10 && page < totalPages && !loader) {
        setInitialScrollPosition(scrollTop + 900);

        const nextPage = page + 1;
        getbyIDChatUsers(nextPage);

        setPage(nextPage);
      }
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    getbyIDChatUsers();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current && scrollDown) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    } else {
      chatContainerRef.current.scrollTop = 900;
    }
  }, [messages]);

  const imageExtension = [
    ".jpg",
    ".jpeg",
    ".png",
    ".webp",
    ".apng",
    ".avif",
    ".gif",
    ".bmp",
    ".tiff",
    ".tif",
    ".jfif",
    ".pjpeg",
    ".pjp",
  ];
  const isImages = imageExtension.some(
    (extension) =>
      typeof message === "string" && message.toLowerCase().endsWith(extension)
  );
  function checkMethodURL(url) {
    const pathname = new URL(url)?.pathname;
    const filename = pathname.split("/").pop();
    return filename;
  }
  const FileExtension = [
    ".pdf",
    ".doc",
    ".txt",
    ".docx",
    ".html",
    ".htm",
    ".odt",
    ".xls",
    ".xlsx",
    ".ods",
    ".ppt",
    ".pptx",
  ];
  const isFiles = FileExtension.some(
    (extension) =>
      typeof message === "string" && message.toLowerCase().endsWith(extension)
  );

  function isLink(str) {
    const linkRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".webp",
      ".apng",
      ".avif",
      ".gif",
      ".bmp",
      ".tiff",
      ".tif",
      ".jfif",
      ".pjpeg",
      ".pjp",
    ];
    const isImage = imageExtensions.some(
      (extension) =>
        typeof str.message === "string" &&
        str.message.toLowerCase().endsWith(extension)
    );
    const FileExtensions = [
      ".pdf",
      ".doc",
      ".txt",
      ".docx",
      ".svg",
      ".html",
      ".htm",
      ".odt",
      ".xls",
      ".xlsx",
      ".ods",
      ".ppt",
      ".pptx",
    ];
    const isFile = FileExtensions.some(
      (extension) =>
        typeof str.message === "string" &&
        str.message.toLowerCase().endsWith(extension)
    );

    if (linkRegex.test(str.message)) {
      return (
        <div
          className={
            isImage
              ? "w-96 h-auto flex items-end my-2.5"
              : `bg-[#73C7A9]  w-96 h-auto flex items-end my-2.5`
          }
          style={{ borderRadius: "20px 20px 0px 20px" }}
        >
          <div
            className={` ${
              isImage ? "justify-end" : "justify-between"
            } p-2 flex items-end w-full  max-w-full `}
          >
            {isFile ? (
              <a
                href={str?.message}
                className="text-[#FFFFFF] break-all"
                target="_blank"
              >
                {checkMethodURL(str?.message)}
              </a>
            ) : isImage ? (
              <div className="text-[#FFFFFF] one-line w-[200px] break-all">
                <a
                  href={str?.message}
                  className="text-[#FFFFFF] break-all"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <img src={str?.message} />
                </a>
              </div>
            ) : (
              <a
                href={str?.message}
                className="text-[#FFFFFF] break-all"
                target="_blank"
              >
                {str?.message}
              </a>
            )}

            <h2 className="absolute bottom-6 right-7 flex items-center text-[#FFFFFF] font-medium text-xs">
              {/* {formatDateString(str?.createdAt)} */}
            </h2>
          </div>
        </div>
      );
    }
    // const textRegex = /^[a-zA-Z0-9\s]+$/;
    // if (textRegex.test(str.message)) {
    return (
      <div
        className="bg-[#73C7A9]   h-auto flex items-end my-1"
        style={{ borderRadius: "12px 12px 0px 16px", width: "auto" }}
      >
        <div
          className="px-3 py-3 flex items-end w-full justify-between break-all "
          style={{
            flexDirection: "column",
          }}
        >
          <p className="text-[#FFFFFF] text-sm w-auto">{str?.message}</p>
          <h2 className="flex items-center text-[#FFFFFF] font-medium text-xs ">
            {formatDateString(str?.createdAt)}
          </h2>
        </div>
      </div>
    );
    // }
    // return "";
  }
  function isSenderLink(str) {
    const linkRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".webp",
      ".apng",
      ".avif",
      ".gif",
      ".svg",
      ".bmp",
      ".tiff",
      ".tif",
      ".jfif",
      ".pjpeg",
      ".pjp",
    ];
    const isImage = imageExtensions.some(
      (extension) =>
        typeof str.message === "string" &&
        str.message.toLowerCase().endsWith(extension)
    );
    const fileExtensions = [
      ".pdf",
      ".doc",
      ".txt",
      ".docx",
      ".html",
      ".htm",
      ".odt",
      ".xls",
      ".xlsx",
      ".ods",
      ".ppt",
      ".pptx",
      ".svg",
    ];
    const isFile = fileExtensions.some(
      (extension) =>
        typeof str === "string" && str.message.toLowerCase().endsWith(extension)
    );

    if (linkRegex.test(str.message)) {
      return (
        <div
          className={
            isImage
              ? "w-96 h-auto flex items-end my-2.5"
              : `bg-[#73C7A9]  w-96 h-auto flex items-end my-2.5`
          }
          style={{ borderRadius: "20px 20px 0px 20px" }}
        >
          <div
            className={` ${
              isImage ? "justify-start" : "justify-between  p-4"
            } p-2 flex items-end w-full  max-w-full `}
          >
            {isFile ? (
              <a
                href={str?.message}
                className="text-[#FFFFFF] break-all "
                target="_blank"
              >
                {checkMethodURL(str?.message)}
              </a>
            ) : isImage ? (
              <div className="text-[#FFFFFF] one-line w-[200px] ">
                <a
                  href={str?.message}
                  className="text-[#FFFFFF] break-all "
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <img src={str?.message} />
                </a>
              </div>
            ) : (
              <a
                href={str?.message}
                className="text-[#FFFFFF] break-all "
                target="_blank"
              >
                {str?.message}
              </a>
            )}
            <h2 className="absolute bottom-3.5 right-7 flex items-center text-[#767474] font-medium text-xs"></h2>
          </div>
        </div>
      );
    }
    return (
      <div
        className="bg-opacity-10 bg-black w-auto h-auto flex items-end my-2.5 ml-2.5"
        style={{ borderRadius: "12px 12px 0px 16px", width: "auto" }}
      >
        <div className="p-3 flex justify-between flex-col break-all">
          <p className="text-[#333333] text-sm w-auto">{str?.message}</p>
          <h2 className="flex items-center text-[#767474] font-medium text-xs justify-end">
            {formatDateString(str?.createdAt)}
          </h2>
        </div>
      </div>
    );
  }

  const aaa = messages
    ?.slice()
    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    ?.reduce((acc, cur) => {
      const date = formatMessageDate(cur.createdAt);
      if (acc[date]) {
        acc[date] = [...acc[date], cur];
      } else {
        acc[date] = [cur];
      }
      return acc;
    }, {});

  return (
    <>
      <Nav />
      <div
        className="flex justify-center items-center body-screen"
        style={{ backgroundColor: "#F3F2EF", height: "100%" }}
      >
        <div
          className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <div
            className="md:block md:!px-[16px] lg:!px-0 hidden w-full min-w-[271px] lg:w-1/4"
            style={{ minWidth: "25%" }}
          >
            <RightSideBar />
          </div>
          <div className="flex flex-col !gap-2 md:px-5 lg:px-0 w-full xl:w-1/2">
            <div>
              <>
                <div
                  className="flex justify-between p-3 bg-white rounded-[10px] sm:w-full relative"
                  style={{ minHeight: "calc(100vh - 120px)" }}
                >
                  <div className="w-full ">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-3">
                        <div className="flex items-center gap-2">
                          <Link
                            onClick={leaveRoom}
                            to={
                              Type == "staff"
                                ? `/meassage?param1=${
                                    localStorage.getItem("tab") || "tab1"
                                  }`
                                : `/hospitalMsg?param1=${
                                    localStorage.getItem("tab") || "tab1"
                                  }`
                            }
                          >
                            <img src={backBtn} className="h-[20px] w-[20px]" />
                          </Link>
                          <div className="w-12 min-w-[40px] h-12 rounded-full overflow-hidden">
                            <img
                              src={
                                userStore?.image
                                  ? userStore?.image
                                  : userStore?.avatar
                              }
                              className="object-cover w-full h-full"
                            />
                          </div>
                        </div>
                        <div>
                          <h3 className="p-0 text-[#333333] text-[18px] font-semibold">
                            {userStore?.name}
                          </h3>
                          <p className="text-gray-500 text-[15px] font-normal">
                            {userStore?.hiringRole || userStore?.jobTitle}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      ref={chatContainerRef}
                      className="border border-gray-100 my-3 asasas relative flex flex-col overflow-y-scroll"
                      style={{
                        minHeight: "calc(100vh - 290px)",
                        maxHeight: "calc(100vh - 290px)",
                      }}
                    >
                      {messages?.length > 0 && (
                        <>
                          {loader && (
                            <div className="h-3 w-full">
                              <Loader />
                            </div>
                          )}
                          {Object.entries(aaa)?.map((group, index) => {
                            return (
                              <>
                                <div
                                  key={`date-${index}`}
                                  className="date-header flex justify-center mt-2"
                                >
                                  {group[0]}
                                </div>
                                {group[1]?.map((item, mesIndex) => {
                                  const isSender =
                                    item?.senderId?._id === loggedin_id;
                                  return (
                                    <div className="">
                                      <div
                                        key={`date-${mesIndex}`}
                                        className={
                                          isSender
                                            ? "w-full flex justify-end"
                                            : "w-full flex justify-start"
                                        }
                                      >
                                        {!isSender && (
                                          <>
                                            <div className="reciever w-[50%]">
                                              <div className="flex justify-start relative mr-2.5">
                                                {isSenderLink(item)}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        {isSender && (
                                          <>
                                            <div className="sent w-[50%]">
                                              <div className="flex justify-end relative mr-2.5">
                                                {isLink(item)}
                                                <div
                                                  className="flex items-top gap-1 relative"
                                                  onClick={() =>
                                                    openRightMenu(item?._id)
                                                  }
                                                >
                                                  {isOpen &&
                                                    selectedItemId ===
                                                      item?._id && (
                                                      <div className="dropdown-card rounded-[10px] top-[10px] ">
                                                        <a
                                                          onClick={() =>
                                                            handleDelete(
                                                              item?._id
                                                            )
                                                          }
                                                          className="cursor-pointer"
                                                        >
                                                          Delete Message
                                                        </a>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                    {isTyping && <div>User is typing...</div>}
                    <div className="flex items-center justify-between mt-3 gap-3.5 w-full">
                      <div className="relative w-full ">
                        <input
                          type="text"
                          placeholder="Type a message..."
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          onKeyPress={handleTyping}
                          style={{
                            borderRadius: "11px",
                          }}
                          className="w-full h-[58px] pl-4 pr-12 py-2 border rounded-md border-gray-300 outline-none"
                        />
                        <div className="absolute top-[35px] right-3 transform -translate-y-1/2 cursor-pointer">
                          <label htmlFor="fileInput">
                            <img
                              src={fileChat}
                              onClick={openImagePopup}
                              alt="File Icon"
                              className="w-6 h-6"
                            />
                          </label>
                        </div>
                      </div>
                      <div onClick={() => sendMessageToUser()}>
                        <img className="cursor-pointer" src={chatSend} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
          <RecommendedForYou />
        </div>
      </div>
      <Modal
        isOpen={imageOpen}
        onClose={closeImagepopup}
        className={"md:w-1/2     md:mt-0"}
      >
        <div className={"bg-white z-10 w-full h-full  rounded-lg"}>
          <div
            className={`bg-white rounded-lg w-full px-3 py-2 ${
              uploadedFiles.length > 4 ? "h-auto" : "h-full"
            }  `}
          >
            <h2 className="text-xl font-medium leading-20 tracking-wider text-[#333333]">
              Select your attachment
            </h2>
            <div
              className={`mt-3   rounded-6  border-gray-600 ${
                uploadedFiles.length > 0
                  ? "flex w-full border-none  "
                  : "flex border-2  border-dashed h-[300px]"
              }  items-center justify-center rounded-md`}
            >
              {/* {selectedImage && (
                <div>
                  {isImages ? (
                    <img
                      src={selectedImage}
                      alt="Selected Image"
                      onClick={() => setSelectedImage(null)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )} */}

              <div
                className={`${
                  uploadedFiles.length > 0
                    ? isImages
                      ? `gap-2 p-2 w-full flex h-[300px] rounded-lg `
                      : `gap-2 p-2 w-full flex rounded-lg`
                    : "w-full border-0 flex justify-center"
                }`}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {message ? (
                      <div
                        onClick={() => setSelectedImage(message)}
                        className="w-full"
                      >
                        {isImages ? (
                          <img
                            src={message}
                            className="rounded-lg h-[300px] w-full"
                            // alt={`Preview`}
                            onLoad={() => setImageLoaded(true)}
                          />
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#73C7A9",
                              display: "flex",
                              padding: "13px",
                              color: "white",
                              borderRadius: "16px",
                              height: "74px",
                            }}
                          >
                            <img src={VectorPdf} />
                            <a
                              href={message}
                              className="text-[#FFFFFF] break-all "
                              target="_blank"
                              style={{
                                marginLeft: "12px",
                                marginTop: "12px",
                              }}
                            >
                              {checkFileType(message) == 2
                                ? checkMethodURL(message)
                                : message}
                              {/* {checkMethodURL(message)} */}
                            </a>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="relative">
                        <div className="flex justify-center mb-3.5">
                          <img
                            src={videoupload}
                            alt=""
                            onChange={(e) => handleUpload(e?.target?.files)}
                            className="absolute"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            defaultValue={files}
                            multiple
                            onChange={(e) => handleUpload(e?.target?.files)}
                            className="opacity-0 w-[100px]"
                          />
                        </div>
                        <div className="mt-3">
                          Drop your image or document file here
                        </div>
                        <h2 className="flex justify-center m-3.5">OR</h2>
                        <div className="relative flex gap-24">
                          <div
                            className="flex justify-center mb-3.5"
                            style={{ cursor: "pointer" }}
                          >
                            <button
                              className="w-[192px] h-[44px] border border-[#333333]-700 rounded-[100px] text-sm text-[#000000] font-normal   cursor-pointer hover:!cursor-pointer absolute"
                              onClick={(e) => handleUpload(e?.target?.files)}
                            >
                              Select from document
                            </button>
                            <input
                              type="file"
                              accept="doc/*"
                              defaultValue={files}
                              multiple
                              onChange={(e) => handleUpload(e?.target?.files)}
                              className="opacity-0 w-[100px]"
                            />
                          </div>
                          <div
                            className="flex justify-center mb-3.5"
                            style={{ cursor: "pointer" }}
                          >
                            <button
                              className="w-[192px] h-[44px] border border-[#333333]-700 rounded-[100px] text-sm text-[#000000] font-normal   cursor-pointer hover:!cursor-pointer absolute"
                              onClick={(e) => handleUpload(e?.target?.files)}
                            >
                              Select from gallery
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              defaultValue={files}
                              multiple
                              onChange={(e) => handleUpload(e?.target?.files)}
                              className="opacity-0 w-[100px]"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="mt-10 flex justify-end">
              <button
                className="h-12 w-32 px-2 border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                onClick={() => {
                  setUploadedFiles([]);
                  setMessage("");
                  closeImagepopup();
                }}
              >
                Cancel
              </button>
              <button
                className={`h-12 w-32 px-2 rounded-full ${
                  isImages || isFiles
                    ? "bg-[#73C7A9] text-[#FFFFFF]"
                    : "bg-gray-400 text-gray-700 cursor-not-allowed"
                } ml-8`}
                onClick={() => {
                  if (isImages || isFiles) {
                    sendMessageToUser();
                  }
                  closeImagepopup();
                }}
                //  disabled={!imageLoaded}
              >
                {isImages || isFiles ? "Send" : "Done"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HospitalChatBox;
