import React, { useEffect, useState } from "react";
import leftArrow from "../../assets/images/leftArrow.png";
import user from "../../assets/images/user.png";
import Nav from "../Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import { useHistory } from "react-router-dom";
import likedTrue from "../../assets/images/likedTrue.png";
import replay from "../../assets/images/reply.png";
import { useDispatch, useSelector } from "react-redux";
import dots from "../../assets/images/dots.png";
import messageReplay from "../../assets/images/messageReplay.svg";
import part3_back1 from "../../assets/images/part3_back1.png";
import Loader from "../../modals/Loader";
import RightSideBar from "../../containers/commonComponent/rightSideBar";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";
import { useRef } from "react";
import edit from "../../assets/images/edit.png";
import deletebox from "../../assets/images/deletebox.png";
import hideEye from "../../assets/images/hide.png";
import Modal from "../../common/modal";
import like from "../../assets/images/like.png";
// import sendReply from "../../assets/images/sendMessage.png";
import sendReply from "../../assets/images/sendReply.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import calculateTime from "../../modals/calculateTime";
import classNames from "classnames";

function ReplayList(props) {
  const history = useHistory();
  const { location } = props || {};
  const urlParams = new URLSearchParams(window.location.search);
  const postId = urlParams.get("postId");
  const commentId = location?.state?.commentId || "";
  const [updateReplyId, setUpdateReplyId] = useState(0);
  const userRole = props?.userId?.role.split(/(?=[A-Z])/);
  const JoinRole = userRole?.join(" ");
  const profile = useSelector((state) => state.data.profile);
  const [viewAll, setViewAll] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [isButtonHidden, setIsButtonHidden] = useState(true);
  const [commentText, setCommentText] = useState("");
  const [openModalId, setOpenModalId] = useState(null);
  const [updateReply, setUpdateReply] = useState(false);
  const [updateReplyText, setUpdateReplyText] = useState(null);
  const [count, setCount] = useState(0);
  const [likeState, setLikeState] = useState(0);
  const [likes, setLike] = useState([]);

  const handleLikePost = async (commentId) => {
    const likeButton = document.getElementById("likeButton");
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    let url = `/v2/LHS/User/likeDislikeComment`;
    axiosInstance
      .post(url, { commentId: postId })
      .then((res) => {
        setLike(res);
        showSuccessToast(res?.data?.message);
        getAllReplyByCommentId();
      })
      .catch(() => {
        showErrorToast("Something went wrong!");
      })
      .finally(() => {
        // likeButton.disabled = false;
        setButtonClicked(false);
      });
  };

  const renderImage = (isLiked) => {
    if (isLiked ?? likeState) {
      return (
        <img
          src={likedTrue}
          alt="Liked"
          style={{ height: "19px", width: "20px", cursor: "pointer" }}
        />
      );
    } else {
      return (
        <img
          src={like}
          alt="Like"
          style={{ height: "19px", width: "20px", cursor: "pointer" }}
        />
      );
    }
  };
  useEffect(() => {
    if (postId) {
      getAllReplyByCommentId(postId);
    }
  }, [postId]);
  const token = localStorage.getItem("type");

  const handleButtonClick = (data) => {
    history.push(`/LikedCommentList?comment_id=${data?._id}`);
  };
  const [allReplyByCommntId, setAllReplyByCommntId] = useState([]);
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [isEditReplyModalOpen, setIsEditReplyModalOpen] = useState(false);
  const [selectedReply, setSelectedReply] = useState(null);

  const getAllReplyByCommentId = async () => {
    try {
      let url = `/v2/LHS/User/getAllReplyByCommentId?commentId=${postId}`;
      const response = await axiosInstance.get(url);
      if (response.data) {
        if (response?.data?.data) {
          setAllReplyByCommntId(response?.data?.data?.replyData);
        } else {
          setAllReplyByCommntId([]);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const [updateCommentOption, setUpdateCommentOption] = useState(false);

  const handleToggleReplyPopup = (replyId) => {
    if (updateReplyId === replyId) {
      setUpdateReplyId(null);
    } else {
      setUpdateReplyId(replyId);
    }
  };
  const [hidePost, setHidePost] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [replyText, setReplyText] = useState("");

  const currentPath = window.location.pathname;
  const [id1, id2] = currentPath.split("/").slice(-2);
  const urlParts = id1.split("=");

  const openIsModal = () => {
    setModalOpen(true);
  };
  const handleDeleteReply = (_id) => {
    // setLoading(true);
    let url = `v2/LHS/User/deleteReply/${_id}`;
    axiosInstance
      .delete(url)
      .then((data) => {
        // handleAllReplyByCommntId();
        getAllReplyByCommentId();
        showSuccessToast("Reply deleted successfully!");
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
      });
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const editPostPopup = useRef();
  const updateReplyPopup = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editPostPopup.current &&
        !editPostPopup.current.contains(event.target)
      ) {
        setHidePost(false);
      }

      // if (
      //   editCommentPopup?.current &&
      //   !editCommentPopup.current.contains(event.target)
      // ) {
      //   set(false);
      //   setUpdateComments(false);
      // }

      if (
        updateReplyPopup?.current &&
        !updateReplyPopup.current.contains(event.target)
      ) {
        setUpdateReplyId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
    setIsButtonHidden(true);
  };

  const handleEditReply = (replytext) => {
    setUpdateReply(true);
    setUpdateReplyText(replytext?.comment);
    setIsEditReplyModalOpen(true);
    setUpdateReplyId(replytext?._id);
    setSelectedReply(replytext);
  };
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleUpdateReply = async (commentId) => {
    setUpdateLoading(true);
    try {
      const formData = {
        comment: updateReplyText,
      };
      const response = await axiosInstance.put(
        `v2/LHS/User/updateReply/${commentId}`,
        formData
      );
      setUpdateReplyText("");
      setUpdateReplyId(null);
      showSuccessToast("Reply updated successfully!");
    } catch (error) {
      showErrorToast("Something went wrong");
      setUpdateLoading(false);
    } finally {
      // handleAllReplyByCommntId();
      setUpdateLoading(false);
      getAllReplyByCommentId();
    }
  };

  const storedDataString = localStorage.getItem("dataId");

  const handleReplyComment = async () => {
    setLoading(true);
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    try {
      const formData = {
        commentId: postId,
        comment: commentText,
      };
      await axiosInstance.post("v2/LHS/User/addReply", formData);
      showSuccessToast("Reply added successfully!");
      setLoading(false);
      setReplyText("");
      setCommentText("");
    } catch (error) {
      showErrorToast("Something went wrong");
      setLoading(false);
    } finally {
      setReplyText("");
      setCommentText("");
      getAllReplyByCommentId();
      // handleAllReplyByCommntId();
      setButtonClicked(false);
    }
  };
  return (
    <>
      {token ? <Nav /> : <JobsHeader />}
      <div
        className="flex justify-center items-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 72px)" }}
      >
        <div className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]">
          <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />
          <div className="flex flex-col !gap-3 md:px-5 lg:px-0 w-full xl:w-[52%] lg:w-[48%] mx-auto bg-white p-4 rounded-[10px]">
            <div className={"mb-2 flex items-center"}>
              <div
                className="flex items-center"
                onClick={() =>
                  history.push({
                    pathname: "/staff",
                  })
                }
              >
                <img
                  src={leftArrow}
                  style={{
                    height: "18px",
                    width: "22px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <h1 className="font-semibold text-[18px] leading-[20px]">
                  {allReplyByCommntId?.length}{" "}
                  {allReplyByCommntId?.length > 1 ? "Replies" : "Reply"}
                </h1>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="border border-[#000000]-600 opacity-40 w-full" />
            </div>

            {/* {loadData ? (
                <Loader />
              ) : ( */}
            <div>
              <div
                className="w-full h-full pb-[30px] "
                style={{ height: "calc(100vh - 220px)" }}
              >
                <div
                  className="overflow-y-scroll pr-2"
                  style={{ height: "calc(100vh - 280px)" }}
                >
                  {allReplyByCommntId?.map((item) => {
                    return (
                      <>
                        <div className="flex gap-[20px]" key={item._id}>
                          <img
                            src={
                              item?.userId?.image ? item?.userId?.image : user
                            }
                            style={{
                              height: "48px",
                              width: "48px",
                            }}
                            className="rounded-full"
                          />
                          <div className="w-full">
                            <div className="">
                              <div className="border border-gray-400 rounded-[8px] p-3 bg-[#F3F2EF]">
                                <div className="flex justify-between items-start">
                                  <div className="leading-[26px]">
                                    <h1 className="font-[#333333] mt-2 text-md font-semibold leading-5 tracking-wide">
                                      {item?.userId?.name
                                        ? item?.userId?.name
                                        : item?.userId?.firstName +
                                          " " +
                                          item?.userId?.lastName}
                                    </h1>
                                    <h4 className="text-gray-500 text-sm">
                                      {item?.userId?.role == "hospital"
                                        ? item?.userId?.hiringRole
                                        : item?.userId?.role == "staff"
                                        ? item?.userId?.jobTitle
                                        : JoinRole}
                                    </h4>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <p className="text-sm leading-[15px] text-gray-500 whitespace-nowrap">
                                      {calculateTime(item?.createdAt)}
                                    </p>
                                    <div className="flex flex-col w-[10%]">
                                      <div className="flex justify-end relative  left-[40px] bottom: [10px]">
                                        {/* <img
                                    src={dots}
                                    alt="Liked"
                                    onClick={() => setHidePost((s) => !s)}
                                    style={{
                                      height: "5px",
                                      width: "20px",
                                      cursor: "pointer",
                                    }}
                                  /> */}

                                        {hidePost &&
                                          openModalId === item._id && (
                                            <div
                                              ref={editPostPopup}
                                              className="absolute flex flex-wrap justify-center items-center z-10  bg-white border border-gray-100 mt-4 rounded-md px-2 py-2 w-[150px] max-h-24 ml-auto "
                                            >
                                              <div>
                                                <div className="flex items-center">
                                                  <img
                                                    src={edit}
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                      marginRight: "8px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <h2
                                                    className="ml-2 text-sm cursor-pointer"
                                                    onClick={() =>
                                                      handleEditReply(item)
                                                    }
                                                  >
                                                    Edit Reply
                                                  </h2>
                                                </div>
                                                <div className="flex items-center mt-2">
                                                  <img
                                                    src={deletebox}
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                      marginRight: "8px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <h2
                                                    className="text-sm ml-2 cursor-pointer"
                                                    onClick={() =>
                                                      handleDeleteReply(
                                                        item?._id
                                                      )
                                                    }
                                                  >
                                                    Delete Reply
                                                  </h2>
                                                </div>
                                              </div>
                                              <Modal
                                                isOpen={modalOpen}
                                                onClose={closeModal}
                                                className="md:w-1/3 mt-0"
                                              >
                                                <div>
                                                  <h1 className="font-medium text-xl text-center mb-3">
                                                    Delete post?
                                                  </h1>
                                                  <p className="text-base text-center m-auto mb-4">
                                                    Are you sure you want to
                                                    permanently remove this{" "}
                                                    <span className="m-auto">
                                                      post from LinkHealthStaff?
                                                    </span>
                                                  </p>

                                                  <div className="text-center">
                                                    <button
                                                      onClick={() =>
                                                        setModalOpen(false)
                                                      }
                                                      className="text-base border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white rounded-full w-32 h-11 ml-5"
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                                                      onClick={() => {
                                                        // handleSubmit(props._id);
                                                        setModalOpen(false);
                                                        // setHidePost(false);
                                                      }}
                                                    >
                                                      Delete
                                                    </button>
                                                  </div>
                                                </div>
                                              </Modal>
                                              {/* )} */}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    {(profile?._id == item?.userId?._id ||
                                      profile?.role == "superAdmin") && (
                                      <img
                                        src={dots}
                                        alt="Liked"
                                        // onClick={() => toggleHidePost(commentIds)}
                                        onClick={() => {
                                          setOpenModalId(item._id);
                                          setHidePost(true);
                                        }}
                                        // onClick={() => setHidePost((s) => !s)}
                                        style={{
                                          height: "5px",
                                          width: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                <p className="text-base font-normal leading-6 tracking-[0.2px] mt-[14px]">
                                  {item?.comment}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {selectedReply && updateReplyId == item._id && (
                          <div className="flex items-center justify-end mt-3">
                            <img
                              src={
                                item?.userId?.image ? item?.userId?.image : user
                              }
                              style={{
                                height: "38px",
                                width: "38px",
                                borderRadius: "40px",
                                marginRight: "15px",
                              }}
                            />
                            <div className="border-gray-100 border rounded-lg justify-center w-10/12 items-center">
                              <input
                                className="border-gray-100 appearance-none border rounded w-full h-11 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="inline-full-name"
                                type="text"
                                placeholder="Update  reply"
                                // defaultValue={updateReplyText}
                                value={updateReplyText}
                                onChange={(e) =>
                                  setUpdateReplyText(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex items-center mt-3 ml-28">
                          {updateReplyId == item._id && updateReply && (
                            <div className="mb-8 flex items-center mt-3 gap-[20px]">
                              <button
                                onClick={() => {
                                  // setUpdateReply(false);
                                  // setUpdateReplyId(null);
                                  // setUpdateReplyText("");
                                  // setSelectedReply(null); // Reset the selected reply when the modal is closed

                                  // setIsEditReplyModalOpen(false);

                                  setUpdateReply(false);
                                  setUpdateReplyId(null);
                                  setUpdateReplyText("");
                                }}
                                className="h-9 w-[100px] font-medium border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                              >
                                Cancel
                              </button>
                              {updateReplyText && (
                                <button
                                  onClick={() => handleUpdateReply(item._id)}
                                  className="h-9 w-[140px] rounded-full bg-[#73C7A9] text-[#FFFFFF] font-medium"
                                >
                                  {updateLoading ? <Loader /> : "Update Reply"}
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
                <div
                  className="flex items-center bg-white sm:w-full  bottom-0 left-0 right-0 pt-2"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <div className="flex flex-col gap-[15px] w-full">
                    <div className="flex gap-3">
                      <div className="w-full max-w-fit">
                        <img
                          src={profile?.image}
                          style={{
                            height: "48px",
                            width: "48px",
                          }}
                          className="rounded-full"
                        />
                      </div>
                      <div className="border-gray-100 border rounded-lg justify-center w-full items-center relative flex">
                        <input
                          className="border-[#EAEAEA] appearance-none border rounded-[6px] w-full h-12 py-1 pl-3 pr-12 text-[16px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="inline-full-name"
                          type="text"
                          placeholder="Add a Reply"
                          defaultValue={commentText}
                          value={commentText}
                          onChange={(e) => setCommentText(e.target.value)}
                        />
                        {loading ? (
                          <Loader className="absolute right-4 h-6 w-6" />
                        ) : (
                          <img
                            onClick={() => {
                              commentText?.length && handleReplyComment();
                            }}
                            src={sendReply}
                            className={classNames(
                              {
                                "opacity-70 cursor-not-allowed":
                                  !commentText?.length,
                                "opacity-100 cursor-pointer":
                                  commentText?.length,
                              },
                              "absolute right-4 h-6 w-6"
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-28">
                  {currentCommentId && (
                    <div className="mb-10 flex items-center mt-3 gap-[20px]">
                      <button
                        onClick={() => {
                          setCurrentCommentId("");
                          setReplyText("");
                        }}
                        className="h-9 w-[100px] font-medium  border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                      >
                        Cancel
                      </button>
                      {replyText && (
                        <button
                          onClick={() => handleReplyComment(data._id)}
                          className="h-9 w-[100px] rounded-full bg-[#73C7A9] text-[#FFFFFF] font-medium"
                          disabled={buttonClicked}
                        >
                          Reply
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* // )} */}
            </div>
          </div>
          <RecommendedForYou />
        </div>
      </div>
    </>
  );
}

export default ReplayList;
