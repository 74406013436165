import React, { useEffect, useState } from "react";
import institution from "../../assets/images/institute.svg";
import institutionSize from "../../assets/images/instituteSize.svg";
import institutionWebsite from "../../assets/images/inst_website.svg";
import ErrorState from "../staffSignUp/components/errorState";
import SubHeader from "../staffSignUp/components/subHeader";
// import img from "../../assets/images/2-3.png";
import img from "../../assets/images/2-4.svg.svg";
import Location from "./location";
import Alert from "../../modals/alert";
import "./hospSignUp.css";
import { decrementStep } from "../../../redux/action/action";
import { useHistory } from "react-router-dom";
import Loader from "../../modals/Loader";
import { HeaderComp } from "../staffSignUp/headerComp";

function HospSignUp_2(props) {
  const [instName, setInstName] = useState("");
  const [instSize, setInstSize] = useState("");
  const [instWebsite, setInstWebsite] = useState("");
  const [error, setError] = useState({
    instName: "none",
    instSize: "none",
    instWebsite: "none",
    aboutUs: "none",
  });
  const [showAlert, setShowAlert] = useState("none");
  const [alertContent, setAlertContent] = useState(
    "Please fill the location fields correctly."
  );
  const [propsZip, setPropsZip] = useState({});
  const [nameErr, setNameErr] = useState(
    "Healthcare institution name is required"
  );
  const [showLocErr, setShowLocErr] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const setVal = (e) => {
    if (e.target.value.length < 250) {
      setInstName(e.target.value);
      const updatedError = { ...error };
      if (e.target.value !== "") {
        updatedError.instName = "none";
        setError(updatedError);
      } else {
        updatedError.instName = "block";
        setError(updatedError);
      }
    }
  };

  const setInstitutionSize = (e) => {
    if (e.target.value.length < 7) {
      setInstSize(e.target.value);
      const updatedError = { ...error };
      updatedError.instSize = "none";
      setError(updatedError);
    }
  };

  const formatInput = (e) => {
    const checkIfNum =
      (e.key !== undefined &&
        (e.key === "e" || e.key === "." || e.key === "+" || e.key === "-")) ||
      (e.keyCode !== undefined &&
        (e.keyCode === 69 ||
          e.keyCode === 190 ||
          e.keyCode === 187 ||
          e.keyCode === 189));
    checkIfNum && e.preventDefault();
  };

  const setInstitutionWebsite = (e) => {
    setInstWebsite(e.target.value);
    const pattern = new RegExp(
      "^(https?://)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    const valid = !!pattern.test(e.target.value);
    const updatedError = { ...error };
    if (e.target.value === "" || !valid) {
      updatedError[e.target.name] = "block";
      setError(updatedError);
    } else {
      updatedError[e.target.name] = "none";
      setError(updatedError);
    }
  };

  const setLocError = (val) => {
    setShowLocErr(val);
  };

  useEffect(() => {
    var hosInstiName = window.localStorage.getItem("institution_name") || "";
    var hosInstiSize = window.localStorage.getItem("institution_size") || "";
    var hosInstiWeb = window.localStorage.getItem("institution_website") || "";
    var hosZipcode = window.localStorage.getItem("zip_code") || "";
    var hosCity = window.localStorage.getItem("hoscity") || "";
    var hosState = window.localStorage.getItem("hosstate") || "";
    var hosAddress = window.localStorage.getItem("hosstreet_address") || "";
    setInstName(hosInstiName);
    setInstSize(hosInstiSize);
    setInstWebsite(hosInstiWeb);
    setPropsZip({
      zipCode: hosZipcode,
      street: hosAddress,
      state: hosState,
      city: hosCity,
    });
  }, []);

  const continueToNext = () => {
    setShowLoader(true);
    const instNameError =
      error.instName === "block" || instName === "" ? "block" : "none";
    const instSizeError =
      error.instSize === "block" || instSize === "" ? "block" : "none";
    const instWebsiteError =
      error.instWebsite === "block" || instWebsite === "" ? "block" : "none";

    const updatedError = {
      instName: instNameError,
      instSize: instSizeError,
      instWebsite: instWebsiteError,
    };
    setError(updatedError);
    if (
      instNameError === "none" &&
      instSizeError === "none" &&
      instWebsiteError === "none" &&
      !showLocErr
    ) {
      window.localStorage.setItem("institution_name", instName);
      window.localStorage.setItem("institution_size", instSize);
      window.localStorage.setItem("institution_website", instWebsite);
      if (
        window.localStorage.getItem("zip_code") == undefined ||
        window.localStorage.getItem("hoscity") == undefined
      ) {
        setShowLoader(false);
      } else {
        props.history.push("/hospSignUp_3");
      }
    }
    setShowLoader(false);
  };

  const history = useHistory();
  const handleBack = () => {
    history.push("/hospSignUp_1");
  };

  return (
    <div
      className="outer_container"
      style={{ backgroundColor: "#73C7A9", height: "100vh" }}
    >
      <div
        className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
        style={{ padding: "0", maxWidth: 720 }}
      >
        <div className="ssu_container py-4 px-5 min-h-[90vh] max-h-[90vh] h-auto flex flex-col">
          <HeaderComp />
          <div className="flex flex-col justify-between h-screen">
            <SubHeader
              num="2"
              title="Healthcare Institution"
              next="/hospSignUp_3"
              prev="/hospSignUp_1"
              img={img}
              onBack={handleBack}
            />
            <div className="hsu1_txt1 text-xl mt-4 mb-0">
              Please provide your Healthcare Institution details
            </div>
            <div className="ssu3_body w-full">
              <div className="ssu2_modal1_input py-1">
                <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                  <div className="input_left_icon">
                    <img
                      width="18px"
                      height="15px"
                      alt="img"
                      src={institution}
                    />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="Healthcare institution name"
                    type="text"
                    value={instName}
                    name="instName"
                    onChange={setVal}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") continueToNext();
                    }}
                  />
                </div>
                <ErrorState show={error.instName} name={nameErr} />
              </div>
              <div className="ssu2_modal1_input py-1">
                <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                  <div className="input_left_icon">
                    <img
                      alt="img"
                      width="18px"
                      height="15px"
                      src={institutionSize}
                    />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="Healthcare institution size"
                    type="number"
                    min="1"
                    max="999999"
                    value={instSize}
                    name="instSize"
                    onKeyDown={formatInput}
                    onChange={setInstitutionSize}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") continueToNext();
                    }}
                  />
                </div>
                <ErrorState
                  show={error.instSize}
                  name="Healthcare institution size is required. ( 1 ~ 999999 )"
                />
              </div>
              <div className="ssu2_modal1_input py-1">
                <div className="ssu2_modal1_input py-1 ssu2_modal3_selectBox">
                  <div className="input_left_icon">
                    <img
                      width="18px"
                      height="15px"
                      alt="img"
                      src={institutionWebsite}
                    />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="Healthcare institution website"
                    type="text"
                    value={instWebsite}
                    name="instWebsite"
                    onChange={setInstitutionWebsite}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") continueToNext();
                    }}
                  />
                </div>
                <ErrorState
                  show={error.instWebsite}
                  name="Valid website address is required."
                />
              </div>
              <div className="hsu1_txt2"> Corporate Office Address </div>
              <Location
                showErr={showLocErr}
                street={propsZip?.street || props.location.street_address}
                continueToNext={continueToNext}
                zipCode={propsZip?.zipCode || props.location.zip_code}
                state={propsZip?.state}
                city={propsZip?.city}
                setLocError={setLocError}
              />
            </div>
            <hr style={{ margin: "10px 0px 0px" }} />
            <div className="ssu_bottom">
              <div className="ssu_button" onClick={continueToNext}>
                {showLoader ? <Loader /> : "CONTINUE"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        display={showAlert}
        title={"ALERT"}
        content={alertContent}
        agreeFn={() => setShowAlert("none")}
      />
    </div>
  );
}

export default HospSignUp_2;
