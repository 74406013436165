import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";

export default function Modal({
  isOpen,
  onClose,
  className,
  wrapperClass,
  children,
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-10000 inset-0 bg-black bg-opacity-75 backdrop-filter backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
          <div className="flex relative items-end justify-center p-4 text-center sm:items-center sm:p-0  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  className,
                  "relative transform rounded-lg bg-white text-left shadow-xl transition-all max-w-none sm:my-8 sm:w-full min-w-75 h-full"
                )}
              >
                <div
                  className={classNames(
                    wrapperClass,
                    "bg-white p-5 sm:p-5 h-full"
                  )}
                >
                  <div className="relative">{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
