import { socket } from "./socketManager";
const userId = localStorage.getItem("_id");

let isUserInitialized = false;

export const initializeUser = () => {
  return new Promise((resolve, reject) => {
    socket.emit("onlineUser", { userId });
    socket.on("onlineUserOk", (data) => {
      isUserInitialized = true;
      console.log("User is initialized", data);
      resolve();
    });
  });
};

export const initializeChatUser = (chatId, loggedin_id, userrtype) => {
  const listenerData = {
    chatId: chatId,
    userId: loggedin_id,
    role: userrtype,
  };

  socket.emit("joinRoom", listenerData);
};

export const chatMessageService = {
  onIncomingMessage: (callback) => {
    socket.on("sendMessageOk", (data) => {
      callback(data);
    });
  },
  offIncomingMessage: () => {
    socket.off("sendMessageOk");
  },
  sendMessage: (messageData) => {
    socket.emit("send_message_user", messageData);
  },
  leaveRoom: (chatId, loggedin_id, userrtype) => {
    const listenerData = {
      chatId: chatId,
      userId: loggedin_id,
      role: userrtype,
    };
    socket.emit("leaveRoom", listenerData);
  },
};

export const headerNotificationService = {
  onNotificationReceived: (callback) => {
    socket.on("notificationCount", (notification) => {
      callback(notification);
    });
  },
  offNotificationReceived: () => {
    socket.off("notificationCount");
  },
};

export const likeCommentCountService = {
  onLikeDislike: (callback) => {
    socket.on("newUpdateOk", (data) => {
      callback(data);
    });
  },
  offLikeDislike: () => {
    socket.off("notificationCount");
  },
};

export const hospitalMessageService = {
  onChatCount: (callback) => {
    socket.on("newMessageOk", (count) => {
      console.log("new message got", count);
      callback(count);
    });
  },
  offChatCount: () => {
    socket.off("newMessageOk");
  },
};

socket.on("connect", () => {
  console.log("Socket connected");
  if (!isUserInitialized) {
    initializeUser()
      .then(() => {
        console.log("User initialization complete");
      })
      .catch((error) => {
        console.error("Error initializing user:", error);
      });
  }
});

socket.on("disconnect", (reason) => {
  console.log("Socket disconnected. Reason: ", reason);
  isUserInitialized = false;
});

socket.on("reconnect", (attempNumber) => {
  console.log(`Socket reconnected after ${attempNumber} attempts`);
  if (!isUserInitialized) {
    initializeUser()
      .then(() => {
        console.log("User initialization complete");
      })
      .catch((error) => {
        console.error("Error initializing user:", error);
      });
  }
});

socket.on("reconnection_error", (error) => {
  console.log("Reconnection attempt failed", error);
});

socket.on("reconnection_failed", () => {
  console.log("Reconnection failed, No more attempts");
});
