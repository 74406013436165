import React, { useEffect, useRef } from "react";
// import search_icon from "../../assets/images/search_icon.png";
import search from "../../assets/images/search.svg";
// import filter from "../../assets/images/filter.png";
import filter_img from "../../assets/images/filter_img.svg";
import recent from "../../assets/images/recent.svg";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
// import { search } from "superagent";

const SearchFilter = ({
  hideRecentHistory = false,
  hideFilters = [],
  onFilterChange,
  placeholder,
  clearFilter,
  setClearFilterFalse,
}) => {
  const [showRecent, setShowRecent] = useState(false);
  const debounceTimer = useRef(null);
  const [filterModel, setFilterModel] = useState(false);
  const [filterData, setFilterData] = useState({
    sort: "",
    filter: "",
    search: null,
    value: "",
    sortValue: "",
  });
  const modalPopupRef = useRef(null);
  const [searchHistory, setSearchHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    if (clearFilter) {
      setFilterData({
        sort: "",
        filter: "",
        search: null,
        value: "",
        sortValue: "",
      });
      inputRef.current.value = "";
    }
  }, [clearFilter]);

  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowHistory(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value.trim();
    if (query !== "") {
      setSearchHistory((prevHistory) => [...new Set([query, ...prevHistory])]);
    }
    setShowHistory(true);
    if (query === "") {
      setFilterData((prev) => ({ ...prev, search: "" }));
    } else {
      setFilterData((prev) => ({ ...prev, search: query }));
    }
  };
  const handleSearchFocus = () => {
    setShowHistory(true);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      setShowHistory(true);
      const query = inputRef.current.value.trim();
      if (query === "") {
        setFilterData((prev) => ({ ...prev, search: "" }));
      } else {
        setFilterData((prev) => ({ ...prev, search: query }));
      }
    }
  };
  const handleSearchFromHistory = (query) => {
    setSearchHistory((prevHistory) => [...new Set([query, ...prevHistory])]);
    inputRef.current.value = query;
    setShowHistory(false);
  };

  useEffect(() => {
    const { sort, filter, search, value, sortValue } = filterData;
    if (search == "") {
      setShowHistory(false);
    }
    if (search !== null || filter || sort) {
      console.log(filterData);
      setClearFilterFalse && setClearFilterFalse();
      onFilterChange(filterData);
      setFilterModel(false);
    }
  }, [filterData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalPopupRef?.current &&
        !modalPopupRef.current.contains(event.target)
      ) {
        // setModalOpen(false);
        setShowRecent(!showRecent);
        setFilterModel(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlechange = (e) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = null;
    }
    debounceTimer.current = setTimeout(() => {
      setFilterData((prev) => ({ ...prev, search: e?.target?.value }));
      handleSearchChange(e);
    }, 300);
  };

  return (
    <>
      <div
        className="bg-white flex items-center justify-between border px-4 border-gray-100 rounded-lg"
        style={{
          height: "60px",
          borderRadius: "30px",
          position: "relative",
          zIndex: "1",
          boxShadow: "2px 5px 15px 0 rgba(0, 0, 0, 0.15)",
        }}
      >
        <div className="flex gap-3 w-full">
          <img
            src={search}
            style={{
              height: "22px",
              width: "22px",
            }}
          />
          <input
            className="justify-center appearance-none text-base	rounded h-full w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="inline-full-name"
            type="text"
            ref={inputRef}
            placeholder={placeholder || "Search job title or specialization"}
            onChange={(e) => {
              handlechange(e);
            }}
            onKeyDown={handleEnterKeyPress}
            onFocus={handleSearchFocus}
          />
        </div>
        <img
          src={filter_img}
          style={{
            height: "13px",
            width: "24px",
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => setFilterModel(true)}
        />

        {filterModel && (
          <div
            className="bg-white p-4 z-10 absolute right-0 top-10 shadow-md border border-gray-50 rounded-[6px]"
            ref={modalPopupRef}
            style={{ minWidth: "242px" }}
          >
            <div className="flex justify-between items-center mb-4 float-right">
              <h2
                onClick={() => {
                  setFilterData({
                    sort: "",
                    filter: "",
                    search: "",
                    sortValue: "",
                    value: "",
                  });
                }}
                className="font-semibold text-[14px] cursor-pointer"
              >
                Clear filter
              </h2>
            </div>
            {![1, 2, 3, 4].every((filter) => hideFilters.includes(filter)) && (
              <>
                <h1 className="font-semibold text-[17px]">Filter by</h1>
                <div className="flex flex-col justify-center gap-3 my-3 w-full">
                  {!hideFilters.includes(1) && (
                    <button
                      onClick={() =>
                        setFilterData((prev) => ({
                          ...prev,
                          filter: "Commitment",
                          value: "Full Time",
                        }))
                      }
                      className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                        filterData.value?.includes("Full Time")
                          ? "bg-[#73C7A9] text-white"
                          : ""
                      }`}
                    >
                      Full Time Commitment
                    </button>
                  )}
                  {!hideFilters.includes(2) && (
                    <button
                      onClick={() =>
                        setFilterData((prev) => ({
                          ...prev,
                          filter: "Commitment",
                          value: "Part Time",
                        }))
                      }
                      className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                        filterData.value?.includes("Part Time")
                          ? "bg-[#73C7A9] text-white"
                          : ""
                      }`}
                    >
                      Part Time Commitment
                    </button>
                  )}
                  {!hideFilters.includes(3) && (
                    <button
                      onClick={() =>
                        setFilterData((prev) => ({
                          ...prev,
                          filter: "weekendAvailiblity",
                          value: "true",
                        }))
                      }
                      className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                        filterData.value?.includes("true")
                          ? "bg-[#73C7A9] text-white"
                          : ""
                      }`}
                    >
                      Weekend Availability
                    </button>
                  )}
                  {!hideFilters.includes(4) && (
                    <button
                      onClick={() =>
                        setFilterData((prev) => ({
                          ...prev,
                          filter: "weekendAvailiblity",
                          value: "false",
                        }))
                      }
                      className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                        filterData.value?.includes("false")
                          ? "bg-[#73C7A9] text-white"
                          : ""
                      }`}
                    >
                      No Weekend Availability
                    </button>
                  )}
                </div>
              </>
            )}
            <h1 className="font-semibold text-[17px] mb-3">Sort by</h1>
            <div className="flex flex-col justify-center gap-3 w-full">
              {!hideFilters.includes(5) && (
                <button
                  onClick={() =>
                    setFilterData((prev) => ({
                      ...prev,
                      sort: "latestFirst",
                      sortValue: "",
                    }))
                  }
                  className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                    filterData.sort.includes("latestFirst")
                      ? "bg-[#73C7A9] text-white"
                      : ""
                  }`}
                >
                  Newest First
                </button>
              )}
              {!hideFilters.includes(6) && (
                <button
                  onClick={() =>
                    setFilterData((prev) => ({
                      ...prev,
                      sort: "oldestFirst",
                      sortValue: "",
                    }))
                  }
                  className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                    filterData.sort.includes("oldestFirst")
                      ? "bg-[#73C7A9] text-white"
                      : ""
                  }`}
                >
                  Oldest First
                </button>
              )}
              {!hideFilters.includes(7) && (
                <button
                  onClick={() =>
                    setFilterData((prev) => ({
                      ...prev,
                      sort: "rating",
                      sortValue: "-1",
                    }))
                  }
                  className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                    filterData.sortValue == "-1"
                      ? "bg-[#73C7A9] text-white"
                      : ""
                  }`}
                >
                  Rating Highest
                </button>
              )}
              {!hideFilters.includes(8) && (
                <button
                  onClick={() =>
                    setFilterData((prev) => ({
                      ...prev,
                      sort: "rating",
                      sortValue: "1",
                    }))
                  }
                  className={`text-[13px] text-gray-500 border border-gray-300 rounded-[30px] py-2 px-4 focus:bg-[#73C7A9] focus:text-white focus:border-none ${
                    filterData.sortValue == "1" ? "bg-[#73C7A9] text-white" : ""
                  }`}
                >
                  Rating Lowest
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {!hideRecentHistory && showHistory && searchHistory.length > 0 && (
        <div
          className="search-history p-3 mb-3 absolute w-full z-10"
          style={{
            boxShadow: "0px 0px 12px darkgray",
            background: "#fff",
            borderRadius: "10px",
            top: "65px",
          }}
        >
          <p>Search History:</p>
          <ul>
            {searchHistory.slice(0, 5).map((query, index) => (
              <li
                className="cursor-auto m-2 d-flex"
                key={index}
                onClick={() => handleSearchFromHistory(query)}
              >
                <img src={recent} alt="Recent search" className="mr-2" />
                {query}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default SearchFilter;
