import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../main/Staff.css";
import { useParams } from "react-router-dom";
import Staff from "./staff";

const SharedPost = () => {
  const { id: PostId } = useParams();

  return (
    <>
      <Staff sharedPostId={PostId} />
    </>
  );
};

export default SharedPost;
