import React, { useEffect, useState } from "react";
import FileBrowser from "../../theme_1/staffSignUp/components/fileBrowser";
import ErrorState from "../../theme_1/staffSignUp/components/errorState";
import DeleteIcon from "@material-ui/icons/Delete";
import videoupload from "../../assets/images/videoUpload.svg";
import download from "../../assets/images/download.svg";
import Pending from "../../modals/pending";
import moment from "moment";
import { callApi } from "../../../redux/action/action";
import { axiosInstance } from "../../../config/axiosConfig";
import edit_note from "../../assets/images/edit_note.svg";
import Modal from "../../common/modal";
import { showSuccessToast } from "../../common/toastMeassage/ToastMeassage";
import useLoader from "../../modals/hooks/useLoader";
import { useDispatch, useSelector } from "react-redux";

const ResumeDetail = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.data?.profile);
  const [uploadRes, setUploadRes] = useState(profile);
  const [profileId, setProfileId] = useState();
  const { showLoader, hideLoader } = useLoader();
  const [showPending, setShowPending] = useState("none");

  const fetchData = async (id) => {
    try{
      showLoader()
      const res = await axiosInstance.get(`v1/LHS/staff/getById/${profileId || id}`);
      if (res.data?.data) {
        setUploadRes(res.data?.data);
        setProfileId(res.data?.data?._id)
        dispatch({ type: "PROFILE_DATA", payload: res?.data?.data });
      }
    }catch (err) {

    }
    finally{
      hideLoader()
    }
  };
  useEffect(() => {
    fetchData(profile?._id);
  }, [profile?._id]);

  const deleteFile = async () => {
    setShowPending("block");
    showLoader();
    try {
      const response = await axiosInstance.put(
        `v1/LHS/staff/update/${profile?._id}`,
        {
          resume: {
            lastUpdated: "",
            link: "",
          },
        }
      );

      if (response.data) {
        fetchData(profile?.id)
        showSuccessToast("Resume deleted successfully");
      } else {
      }
    } catch (error) {
      console.error("File delete error:", error);
    } finally {
      setShowPending("none");
      hideLoader();
    }
  };

  const openPdf = () => {
    if (fileLink) {
      window.open(fileLink, "_blank");
    }
  };

  const uploadStaff = async (file) => {
    showLoader();
    let uploadFile = "";
    if (file) {
      try {
        const formData = new FormData();
        formData.append("files", file);
        let url = "v2/LHS/Upload/uploadFiles";
        const response = await axiosInstance.post(url, formData);
        uploadFile = response.data.data[0];
        setFileLink(uploadFile);
      } catch (err) {
      } finally {
      }
    }
    if (uploadFile) {
      const currentDateTime = new Date();
      const iso8601String = currentDateTime.toISOString();
      const data = {
        resume: {
          lastUpdated: iso8601String,
          link: uploadFile,
        },
      };
      try {
        const response = await axiosInstance.put(
          `v1/LHS/staff/update/${profile?._id}`,
          data
        );
        if (response.data.data.resume) {
          showSuccessToast("Resume updated successfully");
          fetchData(profile?.id)
        }
      } catch (error) {
        console.error("File upload error:", error);
      } finally {
        hideLoader();
      }
    }
  };

  const currentDate = new Date();

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return (
    <div className="t2_sp_work">
      <div className="t2_sp_work_title">
        {uploadRes?.resume?.link ? "Resume Details" : "Add your resume"}
      </div>
      {uploadRes?.resume?.link ? (
        <div className="">
          <div class="w-fit flex border border-solid gap-20 border-opacity-10 p-3 rounded-lg">
            <div className="flex flex-col gap-2">
              <div
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  window.location.href = uploadRes?.resume?.link;
                }}
              >
                {uploadRes?.name}.
                {
                  uploadRes?.resume?.link.split(".")[
                    uploadRes?.resume?.link.split(".").length - 1
                  ]
                }
              </div>
              <h6
                style={{
                  display: uploadRes?.resume?.link === "" ? "none" : "inline",
                }}
                className="text-sm"
              >
                {formattedDate}
              </h6>
            </div>

            <div class="flex justify-between items-center gap-2">
              <div className="flex p-1 h-full cursor-pointer relative">
                <input
                  type="file"
                  accept=".pdf, .docx"
                  className="opacity-0 absolute w-5 h-full cursor-pointer"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      uploadStaff(file);
                    }
                  }}
                />
                <img
                  alt="icon.svg"
                  src={edit_note}
                  width="20px"
                  style={{ cursor: "pointer" }}
                  onClick={openPdf}
                />
              </div>
              <span
                style={{
                  marginLeft: "0px",
                  cursor: "pointer",
                }}
                onClick={() => deleteFile()}
              >
                <DeleteIcon />
              </span>
              {/* <button
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href = profile?.resume?.link;
                }}
              >
                <img src={download} />
              </button> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col w-3/5 gap-4 border !border-dashed !border-[#000000] p-5">
          <div className="flex justify-center">
            <img
              src={videoupload}
              alt=""
              onChange={(e) => handleUpload(e.target.files)}
              className="absolute"
            />
            <input
              type="file"
              accept=".pdf, .docx"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  uploadStaff(file);
                }
              }}
              className="opacity-0 w-[100px]"
            />
          </div>
          <div className="mt-3 text-center">Drop your pdf, docx here</div>
          <h2 className="flex justify-center">OR</h2>
          <div className="relative">
            <div className="flex justify-center" style={{ cursor: "pointer" }}>
              <button className="w-[192px] h-[44px] border border-black rounded-[100px] font-semibold text-sm text-[#333333] cursor-pointer hover:!cursor-pointer absolute">
                Select resume from desktop
              </button>
              <input
                type="file"
                accept=".pdf, .docx"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    uploadStaff(file);
                  }
                }}
                className="opacity-0 w-auto h-10 cursor-pointer"
              />
            </div>
          </div>
        </div>
      )}
      {/* <Pending display={showPending} showImg={showImg} title={title} /> */}
    </div>
  );
};

export default ResumeDetail;
