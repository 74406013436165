import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import SubHeader from "./components/subHeader";
import ErrorState from "./components/errorState";
// import img from "../../assets/images/2-4.png";
import img from "../../assets/images/2-4.svg.svg";
import place from "../../assets/images/place.svg";
import rightArrow from "../../assets/images/right_arrow_slim.svg";
import deleteBox from "../../assets/images/deleteDark.png";
import "./staffSignUp_2.css";
import user from "../../assets/images/user.png";
import { stateOfUs } from "../../../constants/otherConstans";
import { decrementStep } from "../../../redux/action/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileBrowser from "./components/fileBrowser";
import Loader from "../../modals/Loader";
import Modal from "../../common/modal";
import CardEditDelete from "../../containers/commonComponent/Card";
import classNames from "classnames";
import { HeaderComp } from "./headerComp";

const ToggleExpItem = (props) => {
  const [clicked, setClicked] = useState(false);

  const toggleExp = (e) => {
    props.toggleExp(props.id);
    setClicked(!clicked);
  };

  const baseClassName = "ssu1_nearLocItem1";
  const selectedClassName = "selectedExp";
  const otherExpClassName = "ssu2_otherExp_item";

  const classNames = [baseClassName];

  if (props.exp === "Other" && props.display === "none") {
    classNames.push(otherExpClassName);
  } else if (props.exp === "Other") {
    classNames.push(baseClassName, otherExpClassName);
  }

  if (props.selectedExp === props.exp && clicked) {
    classNames.push(selectedClassName);
  }

  return (
    <button className={classNames.join(" ")} onClick={toggleExp}>
      {props.exp}
    </button>
  );
};

const StaffSignUp_2 = (props) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [modallicOpens, setModallicOpens] = useState(false);
  const [modalinsOpens, setModalinsOpens] = useState(false);
  const [modaldegOpens, setModaldegOpens] = useState(false);
  const [modalcerOpens, setModalcerOpens] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  // const setShowOtherExp = () => { };
  const [redo, setRedo] = useState([]);
  const [exp, setExp] = useState("");
  const [_exps, setExps] = useState([
    "Acute Care Hospitals",
    "Urgent Care Centers",
    "Rehabilitation Centers",
    "Nursing Homes",
    "Other Long-term Care Facilities",
    "Specialized Outpatient Services",
  ]);
  const [nurseLicenceState, setNurseLicenceState] = useState([]);
  const [curState, setCurState] = useState(false);
  const [stateVal, setStateVal] = useState("");
  const [isNotStateSelected, setIsNotStateSelected] = useState(false);
  // const [stateOfUs, setStateOfUs] = useState([]);
  const [error, setError] = useState({
    medical: "none",
    nursingLicence: "none",
    licenseData: "none",
    selectedState: "none",
  });
  const [formData, setFormData] = useState({
    medicalSettings: "",
    nursingLicense: [],
    certifications: [],
    education: {
      degree: "",
      college: "",
      receivedOn: "",
    },
    experiencedIn: "",
  });

  useEffect(() => {
    if (props?.formData?.nursing) {
      setFormData(props.formData?.nursing);
      if (_exps.includes(props.formData?.nursing?.medicalSettings)) {
        setExp(props.formData?.nursing?.medicalSettings);
      } else {
        setShowOtherExp(true);
        setExp(props.formData?.nursing?.medicalSettings);
      }
    }
  }, [props?.formData?.nursing]);

  const openlicModal = () => {
    setModallicOpens(true);
  };
  const closelicmodal = () => {
    setModallicOpens(false);
  };

  const openinsModal = () => {
    setModalinsOpens(true);
  };
  const closeinsmodal = () => {
    setModalinsOpens(false);
  };

  const opendegModal = () => {
    setModaldegOpens(true);
  };
  const closedegmodal = () => {
    setModaldegOpens(false);
  };

  const opencerModal = () => {
    setModalcerOpens(true);
  };
  const closecermodal = () => {
    setModalcerOpens(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [licenseIndex, setLicenseIndex] = useState(false);
  const [certificaionIndex, setCertificationIndex] = useState(null);
  const [licenseFormData, setLicenseFormData] = useState({});
  const [degreeFormData, setDegreeFormData] = useState({});
  const [certificationFormData, setCertificationFormData] = useState([]);

  const saveLicenseData = () => {
    const { name, number, expirationDate, image } = licenseFormData || {};
    if (!name || !number || !expirationDate || !image) {
      showErrorToast("All fields are required");
      return;
    }
    setFormData((prevFormData) => {
      const updatedNursingLicense = [...prevFormData.nursingLicense];

      updatedNursingLicense[licenseIndex] = {
        ...updatedNursingLicense[licenseIndex],
        name,
        number,
        expirationDate,
        image,
      };

      return {
        ...prevFormData,
        nursingLicense: updatedNursingLicense,
      };
    });

    setLicenseFormData({});
    closelicmodal();
  };

  const saveDegreeData = () => {
    const { degree, college, receivedOn } = degreeFormData || {};
    if (!degree || !college || !receivedOn) {
      showErrorToast("All fields are required");
      return;
    }
    setFormData({
      ...formData,
      education: {
        degree,
        college,
        receivedOn,
      },
    });
    setDegreeFormData({});
    closedegmodal();
  };

  const saveCertificationData = () => {
    const { name, certifyingAuthority, receivedOn, expirationDate } =
      certificationFormData || {};
    if (!name || !certifyingAuthority || !receivedOn || !expirationDate) {
      showErrorToast("All fields are required");
      return;
    }
    if (certificaionIndex !== null) {
      setFormData((prevFormData) => {
        const updatedCertificationsLicense = [...prevFormData.certifications];

        updatedCertificationsLicense[certificaionIndex] = {
          ...updatedCertificationsLicense[certificaionIndex],
          name,
          certifyingAuthority,
          receivedOn,
          expirationDate,
        };

        return {
          ...prevFormData,
          certifications: updatedCertificationsLicense,
        };
      });
      setCertificationIndex(null);
    } else {
      setFormData({
        ...formData,
        certifications: [
          ...formData.certifications,
          {
            name,
            certifyingAuthority,
            receivedOn,
            expirationDate,
          },
        ],
      });
    }

    setCertificationFormData([]);
    closecermodal();
  };

  const nurseLicence = [];
  // useEffect(() => {
  //   convertObjToArr(nurseLicence);
  //   setRedo([]);
  //   setExp("");
  //   setNurseLicenceState("selectedStates");
  //   // setStateOfUs(stateOfUs.map((state, num) => ({ name: state, num: num })));
  // }, []);

  useEffect(() => {
    // convertObjToArr(nurseLicence);
    // setExp("");
    // setNurseLicenceState("selectedStates");
    setError({
      medical: "none",
      nursingLicence: "none",
      licenseData: "none",
    });
  }, []);

  const convertObjToArr = (obj) => {
    const arr = [];
    for (let state in obj) {
      obj[state].state = state;
      arr.push(obj[state]);
    }
    setNurseLicenceState(arr);
  };

  const toggleExp = (selectedExp) => {
    setExp(selectedExp);
    setFormData((prev) => ({ ...prev, medicalSettings: selectedExp }));
    setShowOtherExp(selectedExp === "Other" ? !showOtherExp : showOtherExp);
  };

  const [showOtherExp, setShowOtherExp] = useState(false);
  const toggleExps = () => {
    // Toggle the visibility of the input field
    setExp("");
    setFormData((prev) => ({ ...prev, medicalSettings: "" }));
    setShowOtherExp((prev) => !prev);
  };

  const setOtherExp = (e) => {
    const errorCopy = { ...error };
    errorCopy.medical = "none";
    if (e.target.value.length < 51) {
      setExp(e.target.value);
      setFormData((prev) => ({ ...prev, medicalSettings: e.target.value }));
      if (e.target.value.length > 0) setError(errorCopy);
    }
  };

  const addState = (e) => {
    const state = e.target.value;
    if (state !== "") {
      const errorCopy = { ...error };
      errorCopy.nursingLicence = "none";
      setError(errorCopy);
    }
    const num = stateOfUs.findIndex((item) => item.name === state);
  };

  // const setLicence = (newLicence) => {
  //   const nurseLicenceCopy = [...nurseLicenceState];
  //   nurseLicenceCopy[curState].licences.push({
  //     image: newLicence.file,
  //     imgUrl: newLicence.imgUrl,
  //     name: newLicence.lname,
  //     number: newLicence.lnum,
  //     expDate: newLicence.expDate,
  //     state: curState,
  //     num: nurseLicenceCopy[curState].licences.length,
  //   });
  //   // Dispatch an action to update the state with the new data
  //   dispatch({ type: SET_LICENCE, data: nurseLicenceCopy });
  // };

  const continueToNext = () => {
    let nursingMissing = false;
    if (
      !formData?.nursingLicense[0]?.state ||
      !formData?.nursingLicense[0]?.name
    ) {
      nursingMissing = true;
    }
    formData?.nursingLicense?.map((item, index) => {
      if (!item?.state || !item?.name) {
        nursingMissing = true;
      }
    });
    if (nursingMissing) {
      showErrorToast("Please fill nursing license");
      return;
    }
    if (!formData?.medicalSettings) {
      showErrorToast("Please select medical settings");
      return;
    }

    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      props.onFormSubmit({ nursing: formData });
    }, 1000);
  };

  const handleBack = () => {
    decrementStep();
  };
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const handleStateSelect = (selectedState) => {
    setFormData((prev) => ({
      ...prev,
      nursingLicense: [
        ...prev.nursingLicense,
        {
          expirationDate: "",
          image: "",
          name: "",
          number: "",
          state: selectedState,
        },
        // Keep the rest of the nursingLicense array unchanged
      ],
    }));
    // setIsNotStateSelected(selectedState !== "");
    // setError({ ...error, nursingLicence: selectedState ? "none" : "block" });
    setDropdownOpen(false);
  };

  const [display, setDisplay] = useState(false);
  const handleClick = () => {
    setDisplay(true);
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="d-flex h-screen overflow-hidden"
      style={{ backgroundColor: "#73C7A9" }}
    >
      <div
        className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
        style={{ padding: "0", maxWidth: 720, width: "100%" }}
      >
        <div className="ssu_container py-4 px-5 max-h-[90vh] h-auto">
          <HeaderComp />
          <SubHeader
            num="2"
            title="Credentials"
            next="/staffSignUp_3"
            prev="/staffSignUp_1"
            img={img}
            onBack={handleBack}
          />
          <div className="ssu_body mt-4 w-auto">
            <div className="ssu_txt1 text-xl mt-4 mb-0">
              Where do you have an active nursing license?
            </div>
            {formData?.nursingLicense?.length > 0 &&
              formData?.nursingLicense?.map((item, index) => (
                <div key={index} className="pt-3 w-3/4 mx-auto">
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                      {/* <img src={rightArrow} className="w-4 h-4" /> */}
                      {item?.state}
                    </div>
                    <img
                      src={deleteBox}
                      onClick={() => {
                        setFormData((prev) => {
                          const newData = [...prev.nursingLicense];
                          newData.splice(index, 1);
                          return {
                            ...prev,
                            nursingLicense: newData,
                          };
                        });
                      }}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="text-center text-base mt-4">
                    Please provide your nursing license details
                  </div>
                  {item?.name ? (
                    <>
                      <CardEditDelete
                        className="mt-3 mx-auto w-full"
                        onEdit={() => {
                          setLicenseFormData(formData?.nursingLicense?.[index]);
                          setLicenseIndex(index);
                          openlicModal();
                        }}
                        onDelete={() => {
                          setLicenseFormData([]);
                          setFormData((prev) => {
                            const newData = [...prev.nursingLicense];
                            newData.splice(index, 1);
                            return {
                              ...prev,
                              nursingLicense: newData,
                            };
                          });
                        }}
                      >
                        <div className="flex flex-col gap-3">
                          <div className="flex justify-between">
                            <div>
                              <p>Name</p>
                              <p className="text-lg">{item?.name}</p>
                            </div>
                            <div>
                              <img
                                src={item?.image}
                                className="w-14 h-14 mr-5 rounded-xl"
                              />
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div>
                              <p>Number</p>
                              <p className="text-lg">{item?.number}</p>
                            </div>
                            <div>
                              <p>Expiration Date</p>
                              <p className="text-lg">
                                {new Date(
                                  item?.expirationDate
                                ).toLocaleDateString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      </CardEditDelete>
                    </>
                  ) : (
                    <div
                      className="text-center font-semibold text-base text-[#73C7A9] mt-2 cursor-pointer"
                      onClick={() => {
                        setLicenseIndex(index);
                        setLicenseFormData({
                          name: "",
                          number: "",
                          expirationDate: null,
                          image: "",
                        });

                        openlicModal();
                      }}
                    >
                      + Add License
                    </div>
                  )}
                  <hr className="mt-4" />
                </div>
              ))}

            <div
              className="ssu2_modal1_input"
              style={{ maxWidth: "800px", width: "70%", margin: "auto" }}
            >
              <div className="input_left_icon">
                <img alt="img" src={place} width="16px" height="20px" />
              </div>
              {/* <select
                className="ssu2_modal3_select mb-1 pl-10"
                name="option"
                onChange={handleStateSelect}
                value={stateVal}
              >
                <option value="" disabled hidden>
                  Select a state to Add Nursing License
                </option>

                {stateOfUs.map((state) => {
                  return (
                    <option key={state.num} value={state._id}>
                      {state}
                    </option>
                  );
                })}
              </select> */}
              <div
                className="ssu2_modal1_input ssu2_modal3_selectBox"
                ref={dropdownRef}
              >
                <div className="input_left_icon">
                  <img alt="img" src={place} width="16px" height="20px" />
                </div>
                <div
                  className=" ssu2_modal3_select "
                  onClick={() => setDropdownOpen(!DropdownOpen)}
                >
                  {formData.healthCareLocation ||
                    " Select a state to Add Nursing License"}
                </div>
                {DropdownOpen && (
                  <div className="custom-dropdown-contentnew text-black overflow-auto h-96">
                    {stateOfUs.map((state) => (
                      <div
                        key={state}
                        className="custom-dropdown-item"
                        onClick={() => handleStateSelect(state)}
                        onMouseEnter={(e) => e.target.classList.add("hover")}
                        onMouseLeave={(e) => e.target.classList.remove("hover")}
                      >
                        {state}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {stateVal == "" && isNotStateSelected ? (
                <ErrorState
                  show={isNotStateSelected}
                  name="Please select a state"
                />
              ) : (
                ""
              )}

              <Modal
                isOpen={modallicOpens}
                onClose={closelicmodal}
                className={"md:w-2/5 md:mt-0"}
              >
                <div className=" bg-white">
                  <div className="flex items-center">
                    <div onClick={closelicmodal}>
                      <svg
                        width="24"
                        height="20"
                        viewBox="0 0 30 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30 10.927H6.23656L14.8599 2.30368L12.5562 0L0 12.5562L12.5562 25.1124L14.8599 22.8087L6.23656 14.1854H30V10.927Z"
                          fill="#333333"
                        />
                      </svg>
                    </div>
                    <div className="m-auto text-xl">Add License</div>
                  </div>
                  <div className="w-full h-0  border-b border-[#eaeaea] border-solid border-0.20 my-4"></div>
                  <div className="ssu2_modal1_input">
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <input
                        className="ssu2_modal3_select pl-[20px]"
                        placeholder="License Name"
                        onChange={(e) =>
                          setLicenseFormData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        value={licenseFormData?.name}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="ssu2_modal1_input">
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <input
                        className="ssu2_modal3_select pl-[20px]"
                        placeholder="License Number"
                        type="text"
                        onChange={(e) =>
                          setLicenseFormData((prev) => ({
                            ...prev,
                            number: e.target.value,
                          }))
                        }
                        value={licenseFormData?.number}
                      />
                    </div>
                  </div>
                  <div className="ssu2_modal1_input">
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <DatePicker
                        selected={licenseFormData?.expirationDate}
                        onChange={(e) => {
                          setLicenseFormData((prev) => ({
                            ...prev,
                            expirationDate: e,
                          }));
                        }}
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        className="ssu2_modal3_select pl-[20px]"
                        placeholderText="Expiration Date"
                      />
                    </div>
                  </div>

                  <div className="ssu2_modal1_input">
                    <div
                      className="ssu2_modal1_text2 text-center"
                      style={{ marginBottom: "-10px" }}
                    >
                      {" "}
                      Please upload your license
                    </div>
                  </div>
                  <>
                    {licenseFormData?.image && (
                      <div className="text-center mt-3 flex justify-center">
                        <img
                          src={licenseFormData?.image}
                          alt="Uploaded License"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100px",
                            borderRadius: "12px",
                          }}
                        />
                      </div>
                    )}
                    <div
                      className="flex justify-center relative mt-[15px]"
                      style={{ cursor: "pointer" }}
                    >
                      <button className="w-full h-[44px] border border-[#73C7A9] rounded-[100px] mt-3 font-semibold text-sm text-[#73C7A9] cursor-pointer hover:!cursor-pointer absolute">
                        UPLOAD YOUR LICENSE
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ height: "44px !important" }}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onloadend = () =>
                              setLicenseFormData((prev) => ({
                                ...prev,
                                image: reader.result,
                              }));
                            reader.readAsDataURL(file);
                          }
                        }}
                        className="opacity-0 mt-3 w-auto !h-12 cursor-pointer"
                      />
                    </div>
                    <div
                      className="ssu2_modal1_text2 text-center"
                      style={{ marginTop: "1px" }}
                    >
                      {" "}
                      Please upload only png, jpg, jpeg image
                    </div>
                    <button
                      onClick={saveLicenseData}
                      className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-full h-11 mt-[10px]"
                    >
                      Save
                    </button>
                  </>
                </div>
              </Modal>

              {!nurseLicenceState.length && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></div>
              )}
            </div>

            {/* <div className="text-center text-base mt-4">Do you have Liability Insurance?</div>
            <div className="text-center font-semibold text-base text-[#73C7A9] mt-2 cursor-pointer" onClick={openinsModal}>
              + Add Liability Insurance
            </div>
            <Modal
              isOpen={modalinsOpens}
              onClose={closeinsmodal}
              className={"md:w-2/5 md:mt-0"}
            >
              <div className=" bg-white">
                <div className="flex items-center">
                  <div onClick={closeinsmodal}>
                    <svg
                      width="24"
                      height="20"
                      viewBox="0 0 30 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 10.927H6.23656L14.8599 2.30368L12.5562 0L0 12.5562L12.5562 25.1124L14.8599 22.8087L6.23656 14.1854H30V10.927Z"
                        fill="#73C7A9"
                      />
                    </svg>
                  </div>
                  <div className="m-auto text-xl">Add Liability Insurance</div>
                </div>
                <div className="w-full h-0  border-b border-[#eaeaea] border-solid border-0.20 my-4"></div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={user} />
                    </div>
                    <input
                      className="ssu2_modal3_select pl-[20px]"
                      placeholder="Insurance provider"
                      type="text"
                    />
                  </div>
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={user} />
                    </div>
                    <input
                      className="ssu2_modal3_select pl-[20px]"
                      placeholder="Policy Number"
                      type="text"
                    />
                  </div>
                </div>
                <button className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-full h-11 mt-[25px]">
                  Save
                </button>
              </div>
            </Modal> */}
            <div className="text-center text-base mt-3">
              What’s your highest education level?
            </div>
            {/* <Education /> */}
            {formData?.education?.degree ? (
              <CardEditDelete
                className="mt-3 mx-auto w-3/4"
                onEdit={() => {
                  setDegreeFormData(formData?.education);
                  opendegModal();
                }}
                onDelete={() => {
                  setDegreeFormData([]);
                  setFormData((prev) => ({
                    ...prev,
                    education: {},
                  }));
                }}
              >
                <div className="flex flex-col gap-3">
                  <div>
                    <p>Degree</p>
                    <p className="text-lg">{formData?.education?.degree}</p>
                  </div>
                  <div>
                    <p>College/University</p>
                    <p className="text-lg">{formData?.education?.college}</p>
                  </div>
                  <div>
                    <p>Received Date</p>
                    <p className="text-lg">
                      {new Date(
                        formData?.education?.receivedOn
                      ).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </div>
              </CardEditDelete>
            ) : (
              <div
                className="text-center font-semibold text-base text-[#73C7A9] mt-2 cursor-pointer"
                onClick={opendegModal}
              >
                + Add Degree
              </div>
            )}
            <Modal
              isOpen={modaldegOpens}
              onClose={closedegmodal}
              className={"md:w-2/5 md:mt-0"}
            >
              <div className=" bg-white">
                <div className="flex items-center">
                  <div onClick={closedegmodal}>
                    <svg
                      width="24"
                      height="20"
                      viewBox="0 0 30 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 10.927H6.23656L14.8599 2.30368L12.5562 0L0 12.5562L12.5562 25.1124L14.8599 22.8087L6.23656 14.1854H30V10.927Z"
                        fill="#000000"
                      />
                    </svg>
                  </div>
                  <div className="m-auto text-xl">Add Degree</div>
                </div>
                <div className="w-full h-0  border-b border-[#eaeaea] border-solid border-0.20 my-4"></div>
                <div className="ssu2_modal1_text2">
                  {" "}
                  What's your highest education level?
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <input
                      className="ssu2_modal3_select pl-[20px]"
                      placeholder="Degree"
                      type="text"
                      onChange={(e) =>
                        setDegreeFormData((prev) => ({
                          ...prev,
                          degree: e.target.value,
                        }))
                      }
                      value={degreeFormData?.degree}
                    />
                  </div>
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <input
                      className="ssu2_modal3_select pl-[20px]"
                      placeholder="College/University"
                      type="text"
                      onChange={(e) =>
                        setDegreeFormData((prev) => ({
                          ...prev,
                          college: e.target.value,
                        }))
                      }
                      value={degreeFormData?.college}
                    />
                  </div>
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <DatePicker
                      selected={degreeFormData?.receivedOn}
                      onChange={(e) => {
                        setDegreeFormData((prev) => ({
                          ...prev,
                          receivedOn: e,
                        }));
                      }}
                      maxDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      className="ssu2_modal3_select pl-[20px] custom-datepicker "
                      placeholderText="Received Date"
                      calendarClassName="custom-datepicker"
                    />
                  </div>
                </div>
                <div
                  className="ssu2_modal1_text2"
                  style={{ marginBottom: "-10px" }}
                >
                  {" "}
                  Note :- Incase if you don't remember the exact date select
                  first day of the month.
                </div>

                <button
                  onClick={saveDegreeData}
                  className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-full h-11 mt-[25px]"
                >
                  Save
                </button>
              </div>
            </Modal>
            <div className="text-center text-base mt-4">
              What certifications do you have?
            </div>
            {/* <Certification /> */}
            {formData?.certifications?.[0]?.name &&
              formData?.certifications?.map((item, index) => (
                <CardEditDelete
                  key={index}
                  className="mt-3 mx-auto w-3/4"
                  onEdit={() => {
                    setCertificationIndex(index);
                    setCertificationFormData(formData?.certifications?.[index]);
                    opencerModal();
                  }}
                  onDelete={() => {
                    setCertificationFormData([]);
                    setFormData((prev) => {
                      const newCertifications = [...prev.certifications];
                      newCertifications.splice(index, 1);
                      return {
                        ...prev,
                        certifications: newCertifications,
                      };
                    });
                  }}
                >
                  <div className="flex flex-col gap-3">
                    <div>
                      <p>Certificate</p>
                      <p className="text-lg">{item?.name}</p>
                    </div>
                    <div>
                      <p>Certifying Authority</p>
                      <p className="text-lg">{item?.certifyingAuthority}</p>
                    </div>
                    <div>
                      <p>Received Date </p>
                      <p className="text-lg">
                        {new Date(item?.receivedOn).toLocaleDateString(
                          "en-US",
                          { month: "2-digit", day: "2-digit", year: "numeric" }
                        )}
                      </p>
                    </div>
                    <div>
                      <p>Expiration Date</p>
                      <p className="text-lg">
                        {new Date(item?.expirationDate).toLocaleDateString(
                          "en-US",
                          { month: "2-digit", day: "2-digit", year: "numeric" }
                        )}
                      </p>
                    </div>
                  </div>
                </CardEditDelete>
              ))}
            <div
              className="text-center font-semibold text-base text-[#73C7A9] mt-2 cursor-pointer"
              onClick={opencerModal}
            >
              + Add another certification
            </div>
            <Modal
              isOpen={modalcerOpens}
              onClose={closecermodal}
              className={"md:w-2/5 md:mt-0"}
            >
              <div className=" bg-white rounded-lg">
                <div className="flex items-center">
                  <div onClick={closecermodal}>
                    <svg
                      width="24"
                      height="20"
                      viewBox="0 0 30 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 10.927H6.23656L14.8599 2.30368L12.5562 0L0 12.5562L12.5562 25.1124L14.8599 22.8087L6.23656 14.1854H30V10.927Z"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                  <div className="m-auto text-xl">Add Certifications</div>
                </div>
                <div className="w-full h-0  border-b border-[#eaeaea] border-solid border-0.20 my-4"></div>
                <div className="ssu2_modal1_text2">
                  {" "}
                  What Certifications do you have?
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <input
                      className="ssu2_modal3_select pl-[20px]"
                      placeholder="Certification"
                      type="text"
                      onChange={(e) =>
                        setCertificationFormData((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      value={certificationFormData?.name}
                    />
                  </div>
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <input
                      className="ssu2_modal3_select pl-[20px]"
                      placeholder="Certifying Authority"
                      type="text"
                      onChange={(e) =>
                        setCertificationFormData((prev) => ({
                          ...prev,
                          certifyingAuthority: e.target.value,
                        }))
                      }
                      value={certificationFormData?.certifyingAuthority}
                    />
                  </div>
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <DatePicker
                      selected={certificationFormData?.receivedOn}
                      onChange={(e) => {
                        setCertificationFormData((prev) => ({
                          ...prev,
                          receivedOn: e,
                        }));
                      }}
                      maxDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      className="ssu2_modal3_select pl-[20px]"
                      placeholderText="Received Date"
                    />
                  </div>
                </div>
                <div className="ssu2_modal1_input">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <DatePicker
                      selected={certificationFormData?.expirationDate}
                      onChange={(e) => {
                        setCertificationFormData((prev) => ({
                          ...prev,
                          expirationDate: e,
                        }));
                      }}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      className="ssu2_modal3_select pl-[20px]"
                      placeholderText="Expiration Date"
                    />
                  </div>
                </div>

                <button
                  onClick={saveCertificationData}
                  className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-full h-11 mt-[25px]"
                >
                  Save
                </button>
              </div>
            </Modal>
            <div className="text-base text-center mt-4 mb-2">
              In which medical setting do you have experience in?
            </div>
            <div className="row ssu1_locs">
              {_exps.map((item) => (
                <button
                  className={classNames(
                    { selectedExp: item != exp },
                    "ssu1_nearLocItem1"
                  )}
                  onClick={() => {
                    toggleExp(item);
                    setShowOtherExp(false);
                  }}
                >
                  {item}
                </button>
              ))}
              <div className="ssu2_otherItem">
                <button
                  className={classNames("ssu1_nearLocItem1", {
                    selectedExp: !showOtherExp,
                  })}
                  onClick={toggleExps}
                >
                  Other
                </button>
              </div>
              {showOtherExp === true ? (
                <input
                  type="text"
                  value={exp}
                  className="ssu2_otherExp ssu2_modal1_input ssu2_modal3_select d-flex"
                  placeholder="Please specify"
                  style={{ display: showOtherExp ? "block" : "none" }}
                  onChange={setOtherExp}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") continueToNext();
                  }}
                />
              ) : (
                ""
              )}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="ssu_bottom">
              <div className="ssu_button" onClick={continueToNext}>
                {showLoader ? <Loader /> : "CONTINUE"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffSignUp_2;
