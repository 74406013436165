import React, { useEffect, useRef, useState } from "react";
import fllipsImag from "../../../assets/images/UserEllipse.png";
import Nav from "../../../theme_1/Nav";
import { axiosInstance } from "../../../../config/axiosConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../common/toastMeassage/ToastMeassage";
import { ToastContainer } from "react-toastify";
import StarIcon from "@material-ui/icons/Star";
import { Link } from "react-router-dom";
import useLoader from "../../../modals/hooks/useLoader";
import Loader from "../../../modals/Loader";
import RecommendedForYou from "../../../containers/commonComponent/RecommendedForYou";
import RightSideBar from "../../../containers/commonComponent/rightSideBar";
import JobsHeader from "../../../theme_1/staffSignUp/JobsHeader";
import SearchFilter from "../../../containers/commonComponent/SearchFilter";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../../common/modal";
import JobCard from "../../../containers/commonComponent/JobCard";
import MyJobDetails from "./MyJobDetails";
import InfiniteScroll from "react-infinite-scroll-component";

const StaffMyJob = ({
  apiUrl = "getAllJobList",
  loginModal = false,
  setSelectedJobId = "0",
}) => {
  const [allJobs, setAllJobs] = useState([]);
  const { showLoader, hideLoader } = useLoader();
  const [hasMore, setHasMore] = useState(false);
  const profile = useSelector((state) => state.data.profile);
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalCount] = useState(null);
  const [jobDetailsPage, setJobDetailsPage] = useState(false);
  const [windowScrollY, setWindowScrollY] = useState(0);
  const [jobDetails, setJobDetails] = useState({});
  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowHistory(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleFilterChange = (data) => {
    const filter = {
      ...data,
      search: data?.search == null ? "" : data?.search,
    };
    setFilterData(filter);
    setPage(1);
    getAllJobs(1, filter);
  };

  useEffect(() => {
    if (!jobDetailsPage) {
      window.scrollTo(0, windowScrollY);
      setWindowScrollY(0);
      setJobDetails({});
    } else {
      window.scrollTo(0, 0);
    }
  }, [jobDetailsPage]);

  const getAllJobs = async (nextPage, filter, limit = 10) => {
    try {
      setLoader(true);
      let urlGetAllJobList = `/v2/LHS/User/${apiUrl}?page=${
        nextPage || page
      }&limit=${limit}&${new URLSearchParams(filter || filterData)}`;
      const response = await axiosInstance.get(urlGetAllJobList);
      if (response.data.status == 200 || response.status == 200) {
        if (apiUrl == "getRecomendedJobs") {
          setAllJobs((prevData) => {
            if (prevData.length >= 10 && page > 1 && !nextPage) {
              return [...prevData, ...response?.data?.data?.jobData];
            } else {
              return [...response?.data?.data?.jobData];
            }
          });
          const totalPage = nextPage ? nextPage * 10 : page * 10;
          setHasMore(response?.data?.data?.jobCount > totalPage);
          setPage((prevPage) => prevPage + 1);
          setTotalCount(response?.data?.data?.jobCount);
        } else {
          setAllJobs((prevData) => {
            if (prevData.length >= 10 && page > 1 && !nextPage) {
              return [...prevData, ...response?.data?.data?.data];
            } else {
              return [...response?.data?.data?.data];
            }
          });
          const totalPage = nextPage ? nextPage * 10 : page * 10;
          setHasMore(response?.data?.data?.count > totalPage);
          setPage((prevPage) => prevPage + 1);
          setTotalCount(response.data.data.totalPages);
        }
      }
    } catch (error) {
      hideLoader();
      setAllJobs([]);
      setPage(1);
      setTotalCount(0);
    } finally {
      hideLoader();
      setLoader(false);
    }
  };

  let Iran = false;
  useEffect(() => {
    if (!Iran) {
      getAllJobs(1);
      Iran = true;
    }
  }, []);

  const applyJob = async (id, index) => {
    try {
      const jobCardIndex = jobDetails?.index ? jobDetails?.index : index;
      if (!token || !profile) {
        setSelectedJobId(id);
        loginModal(true);
        return;
      }
      setLoading(index);
      let url = `/v1/LHS/job/apply/${id}`;
      const datas = await axiosInstance.post(url, { costPerHour: "50" });
      if (
        datas.Message ==
        "Positions Have been Filled. Cannot Apply for this Job.."
      ) {
        showSuccessToast(datas.Message);
      }
      showSuccessToast("Applied successfully!");
      setJobDetails((prev) => ({
        ...prev,
        pageType: "applied",
      }));
      setAllJobs((prev) => {
        const updatedAllJobs = [...prev];
        updatedAllJobs[jobCardIndex] = {
          ...updatedAllJobs[jobCardIndex],
          // appliedBy: [...updatedAllJobs[jobCardIndex].appliedBy, profile?._id],
          isApplied: true,
        };
        return updatedAllJobs;
      });
    } catch (error) {
      showErrorToast(error.Message);
    } finally {
      setLoading(false);
    }
  };

  const handleButtonAction = ({ index, jobCardData, type }) => {
    if (type == "apply") {
      applyJob(jobCardData?.data?._id, index);
    }
  };

  return (
    <>
      {token ? <Nav /> : <JobsHeader />}
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div className="lg:flex block justify-between align-top py-4 w-11/12 gap-3 mx-auto max-w-[1500px]">
          {(token || profile) && (
            <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />
          )}
          <div className="flex flex-col !gap-3 md:px-5 lg:px-0 w-full xl:w-[52%] lg:w-[48%] relative mx-auto">
            {jobDetailsPage ? (
              <MyJobDetails
                buttonAction={handleButtonAction}
                detailsData={jobDetails}
                closeJobDetails={() => setJobDetailsPage(false)}
                loading={loading}
              />
            ) : (
              <>
                <SearchFilter onFilterChange={handleFilterChange} />
                {apiUrl == "getRecomendedJobs" && (
                  <div className="text-base my-2">Recommended for you</div>
                )}
                <div className="flex flex-col gap-[18px]">
                  {allJobs?.length > 0 ? (
                    <>
                      {page == 1 && loader && <Loader />}
                      <InfiniteScroll
                        dataLength={allJobs?.length}
                        next={getAllJobs}
                        hasMore={hasMore || false}
                        loader={<Loader />}
                        className="flex flex-col gap-[18px]"
                        endMessage={<p>No more items to load</p>}
                      >
                        {allJobs?.map((item, index) => {
                          let alreadyApplied = item?.isApplied;
                          return (
                            <JobCard
                              index={index}
                              jobCardData={{ data: item }}
                              pathName={() => {
                                setJobDetails({
                                  id: item?._id,
                                  data: item,
                                  index: index,
                                  pageType: alreadyApplied
                                    ? "applied"
                                    : !item?.positionsAvailable &&
                                      token &&
                                      profile
                                    ? "filled"
                                    : "apply",
                                });
                                setJobDetailsPage(true);
                                setWindowScrollY(window.scrollY);
                              }}
                              buttonAction={handleButtonAction}
                              userImage={fllipsImag}
                              name={item?.hospital?.healthCareInstitution?.name}
                              location={item?.healthCareLocation}
                              ratings={item?.hospital?.ratings?.ratingSum.toFixed(
                                1
                              )}
                              totalRatings={
                                item?.hospital?.ratings?.totalRatings
                              }
                              seeAll={item?.hospital?._id}
                              jobTitle={item.jobTitle}
                              medicalSettings={item?.medicalSettings}
                              startDate={item?.expectedStartDate}
                              costPerHour={item?.costPerHour}
                              contractType={item?.contractType}
                              contractLength={item?.contractLength}
                              timeCommitment={item?.timeCommitment}
                              jobDescription={item?.jobDescription}
                              weekendAvailiblity={item?.weekendAvailiblity}
                              buttonType={
                                alreadyApplied
                                  ? "applied"
                                  : !item?.positionsAvailable &&
                                    token &&
                                    profile
                                  ? "filled"
                                  : "apply"
                              }
                              loading={loading}
                            />
                          );
                        })}
                      </InfiniteScroll>
                    </>
                  ) : (
                    <div className="text-center mt-3">
                      {loader ? <Loader /> : <p>No record found</p>}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {(token || profile) && <RecommendedForYou />}
        </div>
      </div>
    </>
  );
};

export default StaffMyJob;
