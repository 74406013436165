import React, { useEffect, useState } from "react";
import videoupload from "../../../../assets/images/videoUpload.svg";
import { axiosInstance } from "../../../../../config/axiosConfig";
import useLoader from "../../../../modals/hooks/useLoader";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../common/modal";

const NewAdvertisement = ({ setCreateNew, adData }) => {
  const profile = useSelector((state) => state.data.profile);
  const advertisementData = useSelector(
    (state) => state.checkoutAdvertisement.advertisementData
  );
  const [uploadfiles, setUploadedFiles] = useState(
    advertisementData?.fileUrl[0] || null
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [appURL, setAppUrl] = useState(advertisementData?.url || null);
  const dispatch = useDispatch();
  const [description, setDescription] = useState(
    advertisementData?.caption || null
  );
  const { showLoader, hideLoader } = useLoader();

  const handleUpload = async (files) => {
    var formData = new FormData();
    formData.append("file", files[0]);
    try {
      showLoader();
      let url = "v2/LHS/Upload/uploadFile";
      const response = await axiosInstance.post(url, formData);
      const respData = response.data.data;
      setUploadedFiles(respData);
      hideLoader(false);
    } catch {
      hideLoader(false);
      console.error("Failed to upload image:");
    }
  };

  const handleSubmit = async () => {
    const data = {
      caption: description,
      fileUrl: [uploadfiles],
      mediaType: 1,
      url: appURL,
    };

    dispatch({ type: "SET_ADVERTISEMENT_DATA", payload: data });
    setCreateNew("tab_3");
  };

  return (
    <div className="pb-7">
      <div className="pt-[40px] pb-[60px] px-[30px] flex flex-col gap-[35px]">
        <div className="flex justify-between items-center mx-auto w-full">
          <div className=" flex justify-between items-start">
            <div
              className=" flex items-center gap-[14px]"
              style={{ cursor: "pointer" }}
            >
              <img
                src={profile?.image}
                style={{
                  height: "64px",
                  width: "64px",
                }}
                className="rounded-full"
              />
              <div className="flex flex-col gap-y-[6px]">
                <h1 className="font-medium text-[18px] leading-5 tracking-[0.2px]">
                  {profile?.healthCareInstitution?.name}
                </h1>
                <h1 className="text-[#73C7A9] text-[15px] tracking-[0.2px]">
                  Promoted
                </h1>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={() => setCreateNew("tab_0")}
              className="text-base border-1 border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white w-32 h-11 ml-5"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                // setModalOpen(true);
                handleSubmit();
              }}
              disabled={!uploadfiles}
              className={`text-base border-2 border-[#73C7A9] ${
                !uploadfiles
                  ? "text-white bg-[#9fe1ca] border-none"
                  : "bg-[#73C7A9] text-white"
              } rounded-full w-32 h-11 ml-5`}
            >
              Next
            </button>
          </div>
        </div>
        <div>
          <h1 className="text-[20px] leading-[20px] tracking-[0.2px] text-[#333333]">
            Image*
          </h1>

          <div className="mt-3 rounded-[10px] border border-gray-600 py-3 px-2">
            <div
              className={`border-2 border-gray-600 rounded-[10px] border-dashed w-full flex flex-col items-center justify-center ${
                uploadfiles
                  ? "w-full max-h-[400px] border-none rounded-[12px]"
                  : "h-[300px]"
              }`}
            >
              {uploadfiles?.length > 0 ? (
                <div className="overflow-hidden flex rounded-xl w-full">
                  <img
                    src={uploadfiles}
                    className="w-full h-auto object-contain rounded-xl"
                    alt={`Preview`}
                  />
                </div>
              ) : (
                <div className="relative">
                  <div className="flex justify-center mb-3.5">
                    <img
                      src={videoupload}
                      alt=""
                      onChange={(e) => handleUpload(e.target.files)}
                      className="absolute"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => handleUpload(e.target.files)}
                      className="opacity-0 w-[100px]"
                    />
                  </div>
                  <div className="mt-3 text-center">
                    Drop your Image file here
                  </div>
                  <h2 className="flex justify-center m-3.5">OR</h2>
                  <div className="relative">
                    <div
                      className="flex justify-center"
                      style={{ cursor: "pointer" }}
                    >
                      <button
                        className="w-[192px] h-[44px] border border-black rounded-[100px] font-semibold text-sm text-[#333333] cursor-pointer hover:!cursor-pointer absolute"
                        onClick={(e) => handleUpload(e?.target?.files)}
                      >
                        Select Image to share
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={(e) => handleUpload(e.target.files)}
                        className="opacity-0 w-auto h-10 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <h1 className="text-[20px] leading-[20px] tracking-[0.2px] mt-4 mb-3 text-[#333333] ">
            Description
          </h1>
          <textarea
            type="text"
            placeholder="What do you want to talk about in your advertisement?"
            className="w-full h-[216px] p-4 border text-[16px] border-gray-400 rounded-lg outline-none"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          <h1 className="text-[20px] leading-[20px] tracking-[0.2px] mt-4 mb-3 rounded-lg text-[#333333]">
            Add URL{" "}
          </h1>
          <input
            type="text"
            onChange={(e) => setAppUrl(e.target.value)}
            value={appURL}
            placeholder="Where do you want the user to redirect?"
            className="w-full h-auto p-3  border text-[16px] border-gray-400 rounded-lg"
          />
        </div>
      </div>
      {/* <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        className={"md:w-[600px] md:mt-0"}
      >
        <div className=" flex justify-center items-start w-full">
          <div className="flex flex-col justify-center">
            <p className="flex justify-center text-xl leading-[20px] tracking-[0.2px] mb-[20px]">
              Alert
            </p>
            <p className="text-base flex justify-center leading-7 font-medium m-auto mb-4 text-center tracking-[0.2px] w-[400px]">
              Once the payment is done, you cannot edit or delete the
              advertisement. Please cross check again.
            </p>
            <div className="text-center">
              <button
                className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5"
                onClick={() => setModalOpen(false)}
              >
                Back
              </button>
              <button
                onClick={() => {
                  handleSubmit();
                  setCreateNew("tab_3");
                  setModalOpen(false);
                }}
                className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};
export default NewAdvertisement;
