import React, { useEffect, useState } from "react";
import Nav from "../Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import RightSideBar from "../../containers/commonComponent/rightSideBar";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";
import Loader from "../../modals/Loader";
import calculateTime from "../../modals/calculateTime";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import moment from "moment";
const Notification = () => {
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state.data.profile);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const NOTIFICATION_TYPE = {
    POST: [1, 2, 3],
    REPLYONCOMMENT: 4,
    CHAT: 5,
    ACCEPTED: 6,
    BLOCKEDADVERTISEMENT: 9,
    INVITATION: 8,
  };

  const GetNotification = async ({ nextPage = 1 }) => {
    try {
      setLoading(true);
      let url = `/v2/LHS/User/getNotification?page=${
        nextPage || page
      }&limit=10`;
      const response = await axiosInstance.get(url);
      if (response.data) {
        setNotifications((prevData) => {
          if (nextPage > 1) {
            return [...prevData, ...response?.data?.data?.notifications];
          } else {
            return [...response?.data?.data?.notifications];
          }
        });
        setTotalPages(response?.data?.data?.totalPages);
      }
    } catch (error) {
      console.error("Error fetching notification:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetNotification({ nextPage: 1 });
  }, []);

  useEffect(() => {
    if (totalPages && page <= totalPages) {
      const loadMorePages = () => {
        if (page < totalPages) {
          setPage((prevPage) => {
            const nextPage = prevPage + 1;
            GetNotification({ nextPage });
            return nextPage;
          });
        }
      };

      const handleIntersection = (entries) => {
        if (entries[0].isIntersecting && !loading) {
          loadMorePages();
        }
      };

      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      });

      const target = document.getElementById("scroll-trigger");
      observer.observe(target);

      return () => {
        observer.disconnect();
      };
    }
  }, [loading, totalPages, page]);

  const redirectNotification = (item) => {
    const combinedIds = `${item?.chatId}-${item?.receiverId}`;
    const timestamp = moment().unix();
    if (NOTIFICATION_TYPE.POST?.includes(item?.pushType)) {
      history.push(`/Post/${item?.postId?._id}`);
    } else if (item?.pushType == NOTIFICATION_TYPE?.CHAT) {
      history.push({
        pathname: `/HospitalChatBox/${combinedIds}?chatid=${item?.userId?._id}&timestamp=${timestamp}`,
        search: `param1=tab1`,
      });
    } else if (item?.pushType == NOTIFICATION_TYPE?.BLOCKEDADVERTISEMENT) {
      history?.push("/hospital");
    } else if (item?.pushType == NOTIFICATION_TYPE?.REPLYONCOMMENT) {
      history.push(`/Post/${item?.postId?._id}`);
    } else if (item?.pushType == NOTIFICATION_TYPE?.INVITATION) {
      history?.push(`/hospitalMsg?param1=tab2`);
    } else if (item?.pushType == NOTIFICATION_TYPE?.ACCEPTED) {
      history?.push(`/meassage?param1=tab2`);
    }
  };
  return (
    <>
      <Nav />
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", height: "100%" }}
      >
        <div
          className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />

          <div className="flex flex-col !gap-3 md:px-5 lg:px-0 w-full xl:w-[52%] lg:w-[48%]">
            {!loading && notifications.length === 0 ? (
              <div className="flex justify-center items-center p-3 rounded-[10px] h-full sm:w-full font-semibold text-[24px]">
                <h2>No data found</h2>
              </div>
            ) : (
              notifications?.map((item, index) => {
                const formattedTime = calculateTime(item?.createdAt);
                const { isUserRead, title, message } = item || {};
                return (
                  <div
                    className={classNames(
                      { "bg-[#ffffff80]": isUserRead, "bg-white": !isUserRead },
                      "flex justify-between p-3 rounded-[12px] sm:w-full cursor-pointer"
                    )}
                    onClick={() => redirectNotification(item, index)}
                    key={index}
                    style={{ boxShadow: "0 5px 15px 0 rgba(0, 0, 0, 0.1)" }}
                  >
                    <div className="flex items-center gap-4">
                      <div className="w-12 min-w-[40px] h-12 rounded-full overflow-hidden">
                        <img
                          src={item?.userId?.image}
                          alt=""
                          className="object-cover w-full h-full"
                        />
                      </div>
                      <div>
                        <h3 className="p-0 text-[#333333] text-[18px] font-semibold">
                          {title}
                        </h3>
                        <p className="text-gray-500 text-[15px] font-normal overflow-ellipsis line-clamp-1 w-full leading-[19px]">
                          {message}
                        </p>
                      </div>
                    </div>
                    <p className="text-[12px] text-gray-500 mt-1 font-normal">
                      {formattedTime}
                    </p>
                  </div>
                );
              })
            )}
            {loading && <Loader />}
            <div id="scroll-trigger" style={{ height: "10px" }}></div>
          </div>
          <RecommendedForYou />
        </div>
      </div>
    </>
  );
};

export default Notification;
