import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import del from "../../../assets/images/delete.svg";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import edit_note from "../../../assets/images/edit_note.svg";
import trans from "../../../assets/images/trans.svg";
import { axiosInstance } from "../../../../config/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../../common/modal";
import Loader from "../../../modals/Loader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../common/toastMeassage/ToastMeassage";

const Locations = ({ hospitalData, setHospitalDetails }) => {
  const profile = useSelector((state) => state?.data?.profile);
  const [addressDetails, setAddressDetails] = useState({
    zipCode: "",
    street: "",
    city: "",
    state: "",
  });
  const [addEditAddress, setAddEditAddress] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState({
    street: "block",
    zipCode: "block",
  });
  const [showZipErr, setShowZipErr] = useState(false);
  const [showStreetErr, setShowStreetErr] = useState(false);

  const closeModal = () => {
    setShowZipErr(false);
    setModalOpen(false);
  };

  const addNewLoc = () => {
    setAddEditAddress(null);
    setAddressDetails({ zipCode: "", street: "", city: "", state: "" });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setAddEditAddress(item?._id);
    const { zipCode, street, city, state } = item || {};
    setAddressDetails({ zipCode, street, city, state });
    setModalOpen(true);
  };

  const setStreetValue = (e) => {
    setAddressDetails((prev) => ({
      ...prev,
      street: e.target.value,
    }));
    if (!addressDetails?.street.length) {
      setShowStreetErr(true);
    } else {
      setShowStreetErr(false);
    }
  };

  const setCityValue = (e) => {
    setAddressDetails((prev) => ({
      ...prev,
      city: e.target.value,
    }));
    setLocError();
  };
  const setZipCodeValue = (e) => {
    setAddressDetails((prev) => ({
      ...prev,
      zipCode: e.target.value,
    }));
    setLocError(e.target.value);
  };

  const needToSetZip = () => {
    setShowZipErr(true);
  };

  const setLocError = async (val) => {
    var newError = {
      ...error,
    };
    var zip = val || addressDetails?.zipCode;
    if (zip.length !== 5) {
      setAddressDetails((prev) => ({
        ...prev,
        city: "",
        state: "",
      }));
      setShowZipErr(true);
    } else {
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      var res = await axiosInstance.post("v1/LHS/other/getCityState", {
        zip: zip,
      });
      if (res.data?.result === "OK") {
        setAddressDetails((prev) => ({
          ...prev,
          city: res.data.data.city,
          state: res.data.data.state,
        }));
        setShowZipErr(false);
      } else {
        setAddressDetails((prev) => ({
          ...prev,
          city: "",
          state: "",
        }));
        setShowZipErr(true);
      }
    }
    setError(newError);
    return newError;
  };

  const deleteDetails = (item) => {
    handleSave(item?._id);
  };

  const handleSave = async (deleteId) => {
    let { city, state, street, zipCode } = addressDetails;
    if ((!city || !state || !street || !zipCode) && !deleteId) {
      showErrorToast("All fields are required!");
      return;
    }
    setShowLoader(true);
    try {
      let updatedData;
      if (addEditAddress === null && !deleteId) {
        updatedData = [
          ...(hospitalData?.corporateAddress || []),
          addressDetails,
        ];
      } else if (deleteId) {
        updatedData = hospitalData?.corporateAddress
          ? hospitalData.corporateAddress.filter(
              (item) => item?._id !== deleteId
            )
          : [];
      } else {
        updatedData = hospitalData?.corporateAddress
          ? hospitalData.corporateAddress.map((item, index) =>
              item?._id === addEditAddress ? addressDetails : item
            )
          : [];
      }
      const response = await axiosInstance.post(
        `v1/LHS/hospital/update/${hospitalData?._id}`,
        { corporateAddress: updatedData }
      );
      setHospitalDetails(response?.data?.data);
      setAddEditAddress(null);
      if (deleteId) {
        showSuccessToast("Location deleted successfully");
      } else {
        if (addEditAddress == null) {
          showSuccessToast("Location added successfully");
        } else {
          showSuccessToast("Location updated successfully");
        }
      }
      setAddressDetails({ zipCode: "", street: "", city: "", state: "" });
      closeModal();
    } catch (error) {
      console.error("Save error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="t2_sp_work_title">Healthcare Institution locations</div>
        {(profile?.role == "hospital" || profile?.role == "superAdmin") && (
          <div
            className="text-[18px] text-[#73C7A9] border !border-[#73C7A9] py-2 cursor-pointer rounded-full px-6 w-1/2"
            onClick={() => addNewLoc()}
          >
            + Add {hospitalData?.corporateAddress?.length ? "Another " : ""}
            Location
          </div>
        )}
        <div className="py-4 grid gap-4 w-full grid-cols-2">
          {hospitalData?.corporateAddress?.slice(1)?.map((item, index) => (
            <div className="relative t2_sp_work_container p-8 gap-0 mt-2 ">
              <div className="grid grid-cols-2 w-full">
                <div className="flex flex-col gap-4">
                  <div className="">
                    <h6 style={{ fontSize: 16 }}> Zip Code </h6>
                    <h5 className="t2_detail_textbox  text-lg">
                      {item?.zipCode}
                    </h5>
                  </div>
                  <div className="">
                    <h6 style={{ fontSize: 16 }}> City </h6>
                    <h5 className="t2_detail_textbox text-lg">{item?.city}</h5>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="">
                    <h6 style={{ fontSize: 16 }}> Street address </h6>
                    <h5 className="t2_detail_textbox text-lg">
                      {item?.street}
                    </h5>
                  </div>
                  <div className="">
                    <h6 style={{ fontSize: 16 }}> State </h6>
                    <h5 className="t2_detail_textbox text-lg">{item?.state}</h5>
                  </div>
                </div>
              </div>
              <div className="flex top-4 right-4 absolute gap-4">
                {(profile?.role == "hospital" ||
                  profile?.role == "superAdmin") && (
                  <img
                    alt="icon.svg"
                    src={edit_note}
                    width="20px"
                    className="cursor-pointer"
                    onClick={() => handleEdit(item)}
                  />
                )}
                {(profile?.role == "hospital" ||
                  profile?.role == "superAdmin") && (
                  <img
                    className="cursor-pointer"
                    width="18px"
                    height="15px"
                    alt="img"
                    onClick={() => deleteDetails(item)}
                    src={trans}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        {modalOpen && (
          <Modal isOpen={modalOpen} onClose={closeModal} className="!w-1/2">
            <div className="flex justify-between">
              <div className="ssu2_modal1_text1 text-xl">
                {addEditAddress == null ? "Add" : "Edit"} other location
              </div>
              {/* <p onClick={closeModal}>X</p> */}
            </div>
            <div className=" ssu2_modal1_input ">
              <div className="text-lg mt-2 p-2">zipcode</div>
              <div className=" ssu2_modal1_input  ssu2_modal3_selectBox ">
                <div className=" input_left_icon ">
                  {/* <img
                        height="15px"
                        src={place}
                        width="18px"
                        alt="location"
                      /> */}
                </div>

                <input
                  className="ssu2_modal3_select pl-5"
                  type="text"
                  placeholder="Zip code"
                  value={addressDetails?.zipCode}
                  defaultValue={addressDetails?.zipCode}
                  name="zipCode"
                  onChange={setZipCodeValue}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleSave();
                  }}
                />
              </div>
              <ErrorState
                show={showZipErr ? error.zipCode : "none"}
                name="Valid zip code is required."
              />
            </div>
            <div className="text-lg p-2">Street address</div>
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal3_selectBox">
                <div className="input_left_icon">
                  {/* <img width="18px" height="15px" alt="img" src={home} /> */}
                </div>
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="Street Address"
                  type="text"
                  value={addressDetails?.street}
                  defaultValue={addressDetails?.street}
                  name="street"
                  onChange={setStreetValue}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleSave();
                  }}
                />
              </div>
              <ErrorState
                show={showStreetErr ? error.street : "none"}
                name="Street address is required."
              />
            </div>
            <div className="text-lg p-2 ">City</div>
            <div
              className="row ssu2_modal1_input m-auto  "
              style={{ flexDirection: "row" }}
            >
              <div
                className="ssu2_modal3_selectBox"
                style={{ padding: 0 }}
                onClick={needToSetZip}
              >
                <div
                  className="ssu2_modal3_selectBox"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  <div className="input_left_icon">
                    {/* <img width="18px" height="15px" alt="img" src={place} /> */}
                  </div>
                  <input
                    className="ssu2_modal3_select pl-5"
                    placeholder="City"
                    type="text"
                    defaultValue={addressDetails?.city}
                    name="City"
                    value={addressDetails?.city}
                    // onChange={setCityValue}
                    readOnly
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleSave();
                    }}
                  />
                </div>
              </div>
              <div
                className="ssu2_modal3_selectBox mt-3"
                style={{ padding: 0 }}
                onClick={needToSetZip}
              ></div>
              <div className="text-lg p-2">State</div>

              <div className="ssu2_modal3_selectBox hsu1_input2 !p-0">
                <div className="input_left_icon">
                  {/* <img
                        alt="img"
                        src={place}
                        width="18px"
                        height="15px"
                        style={{ marginTop: "-3px" }}
                      /> */}
                </div>
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="State"
                  type="text"
                  readOnly
                  value={addressDetails?.state}
                  defaultValue={addressDetails?.state}
                />
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-4">
              <button
                className="w-32 bg-white  border-1 text-[#73C7A9] rounded-[28px] border !border-[#73C7A9] p-2 hover:!bg-[#73C7A9] hover:text-white"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="w-32 text-white bg-[#73C7A9] rounded-[28px] p-2"
                onClick={() => handleSave()}
              >
                {showLoader ? (
                  <Loader />
                ) : addEditAddress == null ? (
                  "Add"
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Locations;
