import React from "react";
import topImg from "../../assets/images/topImg.png";
import yoga from "../../assets/images/yoga.png";
import tech from "../../assets/images/tech.png";
import mask from "../../assets/images/mask.png";
import Nav from "../Nav";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Staff from "./staff";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

const SuperAdmin = () => {
  const slides = [{ url: topImg }, { url: yoga }, { url: mask }, { url: tech }];

  const [hidePost, setHidePost] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [updatedData1, setUpdateddata] = useState();

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const profile = useSelector((state) => state.data.profile);

  // const handleidData = (data) => {
  //   setUpdateddata(data);
  // };

  return (
    <>
      <Staff />
    </>
  );
};

export default SuperAdmin;
