import React, { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorState from "../../theme_1/staffSignUp/components/errorState";
import key from "../../assets/images/key.svg";
import { useDispatch } from "react-redux";
import { removeSession, callApi } from "../../../redux/action/action";
import { axiosInstance } from "../../../config/axiosConfig";
import { showErrorToast } from "../../common/toastMeassage/ToastMeassage";

const ChangePassword = () => {
  const [psw, setPsw] = useState("");
  const [repsw, setRepsw] = useState("");
  const [cpsw, setCpsw] = useState("");
  const [errors, setErrors] = useState({
    psw: "",
    repsw: "",
    cpsw: "",
  });

  const dispatch = useDispatch();

  const togglePsw = (id) => {
    const val = document.getElementById(id);
    if (val.type === "password") {
      val.type = "text";
    } else {
      val.type = "password";
    }
  };

  const validatePassword = (password) => {
    // Perform your password validation here
    // Example: Password should have a minimum of 6 characters, 1 upper case, 1 lower case, 1 special character, and 1 number
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

    return passwordPattern.test(password);
  };

  const setPassword = (e) => {
    const newPassword = e.target.value;
    setPsw(newPassword);
    const newErrors = { ...errors };

    if (newPassword.length === 0) {
      newErrors.psw = "Password is required.";
    } else if (!validatePassword(newPassword)) {
      newErrors.psw =
        "Password should have a minimum of 6 characters, 1 upper case, 1 lower case, 1 special character, and 1 number.";
    } else {
      newErrors.psw = "";
    }
    setErrors(newErrors);
    if (repsw !== "") {
      setRepsw(repsw);
    }
  };

  const setCurrentPassword = (e) => {
    const currentPassword = e.target.value;
    setCpsw(currentPassword);
    const newErrors = { ...errors };

    if (currentPassword.length === 0) {
      newErrors.cpsw = "Password is required.";
    } else if (!validatePassword(currentPassword)) {
      newErrors.cpsw =
        "Password should have a minimum of 6 characters, 1 upper case, 1 lower case, 1 special character, and 1 number.";
    } else {
      newErrors.cpsw = "";
    }

    setErrors(newErrors);
  };

  const setConfirmPsw = (e) => {
    const confirmPassword = e.target.value;
    setRepsw(confirmPassword);
    const newErrors = { ...errors };

    if (confirmPassword !== psw) {
      newErrors.repsw = "Passwords don't match.";
    } else {
      newErrors.repsw = "";
    }

    setErrors(newErrors);
  };

  const savePassword = async () => {
    const newErrors = { psw: "", repsw: "", cpsw: "" };

    if (psw.length === 0) {
      newErrors.psw = "Password is required.";
    } else if (!validatePassword(psw)) {
      newErrors.psw =
        "Password should have a minimum of 6 characters, 1 upper case, 1 lower case, 1 special character, and 1 number.";
    }

    if (cpsw.length === 0) {
      newErrors.cpsw = "Password is required.";
    } else if (!validatePassword(cpsw)) {
      newErrors.cpsw =
        "Password should have a minimum of 6 characters, 1 upper case, 1 lower case, 1 special character, and 1 number.";
    }

    if (repsw.length === 0 || repsw !== psw) {
      newErrors.repsw = "Passwords don't match.";
    }
    // const currentPasswordMatch = await checkCurrentPasswordMatch(cpsw);
    // if (!currentPasswordMatch) {
    //   newErrors.cpsw = "Current password doesn't match.";
    // }
    // setErrors(newErrors);

    if (
      newErrors.psw === "" &&
      newErrors.cpsw === "" &&
      newErrors.repsw === ""
    ) {
      // Passwords are valid; you can proceed with saving
      const data = {
        oldPassword: cpsw,
        newPassword: psw,
      };

      const Authorization = "Bearer ".concat(
        window.localStorage.getItem("token")
      );
      try {
        const res = await axiosInstance.post(
          "v1/LHS/staff/changePassword",
          data
        );
        if (res?.data?.Message === "Password updated Successfully ...") {
          setPsw("");
          setCpsw("");
          setRepsw("");
          alert("Password updated");
          removeSession();
          window.location.href = "/login";
        } else {
          alert("Failed to Change Password.");
        }
      } catch (err) {
        showErrorToast(err?.Error);
      }
    }
  };
  const checkCurrentPasswordMatch = async (currentPassword) => {
    try {
      const response = await axiosInstance.post(
        "v1/LHS/staff/checkCurrentPassword",
        { currentPassword }
      );
      console.log("API Response:", response);
      return response?.data?.isMatch; // Adjust based on your actual API response
    } catch (error) {
      console.error("Error checking current password match:", error);
      return false;
    }
  };

  const [cupasswordVisible, setcuPasswordVisible] = useState(false);
  const cutogglePasswordVisibility = () => {
    setcuPasswordVisible((prevState) => !prevState);
  };

  const [nepasswordVisible, setnePasswordVisible] = useState(false);
  const netogglePasswordVisibility = () => {
    setnePasswordVisible((prevState) => !prevState);
  };

  const [copasswordVisible, setcoPasswordVisible] = useState(false);
  const cotogglePasswordVisibility = () => {
    setcoPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="t2_sp_work">
      <div className="t2_sp_work_title">Change Password</div>
      <div className="row t2_sp_work_container" style={{ width: "476px" }}>
        <div className="" style={{ padding: "0px 20px 0px 20px" }}>
          <h1 className="text-[16px]">Current Password</h1>
          <div className="ssu2_modal1_input" style={{}}>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                <img width="18px" height="15px" alt="img" src={key} />
              </div>
              <input
                className="ssu3_password"
                id="cpsw"
                placeholder="Current Password"
                type={cupasswordVisible ? "text" : "password"}
                value={cpsw}
                onChange={setCurrentPassword}
                onKeyUp={(e) => {
                  if (e.key === "Enter") savePassword();
                }}
              />
              <div
                className="ssu3_eye"
                onMouseDown={() => togglePsw("cpsw")}
                onMouseUp={() => togglePsw("cpsw")}
                onTouchStart={() => togglePsw("cpsw")}
                onTouchEnd={() => togglePsw("cpsw")}
              >
                <button
                  type="button"
                  onClick={cutogglePasswordVisibility}
                  className=""
                >
                  {cupasswordVisible ? (
                    <VisibilityIcon id="c_psw" />
                  ) : (
                    <VisibilityOffIcon
                      id="o_psw"
                      style={{ display: "block" }}
                    />
                  )}
                </button>
              </div>
            </div>
            <ErrorState name={errors.cpsw} show={errors.cpsw !== ""} />
          </div>
          <h1 className="text-[16px]">New Password</h1>
          <div className="ssu2_modal1_input" style={{ marginTop: 0 }}>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                <img width="18px" height="15px" alt="img" src={key} />
              </div>
              <input
                className="ssu3_password"
                id="psw"
                placeholder="New Password"
                type={nepasswordVisible ? "text" : "password"}
                value={psw}
                onChange={setPassword}
                onKeyUp={(e) => {
                  if (e.key === "Enter") savePassword();
                }}
              />
              <div
                className="ssu3_eye"
                onMouseDown={() => togglePsw("psw")}
                onMouseUp={() => togglePsw("psw")}
                onTouchStart={() => togglePsw("psw")}
                onTouchEnd={() => togglePsw("psw")}
              >
                <button
                  type="button"
                  onClick={netogglePasswordVisibility}
                  className=""
                >
                  {nepasswordVisible ? (
                    <VisibilityIcon id="c_psw" />
                  ) : (
                    <VisibilityOffIcon
                      id="o_psw"
                      style={{ display: "block" }}
                    />
                  )}
                </button>
              </div>
            </div>
            <ErrorState name={errors.psw} show={errors.psw !== ""} />
          </div>
          <h1 className="text-[16px]">Confirm New password</h1>
          <div className="ssu2_modal1_input" style={{ marginTop: 0 }}>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                <img height="15px" width="18px" alt="img" src={key} />
              </div>
              <input
                className="ssu3_password"
                id="rpsw"
                placeholder="Confirm New password"
                type={copasswordVisible ? "text" : "password"}
                value={repsw}
                onChange={setConfirmPsw}
                onKeyUp={(e) => {
                  if (e.key === "Enter") savePassword();
                }}
              />
              <div
                className="ssu3_eye"
                onMouseDown={() => togglePsw("rpsw")}
                onTouchStart={() => togglePsw("rpsw")}
                onMouseUp={() => togglePsw("rpsw")}
                onTouchEnd={() => togglePsw("rpsw")}
              >
                <button
                  type="button"
                  onClick={cotogglePasswordVisibility}
                  className=""
                >
                  {copasswordVisible ? (
                    <VisibilityIcon id="o_rpsw" />
                  ) : (
                    <VisibilityOffIcon
                      id="c_rpsw"
                      style={{ display: "block" }}
                    />
                  )}
                </button>
              </div>
            </div>
            <ErrorState name={errors.repsw} show={errors.repsw !== ""} />
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          className="ssu_button"
          onClick={savePassword}
          style={{
            margin: "30px 0px 0px 0px",
            maxWidth: "221px",
            opacity: "100%",
          }}
        >
          Change password
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
