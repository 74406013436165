import React, { useEffect } from "react";
import user from "../../assets/images/user.png";
import videoupload from "../../assets/images/videoUpload.svg";
import imgicon from "../../assets/images/imgicon.svg";
import playicon from "../../assets/images/playicon.svg";
import errorImage from "../../assets/images/errorImage.svg";
import plus from "../../assets/images/plus.svg";
import Modal from "../../common/modal";
import Nav from "../Nav";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StaffCard from "../../ui/staffCard";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../config/axiosConfig";
import "../main/Staff.css";
import useLoader from "../../modals/hooks/useLoader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import Loader from "../../modals/Loader";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";
import RightSideBar from "../../containers/commonComponent/rightSideBar";
import JobsHeader from "../staffSignUp/JobsHeader";
import { AdvertisementCard } from "../../theme_2/staffProfile/components/Advertisement/AdvertisementCard";
import io from "socket.io-client";
import { useHistory } from "react-router-dom";

const Staff = ({ sharedPostId, singlePostId }) => {
  const [hidePost, setHidePost] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [updateComment, setUpdateComment] = useState(null);
  const profile = useSelector((state) => state.data.profile);
  const [files, setImage] = useState(null);
  const token = localStorage.getItem("accessToken");
  const [video, setVideo] = useState(null);
  const [showLoader1, setShowLoader] = useState(false);
  const [Idmodal, setIdmodal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [videoOpen, setvideoOpen] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [mediaType, setMediaType] = useState("");
  const [error, setError] = useState(false);
  const [allInterestJobs, setAllInterestJobs] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [caption, setCaption] = useState("");
  const [bottomLoader, setBottomLoader] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [file, setFile] = useState(null);
  const { showLoader, hideLoader } = useLoader();
  const [updatedData1, setUpdateddata] = useState();
  const [videoUploading, setVideoUploading] = useState(false);
  const [videoUploadCheck, setVideoUploadCheck] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [gobackOpens, setgobackOpens] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    setgobackOpens(false);
  };
  const openvideoPopup = () => {
    setvideoOpen(true);
  };
  const closevideopopup = () => {
    setvideoOpen(false);
  };

  const openImagePopup = () => {
    setVideoUploadCheck(false);
    setImageOpen(true);
  };
  const closeImagepopup = () => {
    setImageOpen(false);
    setSizeError(null);
    setVideoUploadCheck(false);
    setImage(null);
  };
  const closeModal = () => {
    setModalOpen(false);
    setImageOpen(false);
    setvideoOpen(false);
    setCaption("");
    setUploadedFiles([]);
    setVideoUploadCheck(false);
    setError(false);
    setIdmodal("");
  };
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const GetAllInterestJobs = async ({ nextPage = 1 }) => {
    try {
      page && setBottomLoader(true);
      !page && showLoader();

      if (sharedPostId) {
        let url = `v2/LHS/User/getPostDetailByToken/${sharedPostId}`;
        const response = await axiosInstance.get(url);
        if (response.data.success) {
          setAllInterestJobs([response?.data?.data]);
        }
      } else if (singlePostId) {
        let url = `v2/LHS/User/getPostById/${singlePostId}`;
        const response = await axiosInstance.get(url);
        response.data.data.commentsCount =
          response?.data?.data?.comments?.length || 0;
        if (response.data.success) {
          setAllInterestJobs([response?.data?.data]);
        }
      } else {
        let url = `v2/LHS/User/homepage?page=${nextPage || page}&limit=10`;
        const response = await axiosInstance.get(url);

        if (response.data.success) {
          setAllInterestJobs((prevData) => {
            if (nextPage > 1) {
              return [...prevData, ...response?.data?.data?.postData];
            } else {
              return [...response?.data?.data?.postData];
            }
          });
          dispatch({
            type: "NOTIFICATION_COUNT",
            payload: response?.data?.data?.pendingNotification,
          });
          setTotalPages(response.data.data.totalPages);
        }
      }
    } catch (error) {
      if (error.message == "Post not found.") {
        if (profile.role == "staff") {
          history.push("/staff");
        } else if (profile?.role == "hospital") {
          history.push("/hospital");
        } else if (profile?.role == "superAdmin") {
          history.push("/main/admin");
        }
        showSuccessToast(error?.message);
      }
    } finally {
      hideLoader();
      setBottomLoader(false);
    }
  };

  let ranFirst = false;
  useEffect(() => {
    if (!ranFirst) {
      GetAllInterestJobs({ nextPage: 1 });
      ranFirst = true;
    }
  }, []);

  useEffect(() => {
    if (totalPages && page <= totalPages) {
      const loadMorePages = () => {
        if (page < totalPages) {
          setPage((prevPage) => {
            const nextPage = prevPage + 1;
            GetAllInterestJobs({ nextPage });
            return nextPage;
          });
        }
      };

      const handleIntersection = (entries) => {
        if (entries[0].isIntersecting && !bottomLoader) {
          loadMorePages();
        }
      };

      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      });

      const target = document.getElementById("scroll-trigger");
      observer.observe(target);

      return () => {
        observer.disconnect();
      };
    }
  }, [bottomLoader, totalPages, page]);

  const getImageSize = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };
  const handleUpload = async (files) => {
    if (!files) return;
    const images = [...files];
    setSizeError(null);
    if (!images) {
      console.error("No image selected.");
      return;
    } else if (images.length > 5) {
      setSizeError("Select up to 5 images only");
      return;
    }
    for (const image of images) {
      const imageSize = await getImageSize(image);
      if (imageSize?.width > 6012) {
        setSizeError(
          `This image is too big. Please upload an image smaller than 6012 × 6012 px.`
        );
        return;
      }
    }
    try {
      showLoader();
      const formData = new FormData();
      images.forEach((image) => formData.append("files", image));
      let url = "v2/LHS/Upload/uploadFiles";
      const response = await axiosInstance.post(url, formData);
      const uploadedImageUrls = response.data.data;
      setUploadedFiles([...uploadedFiles, ...uploadedImageUrls]);
    } catch (error) {
      console.error("Failed to upload image:", response.error);
    } finally {
      setShowLoader(false);
      hideLoader();
    }
  };
  const isvideo = (url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return ["mp4", "avi", "mkv", "mov"].includes(fileExtension);
  };
  const isImage = (url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return ["png", "jpeg", "jpg", "svg", "webp", "avif"].includes(
      fileExtension
    );
  };
  const handleUploadvideo = async (video) => {
    if (!video) {
      console.error("No video selected.");
      return;
    }
    setVideoUploading(true);
    setVideoUploadCheck(true);
    try {
      // showLoader();
      const formDatas = new FormData();
      formDatas.append("files", video);
      let url = "v2/LHS/Upload/uploadFiles";
      const response = await axiosInstance.post(url, formDatas);
      const uploadedImageUrls = response.data.data;
      setUploadedFiles([...uploadedFiles, ...uploadedImageUrls]);
      if (response.ok && response.result === "OK") {
        setVideoUploading(false);
      } else {
        setVideoUploading(false);
      }
    } catch (error) {
      setVideoUploading(false);
      console.error("Error while uploading image:", error);
    } finally {
      // hideLoader();
    }
  };
  const handleAddPost = async () => {
    try {
      setShowLoader(true);
      // showLoader();
      const formdata = {
        caption: caption,
        fileUrl: uploadedFiles,
        mediaType: 1,
      };
      const url = "v2/LHS/User/addPost";
      const response = await axiosInstance.post(url, formdata);
      if (response?.data?.message == true) {
        setImage(null);
        setCaption("");
      }
      setImage(null);
      setCaption("");
      setUploadedFiles([]);
      closeModal();
      setModalOpen(false);
      showSuccessToast("Post added successfully!");
      setAllInterestJobs([]);
      GetAllInterestJobs({ nextPage: 1 });
    } catch (error) {
      showErrorToast("An error occurred while adding the post.");
      console.error("Error adding post:", error);
    } finally {
      setShowLoader(false);
      // hideLoader();
    }
  };
  const handleSubmit = async (id) => {
    try {
      showLoader();
      let url = `v2/LHS/User/deletePost/${id}`;
      const response = await axiosInstance.delete(url);
      if (response.data?.success == true) {
        setAllInterestJobs([]);
        GetAllInterestJobs({ nextPage: 1 });
        showSuccessToast("Post deleted successfully!");
        setImage(null);
        setModalOpen(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("Something went wrong");
    } finally {
      hideLoader();
    }
  };
  const handleidData = (data) => {
    setUpdateddata(data);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpdatePost = async () => {
    const updatedData = {
      caption: caption,
      fileUrl: uploadedFiles,
      mediaType: updatedData1?.mediaType,
    };
    try {
      showLoader();
      const response = await axiosInstance.put(
        `v2/LHS/User/updatePost/${Idmodal}`,
        updatedData
      );
      if (response?.data?.success == true) {
        showSuccessToast(response?.data?.message);
        setHidePost(false);
        setModalOpen(false);
        setMediaType("image");
        GetAllInterestJobs({ nextPage: page });
        closeModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 400) {
      setCaption(newText);
    }
  };

  const handlePost = async (data) => {
    setModalOpen(!modalOpen);
    setIdmodal(data?._id);
    setCaption(data?.caption);
    setUploadedFiles(data?.fileUrl);
    const extension = data?.fileUrl[0].split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "bmp"].includes(extension)) {
      setVideoUploadCheck(false);
    } else if (["mp4", "avi", "mov", "mkv"].includes(extension)) {
      setVideoUploadCheck(true);
    }
  };

  const handleVideoChange = (e) => {
    const image = e.target.files[0];
    handleUploadvideo(image);
  };

  const handleImageRemove = (index) => {
    const updatedUploadedFiles = [...uploadedFiles];
    let tempData = updatedUploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(tempData);
  };

  const isMaxImageUploadReached = uploadedFiles.length >= 6;
  const isPublishButtonDisabled =
    isMaxImageUploadReached || caption.trim() === "";

  const [sizeError, setSizeError] = useState(null);

  return (
    <>
      {token ? <Nav /> : <JobsHeader />}
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div className="lg:flex block justify-between align-top py-4 w-11/12 mx-auto gap-3 max-w-[1500px]">
          {!sharedPostId && (
            <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />
          )}

          <div className="flex flex-col !gap-3 md:px-5 lg:px-0 w-full xl:w-[52%] lg:w-[48%] mx-auto">
            {!sharedPostId && !singlePostId && (
              <div
                className="flex items-center gap-[20px] border border-gray-100 bg-white sm:w-full px-[20px] py-[10px] "
                style={{
                  borderRadius: "10px",
                }}
              >
                <div className="w-14 min-w-[3.5rem] h-14 rounded-full overflow-hidden">
                  <img
                    src={profile?.image ? profile?.image : user}
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="border border-gray-100 rounded-lg justify-center w-full items-center">
                  <input
                    className="border border-gray-100 appearance-none  rounded  w-full  h-11 py-2 px-3 text-gray-700 leading-tight  focus:outline-none focus:shadow-outline "
                    id="inline-full-name"
                    type="text"
                    placeholder="Start a post"
                    onClick={openModal}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              {allInterestJobs.map((item, index) => {
                if (item.contentType == 1) {
                  return <AdvertisementCard data={item} />;
                }
                return (
                  <StaffCard
                    handleFileChange={handleFileChange}
                    hidePost={hidePost}
                    setHidePost={setHidePost}
                    updateComment={updateComment}
                    setUpdateComment={setUpdateComment}
                    handleidData={handleidData}
                    Idmodal={Idmodal}
                    openModal={openModal}
                    handleSubmit={handleSubmit}
                    myProfile={profile}
                    handlePost={handlePost}
                    setLoading={setLoading}
                    indexData={index}
                    {...item}
                  />
                );
              })}
              {bottomLoader && <Loader />}
              <div id="scroll-trigger" style={{ height: "10px" }}></div>
            </div>
          </div>

          {!sharedPostId && <RecommendedForYou />}
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        className={"md:w-1/2 md:mt-0"}
        style={{ backgroundColor: "red" }}
      >
        <div className="">
          <div className="flex items-center justify-between mb-[20px]">
            <div className="flex items-center sm:gap-4 gap-3.5">
              <img
                src={profile?.image}
                style={{
                  borderRadius: "40px",
                }}
                className="lg:w-[60px] lg:h-[60px] md:w-[60px] md:h-[60px] w-[45px] h-[45px]"
              />
              <div>
                <h5 className="text-lg font-semibold leading-5 Avenir">
                  {profile?.name
                    ? profile?.name
                    : profile?.firstName + " " + profile?.lastName}
                </h5>
                <p className="text-sm text-gray-400 leading-5 Avenir mt-[3px]">
                  Share with everyone
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="gap-3 flex">
                <button
                  className="border-1 text-sm  border-1 !text-[#73C7A9] leading-5 border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:!text-white "
                  onClick={closeModal}
                >
                  Cancel
                </button>

                <button
                  className={`border-1 text-sm text-white leading-5 bg-[#73C7A9] border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:text-white hover:border-none ${
                    showLoader1 || isPublishButtonDisabled
                      ? "disabled:opacity-50 bg-[#73C7A9] cursor-not-allowed"
                      : ""
                  }`}
                  onClick={() =>
                    Idmodal ? handleUpdatePost() : handleAddPost()
                  }
                  disabled={isPublishButtonDisabled || showLoader1}
                >
                  {Idmodal ? "Update" : "Publish"}
                </button>
              </div>
            </div>
          </div>
          <input type="file" accept="image/*" style={{ display: "none" }} />
          <div className="w-full py-2 border-1 border-gray-200 rounded-md ">
            <div className="flex items-center justify-between border-b-[1px] pb-2 px-2">
              <div className="flex items-center gap-2">
                <label htmlFor="imageInput">
                  <img
                    src={imgicon}
                    alt="Upload Image"
                    onClick={
                      uploadedFiles.length < 5 && videoUploadCheck == false
                        ? openImagePopup
                        : null
                    }
                    style={{
                      cursor:
                        videoUploadCheck || uploadedFiles.length >= 5
                          ? "not-allowed "
                          : "pointer",
                      opacity:
                        videoUploadCheck || uploadedFiles.length >= 5 ? 0.3 : 1,
                    }}
                  />
                </label>

                <img
                  src={playicon}
                  alt=""
                  onClick={uploadedFiles.length < 1 ? openvideoPopup : null}
                  style={{
                    cursor:
                      uploadedFiles.length >= 1 || videoUploadCheck
                        ? "not-allowed"
                        : "pointer",
                    opacity:
                      uploadedFiles.length >= 1 || videoUploadCheck ? 0.3 : 1,
                  }}
                />
                <Modal
                  className="md:w-1/2 mt-0"
                  isOpen={videoOpen}
                  onClose={closevideopopup}
                >
                  <div id="popup">
                    <div className="w-full md:mx-0">
                      <h2 className="text-xl font-medium leading-20 tracking-wider text-[#333333]">
                        Select your video
                      </h2>

                      <div className="mt-3 cursor-pointer h-[300px] top-182 left-450 rounded-6  border-gray-600 flex items-center justify-center border-dashed  border-2 rounded-md">
                        {videoUploading ? (
                          <Loader />
                        ) : uploadedFiles?.length > 0 ? (
                          <video
                            // width="400"
                            controls
                            onLoadedData={() => setVideoLoaded(true)}
                            className="h-full w-full rounded-md"
                          >
                            <source src={uploadedFiles} type="video/mp4" />
                          </video>
                        ) : (
                          <div className="relative">
                            <div className=" flex justify-center mb-3.5">
                              <img
                                src={videoupload}
                                alt=""
                                onChange={handleVideoChange}
                                className="absolute cursor-pointer"
                              />
                              <input
                                type="file"
                                accept="video/*"
                                defaultValue={video}
                                onChange={(e) => handleVideoChange(e)}
                                className="opacity-0 cursor-pointer w-auto h-10"
                              />
                            </div>
                            <div className="mt-3 text-center">
                              Drop your video file here
                            </div>
                            <h2 className="flex justify-center m-3.5">OR</h2>
                            {/* <button className="w-[192px] h-[44px] border border-[#333333]-700 rounded-[100px] text-sm text-[#333333]">
                                          Select video to share{" "}
                                        </button> */}

                            <div className="relative ">
                              <div className="flex justify-center mb-3.5 ">
                                <button
                                  className="w-[192px] h-[44px] border border-black rounded-[100px] text-sm font-semibold text-[#333333] cursor-pointer absolute"
                                  onClick={(e) =>
                                    handleUpload(e?.target?.files)
                                  }
                                >
                                  <div className="cursor-pointer">
                                    Select video to share
                                  </div>
                                </button>

                                <input
                                  type="file"
                                  accept="video/*"
                                  defaultValue={video}
                                  onChange={(e) => handleVideoChange(e)}
                                  className="opacity-0 w-[100px] cursor-pointer w-auto h-10"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-10 flex justify-end gap-3">
                        {!videoUploadCheck && uploadedFiles.length >= 1 && (
                          <div className="relative">
                            <div className="flex justify-center mb-3.5">
                              <img
                                src={plus}
                                alt=""
                                onClick={(e) => handleUpload(e.target.files)}
                                width={40}
                                height={40}
                                className="absolute"
                              />
                              <input
                                type="file"
                                accept="video/*"
                                defaultValue={video}
                                multiple
                                onChange={(e) => handleUpload(e.target.files)}
                                className="opacity-0 w-[100px]"
                              />
                            </div>
                          </div>
                        )}
                        <button
                          className="border-1 text-sm  border-1 text-[#73C7A9] leading-5 border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:text-white hover:border-none"
                          onClick={() => {
                            setvideoOpen(false);
                            setUploadedFiles([]);
                            closeImagepopup();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className={`border-1 text-sm text-white leading-5 border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:text-white hover:border-none ${
                            !videoLoaded
                              ? "disabled:opacity-50 bg-[#73C7A9] cursor-not-allowed"
                              : "bg-[#73C7A9]"
                          }`}
                          onClick={closevideopopup}
                          disabled={!videoLoaded}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal
                  className="md:w-1/2 mt-0"
                  isOpen={imageOpen}
                  onClose={closeImagepopup}
                >
                  <div id="popup">
                    <div
                      className={`bg-white rounded-lg w-full md:mx-0 ${
                        uploadedFiles.length > 4 ? "h-auto" : ""
                      }  `}
                    >
                      <h2 className="text-xl font-medium leading-20 tracking-wider text-[#333333]">
                        Select your Image
                      </h2>

                      <div
                        className={`mt-3  top-182 left-450 rounded-6 flex-col border-gray-600 ${
                          uploadedFiles.length > 0
                            ? "h-auto  border-0"
                            : "flex border-dashed h-[300px]  border-2"
                        }  items-center justify-center  rounded-md`}
                      >
                        <div
                          className={`${
                            uploadedFiles.length > 0
                              ? "border border-gray-600 rounded-lg h-auto"
                              : ""
                          }`}
                        >
                          {uploadedFiles.length > 0 ? (
                            <div className="flex flex-wrap rounded-lg overflow-hidden">
                              {uploadedFiles?.map((preview, index) => (
                                <div
                                  key={index}
                                  className={`w-${
                                    uploadedFiles.length === 1
                                      ? "full h-64"
                                      : index < 2
                                      ? uploadedFiles.length === 2
                                        ? "[50%] h-64"
                                        : index === 0
                                        ? "[66.6%] min-w-2/3 h-32"
                                        : "[33.3%] h-32"
                                      : "[33.3%] h-32"
                                  } `}
                                  onClick={() => setSelectedImage(preview)}
                                >
                                  <img
                                    key={index}
                                    src={preview}
                                    className="w-full h-full object-cover"
                                    alt={`Preview ${index}`}
                                    onLoad={() => setImageLoaded(true)}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="relative">
                              <div className="flex justify-center mb-3.5">
                                <img
                                  src={videoupload}
                                  alt=""
                                  onChange={(e) => handleUpload(e.target.files)}
                                  className="absolute"
                                />
                                <input
                                  type="file"
                                  accept="image/*"
                                  defaultValue={files}
                                  multiple
                                  onChange={(e) => handleUpload(e.target.files)}
                                  className="opacity-0 w-[100px]"
                                />
                              </div>
                              <div className="mt-3 text-center">
                                Drop your Image file here
                              </div>
                              <h2 className="flex justify-center m-3.5">OR</h2>
                              <div className="relative">
                                <div
                                  className="flex justify-center"
                                  style={{ cursor: "pointer" }}
                                >
                                  <button
                                    className="w-[192px] h-[44px] border border-black rounded-[100px] font-semibold text-sm text-[#333333] cursor-pointer hover:!cursor-pointer absolute"
                                    onClick={(e) =>
                                      handleUpload(e?.target?.files)
                                    }
                                  >
                                    Select Image to share
                                  </button>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    defaultValue={files}
                                    multiple
                                    onChange={(e) =>
                                      handleUpload(e.target.files)
                                    }
                                    className="opacity-0 w-auto h-10 cursor-pointer"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {sizeError && (
                          <div className="flex flex-row gap-1 mt-4 align-center self-center">
                            <img
                              src={errorImage}
                              className="rounded-full w-3 h-3"
                            />
                            <p className="text-[#EB5757] text-xs text-center">
                              {sizeError}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="mt-10 flex justify-end">
                        {!videoUploadCheck && uploadedFiles.length >= 1 && (
                          <div className="relative">
                            <div className="flex justify-center mb-3.5">
                              <img
                                src={plus}
                                alt=""
                                onClick={(e) => handleUpload(e.target.files)}
                                width={40}
                                height={40}
                                className="absolute"
                              />
                              <input
                                type="file"
                                accept="image/*"
                                defaultValue={files}
                                multiple
                                onChange={(e) => {
                                  handleUpload(e?.target?.files);
                                }}
                                className="opacity-0 w-[100px]"
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex gap-3">
                          <button
                            className="border-1 text-sm  border-1 text-[#73C7A9] leading-5 border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:text-white hover:border-none"
                            onClick={() => {
                              setUploadedFiles([]);
                              closeImagepopup();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className={`border-1 text-sm text-white leading-5 bg-[#73C7A9] border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:text-white hover:border-none ${
                              !imageLoaded
                                ? "disabled:opacity-50 bg-[#73C7A9] cursor-not-allowed"
                                : ""
                            }`}
                            onClick={closeImagepopup}
                            disabled={!imageLoaded}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <></>
                </Modal>
                <input
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleUpload(e?.target?.files)}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <p className="text-sm text-gray-500 font-medium leading-5">
                  {caption?.length}/400
                </p>
              </div>
            </div>
            <div>
              <textarea
                className="border-gray-300 border-none w-full outline-none p-[20px]"
                placeholder="What do you want to talk about?"
                value={caption}
                rows={7}
                onChange={handleTextChange}
              />

              <div
                className={`${uploadedFiles.length <= 2 ? "" : ""} ${
                  uploadedFiles.length > 2
                    ? `flex flex-wrap relative`
                    : "flex relative max:!p-6 !p-0"
                } `}
              >
                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((data, index) => (
                    <div
                      key={index}
                      className={`w-${
                        uploadedFiles.length === 1
                          ? "full h-64"
                          : index < 2
                          ? uploadedFiles.length === 2
                            ? "[50%] h-64"
                            : index === 0
                            ? "[66.6%] min-w-2/3 h-32"
                            : "[33.3%] h-32"
                          : "[33.3%] h-32"
                      } relative w-`}
                    >
                      {isImage(data) && (
                        <img
                          src={data}
                          className="w-full h-full object-cover"
                          alt=""
                        />
                      )}
                      {isvideo(data, index) && (
                        <video
                          key={index}
                          controls
                          className="h-inherit max-h-full w-full p-4 !rounded-[2rem]"
                          style={{ height: "inherit !important" }}
                        >
                          <source src={data} type="video/mp4" />
                        </video>
                      )}
                      <button
                        className="absolute top-2 right-3 p-1 rounded-full text-white cursor-pointer w-[30px] h-[30px] flex items-center justify-center bg-black"
                        onClick={() => {
                          handleImageRemove(index);
                          setVideoUploadCheck(false);
                        }}
                      >
                        x
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {error && (
            <p className="text-red-500 mt-2">
              Character limit exceeded (max: 400 characters).
            </p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Staff;
