import React, { useState } from "react";
import FindStaff from "./components/findStaff";
import Applicants from "./components/applicants";
import ApplicantJob from "./components/applicantJob";
import JobPosting from "./components/jobPosting";
import JobPost from "./components/jobPost";
import JobEdit from "./components/jobEdit";
import Header from "../components/header";
import "./staffHiring.css";
import Nav from "../../theme_1/Nav";
import { useHistory } from "react-router-dom";
import Button from "../../ui/Button";
import classNames from "classnames";
const StaffHiring = (props) => {
  const [activeClass, setActiveClass] = useState("job");
  const [applicant, setApplicant] = useState(false);
  const [jobPost, setJobPost] = useState(false);
  const [jobEdit, setJobEdit] = useState(false);
  const [applicantJobTile, setApplicantJobTile] = useState("");
  const [cometChat] = useState(localStorage.getItem("token"));
  const [jobDetails, setJobDetails] = useState({});
  const [holdBack, setHoldBack] = useState(0);
  const history = useHistory();

  const handleMenu = (val) => {
    setActiveClass(val);
  };

  const handleApplicant = (jobTitle, jobDetails) => {
    if (jobTitle === "Post New Job") {
      setApplicant(true);
      setJobPost(true);
      setJobEdit(false);
      setApplicantJobTile(jobTitle);
    } else if (jobTitle === "Edit Job") {
      setApplicant(true);
      setJobPost(false);
      setJobEdit(true);
      setApplicantJobTile(jobTitle);
    } else {
      setApplicant(true);
      setJobPost(false);
      setJobEdit(false);
      setApplicantJobTile(jobTitle);
      setJobDetails(jobDetails);
    }
  };

  const handleTitle = (userName) => {
    setApplicantJobTile(userName);
  };

  const goBackStaff = () => {
    setApplicant(false);
    setApplicantJobTile("");
  };

  const redirectTo = () => {
    props.history.push("/main/hospital/chats");
  };

  return (
    <div>
      <Nav />
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div
          className="lg:flex gap-3 block align-top py-4 w-11/12 md:w-3/4 mx-auto flex-col max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          {/* )
           : jobPost ? (
            <JobPost goBack={goBackStaff} applicant={handleApplicant} />
          ) : jobEdit ? (
            <JobEdit goBack={goBackStaff} />
          )  */}
          {!applicant ? (
            <div>
              <div className="flex justify-between w-full mb-3 bg-white overflow-hidden rounded-tr-xl rounded-tl-xl">
                <div
                  className={classNames(
                    "w-full text-center cursor-pointer p-3",
                    {
                      "text-[#73C7A9] border-b-4 border-[#73C7A9]":
                        activeClass === "job",
                    }
                  )}
                  onClick={() => handleMenu("job")}
                >
                  JOB POSTING
                </div>
                <div
                  className={classNames(
                    "w-full text-center cursor-pointer p-3",
                    {
                      "text-[#73C7A9] border-b-4 border-[#73C7A9]":
                        activeClass === "applicants",
                    }
                  )}
                  onClick={() => handleMenu("applicants")}
                >
                  APPLICANTS
                </div>
              </div>

              {/* {activeClass === "find" ? (
                <FindStaff />
              ) :  */}
              {activeClass === "applicants" ? (
                <Applicants
                  holdBack={holdBack}
                  setHoldBack={setHoldBack}
                  applicant={handleApplicant}
                />
              ) : (
                <JobPosting
                  setHoldBack={setHoldBack}
                  applicant={handleApplicant}
                />
              )}
            </div>
          ) : (
            <ApplicantJob
              redirectTo={redirectTo}
              titleChange={handleTitle}
              jobDetails={jobDetails}
              revertBack={() => setApplicant(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffHiring;
