import React, { useState } from "react";
import LightboxComponent from "../../../../ui/Lightbox";

export const AdvertisementCard = ({ data }) => {
  const [readMore, setReadMore] = useState(false);
  const renderTextWithLinks = (text) => {
    const parts = text?.split(/(https?:\/\/[^\s]+)/g);

    return parts.map((part, index) => {
      if (part.match(/https?:\/\/[^\s]+/)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer text-[#73C7A9]"
          >
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };
  return (
    <>
      <div
        className="px-[22px] py-3 border border-gray-100 bg-white sm:w-full"
        style={{
          borderRadius: "10px",
        }}
      >
        <div>
          <div className=" flex justify-between items-start">
            <div className=" flex items-center gap-[20px]">
              <div className="rounded-full w-[54px] h-[54px] overflow-hidden">
                <img
                  src={data?.userId?.image}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col gap-y-[6px]">
                <h1 className="font-medium text-[18px] leading-5 tracking-[0.2px]">
                  {data?.userId?.healthCareInstitution?.name}
                </h1>
                <h1 className="text-gray-500 text-[15px]">
                  {data?.userId?.corporateAddress?.[0]?.street &&
                    `${data?.userId?.corporateAddress?.[0]?.street} ,`}
                  {data?.userId?.corporateAddress?.[0]?.city &&
                    `${data?.userId?.corporateAddress?.[0]?.city} ,`}
                  {data?.userId?.corporateAddress?.[0]?.state &&
                    `${data?.userId?.corporateAddress?.[0]?.state}`}
                </h1>
                <h1 className="text-[#73C7A9] text-[15px] tracking-[0.2px]">
                  Promoted
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="font-normal mt-4 text-container text-[16px] w-full break-words text-gray-500 leading-[24px] tracking-[0.2px]">
              {readMore ? (
                <div
                  className="font-normal mt-4 w-full break-words text-[16px] text-gray-500 "
                  style={{ whiteSpace: "pre-line" }}
                >
                  {data?.caption && renderTextWithLinks(data?.caption)}
                </div>
              ) : (
                <div
                  className="font-normal mt-4 text-container text-[16px] w-full break-words text-gray-500 leading-[24px] tracking-[0.2px]"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {data?.caption &&
                    renderTextWithLinks(data?.caption?.slice(0, 150))}{" "}
                  {data?.caption?.length > 150 && (
                    <>
                      <div
                        onClick={() => setReadMore(true)}
                        className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
                      >
                        ...see more
                      </div>
                    </>
                  )}
                </div>
              )}
              {data?.caption?.length > 100 && readMore && (
                <div
                  onClick={() => setReadMore(false)}
                  className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
                >
                  ...see less
                </div>
              )}
            </div>
            <div className="mt-[18px] flex content-center">
              <LightboxComponent images={[{ src: data?.fileUrl[0] }]} />
            </div>
            {data?.url && (
              <button
                className="text-base font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white mb-2 bg-[#73C7A9] text-white mt-[18px] mb-2"
                onClick={() => window.open(data?.url, "_blank")}
              >
                Click here
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
