import React, { useState, useEffect } from "react";
import edit_note from "../../../assets/images/edit_note.svg";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import home from "../../../assets/images/home.svg";
import place from "../../../assets/images/place.svg";
import Modal from "../../../common/modal";
import Loader from "../../../modals/Loader";
import { axiosInstance } from "../../../../config/axiosConfig";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../common/toastMeassage/ToastMeassage";
const CorporateAddress = ({ hospitalData, setHospitalDetails }) => {
  const [addressDetails, setAddressDetails] = useState({
    zipCode: "",
    street: "",
    city: "",
    state: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState({
    street: "block",
    zipCode: "block",
  });
  const profile = useSelector((state) => state.data.profile);
  const [showZipErr, setShowZipErr] = useState(false);
  const [showStreetErr, setShowStreetErr] = useState(false);
  const openModal = () => {
    setAddressDetails(hospitalData?.corporateAddress[0]);
    setModalOpen(true);
  };
  const closeModal = () => {
    setShowZipErr(false);
    setModalOpen(false);
  };
  useEffect(() => {
    const { zipCode, city, street, state } =
      hospitalData?.corporateAddress[0] || {};
    setAddressDetails({ zipCode, street, city, state });
  }, []);

  const setStreetValue = (e) => {
    setAddressDetails((prev) => ({
      ...prev,
      street: e.target.value,
    }));
  };

  const setCityValue = (e) => {
    setAddressDetails((prev) => ({
      ...prev,
      city: e.target.value,
    }));
    setLocError();
  };
  const setZipCodeValue = (e) => {
    setAddressDetails((prev) => ({
      ...prev,
      zipCode: e.target.value,
    }));
    setLocError(e.target.value);
  };

  const needToSetZip = () => {
    setShowZipErr(true);
  };

  const setLocError = async (val) => {
    var newError = {
      ...error,
    };
    var zip = val || addressDetails?.zipCode;
    if (zip.length !== 5) {
      setAddressDetails((prev) => ({
        ...prev,
        city: "",
        state: "",
      }));
      setShowZipErr(true);
    } else {
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      var res = await axiosInstance.post("v1/LHS/other/getCityState", {
        zip: zip,
      });
      if (res.data?.result === "OK") {
        setAddressDetails((prev) => ({
          ...prev,
          city: res.data.data.city,
          state: res.data.data.state,
        }));
        setShowZipErr(false);
      } else {
        setAddressDetails((prev) => ({
          ...prev,
          city: "",
          state: "",
        }));
        setShowZipErr(true);
      }
    }
    setError(newError);
    return newError;
  };

  const handleSave = async () => {
    let { city, state, street, zipCode } = addressDetails;
    if (!city || !state || !street || !zipCode) {
      showErrorToast("All fields are required!");
      return;
    }
    setShowLoader(true);
    try {
      const updatedData = [...hospitalData?.corporateAddress];
      updatedData[0] = addressDetails;
      const response = await axiosInstance.post(
        `v1/LHS/hospital/update/${hospitalData?._id}`,
        { corporateAddress: updatedData }
      );
      setHospitalDetails(response?.data?.data);
      showSuccessToast(response?.data?.Message);
      closeModal();
    } catch (error) {
      console.error("Save error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className="">
      <div className="" style={{ fontSize: "25px" }}>
        Corporate Office address
      </div>
      <div className="relative t2_sp_work_container p-8 gap-0 mt-2 ">
        <div className="grid grid-cols-2 w-full">
          <div className="flex flex-col gap-4">
            <div className="">
              <h6 style={{ fontSize: 16 }}> Zip Code </h6>
              <h5 className="t2_detail_textbox  text-lg">
                {hospitalData?.corporateAddress[0]?.zipCode}
              </h5>
            </div>
            <div className="">
              <h6 style={{ fontSize: 16 }}> City </h6>
              <h5 className="t2_detail_textbox text-lg">
                {hospitalData?.corporateAddress[0]?.city}
              </h5>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="">
              <h6 style={{ fontSize: 16 }}> Street address </h6>
              <h5 className="t2_detail_textbox text-lg">
                {hospitalData?.corporateAddress[0]?.street}
              </h5>
            </div>
            <div className="">
              <h6 style={{ fontSize: 16 }}> State </h6>
              <h5 className="t2_detail_textbox text-lg">
                {hospitalData?.corporateAddress[0]?.state}
              </h5>
            </div>
          </div>
        </div>
        <div>
          {(profile?.role == "hospital" || profile?.role == "superAdmin") && (
            <img
              alt="icon.svg"
              src={edit_note}
              width="20px"
              className="cursor-pointer absolute top-2 right-2"
              onClick={openModal}
            />
          )}
        </div>
      </div>

      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          className="w-full md:!w-1/2"
        >
          <div>
            <div className="flex justify-between">
              <div className="ssu2_modal1_text1 text-xl">
                Edit Corporate Office Address
              </div>
              {/* <p onClick={closeModal}>X</p> */}
            </div>

            <div className=" ssu2_modal1_input ">
              <div className="text-lg mt-2 p-2">zipcode</div>
              <div className=" ssu2_modal1_input  ssu2_modal3_selectBox ">
                <div className=" input_left_icon ">
                  {/* <img
                        height="15px"
                        src={place}
                        width="18px"
                        alt="location"
                      /> */}
                </div>

                <input
                  className="ssu2_modal3_select pl-5"
                  type="text"
                  placeholder="Zip code"
                  value={addressDetails?.zipCode}
                  defaultValue={addressDetails?.zipCode}
                  name="zipCode"
                  onChange={setZipCodeValue}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleSave();
                  }}
                />
              </div>
              <ErrorState
                show={showZipErr ? error.zipCode : "none"}
                name="Valid zip code is required."
              />
            </div>
            <div className="text-lg p-2">Street address</div>
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal3_selectBox">
                <div className="input_left_icon">
                  {/* <img width="18px" height="15px" alt="img" src={home} /> */}
                </div>
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="Street Address"
                  type="text"
                  value={addressDetails?.street}
                  defaultValue={addressDetails?.street}
                  name="street"
                  onChange={setStreetValue}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleSave();
                  }}
                />
              </div>
              <ErrorState
                show={showStreetErr ? error.street : "none"}
                name="Street address is required."
              />
            </div>
            <div className="text-lg p-2 ">City</div>
            <div
              className="row ssu2_modal1_input m-auto  "
              style={{ flexDirection: "row" }}
            >
              <div
                className="ssu2_modal3_selectBox"
                style={{ padding: 0 }}
                onClick={needToSetZip}
              >
                <div
                  className="ssu2_modal3_selectBox"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  <div className="input_left_icon">
                    {/* <img width="18px" height="15px" alt="img" src={place} /> */}
                  </div>
                  <input
                    className="ssu2_modal3_select pl-5"
                    placeholder="City"
                    type="text"
                    defaultValue={addressDetails?.city}
                    name="City"
                    value={addressDetails?.city}
                    readOnly
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleSave();
                    }}
                  />
                </div>
              </div>
              <div
                className="ssu2_modal3_selectBox mt-3"
                style={{ padding: 0 }}
                onClick={needToSetZip}
              ></div>
              <div className="text-lg p-2">State</div>

              <div className="ssu2_modal3_selectBox hsu1_input2 !p-0">
                <div className="input_left_icon">
                  {/* <img
                        alt="img"
                        src={place}
                        width="18px"
                        height="15px"
                        style={{ marginTop: "-3px" }}
                      /> */}
                </div>
                <input
                  className="ssu2_modal3_select pl-5"
                  placeholder="State"
                  type="text"
                  value={addressDetails?.state}
                  defaultValue={addressDetails?.state}
                  readOnly
                />
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-4">
              <button
                className="w-32 bg-white  border-1 text-[#73C7A9] rounded-[28px] border !border-[#73C7A9] p-2 hover:!bg-[#73C7A9] hover:text-white"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="w-32 text-white bg-[#73C7A9] rounded-[28px] p-2"
                onClick={handleSave}
              >
                {showLoader ? <Loader /> : "Update"}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CorporateAddress;
