import { useState, useEffect, useRef } from "react";

const useSticky = (offsetTop1 = 80) => {
  const [isSticky, setIsSticky] = useState(false);
  const [parentWidth, setParentWidth] = useState(null);
  const stickyElementRef = useRef(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const element = stickyElementRef.current;
    const parentElement = element?.parentElement;

    if (element && parentElement) {
      const offsetTop = offsetTop1;
      setIsSticky(scrollPosition > offsetTop);
      setParentWidth(parentElement.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { isSticky, parentWidth, stickyElementRef };
};

export default useSticky;
