import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import search from "../../assets/images/search.svg";
import avatar from "../../assets/images/avatar.png";
import badge from "../../assets/images/badge.png";
import { removeSession, callApi } from "../../../redux/action/action";
import { axiosInstance } from "../../../config/axiosConfig";

function Header(props) {
  const [type, setType] = useState(props.auth ? props.auth.type : "");
  const [name, setName] = useState(
    props.hospitalName ? props.hospitalName : ""
  );
  const [title, setTitle] = useState("");
  const [avatarImg, setAvatar] = useState(
    props.hospitalAvatar ? props.hospitalAvatar : ""
  );
  const [badgeVisible, setBadgeVisible] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(props.page);
  const [profileClick, setProfileClick] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [getType, setGetType] = useState(props.auth ? props.auth.type : "");
  const [staffName, setStaffName] = useState(
    props.staffName ? props.staffName : ""
  );
  const [staffAvatar, setStaffAvatar] = useState(
    props.staffAvatar ? props.staffAvatar : ""
  );
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalAvatar, setHospitalAvatar] = useState("");

  useEffect(() => {
    const type = localStorage.getItem("type");
    setGetType(type);

    if (type === "staff") {
      const token = localStorage.getItem("accessToken");
      const _id = localStorage.getItem("_id");
      const tokenWithBearer = "Bearer " + token;
      axiosInstance.get("v1/LHS/staff/getById/" + _id)
        .then((res) => {
          console.log(res.data);
          // Update the state as needed
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    setStaffName(props.staffName);
    setStaffAvatar(props.staffAvatar);
    setName(props.hospitalName);
    setAvatar(props.hospitalAvatar);
  }, [props]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleProfileClick = (val) => {
    if (val === "profile") {
      console.log("in if for profile");
      setProfileClick(true);
      window.location.href = `main/staff/profile`;
    } else {
      removeSession();
      window.location.href = "/login";
    }
  };

  const handleOpenAccount = () => {
    setOpenAccount(!openAccount);
  };

  return (
    <div className="theme2_header">
      {page}
      <div className="theme2_header_item">
        <div
          className="theme2_header_avatar_container mb-0"
          onClick={handleOpenAccount}
        >
          <div className="theme2_header_avatar_img">
            {getType === "staff" ? (
              <img
                width="100px"
                src={staffAvatar ? staffAvatar : avatar}
                alt="avatar.png"
                style={{ borderRadius: "50%" }}
              />
            ) : (
              <img
                src={avatarImg ? avatarImg : avatar}
                alt="avatar.png"
                style={{ borderRadius: "50%" }}
              />
            )}

            <img src={badge} alt="badge.png" className="theme2_header_badge" />
            <div
              className={openAccount ? "rectangle" : "rectangle openAccount"}
            ></div>
            <div className={openAccount ? "account" : "account openAccount"}>
              <Link
                style={{
                  textDecoration: "none",
                  marginBottom: "15px",
                  display: "flex",
                }}
                to={`/main/${getType}/profile`}
                className="profile"
              >
                Profile
              </Link>
              <p
                className="logout"
                onClick={() => handleProfileClick("logout")}
              >
                Logout
              </p>
            </div>
          </div>
          <b className="theme2_header_avatar_name">
            {getType === "staff" ? staffName : name}
          </b>
        </div>
        <div className="searchImag">
          <img
            className="theme2_header_search_img"
            alt="search.svg"
            src={search}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
