import React, { useState, useEffect, useRef } from "react";
import "./applicants.css";
// import filter_img from "../../../assets/images/filter.png";
import filter_img from "../../../assets/images/filter_img.svg";
import searchImg from "../../../assets/images/search.svg";
import Pagination from "../../admin/pagination";
import { axiosInstance } from "../../../../config/axiosConfig";
import Loader from "../../../modals/Loader";
import DataTable from "../../../ui/DataTable";

const Applicants = (props) => {
  const [filter, setFilter] = useState({
    page: 0,
    searchValue: "",
    sortingKey: "",
    limit: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [jobs, setJobs] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);

  const getAllApplications = async () => {
    try {
      setLoader(true);
      const res = await axiosInstance.get(
        `v1/LHS/job/getByName?skip=${props.holdBack * 10}&search=${
          filter?.searchValue
        }&sort=${filter?.sortingKey}&limit=${filter?.limit}`
      );
      if (res.data?.status == 200) {
        setJobs(res.data.data.job);
      }
      setTotalRecords(res.data.data.count);
    } catch (error) {
      setLoader(false);
      setJobs([]);
      setTotalRecords(0);
      setJobs([]);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllApplications();
  }, [filter]);

  // const handleSorting = async (sort) => {
  //   setFilter((prev) => ({ ...prev, sortingKey: sort.target.value }));
  // };

  const handleApplicant = (jobTitle, jobDetails) => {
    props?.applicant(jobTitle, jobDetails);
  };

  const debounceTimer = useRef(null);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = null;
    }
    debounceTimer.current = setTimeout(() => {
      setFilter((prev) => ({
        ...prev,
        searchValue: value,
        limit: value?.length > 0 ? 99999999 : 10,
      }));
      props.setHoldBack(0);
    }, 300);
  };

  const columns = [
    {
      title: "Job Title",
      key: "jobTitle",
    },
    {
      title: "Job Type",
      key: "contractType",
    },
    {
      title: "Positions",
      key: "openPositions",
    },
    {
      title: "Healthcare Institution Location",
      key: "healthCareLocation",
    },
    {
      title: "Applicants",
      key: "jobTitle",
      render: (item) => {
        return <>{item?.appliedBy?.length}</>;
      },
    },
  ];

  const onPageChange = (e) => {
    setFilter((prev) => ({ ...prev, page: e - 1 }));
    props.setHoldBack(e - 1);
  };
  const [sortOptionText, setSortOptionText] = useState("Sort by");
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const handleSorting = (sortValue) => {
    let optionText = "Latest First";
    if (sortValue === "asc") {
      optionText = " Oldest First";
    }
    setFilter((prev) => ({ ...prev, sortingKey: sortValue }));
    setSortOptionText(optionText);
    setSortDropdownOpen(false);
    getAllApplications();
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSortDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="bg-white overflow-hidden rounded-tr-xl rounded-tl-xl">
      <div className="flex items-center justify-between p-4">
        <div className="w-3/5 top_NSearch overflow-hidden">
          <div className="flex gap-3 w-full">
            <img className="w-5" alt="search.svg" src={searchImg} />
            <input
              className="w-full"
              placeholder="Search By Job Title"
              type="text"
              value={searchText}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div
            className="relative flex items-center cursor-pointer border-1 border-[#73C7A9] rounded-full px-7 py-2 appearance-none"
            ref={dropdownRef}
          >
            <div className="dropdown">
              <span
                className="dropdown-btn "
                onClick={() => setSortDropdownOpen(!sortDropdownOpen)}
              >
                <div className="mr-2">{sortOptionText}</div>
              </span>
              {sortDropdownOpen && (
                <div className="dropdown-content absolute bg-white shadow-md mt-3 ml-4 w-full z-40 rounded-lg">
                  <div onClick={() => handleSorting("desc")}>Latest First</div>
                  <div onClick={() => handleSorting("asc")}>Oldest First</div>
                </div>
              )}
            </div>
            <img src={filter_img} className="w-5 absolute right-2" />
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={jobs}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        onSearch={null}
        holdPageState={props.holdBack}
        title={(item) => handleApplicant(item.jobTitle, item)}
        loader={loader}
        // moveBack={moveBack}
      />
    </div>
  );
};

export default Applicants;
