import React, { useState, useEffect, useRef } from "react";
import "./findStaff.css";
import "./applicantJob.css";
import { axiosInstance } from "../../../../config/axiosConfig";
import searchImg from "../../../assets/images/search.svg";
import DataTable from "../../../ui/DataTable";
import ApplicantData from "./ApplicantData";
import RateReviewModal from "../../../containers/staff/rate_review_modal";

function ApplicantJob(props) {
  const [filter, setFilter] = useState({
    page: 0,
    searchValue: "",
    sortingKey: "",
    limit: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [jobApplicantsList, setJobApplicantsList] = useState([]);
  const [modalOpens, setModalOpens] = useState(false);
  const [applicantDataId, setApplicantDataId] = useState();
  const [reviewUserData, setReviewUserData] = useState({});
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  const getAllReviews = async (id) => {
    try {
      setLoader(true);
      setShowReviewModal(true);
      const data = await axiosInstance.get(
        `v1/LHS/rating/getStaffRating/${id}`
      );
      if (data.status === 200) {
        setReviewData(data.data.data);
      }
    } catch (err) {
      setReviewData([]);
    } finally {
      setLoader(false);
    }
  };

  const getAllApplicationsList = async () => {
    try {
      setLoader(true);
      const res = await axiosInstance.get(
        `v1/LHS/hospital/viewJobApplicant/?job=${props.jobDetails._id}&skip=${
          filter?.page * 10
        }&search=${filter?.searchValue}&sort=${filter?.sortingKey}&limit=${
          filter?.limit
        }`
      );

      if (res.status === 200) {
        setJobApplicantsList(res.data.data?.data);
        setTotalRecords(res.data?.data?.count);
      }
    } catch (error) {
      setJobApplicantsList([]);
      setTotalRecords(0);
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllApplicationsList();
  }, [filter]);

  const handleApplicant = (item, index) => {
    setModalOpens(true);
    setApplicantDataId(item?._id);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
    },
    {
      title: "Job title",
      key: "jobTitle",
    },
    {
      title: "Location",
      key: "currentLocation",
      render: (item) => {
        return (
          <>
            {item?.currentLocation?.name}, {item?.currentLocation?.state}
          </>
        );
      },
    },
    {
      title: "Reviews",
      key: "ratingTotal",
      render: (item) => {
        return (
          <div className="flex gap-2 flex-col items-center">
            <p>{item?.ratings?.totalRatings}</p>
            {item?.ratings?.totalRatings > 0 && (
              <div
                className="text-[#73C7A9] text-[14px] cursor-pointer self-center"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  getAllReviews(item?.staff);
                  setReviewUserData(item);
                }}
              >
                (See all)
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Medical Settings",
      key: "medicalSettings",
    },
    {
      title: "Status",
      key: "status",
      render: (item) => {
        return <div className="text-[#73C7A9]">{item?.status}</div>;
      },
    },
  ];

  const onPageChange = (e) => {
    setFilter((prev) => ({ ...prev, page: e - 1 }));
  };

  const debounceTimer = useRef(null);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = null;
    }
    debounceTimer.current = setTimeout(() => {
      setFilter((prev) => ({
        ...prev,
        page: 0,
        searchValue: value,
        limit: value?.length > 0 ? 99999999 : 10,
      }));
    }, 300);
  };

  return (
    <div className="">
      <div className="bg-white overflow-hidden rounded-tr-xl rounded-tl-xl flex gap-4 mb-3 p-3 items-center">
        <div className="cursor-pointer rounded-md" onClick={props?.revertBack}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 30 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30 10.927H6.23656L14.8599 2.30368L12.5562 0L0 12.5562L12.5562 25.1124L14.8599 22.8087L6.23656 14.1854H30V10.927Z"
              fill="#000"
            />
          </svg>
        </div>
        <p className="self-center text-lg">{props?.jobDetails?.jobTitle}</p>
      </div>
      <div className="bg-white overflow-hidden rounded-tr-xl rounded-tl-xl">
        <div className="flex items-center justify-between p-4">
          <div className="w-3/5 top_NSearch overflow-hidden">
            <div className="flex gap-3 w-full">
              <img className="w-5" alt="search.svg" src={searchImg} />
              <input
                className="w-full"
                placeholder="Search By Name"
                type="text"
                value={searchText}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={jobApplicantsList}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
          title={(item, index) => handleApplicant(item, index)}
          loader={loader}
          holdPageState={filter?.page}
          // moveBack={moveBack}
        />
      </div>
      <ApplicantData
        loading={loader}
        data={jobApplicantsList.find(
          (applicant) => applicant._id === applicantDataId
        )}
        modalOpen={modalOpens}
        jobDetails={props?.jobDetails}
        getAllApplicationsList={getAllApplicationsList}
        modalClose={() => setModalOpens(false)}
      />
      <RateReviewModal
        isOpen={showReviewModal}
        onClose={() => setShowReviewModal(false)}
        className={"md:w-1/2 md:mt-0"}
        reviewData={reviewData}
        loader={loader}
        data={reviewUserData}
      />
    </div>
  );
}

export default ApplicantJob;
