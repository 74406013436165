import React, { useState } from "react";
import ErrorState from "../staffSignUp/components/errorState";
import logo from "../../assets/images/loginLogo.png";
import ChangePassword from "./ChangePassword";
import { callApi } from "../../../redux/action/action";
import { Link } from "react-router-dom";
import Loader from "../../modals/Loader";
import { showSuccessToast } from "../../common/toastMeassage/ToastMeassage";
import "./logIn.css";
import emailIcon from "../../assets/images/email.svg";

const LogIn = ({ history }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("none");
  const [showpassword, setShowPassword] = useState(true);
  const [userId, setUserId] = useState("");
  const [role, setRole] = useState("");
  const [errorMsg, setErrorMsg] = useState("Valid Email Address is required");
  const [showLoader, setShowLoader] = useState(false);
  const [res, setRes] = useState(false);

  const continueToNext = async () => {
    if (email === "") {
      setError("block");
      setErrorMsg("Valid email address is required");
      setShowLoader(false);
    } else if (error !== "block") {
      setShowLoader(true);
      const data = {
        email: email,
      };

      const response = await callApi(
        "POST",
        "v1/LHS/general/forgetPassword",
        null,
        data
      );
      handleLogin(response);
    }
  };

  const handleLogin = (response) => {
    if (response.type === "OK") {
      showSuccessToast("OTP Sent Successfully..");
      setShowPassword(false);
      setUserId(response.userId);
      setRole(response.role);
      setShowLoader(false);
      // setTimeout(() => {
      //   history.push("/login");
      // }, 5000);
    } else {
      setError("block");
      setShowLoader(false);
      setErrorMsg("Email address not found");
    }
  };

  return (
    <div
      className="d-flex h-screen overflow-hidden"
      style={{ backgroundColor: "#73C7A9" }}
    >
      <div
        className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
        style={{ padding: "0", maxWidth: 720 }}
      >
        <div className="ssu_container p-5 max-h-[90vh] h-auto">
          <p className="flex items-center justify-center">
            <img src={logo} alt="img" className="logInLogo" />
          </p>

          {showpassword && (
            <div className="logIn_txt1 font-semibold text-2xl mt-4">
              Forgot Password?
            </div>
          )}
          {showpassword ? (
            <div className="logIn_txt2  mt-1 forgotPassword_txt2 text-base">
              Enter the email address associated with your account, and we’ll
              email you otp to reset your password.
            </div>
          ) : (
            ""
          )}
          <>
            {" "}
            {showpassword ? (
              <div className="logIn_body mt-3 w-auto">
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div className="input_left_email_icon">
                    <img width="18px" height="15px" alt="img" src={emailIcon} />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="Email Address"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") continueToNext();
                    }}
                    onClick={() => {
                      setError("none");
                      setShowLoader(false);
                    }}
                  />
                </div>
                <ErrorState show={error} name={errorMsg} />
                <div
                  className="logIn_button signUp_button mt-5"
                  onClick={continueToNext}
                >
                  {showLoader ? <Loader /> : "SEND OTP"}
                </div>
                <Link
                  className="logIn_button mt-3 hover:!text-white"
                  to={"/logIn"}
                >
                  GO BACK TO LOGIN
                </Link>
              </div>
            ) : (
              <>
                <ChangePassword userId={userId} role={role} history={history} />
                {/* <div
                  className="logIn_button"
                  onClick={() => setShowPassword(true)}
                >
                  GO BACK1
                </div> */}
                <Link
                  className="logIn_button mt-3 hover:!text-white"
                  to={"/logIn"}
                >
                  GO BACK
                </Link>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
