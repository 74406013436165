import React, { useState, useEffect } from "react";
import AddEditJob from "./AddEditJob";
import Nav from "../../theme_1/Nav";

const postnewjob = (props) => {
  return (
    <>
      <Nav />
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div
          className="lg:flex gap-3 block align-top py-4 w-11/12 md:w-3/4 mx-auto flex-col max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <AddEditJob />
        </div>
      </div>
    </>
  );
};

export default postnewjob;
