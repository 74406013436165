import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { removeSession } from "../../../redux/action/action";
import "../global.css";
import Modal from "../../common/modal";

const MyProfileHeader = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dropdownRef = useRef(null);

  const closeRightMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        closeRightMenu();
      }
    };

    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, closeRightMenu]);

  return (
    <div className="bg-[#FFFFFF]">
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        className={"md:w-1/3 md:mt-0"}
      >
        <div className="bg-white">
          <div className="">
            <h1 className="font-semibold text-xl text-center mb-4">Logout </h1>
            <p className="text-center text-lg m-auto mb-5">
              Are you sure you want to Logout?
            </p>

            <div className="text-center">
              <button
                onClick={() => setModalOpen(false)}
                className="border-2  border-1 border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white w-32 h-11 ml-4"
              >
                Cancel
              </button>
              <button
                className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                onClick={() => {
                  history.push("/login");
                  localStorage.removeItem("accessToken");
                  removeSession();
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyProfileHeader;
