import successToastImage from "../../assets/images/successToast.svg";
import warningToastImage from "../../assets/images/warningToast.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ToastMeassage.css";
const toastStyles = {
  success: "toast-success",
  error: "toast-error",
};

// Function to show a success toast notification
export const showSuccessToast = (message) => {
  toast.success(message, {
    className: toastStyles.success,
    icon: ({ theme, type }) => <img src={successToastImage} alt="Success" />,
    hideProgressBar: true,
    autoClose: 2500,
  });
};

export const showWarningToast = (message) => {
  toast.warning(message, {
    className: toastStyles.success,
    icon: ({ theme, type }) => <img src={warningToastImage} alt="Success" />,
    hideProgressBar: true,
    autoClose: 2500,
    closeOnClick: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

// Function to show an error toast notification
export const showErrorToast = (message) => {
  toast.error(message, {
    className: toastStyles.error,
    hideProgressBar: true,
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
