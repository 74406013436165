import classNames from "classnames";
import Loader from "../modals/Loader";
import Pagination from "./Pagination";

const DataRender = ({ item, column }) => {
  if (column && column.render) {
    return column.render(item);
  }

  return item ? item[column.key] || "" : "";
};

const DataTable = ({
  columns = [],
  data = [],
  totalRecords = 0,
  onPageChange,
  title = false,
  loader = false,
  holdPageState,
  noDataFoundText = "",
  moveBack = 0,
}) => {
  return (
    <div className="">
      <div className="max-w-full overflow-x-auto border-t border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <table className="w-full table-auto relative">
          <thead>
            <tr className="text-left dark:bg-meta-4 font-bold">
              {columns
                .filter((column) => column)
                .map((column, index) => (
                  <th
                    key={index}
                    className="py-4 px-4 text-sm border-b border-[#eee]"
                  >
                    {column.title}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && !loader && (
              <tr>
                <td
                  colSpan={columns.length}
                  className="py-4 px-4 text-black border border-[#eee] text-center"
                >
                  {noDataFoundText || "No data found"}
                </td>
              </tr>
            )}
            {loader && (
              <tr>
                <td colSpan={columns.length}>
                  <Loader className="my-4 mx-auto" />
                </td>
              </tr>
            )}
            {data.map((item, index) => (
              <tr
                key={index}
                className={classNames("", {
                  "hover:bg-gray-100": index % 2 === 0,
                  "hover:bg-gray-50": index % 2 !== 0,
                })}
              >
                {columns
                  .filter((column) => column)
                  .map((column, colIndex) => (
                    <td
                      key={colIndex}
                      className="py-4 px-4 border-b border-[#eee] truncate"
                    >
                      {title && column.key !== "action" ? (
                        <div
                          className="font-medium cursor-pointer"
                          onClick={() => title(item, index)}
                        >
                          <DataRender item={item} column={column} />
                        </div>
                      ) : (
                        <DataRender item={item} column={column} />
                      )}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>

        {totalRecords > 0 && (
          <Pagination
            totalRecords={totalRecords}
            onPageChange={(e) => {
              if (onPageChange) {
                onPageChange(e.selected + 1);
              }
            }}
            holdPageState={holdPageState}
            moveBack={moveBack}
          />
        )}
      </div>
    </div>
  );
};

export default DataTable;
