import React, { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import key from "../../assets/images/key.svg";
import ErrorState from "../../theme_1/staffSignUp/components/errorState";
import Loader from "../../modals/Loader";
import "../theme2.css";
import { axiosInstance } from "../../../config/axiosConfig";
import { removeSession } from "../../../redux/action/action";

const ChangePassword = () => {
  const [psw, setPsw] = useState("");
  const [repsw, setRepsw] = useState("");
  const [cpsw, setCpsw] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState({
    psw: "none",
    repsw: "none",
    cpsw: "none",
    otp: "none",
  });
  const [cpswErr, setCpswErr] = useState("Password is required");
  const [pswErr, setPswErr] = useState("Password is required");
  const [otpErr, setOtpErr] = useState("OTP is required");
  const [showConfirmPwdBtn, setShowConfirmPwdBtn] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [pswLoader, setPswLoader] = useState(false);

  const togglePsw = (id) => {
    const val = document.getElementById(id);
    if (val.type === "password") {
      val.type = "text";
      document.getElementById(`c_${id}`).style.display = "none";
      document.getElementById(`o_${id}`).style.display = "block";
    } else {
      val.type = "password";
      document.getElementById(`o_${id}`).style.display = "none";
      document.getElementById(`c_${id}`).style.display = "block";
    }
  };

  const setPassword = (e) => {
    setPsw(e.target.value);
    const password = e.target.value;
    const newError = { ...error };

    if (password.length === 0) {
      setPswErr("Password is required.");
      newError.psw = "block";
      setError(newError);
      return;
    } else if (password.length < 6) {
      setPswErr(
        "Password should have a minimum of 6 characters, 1 uppercase, 1 lowercase, 1 special character, and 1 number."
      );
      newError.psw = "block";
      setError(newError);
    } else {
      const matched = [];
      matched.push("[$@$!%*#?&]"); // Special Character
      matched.push("[A-Z]"); // Uppercase Alphabets
      matched.push("[0-9]"); // Numbers
      matched.push("[a-z]"); // Lowercase Alphabets

      let ctr = 0;
      for (let i = 0; i < matched.length; i++) {
        if (new RegExp(matched[i]).test(password)) {
          ctr++;
        }
      }

      switch (ctr) {
        case 0:
        case 1:
        case 2:
        case 3:
          setPswErr(
            "Password should have a minimum of 6 characters, 1 uppercase, 1 lowercase, 1 special character, and 1 number."
          );
          newError.psw = "block";
          setError(newError);
          break;
        case 4:
          newError.psw = "none";
          setError(newError);
          break;
        default:
          return;
      }
    }

    if (repsw !== "") {
      const validate = repsw === e.target.value;
      const newError = { ...error };

      if (validate) {
        newError.repsw = "none";
        setError(newError);
      } else {
        newError.repsw = "block";
        setError(newError);
      }
    }
  };

  const setCpsw1 = (e) => {
    setCpsw(e.target.value);
    const password = e.target.value;
    const newError = { ...error };

    if (password.length === 0) {
      setCpswErr("Password is required.");
      newError.cpsw = "block";
      setError(newError);
      return;
    } else if (password.length < 6) {
      setCpswErr(
        "Password should have a minimum of 6 characters, 1 uppercase, 1 lowercase, 1 special character, and 1 number."
      );
      newError.cpsw = "block";
      setError(newError);
    } else {
      const matched = [];
      matched.push("[$@$!%*#?&]"); // Special Character
      matched.push("[A-Z]"); // Uppercase Alphabets
      matched.push("[0-9]"); // Numbers
      matched.push("[a-z]"); // Lowercase Alphabets

      let ctr = 0;
      for (let i = 0; i < matched.length; i++) {
        if (new RegExp(matched[i]).test(password)) {
          ctr++;
        }
      }

      switch (ctr) {
        case 0:
        case 1:
        case 2:
        case 3:
          setCpswErr(
            "Password should have a minimum of 6 characters, 1 uppercase, 1 lowercase, 1 special character, and 1 number."
          );
          newError.cpsw = "block";
          setError(newError);
          break;
        case 4:
          newError.cpsw = "none";
          setError(newError);
          break;
        default:
          return;
      }
    }
  };

  const setConfirmPsw = (e) => {
    const validate = psw === e.target.value;
    setRepsw(e.target.value);
    const newError = { ...error };

    if (e.target.value !== "" && validate) {
      newError.repsw = "none";
      setError(newError);
    } else if (e.target.value === "" && psw === "") {
      newError.repsw = "none";
      setError(newError);
    } else {
      newError.repsw = "block";
      setError(newError);
    }
  };

  const setOtp1 = (e) => {
    setOtp(e.target.value);
    const newError = { ...error };

    if (e.target.value !== "") {
      newError.otp = "none";
      setError(newError);
    } else {
      newError.otp = "block";
      setError(newError);
    }
  };

  const sendOtp = async () => {
    setOtpLoader(true);
    const token = localStorage.getItem("accessToken");
    const data = await axiosInstance.post("v1/LHS/otp/send");

    if (data.status === 200) {
      alert("OTP sent successfully to your email address");
      setShowConfirmPwdBtn(true);
      setOtpLoader(false);
    }
  };

  const savePassword = async () => {
    const pswError = error.psw;
    const repswError = error.repsw;
    const otpError = error.otp;

    if (pswError === "") {
      setPswErr("Password is required");
    }

    const valid = psw === repsw;

    if (!valid && pswError !== "") {
      repswError = "block";
    }

    let pswErr = pswError === "block" || psw === "" ? "block" : "none";
    let otpErr = otpError === "block" || otp === "" ? "block" : "none";

    const newError = {
      psw: pswErr,
      repsw: repswError,
      otp: otpErr,
    };

    setError(newError);

    if (
      psw !== "" &&
      repsw !== "" &&
      otp !== "" &&
      pswErr === "none" &&
      otpErr === "none" &&
      repswError === "none" &&
      valid
    ) {
      setPswLoader(true);
      const data = {
        password: psw,
        otp,
      };
      const res = await axiosInstance.put("v1/LHS/admin/changePassword");
      if (res.Message === "Password Updated Successfully..") {
        removeSession();
        setPsw("");
        setCpsw("");
        setRepsw("");
        setOtp("");
        setPswLoader(false);
        alert("Password updated");
        window.location.href = "/login";
      } else {
        alert("Failed to Change Password.");
        setPswLoader(false);
      }
    }
  };

  return (
    <>
      <div className="t2_sp_work mt-3">
        <div className="t2_sp_work_title">Change Password</div>
        <div className="row t2_sp_work_container">
          <div
            className="ssu2_modal1_input"
            style={{ maxWidth: 600, marginTop: 60 }}
          >
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                <img width="18px" height="15px" alt="img" src={key} />
              </div>
              <input
                className="ssu3_password"
                id="psw"
                placeholder="New Password"
                type="password"
                value={psw}
                onChange={setPassword}
                onKeyUp={(e) => {
                  if (e.key === "Enter") savePassword();
                }}
              />
              <div
                className="ssu3_eye"
                onMouseDown={() => togglePsw("psw")}
                onMouseUp={() => togglePsw("psw")}
                onTouchStart={() => togglePsw("psw")}
                onTouchEnd={() => togglePsw("psw")}
              >
                <VisibilityOffIcon style={{ display: "block" }} id="c_psw" />
                <VisibilityIcon style={{ display: "none" }} id="o_psw" />
              </div>
            </div>
            <ErrorState name={pswErr} show={error.psw} />
          </div>
          <div
            className="ssu2_modal1_input"
            style={{ maxWidth: 600, marginTop: 0 }}
          >
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                <img height="15px" width="18px" alt="img" src={key} />
              </div>
              <input
                className="ssu3_password"
                id="rpsw"
                placeholder="Confirm New password"
                type="password"
                value={repsw}
                onChange={setConfirmPsw}
                onKeyUp={(e) => {
                  if (e.key === "Enter") savePassword();
                }}
              />
              <div
                className="ssu3_eye"
                onMouseDown={() => togglePsw("rpsw")}
                onTouchStart={() => togglePsw("rpsw")}
                onMouseUp={() => togglePsw("rpsw")}
                onTouchEnd={() => togglePsw("rpsw")}
              >
                <VisibilityOffIcon id="c_rpsw" style={{ display: "block" }} />
                <VisibilityIcon id="o_rpsw" style={{ display: "none" }} />
              </div>
            </div>
            <ErrorState
              name="Doesn't match with the password."
              show={error.repsw}
            />
          </div>
          <div
            className="ssu2_modal1_input"
            style={{ maxWidth: 600, marginTop: 0 }}
          >
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_icon">
                <img width="18px" height="15px" alt="img" src={key} />
              </div>
              <input
                className="ssu3_password"
                id="cpsw"
                placeholder="Enter OTP"
                type="text"
                value={otp}
                onChange={setOtp}
                onKeyUp={(e) => {
                  if (e.key === "Enter") savePassword();
                }}
              />
            </div>
            <ErrorState name={otpErr} show={error.otp} />
          </div>
          <div
            style={{ width: "100%" }}
            className="ssu2_modal1_input d-flex justify-content-between align-items-center"
          >
            <div
              className="ssu_button w-25"
              style={{ marginLeft: "148px" }}
              onClick={sendOtp}
            >
              {otpLoader ? <Loader /> : "SEND OTP"}
            </div>
            {showConfirmPwdBtn ? (
              <div
                className="ssu_button w-25"
                onClick={savePassword}
                style={{ marginRight: "150px" }}
              >
                {pswLoader ? <Loader /> : "Change Password"}
              </div>
            ) : null}
          </div>
          {showConfirmPwdBtn ? (
            <p className="text-center" style={{ fontSize: "15px" }}>
              OTP is valid for only 10 minutes.
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
