// src/redux/reducers.js

const profileData = {
  update: null,
  profile: null,
  notificationCount: 0,
  leaveRoom: { ChatId: "", loggedin_id: "", userrtype: "" },
};

const checkoutInitialState = {
  advertisementData: null,
  bookingData: null,
  paymentData: null,
};

export const stepReducer = (state = 0, action) => {
  switch (action.type) {
    case "INCREMENT_STEP":
      return state + 1;
    case "DECREMENT_STEP":
      return state - 1;
    case "RESET_STEPS":
      return 0;
    default:
      return state;
  }
};

export const dataReducer = (state = profileData, action) => {
  switch (action.type) {
    case "UPDATE_DATA":
      return { ...state, ...action.payload };
    case "PROFILE_DATA":
      return { ...state, profile: action.payload };
    case "NOTIFICATION_COUNT":
      return { ...state, notificationCount: action.payload };
    case "LEAVE_ROOM":
      return { ...state, leaveRoom: action.payload };
    default:
      return state;
  }
};

export const checkoutReducer = (state = checkoutInitialState, action) => {
  switch (action?.type) {
    case "SET_ADVERTISEMENT_DATA":
      return { ...state, advertisementData: action.payload };
    case "SET_BOOKING_DATA":
      return { ...state, bookingData: action.payload };
    case "SET_PAYMENT_DATA":
      return { ...state, paymentData: action.payload };
    case "CLEAR_BOOKING_DATA":
      return { ...state, paymentData: null, bookingData: null };
    case "CLEAR_CHECKOUT_DATA":
      return checkoutInitialState;
    default:
      return state;
  }
};
