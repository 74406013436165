import React from "react";
import Header from "./components/header";
import Dashboard from "./hospitalProfile/Dashboard";

const HospitalDashboard = () => {
  return (
    <div className="theme2">
      <div className="theme2_container">
        <Header />
        <div className="theme2_main_container">
          <Dashboard />
        </div>
      </div>
    </div>
  );
};

export default HospitalDashboard;
