import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./components/privteRoute/PriveteRoute";
import Staff from "./components/theme_1/main/staff";
import signUp from "./components/theme_1/logIn/signUp";
import staffSignUp from "./components/theme_1/staffSignUp/staffSignUp";
import staffSignUp_1 from "./components/theme_1/staffSignUp/staffSignUp_1";
import staffSignUp_2 from "./components/theme_1/staffSignUp/staffSignUp_2";
import staffSignUp_3 from "./components/theme_1/staffSignUp/staffSignUp_3";
import staffSignUp_4 from "./components/theme_1/staffSignUp/staffSignUp_4";
import logIn from "./components/theme_1/logIn/logIn";
import forgotPassword from "./components/theme_1/logIn/forgotPassword";
import hospSignUp_1 from "./components/theme_1/hospSignUp/hospSignUp_1";
import hospSignUp_2 from "./components/theme_1/hospSignUp/hospSignUp_2";
import hospSignUp_3 from "./components/theme_1/hospSignUp/hospSignUp_3";
import PaymentSuccess from "./components/theme_1/main/PaymentSuccess";
import homeDetails from "./components/theme_1/main/homeDetails";
import hospital from "./components/theme_1/main/hospital";
import aboutUs from "./components/theme_1/main/aboutUs";
import Notification from "./components/theme_1/main/notification";
import landing from "./components/theme_1/landing";
import HospitalDashboard from "./components/theme_2/HospitalDashboard";
import Dashboard from "./components/theme_2/staffProfile/Dashboard";
import staffProfile from "./components/theme_2/staffProfile";
import FindJobs from "./containers/staff/findJobs/FindJobs";
import MyJob from "./containers/staff/myJobs/myJob";
import JobDetails from "./containers/staff/jobDetails/JobDetails";
import Chat from "./containers/staff/chats/chat";
import Attendance from "./containers/staff/Attendance/Attendance";
import AttendanceDetail from "./containers/staff/Attendance/AttendanceDetail";
import JobOfferDetails from "./containers/staff/jobDetails/JobOfferDetails";
import manageHospitalProfile from "./components/theme_2/admin/components/manageHospitalProfile";
import ManageHospitalEdit from "./components/theme_2/admin/components/manageHospitalEdit";
import ManageAttendance from "./components/theme_2/admin/manageAttendance";
import StaffAttendance from "./components/theme_2/admin/staffAttendance";
import ManageHospital from "./components/theme_2/admin/manageHospital";
import ManageStaffEdit from "./components/theme_2/admin/components/manageStaffEdit";
import ManageStaff from "./components/theme_2/admin/manageStaff";
import ManageAdmin from "./components/theme_2/admin/manageAdmin";
import ManageAdminAddEdit from "./components/theme_2/admin/manageAdminAddEdit";
import ChangePassword from "./components/theme_2/staffProfile/changePassword";
import HospitalJobs from "./components/theme_2/admin/hospitalJobs";
import StaffList from "./components/theme_2/admin/staffList";
import SuperAdmin from "./components/theme_1/main/superAdmin";
import LikedPostList from "./components/theme_1/main/likedPostList";
import StaffMyJob from "./components/theme_2/staffProfile/components/StaffMyJob";
import StaffContactInformation from "./components/theme_2/staffProfile/components/StaffContactInformation";
import StaffResume from "./components/theme_2/staffProfile/components/StaffResume";
import StaffAccountInformation from "./components/theme_2/staffProfile/components/StaffAccountInformation";
import StaffChangePassword from "./components/theme_2/staffProfile/components/StaffChangePassword";
import LikedCommentList from "./components/theme_1/main/likedCommentList";
import ReplayList from "./components/theme_1/main/replayList";
import Jobs from "./components/theme_1/logIn/jobs";
import store from "./store";
import ChatSection from "./components/theme_2/staffProfile/components/chatsection";
import JobPosting from "./components/theme_2/hospitalProfile/components/jobPosting";
import ManageStaffProfile from "./components/theme_2/admin/components/manageStaffProfile";
import postnewjob from "./components/theme_1/main/postnewjob";
import staffMyJobDetails from "./components/theme_2/staffProfile/components/staffMyJobDetails";
import MyJobDetails from "./components/theme_2/staffProfile/components/MyJobDetails";
import AdminDashBoard from "./components/theme_2/admin/dashboard";
import NewAdministrators from "./components/theme_2/admin/NewAdministrators";
import StaffProfile from "./components/theme_2/admin";
import StaffHiring from "./components/theme_2/hospitalProfile/StaffHiring";
import MyStaff from "./components/theme_2/hospitalProfile/components/MyStaff";
import HospitalProfile from "./components/theme_2/admin/components/manageHospitalProfile";
import ManageAdiministart from "./components/theme_2/admin/ManageAdiministart";
import Meassge from "./components/theme_1/main/Meassge";
import HospitalMsg from "./components/theme_1/main/HospitalMsg";
import UserHospital from "./components/theme_1/main/UserHospital";
import HospitalChatBox from "./components/theme_1/main/HospitalChatBox";
import EditJob from "./components/theme_1/main/EditJob";
import RecommendedJobs from "./components/theme_2/staffProfile/components/RecommendedJobs";
import { ToastContainer } from "react-toastify";
import io from "socket.io-client";
import SharedPost from "./components/theme_1/main/sharedPost";
import SinglePost from "./components/theme_1/main/post";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
const container = document.getElementById("root");
const root = createRoot(container);

const isAuthenticated = localStorage.getItem("accessToken");

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <div className="loader">
        <div className="loader__content"></div>
      </div>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={landing} exact />
          <PrivateRoute
            path="/staff"
            component={Staff}
            isAuthenticated={isAuthenticated}
          />
          {/* <Route path="/meassage" component={Meassge} /> */}
          <PrivateRoute
            path="/meassage"
            component={Meassge}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/hospitalMsg"
            component={HospitalMsg}
            isAuthenticated={isAuthenticated}
          />
          {/* <Route path="/hospitalMsg" component={HospitalMsg} /> */}
          <Route path="/signUp" component={signUp} />
          <Route path="/jobs" component={Jobs} />
          <Route path="/staffSignUp" component={staffSignUp} />
          <Route path="/staffSignUp_1" component={staffSignUp_1} />
          <Route path="/staffSignUp_2" component={staffSignUp_2} />
          <Route path="/staffSignUp_3" component={staffSignUp_3} />
          <Route path="/staffSignUp_4" component={staffSignUp_4} />
          <Route path="/login" component={logIn} />
          <Route path="/forgotPassword" component={forgotPassword} />
          <Route path="/hospSignUp_1" component={hospSignUp_1} />
          <Route path="/hospSignUp_2" component={hospSignUp_2} />
          <Route path="/hospSignUp_3" component={hospSignUp_3} />
          <PrivateRoute
            path="/paymentsuccess"
            component={PaymentSuccess}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/homeDetails"
            component={homeDetails}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            path="/hospital"
            component={Staff}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/userHospital"
            component={UserHospital}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/HospitalChatBox/:id"
            component={HospitalChatBox}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            path="/aboutUs"
            component={aboutUs}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/notification"
            component={Notification}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/likedPostList"
            component={LikedPostList}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/likedCommentList"
            component={LikedCommentList}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/replayList"
            component={ReplayList}
            isAuthenticated={isAuthenticated}
          />
          <Route path="/main/staff/others" component={HospitalDashboard} />
          <Route path="/main/staff" component={Dashboard} exact />
          <Route path="/postnewjob" component={postnewjob} />
          <Route path="/postEditJob" component={EditJob} />
          <Route path="/main/:id/profile" component={staffProfile} />
          <Route path="/MyStaff" component={MyStaff} />
          <Route path="/sharedPost/:id" component={SharedPost} />
          <Route path="/Post/:id" component={SinglePost} />
          <Route path="/admin/dashboard" component={AdminDashBoard} />
          <Route path="/myJob" component={StaffMyJob} />
          <Route path="/recommendedJobs" component={RecommendedJobs} />
          <Route path="/myjobs/:id" component={MyJobDetails} />
          <Route path="/myJobDetails/:id" component={staffMyJobDetails} />
          <Route path="/post-new-job" component={JobPosting} />
          <Route path="/chat" component={ChatSection} />
          <Route
            path="/staff-ContactInfo"
            component={StaffContactInformation}
          />
          <Route path="/staff-Resume" component={StaffResume} />
          <Route path="/change-password" component={StaffChangePassword} />
          <Route
            path="/staffAccount-Information"
            component={StaffAccountInformation}
          />
          <Route path="/main/staff/find-jobs" component={FindJobs} exact />
          <Route path="/main/staff/my-jobs" component={MyJob} exact />
          <Route path="/main/staff/jobDetails" component={JobDetails} exact />
          <Route path="/main/staff/chats" component={Chat} exact />
          <Route path="/main/staff/attendance" component={Attendance} exact />
          <Route
            path="/main/staff/attendance-detail"
            component={AttendanceDetail}
            exact
          />
          <Route
            path="/main/admin/manageHospitalEdit"
            component={ManageHospitalEdit}
          />
          <Route
            path="/main/admin/manageAttendance"
            component={ManageAttendance}
          />
          <Route path="/main/admin/hospitalJob" component={HospitalJobs} />
          <Route path="/main/admin/hospital-staffList" component={StaffList} />
          <Route
            path="/main/admin/hospital-staffAttendance"
            component={StaffAttendance}
          />
          <Route path="/main/admin/manageHospital" component={ManageHospital} />
          <Route
            path="/main/admin/manageStaffEdit"
            component={ManageStaffEdit}
          />
          <Route path="/main/admin/manageStaff" component={ManageStaff} />
          <Route path="/main/admin/manageAdmin" component={ManageAdmin} />
          <Route
            path="/main/admin/ManageAdiministart"
            render={(props) => <ManageAdiministart {...props} />}
          />
          <Route
            path="/main/admin/NewAdministrators"
            component={NewAdministrators}
          />
          <Route
            path="/main/admin/change-password"
            component={ChangePassword}
          />
          <Route path="/main/admin" component={Staff} />
          <Route
            path="/main/staff/job-offer-details"
            component={JobOfferDetails}
          />

          <Route path="/main/hospital" component={manageHospitalProfile} />
          <Route path="/main/admin" component={SuperAdmin} exact />
          <Route
            path="/main/admin/manageHospitalEdit"
            component={ManageHospitalEdit}
          />
          <Route
            path="/main/admin/manageAttendance"
            component={ManageAttendance}
          />

          <Route path="/main/admin/hospitalJob" component={HospitalJobs} />
          <Route path="/main/admin/hospital-staffList" component={StaffList} />
          <Route
            path="/main/admin/hospital-staffAttendance"
            component={StaffAttendance}
          />
          <Route path="/main/admin/manageHospital" component={ManageHospital} />
          <Route path="/StaffHiring" exact component={StaffHiring} />
          <Route
            path="/main/admin/manageStaffEdit"
            component={ManageStaffProfile}
          />
          <Route path="/main/admin/manageStaff" component={ManageStaff} />
          <Route path="/myjobs" component={MyJob} />
          <Route path="/staff-profile" component={StaffProfile} />
          <Route path="/main/admin/manageAdmin" component={ManageAdmin} />
          <Route path="/main/admin/admin/add" component={ManageAdminAddEdit} />
          <Route path="/my-staff" component={MyStaff} />
          <Route path="/my-hospital" component={HospitalProfile} />
          <Route
            path="/main/admin/change-password"
            component={ChangePassword}
          />
          <Route path="/main/admin" component={Dashboard} />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
