import React, { useState, useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import ManageHospitalProfile from "./manageHospitalProfile";
import right_arrow from "../../../assets/images/right_arrow.svg";
import "../admin.css";
import "../../theme2.css";

const ManageHospitalEdit = ({ id, setAddEditModal }) => {
  return (
    <>
      <p className="flex items-center py-3 bg-white rounded-tl-[10px] px-3 rounded-tr-[10px]">
        <span className="flex gap-10 items-center" onClick={setAddEditModal}>
          <img src={right_arrow} className="w-6 h-6 cursor-pointer" />
          <div className="text-lg"> Edit Hospital</div>
        </span>
      </p>
      <ManageHospitalProfile adminHosid={id} />
    </>
  );
};

export default ManageHospitalEdit;
