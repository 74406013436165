import React, { useState, useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa";
import logo from "../../assets/images/Logo.png";
import rightArrow from "../../assets/images/right_arrow_slim.svg";
import { useHistory } from "react-router-dom";
export const HeaderComp = (props) => {
  const history = useHistory();
  return (
    <div className="w-full flex justify-center flex-col pb-7 relative">
      <img
        src={rightArrow}
        onClick={() => history.push("/signup")}
        className="absolute top-[6px] cursor-pointer w-5 h-5 transform scale-x-[-1]"
      />
      <img alt="img" className="w-52 self-center" src={logo} />
      <hr
        className="w-full mt-4"
        style={{
          width: "calc(100% + 96px)",
          position: "relative",
          left: "-48px",
        }}
      />
    </div>
  );
};
