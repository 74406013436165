import React, { useState, useEffect } from 'react';

function Alert(props) {
    const [display, setDisplay] = useState('none');

    useEffect(() => {
        setDisplay(props.display);
    }, [props.display]);

    return (
        <div style={{ display }} className="w3-modal">
            <div style={{ maxHeight: 600, overflowY: 'auto' }} className="w3-modal-content ssu2_modal1">
                <div className="w3-container">
                    <div className="ssu2_modal1_text1">
                        <h2>{props.title}</h2>
                    </div>
                    <hr style={{ margin: '30px 0px 0px' }} className="t2_sp_hr" />
                    <h4 style={{ marginTop: 30, textAlign: 'center' }}>{props.content}</h4>
                    <hr className="t2_sp_hr" style={{ margin: '40px 0px 0px' }} />
                    <div className="row ssu_bottom">
                        <div className="ssu_button" onClick={props.agreeFn}> OK </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Alert;
