import React, { useState, useEffect } from "react";
import Contact from "../../hospitalProfile/components/contact";
import Institution from "../../hospitalProfile/components/institution";
import { axiosInstance } from "../../../../config/axiosConfig";
import "../admin.css";
import "../../theme2.css";
import Loader from "../../../modals/Loader";
import { useSelector } from "react-redux";

const HospitalProfile = ({ adminHosid }) => {
  const [hospitalDetails, setHospitalDetails] = useState();
  const [loader, setLoader] = useState(false);
  const _id = localStorage.getItem("_id");
  const _hospitalID = localStorage.getItem("_hospitalID");
  const profile = useSelector((state) => state?.data?.profile);
  const type = localStorage.getItem("type");

  const fetchData = async (id) => {
    setLoader(true);
    try {
      const res = await axiosInstance.get(`v1/LHS/hospital/getById/${id}`);
      if (res.status === 200) {
        const data = res.data.data;
        setHospitalDetails(data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const idToFetch = adminHosid || profile?._id;

    fetchData(idToFetch);
  }, [adminHosid, profile?._id]);

  return (
    <div
      className="block justify-between align-top w-full mx-auto max-w-[1500px"
      style={{
        backgroundColor: "#ffff",
        border: "1px solid white",
        borderRadius: "10px",
      }}
    >
      {loader ? (
        <Loader />
      ) : (
        <>
          <Contact
            hospitalData={hospitalDetails}
            setHospitalDetails={setHospitalDetails}
          />
          <div className="border border-red-500 w-full m-0 p-0"></div>
          {/* <CorporateAddress hospitalData={hospitalDetails} /> */}
          <Institution
            hospitalData={hospitalDetails}
            setHospitalDetails={setHospitalDetails}
          />
          {/* <Locations hospitalData={hospitalDetails} fetchData={fetchData} /> */}
        </>
      )}
    </div>
  );
};

export default HospitalProfile;
