import React, { useEffect, useState } from "react";
import SubHeader from "./subHeader";
import Profile from "./profile";
import ContactDetails from "./contactDetails";
import ResumeDetail from "./resumeDetail";
import ChangePassword from "./changePassword";
import MyProfileHeader from "../../theme_1/staffSignUp/myProfileHeader";
import { useHistory } from "react-router-dom";
import Nav from "../../theme_1/Nav";
import Dashboard from "./Dashboard";
import HospitalProfile from "../admin/components/manageHospitalProfile";
import Advertisement from "./components/Advertisement";
import Advertise from "./components/Advertisement/CreateAdvertise";
import InitiatePayment from "./components/Advertisement/InitiatePayment";
import { axiosInstance } from "../../../config/axiosConfig";
import ViewAdvertisement from "./components/Advertisement/viewAdvertisement";
import "./dashboard.css";

function StaffProfile() {
  const history = useHistory();
  const [navSelected, setNavSelected] = useState("myprofile");
  const navType = localStorage.getItem("type");
  const [createNew, setCreateNew] = useState("tab_0");
  const [adData, setAdData] = useState({
    caption: "",
    fileUrl: [],
    mediaType: 1,
    url: "",
  });
  const [next, setNext] = useState(false);
  const handleTabClick = (tab) => {
    history.push(tab);
  };
  useEffect(() => {
    if (navType == "superAdmin" || navType == "admin") {
      setNavSelected("dashboard");
    }
  }, [navType == "superAdmin" || navType == "admin"]);

  return (
    <>
      <Nav />
      <div style={{ background: "rgb(243, 242, 239)", height: "100%" }}>
        <MyProfileHeader />
        <div
          className="lg:flex gap-3 block align-top py-4 w-11/12 md:w-3/4 mx-auto flex-col max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <SubHeader
            handleTabClick={handleTabClick}
            setNavSelected={setNavSelected}
            navSelected={navSelected}
          />
          <div className="grid grid-cols-1 lg:grid-cols-4 theme2 lg:gap-4 2xl:gap-12 justify-between align-top col-span-4">
            <div className="col-span-4">
              <div
                className="theme2_main_container col-span-4"
                style={{
                  background: "#fff",
                  borderRadius: "12px 12px 12px 12px",
                }}
              >
                {navType == "staff" && (
                  <>
                    {navSelected === "dashboard" && (
                      <div className="theme2_body">
                        <Dashboard />
                      </div>
                    )}
                    {navSelected === "myprofile" && (
                      <div className="theme2_body">
                        <Profile />
                      </div>
                    )}
                    {navSelected === "contact" && (
                      <div className="theme2_body">
                        <ContactDetails />
                      </div>
                    )}
                    {navSelected === "resume" && (
                      <div className="theme2_body">
                        <ResumeDetail />
                      </div>
                    )}
                    {navSelected === "password" && (
                      <div className="theme2_body">
                        <ChangePassword />
                      </div>
                    )}
                  </>
                )}
                {navType == "hospital" && (
                  <>
                    {navSelected === "myprofile" && (
                      <div className="theme2_body">
                        <HospitalProfile />
                      </div>
                    )}
                    {navSelected === "password" && (
                      <div className="theme2_body">
                        <ChangePassword />
                      </div>
                    )}
                    {navSelected === "dashboard" && (
                      <div className="theme2_body">
                        <Dashboard />
                      </div>
                    )}
                    {navSelected === "advertisement" && (
                      <div className="">
                        {createNew == "tab_0" && (
                          <Advertisement setCreateNew={setCreateNew} />
                        )}
                        {createNew == "tab_1" && (
                          <Advertise
                            setCreateNew={setCreateNew}
                            setAdData={setAdData}
                          />
                        )}
                        {createNew == "tab_2" && (
                          <ViewAdvertisement setCreateNew={setCreateNew} />
                        )}
                        {createNew == "tab_3" && (
                          <InitiatePayment setCreateNew={setCreateNew} />
                        )}
                      </div>
                    )}
                  </>
                )}
                {navType == "superAdmin" && (
                  <>
                    {navSelected === "dashboard" && (
                      <div className="theme2_body">
                        <Dashboard />
                      </div>
                    )}
                    {navSelected === "password" && (
                      <div className="theme2_body">
                        <ChangePassword />
                      </div>
                    )}
                    {navSelected === "advertisement" && (
                      <div className="">
                        {createNew == "tab_0" && (
                          <Advertisement setCreateNew={setCreateNew} />
                        )}
                        {createNew == "tab_1" && (
                          <Advertise
                            setCreateNew={setCreateNew}
                            setNext={setNext}
                          />
                        )}
                        {createNew == "tab_2" && (
                          <InitiatePayment
                            setCreateNew={setCreateNew}
                            setNext={setNext}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
                {navType == "admin" && (
                  <>
                    {navSelected === "dashboard" && (
                      <div className="theme2_body">
                        <Dashboard />
                      </div>
                    )}
                    {navSelected === "password" && (
                      <div className="theme2_body">
                        <ChangePassword />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffProfile;
