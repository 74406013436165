import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import useLoader from "../../../../modals/hooks/useLoader";
import CheckoutForm from "./Payment/CheckoutForm";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../common/toastMeassage/ToastMeassage";
import Modal from "../../../../common/modal";
import { axiosInstance } from "../../../../../config/axiosConfig";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

console.log(stripeKey, "stripeeeeeeeeee");
const stripePromise = loadStripe(stripeKey);

const NewAdvertisement = ({ setCreateNew }) => {
  const advertisementData = useSelector(
    (state) => state.checkoutAdvertisement.advertisementData
  );

  const profile = useSelector((state) => state.data.profile);
  const [modalViewOpen, setModalViewOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [readMore, setReadMore] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const locationDetails = useSelector(
    (state) => state?.checkoutAdvertisement?.activeLocation
  );
  const bookingData = useSelector(
    (state) => state?.checkoutAdvertisement?.bookingData
  );
  const paymentData = useSelector(
    (state) => state?.checkoutAdvertisement?.paymentData
  );

  const checkBookingData = () => {
    if (!locationDetails || !bookingData) {
      showErrorToast("Invalid booking data");
      // history.push("/");
    }
  };

  // useEffect(() => {
  //   checkBookingData();
  // }, []);

  const handleAdvertisement = async () => {
    setConfirmationModalOpen(false);
    const data = {
      ...advertisementData,
      sourceId: paymentData?.cardId || paymentData?.tokenId,
    };
    try {
      showLoader();
      let url = "v2/LHS/User/addAdvertisement";
      const response = await axiosInstance.post(url, data);
      if (response?.data?.success) {
        showSuccessToast("Payment successfull");
        showSuccessToast(response?.data?.message);
        dispatch({ type: "CLEAR_BOOKING_DATA" });
        setCreateNew("tab_0");
      }
    } catch {
      console.error("Failed to upload image:");
    } finally {
      hideLoader();
    }
  };

  const handleFormSubmit = async (payload) => {
    const { selectedCard, token, isSaveCard } = payload || {};
    const { id: sourceId } = selectedCard?.card || token || {};
    try {
      let paymentObj = {
        source: selectedCard || token,
        tokenId: sourceId,
      };
      if (isSaveCard) {
        showLoader();
        const postData = {
          token: sourceId,
        };
        let url = "/v2/LHS/User/addCard";
        const resCard = await axiosInstance.post(url, postData);
        const { message, success, data } = resCard?.data;
        if (!success) {
          showErrorToast(message);
          return;
        }
        paymentObj = {
          ...paymentObj,
          cardId: data?.id,
        };
      }

      dispatch({ type: "SET_PAYMENT_DATA", payload: paymentObj });
      setConfirmationModalOpen(true);
      // router.push("/checkout/confirmation");
    } catch (error) {
      const { message } = error || {};
      showErrorToast(message);
    } finally {
      hideLoader();
    }
  };
  return (
    <div className="pb-7 flex flex-col gap-4 bg-[#f3f2ef]">
      <div className="pt-[40px] p-4 flex flex-col gap-[35px] bg-white">
        <div className=" flex justify-between place-items-center">
          <div className="flex items-center gap-[14px] w-full">
            <div className="rounded-md w-16 h-16 overflow-hidden">
              <img
                src={advertisementData?.fileUrl[0]}
                className="w-full !h-full object-cover"
              />
            </div>
            <div className="flex flex-col gap-y-[6px] w-1/2">
              <p className="font-medium text-[15px] leading-5 tracking-[0.2px] line-clamp-2 whitespace-pre-line">
                {advertisementData?.caption}
              </p>
            </div>
          </div>

          <div className="text-center flex">
            <button
              className="text-base border-2 border-[#73C7A9] rounded-full w-32 h-11 ml-5"
              onClick={() => {
                setModalOpen(false);
                setCreateNew("tab_1");
              }}
            >
              Edit
            </button>
            <button
              onClick={() => {
                setModalViewOpen(true);
                setModalOpen(false);
              }}
              className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
            >
              View
            </button>
          </div>
        </div>
      </div>
      <div className="pt-[40px] pb-[60px] px-[30px] flex flex-col gap-[35px] bg-white">
        <div className="rounded-0 bg-white">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              onSubmit={handleFormSubmit}
              setCreateNew={setCreateNew}
            />
          </Elements>
        </div>
      </div>
      <Modal
        isOpen={modalViewOpen}
        onClose={() => setModalViewOpen(false)}
        className={"md:w-[647px] md:mt-0"}
      >
        <div className=" flex justify-between items-start">
          <div
            className=" flex items-center gap-[20px]"
            style={{ cursor: "pointer" }}
          >
            <img
              src={profile?.image}
              style={{
                height: "54px",
                width: "54px",
              }}
              className="rounded-full"
            />
            <div className="flex flex-col gap-y-[6px]">
              <h1 className="font-medium text-[18px] leading-5 tracking-[0.2px]">
                {profile?.healthCareInstitution?.name}
              </h1>
              <h1 className="text-gray-500 text-[15px]">
                {profile?.corporateAddress?.[0]?.street}
              </h1>
              <h1 className="text-[#73C7A9] text-[15px] tracking-[0.2px]">
                Promoted
              </h1>
            </div>
          </div>
          <div
            onClick={() => setModalViewOpen(false)}
            className="cursor-pointer text-[18px] font-semibold"
          >
            X
          </div>
        </div>
        <div>
          <div className="font-normal mt-[18px] text-container text-[16px] w-full break-words text-gray-500 leading-[24px] tracking-[0.2px]">
            {readMore ? (
              <div
                className="font-normal mt-4 w-full break-words text-[16px] text-gray-500 "
                style={{ whiteSpace: "pre-line" }}
              >
                {advertisementData?.caption}
              </div>
            ) : (
              <div
                className="font-normal mt-4 text-container text-[16px] w-full break-words text-gray-500 leading-[24px] tracking-[0.2px]"
                style={{ whiteSpace: "pre-line" }}
              >
                {advertisementData?.caption?.slice(0, 150)}{" "}
                {advertisementData?.caption?.length > 150 && (
                  <>
                    <div
                      onClick={() => setReadMore(true)}
                      className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
                    >
                      ...see more
                    </div>
                  </>
                )}
              </div>
            )}
            {advertisementData?.caption?.length > 100 && readMore && (
              <div
                onClick={() => setReadMore(false)}
                className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
              >
                ...see less
              </div>
            )}
          </div>
          <div className="mt-[18px] flex flex-wrap rounded-s gap-4 w-full justify-center">
            <img
              src={advertisementData?.fileUrl[0]}
              style={{
                height: "auto",
                width: "100%",
                borderRadius: "10px",
              }}
            />
          </div>

          {advertisementData?.url && (
            <button
              onClick={() => window.open(advertisementData?.url, "_blank")}
              className="text-base font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white mb-2 bg-[#73C7A9] text-white mt-[18px] mb-2"
            >
              Click here
            </button>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        className={"md:w-[600px] md:mt-0"}
      >
        <div className=" flex justify-center items-start w-full">
          <div className="flex flex-col justify-center">
            <p className="flex justify-center text-xl leading-[20px] tracking-[0.2px] mb-[20px]">
              Alert
            </p>
            <p className="text-base flex justify-center leading-7 font-medium m-auto mb-4 text-center tracking-[0.2px] w-[400px]">
              Once the payment is done, you cannot edit or delete the
              advertisement. Please cross check again.
            </p>
            <div className="text-center">
              <button
                className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5"
                onClick={() => setConfirmationModalOpen(false)}
              >
                Back
              </button>
              <button
                onClick={() => handleAdvertisement()}
                className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default NewAdvertisement;
