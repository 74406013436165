import React, { useEffect, useState } from "react";
import Nav from "../Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import msgIcon from "../../../components/assets/images/MsgIcon.svg";
import RightIcon from "../../../components/assets/images/RightIcon.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import Modal from "../../common/modal";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import useLoader from "../../modals/hooks/useLoader";
import Loader from "../../modals/Loader";

const UserHospital = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [BtnChange, setBtnChange] = useState(false);
  const [userData, setUserData] = useState(null);
  const [getAllChats, setGetAllChats] = useState([]);
  const userrtype = localStorage.getItem("type");
  const storedChat = localStorage.getItem("SetChat");
  const ClickUserIS = JSON.parse(localStorage.getItem("ClickUser"));
  const CurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
  const ClickedUserRole = ClickUserIS.userId?.role;
  const CurrentUserRole = CurrentUser?.role;
  const loal_id = localStorage.getItem("_id");

  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const getChatUsers = async () => {
    try {
      let url = `/v2/LHS/User/getAllChats`;
      const response = await axiosInstance.get(url);
      if (response.status == 200) {
        setGetAllChats(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getChatUsers();
  }, []);

  const handleModalPopup = () => {
    if (
      CurrentUserRole == "staff" &&
      ClickedUserRole == "hospital" &&
      userData?.profile?.requestStatus == 3
    ) {
      setModalOpen(true);
      setBtnChange(false);
    } else if (userData?.profile?.requestStatus == 1) {
      setModalOpen(false);
    } else if (userData?.profile?.requestStatus == 0) {
      setModalOpen(false);
      SendRequest();
    } else {
      SendRequest();
    }
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const getProfileId = async (data) => {
    const storeId = localStorage.getItem("postID");
    try {
      if (data !== "test") {
        showLoader();
      }
      const url = `/v2/LHS/User/getProfileById/${storeId}`;
      const response = await axiosInstance.get(url);
      if (response.data.data) {
        hideLoader(false);
        setUserData(response.data.data);
      }
    } catch (error) {
      console.error(error);
      hideLoader(false);
    }
  };
  useEffect(() => {
    getProfileId();
  }, []);
  const [loader, setLoader] = useState(false);

  const SendRequest = async () => {
    let ChatType;
    let Type;

    if (ClickedUserRole == "staff" && CurrentUserRole == "staff") {
      ChatType = 2;
      Type = 0;
    } else if (ClickedUserRole == "hospital" && CurrentUserRole == "hospital") {
      ChatType = 0;
      Type = 0;
    } else if (
      ClickedUserRole == "hospital" &&
      CurrentUserRole == "staff" &&
      userData?.profile?.requestStatus == 3
    ) {
      ChatType = 1;
      Type = 1;
    } else if (
      ClickedUserRole == "hospital" &&
      CurrentUserRole == "staff" &&
      userData?.profile?.requestStatus !== 3
    ) {
      ChatType = 1;
      Type = 0;
    } else if (ClickedUserRole == "staff" && CurrentUserRole == "hospital") {
      ChatType = 1;
      Type = 0;
    }

    const storedData = localStorage.getItem("postID");
    const data = {
      id: storedData,
      type: Type,
      chatType: ChatType,
    };

    try {
      setLoader(true);
      let url = `/v2/LHS/User/sendChatRequest`;
      const response = await axiosInstance.post(url, data);

      if (response?.status === 200) {
        localStorage.setItem("SendReqID", data?.id);
        setLoader(false);
        getProfileId("test");
        if (
          userData?.profile?.requestStatus == 0 ||
          userData?.profile?.requestStatus == undefined
        ) {
          history.push({
            pathname:
              CurrentUserRole !== "staff" ? `/hospitalMsg` : `/meassage`,
            search:
              CurrentUserRole == "hospital" ? "param1=tab3" : "param1=tab2",
          });
        }
        if (
          userData?.profile?.requestStatus == 3 &&
          ClickedUserRole == "hospital" &&
          CurrentUserRole == "hospital"
        ) {
          history.push(`/hospitalMsg?param1=tab3`);
        }
      }
    } catch (error) {
      if (error?.message == "Chat request already sent!") {
        history.push({
          pathname: CurrentUserRole !== "staff" ? `/hospitalMsg` : `/meassage`,
          search: CurrentUserRole == "hospital" ? "param1=tab3" : "param1=tab2",
        });
      }
    }
  };

  const displayText =
    userData?.profile?.requestStatus === 3 &&
    ClickedUserRole == "hospital" &&
    CurrentUserRole == "staff"
      ? "Send request"
      : userData?.profile?.requestStatus === 1
      ? "Request sent"
      : "Message";
  return (
    <>
      <Nav />
      <div
        className="flex justify-center items-center"
        style={{ backgroundColor: "#F3F2EF", height: "100%" }}
      >
        <div
          className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <div className="flex flex-col !gap-2 md:px-5 lg:px-0 w-full">
            <>
              <div className="flex justify-evenly bg-white rounded-[10px] w-full md:w-full lg:w-full  sm:w-full h-[360px] flex-col">
                <Link to={"/hospital"}>
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 30 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-5"
                  >
                    <path
                      d="M30 10.927H6.23656L14.8599 2.30368L12.5562 0L0 12.5562L12.5562 25.1124L14.8599 22.8087L6.23656 14.1854H30V10.927Z"
                      fill="#333333"
                    />
                  </svg>
                </Link>
                <div className="flex flex-col justify-between px-4">
                  <div className="flex items-center gap-4">
                    <img
                      src={
                        userData?.profile?.image
                          ? userData?.profile?.image
                          : userData?.profile?.avatar
                      }
                      alt=""
                      className="w-[144px] h-[144px] rounded-full"
                      style={{
                        border: "2.5px solid #73C7A9",
                      }}
                    />
                    <div className="flex flex-col gap-4">
                      <div className="m-0">
                        <h3 className="p-0 text-[#333333] text-[20px] font-semibold mb-2">
                          {userData?.profile?.name}
                        </h3>
                        {userData?.role == "Hospital" ? (
                          <p className="text-gray-500 text-[15px] font-normal">
                            {userData?.profile?.corporateAddress?.length && (
                              <>
                                {userData?.profile?.corporateAddress[0]?.city},{" "}
                                {userData?.profile?.corporateAddress[0]?.state}{" "}
                                |
                              </>
                            )}
                            Zip :{" "}
                            {userData?.profile?.corporateAddress[0]?.zipCode}
                          </p>
                        ) : (
                          <p className="text-gray-500 text-[15px] font-normal">
                            {userData?.profile?.jobTitle} |{" "}
                            {userData?.profile?.currentLocation?.name} ,{" "}
                            {userData?.profile?.currentLocation?.state}
                          </p>
                        )}
                      </div>
                      {loal_id == userData?.profile._id ? (
                        ""
                      ) : (
                        <div>
                          {userrtype !== "superAdmin" && (
                            <button
                              onClick={handleModalPopup}
                              className={`text-base border-2 ${
                                userData?.profile?.requestStatus === 1
                                  ? "border-[#067647] !text-[#067647] w-44"
                                  : "border-[#73C7A9] bg-[#73C7A9] w-40"
                              } rounded-full text-white  h-11`}
                              disabled={userData?.profile?.requestStatus === 1}
                            >
                              <div
                                className={`flex items-center justify-center gap-3`}
                              >
                                {!loader ? (
                                  <div>
                                    {userData?.profile?.requestStatus === 1 ? (
                                      <img src={RightIcon} alt="Message Icon" />
                                    ) : (
                                      <img src={msgIcon} alt="Message Icon" />
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div>{loader ? <Loader /> : displayText}</div>
                              </div>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="border border-gray-500"></div>
                <div className="p-4 flex justify-between">
                  <div className=" gap-[10px] flex flex-col">
                    <h1 className=" text-20 font-bold leading-5 tracking-widest  ">
                      {userData?.role == "Hospital"
                        ? "Institution name"
                        : "Medical setting"}
                    </h1>
                    <p className="text-lg font-medium leading-5 text-[#333333]">
                      {userData?.role == "Hospital"
                        ? userData?.profile?.healthCareInstitution?.name
                        : userData?.profile?.medicalSettings}
                    </p>
                  </div>
                  <div className="gap-[10px] flex flex-col">
                    <h1 className=" text-20 font-bold leading-5 tracking-widest">
                      {userData?.role == "Hospital"
                        ? "Institution website"
                        : "Highest education level"}
                    </h1>

                    <p>
                      {userData?.role == "Hospital"
                        ? userData?.profile?.healthCareInstitution?.website
                        : userData?.profile?.education?.degree}
                    </p>
                  </div>
                  <div className=" gap-[10px] flex flex-col">
                    <h1 className=" text-20 font-bold leading-5 tracking-widest  ">
                      {userData?.role == "Hospital"
                        ? "Corporate office address"
                        : "Available for other location"}
                    </h1>
                    <p className="text-[16px] font-medium leading-5">
                      {userData?.role == "Hospital" ? (
                        <>
                          {userData?.profile?.corporateAddress[0]?.street}, {""}
                          {userData?.profile?.corporateAddress[0]?.city} {""}
                          {userData?.profile?.corporateAddress[0]?.state}, {""}
                          {userData?.profile?.corporateAddress[0]?.zipCode}
                        </>
                      ) : userData?.profile?.otherCities?.length > 0 ? (
                        "Yes"
                      ) : (
                        "No"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          </div>
          <RecommendedForYou />
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        className={"md:w-1/3 md:mt-0"}
      >
        <div className="bg-white z-10 w-full h-full rounded-lg">
          <div className="2xl:px-10 2xl:py-5 xl:p-1">
            <p className="text-xl leading-8 font-medium m-auto 2xl:mb-5 mb-4 text-center">
              Are you sure you want to send request to connect?
            </p>

            <div className="flex justify-center items-center gap-6">
              <button
                onClick={closeModal}
                className="text-base  border-1  !border-[rgb(115,199,169)] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white py-[6px] px-4"
              >
                Cancel
              </button>
              <button
                className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white py-[6px] px-4"
                // onClick={handleSubmit}
                onClick={() => {
                  setModalOpen(false);
                  setBtnChange(true);
                  SendRequest();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserHospital;
