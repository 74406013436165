import React from "react";
import Loader from "../modals/Loader";

const Button = ({
  type = "button",
  children,
  onClick,
  className,
  loading,
  iconRight,
  icon,
  style,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={`bg-[#73C7A9] flex items-center gap-3 justify-center text-white px-4 py-2 focus:outline-none focus:shadow-outline ${
        loading ? "opacity-50 cursor-not-allowed" : ""
      } ${className || ""}`}
      onClick={!loading ? onClick : null}
      style={style}
      disabled={loading}
      {...rest}
    >
      {loading && <Loader className="m-0" />}
      {!iconRight && icon && (
        <img width={17} height={17} className="mr-2" src={icon} alt="Logo" />
      )}
      <span>{children}</span>
      {iconRight && (
        <img width={17} height={17} className="ml-2" src={icon} alt="Logo" />
      )}
    </button>
  );
};

export default Button;
