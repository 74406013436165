import React, { useEffect, useRef, useState } from "react";
import user from "../assets/images/user.png";
import notifications from "../assets/images/notifications.png";
import blueNotification from "../assets/images/blueNotification.svg";
import logo from "../assets/images/Logo.png";
import { useHistory } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { removeSession } from "../../redux/action/action";
import { Link, useLocation } from "react-router-dom";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AccessibilityNewOutlinedIcon from "@material-ui/icons/AccessibilityNewOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import HomeIcon from "@material-ui/icons/Home";
import { userLoggedInUserInfo } from "../../config/userLoggedInUserInfo";
import msg from "../assets/images/msg.png";
import myJobIcon from "../assets/images/myJobIcon.svg";
import blueMyJobIcon from "../assets/images/blueMyJobIcon.svg";
import blueMessageIcon from "../assets/images/blueMessageIcon.svg";
import Modal from "../common/modal";
import { axiosInstance } from "../../config/axiosConfig";
import "./global.css";
import {
  chatMessageService,
  headerNotificationService,
} from "../../sockets/socketService";
import { useDispatch, useSelector } from "react-redux";

const Nav = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const token = localStorage.getItem("accessToken");
  const navType = localStorage.getItem("type");
  const [showNotificationCount, setShowNotificationCount] = useState(true);
  // const [notificationCount, setNotificationCount] = useState(0);
  const profile = useSelector((state) => state.data.profile);
  const notificationCount = useSelector(
    (state) => state.data.notificationCount
  );
  const { Profile } = userLoggedInUserInfo();
  const leaveRoom = useSelector((state) => state.data.leaveRoom);

  const openRightMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeRightMenu = () => {
    setIsOpen(false);
  };
  const openIsModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const { ChatId, loggedin_id, userrtype } = leaveRoom;
    chatMessageService.leaveRoom(ChatId, loggedin_id, userrtype);
    const handleNotification = (notification) => {
      // setNotificationCount(notification?.data?.pendingCount);
      dispatch({
        type: "NOTIFICATION_COUNT",
        payload: notification?.data?.pendingCount,
      });
    };

    headerNotificationService.onNotificationReceived(handleNotification);
    return () => {
      headerNotificationService.offNotificationReceived();
    };
  }, []);

  const handleClick = () => {
    if (location.pathname === "/notification") {
      return;
    }
    // setShowNotificationCount((prevIcon) => !prevIcon);
    history.push("/notification");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        closeRightMenu();
      }
    };

    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, closeRightMenu]);

  const navIcon = history.location.pathname;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        closeRightMenu();
      }
    };

    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, closeRightMenu]);

  return (
    <>
      {token && (
        <div
          className="flex items-center py-3 w-11/12 m-auto max-w-[1500px] hover:text-[#73C7A9]"
          style={{
            minWidth: "25%",
            maxHeight: "calc(100vh - 100px)",
            justifyContent: "space-between",
            alignItems: "center",
            background: "white",
            zIndex: "999",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <div className="w-[200px] h-[34px] sm:w-60 lg:w-52 ">
            <img alt="img" src={logo} />
          </div>
          <div className="items-center w-auto  mdx:flex hidden gap-[14px] xl:gap-[32px] hover:!text-[#73C7A9]">
            {navType === "staff" && token && (
              <Link
                to="/staff"
                className={`flex items-center text-sm px-1 tracking-[0.2px] hover:!text-[#73C7A9] ${
                  location.pathname === "/staff" ||
                  location.pathname == "/LikedCommentList" ||
                  location.pathname == "/userHospital" ||
                  location.pathname.split("/")[1] == "Post"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                <HomeIcon
                  className={`!h-[24px] !w-[24px] mr-2.5 hover:!text-[#73C7A9] ${
                    location.pathname === "/staff" ||
                    location.pathname == "/LikedCommentList" ||
                    location.pathname == "/userHospital" ||
                    location.pathname.split("/")[1] == "Post"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Home
              </Link>
            )}
            {navType === "staff" && token && (
              <Link
                to="/myJob"
                className={`flex items-center text-sm px-1 tracking-[0.2px] hover:!text-[#73C7A9] ${
                  location.pathname === "/myJob"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <PageviewOutlinedIcon
                  className={`!h-[25px] !w-[25px] mr-2.5 ${
                    location.pathname === "/myJob"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 font-medium hover:text-gray-500"
                  }`}
                />
                Jobs
              </Link>
            )}
            {navType === "staff" && token && (
              <Link
                to="/myjobs"
                className={`flex items-center text-sm px-1 tracking-[0.2px] hover:!text-[#73C7A9] ${
                  location.pathname === "/myjobs"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                {location.pathname === "/myjobs" ? (
                  <img
                    src={blueMyJobIcon}
                    alt=""
                    className="mr-2.5 !h-[16px] !w-[18px]"
                  />
                ) : (
                  <img
                    src={myJobIcon}
                    alt=""
                    className="mr-2.5 !h-[18px] !w-[19px]"
                    style={{ color: "gray" }}
                  />
                )}
                My jobs
              </Link>
            )}
            {navType === "hospital" && token && (
              <Link
                to="/hospital"
                className={`flex items-center px-1 hover:!text-[#73C7A9] ${
                  location.pathname === "/hospital" ||
                  location.pathname == "/userHospital"
                    ? "text-[#73C7A9] font-medium !text-[17px]"
                    : "text-gray-500 hover:text-gray-500 !text-[17px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <HomeIcon
                  className={`h-5 w-4 mr-2.5 hover:!text-[#73C7A9] ${
                    location.pathname === "/hospital" ||
                    location.pathname == "/userHospital"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Home
              </Link>
            )}
            {navType === "hospital" && token && (
              <Link
                to="/StaffHiring"
                className={`flex items-center px-1 hover:!text-[#73C7A9] ${
                  location.pathname === "/StaffHiring"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <AccessibilityNewOutlinedIcon
                  className={`h-3 w-4 mr-2 ${
                    location.pathname === "/StaffHiring"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Staff Hiring
              </Link>
            )}
            {navType === "hospital" && token && (
              <Link
                to="/MyStaff"
                className={`flex items-center px-1 hover:!text-[#73C7A9] ${
                  location.pathname === "/MyStaff"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <PeopleAltOutlinedIcon
                  className={`h-4 w-4 mr-2 ${
                    location.pathname === "/MyStaff"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                My Staff
              </Link>
            )}
            {navType === "superAdmin" && token && (
              <Link
                to="/main/admin"
                className={`flex items-center hover:!text-[#73C7A9] ${
                  location.pathname === "/main/admin"
                    ? "text-[#73C7A9] font-medium !text-[17px]"
                    : "text-gray-500 hover:text-gray-500 !text-[17px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <HomeIcon
                  className={`h-[20px] w-[20px] mr-2  hover:!text-[#73C7A9] ${
                    location.pathname === "/main/admin"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Home
              </Link>
            )}
            {navType === "superAdmin" && token && (
              <Link
                to="/main/admin/manageAdmin"
                className={`flex items-center hover:!text-[#73C7A9]  ${
                  location.pathname === "/main/admin/manageAdmin"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <ManageAccountsIcon
                  className={`h-4 w-4 mr-2 ${
                    location.pathname === "/main/admin/manageAdmin"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Manage Administrator
              </Link>
            )}
            {(navType === "superAdmin" ||
              (profile?.role == "admin" &&
                profile?.permissions?.includes("Manage Staff"))) && (
              <Link
                to="/main/admin/manageStaff"
                className={`flex items-center hover:!text-[#73C7A9] ${
                  location.pathname === "/main/admin/manageStaff"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <PeopleAltOutlinedIcon
                  className={`h-4 w-4 mr-2 ${
                    location.pathname === "/main/admin/manageStaff"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Manage Staff
              </Link>
            )}
            {(navType === "superAdmin" ||
              (profile?.role == "admin" &&
                profile?.permissions?.includes("Manage Hospital"))) && (
              <Link
                to="/main/admin/manageHospital"
                className={`flex items-center hover:!text-[#73C7A9] ${
                  location.pathname === "/main/admin/manageHospital"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <QuestionAnswerOutlinedIcon
                  className={`h-4 w-4 mr-2 ${
                    location.pathname === "/main/admin/manageHospital"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Manage Hospital
              </Link>
            )}
            {(navType === "superAdmin" || navType === "admin") && (
              <Link
                to="/main/staff/profile/dashbord"
                className={`flex items-center hover:!text-[#73C7A9] ${
                  location.pathname === "/main/staff/profile/dashbord"
                    ? "text-[#73C7A9] font-medium !text-[17px]"
                    : "text-gray-500 hover:text-gray-500 !text-[17px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <PersonOutlineOutlinedIcon
                  className={`h-4 w-4 mr-2 ${
                    location.pathname === "/main/staff/profile/dashbord"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 hover:text-gray-500"
                  }`}
                />
                Account
              </Link>
            )}
            {navType === "hospital" && token && (
              <Link
                to="/hospitalMsg?param1=tab1"
                className={`flex items-center px-1 hover:!text-[#73C7A9] ${
                  location.pathname === "/hospitalMsg" ||
                  location.search == "?param1=tab3" ||
                  location.search == "?param1=tab2" ||
                  location.search == "?param1=tab1" ||
                  location.pathname.split("/")[1] == "HospitalChatBox"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                {location.pathname === "/hospitalMsg" ||
                location.search == "?param1=tab3" ||
                location.search == "?param1=tab2" ||
                location.search == "?param1=tab1" ||
                location.pathname.split("/")[1] == "HospitalChatBox" ? (
                  <img
                    src={blueMessageIcon}
                    alt=""
                    className="mr-2.5 h-[19px] w-[20px]"
                  />
                ) : (
                  <img src={msg} alt="" className="mr-2.5 h-[19px] w-[20px]" />
                )}
                Messaging
              </Link>
            )}

            {navType === "staff" && token && (
              <Link
                to="/meassage?param1=tab1"
                className={`flex items-center text-sm px-1 tracking-[0.2px] hover:!text-[#73C7A9] ${
                  location.pathname === "/meassage" ||
                  location.search == "meassage?param1=tab1" ||
                  location.search == "meassage?param1=tab2" ||
                  location.pathname.split("/")[1] == "HospitalChatBox"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                {location.pathname === "/meassage" ||
                location.search == "meassage?param1=tab1" ||
                location.search == "meassage?param1=tab2" ||
                location.pathname.split("/")[1] == "HospitalChatBox" ? (
                  <img
                    src={blueMessageIcon}
                    alt=""
                    className="mr-2.5 h-[19px] w-[20px]"
                  />
                ) : (
                  <img src={msg} alt="" className="mr-2.5 h-[19px] w-[20px]" />
                )}
                Messaging
              </Link>
            )}
            {navType === "hospital" && token && (
              <Link
                to={`/main/${navType}/profile`}
                className={`flex items-center px-1 hover:!text-[#73C7A9] ${
                  location.pathname === `/main/${navType}/profile`
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <SettingsOutlinedIcon
                  className={`h-4 w-4 mr-2 ${
                    location.pathname === `/main/${navType}/profile`
                      ? "text-[#73C7A9]"
                      : "text-gray-500 font-medium hover:text-gray-500"
                  }`}
                />
                Settings
              </Link>
            )}
            {navType === "staff" && token && (
              <Link
                to="/main/staff/profile"
                className={`flex items-center text-sm px-1 tracking-[0.2px] hover:!text-[#73C7A9] ${
                  location.pathname === "/main/staff/profile"
                    ? "text-[#73C7A9] font-medium !text-[16px]"
                    : "text-gray-500 hover:text-gray-500 !text-[16px]"
                }`}
                style={{ cursor: "pointer" }}
              >
                <SettingsOutlinedIcon
                  className={`h-4 w-4 mr-2.5 ${
                    location.pathname === "/main/staff/profile"
                      ? "text-[#73C7A9]"
                      : "text-gray-500 font-medium hover:text-gray-500"
                  }`}
                />
                Settings
              </Link>
            )}
          </div>

          {navType && (
            <div className="flex items-center justify-between gap-4 relative">
              <div className="flex items-center justify-between gap-4 cursor-pointer">
                {navType !== "admin" && (
                  <div className="flex items-center sm:gap-3 gap-[3px]">
                    <a
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      <div
                        className="flex items-center w-7 relative"
                        style={{ cursor: "pointer" }}
                      >
                        {showNotificationCount && (
                          <img
                            src={
                              location.pathname === "/notification" ||
                              notificationCount
                                ? blueNotification
                                : notifications
                            }
                            className="h-6 w-8"
                          />
                        )}
                        {notificationCount !== 0 && (
                          <span className="inline-block px-[2.6px] text-[9px] font-normal rounded-full bg-[#73C7A9] text-white absolute -top-[2px] -right-[2px]">
                            {notificationCount}
                          </span>
                        )}
                      </div>
                    </a>
                  </div>
                )}
                <div
                  className="flex items-center gap-1 relative"
                  onClick={openRightMenu}
                >
                  <div className="w-10 min-w-[40px] h-10 rounded-full overflow-hidden">
                    <img
                      src={profile?.image ? profile?.image : user}
                      className="object-cover w-full h-full"
                    />
                  </div>
                </div>
              </div>
              {isOpen && (
                <div className="dropdown-card rounded-[10px]" ref={dropdownRef}>
                  <a
                    onClick={() => {
                      openIsModal();
                    }}
                    className="cursor-pointer"
                  >
                    Logout
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        className={"md:w-1/3 md:mt-0"}
        style={{ backgroundColor: "red" }}
      >
        <div className="">
          <div className="">
            <h1 className="font-semibold text-xl text-center mb-4">Logout </h1>
            <p className="text-center text-lg m-auto mb-5">
              Are you sure you want to Logout?
            </p>

            <div className="text-center">
              <button
                onClick={() => setModalOpen(false)}
                className="border-2 border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white w-32 h-11 ml-5"
              >
                Cancel
              </button>
              <button
                className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                onClick={() => {
                  localStorage.removeItem("accessToken");
                  localStorage.clear();
                  removeSession();
                  window.location.href = "/login";
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Nav;
