import React, { useState, useEffect, useRef } from "react";
import fllipsImag from "../../assets/images/UserEllipse.png";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../config/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import useSticky from "../../modals/hooks/useSticky";
import classNames from "classnames";

const RecommendedForYou = () => {
  const usertype = localStorage.getItem("type");
  const [currentJobData, setCurrentJobData] = useState([]);
  const { isSticky, parentWidth, stickyElementRef } = useSticky();
  const profile = useSelector((state) => state?.data?.profile);
  const GetRecommendedJobs = async () => {
    if (usertype == "staff") {
      try {
        let url = `/v2/LHS/User/getRecomendedJobs`;
        const response = await axiosInstance.get(url);
        if (response.data) {
          if (response?.data?.data) {
            setCurrentJobData(response?.data?.data?.jobData);
          } else {
            setCurrentJobData([]);
          }
        }
      } catch (error) {
        console.error("Error fetching notification:", error);
      }
    }
  };

  const GetAllJobs = async (id) => {
    if (usertype == "hospital") {
      // showLoader();
      try {
        let url = `/v2/LHS/User/getJobsByHospitalId/${id}`;
        const response = await axiosInstance.get(url);
        if (response.data.data) {
          setCurrentJobData(response?.data?.data?.jobData);
        } else {
          setCurrentJobData([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        // hideLoader();
      }
    }
  };
  useEffect(() => {
    if (profile?._id) {
      GetAllJobs(profile?._id);
    }
  }, [profile?._id]);

  useEffect(() => {
    GetRecommendedJobs();
  }, []);

  return (
    <div
      className="relative hidden lg:block
    "
      style={{ minWidth: "25%", maxWidth: "25%" }}
    >
      {usertype == "superAdmin" ? (
        <>
          {/* <div
          className="sticky top-0 border border-gray-100 bg-white rounded-[10px] xl:block"
          style={{
            position: "sticky",
            top: "100px",
            maxHeight: "calc(100vh - 100px)",
          }}
          ref={stickyElementRef}
        >
          <h1 className="font-medium text-base p-3">Top Specialties Jobs</h1>
          <div
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          />
          <div className="px-3 pb-4">
            <h1 className="font-normal mt-2">Primary Care Doctor</h1>
            <h1 className="font-normal mt-2">Urgent Care</h1>
            <h1 className="font-normal mt-2">OB-GYN</h1>
            <h1 className="font-normal mt-2">Dentist</h1>
            <h1 className="font-normal mt-2">Ear, Nose & Throat Doctor</h1>
            <h1 className="font-normal mt-2">Podiatrist</h1>
            <h1 className="font-normal mt-2">Urologist</h1>
            <h1 className="font-normal mt-2">Primary Care Doctor</h1>
            <h1 className="font-normal mt-2">Urgent Care</h1>
          </div>
        </div> */}
        </>
      ) : (
        <div
          style={{
            position: "sticky",
            top: isSticky ? "20px" : "auto",
            // minWidth: parentWidth ? `${parentWidth}px` : "25%",
            // maxWidth: parentWidth ? `${parentWidth}px` : "100%",
          }}
          ref={stickyElementRef}
          className="border border-gray-100 bg-white rounded-[10px] xl:block"
        >
          <h1 className="font-medium text-base p-3">
            {usertype == "hospital" && "Recently posted jobs"}
            {usertype == "staff" && "Recommended for you"}
          </h1>
          <div
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          />
          {currentJobData.length == 0 && usertype == "hospital" && (
            <div className="flex items-start p-3 justify-between">
              Please post recently available job opportunities.
            </div>
          )}
          {currentJobData.length == 0 && usertype == "staff" && (
            <div className="flex items-start p-3 justify-between">
              Presently, relevant job opportunities are unavailable
            </div>
          )}
          {currentJobData.slice(0, 4).map((item, index) => {
            return (
              <>
                <div className="flex items-start p-3 justify-between">
                  <Link
                    className={classNames(
                      {
                        "cursor-pointer": usertype == "staff",
                        "cursor-default": usertype == "hospital",
                      },
                      "flex items-center gap-[15px]"
                    )}
                    to={
                      usertype == "staff" && {
                        pathname: `/myJobDetails/${item?._id}`,
                        state: {
                          prevPath: window?.location?.pathname,
                        },
                      }
                    }
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={fllipsImag}
                      alt="not found"
                      className="rounded-full w-12 h-12"
                    />
                    <div className="">
                      <h1 className="text-md tracking-wide leading-[24.8px] mb-2 font-semibold">
                        {item?.jobTitle}
                      </h1>
                      <p className="text-sm text-gray-500 mb-[4px] leading-3">
                        {item?.medicalSettings}
                      </p>
                      {usertype == "staff" && (
                        <p className="text-sm font-normal leading-3">
                          {item?.hospital?.healthCareInstitution?.name +
                            " | " +
                            item?.healthCareLocation}
                        </p>
                      )}
                      {usertype == "hospital" && (
                        <p className="text-sm font-normal leading-3">
                          {item?.hospitalName +
                            " | " +
                            item?.healthCareLocation}
                        </p>
                      )}
                    </div>
                  </Link>
                </div>
                {index !==
                  (currentJobData?.length < 4
                    ? currentJobData.length - 1
                    : 3) && <hr style={{ height: "0px" }} />}
              </>
            );
          })}
          {usertype == "staff" && currentJobData?.length >= 4 && (
            <div className="p-3 pt-4">
              <Link
                to="/recommendedJobs"
                className="ShowAllButton p-0 items-center text-base text-white top-0 m-[20px]"
              >
                Show all
              </Link>
            </div>
          )}
          {usertype == "hospital" && currentJobData?.length >= 4 && (
            <div className="p-3 pt-4">
              <Link
                to="/StaffHiring"
                className="ShowAllButton p-0 items-center text-base text-white top-0 m-[20px]"
              >
                Show all
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RecommendedForYou;
