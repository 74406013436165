import React from "react";
import { connect, useDispatch } from "react-redux";
import SubHeader from "./components/subHeader";
import ErrorState from "./components/errorState";
// import img from "../../assets/images/1-4.png";
import img from "../../assets/images/1-4.svg";
import place from "../../assets/images/place.svg";
import "./staffSignUp_1.css";
import Loader from "../../modals/Loader";
import {
  SET_LOCATIONS,
  SET_PAGE_VISITED,
} from "../../../constants/actionTypes";
import { withRouter } from "react-router-dom";
import {
  callApi,
  collectData,
  decrementStep,
} from "../../../redux/action/action";
import { HeaderComp } from "./headerComp";

const AddLocItem = (props) => {
  return (
    <button
      className={
        props.selected ? "ssu1_locItem " : "ssu1_locItem ssu1_nearLocItem"
      }
      onClick={(e) => {
        props.setCurLoc(props.name, props.state, props.zipCode);
      }}
      id={props.name}
    >
      <p style={{ margin: "auto auto" }}>{props.name + ", " + props.state}</p>
    </button>
  );
};

const SuggestionItem = (props) => {
  return (
    <button
      className={
        props.selected ? "ssu1_locItem " : "ssu1_locItem ssu1_nearLocItem"
      }
      title={props.zipCode}
      style={{
        wordBreak: "break-all",
        color: props.name === "ERROR" ? "red" : null,
        border: props.name === "ERROR" ? "2px solid red" : "1px solid #73C7A9",
      }}
      id={props.name}
      onClick={() =>
        props.setNearLoc(props.name, props.state, props.zipCode, props.selected)
      }
    >
      <p style={{ margin: "10px auto" }}>
        {props.name + (props.zipCode !== "ERROR" ? ", " : "") + props.state}
      </p>
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    redo: state.staffSignUp.pageVisited,
    curVal: state.staffSignUp.curVal,
    nearVal: state.staffSignUp.nearVal,
    otherLoc: state.staffSignUp.otherLoc,
    nearLoc: state.staffSignUp.nearLoc,
    addLoc: state.staffSignUp.addLoc,
    curCity: state.staffSignUp.curCity,
    nearCity: state.staffSignUp.nearCity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPageVisited: (data) => dispatch({ type: SET_PAGE_VISITED, data }),
  setLocations: (data) => dispatch({ type: SET_LOCATIONS, data }),
});

class StaffSignUp_1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redo: [],
      curVal: "",
      nearVal: "",
      otherLoc: [],
      addLoc: [],
      nearLoc: [],
      curCity: {
        name: false,
        state: false,
        zipCode: false,
        selected: false,
      },
      nearCity: [],
      error: {
        curLoc: false,
        nearLoc: false,
      },
      isInputFocused: false,
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props?.formData && this.props?.formData?.currentLocation) {
      const { zipCode, name, state } = this.props.formData.currentLocation;
      const curCity = {
        name: name,
        state: state,
        zipCode: zipCode,
        selected: true,
      };

      this.setState({
        curVal: `${name}, ${state}`,
        curCity: curCity,
      });
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    // Assuming you want to update the state with the input value
    this.setState({
      [name]: value,
    });
  };
  componentWillReceiveProps = (newProps) => {
    // this.setState({ redo: [...newProps.redo] });
    const dispatch = useDispatch();
  };

  setCurZip = async (e) => {
    var zip = e.target.value;
    var error = this.state.error;
    this.setState({ curVal: zip });
    this.setState({
      curCity: { name: false, zipCode: false, selected: false },
    });
    if (this.state.curCity.name) {
      this.setState({
        curCity: {
          name: false,
          state: false,
          zipCode: false,
          selected: false,
        },
        addLoc: [],
        nearLoc: [],
        curVal: "",
      });
    }
    if (zip.length === 5) {
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      var city;
      var state;
      var res = await callApi("POST", "v1/LHS/other/getCityState", null, {
        zip: zip,
      });
      if (res.result === "OK") {
        city = res.data.city;
        state = res.data.state;
        error.curLoc = false;
        this.setState({ error: error });
        var temp = {
          name: city,
          state: state,
          zipCode: zip,
        };

        var addLoc = this.state.addLoc;
        addLoc = [temp];
        this.setState({ addLoc: [...addLoc] });
      } else {
        error = this.state.error;
        error.curLoc = "This isn't a zip code of the city in USA.";
        this.setState({ error: error });
      }
    } else {
      error = this.state.error;
      error.curLoc = "Required 5 digits as a zip code.";
      this.setState({ error: error });
    }
  };

  setOtherZip = async (e) => {
    var zip = e.target.value;
    var error = this.state.error;
    var otherLoc = this.state.otherLoc;
    this.setState({ nearVal: zip });
    var city, state;
    if (zip.length === 0) {
      error.nearLoc = "";
    } else if (zip.length !== 5) {
      error.nearLoc = "Required 5 digits as a zip code.";
      if (otherLoc.length && !otherLoc[otherLoc.length - 1].selected)
        otherLoc.pop();
    } else {
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      if (zip[0] === "0") {
        zip = zip.substring(1);
      }
      var res = await callApi("POST", "v1/LHS/other/getCityState", null, {
        zip: zip,
      });
      if (res.result === "OK") {
        city = res.data.city;
        state = res.data.state;
        error.nearLoc = "";
        otherLoc.push({
          name: city,
          state: state,
          zipCode: zip,
          selected: false,
        });
      } else {
        error = this.state.error;
        error.nearLoc = "This isn't a zip code of the city in USA.";
        this.setState({ error: error });
      }
    }
    this.setState({ error: error, otherLoc: otherLoc });
  };

  setCurLoc = async (name, state, zipCode) => {
    if (!this.state.selected) {
      var addLoc = this.state.addLoc;
      if (addLoc.length) {
        addLoc[0].selected = true;
        this.setState({
          addLoc: [...addLoc],
        });
      } else {
        addLoc.push({
          name: name,
          state: state,
          zipCode: zipCode,
          selected: true,
        });
      }
      var error = this.state.error;
      error.curLoc = false;
      this.setState({ error: error });
      this.setState({
        curCity: { name: name, zipCode: zipCode, state: state, selected: true },
      });
      this.setState({ curVal: name + ", " + state });
      zipCode = parseInt(zipCode, 10);
      var res = await callApi("POST", "v1/LHS/other/getNearCityState", null, {
        zipCode: zipCode,
        name: name,
        state: state,
      });
      if (res.result === "OK") this.setState({ nearLoc: [...res.data] });
      // this.setState({nearLoc: []}, async () => {
      //     let num = 0;
      //     var i = 0;
      //     if(zipCode < 60000){
      //          while (num < 6) {
      //             var res = await callApi('POST', 'v1/LHS/other/getCityState', null, {zip: zipCode + i});
      //             if(res.result === 'OK'){
      //                 var newCity = res.data.city;
      //                 var newState = res.data.state;
      //                 if(name !== newCity || state !== newState){
      //                     var temp = {
      //                         name: newCity,
      //                         state: newState,
      //                         zipCode: zipCode + i
      //                     };
      //                     var nearLoc = this.state.nearLoc;
      //                     var repeat = false;
      //                     for(var j = 0; j < nearLoc.length; j++){
      //                         if(nearLoc[j].name === newCity && nearLoc[j].state === newState )
      //                             repeat = true;
      //                     }
      //                     if(!repeat){
      //                         nearLoc.push(temp);
      //                         this.setState({nearLoc: [...nearLoc]});
      //                         num++;
      //                     }
      //                     i++;
      //                     continue;
      //                 } else {
      //                     i++;
      //                     continue;
      //                 }
      //             } else {
      //                 i++;
      //                 continue;
      //             }
      //         }
      //     } else {
      //         while(num < 6) {
      //             var res = await callApi('POST', 'v1/LHS/other/getCityState', null, {zip: zipCode - i});
      //             if(res.result === 'OK'){
      //                 var newCity = res.data.city;
      //                 var newState = res.data.state;
      //                 if(name !== newCity || state !== newState){
      //                     var temp = {
      //                         name: newCity,
      //                         state: newState,
      //                         zipCode: zipCode - i
      //                     };
      //                     var nearLoc = this.state.nearLoc;
      //                     var repeat = false;
      //                     for(var j = 0; j < nearLoc.length; j++){
      //                         if(nearLoc[j].name === newCity && nearLoc[j].state === newState )
      //                             repeat = true;
      //                     }
      //                     if(!repeat){
      //                         nearLoc.push(temp);
      //                         this.setState({nearLoc: [...nearLoc]});
      //                         num++;
      //                     }
      //                     i++;
      //                     continue;
      //                 } else {
      //                     i++;
      //                     continue;
      //                 }
      //             } else {
      //                 i++;
      //                 continue;
      //             }
      //         }
      //     }
      // });
    }
  };

  setOtherLoc = (name, state, zipCode, selected) => {
    var otherLoc = this.state.otherLoc;
    var selNum = false;

    for (var i = 0; i < otherLoc.length; i++) {
      if (otherLoc[i].zipCode === zipCode) {
        selNum = i;
      }
    }

    if (otherLoc[selNum].selected) otherLoc.splice(selNum, 1);
    else {
      otherLoc[selNum].selected = true;
      this.setState({ nearVal: "" });
    }

    this.setState({ otherLoc: [...otherLoc] });
  };

  setNearLoc = (name, state, zipCode, selected) => {
    if (!this.state.error.nearLoc) {
      var nearLoc = this.state.nearLoc;
      var selNum = false;

      for (var i = 0; i < nearLoc.length; i++) {
        if (nearLoc[i].zipCode === zipCode) {
          selNum = i;
        }
      }

      if (selNum !== false) {
        // update the nearLoc array to change the items selected value
        nearLoc[selNum].selected = selected ? false : true;
      } else {
        nearLoc.push({
          name: name,
          state: state,
          zipCode: zipCode,
          selected: true,
        });
      }
      this.setState({ nearLoc: [...nearLoc] });

      var error = this.state.error;
      error.nearLoc = false;
      this.setState({ error: error });
    }
  };

  // continueToNext = () => {
  //   console.log(this.state.curCity.selected, "this.state.curCity.selected")
  //   console.log(this.state.curCity.selected, this.state.error.nearLoc, "this.state.curCity.selected")
  //   if (this.state.curCity.selected) {
  //     if (!this.state.error.nearLoc) {
  //       var nearCity = [];
  //       var otherLoc = this.state.otherLoc;
  //       var nearLoc = this.state.nearLoc;
  //       for (var i = 0; i < otherLoc.length; i++) {
  //         if (otherLoc[i].selected) nearCity.push(otherLoc[i]);
  //       }
  //       for (i = 0; i < nearLoc.length; i++) {
  //         if (nearLoc[i].selected) nearCity.push(nearLoc[i]);
  //       }

  //       console.log(nearCity);

  //       // var redo = this.props.redo;
  //       // redo[1] = true;
  //       // this.props.setPageVisited(redo);
  //       // var locations = {
  //       //   curCity: this.state.curCity,
  //       //   nearCity: nearCity,

  //       //   /**page State */
  //       //   curVal: this.state.curVal,
  //       //   nearVal: this.state.nearVal,
  //       //   addLoc: this.state.addLoc,
  //       //   otherLoc: this.state.otherLoc,
  //       //   nearLoc: this.state.nearLoc,
  //       // };
  //       // console.log("locations::", locations)
  //       // this.props.setLocations(locations);
  //       // window.location.href = "/staffSignUp_2"
  //       // this.props.onFormSubmit();
  //     }
  //   } else {
  //     var error = this.state.error;
  //     error.curLoc = " Please select your current location.";
  //     this.setState({ error: error });
  //   }

  //   this.props.onFormSubmit();
  // };
  // continueToNext = () => {
  //   // Check if the required data is available
  //   if (this.state.curCity.selected) {
  //     if (!this.state.error.nearLoc) {
  //       // Prepare the data to be stored in Redux
  //       const nearCity = [];
  //       const otherLoc = this.state.otherLoc;
  //       const nearLoc = this.state.nearLoc;
  //       for (let i = 0; i < otherLoc.length; i++) {
  //         if (otherLoc[i].selected) nearCity.push(otherLoc[i]);
  //       }
  //       for (let i = 0; i < nearLoc.length; i++) {
  //         if (nearLoc[i].selected) nearCity.push(nearLoc[i]);
  //       }

  //       // Dispatch actions to update the Redux store
  //       this.props.setLocations({
  //         curCity: this.state.curCity,
  //         nearCity: nearCity,
  //         // Include other data you want to store
  //       });

  //       // Call the onFormSubmit function to redirect to the second page
  //       this.props.onFormSubmit();
  //     }
  //   } else {
  //     // Handle the case where curCity is not selected
  //     const error = this.state.error;
  //     error.curLoc = "Please select your current location.";
  //     this.setState({ error: error });
  //   }
  // };
  continueToNext = () => {
    if (
      !this.state.loading &&
      this.state.curCity.selected &&
      !this.state.error.nearLoc
    ) {
      this.setState({ loading: true });
      setTimeout(() => {
        var nearCity = [];
        var otherLoc = this.state.otherLoc;
        var nearLoc = this.state.nearLoc;
        for (var i = 0; i < otherLoc.length; i++) {
          if (otherLoc[i].selected) nearCity.push(otherLoc[i]);
        }
        for (i = 0; i < nearLoc.length; i++) {
          if (nearLoc[i].selected) nearCity.push(nearLoc[i]);
        }
        // Redirect to the next page
        this.props.onFormSubmit({ currentLocation: this.state?.curCity });

        // Reset loading state after redirect or any asynchronous operation
        this.setState({ loading: false });
      }, 1000);
    } else {
      var error = this.state.error;
      error.curLoc = " Please select your current location.";
      this.setState({ error: error });
    }
  };

  // const handleBack = () => {
  //   dispatch(decrementStep());
  // };
  // handleBack = () => {
  //   if (this.props.onBack) {
  //     // Check if there is an `onBack` function passed as a prop
  //     if (Object.keys(this.state.error).length > 0) {
  //       // Check if there are errors in the state
  //       // If there are errors, show the error message and prevent going back
  //       console.log("Errors found. Cannot go back.");
  //     } else {
  //         this.props.history.push(this.props.prev);
  //       // No errors, call the `onBack` function
  //       this.props.onBack();
  //     }
  //   }
  // };
  // handleBack = () => {
  //   this.props.dispatch(decrementStep());
  //   // Add your custom logic here if needed.
  // };

  handleBack = () => {
    // decrementStep();
    this.props.history.push("/signup");
  };

  render() {
    // const dispatch = useDispatch();
    // const { dispatch, yourReduxState } = this.props;
    return (
      <div
        className="d-flex h-screen overflow-hidden"
        style={{ backgroundColor: "#73C7A9" }}
      >
        <div
          className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
          style={{ padding: "0", maxWidth: 720, width: "100%" }}
        >
          <div className="ssu_container py-4 px-5 min-h-[90vh] max-h-[90vh] h-auto flex flex-col">
            {/* <SubHeader
              num="2"
              title="Location"
              redo={this.state.redo[1]}
              onBack={this.handleBack}

              next="/staffSignUp_2"
              prev="/staffSignUp"
              // history={this.props.history}
              img={img}
            /> */}
            <HeaderComp />
            <div className="flex flex-col justify-between h-screen">
              <div className="text-center">
                <SubHeader
                  num="1"
                  title="Location"
                  next="/staffSignUp_4"
                  prev="/staffSignUp_4"
                  // history={/* your history object */}
                  img={img}
                  // dispatch={this.props.dispatch} // Pass the dispatch function
                  onBack={this.handleBack}
                />
                <div className="ssu_body mt-4 w-auto">
                  <div className="ssu_txt1 text-xl mt-4 mb-0">
                    What's your current location?
                  </div>
                  <div className="ssu1_input">
                    <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                      <div className="input_left_icon">
                        <img
                          alt="img"
                          src={place}
                          width="16px"
                          height="20px"
                          style={{ marginTop: "-3px" }}
                        />
                      </div>
                      <input
                        className="ssu2_modal3_select"
                        placeholder="Zip Code"
                        type="text"
                        value={this.state.curVal}
                        name="curLoc"
                        onChange={this.setCurZip}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") this.continueToNext();
                        }}
                      />
                    </div>
                    <ErrorState
                      show={this.state.error.curLoc ? "block" : "none"}
                      name={this.state.error.curLoc}
                    />
                  </div>
                  <div className="row ssu1_locs">
                    {this.state.addLoc.map((item) => {
                      return (
                        <AddLocItem
                          key={item.zipCode}
                          selected={item.selected}
                          name={item.name}
                          setCurLoc={this.setCurLoc}
                          state={item.state}
                          zipCode={item.zipCode}
                        />
                      );
                    })}
                  </div>
                  {/* <div className="ssu1_txt2">
                Are you interested in working in other cities?
              </div>
              <div className="ssu1_txt3">If yes, specify zip code.</div>
              <div className="ssu1_input1">
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div className="input_left_icon">
                    <img
                      alt="img"
                      src={place}
                      width="16px"
                      height="20px"
                      style={{ marginTop: "-3px" }}
                    />
                  </div>
                  <input
                    className={`ssu2_modal3_select ${
                      this.state.isInputFocused ? "transparent-background" : ""
                    }`}
                    placeholder="Zip Code"
                    type="text"
                    defaultValue={this.state.nearVal}
                    name="nearLoc"
                    // onChange={this.setOtherZip}
                    onFocus={() => this.setState({ isInputFocused: true })}
                    onBlur={() => this.setState({ isInputFocused: false })}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") this.continueToNext();
                    }}
                    onChange={this.handleInputChange}
                  />
                </div>
                <ErrorState
                  show={this.state.error.nearLoc ? "block" : "none"}
                  name={this.state.error.nearLoc}
                />
              </div>
              <div className="row ssu1_locs">
                {this.state.otherLoc.map((item) => {
                  return (
                    <SuggestionItem
                      key={item.zipCode}
                      selected={item.selected}
                      name={item.name}
                      state={item.state}
                      setNearLoc={this.setOtherLoc}
                      zipCode={item.zipCode}
                    />
                  );
                })}
              </div>
              <div className="ssu1_txt4">
                Suggested locations nearby your current location{" "}
              </div>
              <div className="row ssu1_nearLocs">
                {this?.state?.nearLoc?.map((item) => {
                  return (
                    <SuggestionItem
                      key={item.zipCode}
                      selected={item.selected}
                      name={item.name}
                      state={item.state}
                      setNearLoc={this.setNearLoc}
                      zipCode={item.zipCode}
                    />
                  );
                })}
              </div> */}
                </div>
                {/* <hr style={{ margin: "10px 0px 0px" }} /> */}
              </div>
              <div className="ssu_bottom">
                <div className="ssu_button" onClick={this.continueToNext}>
                  {this.state.loading ? <Loader /> : "CONTINUE"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StaffSignUp_1);
