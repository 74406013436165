import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Button from "./Button";
import classNames from "classnames";

export default function Pagination({
  totalRecords = null,
  pageLimit = 10,
  holdPageState = 0,
  moveBack = 0,
  onPageChange = null,
}) {
  const [page, setPage] = useState({ selected: 0 });
  const pageCount = Math.ceil(totalRecords / pageLimit) || 1;
  const lastPage = (page?.selected + 1) * pageLimit;

  return (
    <div className="pagination relative content-center place-content-center">
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <Button
            className={classNames(
              { "opacity-50 cursor-not-allowed": totalRecords <= lastPage },
              "rounded-[10px]"
            )}
            aria-hidden="true"
          >
            <span className="hidden sm:block px-2">{"Next >"}</span>
          </Button>
        }
        onPageChange={(e) => {
          onPageChange(e);
          setPage(e);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        forcePage={moveBack ? 0 : holdPageState}
        pageCount={pageCount}
        previousClassName="absolute sm:left-8 rounded-xl overflow-hidden"
        nextClassName="absolute sm:right-8 rounded-xl overflow-hidden"
        previousLabel={
          <Button
            className={classNames(
              { "opacity-50 cursor-not-allowed": holdPageState == 0 },
              "rounded-[10px]"
            )}
            aria-hidden="true"
          >
            <span className="hidden sm:block px-2">{"< Prev"}</span>
          </Button>
        }
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
