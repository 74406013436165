import React from "react";
import edit from "../../assets/images/editDark.png";
import deleteBox from "../../assets/images/deleteDark.png";
import Loader from "../../modals/Loader";
import classNames from "classnames";

const CardEditDelete = ({ onEdit, onDelete, loading, className, children }) => {
  return (
    <div
      className={classNames(
        className,
        "flex border border-solid border-opacity-10 p-4 rounded-lg relative"
      )}
    >
      <div className="w-11/12">{children}</div>
      <div className="flex absolute top-2 right-2 gap-2 p-1">
        {onEdit && (
          <div className="flex items-center">
            <img
              src={edit}
              onClick={onEdit}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
          </div>
        )}

        {onDelete && (
          <div className="flex w-max items-center" onClick={onDelete}>
            {loading ? (
              <Loader />
            ) : (
              <img
                src={deleteBox}
                onClick={onDelete}
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardEditDelete;
