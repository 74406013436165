import React, { useState, useEffect } from "react";
import edit from "../../../assets/images/edit_note.svg";
import del from "../../../assets/images/delete.svg";
import keyhole from "../../../assets/images/keyhole.svg";
import ErrorState from "../../../theme_1/staffSignUp/components/errorState";
import Confirm from "../../../modals/confirm";
import { callApi } from "../../../../redux/action/action";
import { axiosInstance } from "../../../../config/axiosConfig";

const Insurance = (props) => {
  const [insurance, setInsurance] = useState({});
  const [provider, setProvider] = useState("");
  const [tprovider, setTProvider] = useState("");
  const [number, setNumber] = useState("");
  const [tnumber, setTNumber] = useState("");
  const [showEditModal, setShowEditModal] = useState("none");
  const [error, setError] = useState({
    provider: "none",
    number: "none",
  });
  const [showConfirm, setShowConfirm] = useState("none");
  const [confirmTitle, setConfirmTitle] = useState("");
  const [agreeFn, setAgreeFn] = useState(false);
  const [title, setTitle] = useState("Add");
  const [isAdmin, setIsAdmin] = useState(false);

  const confirmDelete = () => {
    setShowConfirm("block");
    setConfirmTitle(tprovider);
    setAgreeFn(deleteOneRow);
  };

  const deleteOneRow = async () => {
    const data = {
      liabilityInsurance: {
        insuranceProvider: "",
        policyNumber: "",
      },
    };
    const token = "Bearer " + localStorage.getItem("token");
    const id = window.localStorage.getItem("_id");
    const res = await axiosInstance.put(`v1/LHS/staff/update/${id}`, data);
    if (res.data) {
      setTProvider("");
      setTNumber("");
      setShowConfirm("none");
    }
  };

  const editOneRow = () => {
    setProvider(tprovider);
    setNumber(tnumber);
  };

  const showAddModal = () => {
    setShowEditModal("block");
  };

  const closeModal = () => {
    setShowEditModal("none");
  };

  const setInsurance1 = async () => {
    if (title === "Edit") {
      if (providerError === "none" && numberError === "none") {
        const data = {
          liabilityInsurance: {
            insuranceProvider: provider,
            policyNumber: number,
          },
        };
        const token = "Bearer " + localStorage.getItem("token");
        const id = window.localStorage.getItem("_id");
        const res = await callApi(
          "PUT",
          `v1/LHS/staff/update/${id}`,
          token,
          data
        );
        if (res.data) {
          setTProvider(res?.data?.liabilityInsurance?.insuranceProvider);
          setTNumber(res?.data?.liabilityInsurance?.policyNumber);
        }
        closeModal();
      }
    } else if (title === "Add") {
      if (providerError === "none" && numberError === "none") {
        const data = {
          liabilityInsurance: {
            insuranceProvider: provider,
            policyNumber: number,
          },
        };
        const id = window.localStorage.getItem("_id");
        const res = await axiosInstance.put(`v1/LHS/staff/update/${id}`, data);
        if (res.data) {
          setTProvider(res?.data?.liabilityInsurance?.insuranceProvider);
          setTNumber(res?.data?.liabilityInsurance?.policyNumber);
        }
        closeModal();
      }
    }
  };

  const id = localStorage.getItem("_id");
  const typeData = localStorage.getItem("type");
  const fetchApiData = async () => {
    const res = await axiosInstance.get(`v1/LHS/${typeData}/getById/${id}`);
    if (res.data) {
      setTProvider(res?.data?.liabilityInsurance?.insuranceProvider);
      setTNumber(res?.data?.liabilityInsurance?.policyNumber);
    }
  };
  useEffect(() => {
    if (id) {
      fetchApiData();
    }
  }, [id]);

  return (
    <div>
      <div className="nur_text1" style={{ marginTop: "60px" }}>
        <h4 style={{ textAlign: "left" }}>Liability Insurance</h4>
        <hr className="t2_sp_hr" />
        {tprovider ? (
          <div
            className="row"
            style={{
              overflowX: "auto",
              marginTop: "32px",
            }}
          >
            <table
              style={{
                minWidth: "800px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              <thead>
                <tr className="nurseHead">
                  <th
                    style={{ paddingLeft: "50px" }}
                    className="t2_sp_licence_th"
                  >
                    {" "}
                    Insurance provider{" "}
                  </th>
                  <th className="t2_sp_licence_th"> Policy number </th>
                  {!isAdmin ? (
                    <th
                      style={{
                        width: props.admin ? "80px" : "130px",
                      }}
                      className="t2_sp_licence_th"
                    >
                      {" "}
                      Action{" "}
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 20 }} />
                <tr className="t2_sp_licence_row">
                  <td
                    style={{ paddingLeft: "50px" }}
                    className="t2_sp_licence_td"
                    value={tprovider}
                  >
                    {" "}
                    {tprovider}{" "}
                  </td>
                  <td className="t2_sp_licence_td" value={tnumber}>
                    {" "}
                    {tnumber}{" "}
                  </td>
                  {!isAdmin ? (
                    <td>
                      <span
                        style={{
                          marginRight: "20px",
                        }}
                        onClick={() => editOneRow()}
                      >
                        <img
                          width="20px"
                          height="20px"
                          alt="img"
                          src={edit}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                      <span onClick={confirmDelete}>
                        <img
                          width="20px"
                          height="18px"
                          alt="img"
                          src={del}
                          style={{ cursor: "pointer", display: "inline" }}
                        />
                      </span>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
        {tprovider || isAdmin ? (
          ""
        ) : (
          <div className="ssu2_addItem" onClick={showAddModal}>
            + Add {tprovider && tprovider ? "Another" : ""} Insurance
          </div>
        )}
      </div>
      <div className="w3-modal " style={{ display: showEditModal }}>
        <div className="w3-modal-content ssu2_modal1 ">
          <div className="w3-container ">
            <div className="ssu2_modal1_text1 ">
              {title} Liability Insurance
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="ssu2_modal1_input" style={{ marginTop: "30px" }}>
              <div className="ssu2_modal1_input">
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div className="input_left_icon">
                    <img
                      width="22px"
                      height="23px"
                      alt="img"
                      src={keyhole}
                      style={{ marginTop: "-3px" }}
                    />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="Insurance provider"
                    type="text"
                    value={provider}
                    onChange={setProvider}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") setInsurance();
                    }}
                  />
                </div>
                <ErrorState
                  show={error.provider}
                  name="Insurance provider is required."
                />
              </div>
            </div>
            <div className="ssu2_modal1_input">
              <div className="ssu2_modal1_input">
                <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                  <div className="input_left_icon">
                    <img
                      width="22px"
                      height="23px"
                      alt="img"
                      src={keyhole}
                      style={{ marginTop: "-3px" }}
                    />
                  </div>
                  <input
                    className="ssu2_modal3_select"
                    placeholder="Policy number"
                    type="text"
                    value={number}
                    onChange={setNumber}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") setInsurance();
                    }}
                  />
                </div>
                <ErrorState
                  show={error.number}
                  name="Policy number is required."
                />
              </div>
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="row ssu_bottom">
              <button
                className="ssu2_modal1_button1"
                onClick={() => setInsurance()}
              >
                {" "}
                SAVE{" "}
              </button>
              <button
                className="border !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                onClick={closeModal}
              >
                {" "}
                CANCEL{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        title={'Delete "' + confirmTitle + '"'}
        display={showConfirm}
        agreeFn={agreeFn}
        content={'Are you sure you want to delete "' + confirmTitle + '"'}
        disagreeFn={() => setShowConfirm("none")}
      />
    </div>
  );
};

export default Insurance;
