import React, { useState, useEffect } from "react";
import Confirm from "../../modals/confirm";
import search from "../../assets/images/search.svg";
import Pagination from "./pagination";
import { callApi } from "../../../redux/action/action";
import { Link } from "react-router-dom";
import edit from "../../assets/images/edit_note.svg";
import Loader from "../../modals/Loader";
import Nav from "../../theme_1/Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import NewAdministrators from "./NewAdministrators";
import "../theme2.css";

function ManageAdmin() {
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [adminsList, setAdminsList] = useState([]);
  const [showAdminForm, setShowAdminForm] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [skip, setSkip] = useState(0);
  const [showLoader, setShowLoader] = useState("");
  const [showConfirm, setShowConfirm] = useState("");
  const [confirmTitle, setConfirmTitle] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("type");
    if (data !== "superAdmin") {
      setIsSuperAdmin(false);
    }
    getAllAdmins();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getAllAdmins = async (skip = 0) => {
    var token = localStorage.getItem("accessToken");
    token = "Bearer " + token;
    var res = await axiosInstance.get(
      `v1/LHS/admin/adminList?search=${searchKey}&skip=${skip}`
    );
    console.log(res.data, "res.datares.data");
    if (res.status === 200) {
      var totalCount = res.data.count;
      var page = Math.ceil(totalCount / 10);
      setAdminsList(res.data.data);
      setTotalPage(page);
      setShowLoader("");
    } else {
      setAdminsList([]);
    }
  };

  const changeAdminStatus = async (status, id) => {
    setShowLoader(id);
    var token = localStorage.getItem("accessToken");
    token = "Bearer " + token;
    let userObj = {
      isDeleted: status ? false : true,
    };
    var res = await callApi("PUT", `v1/LHS/admin/update/${id}`, token, userObj);
    console.log(res);
    if (res.Message === "User Updated Successfully") {
      getAllAdmins(skip);
    }
  };

  const getSearchReq = async (e) => {
    await setSearchKey(e.target.value);
    await setCurPage(1);
    await getAllAdmins();
  };

  return (
    <>
      <Nav />
      <div className="theme2_body" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
        <div className="t2_sp_work" style={{ marginTop: 0 }}>
          <div className="row m-0">
            <div className="col-md-8 col-lg-8 col-12 p-0">
              <div className="admin_mngAdmin_nameFilter w-100">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img style={{ width: 16 }} alt="search.svg" src={search} />
                  <input
                    className="admin_mngAdmin_nameFilter_input"
                    placeholder="Search by name"
                    type="text"
                    value={searchKey}
                    onChange={getSearchReq}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-12 p-0">
              {isSuperAdmin ? (
                <button
                  className="w-100 admin_mngAdmin_roleFilter admin_mngAdmin_addAdmin"
                  onClick={() => setShowAdminForm(true)}
                >
                  + Add New Administrators
                </button>
              ) : null}
            </div>
          </div>
          <div
            className="row hide_scrollbar"
            style={{
              overflowX: "auto",
              marginTop: "32px",
              display: adminsList.length ? "block" : "none",
            }}
          >
            <table
              className="admin_table"
              style={{
                width: "99%",
                minWidth: "800px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              <thead>
                <tr className="nurseHead">
                  <th
                    className="t2_sp_licence_th"
                    style={{ paddingLeft: "30px" }}
                  >
                    {" "}
                    Name{" "}
                  </th>
                  <th className="t2_sp_licence_th"> Email Address </th>
                  <th className="t2_sp_licence_th"> Permissions </th>
                  <th className="t2_sp_licence_th"> Change Status </th>
                  {isSuperAdmin ? (
                    <th className="t2_sp_licence_th" style={{ width: "80px" }}>
                      {" "}
                      Action{" "}
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 20 }} />
                {adminsList?.length > 0 &&
                  adminsList.map((item) => (
                    <React.Fragment key={item._id}>
                      <tr className="admin_mngStaff_row">
                        <td
                          className="admin_mngStaff_td"
                          style={{ paddingLeft: "30px" }}
                        >
                          {item.firstName} {item.lastName}
                        </td>
                        <td className="admin_mngStaff_td"> {item.email} </td>
                        <td className="admin_mngStaff_td">
                          {(item.permissions || []).map((v, i) => (
                            <span
                              key={i}
                              className="w3-tag w3-blue w3-round ms-2"
                            >
                              {v}
                            </span>
                          ))}
                        </td>
                        {item.role !== "superAdmin" ? (
                          <td className="admin_mngStaff_td">
                            <button
                              className="w-75 m-0 me-5 admin_mngAdmin_roleFilter admin_mngAdmin_addAdmin"
                              style={{
                                background: !item.isDeleted ? "#ff7675" : "",
                              }}
                              onClick={() =>
                                changeAdminStatus(item.isDeleted, item._id)
                              }
                            >
                              {showLoader === item._id ? (
                                <Loader />
                              ) : item.isDeleted ? (
                                "Enable"
                              ) : (
                                "Disable"
                              )}
                            </button>
                          </td>
                        ) : (
                          <td className="admin_mngStaff_td"></td>
                        )}
                        <td className="admin_mngStaff_td">
                          <Link
                            to={{
                              pathname: "/main/admin/NewAdministrators",
                              state: item,
                            }}
                            style={{ marginRight: "20px" }}
                          >
                            <img
                              width="16px"
                              height="16px"
                              alt="img"
                              src={edit}
                              style={{ cursor: "pointer" }}
                            />
                          </Link>
                        </td>
                      </tr>
                      <tr style={{ height: 24 }}></tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
            <Confirm
              display={showConfirm}
              title={'Delete "' + "confirmTitle" + '"'}
              content={
                'Are you sure you want to delete "' + "confirmTitle" + '"'
              }
              agreeFn={true}
              disagreeFn={() => setConfirmTitle("none")}
            />
          </div>
          {!adminsList.length ? (
            <p className="text-center mt-5">No search found</p>
          ) : null}
          {!adminsList.length ? null : (
            <Pagination
              curPage={curPage}
              totalPage={totalPage}
              setCurPage={setCurPage}
            />
          )}
        </div>
      </div>
      <NewAdministrators />
    </>
  );
}

export default ManageAdmin;
