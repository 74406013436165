import Button from "../../../ui/Button";

export const AddReasonModal = (props) => {
  const [showErr, setShowErr] = useState(false);
  return (
    <Modal {...props}>
      <div className="">
        <div className="flex gap-4 items-center">
          <div className="w-14 h-14 rounded-full overflow-hidden">
            <img
              src={
                props?.userData?.image
                  ? props?.userData?.image
                  : props?.userData?.avatar
              }
              className="object-cover w-full h-full"
            />
          </div>
          <div>
            <p className="text-lg">{props?.userData?.name}</p>
            <div className="flex gap-2 opacity-70">
              <p>{props?.userData?.jobTitle}</p> |{" "}
              <p>
                {props?.userData?.currentLocation?.name},
                {props?.userData?.currentLocation?.state}
              </p>{" "}
              | <p>Reviews {props?.userData?.ratings?.totalRatings}</p>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center my-4">
          <p className="mb-0 ">Reason for rejecting</p>
        </div>

        <div className="row m-0 rate_reviw_card_comment mt-3">
          <textarea
            id="w3review"
            name="w3review"
            rows="4"
            cols="50"
            placeholder="Write your comment here"
            style={{
              borderColor: showErr && props.rejectReason === "" ? "red" : "",
            }}
            onChange={props.setRejectReason}
          ></textarea>
        </div>
      </div>

      <div className="row m-0 justify-content-center align-items-center rate_reviw_card_footer mt-3">
        <div className="flex gap-4 justify-end">
          <Button
            onClick={props?.onClose}
            className="border !border-[#73C7A9] !text-[#73C7A9]  rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
          >
            CANCEL
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowErr(true);
              if (props.rejectReason) {
                props.rejectJobOffer(props?.userData?._id);
                setShowErr(false);
              }
            }}
            className="post_review_btn rounded-full"
          >
            SUBMIT
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../../../config/axiosConfig";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "../../../common/modal";
import StarIcon from "@material-ui/icons/Star";
import moment from "moment";
import RateReviewModal from "../../../containers/staff/rate_review_modal";
import classNames from "classnames";
import { showErrorToast } from "../../../common/toastMeassage/ToastMeassage";

function ApplicantData({
  data,
  loading,
  jobDetails,
  getAllApplicationsList,
  modalOpen,
  modalClose,
}) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [rejectReason, setrejectReason] = useState("");
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [jobOfferModal, setJobOfferModal] = useState(false);
  const [resumeModal, setResumeModal] = useState(false);
  const [jobOfferDetails, setJobOfferDetails] = useState({
    startDate: "",
    endDate: "",
    totalDays: "",
  });

  const getAllReviews = async (id) => {
    try {
      setLoader(true);
      setShowReviewModal(true);
      const data = await axiosInstance.get(
        `v1/LHS/rating/getStaffRating/${id}`
      );
      if (data.status === 200) {
        setReviewData(data.data.data);
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const rejectJobOffer = async (rejectedId) => {
    try {
      setLoader(true);
      let obj = {
        rejectionReason: rejectReason,
      };
      let url = `v1/LHS/job/jobApplication/reject/${rejectedId}`;
      const responseData = await axiosInstance.post(url, obj);
      console.log(responseData);
      if (responseData?.data?.status === 200) {
        showSuccessToast(responseData?.data?.Message);
      }
    } catch (err) {
    } finally {
      setLoader(false);
      modalClose();
      setShowReasonModal(false);
      getAllApplicationsList();
    }
  };

  const initializeChat = async () => {
    const data = {
      id: userData?.staff,
      type: 0,
      chatType: 1,
    };
    try {
      setLoader("chatLoader");
      let url = `/v2/LHS/User/sendChatRequest`;
      const response = await axiosInstance.post(url, data);
      if (response?.status === 200) {
        history.push({
          pathname: "/hospitalMsg",
          search: "param1=tab3",
        });
      }
    } catch (error) {
      if (error?.message == "Chat request already sent!") {
        history.push({
          pathname: "/hospitalMsg",
          search: "param1=tab1",
        });
      }
    } finally {
      setLoader(false);
    }
  };

  const sendJobOffer = async () => {
    try {
      setLoader("jobOfferLoader");
      const { startDate, endDate } = jobOfferDetails || {};
      if (!startDate) {
        showErrorToast("Please select start date");
        return;
      }
      if (jobDetails?.contractType == "Temporary Position" && !endDate) {
        showErrorToast("Please select end date");
        return;
      }
      let totalDays = null;
      if (startDate && endDate) {
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        totalDays = endDateMoment.diff(startDateMoment, "days");
      }
      const res = await axiosInstance.post(
        `v1/LHS/job/jobApplication/offer/${data?._id}`,
        {
          startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
          endDate: endDate
            ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
            : "",
          totalDays,
        }
      );
      setJobOfferModal(false);
      if (res.status === 404) {
      } else {
        getAllApplicationsList();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setUserData(data);
    setJobOfferDetails({ startDate: "", endDate: "", totalDays: "" });
  }, [data]);

  const changeJobStatus = async (status) => {
    try {
      status != "Review" && setLoader(status);
      const res = await axiosInstance.post(
        `v1/LHS/job/changeApplicationStatus/${data?._id}`,
        { status }
      );

      if (res.status === 404) {
      } else {
        getAllApplicationsList();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const isUnsupportedFormat = (url) => {
    if (!url) return false;

    const extension = url.split(".").pop();
    const unsupportedExtensions = ["docx"];

    return unsupportedExtensions.includes(extension.toLowerCase());
  };

  useEffect(() => {
    if (userData?.status == "Applied") changeJobStatus("Review");
  }, [userData?.status]);

  return (
    <Modal
      isOpen={modalOpen}
      onClose={modalClose}
      wrapperClass={"!p-6"}
      className={"md:w-1/2 md:mt-0"}
    >
      <section className="flex flex-col items-center gap-1">
        <div className="w-20 h-20 rounded-full overflow-hidden">
          <img
            src={userData?.image ? userData?.image : userData?.avatar}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex gap-3">
          <p className="font-semibold text-lg">{userData?.name}</p>

          <div className="flex gap-[3px] items-center">
            <StarIcon className="text-yellow-500" />
            <h1 className="text-gray-500 text-sm">
              {userData?.ratings?.ratingSum?.toFixed(1)}
            </h1>
          </div>
        </div>
        <div className="flex gap-2 opacity-70">
          <p>{userData?.jobTitle}</p> |{" "}
          <p>
            {userData?.currentLocation?.name},{userData?.currentLocation?.state}
          </p>{" "}
          | <p>Reviews {userData?.ratings?.totalRatings}</p>
          {userData?.ratings?.totalRatings > 0 && (
            <div
              className="text-[#73C7A9] text-[14px] cursor-pointer self-center"
              onClick={(e) => {
                getAllReviews(userData?.staff);
              }}
            >
              See all
            </div>
          )}
        </div>
        <div className="flex gap-4 mt-7">
          {(userData?.status == "Review" ||
            userData?.status == "Applied" ||
            userData?.status == "Interview") && (
            <Button
              onClick={() => setShowReasonModal(true)}
              loading={loading == "Review" && false}
              className="px-7 rounded-full !text-[#333333] bg-white border !border-[#333333]"
            >
              Reject
            </Button>
          )}
          {(userData?.status == "Review" || userData?.status == "Applied") && (
            <Button
              className="px-7 rounded-full"
              loading={loader == "Interview" || loading}
              onClick={() => {
                changeJobStatus("Interview");
              }}
            >
              Interview
            </Button>
          )}
          {userData?.status === "Interview" && (
            <>
              <Button
                className="px-3 rounded-full"
                loading={loader == "jobOfferLoader" || loading}
                onClick={() => setJobOfferModal(true)}
              >
                Job Offer
              </Button>
              <Button
                loading={loader == "chatLoader"}
                className="px-3 rounded-full"
                onClick={initializeChat}
              >
                Chat
              </Button>
            </>
          )}
          {userData?.status === "Completed" && (
            <Button loading={loading} className="px-3 rounded-full">
              Completed
            </Button>
          )}
          {userData?.status === "Offer Declined" && (
            <Button loading={loading} className="px-3 rounded-full">
              Offer Declined
            </Button>
          )}
          {userData?.status === "In Progress" && (
            <Button loading={loading} className="px-3 rounded-full">
              In-Progress
            </Button>
          )}
          {userData?.status === "Job Offered" && (
            <Button loading={loading} className="px-3 rounded-full">
              Job Offered
            </Button>
          )}
          {userData?.status === "Rejected" && (
            <Button loading={loading} className="px-3 rounded-full">
              Rejected
            </Button>
          )}
        </div>
      </section>
      <hr className="my-4 -mx-14" />
      <section className="flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Medical settings</h1>
        <p className="w-fit rounded-full px-4 py-2 bg-[#73C7A9] text-white">
          {userData?.medicalSettings}
        </p>
      </section>
      <hr className="my-4 -mx-14" />
      <section className="flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Nursing license</h1>
        {userData?.nursingLicense?.map((item, index) => {
          return (
            <div
              style={{
                display: "grid",
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(1, 1fr 2fr 2fr 2fr 1fr)",
              }}
              className="shadow-sm mb-3 justify-between p-3 rounded-xl"
              key={index}
            >
              <div className="w-10 h-10 rounded-xl overflow-hidden">
                <img src={item?.image} className="object-cover w-full h-full" />
              </div>
              <div>
                <p className="text-sm">State</p>
                <p className="text-xl">{item?.state}</p>
              </div>
              <div>
                <p className="text-sm">Name</p>
                <p className="text-xl">{item?.name}</p>
              </div>
              <div>
                <p className="text-sm">Number</p>
                <p className="text-xl">{item?.number}</p>
              </div>
              <div>
                <p className="text-sm">Expiration Date</p>
                <p className="text-xl">
                  {moment(item?.expirationDate).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
          );
        })}
      </section>
      <hr className="my-4 -mx-14" />
      <section className="flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Certification</h1>
        {userData?.certifications?.map((item, index) => {
          return (
            <div
              style={{
                display: "grid",
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(1, 2fr 2fr 2fr 1fr)",
              }}
              className="shadow-sm mb-3 justify-between p-3 rounded-xl"
              key={index}
            >
              <div>
                <p className="text-sm">Name</p>
                <p className="text-xl">{item?.name}</p>
              </div>
              <div>
                <p className="text-sm">Certifying Authority</p>
                <p className="text-xl">{item?.certifyingAuthority}</p>
              </div>
              <div>
                <p className="text-sm">Received On</p>
                <p className="text-xl">
                  {moment(item?.receivedOn).format("MM/DD/YYYY")}
                </p>
              </div>
              <div>
                <p className="text-sm">Expiration Date</p>
                <p className="text-xl">
                  {moment(item?.expirationDate).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
          );
        })}
      </section>
      <hr className="my-4 -mx-14" />
      <section className="flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Highest education</h1>
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            gridTemplateColumns: "repeat(1, 1fr 1fr 0fr)",
          }}
          className="shadow-sm mb-3 justify-between p-3 rounded-xl"
        >
          <div>
            <p className="text-sm">Degree</p>
            <p className="text-xl">{userData?.education?.degree}</p>
          </div>
          <div>
            <p className="text-sm">College/University</p>
            <p className="text-xl">{userData?.education?.college}</p>
          </div>
          {/* <div>
            <p className="text-sm">Date received</p>
            <p className="text-xl">
              {moment(userData?.education?.receivedOn).format("MM/DD/YYYY")}
            </p>
          </div> */}
          <div>
            <p className="text-sm">Date received</p>
            {userData?.education?.receivedOn && (
              <p className="text-xl">
                {moment(userData.education.receivedOn).format("MM/DD/YYYY")}
              </p>
            )}
          </div>
        </div>
      </section>
      <hr className="my-4 -mx-14" />
      <section className="flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Resume</h1>
        {userData?.resume?.link && (
          <div className="flex shadow-sm mb-3 justify-between p-3 rounded-xl">
            <div>
              <p className="text-xl">
                {userData?.name}.
                {
                  userData?.resume?.link.split(".")?.[
                    userData?.resume?.link.split(".")?.length - 1
                  ]
                }
              </p>
            </div>
            <div className="flex gap-4">
              <Button
                className={
                  "rounded-xl px-6 border border-[#333333] !text-[#333333] bg-white"
                }
                onClick={() => {
                  setResumeModal(true);
                }}
              >
                View
              </Button>
              <Button
                onClick={async () => {
                  try {
                    const response = await fetch(userData?.resume?.link);
                    const blob = await response.blob();
                    const urlParts = userData?.resume?.link.split("/");
                    const filename = urlParts[urlParts.length - 1];
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                  } catch (error) {
                    showErrorToast("Error downloading resume");
                  }
                }}
                className={"rounded-xl px-6"}
              >
                Download
              </Button>
            </div>
          </div>
        )}
      </section>
      <RateReviewModal
        isOpen={showReviewModal}
        onClose={() => setShowReviewModal(false)}
        className={"md:w-1/2 md:mt-0"}
        reviewData={reviewData}
        loader={loader}
        data={userData}
      />

      <AddReasonModal
        isOpen={showReasonModal}
        onClose={() => setShowReasonModal(false)}
        className={"md:w-1/2 md:mt-0"}
        applicationType={"reject"}
        setRejectReason={(e) => setrejectReason(e.target.value)}
        rejectJobOffer={rejectJobOffer}
        rejectReason={rejectReason}
        userData={userData}
        keyValue={true}
      />

      <Modal
        isOpen={resumeModal}
        onClose={() => setResumeModal(false)}
        wrapperClass={"!p-6"}
        className={"md:w-1/2 md:mt-0"}
      >
        {!isUnsupportedFormat(userData?.resume?.link) ? (
          <iframe
            src={userData?.resume?.link}
            title="Resume Viewer"
            style={{ width: "100%", height: "90vh" }}
          ></iframe>
        ) : (
          <div className="place-items-center text-center justify-center w-2/3 h-[40vh] flex">
            <p>
              Your browser does not support rendering this file format. Please
              download the file instead.
            </p>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={jobOfferModal}
        onClose={() => setJobOfferModal(false)}
        wrapperClass={"!p-6"}
        className={"md:w-1/2 md:mt-0"}
      >
        <section className="flex flex-col items-center gap-1">
          <div className="w-20 h-20 rounded-full overflow-hidden">
            <img
              src={userData?.image ? userData?.image : userData?.avatar}
              className="object-cover w-full h-full"
            />
          </div>
          <div className="flex gap-3">
            <p className="font-semibold text-lg">{userData?.name}</p>

            <div className="flex gap-[3px] items-center">
              <StarIcon className="text-yellow-500" />
              <h1 className="text-gray-500 text-sm">
                {userData?.ratings?.ratingSum?.toFixed(1)}
              </h1>
            </div>
          </div>
          <div className="flex gap-2 opacity-70">
            <p>{userData?.jobTitle}</p> |{" "}
            <p>
              {userData?.currentLocation?.name},
              {userData?.currentLocation?.state}
            </p>{" "}
            | <p>Reviews {userData?.ratings?.totalRatings}</p>
          </div>
          <p className="text-xl mt-3">Hiring Timeline</p>
          <div
            className={classNames(
              { "gap-4": jobDetails?.contractType == "Temporary Position" },
              "flex justify-between my-3"
            )}
          >
            <div>
              <DatePicker
                selected={jobOfferDetails?.startDate}
                onChange={(e) => {
                  setJobOfferDetails((prev) => ({
                    ...prev,
                    startDate: e,
                  }));
                }}
                minDate={new Date()}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                className="ssu2_modal3_select pl-[20px]"
                placeholderText="Start Date"
                preventOpenOnFocus={true}
              />
            </div>
            <div>
              {jobDetails?.contractType == "Temporary Position" && (
                <DatePicker
                  selected={jobOfferDetails?.endDate}
                  onChange={(e) => {
                    setJobOfferDetails((prev) => ({
                      ...prev,
                      endDate: e,
                    }));
                  }}
                  showYearDropdown
                  minDate={jobOfferDetails?.startDate || new Date()}
                  showMonthDropdown
                  dropdownMode="select"
                  className="ssu2_modal3_select pl-[20px]"
                  placeholderText="End Date"
                  preventOpenOnFocus={true}
                />
              )}
            </div>
          </div>
          <div className="flex gap-4 mt-7">
            <Button
              onClick={() => setJobOfferModal(false)}
              className="px-7  border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
            >
              Cancel
            </Button>
            <Button
              className="px-7 rounded-full"
              loading={loader || loading}
              onClick={() => {
                sendJobOffer();
              }}
            >
              Confirm hiring
            </Button>
          </div>
        </section>
      </Modal>
    </Modal>
  );
}

export default ApplicantData;
