import React, { useState, useEffect } from "react";

function Confirm(props) {
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    setDisplay(props.display);
  }, [props.display]);

  return (
    <>
      <div className="w3-modal" style={{ display }}>
        <div
          className="w3-modal-content ssu2_modal1"
          style={{ maxHeight: 600, overflowY: "auto" }}
        >
          <div className="w3-container">
            <div className="ssu2_modal1_text1">
              <h2>{props.title}</h2>
            </div>
            <hr className="t2_sp_hr" style={{ margin: "30px 0px 0px" }} />
            <h4 style={{ textAlign: "center", marginTop: 35 }}>
              {props.content}
            </h4>
            <hr className="t2_sp_hr" style={{ margin: "40px 0px 0px" }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Confirm;
