import React, { useState, useEffect } from "react";
import logo from "../../assets/images/loginLogo.png";
import Loader from "../../modals/Loader";
import { Link } from "react-router-dom";
import "./logIn.css";
import { useDispatch } from "react-redux";

const SignUp = ({ setCurPos, history }) => {
  const dispatch = useDispatch();
  const [navTo, setNavTo] = useState(false);
  const [hospitalSelected, setHospitalSelected] = useState(false);
  const [staffSelected, setStaffSelected] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [setCurPos]);

  const selectHospital = () => {
    window.localStorage.removeItem("first_name");
    window.localStorage.removeItem("last_name");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("phone");
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("repassword");
    window.localStorage.removeItem("role");
    window.localStorage.removeItem("ein");
    window.localStorage.removeItem("institution_name");
    window.localStorage.removeItem("institution_size");
    window.localStorage.removeItem("institution_website");
    window.localStorage.removeItem("zip_code");
    window.localStorage.removeItem("hoscity");
    window.localStorage.removeItem("hosstate");
    window.localStorage.removeItem("hosstreet_address");
    setHospitalSelected(true);
    setStaffSelected(false);
    dispatch({ type: "RESET_STEPS" });
    setNavTo("/staffSignUp");
  };

  const selectStaff = () => {
    window.localStorage.removeItem("first_name");
    window.localStorage.removeItem("last_name");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("phone");
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("repassword");
    window.localStorage.removeItem("role");
    window.localStorage.removeItem("ein");
    window.localStorage.removeItem("institution_name");
    window.localStorage.removeItem("institution_size");
    window.localStorage.removeItem("institution_website");
    window.localStorage.removeItem("zip_code");
    window.localStorage.removeItem("hoscity");
    window.localStorage.removeItem("hosstate");
    window.localStorage.removeItem("hosstreet_address");
    setHospitalSelected(false);
    setStaffSelected(true);
    dispatch({ type: "RESET_STEPS" });
    setNavTo("/hospSignUp_1");
  };

  const navToPage = () => {
    if (navTo) {
      setShowLoader(true);
      setTimeout(() => {
        history.push(navTo);
        setShowLoader(false);
      }, 1000);
    }
  };

  return (
    <div
      className="d-flex h-screen overflow-hidden"
      style={{ backgroundColor: "#73C7A9", height: "100vh" }}
    >
      <div
        className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
        style={{ padding: "0", maxWidth: 720 }}
      >
        <div className="ssu_container p-5 max-h-[90vh] h-auto">
          <p className="flex items-center justify-center">
            <img alt="img" src={logo} className="logInLogo" />
          </p>
          <div className="logIn_txt1 font-semibold text-2xl mt-4">
            {" "}
            Sign up{" "}
          </div>
          <div className="logIn_txt2 mt-1 text-base">
            {" "}
            What are you looking for on LinkHealthStaff?{" "}
          </div>
          <div className="logIn_body mt-3 w-auto">
            <div className="row gap-3 signUp_item_container m-auto mt-4">
              <div
                className={
                  hospitalSelected
                    ? "col signUp_item signUp_item_selected cursor-pointer"
                    : "col signUp_item cursor-pointer"
                }
                onClick={selectHospital}
              >
                <div className="signUp_item_img">{/* Hospital SVG */}</div>
                <div className="signUp_item_txt text-base">
                  I am looking for a Job <br /> in Healthcare <br />
                  Institution
                </div>
              </div>
              <div
                className={
                  staffSelected
                    ? "col signUp_item signUp_item_selected cursor-pointer"
                    : "col signUp_item cursor-pointer"
                }
                onClick={selectStaff}
              >
                <div className="signUp_item_img">{/* Staff SVG */}</div>
                <div className="signUp_item_txt text-base">
                  I am looking for Staff for
                  <br />
                  my Healthcare Institution
                </div>
              </div>
            </div>
            <div
              className={
                !navTo ? "logIn_button logIn_button_disabled" : "logIn_button"
              }
              onClick={navToPage}
            >
              {showLoader ? <Loader /> : "CONTINUE"}
            </div>

            <Link
              className="logIn_button signUp_button mt-3 hover:!text-[#73C7A9] "
              to={"/logIn"}
            >
              {" "}
              LOG IN
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
