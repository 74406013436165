import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SubHeader from "./components/subHeader";
// import img from "../../assets/images/4-4.png";
import img from "../../assets/images/4-4.svg.svg";
import logo from "../../assets/images/Logo.png";
import "./staffSignUp_4.css";
import { callApi, decrementStep } from "../../../redux/action/action";
import { axiosInstance } from "../../../config/axiosConfig";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import Loader from "../../modals/Loader";
import { useHistory } from "react-router-dom";
import useLoader from "../../modals/hooks/useLoader";
import { HeaderComp } from "./headerComp";
const StaffSignUp_4 = (props) => {
  const [agreement, setAgreement] = useState(false);
  const [showAlert, setShowAlert] = useState("none");
  const [showPending, setShowPending] = useState("none");
  const [agreementData, setAgreementData] = useState("");
  const [userName, setUserName] = useState("olivia");
  const [mm, setMm] = useState("");
  const { showLoader, hideLoader } = useLoader();
  const [yy, setYy] = useState("");
  const [dd, setDd] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [locations, setLocations] = useState("");
  const [after_date, setAfterDate] = useState("th");
  const [showLoaderCustom, setShowLoaderCustom] = useState(false);
  const history = useHistory();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    var hosFirstname = window.localStorage.getItem("fullName");
    var hosZipcode = window.localStorage.getItem("zip_code");
    var hosCity = window.localStorage.getItem("hoscity");
    var hosState = window.localStorage.getItem("hosstate");
    var hosAddress = window.localStorage.getItem("hosstreet_address");

    window.scrollTo({ top: 0, behavior: "smooth" });
    var date = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var dat = date.getDate();
    var after_date = "th";
    if (dat === 1 || dat === 21 || dat === 31) {
      after_date = "st";
    } else if (dat === 2 || dat === 22) {
      after_date = "nd";
    }
    setAfterDate(after_date);
    var mm = date.getMonth() + 1;
    var currentMonth = months[mm - 1];
    var yy = date.getFullYear();
    var dd = dat < 10 ? "0" + dat : dat;
    mm = mm < 10 ? "0" + mm : mm;

    setMm(mm);
    setYy(yy);
    setDd(dd);
    setMonth(currentMonth);
    setDate(dat);
    var loc = `${hosAddress} street, ${hosCity}, ${hosState}, ${hosZipcode}`;
    setLocations(loc);
    setUserName(hosFirstname);
  };

  const handleBack = () => {
    decrementStep();
  };

  const saveAgreementFunc = async (name) => {
    try {
      showLoader();
      const date = new Date();
      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "long" });
      const year = date.getFullYear();
      const fullDate = date.toLocaleDateString("en-US");

      let daySuffix;
      if (day === 1 || day === 21 || day === 31) {
        daySuffix = "st";
      } else if (day === 2 || day === 22) {
        daySuffix = "nd";
      } else if (day === 3 || day === 23) {
        daySuffix = "rd";
      } else {
        daySuffix = "th";
      }

      const AgreementData = await axiosInstance.post(
        "v1/LHS/agreement/save",
        data
      );
      getAgreementFunc();
    } catch (error) {
      console.error("Error saving agreement data:", error);
    } finally {
      hideLoader();
    }
  };

  const getAgreementFunc = async () => {
    try {
      const getAgreementData = await axiosInstance.get("v1/LHS/agreement/get");
      console.log("hii", getAgreementData);
      setAgreementData(getAgreementData?.data?.data[0]?.agreement);
    } catch (error) {
      console.error("Error fetching agreement data:", error);
    } finally {
      hideLoader();
    }
  };

  const finishSignUp = async () => {
    if (showLoaderCustom) return;
    if (!agreement) {
      setShowAlert(true);
      return;
    }

    const formDataCopy = { ...props?.formData };
    const nursingCopy = { ...formDataCopy?.nursing };
    const contactDetailsCopy = { ...formDataCopy?.contactDetails };

    const file = contactDetailsCopy?.file;
    setShowLoaderCustom(true);

    if (file) {
      try {
        const formData = new FormData();
        formData.append("files", file);
        let url = "v2/LHS/Upload/uploadFiles";
        const response = await axiosInstance.post(url, formData);
        contactDetailsCopy.file = response.data.data[0];
      } catch (err) {}
    }

    for (let i = 0; i < nursingCopy?.nursingLicense.length; i++) {
      const image = nursingCopy?.nursingLicense[i]?.image;

      if (image) {
        try {
          const blob = await (await fetch(image)).blob();
          const formData = new FormData();
          formData.append("files", blob, "image.jpg");
          let url = "v2/LHS/Upload/uploadFiles";
          const response = await axiosInstance.post(url, formData);
          nursingCopy.nursingLicense[i].image = response.data.data[0];
        } catch (err) {
          // Handle error if needed
        }
      }
    }

    const postData = {
      socialSecurityNumber: contactDetailsCopy?.ssn,
      name: contactDetailsCopy?.fullName,
      dob: {
        day: contactDetailsCopy?.dob?.split("-")?.[2],
        month: contactDetailsCopy?.dob?.split("-")?.[1],
        year: contactDetailsCopy?.dob?.split("-")?.[0],
      },
      email: contactDetailsCopy?.email,
      phone: contactDetailsCopy?.phone,
      password: contactDetailsCopy?.psw,
      jobTitle: contactDetailsCopy?.jobTitle,
      resume: {
        link: contactDetailsCopy?.file,
        lastUpdated: "",
      },
      currentLocation: {
        name: props?.formData?.currentLocation?.name,
        state: props?.formData?.currentLocation?.state,
        zipCode: props?.formData?.currentLocation?.zipCode,
      },
      ...nursingCopy,
      agreement: { text: agreementData },
    };

    try {
      var res = await axiosInstance.post("v1/LHS/staff/signup", postData);
      if (res.status == 200) {
        showSuccessToast(res?.data?.Message || "Staff Created Successfully");
        props.onFormSubmit({ clearAllData: "" });
        history.push("/login");
      } else {
        showErrorToast(res?.data?.Message);
        //   toast.error("API request failed. Please check your input data.");
        console.error("API request failed with status:", res.status);
      }
    } catch (error) {
      showErrorToast(error.Message);
      console.error("API request failed with error:", error);
    } finally {
      setShowLoaderCustom(false);
    }
  };

  useEffect(() => {
    saveAgreementFunc();
  }, [props?.formData]);

  return (
    <div
      className="d-flex h-screen overflow-hidden"
      style={{ backgroundColor: "#73C7A9" }}
    >
      <div
        className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
        style={{ padding: "0", maxWidth: 720, width: "100%" }}
      >
        <div className="ssu_container py-4 px-5 max-h-[90vh] h-auto">
          <HeaderComp />
          <SubHeader
            num="4"
            title="Agreement"
            next="/staffSignUp_3"
            prev="/staffSignUp_1"
            img={img}
            onBack={handleBack}
          />
          <div className="ssu_body mt-3 w-auto">
            <div className="ssu_txt1 my-4">
              <div className="hsu1_txt1 text-2xl">
                Congrats, {props?.formData?.contactDetails?.fullName}, you're on
                the last step!
              </div>
            </div>
            <div className="ssu_txt1 text-lg mt-3 mb-0">
              Please go through to the agreement and provide your consent
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="ssu4_text3 !text-base">
              This Agreement (the “Agreement”) entered into this{" "}
              {date + after_date} day of {month}, {yy} (“Effective Date – {mm}/
              {dd}/{yy} ”), by and between LINKHEALTHSTAFF LLC (“LHS”), a Nevada
              registered corporation and{" "}
              {props?.formData?.contactDetails?.fullName} (hereinafter referred
              to as “MEDICAL STAFF/PERSONNEL”). WHEREAS, LHS operates as an
              on-demand healthcare staffing platform that facilitates meeting
              and hiring of healthcare personnel to provide services to MEDICAL
              FACILITIES. WHEREAS, LHS Platform desires to facilitate
              acquisition services of a Medical Professional in accordance with
              the terms and conditions of the MEDICAL FACILITY job posting. NOW
              THEREFORE, in consideration of the mutual promises and covenants
              between LHS and HEALTHCARE PERSONNEL, (jointly hereinafter
              referred to as “Party/Parties”), the Parties hereby agree: The
              Medical Professional is licensed and duly qualified to perform the
              services expected within the disciplines and job description in
              the State where healthcare services are being requested. The term
              of this Agreement is for a period of one (1) year from and
              automatically renewed every year thereafter unless terminated by
              either party. This Agreement may be terminated by either party at
              any time.
            </div>
            <div className="checkbox-container mt-4">
              <div
                style={{
                  width: "100%",
                  maxWidth: "730px",
                  margin: "0px auto",
                  display: "flex",
                }}
              >
                <input
                  type="checkbox"
                  className="first"
                  id="first"
                  onChange={(e) => {
                    setAgreement(e.target.checked);
                    if (e.target.checked) {
                      setShowAlert("none");
                    }
                  }}
                />
                <label htmlFor="first"></label>
                <b className="text-base" style={{ fontWeight: "normal" }}>
                  I provide my consent to Medical Staff Agreement
                </b>
              </div>
              <div
                style={{
                  display: showAlert,
                  color: "red",
                  marginTop: "10px",
                  fontSize: "15px",
                }}
              >
                Please provide your consent to the Medical Staff Agreement.
              </div>
            </div>
          </div>
          <hr style={{ margin: "30px 0px 0px" }} />
          <div className="ssu_bottom">
            <div className="ssu_button" onClick={finishSignUp}>
              {showLoaderCustom ? <Loader /> : "CONTINUE"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffSignUp_4;
