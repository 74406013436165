import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency, getParkingFee } from "./helper";
import CreditCardBox from "./CreditCardBox";
import useLoader from "../../../../../modals/hooks/useLoader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../common/toastMeassage/ToastMeassage";
import { axiosInstance } from "../../../../../../config/axiosConfig";

export default function CheckoutForm({ onSubmit, setCreateNew }) {
  const stripe = useStripe();
  const elements = useElements();
  const { showLoader, hideLoader } = useLoader();
  const [isSaveCard, setIsSaveCard] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [cardholderName, setCardholderName] = React.useState("");
  const [cards, setCards] = React.useState([]);
  const [showAddCard, setShowAddCard] = React.useState(true);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const locationDetails = useSelector(
    (state) => state?.checkout?.activeLocation
  );
  const { creditCardFee } = locationDetails || {};

  const fetchCards = async () => {
    try {
      showLoader();
      let url = "v2/LHS/User/getCard";
      const res = await axiosInstance.get(url);
      const { data } = res?.data || {};
      if (data) {
        setCards(data);
        setShowAddCard(data?.length === 0);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const handlePayment = async () => {
    try {
      if (selectedCard) {
        onSubmit({ selectedCard: { card: selectedCard } });
        return;
      }
      const cardElement = elements?.getElement(CardNumberElement);
      const resStripe = await stripe?.createToken(cardElement, {
        name: cardholderName,
      });
      const { error, token } = resStripe || {};
      if (error) {
        const { message, code } = error;
        setErrors(error);
        // console.log('payment error:>', error);
        if (
          code !== "invalid_number" &&
          code !== "incomplete_number" &&
          code !== "incomplete_cvc" &&
          code !== "invalid_expiry_month_past" &&
          code !== "invalid_expiry_year_past"
        ) {
          showErrorToast(message);
        }
        return;
      }
      setErrors(null);
      onSubmit({ token, isSaveCard });
    } catch (err) {
      const { message } = err || {};
      showErrorToast("Please select or add card");
    }
  };

  return (
    <>
      <div className="flex justify-between mb-4">
        <h4 className="text-[30px] font-medium text-gray-900">Payment info</h4>
        {/* <div className="text-center">
          <button
            className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5 cursor-pointer"
            onClick={() => setCreateNew("tab_1")}
          >
            Back
          </button>
          <button
            onClick={handlePayment}
            className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5 cursor-pointer"
          >
            Pay now
          </button>
        </div> */}
      </div>

      {cards?.length > 0 && (
        <div className="mb-10">
          <p className="mb-3 text-[18px] font-medium">Your saved cards</p>
          <div className="flex flex-col space-y-5 md:flex-row md:space-x-5 md:space-y-0">
            {cards?.map((item, index) => {
              return (
                <CreditCardBox
                  item={item}
                  key={index}
                  selectedCard={selectedCard}
                  onSelect={() => {
                    setShowAddCard(false);
                    setSelectedCard(item);
                  }}
                />
              );
            })}
          </div>

          <button
            onClick={() => {
              setSelectedCard(null);
              setShowAddCard(true);
            }}
            className="mt-5 flex h-[65px] w-full items-center justify-center space-x-4 rounded-full border border-gray-300 text-gray-900"
          >
            {/* <FaPlus size={20} /> */}+ <span>Add new card</span>
          </button>
        </div>
      )}

      {showAddCard && (
        <div className="flex flex-col space-y-3">
          <div>
            <label className="mb-1.5 block text-sm font-medium text-gray-700">
              Card number
            </label>
            <CardNumberElement
              className="rounded-full border border-gray-300 p-3"
              options={{
                showIcon: true,
              }}
              onChange={() => setErrors(null)}
            />
            {errors &&
              (errors.code === "incomplete_number" ||
                errors.code === "invalid_number") && (
                <p className="mt-0.5 text-xs text-red-500">{errors.message}</p>
              )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1">
              <label className="mb-1.5 block text-sm font-medium text-gray-700">
                Expiration date
              </label>
              <CardExpiryElement
                className="rounded-full border border-gray-300 p-3"
                onChange={() => setErrors(null)}
              />
              {errors &&
                (errors.code === "invalid_expiry_year_past" ||
                  errors.code === "invalid_expiry_month_past") && (
                  <p className="mt-0.5 text-xs text-red-500">
                    {errors.message}
                  </p>
                )}
            </div>
            <div className="col-span-1">
              <label className="mb-1.5 block text-sm font-medium text-gray-700">
                CVC/CVV
              </label>
              <CardCvcElement
                className="rounded-full border border-gray-300 p-3"
                onChange={() => setErrors(null)}
              />
              {errors && errors.code === "incomplete_cvc" && (
                <p className="mt-0.5 text-xs text-red-500">{errors.message}</p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="col-span-1">
              <label className="mb-1.5 block text-sm font-medium text-gray-700">
                Name on card
              </label>
              <input
                type="text"
                className="rounded-full w-full border border-gray-300 p-3"
                value={cardholderName}
                placeholder="Name on card"
                onChange={(e) => setCardholderName(e.target.value)}
              />
              {errors && errors.code === "invalid_name" && (
                <p className="mt-0.5 text-xs text-red-500">{errors.message}</p>
              )}
            </div>
          </div>

          <div>
            <label className="flex items-center text-sm text-gray-700 ">
              <input
                type="checkbox"
                className="mr-2 customone"
                checked={isSaveCard}
                onChange={() => setIsSaveCard(!isSaveCard)}
              />
              Save card for future payments
            </label>
          </div>
        </div>
      )}

      <div className="mt-4 bg-[#F2F2F2] px-4 py-3">
        <table className="w-full font-medium text-gray-700">
          <tbody>
            <tr>
              <td>Fee</td>
              <td className="text-right">{"$29.99"}</td>
            </tr>
            {/* <tr>
              <td>Credit Card Fee ({creditCardFee}%)</td>
              <td className="text-right">
                {formatCurrency(creditCardFeeAmount)}
              </td>
            </tr> */}
            <tr>
              <td colSpan={2} className="my-2">
                <div className="horizontal-line no-margin"></div>
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td className="text-right">{"$29.99"}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="horizontal-line"></div>
      <div className="flex justify-end mt-3">
        <button
          className="text-base border-2 border-[#73C7A9] rounded-full w-32 h-11 ml-5 cursor-pointer"
          onClick={() => setCreateNew("tab_1")}
        >
          Back
        </button>
        <button
          onClick={handlePayment}
          className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5 cursor-pointer"
        >
          Pay now
        </button>
      </div>
    </>
  );
}
