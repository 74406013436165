import React, { useEffect, useRef, useState } from "react";
import dots from "../assets/images/dots.png";
import hideEye from "../assets/images/hide.png";
import like_true from "../assets/images/like-true.svg";
import like_true2 from "../assets/images/like_true2.svg";
import trans from "../assets/images/trans.svg";
// import comment from "../assets/images/comment.png";
import comment from "../assets/images/comment.svg";
// import share from "../assets/images/share.png";
import share from "../assets/images/share.svg";
import { useSelector } from "react-redux";
import edit from "../assets/images/edit.png";
import user from "../assets/images/user.png";
import deletebox from "../assets/images/deletebox.png";
import Modal from "../common/modal";
import "../../components/theme_1/global.css";
import "../theme_1/main/hospital";
import { useHistory } from "react-router-dom";
import LightboxComponent from "./Lightbox";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../config/axiosConfig";
import {
  showErrorToast,
  showSuccessToast,
} from "../common/toastMeassage/ToastMeassage";
import like from "../assets/images/like.png";
import likedTrue from "../assets/images/likedTrue.png";
import Loader from "../modals/Loader";
import io from "socket.io-client";
import CommentsList from "./CommentsList";
import calculateTime from "../modals/calculateTime";
import "./staffCard";
import { likeCommentCountService } from "../../sockets/socketService";
import LoginModal from "../containers/commonComponent/LoginModal";

export default function StaffCard(props) {
  const {
    handleidData,
    setUpdateComment,
    handlePost,
    setHidePost,
    hidePost,
    image,
    _id,
    handleSubmit,
    myProfile,
    likeCount,
    isLiked,
    userId,
  } = props || {};

  const history = useHistory();
  const [selectIndex, setSelectIndex] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentCount, setCommentCount] = useState(0);
  const [getAllComments, setGetAllComments] = useState(true);
  const [updatedComments, setUpdatedComments] = useState(true);
  const [isButtonHidden, setIsButtonHidden] = useState(true);
  const token = localStorage.getItem("type");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [readMore, setReadMore] = useState(false);
  const [commentIds, setCommentids] = useState(null);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [hide, setHide] = useState(false);
  const [count, setCount] = useState(0);
  const [likeState, setLikeState] = useState(0);
  const profile = useSelector((state) => state.data.profile);

  useEffect(() => {
    setCommentCount(props.commentsCount);
    setCount(props.likeCount || 0);
    setLikeState(props.isLiked);
  }, [props.commentsCount, props.likeCount, props.isLiked]);

  useEffect(() => {
    likeCommentCountService.onLikeDislike((data) => {
      if (data.statusCode === 200 && data.data?.id == _id) {
        setUpdatedComments(data?.data);
        setCount(data.data.like);
        setCommentCount(data.data.comment?.length);
      }
    });
  }, [_id]);
  const isMyPost = userId && myProfile && userId?._id === myProfile?._id;
  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
    setIsButtonHidden(true);
  };
  const openIsModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setError(false);
  };
  const editCommentPopup = useRef();
  const editPostPopup = useRef();
  const updateReplyPopup = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editPostPopup.current &&
        !editPostPopup.current.contains(event.target)
      ) {
      }
      if (
        editCommentPopup?.current &&
        !editCommentPopup.current.contains(event.target)
      ) {
      }

      if (
        updateReplyPopup?.current &&
        !updateReplyPopup.current.contains(event.target)
      ) {
        // setUpdateReplyId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const [loadHidePost, setHidePostData] = useState(false);
  const handleHidePost = async () => {
    setHidePostData(true);
    let url = `v2/LHS/User/hidePost`;
    axiosInstance
      .post(url, { postId: props?._id })
      .then(() => {
        setHidePost(false);
        setHidePostData(false);
        setHide(true);
        showSuccessToast("Post hidden successfully");
      })
      .catch(() => {
        setHidePostData(false);
        showErrorToast("Error adding post hide!");
      });
  };

  const handleLikePost = () => {
    if (!profile) {
      setLoginModal(true);
      return;
    }
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    let url = `/v2/LHS/User/likeDislikePost`;
    axiosInstance
      .post(url, { postId: _id })
      .then((res) => {
        if (_id == res?.data?.data?.id) {
          setLikeState(res?.data?.data?.isLike);
        }
        showSuccessToast(res?.data?.message);
      })
      .catch(() => {
        showErrorToast("Something went wrong!");
      })
      .finally(() => {
        setButtonClicked(false);
      });
  };

  const renderImage = (isLiked) => {
    if (isLiked ?? likeState) {
      return (
        <img
          src={like_true}
          alt="Liked"
          style={{ height: "19px", width: "20px", cursor: "pointer" }}
        />
      );
    } else {
      return (
        <img
          src={like_true2}
          alt="Like"
          style={{ height: "19px", width: "20px", cursor: "pointer" }}
        />
      );
    }
  };

  const isImage = (url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return ["png", "jpeg", "jpg", "svg", "avif", "webp"].includes(
      fileExtension
    );
  };
  const isvideo = (url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return ["mp4", "avi", "mkv", "mov", "webm"].includes(fileExtension);
  };

  const postImages = props?.fileUrl
    ?.filter((item) => isImage(item))
    .map((src) => {
      return { src: src, alt: "" };
    });
  const postVideos = props?.fileUrl?.filter((item) => isvideo(item));
  const userRole = props?.userId?.role?.split(/(?=[A-Z])/);
  const JoinRole = userRole?.join(" ");
  const toProfileList = (id) => {
    if (id?.userId?.role !== "superAdmin") {
      localStorage.setItem("postID", id?.userId?._id);
      history.push(`/userHospital?postID=${id?.userId?._id}`);
    }
  };
  const ClicktoUserData = (data) => {
    localStorage.setItem("ClickUser", JSON.stringify(data));
  };

  const renderTextWithLinks = (text) => {
    const parts = text?.split(/(https?:\/\/[^\s]+)/g);

    return parts.map((part, index) => {
      if (part.match(/https?:\/\/[^\s]+/)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer text-[#73C7A9]"
          >
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  const handlePostComment = async () => {
    setLoading(true);
    setGetAllComments(true);
    try {
      if (buttonClicked) {
        return;
      }
      setButtonClicked(true);
      const formData = {
        postId: _id,
        comment: commentText,
      };
      let url = "v2/LHS/User/addComment";
      const response = await axiosInstance.post(url, formData);
      setCommentText("");
      setIsButtonHidden(false);
      showSuccessToast(response.data.message);
      setShowCommentModal(true);
      setGetAllComments(props?._id);
      setLoading(false);
    } catch (error) {
      showErrorToast("Something went wrong");
      setLoading(false);
    } finally {
      setButtonClicked(false);
    }
  };

  const handleUpdateComment = async (commentIds) => {
    setLoading(true);
    setGetAllComments(true);
    try {
      if (buttonClicked) {
        return;
      }
      setButtonClicked(true);
      const url = `v2/LHS/User/updateComment/${commentIds}`;
      await axiosInstance.put(url, {
        comment: commentText,
      });
      setIsButtonHidden(false);
      setCommentText("");
      setCommentids(null);
      setUpdateComment("");
      showSuccessToast("Comment updated successfully!");
      setShowCommentModal(true);
      setGetAllComments(props?._id);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    } finally {
      setButtonClicked(false);
      setLoading(false);
    }
  };

  const handlePostLike = (props) => {
    history.push(`/LikedCommentList?post_id=${props?._id}`);
  };

  const fetchShareUrl = async () => {
    if (!profile) {
      setLoginModal(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axiosInstance.post(`v2/LHS/User/sharePost`, {
        postId: props?._id,
      });
      setShareLink(response?.data?.data);
      setShareModalOpen(true);
    } catch (error) {
      showErrorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {hide ? null : (
        <div
          id={_id}
          className="px-[22px] py-3 border border-gray-100 bg-white sm:w-full"
          style={{
            borderRadius: "10px",
          }}
          onClick={() => profile && handleidData(props)}
        >
          <div>
            <div className=" flex justify-between items-start">
              <div
                className=" flex items-center gap-[20px]"
                style={{ cursor: "pointer" }}
              >
                <div className="w-14 min-w-[3.5rem] h-14 rounded-full overflow-hidden">
                  <img
                    src={props?.userId?.image ? userId.image : user}
                    className="object-cover w-full h-full"
                    onClick={() => {
                      if (profile) {
                        toProfileList(props);
                        ClicktoUserData(props);
                      }
                    }}
                  />
                </div>

                <div className="flex flex-col gap-y-[6px]">
                  <h1
                    className="font-semibold text-[18px] leading-5"
                    onClick={() => {
                      if (profile) {
                        toProfileList(props);
                        ClicktoUserData(props);
                      }
                    }}
                  >
                    {props?.userId?.name
                      ? props?.userId?.name
                      : props?.userId?.firstName +
                        " " +
                        props?.userId?.lastName}
                  </h1>
                  <h1 className="text-gray-500 text-[15px]">
                    {props?.userId?.role == "hospital"
                      ? props?.userId?.hiringRole
                      : props?.userId?.role == "staff"
                      ? props?.userId?.jobTitle
                      : JoinRole}
                  </h1>
                  <h1 className="text-gray-500 text-[15px] tracking-[0.2px]">
                    {calculateTime(props?.updatedAt || props?.createdAt)}
                  </h1>
                </div>
              </div>
              {profile && (
                <div className="flex flex-col w-[10%]">
                  <div className="flex justify-end relative">
                    <img
                      src={dots}
                      className="w-4 h-1"
                      onClick={() => {
                        setHidePost((s) => !s);
                        setSelectIndex(props.indexData);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    {hidePost && (
                      <>
                        {selectIndex == props.indexData && (
                          <div
                            ref={editPostPopup}
                            className="absolute flex flex-wrap justify-center items-center z-10 shadow-sm bg-white border border-gray-100 mt-4 rounded-md px-2 py-2 w-[150px] max-h-24 ml-auto "
                          >
                            <div>
                              {(isMyPost || token == "superAdmin") && (
                                <div className="flex items-center">
                                  <img
                                    src={edit}
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      marginRight: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handlePost(props)}
                                  />
                                  <h2
                                    className="ml-2 text-sm cursor-pointer"
                                    onClick={() => handlePost(props)}
                                  >
                                    Edit post
                                  </h2>
                                </div>
                              )}
                              {(isMyPost || token == "superAdmin") && (
                                <div className="flex items-center mt-2">
                                  <img
                                    src={deletebox}
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      marginRight: "8px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <h2
                                    className="text-sm ml-2 cursor-pointer"
                                    onClick={openIsModal}
                                  >
                                    Delete post
                                  </h2>
                                </div>
                              )}
                            </div>
                            {token !== "superAdmin" && !isMyPost && (
                              <div className="flex items-center">
                                {loadHidePost ? (
                                  <Loader />
                                ) : (
                                  <>
                                    <img
                                      src={hideEye}
                                      style={{
                                        width: "20px",
                                        height: "15px",
                                        marginRight: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <h1
                                      className="text-base leading-5 cursor-pointer"
                                      onClick={handleHidePost}
                                    >
                                      Hide post
                                    </h1>
                                  </>
                                )}
                              </div>
                            )}
                            {(isMyPost || token == "superAdmin") && (
                              <Modal
                                isOpen={modalOpen}
                                onClose={closeModal}
                                className="md:w-1/3 mt-0"
                              >
                                <div>
                                  <h1 className="font-medium text-xl text-center mb-3">
                                    Delete post?
                                  </h1>
                                  <p className="text-base text-center m-auto mb-4">
                                    Are you sure you want to permanently remove
                                    this{" "}
                                    <span className="m-auto">
                                      post from LinkHealthStaff?
                                    </span>
                                  </p>

                                  <div className="text-center">
                                    <button
                                      onClick={() => setModalOpen(false)}
                                      className="text-base  border-1  !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white w-32 h-11 ml-5"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                                      onClick={() => {
                                        handleSubmit(props._id);
                                        setModalOpen(false);
                                        setHidePost(false);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </Modal>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {readMore ? (
              <div className="font-normal mt-4 text-container break-all text-[16px] w-full text-gray-500 leading-[24px] tracking-[0.2px] whitespace-pre-line">
                {props?.caption && renderTextWithLinks(props.caption)}
              </div>
            ) : (
              <div className="font-normal mt-4 text-container break-all text-[16px] w-full text-gray-500 leading-[24px] tracking-[0.2px] whitespace-pre-line">
                {props?.caption &&
                  renderTextWithLinks(props.caption?.slice(0, 150))}
                {props?.caption?.length > 150 && (
                  <>
                    <div
                      onClick={toggleReadMore}
                      className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
                    >
                      ...see more
                    </div>
                  </>
                )}
              </div>
            )}

            {props?.caption?.length > 100 && readMore && (
              <div
                onClick={toggleReadMore}
                className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
              >
                ...see less
              </div>
            )}

            {(postImages?.length > 0 || postVideos?.length > 0) && (
              <div className="mt-[18px] flex flex-wrap rounded-s gap-4 w-full justify-center">
                <LightboxComponent images={postImages} />
                {postVideos.map((item) => (
                  <video
                    controls
                    className="w-full h-80 max-h-72 rounded-2xl"
                    style={{ cursor: "pointer" }}
                  >
                    <source src={item} type="video/mp4" />
                  </video>
                ))}
              </div>
            )}

            <div>
              <div className="">
                <LightboxComponent images={image} />
                <div
                  className={`mt-[20px] flex items-center flex-col w-full  ${
                    count > 0 || commentCount > 0
                      ? "justify-between"
                      : "justify-end"
                  }`}
                >
                  {(props?.comments?.length > 0 ||
                    props?.count > 0 ||
                    count > 0 ||
                    commentCount > 0) && (
                    <div
                      className="flex w-full items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {(props?.count > 0 || count > 0) && (
                        <button
                          className="flex items-center gap-2"
                          onClick={() => profile && handlePostLike(props)}
                        >
                          <img
                            src={like_true}
                            style={{
                              height: "20px",
                              cursor: "pointer",
                              flexShrink: "0",
                              width: "20px",
                            }}
                          />
                          <h1 className="text-[14px] tracking-[0.2px] leading-[14px] text-gray-500">
                            {count}
                          </h1>
                        </button>
                      )}
                      {(props?.comments?.length > 0 || commentCount > 0) && (
                        <div
                          className="flex items-center justify-end text-sm tracking-[0.2px] w-full relative leading-[14px] text-gray-500"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowCommentModal(true)}
                        >
                          {profile && (
                            <>
                              <h1 className="mr-2">{commentCount}</h1>
                              <h1>Comments</h1>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {(props?.comments?.length > 0 ||
                    props?.count > 0 ||
                    count > 0 ||
                    commentCount > 0) && (
                    <hr className="text-gray-400 my-3 w-full" />
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between mb-4">
                <div
                  className="flex items-center cursor-pointer justify-center"
                  onClick={() => handleLikePost()}
                >
                  <div className="flex items-center text-sm justify-center gap-[10px] pt-[1px]">
                    {renderImage()}
                    <button className="text-[15px] text-[#333333] tracking-[0.2px] pt-[3px]">
                      Like
                    </button>
                  </div>
                </div>

                <div
                  className="flex items-center text-sm justify-center gap-[14px] pt-[1.5px]"
                  onClick={() => {
                    setShowCommentModal(false);
                  }}
                >
                  <img
                    src={comment}
                    style={{
                      height: "18px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <button className="cursor-pointer text-[15px] text-[#333333] tracking-[0.2px]">
                    Comments
                  </button>
                </div>
                <div
                  onClick={fetchShareUrl}
                  className="flex items-center text-sm justify-center gap-[12px]"
                >
                  <img
                    src={share}
                    style={{
                      height: "20px",
                      width: "19px",
                      cursor: "pointer",
                    }}
                  />
                  <button className="text-[15px] text-[#333333] tracking-[0.2px]">
                    Share
                  </button>
                </div>
              </div>
            </div>

            {/* {commentCount >= 0 && ( */}
            <>
              {/* {showCommentModal && (
                <div className="mb-4">
                  <div className="flex items-start gap-[15px]">
                    <div className="w-12 min-w-[48px] h-12 rounded-full overflow-hidden">
                      <img
                        src={profile?.image ? profile?.image : user}
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col gap-[15px] w-full">
                      <div className="border-gray-100 border rounded-lg justify-center w-full items-center">
                        <input
                          className="border-[#EAEAEA] appearance-none border rounded-[6px] w-full h-11 py-1 px-3 text-[16px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="inline-full-name"
                          type="text"
                          placeholder="Add a comment"
                          value={commentText}
                          onChange={handleCommentChange}
                        />
                      </div>
                      {commentText?.length > 0 ? (
                        <div className="mb-2 flex items-center gap-[15px]">
                          {isButtonHidden && (
                            <>
                              {" "}
                              <button
                                className="h-9 w-[150px] font-medium rounded-full bg-white border-[1.4px] text-[#9E9E9E] border-[#999999]"
                                onClick={() => {
                                  setCommentText("");
                                  setIsButtonHidden(false);
                                  setCommentids(null);
                                  setUpdateComment("");
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="h-9 rounded-full bg-[#73C7A9] text-[#FFFFFF] font-medium w-[150px]"
                                onClick={() => {
                                  // setLoading(true);
                                  if (commentIds) {
                                    handleUpdateComment(commentIds);
                                  } else {
                                    handlePostComment();
                                  }
                                  setIsButtonHidden(true);
                                  // setLoading(false);
                                }}
                                disabled={buttonClicked}
                              >
                                {loading == true ? (
                                  <Loader />
                                ) : commentIds ? (
                                  "Update comment"
                                ) : (
                                  "Post"
                                )}
                              </button>
                            </>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )} */}

              {profile && (
                <div className="mb-4">
                  <div className="flex items-start gap-[15px]">
                    <div className="w-12 min-w-[48px] h-12 rounded-full overflow-hidden">
                      <img
                        src={profile?.image ? profile?.image : user}
                        className="object-cover w-full h-full"
                      />
                    </div>

                    <div className="flex flex-col gap-[15px] w-full">
                      <div className="border-gray-100 border rounded-lg justify-center w-full items-center">
                        <input
                          className="border-[#EAEAEA] appearance-none border rounded-[6px] w-full h-11 py-1 px-3 text-[16px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="inline-full-name"
                          type="text"
                          placeholder="Add a comment"
                          value={commentText}
                          onChange={handleCommentChange}
                        />
                      </div>
                      {commentText?.length > 0 ? (
                        <div className="mb-2 flex items-center gap-[15px]">
                          {isButtonHidden && (
                            <>
                              {" "}
                              <button
                                className="h-9 w-[150px] font-medium border-1  border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
                                onClick={() => {
                                  setCommentText("");
                                  setIsButtonHidden(false);
                                  setCommentids(null);
                                  setUpdateComment("");
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="h-9 rounded-full bg-[#73C7A9] text-[#FFFFFF] font-medium w-[150px]"
                                onClick={() => {
                                  // setLoading(true);
                                  if (commentIds) {
                                    handleUpdateComment(commentIds);
                                  } else {
                                    handlePostComment();
                                  }
                                  setIsButtonHidden(true);
                                  // setLoading(false);
                                }}
                                disabled={buttonClicked}
                              >
                                {loading == true ? (
                                  <Loader />
                                ) : commentIds ? (
                                  "Update comment"
                                ) : (
                                  "Post"
                                )}

                                {/* { commentIds ? "Update comment" : "Post"} */}
                              </button>
                            </>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div>
                <CommentsList
                  props={props}
                  setCommentCount={(e) => setCommentCount(e)}
                  commentCount={commentCount}
                  showCommentModal={showCommentModal}
                  updatedComments={updatedComments}
                  getAllComments={getAllComments}
                  toProfileList={toProfileList}
                  ClicktoUserData={ClicktoUserData}
                  setCommentids={setCommentids}
                  setCommentText={setCommentText}
                  updateReplyPopup={updateReplyPopup}
                  setIsButtonHidden={setIsButtonHidden}
                  editCommentPopup={editCommentPopup}
                  setShowCommentModal={(val) => setShowCommentModal(val)}
                />
              </div>
            </>
            {/* )} */}
          </div>
          <Modal
            className="md:w-1/2 mt-0"
            isOpen={shareModalOpen}
            onClose={() => {
              setShareModalOpen(false);
              setShareLink("");
            }}
          >
            <div className="flex flex-col gap-3">
              <p className="text-lg">Share {props?.userId?.name}</p>
              <input
                value={shareLink}
                className="p-[10px] border border-1 border-[#EAEAEA] rounded-md text-sm"
              />
              <div className="flex gap-3 justify-end">
                <button
                  className="border-1 text-sm text-[#73C7A9] leading-5  border-1 border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:text-white hover:border-none"
                  onClick={() => {
                    setShareModalOpen(false);
                    setShareLink("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`border-1 text-sm text-white leading-5 bg-[#73C7A9] border-[#73C7A9] sm:rounded-[30px] rounded-[10px] sm:py-[8px] sm:px-[25px] py-[3px] px-[6px] hover:bg-[#73C7A9] hover:text-white hover:border-none`}
                  onClick={() => {
                    navigator.clipboard.writeText(shareLink);
                    showSuccessToast("Link copied!");
                    setShareModalOpen(false);
                  }}
                >
                  Copy link
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <LoginModal
        openModal={loginModal}
        closeModal={() => setLoginModal(false)}
      />
    </>
  );
}
