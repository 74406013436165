import { Button } from "react-bootstrap";
import React from "react";
import starIcon from "../../../components/assets/images/star_icon.svg";
import avatar from "../../../components/assets/images/avatar.png";
import "./staff_components.css";
import Modal from "../../common/modal";
import Loader from "../../modals/Loader";
import { useSelector } from "react-redux";

const RateReviewModal = (props) => {
  const profile = useSelector((state) => state?.data?.profile);
  return (
    <Modal {...props}>
      <div className="flex justify-between">
        {profile?.role === "hospital" ? (
          <p className="mb-0 rate_reviw_modal_heading">
            {props?.data?.name ? props?.data?.name : ""}
          </p>
        ) : (
          <p className="mb-0 rate_reviw_modal_heading">
            {props?.reviewData?.length && props?.reviewData[0]?.hospital
              ? props?.reviewData[0]?.hospital?.healthCareInstitution?.name
              : ""}
          </p>
        )}

        <p className="flex gap-3">
          <div className="flex gap-1">
            <img
              src={starIcon}
              alt="badge.png"
              className="me-2"
              width="20px"
              height="20px"
            />
            <p className="self-center">
              {props?.data?.ratings
                ? props?.data?.ratings?.ratingSum?.toFixed(1)
                : props?.data?.hospital?.ratings?.ratingSum?.toFixed(1)}
            </p>
          </div>
          <span className="ms-4 self-center">
            {props?.data?.ratings
              ? props?.data?.ratings?.totalRatings
              : props?.data?.hospital?.ratings?.totalRatings}{" "}
            Review
          </span>
        </p>
      </div>

      <div className="m-0 mt-lg-4 mt-3">
        {/* {props?.loader && <Loader />} */}
        {(props?.reviewData || [])?.map((v, i) => {
          return (
            <div className="p-4 mt-3 rate_reviw_card" key={i}>
              <div className="flex justify-between">
                <div className="flex gap-3">
                  {profile?.role === "hospital" ? (
                    <div className="w-10 h-10 rounded-full overflow-hidden">
                      <img
                        alt="avatar.png"
                        src={v?.hospital?.image || avatar}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className="w-10 h-10 rounded-full overflow-hidden">
                      <img
                        alt="avatar.png"
                        src={v?.staff?.image || avatar}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  )}

                  {profile?.role === "hospital" ? (
                    <p className="mb-0 username modal_username">
                      {v?.hospital?.healthCareInstitution?.name}
                    </p>
                  ) : (
                    <p className="mb-0 username modal_username">
                      {v?.staff?.name}
                    </p>
                  )}
                </div>

                <div className="flex gap-3">
                  <img
                    src={starIcon}
                    alt="badge.png"
                    width="18px"
                    height="18px"
                  />
                  {/* <p className="self-center">
                    {profile?.role === "hospital"
                      ? v?.hospital?.ratings.ratingSum
                      : v?.staff?.ratings.ratingSum}
                  </p> */}
                  <p className="self-center">{v?.rating}</p>
                </div>
              </div>

              <div className="m-0 rate_reviw_card_comment mt-3">
                {v?.review}
              </div>
            </div>
          );
        })}
      </div>

      <div className="m-0 justify-content-center align-items-center rate_reviw_card_footer mt-lg-5 mt-4">
        <div className="p-0 d-flex justify-content-center align-items-center">
          <Button
            className=" border-1 !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white "
            variant=""
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RateReviewModal;
