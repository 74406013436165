import React, { useEffect, useState } from "react";
import topImg from "../../assets/images/topImg.png";
import leftArrow from "../../assets/images/leftArrow.png";
import user from "../../assets/images/user.png";
import Nav from "../Nav";
import { axiosInstance } from "../../../config/axiosConfig";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showSuccessToast } from "../../common/toastMeassage/ToastMeassage";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import RightSideBar from "../../containers/commonComponent/rightSideBar";
import RecommendedForYou from "../../containers/commonComponent/RecommendedForYou";
import Loader from "../../modals/Loader";

const LikedCommentList = (props) => {
  const { location } = props || {};
  const commentId = location?.state?.commentId || "";
  const userRole = props?.userId?.role.split(/(?=[A-Z])/);
  const JoinRole = userRole?.join(" ");
  const history = useHistory();
  const profile = useSelector((state) => state.data.profile);
  const idLike = location?.search?.split("=")[1];
  const urlpostComment = location?.search?.split("=")[0];

  const jobData = [
    {
      id: "0",
      Value: "Primary Care Doctor",
    },
    {
      id: "1",
      value: "Urgent Care",
    },
    {
      id: "2",
      value: "Dermatologist",
    },
    {
      id: "3",
      value: " OB-GYN",
    },
    {
      id: "4",
      value: "Dentist",
    },
    {
      id: "5",
      value: "Psychiatrist",
    },
    {
      id: "6",
      value: "Ear, Nose & Throat Doctor",
    },
    {
      id: "7",
      value: "Gastroenterologist",
    },
    {
      id: "8",
      value: "Therapist Counselor",
    },
    {
      id: "9",
      value: " Physical Therapist",
    },
  ];

  const [viewAll, setViewAll] = useState(false);
  const currentJobData = viewAll ? jobData : jobData.slice(0, 5);
  const [likedCommentList, setLikedCommentList] = useState([]);
  const [likeCount, setLikeCount] = useState();
  const [loader, setLoader] = useState(false);

  const GetLikedCommentList = async () => {
    setLoader(true);
    try {
      let url = `/v2/LHS/User/getAllLikesByCommentId/${idLike}?page=${1}&limit=${10}`;
      const response = await axiosInstance.get(url);
      setLikedCommentList(response?.data.data.likeData);
      setLikeCount(response?.data.data.likeCount);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching interest jobs:", error);
      setLoader(false);
    }
  };

  const getAllLikeByPostId = async () => {
    setLoader(true);
    let url = `v2/LHS/User/getAllLikesByPostId/${idLike}?page=1&limit=10`;
    const response1 = await axiosInstance
      .get(url)
      .then((response1) => {
        const data = response1?.data;
        setLikedCommentList(data.data.likeData);
        setLikeCount(data.data.likeCount);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (urlpostComment === "?post_id") {
      getAllLikeByPostId();
    } else if (urlpostComment === "?comment_id") {
      GetLikedCommentList();
    }
  }, [urlpostComment === "?post_id", urlpostComment === "?comment_id"]);

  function calculateTime(time) {
    const x = new Date();
    const y = new Date(time);

    function compare(x, y) {
      return Math.abs(x - y);
    }

    const diff = compare(x, y);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));

    const yesterday = new Date(x.getFullYear(), x.getMonth(), x.getDate() - 1);
    const toDate = new Date(y.getFullYear(), y.getMonth(), y.getDate());

    let hoursMins = "";
    let result = "";

    if (toDate.getTime() === yesterday.getTime()) {
      result = "1d";
      return result;
    } else {
      hoursMins = format(y, "h:mm a");

      if (days >= 365) {
        result = `${Math.floor(days / 365)}Y`;
      } else if (days >= 30) {
        result = `${Math.floor(days / 30)}M`;
      } else if (days >= 7) {
        result = `${Math.floor(days / 7)}w`;
      } else if (days >= 1) {
        result = `${days}d`;
      } else if (hours >= 1) {
        result = `${hours}h`;
      } else if (minutes > 1) {
        result = hoursMins;
      } else {
        result = hoursMins;
      }

      return result;
    }
  }

  return (
    <>
      <Nav />
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 72px)" }}
      >
        <div className="container 2xl:px-0 xl:px-12 lg:px-8 sm:!px-8 !px-3">
          <div className="lg:flex justify-between gap-4 block align-top py-10 sm:px-0 md:!px-0 lg:px-0 xl:px-0 2xl:px-0 px-3">
            <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />

            <div className="flex flex-col bg-white w-full pt-[30px] px-[23px] pb-[40px]">
              <div className={"flex items-center"}>
                <div
                  className="flex items-center gap-[13px]"
                  onClick={() =>
                    history.push({
                      pathname: "/staff",
                    })
                  }
                >
                  <img
                    src={leftArrow}
                    style={{
                      height: "18px",
                      cursor: "pointer",
                      width: "22px",
                    }}
                  />
                  <div className="text-[18px] leading-[20px] tracking-[]">
                    {likeCount ? (
                      likeCount == 1 ? (
                        `${likeCount} Like`
                      ) : (
                        `${likeCount} Likes`
                      )
                    ) : (
                      <div className="animate-pulse flex space-x-2">
                        <div className="h-4 w-10 bg-slate-400 rounded"></div>
                        <div className="h-4 w-28 bg-slate-400 rounded"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div className="border border-[#000000]-600 mt-[30px] mb-[25px] opacity-30  w-full" />
              </div>
              <div className="flex flex-col gap-[27px]">
                {!likedCommentList?.length && !loader && (
                  <p className="flex justify-center items-center p-3 rounded-[10px] h-full sm:w-full font-semibold text-[24px]">
                    No data found
                  </p>
                )}
                {likedCommentList?.length === 0 ? (
                  <Loader />
                ) : (
                  likedCommentList?.map((item, index) => {
                    return (
                      <div
                        className="flex justify-between bg-white rounded-[10px] sm:w-full"
                        key={index}
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-12 min-w-[40px] h-12 rounded-full overflow-hidden">
                            <img
                              // src={topImg}
                              src={item?.userId?.image}
                              alt=""
                              className="object-cover w-full h-full"
                            />
                          </div>
                          <div>
                            <h3 className="p-0 text-[#333333] text-[18px] font-medium">
                              {item?.userId?.name
                                ? item?.userId?.name
                                : item?.userId?.firstName +
                                  " " +
                                  item?.userId?.lastName}
                            </h3>
                            <p className="text-gray-500 text-[15px] font-normal">
                              {item?.userId?.role == "hospital"
                                ? item?.userId?.hiringRole
                                : item?.userId?.role == "staff"
                                ? item?.userId?.jobTitle
                                : JoinRole}
                            </p>
                          </div>
                        </div>
                        <p className="text-[15px] text-gray-500 mt-1 font-normal leading-[15px] tracking-[0.2px]">
                          {/* {item?.pushType} */}
                          {calculateTime(item?.createdAt)}
                        </p>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <RecommendedForYou />
          </div>
        </div>
      </div>
    </>
  );
};

export default LikedCommentList;
