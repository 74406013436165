import React from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import user from "../../assets/images/user.svg";
import email from "../../assets/images/email.svg";
import phone from "../../assets/images/phone.svg";
import key from "../../assets/images/key.svg";
import calendar from "../../assets/images/calendar.png";
import ErrorState from "./components/errorState";
import FileBrowser from "./components/fileBrowser";
import SubHeader from "./components/subHeader";
// import img from "../../assets/images/3-4.png";
import img from "../../assets/images/3-4.svg.svg";
import { callApi, decrementStep } from "../../../redux/action/action";
import "./staffSignUp_3.css";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { agreementData } from "../../../constants/otherConstans";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../config/axiosConfig";
import Loader from "../../modals/Loader";
import { HeaderComp } from "./headerComp";
class StaffSignUp_3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redo: [],
      fullName: "",
      dob: "",
      email: "",
      phone: "",
      psw: "",
      repsw: "",
      // ssn: "",
      // ssnTitleErr: "The SSN number is required",
      file: "",
      fileTitleErr: "The PDF, DOCX file is required.",
      fileAdded: false,
      uploadedFileUrl: null,
      error: {
        fullName: "none",
        dob: "none",
        email: "none",
        phone: "none",
        psw: "none",
        repsw: "none",
        jobTitle: "none",
        file: "none",
        // ssn: "none",
      },
      shouldDeleteLink: false,
      fname: "",
      delFile: false,
      jobTitle: "",
      jobTitleErr: "Job Title is required",
      // fileErr: "The PDF, DOCX file is required.",
      pswErr: "Password is required",
      emailErr: "Valid email address is required.",
      showLoader: false,
      imgUrl: "",
      croppedImgUrl: false,
      showErrors: false,
    };
  }

  componentDidMount() {
    const staffSignUpData = JSON.parse(localStorage.getItem("staffSignUpData"));

    if (this.props.formData?.contactDetails) {
      const {
        fullName,
        dob,
        email,
        phone,
        psw,
        repsw,
        // ssn,
        file,
        jobTitle,
        ...otherDetails
      } = this.props.formData.contactDetails;

      this.setState(
        {
          fullName: fullName || "",
          dob: dob || "",
          email: email || "",
          phone: phone || "",
          psw: psw || "",
          repsw: repsw || "",
          // ssn: ssn || "",
          file: file || null,
          jobTitle: jobTitle || "",
          showLoader: false,
        },
        () => {
          console.log(this.state);
        }
      );
    }
  }

  togglePassword = (id) => {
    let input = $("#" + id);
    if (input.attr("type") === "password") {
      input.attr("type", "text");
      $("#c_" + id).hide();
      $("#o_" + id).show();
    } else {
      input.attr("type", "password");
      $("#o_" + id).hide();
      $("#c_" + id).show();
    }
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  setName = (e) => {
    const inputText = e.target.value;
    const pattern = /^[A-Za-z\s]*$/;
    this.setState((prevState) => ({
      fullName: !prevState.fullName,
    }));
    if (pattern.test(inputText)) {
      this.setState({ fullName: inputText });
      if (inputText !== "") {
        const error = this.state.error;
        error.fullName = "none";
        this.setState({ error });
      }
    }
  };
  formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  setDOB = (e) => {
    const formattedDate = this.formatDate(e);
    this.setState({ dob: formattedDate });
    if (e !== "") {
      var error = this.state.error;
      error.dob = "none";
      this.setState({ error: error });
    }
  };
  validateDOB = (dob) => {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dob)) {
      return false;
    }
    const parsedDate = new Date(dob);
    if (isNaN(parsedDate.getTime())) {
      return false;
    }
    return true;
  };
  setEmail = async (e) => {
    const emailValue = e.target.value;
    this.setState({ email: emailValue });
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zAZ0-9-]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(emailValue).toLowerCase());
    this.setState((prevState) => {
      const updatedError = { ...prevState.error };

      if (emailValue !== "" && valid) {
        updatedError.email = "none";
      } else {
        updatedError.email = "block";
      }

      return { error: updatedError };
    });
  };
  setJobTitle = (e) => {
    const jobTitle = e.target.value;
    this.setState({ jobTitle: jobTitle });

    this.setState((prevState) => {
      const updatedError = { ...prevState.error };

      if (jobTitle !== "") {
        updatedError.jobTitle = "none";
      } else {
        updatedError.jobTitle = "block";
      }

      return { error: updatedError };
    });
  };
  // setSSN = (e) => {
  //   const ssnValue = e.target.value.replace(/\D/g, "");
  //   this.setState({ ssn: ssnValue });
  //   this.setState((prevState) => {
  //     const updatedError = { ...prevState.error };

  //     if (ssnValue !== "") {
  //       updatedError.ssn = "none";
  //     } else {
  //       updatedError.ssn = "block";
  //     }

  //     return { error: updatedError };
  //   });
  // };
  setfile = (e) => {
    // const fileValue = e.target.value;
    // console.log(e, "eeeeeeeeeeeee");
    // this.setState({ file: fileValue });

    this.setState((prevState) => {
      const updatedError = { ...prevState.error };

      if (fileValue !== "") {
        updatedError.file = "none";
      } else {
        updatedError.file = "block";
      }

      return { error: updatedError };
    });
  };
  setPhone = (e) => {
    if (e.target.value.length < 15) {
      this.setState({ phone: e.target.value });
      var validate;
      if (e.target.value.match(/\d/g))
        if (e.target.value.match(/\d/g).length > 7) validate = true;
        else validate = false;
      else validate = false;
      var error = this.state.error;
      if (e.target.value !== "" && validate) {
        error.phone = "none";
        this.setState({ error: error });
      } else {
        error.phone = "block";
        this.setState({ error: error });
      }
    }
    // this.setState((prevState) => ({
    //   phone: !prevState.phone,
    // }));
  };

  formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  setPsw = (e) => {
    const passwordValue = e.target.value;
    this.setState({ psw: passwordValue });
    var password = e.target.value;
    var error = this.state.error;
    if (password.length === 0) {
      this.setState({ pswErr: "Password is required." });
      error.psw = "block";
      this.setState({ error: error });
      return;
    } else {
      if (password.length < 6) {
        this.setState({
          pswErr:
            "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
        });
        error.psw = "block";
        this.setState({ error: error });
      } else {
        var mached = [];
        mached.push("[$@$!%*#?&]"); // Special Charector
        mached.push("[A-Z]"); // Uppercase Alpabates
        mached.push("[0-9]"); // Numbers
        mached.push("[a-z]"); // Lowercase Alphabates
        var ctr = 0;
        for (var i = 0; i < mached.length; i++) {
          if (new RegExp(mached[i]).test(password)) {
            ctr++;
          }
        }
        switch (ctr) {
          case 0:
          case 1:
          case 2:
          case 3:
            this.setState({
              pswErr:
                "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
            });
            error.psw = "block";
            this.setState({ error: error });
            break;
          case 4:
            error.psw = "none";
            this.setState({ error: error });
            break;
          default:
            return;
        }
      }
      if (this.state.repsw !== "") {
        var val = this.state.repsw === e.target.value;
        error = this.state.error;
        if (val) {
          error.repsw = "none";
          this.setState({ error: error });
        } else {
          error.repsw = "block";
          this.setState({ error: error });
        }
      }
    }
  };

  validatePassword = (password) => {
    if (password.length < 8) {
      return false; // Password is too short
    }
    return true;
  };
  setRepsw = (e) => {
    const rePasswordValue = e.target.value;
    this.setState({ repsw: rePasswordValue });
    var validate = this.state.psw === e.target.value;
    var error = this.state.error;
    if (e.target.value !== "" && validate) {
      error.repsw = "none";
      this.setState({ error: error });
    } else if (e.target.value === "" && this.state.psw === "") {
      error.repsw = "none";
      this.setState({ error: error });
    } else {
      error.repsw = "block";
      this.setState({ error: error });
    }
  };

  isPasswordMatch = (psw, repsw) => {
    return psw === repsw;
  };
  setDelFile = (del) => {
    this.setState({ delFile: del, file: false });
    // if (del && this.state.error.file === "block") this.hideFileErr();
  };

  setFile = (file) => {
    // this.uploadFileFunc(file);
    this.setState((prevState) => ({
      file: prevState.file,
    }));
  };

  // uploadFileFunc = async (file) => {
  //   console.log(file, "filefilefile");
  //   const data = {
  //     file: file,
  //     name: "resume",
  //   };
  //   const res1 = await callApi("POST", "v1/LHS/file/upload", data);
  //   console.log(res1.upload, "res1res1");
  //   if (res1?.upload?.upload) {
  //     this.setState({
  //       file: res1?.upload?.upload?.link,
  //       showLoader: false,
  //     });
  //   }
  // };

  showFileErr = () => {
    var err = this.state.error;
    err.file = "block";
    this.setState({ error: err });
  };
  hideFileErr = () => {
    var err = this.state.error;
    err.file = "none";
    this.setState({ error: err });
  };

  goToNexts = () => {};
  preventNumberInput = (e) => {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      ((keyCode > 47 && keyCode) < 58 || (keyCode > 95 && keyCode < 107)) &&
      keyCode !== 8 &&
      keyCode !== 9 &&
      keyCode !== 32
    ) {
      e.preventDefault();
    }
  };

  validateEmail(email) {
    // Regular expression pattern to validate email
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }
  validateForm = () => {
    // Function to validate the form inputs
    let valid = true;
    const error = { ...this.state.error };

    // Validate Full Name
    if (this.state.fullName.trim() === "") {
      error.fullName = "block";
      valid = false;
    }

    // Validate Date of Birth
    if (!this.validateDOB(this.state.dob)) {
      error.dob = "block";
      valid = false;
    }

    // Validate Email
    if (this.state.email === "" || !this.validateEmail(this.state.email)) {
      error.email = "block";
      valid = false;
    }

    if (!this.validatePassword(this.state.psw)) {
      error.psw = "Password does not meet the criteria.";
      valid = false;
    }

    // Validate Re-enter Password
    if (!this.isPasswordMatch(this.state.psw, this.state.repsw)) {
      error.repsw = "Passwords do not match.";
      valid = false;
    }

    if (this.state.phone === "") {
      error.phone = "block";
      valid = false;
    }
    if (this.state.jobTitle === "") {
      error.jobTitle = "block";
      valid = false;
    }
    if (this.state.ssn === "") {
      error.ssn = "block";
      valid = false;
    }
    if (this.state.file === "") {
      error.file = "block";
      valid = false;
    }
    if (this.state.psw === "") {
      error.psw = "block";
      valid = false;
    }

    this.setState({ error, showErrors: true });

    return valid;
  };
  // showLoader = () => {
  //   this.setState({ showLoader: true });
  // };
  hideLoader = () => {
    this.setState({ showLoader: false });
  };
  onNextClick = async () => {
    try {
      this.setState({ showLoader: true });
      if (this.validateForm()) {
        if ((await this.emailRepeatCheck()) && (await this.ssnRepeatCheck())) {
          this.setState({ showLoader: false });
          this.props.onFormSubmit({ contactDetails: this?.state });
        } else {
          this.setState({ showLoader: false });
          this.setState({ emailErr: "This Email address is already in use." });
        }
      } else {
        // Handle the case when form validation fails
        this.setState({ showLoader: false });
        toast.error("Please fill in all the required fields.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Error during sign-up:", error);
    } finally {
      this.setState({ showLoader: false });
    }
  };

  ssnRepeatCheck = async () => {
    try {
      const res1 = await callApi(
        "GET",
        `v1/LHS/staff/ssn/${this.state.ssn}`,
        null
      );
      if (res1.status == 200) {
        this.setState((prevState) => {
          const updatedError = { ...prevState.error };
          updatedError.ssn = "none";
          return { error: updatedError };
        });
        return true;
      } else {
        this.setState((prevState) => {
          const updatedError = { ...prevState.error };
          updatedError.ssn = "block";
          return { error: updatedError };
        });
        this.setState({ ssnTitleErr: "ssn already in use." });
        return false;
      }
    } catch (error) {
      console.error("Error checking ssn existence:", error);
      return true;
    }
  };

  emailRepeatCheck = async () => {
    try {
      const data = {
        email: this.state.email,
      };

      const [res1, res2, res3] = await Promise.all([
        callApi("POST", "v1/LHS/hospital/emailCheck", null, data),
        callApi("POST", "v1/LHS/staff/emailCheck", null, data),
        callApi("POST", "v1/LHS/admin/emailCheck", null, data),
      ]);

      // If any of the API calls return "OK", that means the email exists
      if (res1.result == "OK" && res2.result == "OK" && res3.result == "OK") {
        this.setState((prevState) => {
          const updatedError = { ...prevState.error };
          updatedError.email = "none";
          return { error: updatedError };
        });
        return true;
      } else {
        this.setState((prevState) => {
          const updatedError = { ...prevState.error };
          updatedError.email = "block";
          return { error: updatedError };
        });
        this.setState({ emailErr: "This Email address is already in use." });
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false; // Assume email exists to prevent proceeding
    }
  };

  continueToNext = () => {
    console.log("stateeeee333333", this.state);
    // this.props.onFormSubmit();
  };
  handleDeleteFile = () => {
    if (this.state.shouldDeleteLink) {
      this.setState({
        uploadedFileUrl: null,
      });
    }
  };
  dateFocus = (e) => {
    e.currentTarget.type = "date";
    e.currentTarget.placeholder = "Date Of Birth";
  };
  dateBlur = (e) => {
    e.currentTarget.type = "text";
    e.currentTarget.placeholder = "Date Of Birth";
    e.currentTarget.value = moment(this.state.dob).format("MM-DD-YYYY");
  };
  handleBack = () => {
    decrementStep();
  };
  // setImgUrl = (url) => {
  //   this.setState({ imgUrl: url });
  // };

  setImgUrl = (url) => {
    this.setState((prevState) => ({
      ...prevState,
      imgUrl: url,
      croppedImgUrl: url,
    }));
  };

  render() {
    return (
      <>
        {" "}
        <div
          className="d-flex h-screen overflow-hidden"
          style={{ backgroundColor: "#73C7A9" }}
        >
          <div
            className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
            style={{ padding: "0", maxWidth: 720, width: "100%" }}
          >
            <div className="ssu_container py-4 px-5 max-h-[90vh] h-auto">
              <HeaderComp />
              <SubHeader
                num="3"
                title="Contact Information"
                next="/staffSignUp_3"
                prev="/staffSignUp_1"
                img={img}
                onBack={this.handleBack}
              />
              <div className="ssu_body mt-4 w-auto" style={{ maxWidth: 800 }}>
                <div className="ssu_txt1 text-xl mt-4 mb-0">
                  Please provide your contact details
                </div>
                <div
                  className="ssu2_modal1_input p-0"
                  style={{ marginTop: 10 }}
                >
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={user} />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Full Name"
                      type="text"
                      value={this.state.fullName || this.fullName}
                      onChange={this.setName}
                      onKeyDown={this.preventNumberInput}
                      onBlur={(e) => {
                        this.emailRepeatCheck();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={this.state.error.fullName}
                    name="Full Name is required."
                  />
                </div>
                <div className="ssu2_modal1_input p-0">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon z-10">
                      <img
                        width="18px"
                        height="15px"
                        alt="img"
                        src={calendar}
                      />
                    </div>
                    <DatePicker
                      selected={
                        this.state.dob ? new Date(this.state.dob) : null
                      }
                      onChange={this.setDOB}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      className="ssu2_modal3_select pl-[60px]"
                      placeholderText="DOB"
                      maxDate={new Date()}
                      onBlur={(e) => {
                        if (e) this.emailRepeatCheck();
                      }}
                    />
                    {/* <input
                      className="ssu2_modal3_select"
                      placeholder="DOB"
                      type="text"
                      max={new Date().toISOString().split("T")[0]}
                      value={this.state.dob || this.dob}
                      onChange={this.setDOB}
                      onKeyDown={this.preventNumberInput}
                      onFocus={this.dateFocus}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") this.emailRepeatCheck();
                      }}
                    /> */}
                  </div>
                  <ErrorState
                    show={this.state.error.dob}
                    name="DOB is required."
                  />
                </div>
                <div className="ssu2_modal1_input p-0">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={email} />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Email Address"
                      type="text"
                      value={this.state.email || this.email}
                      onChange={this.setEmail}
                      onBlur={this.emailRepeatCheck}
                    />
                  </div>
                  <ErrorState
                    show={this.state.error.email}
                    name={this.state.emailErr}
                  />
                </div>
                <div className="ssu2_modal1_input p-0">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={phone} />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Phone Number"
                      type="number"
                      min="1"
                      max="999999999999999"
                      value={this.state.phone || this.phone}
                      onChange={this.setPhone}
                      onKeyDown={this.formatInput}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") this.continueToNext();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={this.state.error.phone}
                    name="Phone number is required."
                  />
                </div>
                <div className="ssu2_modal1_input p-0">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={key} />
                    </div>
                    <input
                      id="psw"
                      className="ssu3_password"
                      placeholder="Password"
                      type="password"
                      value={this.state.psw || this.psw}
                      onChange={this.setPsw}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") this.continueToNext();
                      }}
                    />
                    <div
                      className="ssu3_eye"
                      onMouseDown={() => this.togglePassword("psw")}
                      onMouseUp={() => this.togglePassword("psw")}
                      onTouchStart={() => this.togglePassword("psw")}
                      onTouchEnd={() => this.togglePassword("psw")}
                    >
                      <VisibilityOffIcon
                        id="c_psw"
                        style={{ display: "block" }}
                      />
                      <VisibilityIcon id="o_psw" style={{ display: "none" }} />
                    </div>
                  </div>
                  <ErrorState
                    show={this.state.error.psw}
                    // name="Enter strong password"
                    name={this.state.pswErr || "Enter strong password"}
                  />
                </div>
                <div className="ssu2_modal1_input p-0">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={key} />
                    </div>
                    <input
                      id="rpsw"
                      className="ssu3_password"
                      placeholder="Confirm password"
                      type="password"
                      value={this.state.repsw || this.repsw}
                      onChange={this.setRepsw}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") this.continueToNext();
                      }}
                    />
                    <div
                      className="ssu3_eye"
                      onMouseDown={() => this.togglePassword("rpsw")}
                      onMouseUp={() => this.togglePassword("rpsw")}
                      onTouchStart={() => this.togglePassword("rpsw")}
                      onTouchEnd={() => this.togglePassword("rpsw")}
                    >
                      <VisibilityOffIcon
                        id="c_rpsw"
                        style={{ display: "block" }}
                      />
                      <VisibilityIcon id="o_rpsw" style={{ display: "none" }} />
                    </div>
                  </div>
                  <ErrorState
                    show={this.state.error.repsw}
                    name="Doesn't match with the password."
                  />
                </div>

                <div className="ssu2_modal1_input p-0">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={email} />
                    </div>
                    <input
                      className="ssu2_modal3_select"
                      placeholder="Job Title"
                      type="text"
                      value={this.state.jobTitle || this.jobTitle}
                      onChange={this.setJobTitle}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") this.continueToNext();
                      }}
                    />
                  </div>
                  <ErrorState
                    show={this.state.error.jobTitle}
                    name={this.state.jobTitleErr}
                  />
                </div>

                {/* <div className="ssu2_modal1_input p-0">
                  <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                    <div className="input_left_icon">
                      <img width="18px" height="15px" alt="img" src={key} />
                    </div>
                    <input
                      id="ssn"
                      className="ssu2_modal3_select"
                      placeholder="SSN Number"
                      type="password"
                      value={this.state.ssn}
                      onChange={this.setSSN}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") this.continueToNext();
                      }}
                      onBlur={this.ssnRepeatCheck}
                    />
                    <div
                      className="ssu3_eye"
                      onMouseDown={() => this.togglePassword("ssn")}
                      onMouseUp={() => this.togglePassword("ssn")}
                      onTouchStart={() => this.togglePassword("ssn")}
                      onTouchEnd={() => this.togglePassword("ssn")}
                    >
                      <VisibilityOffIcon
                        id="c_ssn"
                        style={{ display: "block" }}
                      />
                      <VisibilityIcon id="o_ssn" style={{ display: "none" }} />
                    </div>
                  </div>
                  <ErrorState
                    show={this.state.error.ssn}
                    name={this.state.ssnTitleErr}
                  />
                </div> */}
                {/* <div
                  className="ssu2_modal1_text2"
                  style={{ marginTop: "10px" }}
                >
                  {" "}
                  Note:- SSN is required for payments.
                </div> */}
                {/* <div
                  className="ssu2_modal1_text2 text-center"
                  style={{ marginTop: "30px" }}
                >
                  {" "}
                  Please upload your resume
                </div> */}
                {this.state.file && (
                  <div className="ssu2_modal1_text2 mt-4">
                    File Uploaded: {this.state.file.name}
                    {/* Display the file name */}
                  </div>
                )}
                <div className="ssu2_modal1_input p-0">
                  <div
                    className="flex justify-center relative mt-[5px]"
                    style={{ cursor: "pointer" }}
                  >
                    <button className="w-full h-[44px] border border-[#73C7A9] rounded-[100px] font-semibold text-sm text-[#73C7A9] cursor-pointer hover:!cursor-pointer">
                      UPLOAD YOUR RESUME
                    </button>
                    <input
                      type="file"
                      accept=".pdf, .docx"
                      style={{
                        position: "absolute",
                        /* top: 15px; */
                        marginTop: "0px !important",
                        height: "44px !important",
                        width: "100% !important",
                      }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          this.setState((prev) => ({
                            ...prev,
                            file: file,
                            fileTitleErr: "",
                          }));
                        }
                      }}
                      className="opacity-0 mt-3 w-auto !h-12 cursor-pointer"
                    />
                  </div>
                  <ErrorState
                    show={this.state.error.file}
                    name={this.state.fileTitleErr}
                  />
                </div>
                {/* {this.state.uploadedFileUrl && ( <div className="ssu2_modal1_text2">
                    <a href={this.state.uploadedFileUrl}>
                      {this.state.uploadedFileUrl}
                    </a>
                    <button onClick={this.handleDeleteFile}></button>
                  </div>
                )} */}
                <div
                  className="ssu2_modal1_text2 text-center"
                  style={{ marginTop: "5px" }}
                >
                  {" "}
                  Please upload only Pdf, Doc, Docx file
                </div>
              </div>

              <hr style={{ margin: "10px 0px 0px" }} />
              <div className="ssu_bottom">
                <div
                  className="ssu_button d-flex align-items-center justify-content-center p-0"
                  onClick={this.onNextClick}
                  // onClick={this.continueToNext}
                >
                  {this.state.showLoader ? <Loader /> : "CONTINUE"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StaffSignUp_3;
