import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../common/modal";

const LoginModal = ({ openModal, closeModal }) => {
  const history = useHistory();
  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      className={"md:w-1/3 md:mt-0"}
      style={{ backgroundColor: "red" }}
    >
      <div className="px-2">
        <p className="text-[21px] text-center text-[#333333] mb-5">
          To perform any action, please log in or sign up. Thank you!
        </p>

        <div className="text-center">
          <button
            onClick={() => {
              history.push({ pathname: "/login" });
              closeModal();
            }}
            className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5"
          >
            Sign up
          </button>
          <button
            className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5 hover:!text-[#73C7A9]"
            onClick={() => {
              history.push({
                pathname: "/login",
              });
              closeModal();
            }}
          >
            Log in
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
