import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import right_arrow from "../../assets/images/right_arrow.svg";
import Modal from "../../common/modal";
import StarIcon from "@material-ui/icons/Star";
import user from "../../assets/images/user.png";
import moment from "moment";
import RateReviewModal from "../staff/rate_review_modal";
import { axiosInstance } from "../../../config/axiosConfig";
import Button from "../../ui/Button";

const JobCard = ({
  index,
  jobCardData,
  closeJobDetails,
  backImg,
  pathName,
  userImage,
  name,
  location,
  ratings,
  totalRatings,
  seeAll,
  jobTitle,
  medicalSettings,
  startDate,
  endDate,
  costPerHour,
  addReview,
  jobDescription,
  contractType,
  contractLength,
  timeCommitment,
  weekendAvailiblity,
  buttonType,
  buttonAction,
  loading,
  expectedDatePlaceholder = "",
}) => {
  const [loader, setLoader] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [buttonTypeFresh, setButtonTypeFresh] = useState("");

  useEffect(() => {
    setButtonTypeFresh(buttonType);
  }, [buttonType]);

  const getAllReviews = async (id) => {
    try {
      setLoader(true);
      setShowReviewModal(true);
      const data = await axiosInstance.get(
        `v1/LHS/rating/getHospitalRating/${id}`
      );
      if (data.status === 200) {
        setReviewData(data.data.data);
      }
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div
        index={index}
        className="p-4 border rounded-[10px] border-gray-100 bg-white sm:w-full"
      >
        <div className="flex gap-3">
          {backImg && (
            <button onClick={closeJobDetails}>
              <img width="18px" height="15px" alt="img" src={right_arrow} />
            </button>
          )}
          <button
            onClick={pathName}
            className="flex items-center text-inherit decoration-inherit"
          >
            <div className="hover:text-black">
              <img
                src={userImage}
                // src={
                //   v?.job?.hospital?.avatar
                //     ? v?.job?.hospital?.avatar
                //     : v?.job?.hospital?.image
                //         ?.image
                // }
                className={"h-[54px] w-[54px] rounded-full"}
              />
            </div>
            <div className="ml-3 text-left">
              <h1 className="text-base font-medium text-[#333333]">{name}</h1>
              <h1 className="text-gray-500 text-[14px] mt-1">{location}</h1>
              <div className="flex text-sm items-center gap-2.5 mt-1">
                <div className="flex gap-[3px] items-center">
                  <StarIcon className="text-yellow-500" />
                  <h1 className="text-gray-500 text-sm">{ratings}</h1>
                </div>
                <div className="w-[1px] h-[20px] bg-[#7b7a7a]"></div>
                <h1 className="text-gray-500 text-sm ">
                  (Reviews {totalRatings})
                </h1>
                {totalRatings > 0 && (
                  <div
                    className="text-[#73C7A9] text-[14px] cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      getAllReviews(seeAll);
                    }}
                  >
                    See all
                  </div>
                )}
              </div>
            </div>
          </button>
        </div>
        <div
          className="border border-[#000000]-800  opacity-50 my-3"
          style={{
            width: "calc(100% + 48px)",
            position: "relative",
            left: "-24px",
          }}
        ></div>

        <div className="flex flex-col gap-2">
          <h1 className="text-[16px] font-semibold ">Job Title: {jobTitle}</h1>
          <h1 className="text-gray-500 text-[14px] font-normal leading-[19.2px]">
            {medicalSettings}
          </h1>
          {startDate && (
            <h1 className="text-gray-500 text-[14px] font-normal leading-[19.2px]">
              {expectedDatePlaceholder
                ? expectedDatePlaceholder
                : "Expected Date"}
              : {moment?.(startDate).format("MM-DD-YYYY")}
            </h1>
          )}
          <h1 className="text-gray-500 text-[14px] font-normal leading-[19.2px]">
            {endDate && contractType != "Permanent Position"
              ? "Contract End Date"
              : "Contract Length"}
            :{" "}
            {contractType == "Permanent Position"
              ? "Permanent"
              : endDate
              ? moment?.(endDate).format("MM-DD-yyyy")
              : contractLength?.duration + " " + contractLength?.length}
          </h1>
          {/* ? moment(endDate).diff(moment(startDate), "days") + " days" */}
          {contractType != "Permanent Position" && (
            <h1 className="text-gray-500 text-[14px] font-normal leading-[19.2px]">
              Payment offer: $ {costPerHour} /hr
            </h1>
          )}
          {addReview && (
            <h1 className="text-[14px] mt-1 cursor-pointer" onClick={addReview}>
              + Add Review
            </h1>
          )}
          <div className="flex gap-3">
            <div className="rounded-full px-4 py-[10px] text-[#73C7A9] bg-[#EDFAFF]">
              {contractType}
            </div>
            {contractType == "Temporary Position" && (
              <div className="rounded-full px-4 py-[10px] text-[#73C7A9] bg-[#EDFAFF]">
                {timeCommitment}{" "}
                {timeCommitment == "Full Time" && "(8hrs or more)"}
              </div>
            )}
            {contractType !== "Permanent Position" && weekendAvailiblity && (
              <div className="rounded-full px-4 py-[10px] text-[#73C7A9] bg-[#EDFAFF]">
                Weekend Job
              </div>
            )}
          </div>
          <h1 className="font-semibold mt-2 leading-[20px] tracking-[0.2px]">
            Job description and key result areas
          </h1>
          <h1 className="text-gray-500 text-[16px] leading-[19.2px] whitespace-break-spaces">
            {jobDescription}
          </h1>
          {buttonTypeFresh === "apply" && (
            <Button
              loading={loading === index}
              onClick={() =>
                buttonAction({ type: buttonTypeFresh, index, jobCardData })
              }
              className={
                "text-base font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white mt-5 mb-2"
              }
            >
              Apply
            </Button>
          )}
          {buttonTypeFresh === "acceptReject" && (
            <div className="mt-10 flex gap-[31px]">
              <Button
                // loading={loading === index}
                onClick={() =>
                  buttonAction({ type: "Reject", index, jobCardData })
                }
                className={
                  "font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] border border-[#333333]"
                }
              >
                Reject
              </Button>
              <Button
                loading={loading === index}
                onClick={() =>
                  buttonAction({ type: "Accept", index, jobCardData })
                }
                className={
                  "font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white"
                }
              >
                Accept
              </Button>
            </div>
          )}
          {buttonTypeFresh === "acceptWithdrawn" && (
            <Button
              loading={loading === index}
              onClick={() =>
                buttonAction({ type: buttonTypeFresh, index, jobCardData })
              }
              className={
                "text-base font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white mt-5 mb-2"
              }
            >
              Withdraw
            </Button>
          )}
          {buttonTypeFresh === "applied" && (
            <Button
              loading={loading === index}
              onClick={buttonAction}
              className={
                "text-base font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white mt-5 mb-2 bg-opacity-[50%]"
              }
            >
              Applied
            </Button>
          )}
          {buttonTypeFresh === "filled" && (
            <Button
              loading={loading === index}
              onClick={buttonAction}
              className={
                "text-base font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white mt-5 mb-2 bg-opacity-[50%]"
              }
            >
              Position filled
            </Button>
          )}
          {buttonTypeFresh === "rejected" && (
            <Button
              loading={loading === index}
              onClick={buttonAction}
              className={
                "font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#ED93A3] text-white mt-10 tracking-[0.2px]"
              }
            >
              Rejected
            </Button>
          )}
          {buttonTypeFresh === "withdrawn" && (
            <Button
              loading={loading === index}
              onClick={buttonAction}
              className={
                "text-sm font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px]  bg-[#fa7c7c] text-white mt-10"
              }
            >
              Withdrawn
            </Button>
          )}
        </div>
        <RateReviewModal
          isOpen={showReviewModal}
          onClose={() => setShowReviewModal(false)}
          className={"md:w-1/2 md:mt-0"}
          reviewData={reviewData}
          loader={loader}
          data={jobCardData?.data || jobCardData}
        />
      </div>
    </>
  );
};

export default JobCard;
