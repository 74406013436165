import React, { useState, useEffect } from "react";
import "../theme2.css";

const Pagination = (props) => {
  const [button, setButton] = useState([1, 2, 3, 4, 5]);
  const [totalPage, setTotalPage] = useState(5);
  const [curPage, setCurPage] = useState(1);

  useEffect(() => {
    initState();
  }, [props]);

  const initState = () => {
    var temp = [];
    var start = 1;

    if (props.curPage > 3) {
      start = props.curPage - 2;
    }

    if (props.totalPage - props.curPage < 2)
      start = props.curPage - 4 + (props.totalPage - props.curPage);

    if (start < 1) start = 1;

    for (var i = 0; i < 5; i++) {
      if (start <= props.totalPage) temp[i] = start;
      else temp[i] = false;
      start++;
    }

    setButton([...temp]);
    setCurPage(props.curPage);
    setTotalPage(props.totalPage);
  };

  const setPage = (num) => {
    props.setCurPage(num);
  };

  const nextPage = () => {
    if (curPage < totalPage) setCurPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    if (curPage > 1) setCurPage((prevPage) => prevPage - 1);
  };

  return (
    <div
      className="row"
      style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
    >
      <div className="page_container">
        <button
          className={curPage === 1 ? "page_arrow page_disable" : "page_arrow"}
          onClick={prevPage}
        >
          <span className="page_collapse">&nbsp;&nbsp;Previous</span>
        </button>

        {button.map((num, index) => (
          <button
            key={index}
            className={curPage === num ? "page_num page_selected" : "page_num"}
            onClick={() => setPage(num)}
            style={{
              display: num === false ? "none" : "block",
            }}
          >
            {num}
          </button>
        ))}

        <button
          className={
            curPage === totalPage
              ? "page_arrow page_next page_disable"
              : "page_arrow page_next"
          }
          onClick={nextPage}
        >
          <span className="page_collapse">Next&nbsp;&nbsp;</span>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
