import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import user from "../../../assets/images/user.png";
import fllipsImag from "../../../assets/images/UserEllipse.png";
import { axiosInstance } from "../../../../config/axiosConfig";
import useLoader from "../../../modals/hooks/useLoader";
import Modal from "../../../common/modal";
import JobCard from "../../../containers/commonComponent/JobCard";

const MyJobDetails = ({
  buttonAction,
  detailsData,
  closeJobDetails,
  loading,
}) => {
  const history = useHistory();
  const [showRecent, setShowRecent] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalWithDrawOpens, setModalWithDrawOpens] = useState(false);
  const [showId, setShowId] = useState([]);
  const modalPopupRef = useRef(null);
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalPopupRef?.current &&
        !modalPopupRef.current.contains(event.target)
      ) {
        setModalOpen(false);
        setShowRecent(!showRecent);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getJobsByHospitalId = async (hospitalId) => {
    try {
      showLoader();
      const url = `/v2/LHS/User/getJobById/${hospitalId}`;
      const response = await axiosInstance.get(url);
      if (response.data.data) {
        setShowId(response.data.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (detailsData?.id) {
      // getJobsByHospitalId(detailsData?.id);
    }
  }, [detailsData?.id]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowRecent(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const closeWithDrawPopup = () => {
    setModalWithDrawOpens(false);
  };

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col !gap-2 md:px-5 lg:px-0 w-full xl:w-full">
          <div className="">
            <JobCard
              index={0}
              backImg
              closeJobDetails={closeJobDetails}
              userImage={fllipsImag}
              name={detailsData?.data?.hospital?.healthCareInstitution?.name}
              location={detailsData?.data?.healthCareLocation}
              ratings={detailsData?.data?.hospital?.ratings?.ratingSum.toFixed(
                1
              )}
              totalRatings={detailsData?.data?.hospital?.ratings?.totalRatings}
              seeAll={detailsData?.data?.hospital?._id}
              jobTitle={detailsData?.data?.jobTitle}
              medicalSettings={detailsData?.data?.medicalSettings}
              startDate={
                detailsData?.diffData?.startDate
                  ? detailsData?.diffData?.startDate
                  : detailsData?.data?.expectedStartDate
              }
              endDate={detailsData?.diffData?.endDate}
              costPerHour={detailsData?.data?.costPerHour}
              contractType={detailsData?.data?.contractType}
              contractLength={detailsData?.data?.contractLength}
              timeCommitment={detailsData?.data?.timeCommitment}
              jobDescription={detailsData?.data?.jobDescription}
              weekendAvailiblity={detailsData?.data?.weekendAvailiblity}
              buttonType={detailsData?.pageType}
              jobCardData={detailsData}
              buttonAction={buttonAction}
              loading={loading}
              expectedDatePlaceholder={detailsData?.expectedDatePlaceholder}
            />
          </div>
          <div className="mt-3 bg-white rounded-lg p-4 shadow-md">
            <h1 className="font-semibold mb-2">Job Details</h1>
            <h2 className="mb-2">Medical Setting</h2>
            <h4 className="text-gray-500 mb-2">
              {detailsData?.data?.medicalSettings}
            </h4>
            {detailsData?.data?.contractType !== "Permanent Position" && (
              <>
                <div className="border-b border-b-gray-200 mb-3"></div>

                <h1 className="mb-2">
                  Weekend availability requierd from the staff
                </h1>
                <h4 className="text-gray-500 mb-2">
                  {detailsData?.data?.weekendAvailiblity === true
                    ? "Yes"
                    : "No"}
                </h4>
                <div className="border-b border-b-gray-200 mb-3"></div>

                <h1 className="mb-2">
                  Commitment is requierd from the staff Per day
                </h1>
                <h4 className="text-gray-500 mb-2">
                  {detailsData?.data?.timeCommitment}
                </h4>
                <div className="border-b border-b-gray-200"></div>
              </>
            )}
          </div>
          <div className="mt-3 bg-white rounded-lg p-4 shadow-md">
            <h1 className="font-semibold mb-2">Hospital Details</h1>
            <h2 className="mb-2">
              {detailsData?.data?.hospital?.healthCareInstitution?.name}
            </h2>
            <h4 className="text-gray-500">
              {detailsData?.data?.healthCareLocation}
            </h4>
          </div>
        </div>
        <Modal
          isOpen={modalWithDrawOpens}
          onClose={closeWithDrawPopup}
          className={"md:w-1/2 md:mt-0"}
        >
          <div className=" bg-white ">
            <div className="flex items-center gap-3">
              <div onClick={closeWithDrawPopup}>
                <img src={user} height={"50px"} width={"50px"} />
              </div>
              <div className=" text-xl">Steve hospital</div>
            </div>
            <div className="my-3">Reason for Withdrawn application</div>
            <div>
              <form class="mb-6">
                <div class="py-2 px-2 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                  <textarea
                    id="comment"
                    rows="6"
                    class="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                    placeholder="Write a comment..."
                    required
                  ></textarea>
                </div>
                <div className="">
                  <button
                    className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                    // onClick={handleSubmit}
                    onClick={() => {
                      closeWithDrawPopup();
                    }}
                  >
                    submit
                  </button>
                  <button
                    onClick={closeWithDrawPopup}
                    className="text-base  border-1  !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white w-32 h-11 ml-5"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          className={"md:w-1/3 md:mt-0"}
          style={{ backgroundColor: "red" }}
        >
          <div className="px-2">
            <p className="text-[21px] text-center text-[#333333] mb-5">
              To apply for the job, please log in or sign up. Thank you!
            </p>

            <div className="text-center">
              <button
                onClick={() => {
                  history.push({ pathname: "/login" });
                  setModalOpen(false);
                }}
                className="text-base border-2 border-[#333] rounded-full w-32 h-11 ml-5"
              >
                Sign up
              </button>
              <button
                className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                onClick={() => {
                  history.push({
                    pathname: "/login",
                  });
                  setModalOpen(false);
                }}
              >
                Log in
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default MyJobDetails;
