import React from "react";

import Call from "./../../../components/theme_2/hospitalProfile/Call";

const Chat = () => {
  return (
    <div className="theme2">
      <div className="theme2_container">
        <div className="theme2_main_container">
          <Call />
        </div>
      </div>
    </div>
  );
};

export default Chat;
