import React, { useState, useEffect } from "react";
import SubHeader from "../staffSignUp/components/subHeader";
// import img from "../../assets/images/3-3.png";
import img from "../../assets/images/3-4.svg.svg";
import { callApi } from "../../../redux/action/action";
import ErrorState from "../staffSignUp/components/errorState";
import Pending from "../../modals/pending";
import { CometChat } from "@cometchat-pro/chat";
import { useHistory } from "react-router-dom";
import Loader from "../../modals/Loader";
import { showSuccessToast } from "../../common/toastMeassage/ToastMeassage";
import { HeaderComp } from "../staffSignUp/headerComp";

const HospSignUp_3 = (props) => {
  const [userName, setUserName] = useState("William");
  const [agreement, setAgreement] = useState(false);
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState("none");
  const [showPending, setShowPending] = useState("none");
  const [mm, setMm] = useState("");
  const [yy, setYy] = useState("");
  const [dd, setDd] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [locations, setLocations] = useState("");
  const [after_date, setAfterDate] = useState("th");
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    var hosFirstname = window.localStorage.getItem("first_name");
    var hosLastName = window.localStorage.getItem("last_name");
    var hosZipcode = window.localStorage.getItem("zip_code");
    var hosCity = window.localStorage.getItem("hoscity");
    var hosState = window.localStorage.getItem("hosstate");
    var hosAddress = window.localStorage.getItem("hosstreet_address");

    window.scrollTo({ top: 0, behavior: "smooth" });
    var date = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var dat = date.getDate();
    var after_date = "th";
    if (dat === 1 || dat === 21 || dat === 31) {
      after_date = "st";
    } else if (dat === 2 || dat === 22) {
      after_date = "nd";
    }
    setAfterDate(after_date);
    var mm = date.getMonth() + 1;
    var currentMonth = months[mm - 1];
    var yy = date.getFullYear();
    var dd = dat < 10 ? "0" + dat : dat;
    mm = mm < 10 ? "0" + mm : mm;

    setMm(mm);
    setYy(yy);
    setDd(dd);
    setMonth(currentMonth);
    setDate(dat);
    var loc = `${hosAddress} street, ${hosCity}, ${hosState}, ${hosZipcode}`;
    setLocations(loc);
    setUserName(`${hosFirstname} ${hosLastName}`);
  };

  const finishSignUp = async () => {
    var hosFirstname = window.localStorage.getItem("first_name");
    var hosLastName = window.localStorage.getItem("last_name");
    var hosEmail = window.localStorage.getItem("email");
    var hosPhone = window.localStorage.getItem("phone");
    var hosPassword = window.localStorage.getItem("password");
    var hosRepassword = window.localStorage.getItem("repassword");
    var hosRole = window.localStorage.getItem("role");
    var hosEin = window.localStorage.getItem("ein");
    var hosInstiName = window.localStorage.getItem("institution_name");
    var hosInstiSize = window.localStorage.getItem("institution_size");
    var hosInstiWeb = window.localStorage.getItem("institution_website");
    var hosZipcode = window.localStorage.getItem("zip_code");
    var hosCity = window.localStorage.getItem("hoscity");
    var hosState = window.localStorage.getItem("hosstate");
    var hosAddress = window.localStorage.getItem("hosstreet_address");

    if (agreement) {
      setShowPending("block");
      setShowLoader(true);

      var data = {
        name: `${hosFirstname} ${hosLastName}`,
        email: hosEmail,
        hiringRole: hosRole,
        healthCareInstitution: {
          name: hosInstiName,
          size: hosInstiSize,
          website: hosInstiWeb,
        },
        corporateAddress: {
          zipCode: hosZipcode,
          street: hosAddress,
          city: hosCity,
          state: hosState,
        },
        password: hosPassword,
        phone: hosPhone,
        avatar: "",
        badge: true,
        ein: hosEin,
      };

      var res = await callApi("POST", "v1/LHS/hospital/signup", null, data);
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
      if (res && res.data && res.data._id) {
        /* CometChat SignUp */
        // props.onFormSubmit();
        showSuccessToast("Hospital created successfully");

        history.push("/login");
      } else {
        console.error("Error in API response:", res);
      }
      setShowPending("none");
    } else {
      setShowAlert("block");
    }
  };

  const handleBack = () => {
    history.push("/hospSignUp_2");
  };
  return (
    <div
      className="d-flex h-screen overflow-hidden"
      style={{ backgroundColor: "#73C7A9" }}
    >
      <div
        className="main_container w-11/12 sm:w-3/4 md:w-3/5 lg:w-2/5 p-0 self-auto relative top-[5vh] max-w-[1500px]"
        style={{ padding: "0", maxWidth: 720 }}
      >
        <div className="ssu_container py-4 px-5 min-h-[90vh] max-h-[90vh] h-auto flex flex-col">
          <HeaderComp />
          <div className="flex flex-col justify-between h-screen">
            <SubHeader
              num="3"
              onBack={handleBack}
              title="Agreement"
              redo={false}
              next={"/hospSignUp_1"}
              prev="/hospSignUp_2"
              img={img}
            />
            <div className="ssu4_body m-0 mt-4 w-full">
              <div className="hsu1_txt1 text-2xl">
                Congrats, {userName}, you're on the last step!
              </div>
              <div className="ssu_txt1 text-lg mt-3 mb-0">
                Please go through the agreement and provide your consent
              </div>
              <div className="ssu_txt1 text-xl mt-3 mb-0">
                Hospital Staffing Agreement
              </div>
              <hr style={{ margin: "40px 0px 0px" }} />
              <div className="ssu4_text3 !text-base">
                This Agreement entered into this {date + after_date} day of{" "}
                {month}, {yy} (“Effective Date – {mm}/{dd}/{yy}”), by and
                between LINKHEALTHSTAFF LLC (“LHS”), a Nevada registered
                corporation, and CLIENT located at {locations} WHEREAS, LHS
                operates an on-demand healthcare staffing platform that
                facilitates meeting and hiring of healthcare personnel to
                provide services to CLIENT. WHEREAS, CLIENT operates a
                Hospital/Medical Facility and CLIENT desires that LHS shall
                provide online meeting place to communicate with healthcare
                professionals.
              </div>
              <div className="ssu4_text3 !text-base">
                NOW THEREFORE, in consideration of the mutual promises and
                covenants between LHS and CLIENT, the Parties hereby agree:
              </div>
              <div className="ssu4_text3 !text-base">
                1. TERM.
                <br />
                The term of this Agreement will commence on the date of the last
                signature and will continue for three (3) years unless
                terminated prior thereto in accordance with the terms of this
                Agreement. If neither Party terminates pursuant to the terms
                herein, this agreement shall be automatically renewed for
                increment of additional (1) year periods and shall not require a
                writing by either party hereto in order to extend the term
                hereof.
              </div>
              <div className="ssu4_text3 !text-base">
                2. TERMINATION.
                <br />
                Either Party may terminate this Agreement at any time. Said
                termination will not affect the rights or responsibilities
                subsequent to transactions occurring prior to the effective date
                of termination.
              </div>
              <div className="ssu4_text3 !text-base">
                3. PERSONNEL.
                <br />
                LINKHEALTHSTAFF shall provide CLIENT with access to healthcare
                staff that comply with all pertinent terms and conditions of
                licensure and credentials including, but not limited to, proof
                of current licensure, registration and or certification (if
                applicable).
              </div>

              <div className="checkbox-container1" style={{ marginTop: 40 }}>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "730px",
                    margin: "0px auto",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {/* <input
                    type="checkbox"
                    className="w3-check ssu4_checkBox"
                    onChange={(e) => setAgreement(e.target.checked)}
                  /> */}
                  <input
                    type="checkbox"
                    className="first1"
                    id="first1"
                    onChange={(e) => setAgreement(e.target.checked)}
                  />
                  <label htmlFor="first1"></label>
                  <b
                    style={{
                      fontWeight: "normal",
                      fontSize: "18px",
                      color: error ? "red" : null,
                    }}
                  >
                    I provide my consent to Hospital Staffing Agreement
                  </b>
                </div>
                <ErrorState
                  show={showAlert}
                  name="Please provide your consent to Hospital Staffing Agreement."
                />
              </div>
            </div>
            <hr style={{ margin: "30px 0px 0px" }} />
            <div className="ssu_bottom">
              <div className="ssu_button" onClick={finishSignUp}>
                {showLoader ? <Loader /> : "CONTINUE"}
              </div>
            </div>
          </div>
          {/* <Pending display={showPending} title="Creating your profile" /> */}
        </div>
      </div>
    </div>
  );
};

export default HospSignUp_3;
