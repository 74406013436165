import React, { useEffect, useState } from "react";
import Header from "./../../../components/theme_2/components/header";
import right_arrow from "../../../components/assets/images/right_arrow.svg";
import jobImg from "../../../components/assets/images/doctors-nurse-walking-corridor 1.png";
import tickIcon from "../../../components/assets/images/tickIcon.png";
import { Modal, Button } from "react-bootstrap";
import starIcon from "../../../components/assets/images/star_icon.svg";
import chat from "../../../components/assets/images/chat.png";
import JobDetailsSection from "./../../../components/containers/staff/job_details_section";
import RateReviewModal from "./../../../components/containers/staff/rate_review_modal";
import moment from "moment";
import { AddReasonModal } from "./../myJobs/myJob";
import { callApi } from "../../../redux/action/action";

const JobOfferDetails = (props) => {
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);
  const [applicationType, setApplicationType] = useState("");
  const [reviewData, setReviewData] = useState([]);
  const [data, setData] = useState({});
  const [status, setStatus] = useState("");
  const [rejectReason, setrejectReason] = useState("");

  useEffect(() => {
    if (props.location.state) {
      setStatus(props.location.state.status);
      setData(props.location.state.data);
      // getJobOfferDetailsById(props.location.state.data._id);
    }
  }, [props.location.state]);

  const getAllReviews = async (id) => {
    const data = await callApi(
      "GET",
      `v1/LHS/rating/getHospitalRating/${id}`,
      null
    );
    if (data.status === 200) {
      setReviewData(data.data);
    }
  };

  const getJobOfferDetailsById = async (id) => {
    var token = localStorage.getItem("accessToken");
    const data = await callApi(
      "GET",
      `v1/LHS/job/get/${id}`,
      `Bearer ${token}`
    );
    if (data) {
      setData(data.data);
      console.log(data, "DDDDDD");
    }
  };

  const acceptJobOffer = async (id) => {
    var token = localStorage.getItem("accessToken");
    const data = await callApi(
      "POST",
      `v1/LHS/staff/accept/${id}`,
      `Bearer ${token}`
    );
    if (data.status === 200) {
      setData(data.data);
      props.history.push("/main/staff/my-jobs");
    }
  };

  const rejectJobOffer = async (id) => {
    var token = localStorage.getItem("accessToken");
    let obj = {
      rejectionReason: rejectReason,
    };
    if (applicationType !== "withdrawn") {
      obj.isJobRejected = true;
      (obj.status = "Rejected"), (obj.rejectedBy = "staff");
    }
    let url =
      applicationType === "withdrawn"
        ? `v1/LHS/staff/withdraw/${data._id}`
        : `v1/LHS/staff/reject/${data._id}`;
    const responseData = await callApi("POST", url, `Bearer ${token}`, obj);
    setShowReasonModal(false);
    if (responseData.status === 200) {
      setData(responseData.data);
      props.history.push("/main/staff/my-jobs");
    }
  };

  return (
    <div className="theme2">
      {/* <NewNavigation /> */}
      <div className="theme2_container">
        <Header page="My Jobs" />

        <div className="theme2_main_container">
          <div className="d-flex align-items-center mb-3">
            <img
              alt="right_arrow.svg"
              src={right_arrow}
              style={{ borderRadius: "50%", cursor: "pointer" }}
              onClick={() => props.history.push("/main/staff/my-jobs")}
            />
            <p className="mb-0 d-flex align-items-center">
              <span
                className="mb-0 go_back_link ps-3"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/main/staff/my-jobs")}
              >
                My jobs &nbsp;
              </span>{" "}
              / &nbsp;
              <span className="hospital_name_link">{data.jobTitle}</span>
            </p>
          </div>

          <div className="job_offer_details_background py-4">
            <div className="row m-0 find_jobs_card find_jobs_card_non_background d-flex align-items-center mt-4">
              <div
                className="col-2 p-0 d-flex align-items-center justify-content-center"
                style={{ width: "12%" }}
              >
                <div className="t2_sp_avatar_img relative">
                  <img
                    alt="avatar.png"
                    src={jobImg}
                    className="job_image"
                    style={{ borderRadius: "50%" }}
                  />
                  <img
                    src={tickIcon}
                    alt="badge.png"
                    className="tick_icon absolute top-[38px] left-[38px]"
                  />
                </div>
              </div>
              <div className="col-3 p-0">
                <p className="job_name mb-1">{data.jobTitle}</p>
                <p className="job_date mb-1">
                  Start Date:{" "}
                  {data.startDate
                    ? moment(data.startDate).format("MM-DD-YYYY")
                    : moment(data.expectedStartDate).format("MM-DD-YYYY")}
                </p>
              </div>
              <div className="col-2 p-0">
                <div className="permanent_position_btn">
                  <button className="px-4 h-12 text-15 text-white bg-[#3498db] shadow-md rounded-full border-0 flex items-center justify-center">
                    {data.contractType}
                  </button>
                  {data.contractType !== "Permanent Position" && (
                    <>
                      {data.weekendAvailiblity && (
                        <button className="mt-2">Weekend Job</button>
                      )}
                      {data.timeCommitment && (
                        <button className="mt-2 px-4 h-12 text-15 text-white bg-[#3498db] shadow-md rounded-full border-0 flex items-center justify-center">
                          {data.timeCommitment === "Full Time"
                            ? "Full Time (8hrs or more)"
                            : data.timeCommitment}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-2 p-0 d-flex align-items-center flex-column job_work_name">
                {console.log(data, "DATATATTATATAT")}
                <p className="mb-0 name_tag w-full">
                  Contract Length :{" "}
                  {data.contractType !== "Permanent Position" ? (
                    <>
                      {" "}
                      {props.location.state.onGoingJobs ? (
                        <span>{data.totalDays} Days</span>
                      ) : (
                        <span>
                          {data.contractLength && data.contractLength.duration}{" "}
                          {data.contractLength && data.contractLength.length}
                        </span>
                      )}
                    </>
                  ) : (
                    <span>Permanent</span>
                  )}{" "}
                </p>
                <div className="flex items-center w-full gap-2">
                  <p className="mb-0 job_rating_count flex gap-2">
                    <img
                      src={starIcon}
                      alt="badge.png"
                      className=""
                      width="20px"
                      height="20px"
                    />
                    4.5
                  </p>
                  <span className="text-gray-500">|</span>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <span>0 Review</span>
                  </div>
                </div>
              </div>

              {status === "completed" && (
                <div className="job_appliction_btn completed col-3 d-flex align-items-center justify-content-center p-0">
                  <button className="completed">COMPLETED</button>
                </div>
              )}

              {status === "onGoing" && (
                <div className="col-3 pl-5 d-flex align-items-center justify-content-center">
                  <p className="mb-0 justify-content-center">
                    {data.daysWorked} of {data.totalDays} days completed
                  </p>
                </div>
              )}

              {status === "InterestJob" && (
                <div className="col-3 d-flex align-items-center justify-content-center p-0">
                  <button className="apply_job_btn">Shows Interest</button>
                </div>
              )}

              {status === "appliedJobs" && (
                <div className="col-3 d-flex align-items-center justify-content-center p-0">
                  <button
                    className="apply_job_btn"
                    onClick={() => {
                      setShowReasonModal(true);
                      setApplicationType("withdrawn");
                    }}
                  >
                    Withdraw Application
                  </button>
                </div>
              )}
              {status === "jobOffer" && (
                <div className="mt-3 col-3 d-flex flex-column align-items-center justify-content-center p-0">
                  <button
                    className="apply_job_btn"
                    onClick={() => acceptJobOffer(data._id)}
                  >
                    Accept Offer
                  </button>
                  <p
                    className="mb-0 mt-2 reject_job_link"
                    onClick={() => {
                      setShowReasonModal(true);
                      setApplicationType("rejectOffer");
                    }}
                  >
                    Reject Offer
                  </p>
                </div>
              )}

              {status === "rejected" && (
                <div className="col-3 d-flex align-items-center justify-content-center p-0">
                  <button
                    style={{ backgroundColor: "#EB5757", border: "none" }}
                    className="apply_job_btn"
                  >
                    Rejected
                  </button>
                </div>
              )}
              {status === "withdrawn" && (
                <div className="col-3 d-flex align-items-center justify-content-center p-0">
                  <button
                    style={{ backgroundColor: "#EB5757", border: "none" }}
                    className="apply_job_btn"
                  >
                    Withdrawn
                  </button>
                </div>
              )}
            </div>

            {status === "onGoing" && status === "jobOffer" && (
              <div className="px-5 mt-4">
                <hr />

                <div className="row m-0 d-flex justify-content-center align-items-center">
                  <div className="col-3">
                    <div
                      onClick={() => props.history.push("/main/staff/chats")}
                      className="job_offer_details_btn d-flex align-items-center justify-content-center"
                    >
                      <img src={chat} width="24px" alt="" />
                      <p className="mb-0 ps-3">CHAT</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {status !== "rejected" && status !== "withdrawn" && (
              <div className="row m-0 mx-5 mt-4 job_offer_process_flow">
                <div className="col-12 p-0 pt-3">
                  <div className="job_details_area_box_card py-4 row m-0">
                    <div className="col-3 px-3 d-flex align-items-center">
                      <div className="d-flex align-items-center m-0">
                        <div className="active_icon">
                          {/* <img src={applied_active} alt="" /> */}
                        </div>

                        <div className="ps-3">
                          <p className="mb-0 appication_status">Applied</p>
                          <p className="mb-0 appication_status_date">
                            01/09/2021 10:30 AM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-3 p-0 d-flex align-items-center">
                      <div className="d-flex align-items-center m-0">
                        <div className="active_icon"></div>

                        <div className="ps-3">
                          <p className="mb-0 appication_status">
                            Under Review{" "}
                          </p>
                          <p className="mb-0 appication_status_date">
                            01/09/2021 10:30 AM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-2 p-0 d-flex align-items-center">
                      <div className="d-flex align-items-center m-0">
                        <div
                          className={`${
                            status === "onGoing" || status === "jobOffer"
                              ? "active_icon"
                              : "inactive_icon"
                          }`}
                        ></div>

                        <div className="ps-3">
                          <p className="mb-0 appication_status">Interview </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-2 p-0 d-flex align-items-center">
                      <div className="d-flex align-items-center m-0">
                        <div
                          className={`${
                            status === "onGoing" || status === "jobOffer"
                              ? "active_icon"
                              : "inactive_icon"
                          }`}
                        ></div>

                        <div className="ps-3">
                          <p className="mb-0 appication_status">Job Offer </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-2 p-0 d-flex align-items-center">
                      <div className="d-flex align-items-center m-0">
                        <div
                          className={`${
                            status === "onGoing"
                              ? "active_icon"
                              : "inactive_icon"
                          }`}
                        ></div>

                        <div className="ps-3">
                          <p className="mb-0 appication_status">Hired </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <JobDetailsSection
              setModalShow={() => {
                setShowReviewModal(true);
                console.log(data, "sASasAS");
                getAllReviews(
                  status === "onGoing"
                    ? data.job.hospital._id
                    : data.ratings
                    ? data.hospital._id
                    : data.hospital._id
                );
              }}
              data={data}
              status={status}
            />
          </div>
        </div>
      </div>

      <RateReviewModal
        isOpen={reviewModal}
        onClose={() => setShowReviewModal(false)}
        className={"md:w-1/2 md:mt-0"}
        reviewData={reviewData}
        data={data}
      />

      {showReasonModal && (
        <AddReasonModal
          userData={data}
          show={showReasonModal}
          applicationType={applicationType}
          onHide={() => setShowReasonModal(false)}
          setRejectReason={(e) => setrejectReason(e.target.value)}
          rejectJobOffer={rejectJobOffer}
          rejectReason={rejectReason}
          keyValue={true}
        />
      )}
    </div>
  );
};

export default JobOfferDetails;
