import React, { Component, useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../../redux/action/action";
import ErrorState from "../../theme_1/staffSignUp/components/errorState";
import { axiosInstance } from "../../../config/axiosConfig";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastMeassage/ToastMeassage";
import Loader from "../../modals/Loader";

class NewAdministrators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      psw: "",
      repsw: "",
      phone: "",
      role: "",
      ein: "",
      showLoader: false,
      selectedPermissions: ["Manage Staff"],
      adminsList: [],
      error: {
        fname: "none",
        lname: "none",
        email: "none",
        psw: "none",
        repsw: "none",
        phone: "none",
        role: "none",
        ein: "none",
      },
    };
  }

  componentDidMount() {
    if (this.props?.administratorData) {
      const { firstName, lastName, email, phone, role, permissions } =
        this.props.administratorData;
      this.setState({
        fname: firstName,
        lname: lastName,
        email: email,
        phone: phone,
        role: role,
        selectedPermissions: permissions,
      });
    }
  }

  togglePassword = (id) => {
    let input = $("#" + id);
    if (input.attr("type") === "password") {
      input.attr("type", "text");
      $("#c_" + id).hide();
      $("#o_" + id).show();
    } else {
      input.attr("type", "password");
      $("#o_" + id).hide();
      $("#c_" + id).show();
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
    }));
  };

  setVal = (name, val) => {
    console.log(name, val);
    if (val < 250) {
      this.setState({ name: val });
      if (val !== "") {
        var error = this.state.error;
        error[name] = "none";
        this.setState({ error: error });
      }
    }
  };

  setRole = (e) => {
    if (e.target.value.length < 100) {
      this.setState({ role: e.target.value });
      if (e.target.value !== "") {
        var error = this.state.error;
        error.role = "none";
        this.setState({ error: error });
      }
    }
  };

  setEmail = async (e) => {
    this.setState({
      email: e.target.value,
      emailErr: "Valid email address is required.",
    });
    if (e.target.value) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var isValidEmail = re.test(String(e.target.value).toLowerCase());
      if (!isValidEmail) {
        this.setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            email: "block",
          },
          emailErr: "Valid email address is required.",
        }));
      } else {
        this.emailRepeatCheck(e.target.value);
        this.setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            email: "none",
          },
          emailErr: "",
        }));
      }
    }
  };

  setPhone = (e) => {
    if (e.target.value.length < 11) {
      this.setState({ phone: e.target.value });
      var validate;
      if (e.target.value.match(/\d/g))
        if (e.target.value.match(/\d/g).length === 10) validate = true;
        else validate = false;
      else validate = false;

      var error = this.state.error;
      if (e.target.value !== "" && validate) {
        error.phone = "none";
        this.setState({ error: error });
      } else {
        error.phone = "block";
        this.setState({ error: error });
      }
    }
  };

  setPsw = (e) => {
    this.setState({ psw: e.target.value });
    var password = e.target.value;
    var error = this.state.error;
    if (password.length === 0) {
      this.setState({ pswErr: "Password is required." });
      error.psw = "block";
      this.setState({ error: error });
      return;
    } else {
      if (password.length < 6) {
        this.setState({
          pswErr:
            "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
        });
        error.psw = "block";
        this.setState({ error: error });
      } else {
        var matchedCase = [];
        matchedCase.push("[$@$!%*#?&]");
        matchedCase.push("[A-Z]");
        matchedCase.push("[0-9]");
        matchedCase.push("[a-z]");
        var ctr = 0;
        for (var i = 0; i < matchedCase.length; i++) {
          if (new RegExp(matchedCase[i]).test(password)) {
            ctr++;
          }
        }
        // Display it
        switch (ctr) {
          case 0:
          case 1:
          case 2:
          case 3:
            this.setState({
              pswErr:
                "Password should have minimum 6 characters, 1 upper case,  1 lower case, 1 special character and 1 number.",
            });
            error.psw = "block";
            this.setState({ error: error });
            break;
          case 4:
            error.psw = "none";
            this.setState({ error: error });
            break;
          default:
            return;
        }
        if (this.state.repsw !== "") {
          var validate = this.state.repsw === e.target.value;
          error = this.state.error;
          if (validate) {
            error.repsw = "none";
            this.setState({ error: error });
          } else {
            error.repsw = "block";
            this.setState({ error: error });
          }
        }
      }
    }
  };

  setRepsw = (e) => {
    var validate = this.state.psw === e.target.value;
    this.setState({ repsw: e.target.value });
    var error = this.state.error;
    if (e.target.value !== "" && validate) {
      error.repsw = "none";
      this.setState({ error: error });
    } else if (e.target.value === "" && this.state.psw === "") {
      error.repsw = "none";
      this.setState({ error: error });
    } else {
      error.repsw = "block";
      this.setState({ error: error });
    }
  };

  setEin = async (e) => {
    const einValue = e.target.value.replace(/\D/g, "");
    this.setState({ ein: einValue });
    if (einValue !== "") {
      var error = this.state.error;
      error.ein = "none";
      this.setState({ error: error });
    }

    var res = await callApi("GET", `v1/LHS/hospital/ein/${einValue}`);
    if (res.status === 500) {
      var error = this.state.error;
      error.ein = "block";
      this.setState({
        error: error,
        einMsgError: "EIN Number already present",
      });
    } else {
      var error = this.state.error;
      error.ein = "none";
      this.setState({
        error: error,
        einMsgError: "The EIN number is required",
      });
    }
  };

  // goToNext = () => {
  //   this.props.history.push("/hospSignUp_2");
  // };

  emailRepeatCheck = async (email) => {
    if (this.state.email !== "" || email !== "") {
      var data = {
        email: email || this.state.email,
      };
      try {
        const [res1, res2, res3] = await Promise.all([
          callApi("POST", "v1/LHS/hospital/emailCheck", null, data),
          callApi("POST", "v1/LHS/staff/emailCheck", null, data),
          callApi("POST", "v1/LHS/admin/emailCheck", null, data),
        ]);

        if (
          res1.result === "OK" &&
          res2.result === "OK" &&
          res3.result === "OK"
        ) {
          this.setState((prevState) => ({
            ...prevState,
            error: {
              ...prevState.error,
              email: "none",
            },
            emailErr: "",
          }));
          return true;
        } else {
          this.setState((prevState) => ({
            ...prevState,
            error: {
              ...prevState.error,
              email: "block",
            },
            emailErr: "This Email address is already in use.",
          }));
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  };

  continueToNext = async () => {
    const { history } = this.props;
    const { fname, lname, email, phone, psw, repsw } = this.state;
    const error = {
      fname: fname.trim() === "" ? "block" : "none",
      lname: lname.trim() === "" ? "block" : "none",
      email: email.trim() === "" ? "block" : "none",
      phone: phone.trim() === "" ? "block" : "none",
      psw: psw.trim() === "" ? "block" : "none",
      repsw: repsw.trim() === "" ? "block" : "none",
    };

    this.setState({ error });
    if (this.props.administratorData?._id) {
      this.handleSubmit();
    } else {
      this.setState({ showLoader: true });
      let validEmail = await this.emailRepeatCheck();
      if (!validEmail) {
        this.setState({ showLoader: false });
        return;
      }
      this.handleSubmit();
    }
  };

  handlePermissionChange = (permission) => {
    this.setState((prevState) => {
      if (prevState.selectedPermissions.includes(permission)) {
        if (
          prevState.selectedPermissions.length === 1 &&
          permission === prevState.selectedPermissions[0]
        ) {
          return {
            selectedPermissions: [permission],
          };
        } else {
          return {
            selectedPermissions: prevState.selectedPermissions.filter(
              (p) => p !== permission
            ),
          };
        }
      } else {
        return {
          selectedPermissions: [...prevState.selectedPermissions, permission],
        };
      }
    });
  };

  handleSubmit = async () => {
    const { fname, lname, email, phone, psw, repsw } = this.state;
    let formIsValid = true;
    const error = { ...this.state.error };

    if (fname.trim() === "") {
      formIsValid = false;
      error.fname = "block";
    } else {
      error.fname = "none";
    }

    if (lname.trim() === "") {
      formIsValid = false;
      error.lname = "block";
    } else {
      error.lname = "none";
    }

    if (email.trim() === "") {
      formIsValid = false;
      error.email = "block";
    } else {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!emailRegex.test(email.trim())) {
        formIsValid = false;
        error.email = "block";
      } else {
        error.email = "none";
      }
    }

    if (phone.trim() === "" || phone.trim().length !== 10) {
      formIsValid = false;
      error.phone = "block";
    } else {
      error.phone = "none";
    }

    if (psw.trim() === "" && !this.props.administratorData?._id) {
      formIsValid = false;
      error.psw = "block";
    } else {
      error.psw = "none";
    }

    if (
      (repsw.trim() === "" || repsw.trim() !== psw) &&
      !this.props.administratorData?._id
    ) {
      formIsValid = false;
      error.repsw = "block";
    } else {
      error.repsw = "none";
    }
    this.setState({ error });
    if (formIsValid) {
      this.props.administratorData?._id
        ? this.changeAdminStatus()
        : this.addAdministrator();
    } else {
      this.setState({ showLoader: false });
    }
  };

  addAdministrator = async () => {
    this.setState({ showLoader: true });
    const { fname, lname, phone, psw, email, selectedPermissions } = this.state;
    const updatedUser = {
      firstName: fname,
      lastName: lname,
      phone: phone,
      email: email,
      password: psw,
      permissions: selectedPermissions,
    };
    try {
      await axiosInstance.post("v1/LHS/admin/add", updatedUser);
      showSuccessToast("Add New Administrator successfully!");
    } catch (error) {
      showErrorToast(error.response?.data?.Message);
    } finally {
      this.setState({ showLoader: false });
      this.props?.closeModal();
    }
  };

  changeAdminStatus = async (status, id) => {
    this.setState({ showLoader: true });
    const { fname, lname, phone, email, selectedPermissions } = this.state;
    const updatedUser = {
      firstName: fname,
      lastName: lname,
      phone: phone,
      email: email,
      permissions: selectedPermissions,
    };
    try {
      await axiosInstance.put(
        `v1/LHS/admin/update/${this.props.administratorData._id}`,
        updatedUser
      );
      showSuccessToast("Administrator updated successfully!");
    } catch (error) {
      showErrorToast("Something went wrong");
    } finally {
      this.props?.closeModal();
      this.setState({ showLoader: false });
    }
  };

  render() {
    const { isConfirmPasswordVisible, isPasswordVisible } = this.state;

    return (
      <>
        <div className="w-full">
          <div className="">
            {/* <img
                    width="12px"
                    height="12px"
                    alt="img"
                    src={right_arrow}
                    onClick={this.backButton}
                  /> */}
            <div className="hsu1_txt1 !text-left !text-xl">
              {this.props.administratorData?._id ? "Edit" : "Add"} new
              administrator
            </div>
          </div>

          <div className="ssu2_modal1_input mt-4">
            <h1 style={{ fontSize: "20px" }}>First name</h1>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_email_icon">
                {/* <img width="18px" height="15px" alt="img" src={user} /> */}
              </div>
              <input
                className="ssu2_modal3_select pl-5"
                placeholder="Enter First Name"
                type="text"
                name="fname"
                value={this.state.fname}
                onChange={(e) => {
                  const filteredValue = e.target.value.replace(
                    /[^a-zA-Z ]/g,
                    ""
                  );
                  this.setState({ fname: filteredValue });
                  if (filteredValue !== "") {
                    var error = this.state.error;
                    error.fname = "none";
                    this.setState({ error: error });
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") this.continueToNext();
                }}
              />
            </div>
            <ErrorState
              show={this.state.error.fname}
              name="First Name is required."
            />
          </div>
          <div className="ssu2_modal1_input">
            <h1 style={{ fontSize: "20px" }}>Last name</h1>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_email_icon">
                {/* <img width="18px" height="15px" alt="img" src={user} /> */}
              </div>
              <input
                className="ssu2_modal3_select pl-5"
                placeholder="Enter Last Name"
                type="text"
                value={this.state.lname}
                name="lname"
                onChange={(e) => {
                  const filteredValue = e.target.value.replace(
                    /[^a-zA-Z ]/g,
                    ""
                  );
                  this.setState({ lname: filteredValue });
                  if (filteredValue !== "") {
                    var error = this.state.error;
                    error.lname = "none";
                    this.setState({ error: error });
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") this.continueToNext();
                }}
              />
            </div>
            <ErrorState
              show={this.state.error.lname}
              name="Last Name is required."
            />
          </div>
          <div className="ssu2_modal1_input">
            <h1 style={{ fontSize: "20px" }}>Contact number</h1>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <input
                className="ssu2_modal3_select pl-5"
                placeholder="Enter Contact Number"
                type="number"
                value={this.state.phone}
                name="phone"
                onChange={this.setPhone}
                onKeyUp={(e) => {
                  if (e.key === "Enter") this.continueToNext();
                }}
              />
            </div>
            <ErrorState
              show={this.state.error.phone}
              name="10 digits phone number is required."
            />
          </div>
          <div className="ssu2_modal1_input">
            <h1 style={{ fontSize: "20px" }}>Email address</h1>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox">
              <div className="input_left_email_icon">
                {/* <img
                        width="18px"
                        height="15px"
                        alt="img"
                        src={emailIcon}
                      /> */}
              </div>
              <input
                className="ssu2_modal3_select pl-5"
                placeholder="Enter Email Address"
                type="text"
                readOnly={this.props.administratorData?._id}
                value={this.state.email}
                name="email"
                onChange={this.setEmail}
                onKeyUp={(e) => {
                  if (e.key === "Enter") this.continueToNext();
                }}
              />
            </div>
            <ErrorState
              show={this.state.error.email}
              name={this.state.emailErr || "Valid Email address is required."}
            />
          </div>
          {!this.props.administratorData?._id && (
            <div className="ssu2_modal1_input">
              <h1 style={{ fontSize: "20px" }}>Password</h1>
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <input
                  id="psw"
                  className="ssu3_password pl-5"
                  placeholder="Enter Password"
                  type={isPasswordVisible ? "text" : "password"}
                  value={this.state.psw}
                  name="psw"
                  onChange={this.setPsw}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") this.continueToNext();
                  }}
                />
                <div
                  className="ssu3_eye"
                  onClick={this.togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <VisibilityIcon id="o_psw" style={{ display: "block" }} />
                  ) : (
                    <VisibilityOffIcon
                      id="c_psw"
                      style={{ display: "block" }}
                    />
                  )}
                </div>
              </div>
              <ErrorState
                show={this.state.error.psw}
                name="Password should have minimum 6 characters, 1 upper case, 1 lower case, 1 special character and 1 number."
              />
            </div>
          )}
          {!this.props.administratorData?._id && (
            <div className="ssu2_modal1_input">
              <h1 style={{ fontSize: "20px" }}>Confirm password</h1>
              <div className="ssu2_modal1_input ssu2_modal3_selectBox">
                <input
                  id="repsw"
                  className="ssu3_password pl-5"
                  placeholder="Enter Confirm Password"
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  value={this.state.repsw}
                  name="repsw"
                  onChange={this.setRepsw}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") this.continueToNext();
                  }}
                />
                <div
                  className="ssu3_eye"
                  onClick={this.toggleConfirmPasswordVisibility}
                >
                  {isConfirmPasswordVisible ? (
                    <VisibilityIcon id="o_psw" style={{ display: "block" }} />
                  ) : (
                    <VisibilityOffIcon
                      id="c_psw"
                      style={{ display: "block" }}
                    />
                  )}
                </div>
              </div>
              <ErrorState
                show={this.state.error.repsw}
                name="Doesn't match with the password."
              />
            </div>
          )}

          <div className="ssu2_modal1_input">
            <h1 style={{ fontSize: "20px", marginBottom: "12px" }}>
              Permission
            </h1>
            <div className="ssu2_modal1_input ssu2_modal3_selectBox grid gap-4">
              <label
                className=""
                style={{
                  border: "1px solid #cccccc",
                  boxShadow: "0px 0px 2px #cccccc",
                  fontSize: "20px",
                  padding: "10px 15px",
                  borderRadius: "25px",
                }}
              >
                <input
                  className="mr-4 customone"
                  type="checkbox"
                  name="permission"
                  onChange={(e) => this.handlePermissionChange("Manage Staff")}
                  checked={this.state.selectedPermissions.includes(
                    "Manage Staff"
                  )}
                />
                Manage staff
              </label>
              <label
                className=""
                style={{
                  border: "1px solid #cccccc",
                  boxShadow: "0px 0px 2px #cccccc",
                  fontSize: "20px",
                  padding: "10px 15px",
                  borderRadius: "25px",
                }}
              >
                <input
                  className="mr-4 customone"
                  type="checkbox"
                  name="permission"
                  onChange={(e) =>
                    this.handlePermissionChange("Manage Hospital")
                  }
                  checked={this.state.selectedPermissions.includes(
                    "Manage Hospital"
                  )}
                />
                Manage Hospital
              </label>
            </div>
          </div>
          <div className="flex justify-end gap-3 text-center mb-4">
            <div
              className=" w-32 bg-white cursor-pointer  border-1 text-[#73C7A9] rounded-[28px] border !border-[#73C7A9] p-2 hover:!bg-[#73C7A9] hover:text-white"
              onClick={this.props?.closeModal}
            >
              Cancel
            </div>
            <div
              className="w-32 text-white bg-[#73C7A9] rounded-[28px] p-2 cursor-pointer"
              onClick={() => {
                this.continueToNext();
              }}
            >
              {this.state.showLoader ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewAdministrators;
