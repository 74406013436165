import React, { useEffect, useRef, useState } from "react";
import Nav from "../../../theme_1/Nav";
import searchImg from "../../../assets/images/search.svg";
import StarIcon from "@material-ui/icons/Star";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../common/toastMeassage/ToastMeassage";
import { axiosInstance } from "../../../../config/axiosConfig";
import DataTable from "../../../ui/DataTable";
import Button from "../../../ui/Button";
import RateReviewModal from "../../../containers/staff/rate_review_modal";
import Modal from "../../../common/modal";
import Loader from "../../../modals/Loader";

export const AddReviewModalHospital = (props) => {
  const [ratings, setRatings] = useState(1);
  const [review, setReview] = useState("");
  const [reviewErr, setReviewErr] = useState(false);

  return (
    <Modal {...props}>
      <div className="">
        <div className="p-0">
          <div className="flex gap-4 items-center">
            <div className="w-14 h-14 rounded-full overflow-hidden">
              <img
                src={
                  props?.userData?.image
                    ? props?.userData?.image
                    : props?.userData?.avatar
                }
                className="object-cover w-full h-full"
              />
            </div>
            <div>
              <p className="text-lg">{props?.userData?.name}</p>
              <div className="flex gap-2 opacity-70">
                <p>{props?.userData?.experiencedIn}</p> |{" "}
                <p>
                  {
                    (props?.userData?.currentLocation?.name,
                    props?.userData?.currentLocation?.state)
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="mt-3">
            {[1, 2, 3, 4, 5].map((ind) => (
              <StarIcon
                key={ind}
                className={
                  ind <= ratings ? "text-yellow-500" : "text-[#e3e3e3]"
                }
                onClick={() => setRatings(ind + 0)}
              />
            ))}
          </div>

          <div className="row m-0 rate_reviw_card_comment mt-3">
            <textarea
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              className={
                reviewErr && review.length < 1 && "border !border-red-500"
              }
              placeholder="Write your comment here"
              value={review}
              onChange={(e) => setReview(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row m-0 justify-end  rate_reviw_card_footer mt-3">
        <div className="flex justify-end  gap-5 w-1/2">
          <Button
            onClick={props?.onClose}
            className="border !border-[#73C7A9] !text-[#73C7A9]  rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white"
          >
            CANCEL
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (review) {
                props.postReview(review, ratings);
              } else {
                setReviewErr(true);
              }
            }}
            className="post_review_btn place-content-center w-full px-6 rounded-full"
          >
            {props.loader ? <Loader /> : "POST"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default function MyStaff(props) {
  const [filter, setFilter] = useState({
    page: 0,
    searchValue: "",
    limit: 10,
  });
  const [showAddReviewModal, setShowAddReviewModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [reviewUserData, setReviewUserData] = useState({});
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inProgressJob, setInprogress] = useState([]);
  const [currentSelectedItem, setCurrentSelectedItem] = useState({});
  const [searchText, setSearchText] = useState("");

  const GetInprogressJob = async ({ load } = {}) => {
    try {
      !load && setLoader(true);
      let url = `/v1/LHS/hospital/inProgress?skip=${filter?.page * 10}&search=${
        filter?.searchValue
      }&limit=${filter?.limit}`;
      const response = await axiosInstance.get(url);
      setInprogress(response?.data?.data?.data);
      setTotalRecords(response?.data?.data?.count);
    } catch (error) {
      setInprogress([]);
      setTotalRecords(0);
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const getAllReviews = async (id) => {
    try {
      setLoader(true);
      setShowReviewModal(true);
      const data = await axiosInstance.get(
        `v1/LHS/rating/getStaffRating/${id}`
      );
      if (data.status === 200) {
        setReviewData(data.data.data);
      }
    } catch (err) {
      setReviewData([]);
    } finally {
      setLoader(false);
    }
  };

  const postReview = async (review, reviewCount) => {
    if (!loader) {
      setLoader(true);
      try {
        let obj = {
          ratingTo: currentSelectedItem?.staff,
          rating: reviewCount,
          review: review,
        };
        let url = `v1/LHS/rating/save`;
        var res = await axiosInstance.post(url, obj);
        if (res?.data?.status == 200) {
          showSuccessToast("Review saved successfully");
          GetAllOngoingJobs({ nextPage: 1 });
          setPage(1);
          // GetAllCompletedJobs(searchFilter);
          setShowAddReviewModal(false);
        }
      } catch (err) {
      } finally {
        setLoader(false);
        setShowAddReviewModal(false);
        setCurrentSelectedItem({});
        GetInprogressJob();
      }
    }
  };

  const handleCreateReview = () => {
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    let url = `/v1/LHS/rating/save`;
    const requestData = {
      ratingTo: staffId,
      rating: rating,
      review: review,
    };
    axiosInstance
      .post(url, requestData)
      .then((res) => {
        showSuccessToast(res?.data?.message);
      })
      .catch(() => {
        showErrorToast("Something went wrong!");
      })
      .finally(() => {
        setButtonClicked(false);
      });
  };

  useEffect(() => {
    GetInprogressJob();
  }, [filter]);

  const handleApplicant = (jobTitle, jobDetails) => {};

  const debounceTimer = useRef(null);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = null;
    }
    debounceTimer.current = setTimeout(() => {
      setFilter((prev) => ({
        ...prev,
        page: 0,
        searchValue: value,
        limit: value?.length > 0 ? 99999999 : 10,
      }));
    }, 300);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (item) => {
        return (
          <div className="w-36" style={{ textWrap: "wrap" }}>
            {item?.name}
          </div>
        );
      },
    },
    {
      title: "Job Title",
      key: "jobTitle",
    },
    {
      title: "Location",
      key: "location",
      render: (item) => {
        return (
          <div className="w-36" style={{ textWrap: "wrap" }}>
            {item?.currentLocation?.name}, {item?.currentLocation?.state}
          </div>
        );
      },
    },
    {
      title: "Review",
      key: "review",
      render: (item) => {
        return (
          <div className="flex gap-2 flex-col items-center">
            <p>{item?.ratings?.totalRatings}</p>
            {item?.ratings?.totalRatings > 0 && (
              <div
                className="text-[#73C7A9] text-[14px] cursor-pointer self-center"
                onClick={(e) => {
                  getAllReviews(item?.staff);
                  setReviewUserData(item);
                }}
              >
                (See all)
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Medical Settings",
      key: "medicalSettings",
      render: (item) => {
        return (
          <div className="w-36" style={{ textWrap: "wrap" }}>
            {item?.medicalSettings}
          </div>
        );
      },
    },
    {
      title: "Nursing License",
      key: "nursingLicense",
      render: (item) => {
        return (
          <div className="text-center">
            {item?.nursingLicense?.length > 0 ? "Yes" : "No"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "actions",
      render: (item) => {
        return (
          <div className="flex gap-3">
            <Button
              className="px-7 rounded-full"
              onClick={() => {
                setCurrentSelectedItem(item);
                setShowAddReviewModal(true);
              }}
            >
              Add review
            </Button>
          </div>
        );
      },
    },
  ];

  const onPageChange = (e) => {
    setFilter((prev) => ({ ...prev, page: e - 1 }));
  };

  return (
    <>
      <Nav />
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div
          className="lg:flex gap-3 block align-top py-4 w-11/12 md:w-3/4 mx-auto flex-col max-w-[1500px]"
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <div className="bg-white overflow-hidden rounded-tr-xl rounded-tl-xl">
            <div className="flex items-center justify-between p-4">
              <div className="w-3/5 top_NSearch overflow-hidden">
                <div className="flex gap-3 w-full">
                  <img className="w-5" alt="search.svg" src={searchImg} />
                  <input
                    className="w-full"
                    placeholder="Search By Staff Name"
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={inProgressJob}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
              onSearch={null}
              noDataFoundText="No staff available"
              loader={loader}
              holdPageState={filter?.page}
              // moveBack={moveBack}
            />
          </div>
        </div>
      </div>
      <AddReviewModalHospital
        isOpen={showAddReviewModal}
        onClose={() => setShowAddReviewModal(false)}
        className={"md:w-1/2 md:mt-0"}
        postReview={(review, reviewCount) => postReview(review, reviewCount)}
        userData={currentSelectedItem}
        loader={loader}
      />
      <RateReviewModal
        isOpen={showReviewModal}
        onClose={() => setShowReviewModal(false)}
        className={"md:w-1/2 md:mt-0"}
        reviewData={reviewData}
        loader={loader}
        data={reviewUserData}
      />
    </>
  );
}
