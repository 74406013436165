import React, { useEffect } from "react";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Nav from "../../../theme_1/Nav";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom";
import { axiosInstance } from "../../../../config/axiosConfig";
import { useSelector } from "react-redux";
import useLoader from "../../../modals/hooks/useLoader";
import RecommendedForYou from "../../../containers/commonComponent/RecommendedForYou";
import RightSideBar from "../../../containers/commonComponent/rightSideBar";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../common/toastMeassage/ToastMeassage";
import JobsHeader from "../../../theme_1/staffSignUp/JobsHeader";
import MyJobDetails from "./MyJobDetails";

const staffMyJobDetails = ({ pageIdentity }) => {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const { prevPath } = location.state || {};
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const profile = useSelector((state) => state.data.profile);

  const applyJob = async ({ jobCardData }) => {
    const id = jobCardData?.data?._id;
    try {
      setLoading(true);
      let url = `/v1/LHS/job/apply/${id}`;
      const datas = await axiosInstance.post(url, { costPerHour: "50" });
      if (
        datas.Message ==
        "Positions Have been Filled. Cannot Apply for this Job.."
      ) {
        showSuccessToast(datas.Message);
      }
      showSuccessToast("Applied successfully!");
      getJobsById(location?.pathname.split("/")[2]);
    } catch (error) {
      showErrorToast(error.Message);
    } finally {
      setLoading(false);
    }
  };

  const getJobsById = async (hospitalId) => {
    try {
      showLoader();
      const url = `/v2/LHS/User/getJobById/${hospitalId}`;
      const response = await axiosInstance.get(url);
      if (response.data.data) {
        setJobData(response.data.data.data?.[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (location?.pathname.split("/")[2]) {
      getJobsById(location?.pathname.split("/")[2]);
    }
  }, [location?.pathname.split("/")[2]]);

  return (
    <>
      {token ? <Nav /> : <JobsHeader />}
      <div
        className="flex justify-center"
        style={{ backgroundColor: "#F3F2EF", minHeight: "calc(100vh - 60px)" }}
      >
        <div className="lg:flex gap-3 block justify-between align-top py-4 w-11/12 mx-auto max-w-[1500px]">
          <RightSideBar className="sticky h-auto min-w-[25%] hidden lg:block" />
          <MyJobDetails
            closeJobDetails={() =>
              history.push(token ? prevPath || "/myJob" : prevPath || "/jobs")
            }
            detailsData={{
              data: jobData,
              pageType:
                !jobData?.positionsAvailable && token && profile
                  ? "filled"
                  : jobData?.isApplied
                  ? "applied"
                  : "apply",
            }}
            loading={loading}
            buttonAction={applyJob}
          />
          <RecommendedForYou />
        </div>
      </div>
    </>
  );
};

export default staffMyJobDetails;
