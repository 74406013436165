import React, { Profiler, useEffect, useState } from "react";
import Adver1 from "../../../../assets/images/Adver1.svg";
import Adver2 from "../../../../assets/images/Adver2.svg";
import Modal from "../../../../common/modal";
import user from "../../../../assets/images/user.png";
import { axiosInstance } from "../../../../../config/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../common/toastMeassage/ToastMeassage";
import classNames from "classnames";
import Pagination from "../../../../ui/Pagination";
import useLoader from "../../../../modals/hooks/useLoader";

const Advertisement = ({ setCreateNew }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [viewData, setViewData] = useState(null);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.data.profile);
  const [modalViewOpen, setModalViewOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hideAdvertise, setHideAdvertise] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [getAllAdData, setGetAllAdData] = useState([]);
  const [blockId, setBlockId] = useState(null);
  const { showLoader, hideLoader } = useLoader();
  const [readMore, setReadMore] = useState(false);
  const openModal = (_id) => {
    setBlockId(_id);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setHideAdvertise(false);
  };
  const openHideAdvertise = () => {
    setHideAdvertise(true);
  };
  const getAllAdvertisementData = async ({ page = 1 }) => {
    try {
      showLoader();
      const data = await axiosInstance.get(
        `v2/LHS/User/getAllAdvertisement?page=${page}`
      );
      if (data.status === 200) {
        setGetAllAdData(data?.data?.data?.advertisement);
        setTotalRecords(data?.data?.data?.count);
      }
    } catch (err) {
      showErrorToast("error");
    } finally {
      hideLoader();
    }
  };

  const handleView = (ads) => {
    setModalViewOpen(true);
    setViewData(ads);
  };
  useEffect(() => {
    getAllAdvertisementData({ page: 1 });
  }, []);
  const handleBlock = async () => {
    try {
      showLoader();
      const data = await axiosInstance.post(`v2/LHS/User/blockAdvertisement`, {
        postId: blockId,
        isBlocked: true,
      });
      if (data.status === 200) {
        getAllAdvertisementData({ page: page });
      }
    } catch (err) {
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  const onPageChange = (e) => {
    setPage(e);
    getAllAdvertisementData({
      page: e,
    });
  };

  return (
    <>
      <div className="pb-7">
        <div className="flex justify-between items-center w-[95%] mx-auto pt-4">
          <div className="float-right text-[25px] tracking-[0.2px]">
            Listed ads
          </div>
          {profile?.role == "hospital" && (
            <div className="flex gap-[25px]">
              <div className="float-left m-auto text-xl tracking-[0.2px]">
                Post your ad at 29.99USD
              </div>
              <div className="">
                <button
                  style={{
                    width: "152px",
                    height: "44px",
                    backgroundColor: "#73C7A9",
                    borderRadius: "60px",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch({ type: "CLEAR_CHECKOUT_DATA" });
                    setCreateNew("tab_1");
                  }}
                >
                  Create new
                </button>
              </div>
            </div>
          )}
        </div>
        <div>
          <table className="w-[95%] pl-10 mx-auto mt-8 border-2">
            <thead
              className="h-14"
              style={{
                backgroundColor: "#EAEAEA",
              }}
            >
              <tr>
                <th className="p-4 tracking-[0.2px]">Image</th>
                <th className="tracking-[0.2px]">Description</th>
                <th className="text-center tracking-[0.2px]">Uploaded date</th>
                <th className="tracking-[0.2px]">Status</th>
                <th className="tracking-[0.2px]">Action</th>
              </tr>
            </thead>
            {getAllAdData?.length > 0 && (
              <tbody>
                <>
                  {getAllAdData?.map((ads, index) => {
                    const originalDate = new Date(ads?.createdAt);
                    const options = {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    };
                    const formattedDate = originalDate.toLocaleDateString(
                      "en-US",
                      options
                    );
                    return (
                      <tr
                        className="border-b-2 border-b-gray-200 w-full"
                        key={index}
                      >
                        <td
                          className="p-4 max-w-[40px]"
                          style={{ marginRight: "10px" }}
                        >
                          <div className="w-20 h-20 overflow-hidden rounded-md">
                            <img
                              className="w-full !h-full object-cover"
                              src={ads?.fileUrl[0]}
                              alt="not found"
                            />
                          </div>
                        </td>
                        <td className="max-w-[200px] text-base">
                          <p className="line-clamp-2">{ads?.caption}</p>
                        </td>
                        <td className="text-center text-base tracking-[0.2px]">
                          {formattedDate}
                        </td>
                        <td
                          className={`text-base ${
                            ads.isBlocked ? "text-[#FF4C4C]" : "text-[#73C7A9]"
                          }`}
                        >
                          {ads.isBlocked ? "Blocked" : "Published"}
                        </td>
                        <td>
                          <button
                            className="mr-2"
                            onClick={() => handleView(ads)}
                          >
                            <img src={Adver1} alt="not found" />
                          </button>
                          <button
                            className={classNames({
                              "opacity-100": !ads.isBlocked,
                              "opacity-50": ads.isBlocked,
                            })}
                            onClick={() => {
                              !ads.isBlocked && openModal(ads?._id);
                            }}
                          >
                            <img
                              src={Adver2}
                              alt="not found"
                              onClick={() => {
                                !ads.isBlocked && openHideAdvertise();
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>

                <Modal
                  isOpen={modalViewOpen}
                  onClose={() => setModalViewOpen(false)}
                  className={"md:w-[647px] md:mt-0"}
                >
                  <div className=" flex justify-between items-start">
                    <div
                      className=" flex items-center gap-[20px]"
                      // style={{ cursor: "pointer" }}
                    >
                      <img
                        src={
                          viewData?.userId?.image
                            ? viewData?.userId?.image
                            : viewData?.userId?.avatar
                        }
                        style={{
                          height: "54px",
                          width: "54px",
                        }}
                        className="rounded-full"
                      />
                      <div className="flex flex-col gap-y-[6px]">
                        <h1 className="font-medium text-[18px] leading-5 tracking-[0.2px]">
                          {viewData?.userId?.healthCareInstitution?.name}
                        </h1>
                        <h1 className="text-gray-500 text-[15px]">
                          {/* {viewData?.userId?.corporateAddress?.[0]?.street} */}
                          {viewData?.userId?.corporateAddress?.[0]?.street &&
                            `${viewData?.userId?.corporateAddress?.[0]?.street} ,`}
                          {viewData?.userId?.corporateAddress?.[0]?.city &&
                            `${viewData?.userId?.corporateAddress?.[0]?.city} ,`}
                          {viewData?.userId?.corporateAddress?.[0]?.state &&
                            `${viewData?.userId?.corporateAddress?.[0]?.state}`}
                        </h1>
                        <h1 className="text-[#73C7A9] text-[15px] tracking-[0.2px]">
                          Promoted
                        </h1>
                      </div>
                    </div>
                    <div
                      onClick={() => setModalViewOpen(false)}
                      className="cursor-pointer text-[18px] font-semibold"
                    >
                      X
                    </div>
                  </div>
                  <div>
                    <div className="font-normal mt-[18px] text-container text-[16px] w-full break-words text-gray-500 leading-[24px] tracking-[0.2px]">
                      {readMore ? (
                        <div
                          className="font-normal mt-4 w-full break-words text-[16px] text-gray-500 "
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {viewData?.caption}
                        </div>
                      ) : (
                        <div
                          className="font-normal mt-4 text-container text-[16px] w-full break-words text-gray-500 leading-[24px] tracking-[0.2px]"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {viewData?.caption?.slice(0, 150)}{" "}
                          {viewData?.caption?.length > 150 && (
                            <>
                              <div
                                onClick={() => setReadMore(true)}
                                className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
                              >
                                ...see more
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {viewData?.caption?.length > 100 && readMore && (
                        <div
                          onClick={() => setReadMore(false)}
                          className="cursor-pointer text-right text-sm text-gray-600 leading-[150%]"
                        >
                          ...see less
                        </div>
                      )}
                    </div>
                    <div className="mt-[18px] flex flex-wrap rounded-s gap-4 w-full justify-center">
                      <img
                        src={viewData?.fileUrl[0]}
                        style={{
                          height: "auto",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      />
                    </div>

                    {viewData?.url && (
                      <button
                        onClick={() => window.open(viewData?.url, "_blank")}
                        className="text-base font-medium w-full h-12 leading-5 border-gray-500 sm:rounded-[30px] rounded-[10px] bg-[#73C7A9] text-white mb-2 bg-[#73C7A9] text-white mt-[18px] mb-2"
                      >
                        Click here
                      </button>
                    )}
                  </div>
                </Modal>
                <Modal
                  isOpen={hideAdvertise}
                  onClose={closeModal}
                  className={"md:w-[600px] md:mt-0"}
                >
                  <div className=" flex justify-center items-start w-full">
                    <div className="flex flex-col justify-center">
                      <p className="flex justify-center text-xl leading-[20px] tracking-[0.2px] mb-[20px]">
                        Alert
                      </p>
                      <p className="text-base flex justify-center leading-7 font-medium m-auto mb-4 text-center tracking-[0.2px] w-[400px]">
                        Are you sure? The action is irreversible, and the
                        advertisement cannot be unblocked.
                      </p>
                      <div className="text-center">
                        <button
                          className="text-base  border-1  !border-[#73C7A9] !text-[#73C7A9] rounded-full bg-white hover:!bg-[#73C7A9] hover:!text-white w-32 h-11 ml-5"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            setHideAdvertise(false);
                            handleBlock();
                          }}
                          className="text-base border-2 border-[#73C7A9] bg-[#73C7A9] rounded-full text-white w-32 h-11 ml-5"
                        >
                          Block
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </Modal>
              </tbody>
            )}
          </table>
          {totalRecords > 0 && (
            <Pagination
              totalRecords={totalRecords}
              onPageChange={(e) => {
                if (onPageChange) {
                  onPageChange(e.selected + 1);
                }
              }}
            />
          )}
        </div>
        {getAllAdData?.length == 0 && (
          <div>
            <div className="h-[300px] flex justify-center items-center font-semibold text-[18px]">
              No advertisements available
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Advertisement;
